import { NominationType } from './reports.type';
import { capitalizeSentence } from '@utils/lib/string';
import { ReportItemType, ReportType } from '@components/RefMan/Reports/ReportsList/ObserverReportsList.type';
import { api } from '@services/apiRequest';
import { RolesType } from '@components/RefMan/Reports/ReportsIndex/ObserverReportsIndex.type';

/**
 * @function getRefs
 *
 * @param {NominationType[]} nominations All nominations
 * @param {'REF' | 'UDC'} group of nomination
 * @return {{member: NominationType, role: string}[]} Only nominations with refs of the proper group, in order
 */
export const getRefs = (
  nominations: NominationType[],
  group: 'REF' | 'UDC'
): {
  nomination: NominationType;
  role: string;
}[] => {
  const okStatus = ['ACC', 'TRA'];
  const res = [];
  if (group === 'REF') {
    const ref1 = nominations.filter((i) => i.role === 'REF1' && okStatus.includes(i.status));
    const ref2 = nominations.filter((i) => i.role === 'REF2' && okStatus.includes(i.status));
    if (ref1.length) {
      res.push({ nomination: ref1[0], role: 'REF1' });
    }
    if (ref2.length) {
      res.push({ nomination: ref2[0], role: 'REF2' });
    }
  }
  if (group === 'UDC') {
    const sp = nominations.filter((i) => i.role === 'SP' && okStatus.includes(i.status));
    const crono = nominations.filter((i) => i.role === 'CRONO' && okStatus.includes(i.status));
    const a24s = nominations.filter((i) => i.role === 'A24S' && okStatus.includes(i.status));
    if (sp.length) {
      res.push({ nomination: sp[0], role: 'SP' });
    }
    if (crono.length) {
      res.push({ nomination: crono[0], role: 'CRONO' });
    }
    if (a24s.length) {
      res.push({ nomination: a24s[0], role: 'A24S' });
    }
  }

  return res;
};

/**
 * @function getQuestionnaire
 *
 * @param {ReportType | null} reportData Report data
 * @param {RolesType} role observer | member
 * @param {string} memberRole REF1 | REF2 | ...
 */
export const getQuestionnaire = async (reportData: ReportItemType | null, role: RolesType, memberRole: string) => {
  if (reportData && role) {
    try {
      let idQuestionnaire: number =
        reportData.member.category.cod_group === 'REF' ? reportData.game.level.survey_model_ref : reportData.game.level.survey_model_udc;
      if (idQuestionnaire === 1) {
        const { data } = await api.get(`/evaluations/fol-observers`);
        if (
          (role !== 'member' && data.includes(reportData.member.id_fip_code)) ||
          (role === 'member' && data.includes(reportData.observerIdFipCode))
        ) {
          idQuestionnaire = 2;
        }
      }
      const idPad = ('000' + idQuestionnaire).slice(-3);
      const version = reportData.member.category.cod_group === 'REF' ? '' : '-' + memberRole.toLowerCase();
      const module = await import('@utils/questionnaires/versions/' + idPad + version + '.json');
      return module?.default ? module.default : {};
    } catch (e) {
      console.error('[REFMAN-ERROR]', 'Error while getting questionnaire', e);
    }
  }
};
