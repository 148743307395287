import React, {useEffect, useState} from "react";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import {CheckBadgeIcon, ExclamationCircleIcon, ExclamationTriangleIcon} from "@heroicons/react/24/solid";
import {useIntl} from "react-intl";

const today = new Date();
const dateFormat = {day: "numeric", month: "short", year: "numeric"}

const info = (title, value) => <div className="flex gap-2 text-lg">
  <span className="italic">{title}:</span>
  <span className="font-bold">{value}</span>
</div>

const MemberInfo = ({data}) => {
  const intl = useIntl();
  const [expCertificate, setExpCertificate] = useState();

  useEffect(() => {
    if (data && data.dat_certificate) {
      const expire = new Date(data.dat_certificate);
      setExpCertificate(Math.floor((today.getTime() - expire.getTime()) / (1000 * 60 * 60 * 24)))
    }
  }, [data])

  return (
    <div className="flex flex-col sm:flex-row w-full xl:w-2/3 gap-10 sm:gap-5">
      <div className="w-full sm:w-1/2 border-l-4 pl-4 flex flex-col gap-2">
        {info('Tessera', data.id_fip_code)}
        {info('Città', `${data.city.city} (${data.city.cod_province})`)}
        {info('Data di nascita', intl.formatDate(data.dat_born, dateFormat))}
        {info('Gruppo', data.category.des_category)}
        {info('Categoria', data.category.des_sub_category)}
        {data.category.cod_group === 'REF'
          ? info('Certificato', <div className="flex flex-row gap-2 items-center">
            {data.dat_certificate ? <>
              <span>{intl.formatDate(data.dat_certificate, dateFormat)}</span>
              <span className="">
              {expCertificate > 0 ? <ExclamationCircleIcon className="h-5 w-5 text-red-300"/>
                : expCertificate > -30 ? <ExclamationTriangleIcon className="h-5 w-5 text-amber-300"/>
                  : <CheckBadgeIcon className="h-5 w-5 text-green-700 opacity-50"/>}
            </span></> : <span>-</span>
            }
          </div>)
          : null
        }
      </div>
      <div className="w-full sm:w-1/2 border-l-4 pl-4 flex flex-col gap-2">
        <div>
          <a className="text-gray-700 text-lg" href={"mailto:" + data.mail}
             target="_blank" rel="noopener noreferrer"
          >
            <EnvelopeIcon className="mr-3 h-5 w-5 inline-block" aria-hidden="true"/>
            {data.mail}
          </a>
        </div>
        <div>
          <a className="text-gray-700 text-lg" href={"tel:" + data.phone}>
            <PhoneIcon className="mr-3 h-5 w-5 inline-block" aria-hidden="true"/>
            {data.phone ? data.phone.replace('+39', '') : '-'}
          </a>
        </div>
      </div>
    </div>
  )
}

export default MemberInfo