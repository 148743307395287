import React, {useContext} from "react";

// Services
import {DataContext} from "@services/DataContext/DataContext";

// Table
import {tableIcons} from "@ui-components/TableIcons";
import TableStyle from "@ui-components/TableStyle";

import {COLUMNS, OPTIONS, LOCALIZATION} from "./services";
import {onRowUpdate, onRowDelete} from "./services";
import MaterialTable from "@material-table/core";

// Ui
import Placeholder from "@ui-components/Placeholder";


export default function ManageJob({push, filteredJobs, scopes}) {

  const dataContext = useContext(DataContext);
  const _onRowUpdate = (newData, oldData) => onRowUpdate(newData, oldData, dataContext, push);
  const _onRowDelete = (oldData) => onRowDelete(oldData, dataContext, push)
  return (
    <>
      {filteredJobs ?
        <TableStyle>
          <MaterialTable
            columns={COLUMNS}
            data={filteredJobs}
            icons={tableIcons}
            options={OPTIONS}
            localization={LOCALIZATION}
            editable={{
              ...(scopes.indexOf(`role:update`) >= 0
                ? {onRowUpdate: _onRowUpdate}
                : {}),
              ...(scopes.indexOf(`role:delete`) >= 0
                ? {onRowDelete: _onRowDelete}
                : {}),
            }}
          />
        </TableStyle> :
        <div className="flex flex-col w-full mb-4 gap-2">
          <Placeholder height="h-10" classNames="rounded-lg bg-am-700"/>
          <Placeholder height="h-96" classNames="rounded-lg"/>
        </div>
      }
    </>
  );
}
