import React, {useState} from "react";
import AuthenticationService from "@services/AuthenticationService";

import Tabs from "@ui-components/Tabs";
import {PageHeading} from "@ui-components/Container";

import {QuizList} from "@components/RefMan/Quiz/QuizList";
import {QuizSessionList} from "@components/RefMan/Quiz/Session";
import {QuestionListFrame} from "@components/RefMan/Quiz/Question/QuestionList";


const TABS = [
  {name: 'I tuoi quiz', value: 'personal_quiz'},
  {name: 'Quiz pubblici', value: 'public_quiz'},
  {name: 'Archivio domande', value: 'question_registry'},
]

const showCurrentTab = (tabIndex: number) => {
  switch (tabIndex) {
    case 0:
      return <div className="my-5">
        <QuizList showPrivateQuiz={true}/>
      </div>;
    case 1:
      return <div className="my-5">
        <QuizList showPrivateQuiz={false}/>
      </div>;
    case 2:
      return <div className="my-5">
        <QuestionListFrame selectable={false} showFilter={true} editable={true}/>
      </div>;
    default:
      throw new Error('tab index out of range')

  }
}


export function TechnicalAreaQuiz() {

  const scopes = AuthenticationService.getUserScopes();
  const viewMode: 'creator' | 'reader' = scopes.includes('quizzes:create') ? 'creator' : 'reader';

  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <>
      <PageHeading title="Test tecnici"/>
      {viewMode === 'creator' ?
        <div>
          <div className='grow'>
            <Tabs
              setActiveTab={setTabIndex}
              active={tabIndex}
              tabs={TABS}
            />
          </div>
          {showCurrentTab(tabIndex)}
        </div>
        : <QuizSessionList/>
      }
    </>
  );
}
