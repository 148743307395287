import React, { useContext, useEffect, useState } from 'react';

// Services
import AuthenticationService from "@services/AuthenticationService";
import { NotificationsContext } from "@ui-components/Notifications";
import { DataContext } from "@services/DataContext/DataContext";
import { getRoleFromScopes } from "@pages/Reports/Reports.lib";
import { api } from "@services/apiRequest";

// Ui
import { PageHeading } from "@ui-components/Container";
import Tabs from "@ui-components/Tabs";

// Components
import { ReportEvaluations } from "@pages/Reports/Sections/Evaluations";
import { ReportRatings } from "@pages/Reports/Sections/Ratings/Ratings";
import { ReportGames } from "@pages/Reports/Sections/Games";
import GroupSelection from "@utils/groupSelection";

import { Evaluations, VisibilityLevel } from "@pages/Reports/Reports.type";
import { ReportCounters } from "@pages/Reports/Sections/Counters";
import useLevels from "@services/hooks/useLevels";


function Reports() {

  const role = getRoleFromScopes();
  const dataContext: any = useContext(DataContext);
  const { push } = useContext(NotificationsContext);
  const groups = AuthenticationService.getUserGroups();
  const levels = useLevels({asObj: false});

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [visibilityLevel, setVisibilityLevel] = useState<VisibilityLevel | undefined>(undefined);

  // this data is needed for tabs 1,2,3, but not 0
  const [evaluations, setEvaluations] = useState<Evaluations[] | undefined>(undefined);

  useEffect(() => {
    api.get(`/roles/${localStorage.getItem('refman_role')}`)
      .then(({ data }) => {
        setVisibilityLevel(data.level)
      })
  }, [])

  useEffect(() => {
    if (['viewer', 'admin'].includes(role ?? '')) {
      api.get(`/evaluations/ratings?cod_group=${dataContext.group}`)
        .then(({ data }) => {
          setEvaluations(data)
        })
        .catch(() => push({ type: "error", title: "Errore durante il caricamento" }))
    }
  }, [dataContext.group])

  const showCurrentTab = (tabIndex: number) => {
    if (!role || !visibilityLevel) {
      return null
    }
    switch (tabIndex) {
      case 0:
        return <ReportGames
          role={role}
          levels={levels}
          group={dataContext.group}
          visibilityLevel={visibilityLevel}
        />;
      case 1:
        return <ReportRatings
          push={push}
          levels={levels}
          data={evaluations}
          group={dataContext.group}
          visibilityLevel={visibilityLevel}
        />;
      case 2:
        return <ReportEvaluations
          levels={levels}
          data={evaluations}
          group={dataContext.group}
          visibilityLevel={visibilityLevel}
        />;
      case 3:
        return <ReportCounters
          group={dataContext.group}
          data={evaluations}
          levels={levels}
          visibilityLevel={visibilityLevel}
        />;
      default:
        throw new Error('tab index out of range')

    }
  }

  return (
    <div className='reports__container'>
      <PageHeading
        title='Valutazioni'
        historyBackPath="_back"
        contentActions={groups.length > 1 ? <div className="mr-6 mt-2">
          <GroupSelection selectedGroup={dataContext.group} setSelectedGroup={dataContext.setGroup} />
        </div> : null}
      />
      {['viewer', 'admin'].includes(role ?? '') ?
        <div className="-mt-3 mb-5">
          <Tabs
            setActiveTab={setTabIndex}
            active={tabIndex}
            tabs={[
              { name: "Dettaglio gare" },
              { name: "Classifiche" },
              { name: "Elenco valutazioni" },
              { name: "Contatori" }
            ]}
          />
        </div> : null
      }
      {showCurrentTab(tabIndex)}
    </div>
  );
}

export default Reports;
