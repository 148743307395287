import React, {useContext, useEffect, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "@services/DataContext/DataContext";

// Ui
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/24/outline";
import Placeholder from "@ui-components/Placeholder";
import Alert from "@ui-components/Alert";

// Components
import {getDataComponent, parseResults} from "@components/RefMan/Reports/ReportsReader/ObserverReportsReader.lib";
import {getEvaluationsData} from "@components/RefMan/Reports/ReportsForm/ReportsForm.lib";
import {getQuestionnaire} from "@components/RefMan/Reports/reports.lib";

// Types
import {
  AnswersParsedType,
  ObserverReportsReaderType,
  ResultParsedType
} from "@components/RefMan/Reports/ReportsReader/ObserverReportsReader.type";
import {questionnaireType} from "@utils/questionnaires/type/questionnaire.type";
import {ResultsType} from "@components/RefMan/Reports/ReportsForm/ReportsForm.type";
import {EvaluationsResultsDataType} from "@components/RefMan/Reports/ReportsMatch/ObserverReportsMatch.type";


const ObserverReportsReader = ({reportData, memberData, role, setStatusList}: ObserverReportsReaderType) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [evaluation, setEvaluation] = useState<ResultsType | null>(null)
  const [questionnaires, setQuestionnaires] = useState<questionnaireType>()
  const [resultsParsed, setResultsParsed] = useState<any>()
  const {push} = useContext(NotificationsContext);
  const [selected, setSelected] = useState<number | null>(0);
  const dataContext: any = useContext(DataContext);

  /**
   * Load evaluation data on form loading
   */
  useEffect(() => {
    void (async () => {
      setIsLoading(true);
      let evaluationDataRequest: EvaluationsResultsDataType[] | null = null;

      if (role === "member" && reportData) {
        evaluationDataRequest = await getEvaluationsData(
          reportData.cod_game,
          reportData.observerIdFipCode,
          reportData.id_fip_code,
        )
      } else if (reportData && memberData) {
        evaluationDataRequest = await getEvaluationsData(
          reportData.cod_game,
          reportData.id_fip_code,
          memberData.id_fip_code
        )
      }
      if (evaluationDataRequest) {
        if (evaluationDataRequest?.[0]?.results) {
          const res = evaluationDataRequest[0]
          setEvaluation(res.results)
          if (role === 'member') {
            const newEvaluations = (dataContext.evaluations?.data || []).filter((i: any) => i.cod_game !== res.cod_game).concat([res])
            dataContext.setEvaluations({
              idFipCode: dataContext.evaluations.idFipCode,
              toRead: newEvaluations.filter((i: any) => !i.flg_read).length,
              data: newEvaluations
            });
            if (setStatusList) {
              setStatusList((prevState) => (prevState?.map(i => ({
                ...i, flg_read: i.cod_game === res.cod_game ? true : i.flg_read}))))
            }
          }
        }
      } else {
        push({title: "Errore nel recuperare i dati", type: "error"});
      }
      setIsLoading(false);
    })()
  }, [])

  useEffect(() => {
    if (evaluation && questionnaires) {
      setResultsParsed(parseResults(evaluation, questionnaires))
    }
  }, [evaluation, questionnaires]);

  /**
   * Retrieving questionnaire
   */
  useEffect(() => {
    (async () => {
      const results = await getQuestionnaire(reportData, role,
        role !== 'member' ? memberData ? memberData.role : "" : reportData ? reportData.role : "");
      setQuestionnaires(results);
    })();
  }, [reportData && memberData]);

  return !isLoading && resultsParsed ?
    <div className="observerreportsreader__section-container pt-5 px-4 pb-4">
      {Object.keys(resultsParsed).length === 0 ? <Alert title="Questo report va consultato su FOL"/> : null }
      {Object.values<any>(resultsParsed).map((section: ResultParsedType, resultsIndex: number) =>
        <div className="observerreportsreader__section mb-3 shadow-xl" key={resultsIndex}>
          <div
            className={"observerreportsreader__head border-x border-t rounded-tl-lg rounded-tr-lg p-4 bg-am-400 cursor-pointer font-bold text-black flex flex-row flex-nowrap justify-between"}
            onClick={() => {
              if (selected === resultsIndex) {
                setSelected(null)
              } else {
                setSelected(resultsIndex)
              }
            }}
          >
            {section.title}
            {selected !== resultsIndex ? <ChevronDownIcon className="h-5 w-5"/> : <ChevronUpIcon className="h-5 w-5"/>}
          </div>
          <div
            className={"observerreportsreader__body border-x border-b rounded-bl-lg rounded-br-lg transition-all p-3" + (resultsIndex !== selected ? " hidden" : "")}>
            {section.results.map((result: AnswersParsedType, sectionIndex: number) =>
              <div
                key={sectionIndex}
                className={"pt-3" + (sectionIndex < section.results.length - 1 ? " border-b pb-3" : "")}
              >
                {getDataComponent(result, sectionIndex)}
              </div>
            )}
          </div>
        </div>
      )}
    </div> : <Placeholder height='h-80'/>
}

export default ObserverReportsReader;