import React, { useContext, useEffect, useState } from 'react';

import AuthenticationService from '@services/AuthenticationService';
import { NotificationsContext } from '@ui-components/Notifications';
import useLevels from '@services/hooks/useLevels';

// Ui
import { ArrowPathIcon, InboxArrowDownIcon } from '@heroicons/react/24/outline';
import { PageHeading } from '@ui-components/Container';
import Placeholder from '@ui-components/Placeholder';

// Component
import { GamesLevelFilter, GamesPeriodFilter, GamesProvinceFilter, getData, gameDownloadExcel } from '@pages/Games/Games.lib';
import { getListItems } from '@pages/Nominations/Observers/Observers.lib';
import RefManList from '@components/RefMan/RefManList/RefManList';
import { MemberSearch } from '@pages/Members/Members.lib';

// Type
import { PaginatedGames, SelectedLevel, SelectedProvince } from './Games.type';
import { RefManListItemType } from '@components/RefMan/RefManList/RefManList.type';
import { useIntl } from 'react-intl';

let searchSelectTimeout: NodeJS.Timeout;

export function Games() {
  const { push } = useContext(NotificationsContext);
  const scopes = AuthenticationService.getUserScopes();
  const showTemp = scopes.includes('games:temp');
  const levels = useLevels({ asObj: false, onlyManaged: true });
  const intl = useIntl();

  let initialEndDate = new Date();
  initialEndDate.setDate(initialEndDate.getDate() + 20);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(initialEndDate);
  const [filterMember, setFilterMember] = useState<string>();
  const [filterGameNumber, setFilterGameNumber] = useState<string>();
  const [filterCity, setFilterCity] = useState<string>();
  const [filterTeam, setFilterTeam] = useState<string>();
  const [selectedLevels, setSelectedLevels] = useState<SelectedLevel[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<SelectedProvince[]>([]);
  const [data, setData] = useState<PaginatedGames | undefined>(undefined);
  const [offset, setOffset] = useState<number>(0);
  const limit = 5;
  const [itemsList, setItemsList] = useState<RefManListItemType[]>();
  const [excelDownloadLoading, setExcelDownloadLoading] = useState<boolean>(false);

  const [textFilters, setTextFilters] = useState({ game_number: '', city: '', team: '', member: '' });
  useEffect(() => {
    setTextFilters({
      game_number: filterGameNumber && filterGameNumber.length >= 2 ? filterGameNumber : '',
      city: filterCity && filterCity.length > 2 ? filterCity : '',
      team: filterTeam && filterTeam.length > 2 ? filterTeam : '',
      member: filterMember && filterMember.length > 2 ? filterMember : '',
    });
  }, [filterMember, filterCity, filterTeam, filterGameNumber]);

  /**
   * Handler for
   * - showTemp
   * - startDate
   * - endDate
   * - selectedLevels
   * - selectedProvinces
   */
  useEffect(() => {
    setData(undefined);
    void getData(showTemp, undefined, startDate, endDate, textFilters, selectedProvinces, selectedLevels, push, limit, offset, setData);
  }, [limit, offset, showTemp, startDate, endDate, selectedLevels, selectedProvinces]);

  /**
   * Handler for
   * - textFilters
   */
  useEffect(() => {
    if (searchSelectTimeout) {
      clearTimeout(searchSelectTimeout);
    }
    searchSelectTimeout = setTimeout(() => {
      void getData(showTemp, undefined, startDate, endDate, textFilters, selectedProvinces, selectedLevels, push, limit, offset, setData);
    }, 600);
  }, [textFilters]);

  useEffect(() => {
    if (data) {
      setItemsList(getListItems(intl, data.items, 'all', showTemp, false,undefined));
    }
  }, [data]);

  const handleGameDownloadExcelClick = async () => {
    setExcelDownloadLoading(true);
    const dataForExcel = await getData(showTemp, undefined, startDate, endDate, textFilters, selectedProvinces, selectedLevels, push, 1000, offset);
    gameDownloadExcel(dataForExcel?.items, intl, showTemp);
    setExcelDownloadLoading(false);
  }

  return (
    <>
      <PageHeading
        title='Gare'
        contentActions={
          data ? (
            <div
              className='mx-2 cursor-pointer'
              onClick={handleGameDownloadExcelClick}
            >
              {excelDownloadLoading ?
                <ArrowPathIcon className='w-7 animate-spin' /> :
                <InboxArrowDownIcon className='w-7' />}
            </div>
          ) : null
        }
      />
      {levels ? (
        <>
          <div className='flex flex-col sm:flex-row justify-between gap-3'>
            <div className='w-full sm:w-1/3'>
              <GamesPeriodFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setOffset={setOffset}
              />
            </div>
            <div className='w-full sm:w-1/3'>
              <GamesLevelFilter
                levels={levels}
                selectedLevels={selectedLevels}
                setSelectedLevels={setSelectedLevels}
                setOffset={setOffset}
              />
            </div>
            <div className='w-full sm:w-1/3'>
              <GamesProvinceFilter
                selectedProvinces={selectedProvinces}
                setSelectedProvinces={setSelectedProvinces}
                setOffset={setOffset}
              />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row justify-center gap-3'>
            <div className='w-full sm:w-1/4'>
              <MemberSearch
                label='Cerca gara'
                searchKey={filterGameNumber}
                setSearchKey={setFilterGameNumber}
                setOffset={setOffset}
              />
            </div>
            <div className='w-full sm:w-1/4'>
              <MemberSearch
                label='Cerca squadra'
                searchKey={filterTeam}
                setSearchKey={setFilterTeam}
                setOffset={setOffset}
              />
            </div>
            <div className='w-full sm:w-1/4'>
              <MemberSearch
                label='Cerca città'
                searchKey={filterCity}
                setSearchKey={setFilterCity}
                setOffset={setOffset}
              />
            </div>
            <div className='w-full sm:w-1/4'>
              <MemberSearch
                label='Cerca tesserato designato'
                searchKey={filterMember}
                setSearchKey={setFilterMember}
                setOffset={setOffset}
              />
            </div>
          </div>
        </>
      ) : (
        <div className='flex flex-row gap-10'>
          <Placeholder height='h-10' classNames='rounded-lg w-1/3' />
          <Placeholder height='h-10' classNames='rounded-lg w-1/3' />
          <Placeholder height='h-10' classNames='rounded-lg w-1/3' />
        </div>
      )}
      <div className='my-5'>
        <RefManList
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          total={data ? data.total : 0}
          containerId='observer-reports-list'
          items={itemsList}
          rowClassNames={['sm:grid', 'sm:grid-cols-5', 'sm:gap-3', '!cursor-default']}
          pagination='backend'
        />
      </div>
    </>
  );
}
