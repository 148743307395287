import { MembersListType, RatingsMembersListType } from "./Ratings.type";
import RatingsProgressBar from "../../../../components/ProgressBar/ProgressBar";

const RatingsMembersList = ({ membersList, setMemberOpen, members, numNominations }: RatingsMembersListType) => {

  return <div className="w-full max-w-2xl">
    <div className="text-center text-xl font-bold border-b-2 pb-2 px-2 mb-4">
      Tesserati
    </div>
    <table className="min-w-full">
      <thead></thead>
      <tbody>
      <tr className="font-bold text-sm">
        <td className="py-1.5 text-center">
          <div className="w-10 mr-3">#</div>
        </td>
        <td className="text-start">
          Tesserato
        </td>
        <td className="text-center">
          Gare
        </td>
        <td className="text-center px-3">
          Report
        </td>
        <td className="text-center">
          Media
        </td>
        <td className="text-center px-3">
          Quiz
        </td>
        <td className="text-center">
          Riunioni
        </td>
      </tr>
      {membersList && membersList.length > 0 &&
        membersList.map((member: MembersListType, index: number) =>
          <tr key={index} className="cursor-pointer hover:bg-gray-100" onClick={() => {
            setMemberOpen(member)
          }}>
            <td className="py-1.5 text-center">
              <div className="rounded-full border border-am-700 px-2 text-ellipsis text-center w-10 text-sm mr-3">
                {index + 1}°
              </div>
            </td>
            <td className="py-1.5 italic text-ellipsis truncate text-start text-sm">
              {member.name}
            </td>
            <td className="py-1.5 text-center">
              <div className="text-center font-bold">
                {numNominations[member.idFipCode] || '-'}
              </div>
            </td>
            <td className="py-1.5 text-center">
              <div className="text-center font-bold">
                {member.count}
              </div>
            </td>
            <td className="py-1.5 flex justify-center">
              <RatingsProgressBar
                max={member.average <= 10 ? 10 : 82}
                rating={member.average}
                classNames="w-40"
              />
            </td>
            <td className="py-1.5 text-center">
              <div className="text-center">
                {members[member.idFipCode]?.kpis?.avg_num_errors ?? '-'}
              </div>
            </td>
            <td className="py-1.5 text-center">
              <div className="text-center">
                {members[member.idFipCode]?.kpis?.num_presences ?? '-'}
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
}

export default RatingsMembersList;