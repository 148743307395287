import React, {useContext, useEffect, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {useIntl, FormattedMessage} from "react-intl";
import {api} from "@services/apiRequest";

// UI
import {PageHeading} from "../../Container";
import Tabs from "@ui-components/Tabs";
import Button from "../../Button";
import Modal from "../../Modal";

// Components
import DialogTicket from "./DialogTicket";
import FaqList from "./FaqList";
import TicketList from "@ui-components/pages/Support/TicketList";
import AuthenticationService from "@services/AuthenticationService";
import EditDialog from "@ui-components/pages/Support/EditDialog";


function Support() {
  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const [tabIndex, setTabIndex] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [showSuccessResponse, setShowSuccessResponse] = useState(false);
  const toggleOpenDialog = () => setShowAdd(!showAdd);

  const [showEditTicket, setShowEditTicket] = useState(undefined);

  const [ticketList, setTicketList] = useState();
  const scopes = AuthenticationService.getUserScopes();
  const isAdmin = scopes.includes('support:update');

  useEffect(() => {
    const fetchData = () => {
      api
        .get("/tickets")
        .then(({data: tickets}) => {
          setTicketList(tickets)
        })
        .catch(() => {
          setTicketList([])
          push({title: "Errore del server", type: "error"});
        });
    };

    if (ticketList === undefined) {
      fetchData();
    }

  }, [ticketList]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <PageHeading
        title={intl.formatMessage({id: "support"})}
        contentActions={
          <div className="flex flex-col items-center gap-5">
            <Button className="w-32" onClick={toggleOpenDialog}>
              <FormattedMessage id="button_faq"/>
            </Button>
          </div>
        }
      />
      <Tabs
        setActiveTab={setTabIndex}
        active={tabIndex}
        tabs={[{name: "I miei ticket"}, {name: "Domande frequenti"}]}
      />
      <div className="mt-4">
        {tabIndex === 1
          ? <FaqList push={push}/>
          : <TicketList ticketList={ticketList} isAdmin={isAdmin} setShowEditTicket={setShowEditTicket}/>
        }
      </div>
      <DialogTicket
        opened={showAdd}
        toggleOpen={toggleOpenDialog}
        setTicketList={setTicketList}
        setShowSuccessResponse={setShowSuccessResponse}
      />
      <Modal
        opened={showSuccessResponse}
        buttonText="Test Modal"
        title={intl.formatMessage({id: "thanks"})}
        description={intl.formatMessage({id: 'success_ticket_sent'})}
        onExit={() => {
          setShowSuccessResponse(false)
        }}
      />
      {showEditTicket ?
        <EditDialog
          toggleOpen={() => setShowEditTicket(undefined)}
          ticket={showEditTicket}
          setTicketList={setTicketList}
        /> : null
      }
    </div>
  );
}

export default Support;
