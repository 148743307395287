import {ObserverReportsReaderSelectType} from "./ObserverReportsReaderSelect.type";
import React from "react";

const ObserverReportsReaderSelect = ({result}: ObserverReportsReaderSelectType) => {
  return <div className="observerreportsreaderselect__container flex flex-col gap-y-2 p-2">
    <div className="observerreportsreaderselect__question text-lg font-semibold mb-3">
      {result.question.title}
    </div>
    <div className="observerreportsreaderselect__question ml-2">
      {result.value}
    </div>
  </div>
}

export default ObserverReportsReaderSelect