import {useContext, useEffect} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "../DataContext/DataContext";
import apiRequest from "../apiRequest";
import _ from "lodash";

const useLevels = (
  {
    onlyManaged = false,
    onlyWithTax = false,
    asObj = true,
    managedGroup = undefined
  }) => {
  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    let closed = false;
    const fetchData = async () => {
      try {
        const data = await apiRequest.get(`/entry/levels`);
        if (!closed) {
          const sorted = _.sortBy(data || [], i => i.level_order)
          dataContext.setLevels(sorted.reduce((obj, row) => _.set(obj, [row.cod_level], row), {}));
        }
      } catch (error) {
        console.log(error)
        dataContext.setLevels(false);
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if (dataContext.levels === null) {
      fetchData();
    }

    return () => {
      closed = true;
    };
  }, [dataContext.levels, push]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (!dataContext.levels) {
    return undefined
  }

  if (!onlyManaged && !managedGroup && asObj) {
    return dataContext.levels
  }

  let res = Object.values(dataContext.levels).filter(i => i.level_order < 80);
  if (onlyManaged) {
    res = res.filter(i => i.designator_level !== null || i.flg_model_basic_ref || i.flg_model_basic_udc)
  }
  if (onlyWithTax) {
    res = res.filter(i => i.amt_game_tax !== null)
  }
  if (managedGroup) {
    res = managedGroup === 'ref'
      ? res.filter(i => i.survey_model_ref)
      : res.filter(i => i.survey_model_udc && i.cod_level !== 'A1/M')
  }

  if (asObj) {
    return res.reduce((obj, row) => _.set(obj, [row.cod_level], row), {})
  }
  return res;

};

export default useLevels;
