import React, { useEffect, useState } from 'react';
import { RatingsEvaluationsType } from './Ratings.type';
import { getEvaluationsData } from './Ratings.lib';
import ObserverReportsReader from "@components/RefMan/Reports/ReportsReader/ObserverReportsReader";
import { ReportItemType } from "@components/RefMan/Reports/ReportsList/ObserverReportsList.type";
import { addObserverDataToReport } from "@components/RefMan/Reports/ReportsList/ObserverReportsList.lib";
import Placeholder from "@ui-components/Placeholder";
import { useIntl } from "react-intl";

const RatingsEvaluations = ({ evaluation, group }: RatingsEvaluationsType) => {
  const intl = useIntl();
  const [reportData, setReportData] = useState<ReportItemType | null>(null);

  useEffect(() => {
    (async () => {
      const data = await getEvaluationsData(evaluation.codGame, evaluation.idMember);
      if (data?.items.length) {
        const reportDataWithObs = addObserverDataToReport(data, String(evaluation.idMember), group)
        setReportData(reportDataWithObs.items[0]);
      }
    })();
  }, [evaluation]);

  return reportData ? <div className='w-full flex flex-col flex-nowrap justify-center pt-6'>
    <div className='text-center'>
      Gara <b>{reportData.game.game_number}</b> del <b>{intl.formatDate(reportData.game.dat_game, { year: "2-digit", day: "numeric", month: 'short' })}</b>
      <span className="mx-5">&#8226;</span>
      Osservatore: <b>{reportData.observerName}</b>
    </div>
    <div className='mt-4 h-full'>
      <ObserverReportsReader
        reportData={reportData}
        memberData={undefined}
        role='member'
      />
    </div>
  </div> : <Placeholder height="h-80" classNames="rounded-lg" />;
};

export default RatingsEvaluations;
