import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { Transition } from "@headlessui/react";
import uuid from "react-uuid";
import types from "./constants/status-types-config";

export const NotificationsContext = React.createContext();

const Notification = ({
                        confirmText = "OK",
                        dismissText = "ANNULLA",
                        onConfirm,
                        onDismiss,
                        fixed = false,
                        timeout = 4000,
                        title = "prova",
                        text = "",
                        type = "info",
                        id,
                      }) => {
  const context = useContext(NotificationsContext);
  const [show, setShow] = useState(false);

  const dismiss = useCallback(() => {
    if (onDismiss) {
      onDismiss();
    }
    setShow(false);
  }, [onDismiss]);

  const confirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    setShow(false);
  }, [onConfirm]);

  useEffect(() => {
    let timeoutId = null;
    setShow(true);
    if (!fixed) {
      timeoutId = setTimeout(() => {
        dismiss();
      }, timeout);
    }

    return () => {
      if (timeoutId) {
        dismiss();
        clearTimeout(timeoutId);
      }
    };
  }, [fixed, timeout, dismiss]);

  if (!types[type]) {
    throw new Error("missing notification type");
  }

  const {
    Icon,
    classes: { textRegular },
  } = types[type];

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={() => {
        context.remove(id);
      }}
    >
      <div
        className="max-w-md w-full bg-white shadow-lg rounded-3xl pointer-events-auto flex ring-1 ring-black ring-opacity-5 divide-x divide-gray-200">
        <div
          className={`flex ${
            text ? "items-start" : "items-center"
          } flex-1 w-0 p-4`}
        >
          <div className="shrink-0 mr-4">
            <Icon className={`h-6 w-6 ${textRegular}`} aria-hidden="true"/>
          </div>

          <div className="w-0 flex-1 flex items-center">
            <div className="w-full">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              {text ? (
                <p className="mt-1 text-sm text-gray-500">{text}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col divide-y divide-gray-200">
            <div className="h-0 flex-1 flex">
              <button
                className="w-full border border-transparent rounded-none rounded-tr-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-am-600 hover:text-am-500 focus:outline-none focus:z-10 focus:ring-2 focus:ring-am-500"
                onClick={confirm}
              >
                {confirmText}
              </button>
            </div>
            {onDismiss ? (
              <div className="h-0 flex-1 flex">
                <button
                  className="w-full border border-transparent rounded-none rounded-br-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-am-500"
                  onClick={dismiss}
                >
                  {dismissText}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Transition>
  );
};

const NotificationsList = ({ list }) => {
  if (!list.length) {
    return null;
  }

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-40"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {list.map((notification) => (
          <Notification key={notification.id} {...notification} />
        ))}
      </div>
    </div>
  );
};

const Notifications = ({ children }) => {
  const [list, setList] = useState([]);

  const push = useCallback((notification) => {
    setList((list) => [...list, { ...notification, id: uuid() }]);
  }, []);

  const remove = useCallback((id) => {
    setList((list) => list.filter((a) => a.id !== id));
  }, []);

  const value = useMemo(() => ({
    list,
    push,
    remove,
  }), [list, push, remove]);

  useEffect(() => {
    return () => {
      setList([]);
    };
  }, []);

  return (
    <NotificationsContext.Provider
      value={value}
    >
      {children}
      <NotificationsList list={list}/>
    </NotificationsContext.Provider>
  );
};

export default Notifications;
