import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import {NumQuizzesByQuestionIdSchema} from "@components/RefMan/Quiz/types";

export default function UpdateAlertQuestion(props: NumQuizzesByQuestionIdSchema) {
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Attenzione</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
                Questa domanda è stata utilizzata in <b>{props.num_finished}</b> quiz terminati, in <b>{props.num_in_progress}</b> in
                corso e in <b>{props.num_not_started}</b> quiz non terminati. Sei sicuro di voler modificare la domanda?
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}