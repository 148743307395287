import React from 'react';

import { CheckBadgeIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { CakeIcon, EnvelopeIcon, MagnifyingGlassIcon, PhoneIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import SearchSelect from '../../ui-components/SearchSelect';
import Placeholder from '@ui-components/Placeholder';
import PrivateImage from '@components/PrivateImage';
import Input from '@ui-components/Input';

import { SelectedCategory, SelectedProvince, CategoryFilterProps, ProvinceFilterProps, MemberSearchProps } from './Members.type';
import { Member } from '@services/types/member';
import { RefManListItemType, MembersKpiProps, MemberWithAge } from '@components/RefMan/RefManList/RefManList.type';
import { UseHistoryType } from '@components/RefMan/NominationCard/NominationCard.type';
import { FormatDateOptions, IntlShape } from 'react-intl';
import { downloadExcel } from '@utils/downloadExcel';
import ImageFromUrl from "@components/ImageFromUrl";

export function CategoryFilter({ categories, selectedCategories, setSelectedCategories }: CategoryFilterProps) {
  // @ts-ignore
  return (
    <SearchSelect
      label='Categorie'
      options={categories.map((i) => ({
        label: (
          <div className='flex flex-col'>
            <span className='text-xs'>{i.des_category}</span>
            <span className='font-bold'>{i.des_sub_category}</span>
          </div>
        ),
        value: i.id,
        data: i,
      }))}
      filterOption={(candidate: { data: SelectedCategory }, input: string) => {
        if (input) {
          const _data = candidate.data.data;
          const _label = _data.des_category + _data.des_sub_category + _data.cod_sub_category;
          return _label.toUpperCase().includes(input.toUpperCase());
        }
        return true;
      }}
      isMulti
      value={selectedCategories}
      onChange={(e: SelectedCategory[]) => {
        setSelectedCategories(e);
      }}
      loadOptions={undefined}
      CustomOption={undefined}
      minHeight={undefined}
    />
  );
}

export function ProvinceFilter({ members, selectedProvinces, setSelectedProvinces }: ProvinceFilterProps) {
  const provinces = Array.from(new Set(Object.values(members).map((i) => i.city.des_province))).sort();
  // @ts-ignore
  return (
    <SearchSelect
      label='Province'
      options={provinces.map((i) => ({ label: i, value: i }))}
      isMulti
      value={selectedProvinces}
      onChange={(e: SelectedProvince[]) => {
        setSelectedProvinces(e);
      }}
      loadOptions={undefined}
      CustomOption={undefined}
      minHeight={undefined}
    />
  );
}

export function MemberSearch({ searchKey, setSearchKey, label, setOffset }: MemberSearchProps) {
  // @ts-ignore
  return (
    <Input
      value={searchKey}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value);
        if (setOffset) {
          setOffset(0);
        }
      }}
      label={
        <div className='flex flex-row gap-2'>
          <MagnifyingGlassIcon className='h-4 w-4 mt-0.5 -mb-3' />
          <span>{label}</span>
        </div>
      }
      name={undefined}
      validation={undefined}
      required={undefined}
      errorMessage={undefined}
      showCalendar={undefined}
      onClear={undefined}
    />
  );
}

const today = new Date();
const dateFormat: FormatDateOptions = { day: 'numeric', month: 'short', year: 'numeric' };

export const getFilteredData = (
  members: Member[],
  selectedCategories: SelectedCategory[],
  selectedProvinces: SelectedProvince[],
  searchKey: string | undefined
): MemberWithAge[] | undefined => {
  if (!members) {
    return undefined;
  }

  if (!members.length) {
    return [];
  }

  let data = members
    .sort((a, b) => a.member.localeCompare(b.member))
    .map((i) => {
      const bday = new Date(i.dat_born);
      const daysOld = Math.floor((today.getTime() - bday.getTime()) / (1000 * 60 * 60 * 24));
      return { ...i, age: Math.floor(Number(daysOld / 365)) };
    });

  if (selectedCategories.length) {
    data = data.filter((i) => selectedCategories.map((c) => c.value).includes(i.id_category));
  }
  if (selectedProvinces.length) {
    data = data.filter((i) => selectedProvinces.map((c) => c.value).includes(i.city.des_province));
  }
  if (searchKey && searchKey?.length >= 3) {
    data = data.filter((i) => {
      const _key = i.member + String(i.id_fip_code) + i.city.city + i.mail + String(i.phone);
      return _key.toLowerCase().includes(searchKey.toLowerCase());
    });
  }

  return data;
};

const getCertificate = (intl: IntlShape, dat_certificate: string | undefined) => {
  if (!dat_certificate) {
    return '-';
  }
  const expire = new Date(dat_certificate);
  const days = Math.floor((today.getTime() - expire.getTime()) / (1000 * 60 * 60 * 24));
  return (
    <div className='flex flex-row gap-1 mt-1 items-center'>
      <span className='font-light text-xs'>Cert. {intl.formatDate(dat_certificate, dateFormat)}</span>
      <span className='font-bolder'>
        {days > 0 ? (
          <ExclamationCircleIcon className='h-5 w-5 text-red-300' />
        ) : days > -30 ? (
          <ExclamationTriangleIcon className='h-5 w-5 text-amber-300' />
        ) : (
          <CheckBadgeIcon className='h-5 w-5 text-green-700 opacity-50' />
        )}
      </span>
    </div>
  );
};

export const getListItems = (
  data: MemberWithAge[],
  showPrivateData: boolean,
  selectedGroup: 'ref' | 'udc',
  history: UseHistoryType,
  intl: IntlShape,
  picsUrls: {[key: string] : string}
): RefManListItemType[] | [] => {
  if (!data.length) {
    return [];
  }

  return data.map((member: MemberWithAge): RefManListItemType => {
    return {
      rowId: `member-${member.id_fip_code}`,
      rowOnClick: () => {
        showPrivateData ? history.push(`/members/${member.id_fip_code}`) : null;
      },
      rowElement: (
        <>
          <dt className='flex flex-row gap-2 items-center'>
            <div className='-my-1'>
              <MemberPicFromUrl
                signedUrl={picsUrls[member.id_fip_code]}
                dimensions='w-12 h-12 lg:w-16 lg:h-16'
              />
            </div>
            <div className='flex flex-col'>
              <span className='text-xs'>{member.id_fip_code}</span>
              <span className='font-bold'>{member.member}</span>
            </div>
          </dt>
          <dt className='flex flex-col mt-3 sm:mt-0'>
            <span className='font-light text-sm'>
              <i>{member.category.des_category}</i> - {member.category.des_sub_category}
            </span>
            <span className='font-light text-sm'>
              {member.city.city} ({member.city.cod_province})
            </span>
          </dt>
          <dt className='flex flex-col mt-3 sm:mt-0 gap-0.5'>
            <span className='font-light text-xs'>
              <a
                className='text-gray-700 italic'
                href={showPrivateData ? '' : 'mailto:' + member.mail}
                target={showPrivateData ? '' : '_blank'}
                rel='noopener noreferrer'
              >
                <EnvelopeIcon className='mr-1 -mt-0.5 h-3 w-3 inline-block' aria-hidden='true' />
                {member.mail}
              </a>
            </span>
            <span className='font-light text-sm'>
              <a
                className='text-gray-700 italic'
                href={showPrivateData ? '' : 'tel:' + member.phone}
                // target="_blank"
                // rel="noopener noreferrer"
              >
                <PhoneIcon className='mr-1 -mt-0.5 h-3 w-3 inline-block' aria-hidden='true' />
                {member.phone}
              </a>
            </span>
          </dt>
          <dt className={showPrivateData ? 'flex flex-col mt-3 sm:mt-0' : 'hidden mt-3 sm:mt-0'}>
            <span className='text-sm'>
              <CakeIcon className='mr-1 -mt-1 h-4 w-4 inline-block' aria-hidden='true' />
              {intl.formatDate(member.dat_born, dateFormat)}
              {selectedGroup === 'ref' ? ` (${member.age} anni)` : ''}
            </span>
            <span className='text-sm'>{selectedGroup === 'ref' ? getCertificate(intl, member.dat_certificate) : `${member.age} Anni`}</span>
          </dt>
          <dt className={showPrivateData ? 'flex flex-col mt-3 sm:mt-0' : 'hidden mt-3 sm:mt-0'}>
            <div className='text-sm flex flex-row font-medium gap-3'>
              <div className='text-am-700'>
                <span className='w-7 h-7 inline-flex items-center justify-center border rounded-full mr-1 font-bold border-am-700 bg-am-700 bg-opacity-10'>
                  {member.kpis.num_game_acc}
                </span>
                {member.kpis.num_game_acc === 1 ? 'gara' : 'gare'}
              </div>
              {member.kpis.num_game_rif > 0 ? (
                <div className='text-red-700'>
                  <span className='w-7 h-7 inline-flex items-center justify-center border rounded-full mr-1 font-bold border-red-700 bg-red-700 bg-opacity-10'>
                    {member.kpis.num_game_rif}
                  </span>
                  {member.kpis.num_game_rif === 1 ? 'rifiuto' : 'rifiuti'}
                </div>
              ) : null}
            </div>
            <div className='text-sm flex flex-row font-medium gap-3 mt-2'>
              <div className='text-am-700'>
                Riunioni: <b>{member.kpis.num_presences}</b>
              </div>
              {member.kpis.avg_num_errors ? <div className='text-red-700'>Media errori: {getAvgString(intl, member.kpis.avg_num_errors)}</div> : null}
            </div>
          </dt>
        </>
      ),
    };
  });
};

const getAvgString = (intl: IntlShape, value?: number) => {
  return value ? intl.formatNumber(value, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : '-';
};

export function MembersKpi({ data, intl }: MembersKpiProps) {
  const numMembers = data.length;
  const numActives = data.filter((i) => i.kpis.num_game_acc > 0).length;
  const avgAge = data.reduce((acc, i) => acc + i.age, 0) / numMembers;
  const avgAcc = data.reduce((acc, i) => acc + i.kpis.num_game_acc, 0) / numMembers;
  const avgRif = data.reduce((acc, i) => acc + i.kpis.num_game_rif, 0) / numMembers;

  const kpi = (title: string, value: string | number) => (
    <div className='flex flex-col border-l-4 pl-4'>
      <span className='text-sm'>{title}</span>
      <span className='font-bold text-xl'>{value}</span>
    </div>
  );

  return (
    <div className='hidden sm:grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-5'>
      {kpi('Tesserati', numMembers)}
      {kpi('Attivi', numActives)}
      {kpi('Età media', getAvgString(intl, avgAge))}
      {kpi('Media gare', getAvgString(intl, avgAcc))}
      {kpi('Media rifiuti', getAvgString(intl, avgRif))}
    </div>
  );
}

export function MemberPic({ idFipCode, dimensions = 'w-48 h-48 lg:w-56 lg:h-56' }: { idFipCode: number; dimensions?: string }) {
  const waitingContent = (
    <div className={`flex w-full justify-center ${dimensions}`}>
      <Placeholder classNames={`mb-0 rounded-full ${dimensions} p-1`} />
    </div>
  );

  if (!idFipCode) {
    return waitingContent;
  }
  return (
    <div className='flex w-full justify-center'>
      {/*@ts-ignore*/}
      <PrivateImage
        image_url={`members/${idFipCode}.png`}
        tag='div'
        className={`${dimensions} bg-white rounded-full shadow bg-cover`}
        //@ts-ignore
        altReturn={<UserCircleIcon className={`${dimensions} opacity-20`} />}
        //@ts-ignore
        waitingContent={waitingContent}
      />
    </div>
  );
}


export function MemberPicFromUrl({ signedUrl, dimensions = 'w-48 h-48 lg:w-56 lg:h-56' }: { signedUrl: string; dimensions?: string }) {
  return (
    <div className='flex w-full justify-center'>
      {/*@ts-ignore*/}
      <ImageFromUrl
        signedUrl={signedUrl}
        className={`${dimensions} bg-white rounded-full shadow bg-cover`}
        //@ts-ignore
        altReturn={<UserCircleIcon className={`${dimensions} opacity-20`} />}
      />
    </div>
  );
}

export const memberDownloadExcel = (filteredData: Member[], intl: IntlShape) => {
  downloadExcel(
    filteredData.map((i) => ({
      id_fip_code: i.id_fip_code,
      lastname: i.lastname,
      firstname: i.firstname,
      mail: i.mail,
      phone: i.phone,
      dat_born: intl.formatDate(i.dat_born, { day: '2-digit', month: '2-digit', year: 'numeric' }),
      dat_certificate: intl.formatDate(i.dat_certificate, { day: '2-digit', month: '2-digit', year: 'numeric' }),
      city: i.city.city,
      cod_province: i.city.cod_province,
      des_sub_category: i.category.des_sub_category,
    })),
    [
      { field: 'id_fip_code', title: 'Tessera' },
      { field: 'lastname', title: 'Cognome' },
      { field: 'firstname', title: 'Nome' },
      { field: 'des_sub_category', title: 'Categoria' },
      { field: 'city', title: 'Città' },
      { field: 'cod_province', title: 'Provincia' },
      { field: 'dat_born', title: 'Data di nascita' },
      { field: 'dat_certificate', title: 'Scadenza certificato' },
      { field: 'mail', title: 'Email' },
      { field: 'phone', title: 'Cellulare' },
    ],
    'tesserati'
  );
};
