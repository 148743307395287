import React, {useState} from "react";

// Components
import {getBgColor, getMemberGroupOptions} from "@pages/Reports/Reports.lib";
import {GamesLevelFilter} from "@pages/Games/Games.lib";
import SearchSelect from "@ui-components/SearchSelect";

// Ui
import Placeholder from "@ui-components/Placeholder";

// Types
import {VisibilityLevel, MemberGroup, Evaluations, Counter} from "@pages/Reports/Reports.type";
import {SelectedLevel} from "@pages/Games/Games.type";
import {LevelSchema} from "@services/types/level";
import {Member} from "@services/types/member";
import Alert from "@ui-components/Alert";


export const ReportCounters = (
  {
    data,
    group,
    levels,
    visibilityLevel
  }: {
    group: 'ref' | 'udc',
    levels: LevelSchema[],
    data: Evaluations[] | undefined,
    visibilityLevel: VisibilityLevel
  }) => {


  const [memberGroup, setMemberGroup] = useState<MemberGroup[] | undefined>(undefined);
  const [selectedLevels, setSelectedLevels] = useState<SelectedLevel[]>([]);

  const currentCategories = (memberGroup ?? []).reduce((acc: string[], i) => acc.concat(i.cod_sub_categories), [])
  const filteredData = data ? data.filter((i: Evaluations) => !memberGroup || !memberGroup.length || currentCategories.includes(i.member.category.cod_sub_category)) : []

  const currentLevels = data ? Array.from(new Set(data)).map(i => i.game.cod_level) : []
  const currentObservers = filteredData.reduce((acc: Member[], i) => {
    if (!acc.find((item) => item.id_fip_code === i.id_observer)) {
      acc.push(i.observer)
    }
    return acc
  }, []).sort((a, b) => a.member.localeCompare(b.member))

  const counterData = Object.values(filteredData.filter(i =>
    (!selectedLevels.length || selectedLevels.map(i => i.value).includes(i.game.cod_level))
  ).reduce((acc: { [k: number]: Counter }, i: Evaluations) => {
    if (acc[i.id_member]) {
      acc[i.id_member][i.id_observer] = (acc[i.id_member][i.id_observer] ?? 0) + 1
      acc[i.id_member][-1] = (acc[i.id_member][-1] ?? 0) + 1
    } else {
      acc[i.id_member] = {
        id_member: i.id_member,
        tag: i.member.tag,
        [i.id_observer]: 1,
        [-1]: 1
      }
    }
    return acc
  }, {})).sort((a, b) => a.tag.localeCompare(b.tag))


  return <div>
    {data && levels && counterData ?
      <>
        <div className="flex flex-col sm:flex-row justify-between gap-2 sm:gap-6">
          <div className="w-full z-50">
            {/*@ts-ignore*/}
            <SearchSelect
              label="Gruppo"
              isMulti
              value={memberGroup}
              options={getMemberGroupOptions(group, visibilityLevel)}
              onChange={(v: MemberGroup[]) => {
                setMemberGroup(v)
              }}
            />
          </div>
          <div className="w-full z-50">
            <GamesLevelFilter
              levels={levels.filter((i: LevelSchema) => currentLevels.includes(i.cod_level))}
              selectedLevels={selectedLevels}
              setSelectedLevels={setSelectedLevels}
            />
          </div>
        </div>
        {counterData.length ?
          <>
            <div className="flex justify-center my-4">
              <div className="px-3 py-2 border rounded-2xl">
                <b className="text-xl">{counterData.reduce((acc, i) => acc + i[-1], 0)}</b> valutazioni
              </div>
            </div>
            <div className="overflow-x-auto flex flex-col h-70vh my-5">
              <div className="inline-block min-w-full pb-2 align-middle flex-grow overflow-auto">
                <table className="">
                  <thead className="sticky top-0 z-30">
                  <tr className="divide-x divide-gray-100 bg-white border-b">
                    <th scope="col" className="sticky bg-white left-0 py-3.5 pl-4 sm:pl-6 z-10">
                    </th>
                    <th scope="col" className="bg-gray-100 whitespace-nowrap h-28 text-xs font-semibold px-2 align-bottom pb-4">
                      <div className="-rotate-90 w-6">TOTALE</div>
                    </th>
                    {currentObservers.map(o =>
                      <th key={o.id_fip_code} scope="col" className="whitespace-nowrap h-28 text-xs font-semibold px-2 align-bottom pb-4">
                        <div className="-rotate-90 w-6">{o.tag}</div>
                      </th>
                    )}
                  </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                  {counterData.map((i) => (
                    <tr key={i.id_member} className="divide-x divide-gray-100">
                      <td className="sticky left-0 z-20 bg-white text-sm font-light text-gray-900">
                        <div>
                          <div className="whitespace-nowrap pl-4 pr-3 sm:pl-6">
                        <span className="block font-bold">
                          {i.tag}
                        </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-sm bg-gray-100 font-bold">
                        <div className="text-center">{i[-1] ?? 0}</div>
                      </td>
                      {currentObservers.map(o =>
                        <td
                          className={`p-1 text-sm bg-am-700 ${getBgColor(i[o.id_fip_code])}`}
                          key={o.id_fip_code}
                        >
                          <div className="flex flex-wrap justify-center gap-1 items-center">
                            {i[o.id_fip_code] ?? ''}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </> : <div className="mt-5">
            <Alert title="Non sono presenti valutazioni" />
          </div>
        }
      </> :
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-10">
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
        </div>
        <Placeholder height="h-72" classNames="rounded-lg"/>
      </div>
    }
  </div>
}