import React, {useContext, useEffect, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {useQuiz} from "@components/RefMan/Quiz/hooks/useQuiz";
import {useHistory} from "react-router-dom";
import {useIntl} from "react-intl";

// Components
import {QuizCreateModal} from "@components/RefMan/Quiz/QuizManagement/Sections";
import {getListItems} from "@components/RefMan/Quiz/QuizList/QuizList.lib";
import {QuizFilters} from "@components/RefMan/Quiz/QuizFilters";

// Ui
import RefManList from "@components/RefMan/RefManList/RefManList";
import {QuizPlaceholder} from "@utils/placeholders";
import Button from "@ui-components/Button";

// Types
import {
  QuizListProps,
  QuizFilterType,
  QuizListPayloadType,
  QuizListPaginatedBaseResponseType
} from "@components/RefMan/Quiz/types";


export function QuizList({showPrivateQuiz}: QuizListProps) {

  const intl = useIntl();
  const history = useHistory();
  const {push} = useContext(NotificationsContext);


  const [filters, setFilters] = useState<QuizFilterType>({
    selectedStatus: null,
    selectedMember: null,
    searchTitle: null,
    selectedTags: [],
    limit: 10,
    offset: 0,
  });
  const [offset, setOffset] = useState<number>(0);
  const [quiz, setQuizList] = useState<QuizListPaginatedBaseResponseType | undefined>();

  const [showCreateQuiz, setShowCreateQuiz] = useState<boolean>(false);

  const {getAllQuiz} = useQuiz();


  useEffect(() => {
    setFilters({...filters, offset: offset});
  }, [offset]);

  useEffect(() => {
    const payload: QuizListPayloadType = {
      id_creator: filters.selectedMember?.value ? filters.selectedMember.value : null,
      des_title: filters.searchTitle ? filters.searchTitle : null,
      cod_status: filters.selectedStatus?.value ? filters.selectedStatus.value : null,
      tags: filters.selectedTags ? filters.selectedTags.map((e) => e.value) : [],
      flg_private: showPrivateQuiz,
      dat_creation: null,
      ordering: null,
      offset: filters.offset,
      limit: filters.limit,
    }

    getAllQuiz(payload)
      .then((r) => setQuizList(r))
      .catch(() => push({type: 'error', title: 'Qualcosa è andato storto'}));
  }, [filters, showPrivateQuiz]);


  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-8 items-center pt-2 pb-5">
        <QuizFilters filter={filters} setFilters={setFilters} showPrivateQuiz={showPrivateQuiz}/>
        {showPrivateQuiz &&
          <div className='flex justify-center items-center'>
            <Button onClick={() => setShowCreateQuiz(true)}>
              <p className='text-xs'>Crea un nuovo quiz</p>
            </Button>
          </div>
        }
      </div>
      {quiz ?
        <RefManList
          limit={filters.limit}
          containerId="observer-reports-list"
          items={getListItems(quiz, history, intl)}
          rowClassNames={["sm:grid", "sm:grid-cols-4", "items-center", "divide-y sm:divide-y-0"]}
          pagination='backend'
          offset={filters.offset}
          setOffset={setOffset}
          total={quiz ? quiz.total : 0}
        />
        : <QuizPlaceholder/>
      }
      {showCreateQuiz && <QuizCreateModal onExit={() => setShowCreateQuiz(false)}/>}
    </div>
  )
}