import RadioGroup from "../ui-components/RadioGroup";
import React from "react";

function GroupSelection(
  {selectedGroup, setSelectedGroup} :
    {selectedGroup: 'ref' | 'udc', setSelectedGroup: React.Dispatch<React.SetStateAction<'ref' | 'udc'>>}
) {
  return (
    <div className="-mt-2">
      <RadioGroup
        id="groupSelection"
        horizontal
        options={[
          {value: "ref", name: "Arbitri"},
          {value: "udc", name: "UdC"},
        ]}
        currentValue={selectedGroup}
        onChange={(value: 'ref' | 'udc') => {
          setSelectedGroup(value);
        }}
      />
    </div>
  )
}

export default GroupSelection;