import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { EvaluationType, RatingsMembersModalType } from './Ratings.type';
import RatingsChart from './RatingsChart';
import RatingsEvaluations from './RatingsEvaluations';

const RatingsMembersModal = ({ memberOpen, setMemberOpen, group }: RatingsMembersModalType) => {
  const [evaluation, setEvaluation] = useState<EvaluationType | null>(null);

  return (
    <Transition.Root show={memberOpen !== null} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        onClose={() => {
          setMemberOpen(null);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>
        {memberOpen && (
          <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
            <div className='flex w-full h-full justify-center p-4 text-center sm:items-center sm:p-0' >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel style={{ height: "70vh" }}
                  className='flex flex-col gap-4 h-full w-full relative transform overflow-hidden rounded-lg bg-white pt-5 text-left shadow-xl transition-all my-8 sm:p-6 mx-6 lg:mx-0 lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl'>
                  <Dialog.Title as='h3' className='flex-none text-base font-semibold leading-6 text-center text-gray-900'>
                    {memberOpen.name}
                  </Dialog.Title>
                  <div className='w-full h-full flex flex-row flex-nowrap justify-center pb-24'>
                    <div className='w-full flex flex-row flex-nowrap justify-center overflow-x-hidden overflow-y-auto'>
                      {evaluation === null && <RatingsChart data={memberOpen} setEvaluation={setEvaluation} />}
                      {evaluation !== null && <RatingsEvaluations evaluation={evaluation} group={group} />}
                    </div>
                  </div>
                  <div className='w-full absolute bottom-5 bg-white text-center'>
                    {evaluation === null && (
                      <button
                        type='button'
                        className='inline-flex w-40 justify-center rounded-md bg-am-200 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 hover:text-gray-700'
                        onClick={() => {
                          setMemberOpen(null);
                        }}
                      >
                        Chiudi
                      </button>
                    )}
                    {evaluation !== null && (
                      <button
                        type='button'
                        className='inline-flex w-40 justify-center rounded-md bg-am-200 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 hover:text-gray-700'
                        onClick={() => {
                          setEvaluation(null);
                        }}
                      >
                        Indietro
                      </button>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        )}
      </Dialog>
    </Transition.Root>
  );
};

export default RatingsMembersModal;
