import React from "react";
import {useIntl} from "react-intl";
import {getCardLabels, timeStampFormat} from "../Absences.lib";
import {AbsenceGroupCardPropsType} from "./AbsenceGroupCard.type";
import {PencilIcon, TrashIcon, UserIcon, UserPlusIcon} from "@heroicons/react/24/outline";


export function AbsenceGroupCard({
                                   absenceReason,
                                   membersByUserId,
                                   setUpdateReason,
                                   setDeleteReason,
                                   setAssignReason,
                                   roles
                                 }: AbsenceGroupCardPropsType) {

  const intl = useIntl();
  const {reason, timeList, period} = getCardLabels(absenceReason, intl)
  let metadata = "Assegnare l'indisponibilità!"
  if (absenceReason.absences?.length) {
    const item = absenceReason.absences
      .sort((a, b) =>
        (a.tms_update ?? '').localeCompare((b.tms_update) ?? '')).reverse()[0]
    const insertedBy = `${membersByUserId[item.id_added_user]} (${roles[item.id_added_role]['description']})`
    metadata = `Aggiornata il ${intl.formatDate(item.tms_update, timeStampFormat)} da ${insertedBy}`
  }

  return (
    <div key={absenceReason.id} className="p-3 sm:grid sm:grid-cols-8 sm:gap-4 sm:px-6">
      <dt className="sm:col-span-3 flex flex-row gap-4 text-gray-900 items-center">
        <div className="cursor-pointer text-xl sm:text-2xl font-bold w-20">
          <span className="rounded-full border py-1 px-2">
            {absenceReason.absences?.length}<UserIcon className="h-4 w-4 inline-block -mt-0.5 ml-1"/>
          </span>
        </div>
        <div className="text-sm font-medium flex flex-col">
          <span>{reason}{timeList.filter(i => i).length ? `, ${timeList.join(' ')}` : ''}</span>
          <span className="font-bold">{period.join(' ')}</span>
        </div>
      </dt>
      <dd className="mt-1 text-sm italic text-gray-700 sm:col-span-2 sm:mt-0 flex flex-col justify-center">
        {absenceReason.des_absence}
      </dd>
      <dd
        className="mt-1 text-sm text-gray-700 sm:col-span-3 mt-3 sm:mt-0 flex flex-col sm:justify-between sm:items-end">
        <span className="text-xxs sm:text-end leading-3">{metadata}</span>
        <span className="font-medium mt-2 flex flex-row divide-x divide-gray-200">
          <span
            className="pr-2 text-am-700 cursor-pointer hover:font-bold"
            onClick={() => setAssignReason(absenceReason)}
          >
            <UserPlusIcon className="h-3 w-3 inline-block -mt-0.5"/> Assegna
          </span>
          <span
            className="px-2 text-am-700 cursor-pointer hover:font-bold"
            onClick={() => setUpdateReason(absenceReason)}
          >
            <PencilIcon className="h-3 w-3 inline-block -mt-0.5"/> Aggiorna
          </span>
          <span
            className="pl-2 text-am-700 cursor-pointer hover:font-bold"
            onClick={() => setDeleteReason(absenceReason)}
          >
            <TrashIcon className="h-3 w-3 inline-block -mt-0.5"/> Elimina
          </span>
        </span>
      </dd>
    </div>
  )
}
