import React, {useContext, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "@services/DataContext/DataContext";
import {api} from "@services/apiRequest";

// Ui
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import {DeleteAbsenceDialogPropsType} from "./Absences.type";


export default function DeleteAbsenceDialog({
                                              onExit,
                                              deleteAbsence,
                                              deleteReason,
                                              setReasons
                                            }: DeleteAbsenceDialogPropsType) {

  const dataContext = useContext(DataContext);
  const {push} = useContext(NotificationsContext);
  const [deleting, setDeleting] = useState<boolean>(false)

  const sendDelete = () => {
    if (deleteAbsence || deleteReason) {
      setDeleting(true)
      const url = deleteAbsence
        ? `/absences/${deleteAbsence.id_fip_code}/${deleteAbsence.id_absence_reason}`
        : deleteReason ? `/absences/reason/${deleteReason.id}` : ''
      api.delete(url)
        .then(() => {
          // @ts-ignore
          dataContext.setAbsences({idFipCode: undefined, data: null})
          if (setReasons) {
            setReasons(undefined)
          }
          push({title: "Indisponibilità eliminata con successo", type: "success"});
          onExit()
        })
        .catch((err) => {
          console.log(err)
          push({title: "Errore del server", type: "error"});
        })
        .finally(() => setDeleting(false))
    }
  }

  return (
    <Modal
      onExit={onExit}
    >
      <div className="m-5">
        <h1 className="font-bold sm:text-xl">Confermi di voler eliminare questa indisponibilità?</h1>
        <h3 className="text-xs sm:text-sm my-3">Non sarà possibile annullare l'operazione.</h3>
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button onClick={sendDelete} submitting={deleting}>
          Conferma
        </Button>
      </div>
    </Modal>
  );
}
