import React, { useEffect, useState } from 'react';

// Services
import useLevels from '@services/hooks/useLevels';

// Components
import { getMemberGroupOptions } from '@pages/Reports/Reports.lib';
import { GamesLevelFilter } from '@pages/Games/Games.lib';
import SearchSelect from '@ui-components/SearchSelect';
import { getMemberOptions } from '@utils/index';

import Placeholder from '@ui-components/Placeholder';
import { MemberGroup } from '@pages/Reports/Reports.type';
import { SelectedLevel } from '@pages/Games/Games.type';
import { LevelSchema } from '@services/types/level';
import { Member } from '@services/types/member';
import { MembersListType, ObserversListType, ReportRatingsPropsType } from './Ratings.type';
import {
  getCurrentLevels,
  getCurrentObservers,
  getFilteredData,
  getEvaluationsDataByMemberGroups,
  getListsData,
} from './Ratings.lib';
import RatingsMembersList from './RatingsMembersList';
import RatingsObserversList from './RatingsObserversList';
import RatingsMembersModal from './RatingsMembersModal';
import useMembers from "@services/hooks/useMembers";
import {ProvinceFilter} from "@pages/Members/Members.lib";
import {SelectedProvince} from "@pages/Members/Members.type";
import {api} from "@services/apiRequest";

export const ReportRatings = ({ push, data, group, visibilityLevel }: ReportRatingsPropsType) => {
  const [memberGroup, setMemberGroup] = useState<MemberGroup | undefined>(undefined);
  const [selectedLevels, setSelectedLevels] = useState<SelectedLevel[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<SelectedProvince[]>([]);
  const [selectedObservers, setSelectedObservers] = useState<Member[]>([]);
  const [currentLevels, setCurrentLevels] = useState<string[]>([]);
  const [currentObservers, setCurrentObservers] = useState<Member[]>([]);
  const [observersList, setObserversList] = useState<ObserversListType[]>();
  const [membersList, setMembersList] = useState<MembersListType[]>();
  const [memberOpen, setMemberOpen] = useState<MembersListType | null>(null)
  const levels = useLevels({ asObj: false, onlyManaged: true });
  const members = useMembers({});

  const [numNominations, setNumNominations] = useState<{[key: number]: number} | undefined>(undefined)

  useEffect(() => {
    if (levels && currentLevels) {
      const _levels = selectedLevels.length
        ? selectedLevels.map(i => i.value)
        : levels.filter((i: LevelSchema) => currentLevels.includes(i.cod_level)).map((i: LevelSchema) => i.cod_level)
      if (_levels.length) {
        api.post('/nominations/counter', {cod_group: group, levels: _levels})
        .then(({ data }) => {
          setNumNominations(data)
        })
        .catch(() => push({ type: "error", title: "Errore durante il caricamento" }))
      }
    }
  }, [group, selectedLevels, currentLevels]);

  // Handle Data
  useEffect(() => {
    const filteredData = getFilteredData(data, memberGroup, selectedLevels, selectedObservers, selectedProvinces)
    const [observersList, membersList] = getListsData(filteredData || []);
    setObserversList(observersList);
    setMembersList(membersList);
  }, [data, memberGroup, selectedLevels, selectedObservers, selectedProvinces])


  // Levels
  useEffect(() => {
    const eEvaluationsDataByMemberGroups = data && memberGroup ?
      getEvaluationsDataByMemberGroups(data, memberGroup) : [];
    const currentLevels = getCurrentLevels(eEvaluationsDataByMemberGroups || []);
    setCurrentLevels(currentLevels)
  }, [data, memberGroup])


  // Observers
  useEffect(() => {
    const eEvaluationsDataByMemberGroups = data && memberGroup ?
      getEvaluationsDataByMemberGroups(data, memberGroup) : [];
    const currentObservers = getCurrentObservers(eEvaluationsDataByMemberGroups || [])
    setCurrentObservers(currentObservers)
  }, [data, selectedLevels])

  return (
    <div>
      {data && levels && members ? (
        <div className='flex flex-col sm:flex-row justify-between gap-2 sm:gap-6'>
          <div className='w-full'>
            <SearchSelect
              label='Gruppo'
              value={memberGroup}
              options={getMemberGroupOptions(group, visibilityLevel)}
              onChange={(v: MemberGroup) => {
                setMemberGroup(v);
                setSelectedLevels([])
                setSelectedProvinces([])
                setSelectedObservers([])
              }}
              loadOptions={undefined}
              isMulti={undefined}
              CustomOption={undefined}
              minHeight={undefined}
            />
          </div>
          <div className={'w-full' + (memberGroup ? '' : ' opacity-40 pointer-events-none cursor cursor-not-allowed')}>
            <ProvinceFilter
              members={members}
              selectedProvinces={selectedProvinces}
              setSelectedProvinces={setSelectedProvinces}
            />
          </div>
          <div className={'w-full' + (memberGroup ? '' : ' opacity-40 pointer-events-none cursor cursor-not-allowed')}>
            <GamesLevelFilter
              levels={levels.filter((i: LevelSchema) => currentLevels.includes(i.cod_level))}
              selectedLevels={selectedLevels}
              setSelectedLevels={setSelectedLevels}
            />
          </div>
          <div className={'w-full' + (memberGroup ? '' : ' opacity-40 pointer-events-none cursor cursor-not-allowed')}>
            <SearchSelect
              label='Osservatori'
              isMulti
              value={selectedObservers}
              options={getMemberOptions(currentObservers, false)}
              onChange={(v: Member[]) => {
                setSelectedObservers(v);
              }}
              loadOptions={undefined}
              CustomOption={undefined}
              minHeight={undefined}
            />
          </div>
        </div>
      ) : (
        <div className='flex flex-row gap-10'>
          <Placeholder height='h-10' classNames='rounded-lg' />
          <Placeholder height='h-10' classNames='rounded-lg' />
          <Placeholder height='h-10' classNames='rounded-lg' />
          <Placeholder height='h-10' classNames='rounded-lg' />
        </div>
      )}
      {memberGroup && numNominations ?
        <div className='flex flex-row flex-wrap justify-around my-8 gap-y-8'>
          <RatingsMembersList
            membersList={membersList}
            setMemberOpen={setMemberOpen}
            members={members}
            numNominations={numNominations}
          />
          <RatingsObserversList observersList={observersList} />
        </div> :
        <div className="italic text-center text-lg mt-16">Seleziona un gruppo</div>}
      <RatingsMembersModal memberOpen={memberOpen} setMemberOpen={setMemberOpen} group={group} />
    </div>
  );
};
