import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, getCategoryColor, getXYChart} from "@components/Charts/commons";

const getChartHigh = (n) => {
  switch (true) {
    case (n < 2):
      return 'h-24'
    case (n < 4):
      return 'h-48'
    case (n < 6):
      return 'h-72'
    default:
      return 'h-96'
  }
}


function CategoryBarChart({id, data}) {

  useLayoutEffect(
    () => {

      if (!data)
        return

      const _data = data.map(i => ({...i, color: {fill: getCategoryColor(i.cod_category, i.cod_sub_category)}}))

      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)])
      root.locale = am5locales_it_IT;
      root.numberFormatter.set("numberFormat", "#,###.");

      // Create chart
      let chart = getXYChart(root, {layout: root.verticalLayout})
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);


      // Create axes
      // CategoryAxis: is the only axis type that requires its own data as well as data field set up.
      let yAxis = chart.yAxes.push(
        chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "cod_sub_category",
            renderer: am5xy.AxisRendererY.new(root, {
              minGridDistance: 0
            }),
          })
        ))

      yAxis.data.setAll(_data);

      let yRenderer = yAxis.get("renderer");
      yRenderer.grid.template.set("forceHidden", true);
      yRenderer.labels.template.set("fontSize", 10);

      let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.05,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererX.new(root, {}),
        calculateTotals: true
      }))
      let xRenderer = xAxis.get("renderer");
      xRenderer.grid.template.set("forceHidden", true);
      xRenderer.labels.template.set("forceHidden", true);

      // Add series
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        stroke: am5.color("rgb(255,255,255)"),
        valueXField: 'num_members',
        categoryYField: "cod_sub_category",
        stacked: false,
        tooltip: am5.Tooltip.new(root, {
          labelText: "[fontSize:10px]{des_category}[/]\n{des_sub_category}\n[bold]{valueX}[/]",
          tooltipY: am5.percent(10),
        })
      }))

      series.columns.template.setAll({
        fillOpacity: 0.65,
        cornerRadiusTL: 4,
        cornerRadiusTR: 4,
        cornerRadiusBL: 4,
        cornerRadiusBR: 4,
        templateField: "color",
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            text: "[bold]{valueX}[/]",
            centerY: am5.p50,
            populateText: true,
            fontSize: 10
          })
        });
      });

      series.data.setAll(_data);
      series.appear(1000);

      return () => {
        root.dispose()
      }
    },
    [id, data] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return <div id={id} className={`w-full ${getChartHigh(data.length)}`}/>
}

export default React.memo(CategoryBarChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
