import React from "react";

import {dateFormat} from "@components/RefMan/NominationCard/NominationCard.lib";
import {ArrowLongRightIcon} from "@heroicons/react/24/solid";
import Placeholder from "@ui-components/Placeholder";
import Alert from "@ui-components/Alert";
import {useIntl} from "react-intl";
import {BriefcaseIcon} from "@heroicons/react/24/outline";


const MemberDetailRatings = ({ratings, history, isObserver}) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col w-full gap-5">
      <div className="w-full flex flex-row justify-between items-center pb-1 border-b-2 border-gray-900">
        <p className="font-bold text-2xl">Valutazioni</p>
        <p
          className="cursor-pointer hover:underline hover:font-bold"
          onClick={() => history.push({pathname: '/reports'})}
        >
          Vai al dettaglio <ArrowLongRightIcon className="inline-block h-4 w-4"/>
        </p>
      </div>
      {ratings ?
        <>
          {ratings.length > 0 ?
            isObserver
              ? <div className="flex flex-col items-center">
                <div className="px-4 py-2 border rounded-3xl bg-am-700 bg-opacity-20">
                  <b className="text-xl">{ratings.filter(i => i.status === 'APPROVED').length}</b> rapporti chiusi
                </div>
              </div>
              : <div className="flex flex-col items-center gap-3">
                <div className="px-4 py-2 border rounded-3xl">
                  <b className="text-xl">{ratings.length}</b> rapporti
                </div>
                <div className="p-1 w-full flex flex-col divide-y divide-gray-300">
                  {ratings.sort((a, b) => b.game.dat_game.localeCompare(a.game.dat_game)).map(r => (
                    <div key={r.cod_game} className="py-2 flex flex-col sm:flex-row sm:justify-between text-am-600 gap-2">
                      <div className="w-full sm:w-2/3 flex flex-col text-sm">
                        <div className="flex flex-row justify-between">
                          <div>
                            <b className="capitalize">{intl.formatDate(r.game.dat_game, dateFormat)}</b>
                            <span className="mx-1.5">&#8226;</span>
                            <span>Gara {r.game.game_number} di <b>{r.game.level.des_level_extended}</b></span>
                          </div>
                        </div>
                        <span className="text-xxs">{r.game.team_a.team_alias} <b>vs</b> {r.game.team_b.team_alias}</span>
                      </div>
                      <div className="flex flex-row gap-2 items-center text-sm font-bold">
                        <div><BriefcaseIcon className="h-5 w-5"/></div>
                        <div className="whitespace-nowrap">
                          {r.observer.tag}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            :
            <div>
              <Alert title="Nessuna valutazione presente"/>
            </div>
          }
        </>
        :
        <div className="flex flex-col w-full mb-4 gap-2">
          <div className="flex flex-row gap-10">
            <Placeholder height="h-16" classNames="rounded-lg w-1/3"/>
            <Placeholder height="h-16" classNames="rounded-lg w-1/3"/>
            <Placeholder height="h-16" classNames="rounded-lg w-1/3"/>
          </div>
          <Placeholder height="h-5" classNames="rounded-lg"/>
          <Placeholder height="h-36" classNames="rounded-lg"/>
        </div>
      }
    </div>
  )
}

export default MemberDetailRatings;
