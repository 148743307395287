import React from "react";
import {OK_STATUS} from "@utils/constants";
import {PersonalNominationTotalKpiPropsType} from "./PersonalNominationTotalKpi.type";


export function PersonalNominationTotalKpi({
                                             nominations,
                                             maxLevel,
                                             vertical
                                           }: PersonalNominationTotalKpiPropsType) {
  const maxLevelNominations = maxLevel ? nominations.filter(i => i.game.level.des_level === maxLevel) : []

  const getKpi = (title: string, statusList: string[], customStyle: string) => (
    <div className={`w-full flex flex-col items-center p-2 ${customStyle}`}>
      <div className="font-bold text-lg sm:text-xl">
        {title}
        <span className="ml-2 text-xl sm:text-2xl">
          {nominations.filter(i => statusList.includes(i.status)).length}
        </span>
      </div>
      {maxLevel ?
        <div className="text-xxs sm:text-sm">
          In {maxLevel}
          <span className="ml-2 text-md sm:text-lg">
            {maxLevelNominations.filter(i => statusList.includes(i.status)).length}
          </span>
        </div> : null
      }
    </div>
  )

  return (
    <div
      className={`w-full flex ${vertical ? 'flex-row md:flex-col justify-around md:justify-center gap-5 md:w-64' : 'flex-row justify-around'}`}>
      {getKpi(
        'Gare',
        OK_STATUS,
        'text-am-700'
      )}
      {getKpi(
        'Revoche',
        ['REV'],
        `text-yellow-500 ${vertical ? 'border-x-4 md:border-x-0 md:border-y-4 md:p-6' : 'border-x-4'}`
      )}
      {getKpi(
        'Rifiuti',
        ['RIF'],
        'text-red-600'
      )}
    </div>
  )
}
