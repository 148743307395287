import React, {useState, useEffect} from "react";
import {api} from "@services/apiRequest";

// UI
import Placeholder from "../../../ui-components/Placeholder";
import SingleFaq from "./SingleFaq";

function FaqList({push}) {
  const [dataFaq, setDataFaq] = useState();
  const [renderFaq, setRenderFaq] = useState(true)

  useEffect(() => {
    const fetchData = () => {
      api
        .get("/tickets/faq")
        .then(({data: faq}) => {
          setDataFaq(faq)
        })
        .catch(() => {
          setDataFaq([])
          push({title: "Errore del server", type: "error"});
        });
    };

    if (dataFaq === undefined) {
      fetchData();
    }

  }, [dataFaq]) // eslint-disable-line react-hooks/exhaustive-deps

  function debounce(ms) {
    let timer;
    return () => {
      setRenderFaq(false)
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        setRenderFaq(true)
      }, ms)
    };
  }

  useEffect(() => {
    // re-render of every FAQ when window width value changes, to correctly refresh opening animation
    window.addEventListener('resize', debounce(300))

    return () => {
      setRenderFaq(false);
    }
  }, [])

  return (
    <div>
      {dataFaq ?
        <div className="p-5 relative rounded-lg shadow-md">
          <div className="list-inside">
            {(dataFaq || []).map((element, index) => (
              <div key={element.answer}>
                {renderFaq ? <SingleFaq answer={element.answer} question={element.question}/> :
                  <Placeholder height="h-3"/>}
                {/* check, if last element does not render separator line */}
                {dataFaq.length !== index + 1 && <div className="mt-4 mb-4 w-full border-t border-gray-300"/>}
              </div>))
            }
          </div>
          <br/>
        </div>
        : <div className="flex flex-col w-full mb-4 gap-2">
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
        </div>
      }
    </div>
  );
}

export default FaqList;
