import {RatingsObserversListType, ObserversListType} from "./Ratings.type"
import RatingsProgressBar from "../../../../components/ProgressBar/ProgressBar";

const RatingsObserversList = ({observersList}: RatingsObserversListType) => {
  return <div className="w-full max-w-xl">
    <div className="text-center text-xl font-bold border-b-2 pb-2 px-2 mb-4">
      Osservatori
    </div>
    <table className="min-w-full">
      <thead></thead>
      <tbody>
      <tr className="font-bold text-sm">
        <td className="py-1.5 text-center">
          <div className="mr-3">#</div>
        </td>
        <td className="text-start">
          Tesserato
        </td>
        <td className="text-center">
          Report
        </td>
        <td className="text-center">
          Media
        </td>
      </tr>
      {observersList && observersList.length > 0 &&
        observersList.map((member: ObserversListType, index: number) =>
          <tr key={index}>
            <td className="py-1.5 flex justify-center">
              <div className="rounded-full border border-am-700 px-2 text-ellipsis text-center w-10 text-sm mr-3">
                {index + 1}°
              </div>
            </td>
            <td className="py-1.5 italic text-ellipsis truncate text-start text-sm">
              {member.name}
            </td>
            <td className="py-1.5 text-center">
              <div className="text-center font-bold">
                {member.count}
              </div>
            </td>
            <td className="py-1.5 flex justify-center">
              <RatingsProgressBar
                max={member.average <= 10 ? 10 : 82}
                rating={member.average}
                classNames="w-40"
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
}

export default RatingsObserversList
