const messages = {
  members: "tesserati",
  colleagues: "i miei colleghi",
  games: "gare",
  "all-games": "tutte le gare",
  nominations: "designazioni",
  planner: "pianificazione",
  players: "Giocatori",
  incompatibilities: "Incompatibilità",
  "members-parameters": "Parametri tesserati",
  agenda: "agenda",
  quiz: "quiz",
  absences: 'indisponibilità',
  observers: 'osservatori',
  algorithms: 'Algoritmi',
  changes: "gestione rifiuti",
  activities: "attività tecnica",
  reports: "valutazioni",
  economics: "economics",
  communications: "comunicazioni",
  relocations: "Gestione fuori sede",
  "technical-area": "area tecnica",
  calendar: "calendario",
  entry: "settings",
  "personal-report": "scheda attività",
  "all-personal-report": "schede attività",
  analytics: "analytics",
  entity_member: "Vista dei tesserati",
  entity_home: "Permessi sulla home",
  entity_games: "Permessi sulle gare",
  entity_entry: "Data Entry",
  entity_economics: "Accesso ai dati economici",
  entity_nominations: "Designazioni",
  entity_absences: "Gestione indisponibilità",
  entity_activities: "Attività tecnica",
  entity_reports: "Rapporti",
  entity_support: "Supporto",
  entity_threads: "Comunicazioni",
};

export default messages;
