import React, {useEffect, useState} from "react";

// Services
import {api} from "@services/apiRequest";

// Components
import CategoryBarChart from "@pages/Home/Sections/categoryBarChart";
import ProvinceHeatMap from "@pages/Home/Sections/ProvinceHeatMap";
import {GamesProvinceFilter} from "@pages/Games/Games.lib";
import {CategoryFilter} from "@pages/Members/Members.lib";
import {MemberHomeKpi} from "./Sections.lib";

// Ui
import Placeholder from "@ui-components/Placeholder";
import {UserIcon} from "@heroicons/react/24/outline";
import Toggle from "@ui-components/Toggle";

// Types
import {MembersSectionPropsType, MemberKpiType} from "@pages/Home/Home.type";


export function MembersSection(
  {
    push,
    group,
    categories,
    selectedCategories,
    setSelectedCategories,
    selectedProvinces,
    setSelectedProvinces,
    includeExtra,
    setIncludeExtra
  }: MembersSectionPropsType) {

  const [membersData, setMembersData] = useState<MemberKpiType | undefined>(undefined);

  useEffect(() => {
    const searchParams = new URLSearchParams({
      cod_group: group,
      ...(!includeExtra && {include_external: "0"}),
      ...(selectedCategories.length && {categories: JSON.stringify(selectedCategories.map(i => i.value))}),
      ...(selectedProvinces.length && {provinces: JSON.stringify(selectedProvinces.map(i => i.value))}),
    });
    api.get(`/kpi/home-members?${searchParams}`)
      .then(({data}) => {
        setMembersData(data)
      })
      .catch(() => push({type: "error", title: "Errore durante il caricamento"}))

  }, [group, selectedCategories, selectedProvinces, includeExtra]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="my-5">
      <div className="flex flex-col sm:flex-row justify-between sm:gap-6">
        <div className="w-full sm:w-2/5">
          <CategoryFilter
            categories={categories}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        </div>
        <div className="w-full sm:w-2/5">
          <GamesProvinceFilter
            selectedProvinces={selectedProvinces}
            setSelectedProvinces={setSelectedProvinces}
          />
        </div>
        <div className="sm:w-1/5 flex items-center -mt-2">
          {/*@ts-ignore*/}
          <Toggle
            smaller
            vertical
            label={<span className="mr-3 whitespace-nowrap"><UserIcon className="w-4 inline-block -mt-0.5"/> fuori regione</span>}
            checked={includeExtra}
            onChange={() => setIncludeExtra(!includeExtra)}
          />
        </div>
      </div>
      {membersData ?
        <div className="flex flex-col sm:flex-row justify-between gap-6 items-center mt-4 sm:mt-0">
          <div className="w-full sm:w-1/5 grid grid-cols-3 sm:grid-cols-1 gap-5 justify-between sm:my-4">
            <MemberHomeKpi
              title="Tesserati"
              value={membersData.categories.reduce((acc, i) => acc + i.num_members, 0)}
            />
            {Object.entries(categories.filter(i => membersData?.categories.map(j => j.cod_sub_category).includes(i.cod_sub_category))
              .reduce((acc, i) => ({...acc, [i.cod_category]: i.des_category}), {}))
              .map(([cod_category, des_category]) =>
                <div key={cod_category}>
                  <MemberHomeKpi
                    codCategory={cod_category}
                    title={String(des_category)}
                    value={membersData?.categories.filter(x => x.cod_category === cod_category).reduce((acc, j) => acc + j.num_members, 0)}
                  />
                </div>
              )}
          </div>
          <div className="w-full sm:w-2/5">
            <CategoryBarChart id="kpi-categories" data={membersData.categories.reverse()}/>
          </div>
          <div className="w-full sm:w-2/5">
            <ProvinceHeatMap
              id="kpi-province-map"
              data={membersData.provinces.map(i => ({value: i.num_members, id: 'IT-' + i.cod_province}))}
            />
          </div>
        </div>
        : <div className="flex flex-row gap-5">
          <Placeholder height="h-32" classNames="rounded-lg w-1/5"/>
          <Placeholder height="h-32" classNames="rounded-lg w-2/5"/>
          <Placeholder height="h-32" classNames="rounded-lg w-2/5"/>
        </div>
      }
    </div>
  );
}
