import React, {useState} from "react";
import { useIntl } from "react-intl";

// UI
import MaterialTable from "@material-table/core";
import { tableIcons } from "../../TableIcons";
import _ from "lodash";

import AuthenticationService from "../../../services/AuthenticationService";
import { api } from "@services/apiRequest";
import TableStyle from "../../TableStyle";
import UpdateScopes from "./UpdateScopes";
import UpdateGroups from "./UpdateGroups";
import Badge from "../../Badge";


const getColumns = (intl) => [
  {
    title: "ID",
    field: "id",
    hidden: true
  },
  {
    title: intl.formatMessage({ id: "name" }),
    field: "name",
    render: ({ name }) => <b>{name}</b>,
  },
  {
    title: intl.formatMessage({ id: "description" }),
    field: "description"
  },
  {
    cellStyle: { width: 170, minWidth: 170 },
    headerStyle: { width: 170, minWidth: 170 },
    title: "Gruppi",
    field: "groups",
    editComponent: (props) => <UpdateGroups {...props} />,
    render: ({ groups }) =>
      groups ? (
        <div>
          {groups.split(";").map((s) => (
            <Badge type="success" classNames="mb-1 mr-1" text={s} key={s}/>
          ))}
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Livello",
    field: "level",
    lookup: {'all': 'Totale', 'c': 'Serie C', 'd': 'Serie D', 'r': 'Regionale', 'personal': 'Personale'}
  },
  {
    cellStyle: { width: 600, minWidth: 600 },
    headerStyle: { width: 600, minWidth: 600 },
    title: "Scopes",
    field: "scopes",
    editComponent: (props) => <UpdateScopes {...props} />,
    render: ({ scopes }) =>
      scopes ? (
        <div>
          {scopes.split(";").map((s) => (
            <Badge classNames="mb-1 mr-1" text={s} key={s}/>
          ))}
        </div>
      ) : (
        "-"
      ),
  },
];

const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: false,
  toolbar: false,
};

const getLocalization = (intl) => ({
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: intl.formatMessage({ id: "delete_element" }),
    },
  },
  pagination: {
    labelDisplayedRows: intl.formatMessage({ id: "roles_displayed_rows" }),
    labelRowsSelect: intl.formatMessage({ id: "roles" }),
  },
});

const RoleTable = ({ rows, setRow, push, apiKey, roleKey }) => {
  const intl = useIntl();
  const [columns] = useState(getColumns(intl))
  const scopes = AuthenticationService.getUserScopes();

  const _rows = rows && _.values(rows).map((user) => ({ ...user }));

  function manageUpdatedUser(oldData, newData) {
    const { [oldData.id]: tmp, ...rest } = rows;
    setRow({ ...rest, [newData.id]: newData });
  }

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      if (isEqual) {
        resolve();
      } else {
        api
          .post(`/${apiKey}/${oldData.id}`, newData)
          .then(() => {
            manageUpdatedUser(oldData, newData);
            push({
              title: intl.formatMessage({ id: "updated" }),
              type: "success",
            });
            resolve();
          })
          .catch(() => {
            push({
              title: intl.formatMessage({ id: "server_error" }),
              type: "error",
            });
            reject();
          });
      }
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      api
        .delete(`/${apiKey}/${encodeURIComponent(newData.id)}`)
        .then(() => {
          const { [newData.id]: tmp, ...rest } = rows;
          setRow({ ...rest });
          push({
            title: intl.formatMessage({ id: "deleted_role" }),
            type: "success",
          });
          resolve();
        })
        .catch(() => {
          push({
            title: intl.formatMessage({ id: "server_error" }),
            type: "error",
          });
          reject();
        });
    });

  return (
    <TableStyle>
      <MaterialTable
        columns={columns}
        data={_rows}
        icons={tableIcons}
        isLoading={_rows === undefined}
        options={OPTIONS}
        localization={getLocalization(intl)}
        editable={{
          ...(scopes.indexOf(`${roleKey}:update`) >= 0
            ? { onRowUpdate: _onRowUpdate }
            : {}),
          ...(scopes.indexOf(`${roleKey}:delete`) >= 0
            ? { onRowDelete: _onRowDelete }
            : {}),
        }}
      />
    </TableStyle>
  );
};

export default React.memo(RoleTable, (props, nextProps) => {
  // Render component only when rows changes
  return _.isEqual(props.rows, nextProps.rows);
});
