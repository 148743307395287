import React, {useContext, useEffect, useState} from "react";

// Services
import {DataContext} from "@services/DataContext/DataContext";

// Table
import {getColumns, OPTIONS, LOCALIZATION} from "./services";
import {tableIcons} from "@ui-components/TableIcons";
import TableStyle from "@ui-components/TableStyle";
import MaterialTable from "@material-table/core";

import {onRowDelete} from "./services";

// Ui
import Placeholder from "@ui-components/Placeholder";


export default function Assign({push, filteredJobs, members, scopes}) {

  const dataContext = useContext(DataContext);
  const [data, setData] = useState();

  useEffect(() => {
    if (members) {
      setData(Object.values(members).filter(i => i.members_jobs.length > 0).flatMap(item =>
        item.members_jobs.map(j => ({
          id_fip_code: item.id_fip_code,
          member: item.member,
          id_job: j.id_job
        }))
      ))
    }
  }, [members]);

  const _onRowDelete = (oldData) => onRowDelete(oldData, dataContext, push)
  return (
    <>
      {filteredJobs ?
        <TableStyle>
          <MaterialTable
            columns={getColumns(filteredJobs)}
            data={data}
            icons={tableIcons}
            options={OPTIONS}
            localization={LOCALIZATION}
            editable={{
              ...(scopes.indexOf(`role:delete`) >= 0
                ? {onRowDelete: _onRowDelete}
                : {}),
            }}
          />
        </TableStyle> :
        <div className="flex flex-col w-full mb-4 gap-2">
          <Placeholder height="h-10" classNames="rounded-lg bg-am-700"/>
          <Placeholder height="h-96" classNames="rounded-lg"/>
        </div>
      }
    </>
  );
}
