import React, { useState, useEffect } from 'react';

// Services
import { api } from '@services/apiRequest';

// Model
import { DesignatorLevelType, RoundsSchema } from '@pages/Nominations/Algorithms/Algorithms.types';


function useChampionshipRounds(designator_level?: DesignatorLevelType) {
  const [rounds, setRounds] = useState<string[] | undefined>(undefined);
  
  useEffect(() => {
    async function _fetchRounds() {
      const { data } = await api.get<RoundsSchema[]>(`/games/available_rounds?designator_level=${designator_level}`)

      // convert the array of objects into an array of strings and remove duplicates
      const roundsString: string[] = data.map((round) => round.des_round);
      setRounds(roundsString.filter((round, index) => roundsString.indexOf(round) === index));
    }

    if (designator_level) {
      _fetchRounds();
    } else {
      setRounds(undefined);
    }
  }, [designator_level]);
  
  return rounds;
}

export default useChampionshipRounds;