import {useContext, useEffect} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "../DataContext/DataContext";
import apiRequest from "../apiRequest";
import _ from "lodash";

const useJobs = () => {
  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    let closed = false;
    const fetchData = async () => {
      try {
        const data = await apiRequest.get(`/jobs`);
        if (!closed) {
          dataContext.setJobs((data || []).reduce((obj, row) => _.set(obj, [row.id], row), {}));
        }
      } catch (error) {
        console.log(error)
        dataContext.setJobs(false);
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if (dataContext.jobs === null) {
      fetchData();
    }

    return () => {
      closed = true;
    };
  }, [dataContext.jobs, push]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (!dataContext.jobs) {
    return undefined
  }

  return dataContext.jobs;

};

export default useJobs;
