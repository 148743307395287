import React from "react";
import { GamesSchema } from '@pages/Games/Games.type';
import {MemberWithCitySchema} from '@pages/Members/Members.type';
import {IntlShape} from "react-intl";
import {DistancesDict} from "@services/types/city";
import {RelocationSchema} from "@pages/Planner/Calendar/Calendar.type";


export interface LevelSchemaWithDesignator {
  cod_level: string,
  des_level: string,
  des_level_extended: string,
  designator_level: DesignatorLevelType,
}

export type DesignatorLevelType = "N" | "B" | "C" | "D" | null;

export type AlgoGroup = "REF" | "UDC";

export type Couple = {member1: string, member2: string};

export const ChampionshipOptions = [
  {
    name: "Serie B2",
    value: "B",
  },
  {
    name: "Serie C",
    value: "C",
  },
  {
    name: "Serie D",
    value: "D",
  },
  {
    name: "Campionati Nazionali",
    value: "N",
  },
];

export interface RoundsSchema {
  des_round: string,
}

export interface EliteAlgorithmGamesProps {
  designator_level: DesignatorLevelType,
  des_round: string,
  group: 'ref' | 'udc',
  sortBy: 'datetime' | 'game'
}

export type PossibleRoles = 'ref1' | 'ref2' | 'sp' | 'crono' | 'a24s'

export const RolesAssociatedToGroup: {ref: PossibleRoles[], udc: PossibleRoles[]} = {
  ref: ['ref1', 'ref2'],
  udc: ['sp', 'crono', 'a24s'],
}

export const RolesDescription: Record<string, string> = {
  ref1: '1° arbitro',
  ref2: '2° arbitro',
  sp: 'Segnapunti',
  crono: 'Cronometrista',
  a24s: "Addetto 24''",
}

export interface ConstraintsAssignmentCardTypes {
  intl: IntlShape,
  role: PossibleRoles,
  game: GamesSchema,
  distances: DistancesDict,
  relocations: RelocationSchema,
  okStatus: string[],
  options: AssignmentOptionSchema[],
  userKwargs: UserKwargsSchema,
  setUserKwargs: React.Dispatch<UserKwargsSchema>,
  setToSave: React.Dispatch<React.SetStateAction<boolean>>
  taskData: EliteAlgorithmTaskSchema | BasicAlgorithmTaskSchema,
  fetching: boolean,
  algoType: 'basic' | 'elite'
}

export interface SolutionByGame {
  fee: number;
  game_tax: number;
  extra_fee: number;
  travel_costs: number;
  id_ref1: number;
  id_ref2: number;
  id_sp: number;
  id_crono: number;
  id_a24s: number;
  ref1: string;
  ref2: string;
  sp: string;
  crono: string;
  a24s: string;
}

export interface JsnResult {
  solution_by_game: { [key: string]: SolutionByGame }
}

export interface EliteAlgorithmTaskSchema {
  designator_level: string,
  des_round: string,
  cod_status: string,
  tms_started_at?: string,
  tms_finished_at?: string,
  des_error_message?: string,
  jsn_input_data?: object,
  jsn_result?: JsnResult,
}

export interface UserKwargsSchema {
  flg_assign_all_games: boolean;
  flg_forbidden_same_city_team_a: boolean;
  flg_forbidden_same_city_team_b: boolean;
  member_role_game: AssignForbiddenSchema;
  member_role: AssignForbiddenSchema;
  member_game: AssignForbiddenSchema;
  member: AssignForbiddenSchema;
  member_couples: AssignForbiddenSchema;
}

export interface BasicInputData extends UserKwargsSchema {
  dat_from: string;
  dat_to: string;
  levels: 'all' | string[];
  provinces: 'all' | string[];
}

export interface BasicAlgorithmTaskSchema {
  id: number;
  cod_group: AlgoGroup;
  cod_status: string;
  created_by: number;
  updated_by: number;
  tms_created_at: string,
  tms_updated_at?: string,
  tms_started_at?: string,
  tms_finished_at?: string,
  des_error_message?: string,
  jsn_input_data: BasicInputData,
  jsn_result?: JsnResult,
}

export interface SelectedSession {
  value: number;
  data: BasicAlgorithmTaskSchema;
  label: any;
}

export const AlgorithmRunStatus = {
  ACCEPTED: 'ACCEPTED',
  RUNNING: 'RUNNING',
  ERROR: 'ERROR',
  END: 'END',
}

export interface OtherGameSchema {
  city: string;
  cod_level: string;
  cod_province: string;
  time_game: string;
}

/** AssignmentOptionSchema */
export interface AssignmentOptionSchema {
  admitted_roles: string[];
  cod_game: string;
  cod_group: AlgoGroup;
  num_nominations_a: number;
  num_nominations_b: number;
  dat_last_game_a?: string;
  dat_last_game_b?: string;
  flg_reserve: boolean;
  id_member: number;
  member: MemberWithCitySchema;
  other_games: OtherGameSchema[];

  // Frontend Only
  isDisabled: boolean;
}

export interface SearchSelectAssignmentOptionSchema {
  value: number;
  label: string;
  isDisabled: boolean;
  option?: AssignmentOptionSchema;
}

interface AssignForbiddenSchema {
  assign: any[]
  forbidden: any[]
}


export interface ParametersModalProps {
  group: 'ref' | 'udc';
  userKwargs: UserKwargsSchema;
  setUserKwargs: React.Dispatch<UserKwargsSchema>;
  saveParameters: Function;
  setToSave: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface GeneralParametersModalProps extends ParametersModalProps {
  games: GamesSchema[];
  setShowGeneralParameters: React.Dispatch<React.SetStateAction<boolean>>;
  basic?: boolean;
}

export interface GameParametersModalProps extends ParametersModalProps {
  intl: IntlShape;
  game: GamesSchema;
  distances: DistancesDict;
  relocations: RelocationSchema;
  setShowGameParameters: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export interface CreateBasicSessionModalProps {
  group: AlgoGroup;
  session?: BasicAlgorithmTaskSchema;
  setSession?: React.Dispatch<React.SetStateAction<BasicAlgorithmTaskSchema | undefined>>
  setShowCreateSession: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface DeleteSessionModalProps {
  session: BasicAlgorithmTaskSchema;
  stopDelete: 'stop' | 'delete';
  setStopDelete: React.Dispatch<React.SetStateAction<'stop' | 'delete' | undefined>>;
  setSession?: React.Dispatch<React.SetStateAction<BasicAlgorithmTaskSchema | undefined>>
}

export interface BasicSessionDetail {
  levels: 'all' | 'some';
  provinces: 'all' | 'some';
}