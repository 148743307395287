import {api} from "@services/apiRequest";
import {IntlShape, useIntl} from "react-intl";
import {useContext, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";

export const useQuizResult = () => {

  // hooks
  const intl: IntlShape = useIntl();

  // context
  const {push} = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);

  const callApi = async (fn: Promise<any>, errorMsg: string) => {
    setLoading(true);
    try {
      return await fn;
    } catch (e) {
      push({type: "error", title: intl.formatMessage({id: "error"}), message: errorMsg});
    } finally {
      setLoading(false);
    }
  }

  const getSessionKpi = async (idQuiz: number) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.get(`/quizzes/session-result-kpi/${idQuiz}`);
        resolve(data);
      }),
      "GET_SESSION_KPI",
    );

  const getDetail = async (idQuiz: number) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.get(`/quizzes/session-result/${idQuiz}`);
        resolve(data);
      }),
      "GET_COMMON_RESULT",
    );

  return {getDetail, getSessionKpi, loading};
}