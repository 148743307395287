import React, {Dispatch, SetStateAction} from "react";
import Input from "@ui-components/Input";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {QuizFilterType} from "@components/RefMan/Quiz/types";

export function QuizTitleFilter({filter, setFilters}: {
  filter: QuizFilterType,
  setFilters: Dispatch<SetStateAction<QuizFilterType>>
}) {

  return <div className="w-full">
    <Input
      name={'QuizTitleFilter'}
      required={false}
      validation={undefined}
      errorMessage={undefined}
      showCalendar={undefined}
      onClear={undefined}
      value={filter.searchTitle}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({...filter, searchTitle: e.target.value});
      }}
      label={
        <div className="flex flex-row gap-2">
          <MagnifyingGlassIcon className="h-4 w-4 mt-0.5 -mb-3"/>
          <span>Titolo</span>
        </div>
      }
    />
  </div>
}