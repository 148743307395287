import React, {useContext, useState} from "react";
import {DataContext} from "@services/DataContext/DataContext";

import {getColumns, OPTIONS, LOCALIZATION} from "./services";
import {tableIcons} from "@ui-components/TableIcons";
import {onRowUpdate, onRowDelete} from "./services";
import TableStyle from "@ui-components/TableStyle";
import MaterialTable from "@material-table/core";

import Placeholder from "@ui-components/Placeholder";
import AddExternalMemberDialog from "@pages/Entry/Members/AddExternalMemberDialog";


export default function EntryMembers({push, group, members, categories}) {

  const dataContext = useContext(DataContext);
  const [tableFilters, setTableFilters] = useState();
  const [showAdd, setShowAdd] = useState(false);

  const _onRowUpdate = (newData, oldData) => onRowUpdate(newData, oldData, dataContext, push);
  const _onRowDelete = (oldData) => onRowDelete(oldData, dataContext, push)

  return (
    <>
      {members ?
        <TableStyle>
          <MaterialTable
            columns={getColumns(group, members, categories, tableFilters)}
            data={Object.values(members)}
            icons={tableIcons}
            options={OPTIONS}
            localization={LOCALIZATION}
            onFilterChange={(filter) => setTableFilters(filter)}
            editable={{
              onRowUpdate: _onRowUpdate,
              onRowDelete: _onRowDelete,
              isDeleteHidden: (rowData) => !rowData.flg_external,
            }}
            actions={[
              {
                icon: tableIcons.Add,
                tooltip: "Inserisci",
                onClick: () => setShowAdd(true),
                isFreeAction: true,
              },
            ]}
          />
        </TableStyle> :
        <div className="flex flex-col w-full mb-4 gap-2">
          <Placeholder height="h-10" color="am-200" classNames="rounded-lg"/>
          <Placeholder height="h-96" classNames="rounded-lg"/>
        </div>
      }
      {showAdd ?
        <AddExternalMemberDialog
          group={group}
          toggleOpen={() => setShowAdd(false)}
          dataContext={dataContext}
          categories={categories}
          push={push}
        />
        : null}
    </>
  );
}
