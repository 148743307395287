import React from 'react';
import { api } from '@services/apiRequest';
import { ReportItemType, ReportStatus, type ReportType } from './ObserverReportsList.type';
import { CalendarIcon, EyeIcon, PencilIcon } from '@heroicons/react/24/outline';
import { getRefs } from '@components/RefMan/Reports/reports.lib';
import { getDateFormatted } from '@utils/lib/dates';
import { LKP_ROLES } from '@utils/constants';
import { RolesType } from '@components/RefMan/Reports/ReportsIndex/ObserverReportsIndex.type';
import { getDateStr } from '@pages/Planner/Calendar/Calendar.lib';
import { SelectedLevel } from '@pages/Games/Games.type';
import NotificationPing from "@ui-components/NotificationPing";


/**
 * @function getStatusBadgeLabel
 *
 * @param {string} status Current status code
 * @returns {string} Label for the status badge
 */
export const getStatusBadgeLabel = (status: string): string => {
  switch (status) {
    case 'ACC':
      return 'Accettata';
    case 'TEM':
      return 'Temporanea';
    case 'TRA':
      return 'Trasmessa';
    case 'REV':
      return 'Revocata';
    case 'RIF':
      return 'Rifiutata';
    default:
      return '';
  }
};


export const addObserverDataToReport = (data: ReportType, idFipCode: string, group: 'ref' | 'udc') => {
  const itemsWithObserver = data.items
    .map((report: ReportItemType) => {
      const nomination = report.game.nominations.find(
        (nomination) =>
          nomination.id_fip_code !== Number(idFipCode) &&
          ((group === 'ref' && nomination.role === 'OBSR') || (group === 'udc' && nomination.role === 'OBSU')) &&
          (nomination.status === 'ACC' || nomination.status === 'TRA')
      );
      return nomination
        ? {
          ...report,
          ...{
            observerName: nomination?.member?.member,
            observerIdFipCode: nomination?.member?.id_fip_code,
          },
        }
        : null;
    });
  return { ...data, items: itemsWithObserver };
}

/**
 * @function getObserverReportsList
 *
 * @param {string} idFipCode idFipCode of current user
 * @param {'ref' | 'udc'} group
 * @param {RolesType} genericRole
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {SelectedLevel[]} selectedLevels
 * @param {string} filterMember
 * @param {number} offset
 * @param {number} limit
 * @returns {Array} List of observer reports
 */
export const getObserverReportsList = async (
  idFipCode: string,
  group: 'ref' | 'udc',
  genericRole: RolesType,
  startDate: Date,
  endDate: Date,
  selectedLevels: SelectedLevel[],
  filterMember: string | undefined,
  offset: number,
  limit: number
): Promise<ReportType | false> => {
  try {
    const role = group === 'ref' ? 'OBSR' : 'OBSU';
    const searchParams = new URLSearchParams({
      only_ok: '1',
      limit: genericRole === 'member' ? '200' : limit ? limit.toString() : '100',
      ...(genericRole !== 'member' && { role: role, temp: '1' }),
      ...(genericRole === 'member' && { with_report: '1' }),
      ...((genericRole === 'viewer' || genericRole === 'admin') && { cod_group: group }),
      ...((genericRole === 'member' || genericRole === 'observer') && { id_fip_code: idFipCode }),
      ...(startDate && { start_date: getDateStr(startDate) }),
      ...(endDate && { end_date: getDateStr(endDate) }),
      ...(offset && { offset: genericRole === 'member' ? '0' : offset.toString() }),
      ...(filterMember && { member: filterMember }),
      ...(selectedLevels.length && { levels: JSON.stringify(selectedLevels.map((i) => i.value)) }),
    });
    const { data } = await api.get(`/nominations?${searchParams}`);
    if (genericRole === 'member') {
      return data ? addObserverDataToReport(data, idFipCode, group) : data
    } else {
      return data;
    }
  } catch (error) {
    console.error('Error retrieving observer reports.', error);
    return false;
  }
};

export const getReportsStatus = async (idFipCode: string, genericRole: RolesType): Promise<ReportStatus[]> => {
  const { data } = await api.get(`/evaluations/list-status?id_${genericRole}=${idFipCode}`);
  return data;
};

/**
 * @function getStepTextClass
 *
 * @param {number} index Step index
 * @param {number} currentStepId Step id
 * @param {number} stepId Id of the step opened
 * @param {boolean} reportDataSet True if there are report data, false otherwise
 * @param {boolean} memberData True if there are member data, false otherwise
 * @return {string} Classes for step text
 */
export const getStepTextClass = (index: number, currentStepId: number, stepId: number, reportDataSet: boolean, memberData: boolean): string => {
  let className: String[] = ['text-sm', 'font-medium', 'text-gray-500', 'hover:text-gray-700'];

  if (index === 0 || (index === 1 && reportDataSet) || (index === 2 && memberData)) {
    className = [...className, ...['cursor-pointer']];
  } else {
    className = [...className, ...['cursor-not-allowed']];
  }

  if (index === 0) {
    className = [...className, ...['ml-1']];
  } else {
    className = [...className, ...['ml-4']];
  }

  if (currentStepId === stepId) {
    className = [...className, ...['underline']];
  }

  return className.join(' ');
};

export const getListItems = (
  reports: ReportType | false | undefined,
  setStepId: React.Dispatch<React.SetStateAction<number>>,
  setReportData: React.Dispatch<React.SetStateAction<ReportItemType | null>>,
  role: RolesType,
  statusList: ReportStatus[] | undefined
) =>
  reports &&
  statusList &&
  reports.items.map((report: ReportItemType, index: number) => {
    const actualNominations = getRefs(report.game.nominations, report.member.category.cod_group);
    const toEvaluate = actualNominations.filter((i) => i.nomination.id_fip_code !== report.id_fip_code).length;
    const idObserver = role === 'member' ? report.observerIdFipCode : report.member.id_fip_code
    const closedReports = statusList.filter((i) =>
      i.cod_game === report.cod_game &&
      i.id_observer === idObserver &&
      i.status.toLowerCase() === 'approved'
    );
    return {
      rowId: `observer-reports-list-${index}`,
      rowElement: (
        <>
          <dt className='font-bold capitalize text-gray-900 flex flex-row items-center gap-2 justify-between'>
            <div className='flex flex-row gap-2 items-center'>
              <div>
                <CalendarIcon className='h-5 sm:h-7 w-5 sm:w-7' />
              </div>
              <div className='flex flex-row sm:flex-col whitespace-nowrap items-center sm:items-start gap-2 sm:gap-0'>
                <span>{getDateFormatted(report.game.dat_game)}</span>
                <span className='sm:text-sm font-normal'>{report.game.time_game}</span>
              </div>
            </div>
          </dt>
          <dd
            className='border-t sm:border-0 w-full my-1 sm:my-0 py-2 sm:py-0 flex flex-col gap-1 justify-center sm:col-span-2'>
            <div className='flex flex-row items-center gap-2 text-sm '>
              <span className='font-bold text-gray-700'># {report.game.game_number}</span>
              <span
                className='text-gray-900 border rounded-xl py-0.5 px-2 border-gray-300'>{report.game.level.des_level_extended}</span>
            </div>
            <span className='text-xxs sm:text-xs truncate overflow-hidden w-full'>
              {report.game.team_a.team_alias} <i>vs</i> {report.game.team_b.team_alias}
            </span>
            {report.game.city ? (
              <span className='text-xxs sm:text-xs truncate overflow-hidden w-full'>
                {report.game.place},{' '}
                <b>
                  {report.game.city.city} ({report.game.city.cod_province})
                </b>
              </span>
            ) : null}
          </dd>
          <div className='flex flex-row w-full sm:col-span-2 items-center justify-between'>
            <div className='text-sm'>
              {actualNominations.map((nomination, index) => (
                <p key={index} className='text-gray-900 mt-1'>
                  {LKP_ROLES[nomination.role]}: <strong>{nomination.nomination.member.member}</strong>
                </p>
              ))}
              {role === 'observer' ? null : (
                <p className='mt-2 text-am-700'>
                  Osservatore: <strong>{role === 'member' ? report.observerName : report.member.member}</strong>
                </p>
              )}
            </div>
            {role === 'member' ? (
              <div className="flex flex-row gap-2 items-center">
                {closedReports.length && !closedReports[0].flg_read ? <NotificationPing external={4} internal={2} /> : null}
                <div className={`w-7 h-7 mr-5 ${closedReports.length ? 'cursor-pointer' : 'text-gray-300'}`}
                  onClick={() => {
                    if (closedReports.length) {
                      setStepId(4);
                      setReportData(report);
                    }
                  }}
                >
                  <EyeIcon title='Leggi la valutazione' />
                </div>
              </div>
            ) : toEvaluate ? (
              <div className='flex flex-col justify-center items-center'>
                <span
                  className='text-xxs mb-1'>{closedReports.length === toEvaluate ? 'Report chiusi' : 'Da compilare'}</span>
                <div
                  className={`min-w-0 w-7 h-7 cursor-pointer ${role === 'viewer' && closedReports.length !== toEvaluate ? 'text-gray-300' : ''}`}
                  onClick={() => {
                    setStepId(1);
                    setReportData(report);
                  }}
                >
                  {closedReports.length === toEvaluate ? <EyeIcon title='Leggi la valutazione' /> :
                    <PencilIcon title='Compila la valutazione' />}
                </div>
              </div>
            ) : null}
          </div>
        </>
      ),
    };
  });
