import React from "react";
import {useIntl} from "react-intl";
import {StatusBullet} from "@components/RefMan/StatusBullet";
import {dateFormat} from "@pages/Planner/Absences/Absences.lib";
import {CalendarIcon, ClockIcon} from "@heroicons/react/24/outline";
import {getQuizStatusColor, getQuizStatusDescription} from "@components/RefMan/Quiz/QuizList/QuizList.lib";

import {QuizDetailResponseType} from "@components/RefMan/Quiz/types";

export function QuizMetadata({data}: { data: QuizDetailResponseType }) {

  const intl = useIntl();
  const {primaryColor, backgroundColor} = getQuizStatusColor(data.cod_status);
  // @ts-ignore
  const counters: {[key: string]: number} = data.sessions.reduce((acc, i) => ({...acc, [i.cod_status]: (acc[i.cod_status] || 0) + 1}), {})

  return (
    <div
      className="p-5 rounded-3xl border border-gray-300 flex flex-col sm:flex-row justify-between divide-y sm:divide-y-0">
      <div
        className="text-sm text-gray-500 flex flex-row sm:flex-col gap-y-2 sm:px-3 pb-3 sm:pb-0 justify-between sm:justify-center sm:items-start w-full">
        {data.associations ?
          <div className="flex flex-row items-center gap-1">
            <b className="text-xl">{data.associations.length}</b>
            <span>{data.associations.length !== 1 ? 'domande' : 'domanda'}</span>
          </div> : null
        }
        <div className="flex flex-row gap-x-2 items-center">
          <StatusBullet primaryColor={primaryColor} backgroundColor={backgroundColor}/>
          <p className={"text-sm text-gray-500 whitespace-nowrap"}>{getQuizStatusDescription(data.cod_status)}</p>
        </div>
      </div>
      <div className="text-sm text-gray-500 flex flex-col gap-y-2 py-3 sm:py-0 w-full justify-center">
        <div className="flex flex-row gap-x-2 items-center whitespace-nowrap">
          <CalendarIcon className="h-5 text-am-700"/>
          {data.tms_deadline
            ? <div className="flex flex-row gap-1">
              <span>Scadenza:</span>
              <span>{intl.formatDate(data.tms_deadline, {...dateFormat, hour: '2-digit', minute: '2-digit'})}</span>
            </div> : 'Nessuna scadenza'}
        </div>
        <div className="flex flex-row gap-x-2">
          <ClockIcon className="h-5 text-am-700"/>
          {data.time_duration ? data.time_duration : 'Nessun limite di tempo'}
        </div>
      </div>
      <div
        className="text-sm text-gray-500 flex flex-row sm:flex-col gap-y-2 pt-3 sm:pt-0 sm:px-3 justify-between items-center sm:items-end w-full">
        <div className="flex flex-row items-center gap-1">
          <b className="text-lg">{data.sessions.length}</b><span>sessioni</span>
        </div>
        <div className="flex flex-row gap-2 text-xs">
          <div className="whitespace-nowrap"><span className="font-semibold mr-1">{counters['TO NOTIFY'] || 0}</span>da notificare</div>
          <div className="whitespace-nowrap"><span className="font-semibold mr-1">{counters['NOT STARTED'] || 0}</span>non iniziate</div>
          <div><span className="font-semibold mr-1">{counters['IN PROGRESS'] || 0}</span>attive</div>
          <div><span className="font-semibold mr-1">{counters['FINISHED'] || 0}</span>chiuse</div>
        </div>
      </div>
    </div>
  )
}