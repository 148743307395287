import React, {useCallback, useContext, useState, useMemo, useEffect} from "react";
import { useIntl, FormattedMessage } from "react-intl";

// Services
import AuthenticationService from "../../../services/AuthenticationService";
import { NotificationsContext } from "../../Notifications";
import apiRequest from "../../../services/apiRequest";
import useRoles from "../Users/hooks/useRoles";

// Components
import UpdatePassword from "../../forms/UpdatePassword";
import MemberDetail from "@pages/Members/Detail";

// Ui
import {PageHeading} from "../../Container";
import Placeholder from "../../Placeholder";
import Button from "../../Button";
import Modal from "../../Modal";
import Input from "../../Input";
import Alert from "../../Alert";
import Badge from "../../Badge";


function Profile() {
  const intl = useIntl();
  const roles = useRoles();
  const [loading, setLoading] = useState(false);
  const userData = useMemo(() => AuthenticationService.getUserData() || {}, []);
  const [showPersonalData, setShowPersonalData] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    if (userData.roles && roles) {
      const personalRoles = Object.values(roles).filter(i => ['ref', 'udc'].includes(i.name)).map(i => String(i.id))
      setShowPersonalData(userData.roles.split(';').filter(i => personalRoles.includes(i)).length > 0)
    }
  }, [roles, userData])

  const handleChangePassword = useCallback(
    async (e) => {
      e.preventDefault();

      if (!isValid) {
        return;
      }

      const data = {
        email: userData.email,
        password: password,
        new_password: newPassword,
      };
      try {
        setLoading(() => true);
        setError(() => "");
        const response = await apiRequest.post("/users/password/reset", data);
        if (response.message === "password_updated") {
          push({
            title: intl.formatMessage({ id: "password_updated_successfully" }),
            type: "success",
          });
          setOpenPassword(!openPassword);
        } else {
          setError(() => intl.formatMessage({ id: "form_error" }));
        }
      } catch (error) {
        if (error?.response?.data?.detail === "user_not_found") {
          setError(() => intl.formatMessage({ id: "wrong_old_password" }));
        } else {
          setError(() => intl.formatMessage({ id: "server_error" }));
        }
      } finally {
        setLoading(false);
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    },
    [push, newPassword, userData, password, openPassword, intl, isValid]
  );

  function readOnlyField(label, key, last = false) {
    const borderStyle = last ? '' : 'border-b border-black'
    let labelId = label
    let value = userData[key]
    if (key === 'roles') {
      const userRoles = userData[key].split(';')
      if (userRoles.length > 1) {
        labelId = 'roles'
        value = userRoles.map((r) => (
          <Badge classNames="mb-1 mr-1" text={roles[r].description} key={r}/>
        ))
      } else {
        value = roles[userRoles].description
      }
    }
    return (
      <div className={`flex p-4 ${borderStyle}`}>
        <div className="w-1/2">
          <b>
            <FormattedMessage id={labelId}/>
          </b>
        </div>
        <div className="w-1/2">{value}</div>
      </div>
    );
  }

  if (!roles) {
    return (
      <>
        <PageHeading title={intl.formatMessage({id: "my_profile"})}/>
        <Placeholder height="20" />
      </>
    )
  }
  return (
    <>
      <PageHeading title={intl.formatMessage({ id: "my_profile" })} />
      <div>
        <div aria-label="simple table">
          <div className="border border-black rounded-3xl ">
            {readOnlyField("role", "roles")}
            {readOnlyField("name", "full_name")}
            {readOnlyField("email", "email", true)}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between items-center mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenPassword(!openPassword)}
        >
          <FormattedMessage id="edit_password" />
        </Button>

        <Button
          styleType="secondary"
          onClick={() => {
            AuthenticationService.logout();
            window.location = "/login";
          }}
        >
          Logout
        </Button>
      </div>
      <Modal
        opened={openPassword}
        onExit={() => setOpenPassword(!openPassword)}
      >
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            <FormattedMessage id="edit_password" />
          </h3>
        </div>

        <form onSubmit={handleChangePassword}>
          <Input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            label="Password"
            type="password"
            errorMessage={intl.formatMessage({
              id: "enter_actual_password",
            })}
            required
          />
          <UpdatePassword
            setPassword={setNewPassword}
            setConfirmPassword={setConfirmPassword}
            password={newPassword}
            confirmPassword={confirmPassword}
            onValid={setIsValid}
          />

          <div className="mt-4 flex items-end justify-end">
            <Button type="submit" submitting={loading} disabled={!isValid}>
              <FormattedMessage id="send" />
            </Button>
          </div>
          {error ? (
            <div className="mt-4">
              <Alert slim type="error" title={error} />
            </div>
          ) : null}
        </form>
      </Modal>
      {showPersonalData ?
        <div className="mt-10 border-t-2">
          <PageHeading title="I miei dati personali" />
          <MemberDetail showMemberActivity={false}/>
        </div>
        : null
      }
    </>
  );
}

export default Profile;
