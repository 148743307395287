import React from "react";
import {IntlShape} from "react-intl";

import {dateFormatWithWeekday} from "@pages/Planner/Absences/Absences.lib";
import ConstraintsAssignmentCard from "@pages/Nominations/Algorithms/Components/ConstraintsAssignmentCard";

import Button from "@ui-components/Button";
import {AdjustmentsHorizontalIcon} from "@heroicons/react/24/outline";

import {RefManListItemType} from "@components/RefMan/RefManList/RefManList.type";
import {GamesSchema} from "@pages/Games/Games.type";
import {
  BasicAlgorithmTaskSchema,
  EliteAlgorithmTaskSchema,
  RolesAssociatedToGroup,
  UserKwargsSchema,
} from "@pages/Nominations/Algorithms/Algorithms.types";
import {CostKpi} from "@pages/Nominations/Algorithms/EliteAlgorithm.services";
import Placeholder from "@ui-components/Placeholder";
import SmallNotification from "@ui-components/SmallNotification";
import {DistancesDict} from "@services/types/city";
import {RelocationSchema} from "@pages/Planner/Calendar/Calendar.type";


export const getListItems = (
  intl: IntlShape,
  items: GamesSchema[],
  distances: DistancesDict,
  relocations: RelocationSchema,
  group: 'ref' | 'udc',
  userKwargs: UserKwargsSchema,
  taskData: EliteAlgorithmTaskSchema | BasicAlgorithmTaskSchema,
  fetching: boolean,
  setUserKwargs: React.Dispatch<React.SetStateAction<UserKwargsSchema | undefined>> = () => {},
  setToSave: React.Dispatch<React.SetStateAction<boolean>>,
  setShowGameParameters: React.Dispatch<React.SetStateAction<string | undefined>>,
  algoType: 'basic' | 'elite'
): RefManListItemType[] | [] => {

  const okStatus = ['ACC', 'TEM', 'TRA']
  return items.map((game: GamesSchema): RefManListItemType => {

    // These are the constraints saved for the specific game in the algorithm page
    const gameAssignedMembers = userKwargs?.member_game.assign.filter(i => i[1] === game.cod_game) ?? [];
    const gameForbiddenMembers = userKwargs?.member_game.forbidden.filter(i => i[1] === game.cod_game) ?? [];

    return ({
      rowId: game.cod_game,
      rowElement: <>
        <div className="flex flex-col w-full gap-3 sm:col-span-3">
          <div className="flex flex-row sm:flex-col xl:flex-row justify-between pb-2 border-b gap-1">
            <div className="flex flex-row gap-2 items-center">
              <div className="whitespace-nowrap text-sm capitalize">
                <b>{intl.formatDate(game.dat_game, dateFormatWithWeekday)}</b> {game.time_game}
              </div>
            </div>
            <div className="flex flex-row items-center gap-2 text-sm ">
              <span className="font-bold text-gray-700">
                # {game.game_number}
              </span>
              <span className="text-gray-900 border rounded-xl py-0.5 px-2 border-gray-300">
              {game.level.des_level_extended}
            </span>
            </div>
          </div>
          <div className="w-full pb-3 sm:pb-0 flex flex-col gap-1 justify-center">
            <span className="text-xs truncate overflow-hidden w-full">
              {game.team_a.team_alias} <i>vs</i> {game.team_b.team_alias}
            </span>
            {game.city ?
              <span className="text-xxs sm:text-xs truncate overflow-hidden w-full">
                {game.place}, <b>{game.city.city} ({game.city.cod_province})</b>
              </span> : null
            }
          </div>
        </div>
        <dd className="flex flex-col sm:flex-row w-full items-center gap-3 sm:col-span-6">
          {
            RolesAssociatedToGroup[group].map((role, index) => {
              return (
                <div key={`${game.cod_game}-${index}`} className="w-full">
                  <ConstraintsAssignmentCard
                    intl={intl}
                    role={role}
                    game={game}
                    distances={distances}
                    relocations={relocations}
                    okStatus={okStatus}
                    options={game.assignment_options}
                    userKwargs={userKwargs}
                    setUserKwargs={setUserKwargs}
                    setToSave={setToSave}
                    taskData={taskData}
                    fetching={fetching}
                    algoType={algoType}
                  />
                </div>
              )
            })
          }
        </dd>
        <div className="flex flex-row sm:flex-col justify-between mt-4 sm:mt-0 gap-4 sm:gap-1">
          <Button styleType="white" className="text-xxs h-8 relative min-w-fit" onClick={() => setShowGameParameters(game.cod_game)}>
            <AdjustmentsHorizontalIcon className="w-4 h-4 mx-2"/>
            <div className="w-full flex flex-row justify-between absolute">
              {gameAssignedMembers.length
                ? <SmallNotification classStyle="z-20" color="bg-am-700 bg-opacity-50 ml-2" counter={gameAssignedMembers.length} />
                : <div></div>
              }
              {gameForbiddenMembers.length
                ? <SmallNotification classStyle="z-20" color="bg-red-400 bg-opacity-50 mr-2" counter={gameForbiddenMembers.length} />
                : <></>
              }
            </div>
          </Button>
          {
            fetching ? <div className="w-full sm:mt-2"><Placeholder height="h-8 sm:h-12" classNames="rounded-2xl"/></div>
              : taskData.cod_status === 'END' || (taskData.cod_status === 'ACCEPTED' && taskData.jsn_result?.solution_by_game[game.cod_game])
              ? <CostKpi intl={intl} gameKpi={taskData.jsn_result?.solution_by_game[game.cod_game]}/>
              : null
          }
        </div>
      </>
    })
  });
}