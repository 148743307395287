const it_faq = {
    support:"Supporto",
    faq: "Domande frequenti",
    button_faq: "Apri Ticket",
    modal_title: "Apri un ticket e ricevi supporto da RefMan",
    type_problem: "Tipologia del problema",
    measure_problem: "In che misura il problema ostacola il tuo lavoro?",
    subject_problem: "Oggetto del problema",
    describe_problem: "Descrivi il tuo problema",
    attachment: "Eventuali allegati",
    send_problem: "Invia",
    error_message: "Compila il campo richiesto.",
    risk_one: "Posso continuare a lavorare senza problemi, è solo un fastidio.",
    risk_two: "Ostacola abbastanza, ma posso continuare a lavorare.",
    risk_three: "Non posso lavorare, da sistemare immediatamente.",
    risk_four: "Non è un problema, è una proposta di miglioramento.",
    category_one: "Non accedo al servizio / la pagina non è disponibile",
    category_three: "I dati forniti sono diversi da quanto atteso",
    category_four: "Il sistema è andato in blocco",
    category_five: "Altro",
    missing_data: "Dati mancanti",
    success:"Elemento aggiunto correttamente",
    select:"Seleziona un'opzione"
}

export default it_faq;