import React from "react";
import {useHistory} from "react-router-dom";

export function TimeOut() {

  const pushHistory = useHistory().push;


  return <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div className="text-center">
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Tempo scaduto</h1>
      <p className="mt-6 text-base leading-7 text-gray-600">Hai terminato il tempo a disposizione.</p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <button
          className="rounded-md bg-am-200 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-am-400
           focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-am-400"
          onClick={() => pushHistory('/technical-area/quiz')}
        >
          Torna ai tuoi quiz
        </button>
      </div>
    </div>
  </main>
}
