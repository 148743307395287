import React, {createContext, useState} from 'react';
import AuthenticationService from "@services/AuthenticationService";

const DataContext = createContext({});
const {Provider} = DataContext;
const DataProvider = ({children}) => {

  const groups = AuthenticationService.getUserGroups();

  const [group, setGroup] = useState(groups[0]);
  const [members, setMembers] = useState(null);
  const [picsUrls, setPicsUrls] = useState(null);
  const [categories, setCategories] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [teams, setTeams] = useState(null);
  const [cities, setCities] = useState(null);
  const [levels, setLevels] = useState(null);
  const [nominations, setNominations] = useState({idFipCode: undefined, data: null})
  const [absences, setAbsences] = useState({idFipCode: undefined, data: null})
  const [evaluations, setEvaluations] = useState({idFipCode: undefined, data: null, toRead: 0})
  const [reasonTypes, setReasonTypes] = useState(null);
  const [threads, setThreads] = useState(null);

  return (
    <Provider
      value={{
        group, setGroup: (v) => setGroup(v),
        members, setMembers: (v) => setMembers(v),
        picsUrls, setPicsUrls: (v) => setPicsUrls(v),
        categories, setCategories: (v) => setCategories(v),
        jobs, setJobs: (v) => setJobs(v),
        teams, setTeams: (v) => setTeams(v),
        cities, setCities: (v) => setCities(v),
        levels, setLevels: (v) => setLevels(v),
        nominations, setNominations: (v) => setNominations(v),
        absences, setAbsences: (v) => setAbsences(v),
        evaluations, setEvaluations: (v) => setEvaluations(v),
        reasonTypes, setReasonTypes: (v) => setReasonTypes(v),
        threads, setThreads: (v) => setThreads(v),
      }}
    >
      {children}
    </Provider>
  );
};
export {DataContext, DataProvider}