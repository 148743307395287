import React from "react";
import {RolesType} from "@components/RefMan/Reports/ReportsIndex/ObserverReportsIndex.type";
import {EvaluationsResultsDataType} from "@components/RefMan/Reports/ReportsMatch/ObserverReportsMatch.type";

export const getButtons = (
  role: RolesType,
  results: EvaluationsResultsDataType | undefined,
  handleReadFormClick: () => void,
  handleFillFormClick: () => void,
  handleReopenFormClick: () => void,
) => {
  if (results?.status && results.status.toLowerCase() === "approved" && role === 'admin') {
    return <div className="flex flex-row flex-nowrap justify-evenly">
      <button
        type="button"
        className="rounded-md bg-am-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-am-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={handleReadFormClick}
      >
        Leggi
      </button>
      <button
        type="button"
        className="rounded-md bg-am-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-am-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={handleReopenFormClick}
      >
        Riapri
      </button>
    </div>
  } else if (results?.status && results.status.toLowerCase() === "approved") {
    return <button
      type="button"
      className="rounded-md bg-am-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-am-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
      onClick={handleReadFormClick}
    >
      Leggi
    </button>
  } else if (role === 'viewer') {
    return <div
      className="border-2 px-3 py-1 rounded-md text-gray-800 border-gray-800 text-sm font-semibold w-fit m-auto cursor-not-allowed">
      Non consultabile
    </div>
  } else {
    return <button
      type="button"
      className="rounded-md bg-am-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-am-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
      onClick={handleFillFormClick}
    >
      Compila
    </button>
  }
}