import { QuestionItemType, questionnaireType, SectionsType } from "@utils/questionnaires/type/questionnaire.type";
import React from "react";
import { ResultsType, ResultType } from "@components/RefMan/Reports/ReportsForm/ReportsForm.type"
import ObserverReportsReaderRadio
  from "@components/RefMan/Reports/ReportsReader/ObserverReportsReaderRadio/ObserverReportsReaderRadio";
import ObserverReportsReaderSelect
  from "@components/RefMan/Reports/ReportsReader/ObserverReportsReaderSelect/ObserverReportsReaderSelect";
import ObserverReportsReaderInputText
  from "@components/RefMan/Reports/ReportsReader/ObserverReportsReaderInputText/ObserverReportsReaderInputText";

/**
 * @function getQuestionById
 *
 * @param {string} id Question id
 * @param {questionnaireType} questionnaires Questionnaire data
 */
export const getQuestionById = (
  id: string,
  questionnaires: questionnaireType | undefined
): QuestionItemType | undefined => {
  let question: QuestionItemType | undefined = undefined;
  if (!questionnaires) {
    throw question;
  }
  const questionFoundError: Error = new Error();
  try {
    questionnaires.sections.forEach((section: SectionsType) => {
      section.items.forEach((currentQuestion: QuestionItemType) => {
        if (currentQuestion.id === id) {
          question = currentQuestion;
          throw questionFoundError;
        }
      })
    })
  } catch (e) {
    if (e !== questionFoundError) {
      throw e;
    }
  }
  return question;
}

/**
 * @function getQuestionComponent
 *
 * @param result
 * @param {number} sectionIndex
 */
export const getDataComponent = (
  result: any,
  sectionIndex: number
): React.ReactElement => {
  const type = result?.question?.type ? result.question.type : "none";
  switch (type) {
    case "radio":
      return <ObserverReportsReaderRadio key={sectionIndex} result={result} />
    case "select":
      return <ObserverReportsReaderSelect key={sectionIndex} result={result} />
    case "text-input":
      return <ObserverReportsReaderInputText key={sectionIndex} result={result} />
    case "total-score-function":
      return <ObserverReportsReaderInputText key={sectionIndex} result={result} />
    case "none":
    default:
      console.error("[REFMAN-ERROR]", `Unknown type of data component${result?.question?.type ? " (" + result?.question?.type + ")" : ""}`);
      return <></>
  }
}

export const parseResults = (
  results: ResultsType,
  questionnaires: questionnaireType | undefined
) => {
  let resultsParsed: any = {};

  if (!questionnaires) {
    return undefined;
  }

  Object.values(results).forEach((result: ResultType) => {
    if (resultsParsed[result.idSection] === undefined) {
      resultsParsed[result.idSection] = {
        title: questionnaires.sections[result.idSection].title,
        results: []
      }
    }
    resultsParsed[result.idSection].results.push({
      ...result, ...{
        question: questionnaires.sections[result.idSection].items.find(
          (item: QuestionItemType) =>
            item.id === result.id)
      }
    })
  })
  return resultsParsed ? resultsParsed : undefined;
}