import React, {useContext, useEffect, useState} from "react";

// Services
import {DataContext} from "@services/DataContext/DataContext";

// Table
import {tableIcons} from "@ui-components/TableIcons";
import TableStyle from "@ui-components/TableStyle";

import {COLUMNS, OPTIONS, LOCALIZATION} from "./services";
import {onRowUpdate, onRowDelete} from "./services";
import MaterialTable from "@material-table/core";

// Ui
import Placeholder from "@ui-components/Placeholder";
import AddCategoryDialog from "./AddCategoryDialog";


export default function EntryCategories({push, group, categories}) {

  const dataContext = useContext(DataContext);

  const [data, setData] = useState();
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    if (categories) {
      setData(Object.values(categories)
        .filter(i => i.cod_group === group.toUpperCase())
      )
    }
  }, [categories, group]);

  const _onRowUpdate = (newData, oldData) => onRowUpdate(newData, oldData, dataContext, push);
  const _onRowDelete = (oldData) => onRowDelete(oldData, dataContext, push)
  return (
    <>
      {data ?
        <TableStyle>
          <MaterialTable
            columns={COLUMNS}
            data={data}
            icons={tableIcons}
            options={OPTIONS}
            localization={LOCALIZATION}
            editable={{
              onRowUpdate: _onRowUpdate,
              onRowDelete: _onRowDelete
            }}
            actions={[
              {
                icon: tableIcons.Add,
                tooltip: "Inserisci",
                onClick: () => setShowAdd(true),
                isFreeAction: true,
              },
            ]}
          />
        </TableStyle> :
        <div className="flex flex-col w-full mb-4 gap-2">
          <Placeholder height="h-10" classNames="rounded-lg bg-am-700"/>
          <Placeholder height="h-96" classNames="rounded-lg"/>
        </div>
      }
      <AddCategoryDialog
        group={group}
        open={showAdd}
        toggleOpen={() => setShowAdd(false)}
        dataContext={dataContext}
        push={push}
      />
    </>
  );
}
