import React from "react";
import { Switch } from "react-router-dom";

import Profile from "../pages/Profile";
import MemberDetail from "@pages/Members/Detail";
import DeclineGame from "@pages/Nominations/DeclineGame";

import AppNavigation from "../AppNavigation";
import PrivateRoute from "../PrivateRoute";
import menu from "../AppNavigation/menu";
import Container from "../Container";
import {ActivityDetail} from "@pages/Activities/Detail";
import {ThreadDetail} from "@pages/Threads/Detail";
import useThreads from "@services/hooks/useThreads";
import useEvaluations from "@services/hooks/useEvaluations";
import {QuizDetail} from "@pages/TechnicalArea/Quiz";
import {SessionFrame} from "@components/RefMan/Quiz/Session/Frame/SessionFrame";
import Algorithms from "@pages/Nominations/Algorithms";
import BasicAlgorithmSession from "@pages/Nominations/Algorithms/BasicAlgorithmSession";

function Dashboard() {
  useThreads();
  useEvaluations({});

  const routes = menu.reduce(
    (acc, route) => [...acc, ...(route.dropdown ? route.dropdown : [route])],
    []
  );
  return (
    <AppNavigation>
      <Container>
        <Switch>
          {routes.map((route) => {
            return (
              <PrivateRoute
                key={route.href}
                exact={route.exact ?? true}
                component={route.component}
                path={route.href}
                role={route.role}
                scopes={route.scopes}
              />
            );
          })}
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/members/:id_fip_code" component={MemberDetail} />
          <PrivateRoute exact path="/activities/:id_activity" component={ActivityDetail} />
          <PrivateRoute exact path="/threads/:id_thread" component={ThreadDetail} />
          <PrivateRoute exact path="/decline-game" component={DeclineGame} />
          <PrivateRoute exact path="/technical-area/quiz/:id_quiz" component={QuizDetail} />
          <PrivateRoute exact path="/technical-area/quiz/session/:id_quiz" component={SessionFrame} />
          <PrivateRoute exact path="/nominations/algorithms/:algo_type" component={Algorithms} />
          <PrivateRoute exact path="/nominations/algorithms/basic/:id_session" component={BasicAlgorithmSession} />
        </Switch>
      </Container>
    </AppNavigation>
  );
}

export default Dashboard;
