import React, { useState, useEffect } from 'react';

// Services
import { api } from '@services/apiRequest';

// Model
import {BasicAlgorithmTaskSchema} from '@pages/Nominations/Algorithms/Algorithms.types';


function useBasicTasks(group: 'ref' | 'udc') {
  const [tasks, setTasks] = useState<BasicAlgorithmTaskSchema[] | undefined>(undefined);
  
  useEffect(() => {
    async function _fetchBasicTasks() {
      const { data } = await api.get<BasicAlgorithmTaskSchema[]>(`/algorithms/basic?cod_group=${group.toUpperCase()}`)

      setTasks(data);
    }

    if (group) {
      _fetchBasicTasks();
    } else {
      setTasks(undefined);
    }
  }, [group]);
  
  return tasks;
}

export default useBasicTasks;