import React, {useCallback, useContext, useState} from "react";

// Services
import {NotificationsContext} from "../../Notifications";
import {useIntl, FormattedMessage} from "react-intl";
import {api} from "@services/apiRequest";

import Button from "../../Button";
import Input from "../../Input";
import Modal from "../../Modal";

import UpdateGroups from "@ui-components/pages/Roles/UpdateGroups";
import usePolicies from "./Hooks/usePolicies";
import SelectPolicies from "./SelectPolicies";
import Placeholder from "../../Placeholder";
import Select from "@ui-components/Select";


function AddRoleDialog({open, toggleOpen, roles, setRoles}) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [groups, setgroups] = useState("");
  const [level, setLevel] = useState();
  const policies = usePolicies();
  const [scopes, setScopes] = useState([]);
  const {push} = useContext(NotificationsContext);

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!name || !groups || !description) {
          push({title: "Dati mancanti", type: "error"});
          return;
        }

        setLoading(true);
        const {data} = await api.post("/roles", {
          name: name.toLocaleLowerCase(),
          description,
          scopes: scopes.join(";"),
          groups: groups,
          level: level,
        });
        setRoles({...roles, [data.id]: data});
        toggleOpen();
        push({
          title: intl.formatMessage({id: "role_created_successfully"}),
          type: "success",
        });
      } catch (error) {
        console.log(error)
        push({
          title: intl.formatMessage({id: "role_already_used"}),
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [name, setRoles, toggleOpen, roles, description, groups, level, scopes, push, intl]
  );

  return (
    <div>
      <Modal opened={open} onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            <FormattedMessage id="add_role"/>
          </h3>
        </div>

        <form id="add-role-form" onSubmit={_onSubmit}>
          <div>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label={intl.formatMessage({id: "role_name"})}
              type="text"
              errorMessage={intl.formatMessage({id: "add_role_name"})}
              required
            />
          </div>
          <div>
            <Input
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              label={intl.formatMessage({id: "description"})}
              type="text"
            />
          </div>
          <div>
            <UpdateGroups value={groups} onChange={setgroups}/>
          </div>
          <div>
            <Select
              options={[
                {value: undefined, name: ""},
                {value: 'all', name: "Totale"},
                {value: 'c', name: "Serie C"},
                {value: 'd', name: "Serie D"},
                {value: 'r', name: "Regionale"},
                {value: 'personal', name: "Personale"},
              ]}
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              label="Livello"
            />
          </div>
          <div className="my-6">
            {policies ? (
              <SelectPolicies
                policies={policies}
                scopes={scopes}
                setScopes={setScopes}
              />
            ) : (
              <div>
                <Placeholder width="w-4/5"/>
                <Placeholder width="w-3/5"/>
              </div>
            )}
          </div>

          <div className="mt-4 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              <FormattedMessage id="add"/>
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddRoleDialog;
