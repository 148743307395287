import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, getXYChart} from "@components/Charts/commons";


const LKP_STATUS = {
    triple: 'In terna',
    double: 'In doppio',
    single: 'In singolo',
    empty: 'Scoperte'
}

const COLORS = {
  double: am5.color("rgb(0, 60, 80)"),
  triple: am5.color("rgb(145,190,136)"),
  single: am5.color("rgb(222,164,54)"),
  empty: am5.color("rgb(146,148,150)"),
}

function GameTrendChart({id, data, categories}) {

  useLayoutEffect(
    () => {

      if (!data)
        return

      const _data = data.map(i => ({...i, num_games_total: 0}))

      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)])
      root.locale = am5locales_it_IT;
      root.numberFormatter.set("numberFormat", "#,###.");
      root.dateFormatter.set("dateFormat", "MMM yyyy")

      // Create chart
      let chart = getXYChart(root, {layout: root.verticalLayout})
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);

      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );

      legend.markers.template.setAll({
        width: 10,
        height: 10
      });

      legend.labels.template.setAll({
        fontSize: 10,
        fontWeight: "300"
      });

      // Create axes
      // CategoryAxis: is the only axis type that requires its own data as well as data field set up.
      let xAxis = chart.xAxes.push(
        chart.xAxes.push(am5xy.DateAxis.new(root, {
            baseInterval: {timeUnit: 'month', count: 1},
            gridIntervals: [{timeUnit: 'month', count: 1}],
            renderer: am5xy.AxisRendererX.new(root, {}),
          })
        ))
      xAxis.data.setAll(_data);
      let xRenderer = xAxis.get("renderer");
      xRenderer.grid.template.set("forceHidden", true);
      xRenderer.labels.template.set("fontSize", 10);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.05,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root, {}),
        calculateTotals: true
      }))
      let yRenderer = yAxis.get("renderer");
      yRenderer.grid.template.set("forceHidden", true);
      yRenderer.labels.template.set("forceHidden", true);


      // Add series
      function makeSeries(name) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: LKP_STATUS[name],
          xAxis: xAxis,
          yAxis: yAxis,
          fill: COLORS[name],
          stroke: am5.color("rgb(255,255,255)"),
          valueYField: `num_games_${name}`,
          valueXField: "month",
          stacked: true,
          tooltip: name === 'total' ? null : am5.Tooltip.new(root, {
            labelText: "{valueX.formatDate()}\n[bold]{name}: {valueY} ({valueYTotalPercent}%)[/]",
            tooltipY: am5.percent(10),
            fontSize: 8
          })
        }))

        series.columns.template.setAll({
          fillOpacity: 0.65,
          visible: name !== 'total',
          cornerRadiusTL: 2,
          cornerRadiusTR: 2,
          cornerRadiusBL: 2,
          cornerRadiusBR: 2,
        });

        if (name === 'total') {
          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationY: 0.5,
              sprite: am5.Label.new(root, {
                text: "[bold]{valueYTotal}[/]",
                centerX: am5.p50,
                centerY: am5.p100,
                populateText: true,
                fontSize: 12
              })
            });
          });
        }


        series.data.processor = am5.DataProcessor.new(root, {
          dateFields: ['month'],
          dateFormat: "yyyy-MM-dd"
        })

        legend.data.push(series);
        series.data.setAll(_data);

        if (name === 'total') {
          series.get("legendDataItem").get("itemContainer").hide();
        }

        series.appear(1000);

        return series
      }

      categories.forEach(c => {
        makeSeries(c)
      })
      makeSeries('total')


      return () => {
        root.dispose()
      }
    },
    [id, data, categories] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return <div id={id} className={`w-full h-80`}/>
}

export default React.memo(GameTrendChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
