import apiRequest from "../../../services/apiRequest";

export const COLUMNS = [
  {
    title: "Codice",
    field: "cod_level",
    editable: "never"
  },
  {
    title: "Campionato",
    field: "des_level"
  },
  {
    title: "Forma estesa",
    field: "des_level_extended"
  },
  {
    title: "Algoritmo Elite",
    field: "designator_level"
  },
  {
    title: "Basic Arbitri",
    field: "flg_basic_ref",
    type: "boolean"
  },
  {
    title: "Basic Udc",
    field: "flg_basic_udc",
    type: "boolean"
  },
  {
    title: "Ordine",
    field: "level_order",
    type: "numeric",
    defaultSort: "asc",
  }
]

export const OPTIONS = {
  filtering: false,
  toolbar: true,
  paging: false,
  sorting: true,
  showTitle: false,
  filterCellStyle: {maxWidth: 200}
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
};


export const onRowUpdate = (newData, oldData, dataContext, push) =>
  new Promise((resolve, reject) => {
    let isEqual = true;
    Object.entries(newData).forEach(([key, value]) => {
      if (oldData[key] !== value) {
        isEqual = false;
      }
    });
    if (isEqual) {
      resolve();
    } else {
      apiRequest
        .put(`/entry/levels/${oldData.cod_level.replace('/', '--')}`, newData)
        .then((res) => {
          dataContext.setLevels({...dataContext.levels, [res['cod_level']]: res})
          push({title: "Riga aggiornata con successo", type: "success"});
          resolve();
        })
        .catch(() => {
          push({
            title: "Errore durante l'aggiornamento della riga",
            type: "error",
          });
          reject();
        });
    }
  });
