import React, { useContext, useEffect, useState } from 'react';

// Services
import AuthenticationService from '@services/AuthenticationService';
import { NotificationsContext } from '@ui-components/Notifications';
import { DataContext } from '@services/DataContext/DataContext';
import useMembers from '@services/hooks/useMembers';
import useLevels from '@services/hooks/useLevels';
import { api } from '@services/apiRequest';

// Components
import { GamesLevelFilter, GamesPeriodFilter, GamesProvinceFilter, getData } from '@pages/Games/Games.lib';
import ObserverNominationDialog from '@pages/Nominations/Observers/ObserverNominationDialog';
import RefManList from '@components/RefMan/RefManList/RefManList';
import { getListItems, getMaxLevelOrder } from './Observers.lib';
import { MemberSearch } from '@pages/Members/Members.lib';
import Placeholder from '@ui-components/Placeholder';
import { PageHeading } from '@ui-components/Container';
import GroupSelection from '@utils/groupSelection';

// Types
import { GamesSchema, PaginatedGames, SelectedLevel, SelectedProvince } from '@pages/Games/Games.type';
import { LevelSchema } from '@services/types/level';
import { Member } from '@services/types/member';
import Toggle from '@ui-components/Toggle';
import Label from '@ui-components/Label';
import { useIntl } from 'react-intl';

let searchSelectTimeout: NodeJS.Timeout;

export function NominationObservers() {
  const { push } = useContext(NotificationsContext);
  const dataContext: any = useContext(DataContext);
  const groups = AuthenticationService.getUserGroups();
  const levels: LevelSchema[] = useLevels({ asObj: false });
  const [data, setData] = useState<PaginatedGames | undefined>(undefined);
  const observers: Member[] = useMembers({ assigned: false, asObj: false, jobs: ['OBS', 'IST', 'TUT'] });

  let initialEndDate = new Date();
  initialEndDate.setDate(initialEndDate.getDate() + 20);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(initialEndDate);
  const [filterMember, setFilterMember] = useState<string>();
  const [filterGameNumber, setFilterGameNumber] = useState<string>();
  const [withObserver, setWithObserver] = useState<boolean>(false);

  const [selectedLevels, setSelectedLevels] = useState<SelectedLevel[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<SelectedProvince[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [managedGame, setManagedGame] = useState<GamesSchema | undefined>(undefined);
  const [filterLevelByRole, setFilterLevelByRole] = useState<string | undefined>(undefined);
  const [offset, setOffset] = useState<number>(0);
  const limit = 10;
  let allowApi = 0;
  const intl = useIntl();

  const selectedGroup: 'ref' | 'udc' = dataContext.group;
  let _levels = levels?.filter((i) => i[`survey_model_${selectedGroup}`]) ?? [];
  if (filterLevelByRole && _levels.length) {
    if (dataContext.group === 'ref' && filterLevelByRole === 'all') {
      _levels = _levels.filter((i) => i.survey_model_ref === 1);
    } else {
      const maxLevelOrder = getMaxLevelOrder(filterLevelByRole, _levels, dataContext.group);
      _levels = _levels.filter((i) => i.level_order >= maxLevelOrder);
    }
    allowApi = 1;
  }

  useEffect(() => {
    api.get(`/roles/${localStorage.getItem('refman_role')}`).then(({ data }) => {
      setFilterLevelByRole(data.level);
    });
  }, []);

  const [textFilters, setTextFilters] = useState({ game_number: '', city: '', team: '', member: '' });
  useEffect(() => {
    setTextFilters({
      game_number: filterGameNumber && filterGameNumber.length >= 2 ? filterGameNumber : '',
      member: filterMember && filterMember.length > 2 ? filterMember : '',
      city: '',
      team: '',
    });
  }, [filterMember, filterGameNumber]);

  /**
   * Handler for
   * - showTemp
   * - startDate
   * - endDate
   * - selectedLevels
   * - selectedProvinces
   */
  useEffect(() => {
    if (allowApi) {
      const _selectedLevels = selectedLevels.length
        ? selectedLevels
        : _levels.map((i) => ({
            value: i.cod_level,
            label: '',
          }));
      void getData(
        true,
        withObserver ? (selectedGroup === 'ref' ? 'OBSR' : 'OBSU') : undefined,
        startDate,
        endDate,
        textFilters,
        selectedProvinces,
        _selectedLevels,
        push,
        undefined,
        undefined,
        setData
      );
    }
  }, [refresh, startDate, endDate, selectedLevels, selectedProvinces, withObserver, allowApi]);

  /**
   * Handler for
   * - textFilters
   */
  useEffect(() => {
    if (searchSelectTimeout) {
      clearTimeout(searchSelectTimeout);
    }
    searchSelectTimeout = setTimeout(() => {
      const _selectedLevels = selectedLevels.length
        ? selectedLevels
        : _levels.map((i) => ({
            value: i.cod_level,
            label: '',
          }));
      if (allowApi) {
        void getData(
          true,
          withObserver ? (selectedGroup === 'ref' ? 'OBSR' : 'OBSU') : undefined,
          startDate,
          endDate,
          textFilters,
          selectedProvinces,
          _selectedLevels,
          push,
          undefined,
          undefined,
          setData
        );
      }
    }, 600);
  }, [textFilters]);

  return (
    <>
      <PageHeading
        title='Designazioni Osservatori'
        historyBackPath='_back'
        contentActions={
          groups.length > 1 ? (
            <div className='mr-6 mt-2'>
              <GroupSelection selectedGroup={dataContext.group} setSelectedGroup={dataContext.setGroup} />
            </div>
          ) : null
        }
      />
      {_levels ? (
        <>
          <div className='flex flex-col sm:flex-row justify-between gap-3'>
            <div className='w-full sm:w-1/3'>
              <GamesPeriodFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
            </div>
            <div className='w-full sm:w-1/3'>
              <GamesLevelFilter levels={_levels} selectedLevels={selectedLevels} setSelectedLevels={setSelectedLevels} />
            </div>
            <div className='w-full sm:w-1/3'>
              <GamesProvinceFilter selectedProvinces={selectedProvinces} setSelectedProvinces={setSelectedProvinces} />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row justify-center gap-3'>
            <div className='w-full sm:w-1/4'>
              <MemberSearch label='Cerca gara' searchKey={filterGameNumber} setSearchKey={setFilterGameNumber} />
            </div>
            <div className='w-full sm:w-1/4'>
              <MemberSearch label='Cerca tesserato designato' searchKey={filterMember} setSearchKey={setFilterMember} />
            </div>
            <div className='w-full sm:w-1/4 sm:pl-2'>
              <Label>Con osservatore</Label>
              <div className='-mt-3'>
                {/*@ts-ignore*/}
                <Toggle vertical checked={withObserver} onChange={() => setWithObserver(!withObserver)} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='flex flex-row gap-10'>
          <Placeholder height='h-10' classNames='rounded-lg w-1/3' />
          <Placeholder height='h-10' classNames='rounded-lg w-1/3' />
          <Placeholder height='h-10' classNames='rounded-lg w-1/3' />
        </div>
      )}
      {data ? (
        <div className='my-5'>
          <RefManList
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            containerId='observer-reports-list'
            total={data.total}
            items={getListItems(intl, data.items, selectedGroup, true, false, setManagedGame)}
            rowClassNames={['sm:grid', 'sm:grid-cols-5', 'sm:gap-3', '!cursor-default']}
            pagination='frontend'
          />
        </div>
      ) : (
        [...new Array(11)].map((each, i) => <Placeholder key={i} height='h-20' classNames='rounded-lg bg-am-600' />)
      )}
      {managedGame ? (
        <ObserverNominationDialog
          onExit={() => setManagedGame(undefined)}
          group={selectedGroup}
          game={managedGame}
          observers={observers}
          setRefresh={setRefresh}
        />
      ) : null}
    </>
  );
}
