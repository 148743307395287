import {useContext, useEffect, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

import {QuizDetailResponseType} from "@components/RefMan/Quiz/types";


interface QuizDetailHookType {
  id_quiz: number;
  refresh: boolean;
}

export const useQuizDetail = (
  payload: QuizDetailHookType
) => {

  // hooks
  const intl = useIntl();

  // context
  const {push} = useContext(NotificationsContext);

  // state
  const [data, setRawData] = useState<QuizDetailResponseType | undefined>(undefined);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (
      async () => {
        setLoading(true);
        try {
          const {data} = await api.get<QuizDetailResponseType>(`/quizzes/${payload.id_quiz}`);
          setRawData(data);
        } catch (e) {
          push({type: 'error', title: intl.formatMessage({id: 'server_error'})})
        } finally {
          setLoading(false);
        }
      }
    )();
  }, [intl, JSON.stringify(payload), push])

  return {data, loading};
}