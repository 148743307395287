import React, {useContext, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {useHistory} from "react-router-dom";
import {api} from "@services/apiRequest";

// Ui
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import {DeleteActivityDialogProps} from "@pages/Activities/Detail/Detail.type";


export function DeleteActivityDialog({onExit, id}: DeleteActivityDialogProps) {

  const history = useHistory();
  const {push} = useContext(NotificationsContext);
  const [deleting, setDeleting] = useState<boolean>(false)

  const sendDelete = () => {
    setDeleting(true)
    api.delete(`/activities/${id}`)
      .then(() => {
        push({title: "Attività eliminata con successo", type: "success"});
        history.push('/activities')
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setDeleting(false))
  }

  return (
    <Modal
      onExit={onExit}
    >
      <div className="m-5">
        <h1 className="font-bold sm:text-xl">Confermi di voler eliminare questa attività tecnica?</h1>
        <h3 className="text-xs sm:text-sm my-3">Non sarà possibile annullare l'operazione.</h3>
        {/*Todo: mettere possibilità di notificare tutti in caso di annullamento*/}
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button onClick={sendDelete} submitting={deleting}>
          Conferma
        </Button>
      </div>
    </Modal>
  );
}
