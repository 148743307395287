import {CheckIcon} from "@heroicons/react/20/solid";
import {ObserverReportsFormStepsType} from "./ObserverReportsFormSteps.type";

const ObserverReportsFormSteps = ({
                                    currentSection,
                                    completedSections,
                                    setCurrentSection,
                                    questionnaires
                                  }: ObserverReportsFormStepsType) => {

  return <div className="observerreportsformsteps__steps">
    <nav
      aria-label="Progress"
      className="flex flex-nowrap justify-center"
    >
      <ol role="list" className="flex justify-between items-center w-full">
        {questionnaires.sections.map((step, index) => (
          <li
            key={index}
            className={"cursor-pointer relative" + (index !== questionnaires.sections.length - 1 ? ' w-full' : '')}
            title={step.title}
            onClick={() => {
              setCurrentSection(index)
            }}
          >
            {completedSections?.includes(index) ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-am-200"/>
                </div>
                <div
                  className="relative flex h-7 w-7 items-center justify-center rounded-full bg-am-200 hover:bg-am-700"
                >
                  <CheckIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                  <span className="sr-only">{step.title}</span>
                </div>
              </>
            ) : currentSection === index ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200"/>
                </div>
                <div
                  className="relative flex h-7 w-7 items-center justify-center rounded-full border-2 border-am-200 bg-white"
                  aria-current="step"
                >
                  <span className="h-2.5 w-2.5 rounded-full bg-am-200" aria-hidden="true"/>
                  <span className="sr-only">{step.title}</span>
                </div>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200"/>
                </div>
                <div
                  className="group relative flex h-7 w-7 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.title}</span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  </div>;
}
export default ObserverReportsFormSteps;