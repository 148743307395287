import {useContext, useEffect} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "../DataContext/DataContext";
import apiRequest from "../apiRequest";

const useNominations = ({idFipCode, includeTemp = false, role = null}) => {

  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    let closed = false;
    const fetchData = async () => {
      try {
        let path = `/nominations?id_fip_code=${idFipCode}&temp=${includeTemp}`
        path = role ? `${path}&role=${role}` : path
        const data = await apiRequest.get(path);
        if (!closed) {
          dataContext.setNominations({idFipCode: idFipCode, data: data.items});
        }
      } catch (error) {
        console.log(error)
        dataContext.setNominations({idFipCode: undefined, data: undefined});
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if (idFipCode && (!dataContext.nominations.data || dataContext.nominations.idFipCode !== idFipCode)) {
      fetchData();
    }

    return () => {
      closed = true;
    };
  }, [idFipCode, dataContext.nominations]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (!dataContext.nominations.data || dataContext.nominations.idFipCode !== idFipCode) {
    return undefined
  }
  return dataContext.nominations.data;

};

export default useNominations;
