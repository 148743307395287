import React from "react";
import Button from "@ui-components/Button";
import Placeholder from "@ui-components/Placeholder";
import SearchSelect from "@ui-components/SearchSelect";
import {TIME_OPTIONS, WEEKDAY_OPTIONS} from "@utils/constants";

import {FormatDateOptions, IntlShape} from "react-intl";
import {FilterAndAddButtonPropsType} from "./Absences.type";
import {Absence, AbsenceReason} from "@services/types/absence";

export const dateFormat: FormatDateOptions = {day: "numeric", month: "numeric", year: "2-digit"}

export const dateFormatExtended: FormatDateOptions = {weekday: "long", day: "numeric", month: "long", year: "numeric"}
export const dateFormatWithWeekday: FormatDateOptions = {
  weekday: "short",
  day: "numeric",
  month: "numeric",
  year: "2-digit"
}
export const timeStampFormat: FormatDateOptions = {
  day: "numeric",
  month: "numeric",
  year: "2-digit",
  hour: "2-digit",
  minute: "numeric"
}

export const getInitAbsence = (idFipCode: number) => ({
  id_fip_code: idFipCode,
  id_absence_reason: 0,
  id_added_user: 0,
  id_added_role: 0,
  absence_reason: {
    id: 0,
    id_reason_type: 1,
    des_absence: '',
    flg_group: false,
    reason_type: {
      id: 0,
      des_reason_type: '',
      flg_public: true
    }
  }
})

export const getMissingDataError = (
  absence: Absence,
  dailyOrPeriod: 'daily' | 'period',
  allOrSomeDays: 'all' | 'some',
  idTypeOrario: number
) => {
  const dat_from = absence.absence_reason.dat_from
  const dat_to = absence.absence_reason.dat_to
  let err
  if ((absence.absence_reason.des_absence ?? '').length < 5) {
    err = 'Inserisci la motivazione'
  }
  if (allOrSomeDays === 'some' && (absence.absence_reason.weekdays ?? '').length === 0) {
    err = 'Seleziona almeno un giorno della settimana'
  }
  if (dailyOrPeriod === 'period') {
    if (!dat_to) {
      err = 'Seleziona la data di fine periodo'
    }
    if (dat_from && dat_to && dat_to <= dat_from) {
      err = 'La data di fine deve essere successiva a quella di inizio'
    }
  }
  if (!dat_from) {
    err = 'Seleziona la data'
  }
  if (absence.absence_reason.id_reason_type === idTypeOrario) {
    const timeFrom = (absence.absence_reason.time_from ?? '').slice(0, 2)
    const timeTo = (absence.absence_reason.time_to ?? '').slice(0, 2)
    if (!timeFrom && !timeTo) {
      err = 'Inserire la fascia oraria di disponibilità'
    } else if (timeFrom === '09' && !timeTo) {
      err = "La fascia oraria è di fatto l'intera giornata"
    } else if (Number(timeFrom) >= Number(timeTo || '24')) {
      err = "L'orario di fine disponibilità deve essere maggiore dell'orario di inizio"
    }
  }
  return err
}

export const AbsencePlaceHolder = () => (
  <div className="flex flex-col w-full mb-4 gap-2">
    <div className="flex flex-row gap-10">
      <Placeholder height="h-10" classNames="rounded-lg w-1/3"/>
      <Placeholder height="h-10" classNames="rounded-lg w-1/3"/>
      <Placeholder height="h-10" classNames="rounded-lg w-1/3"/>
    </div>
    <Placeholder height="h-96" classNames="rounded-lg"/>
  </div>
)

export const AbsenceFilterAndAddButton = (
  {selectedTypes, setSelectedTypes, reasonTypes, setShowAdd}: FilterAndAddButtonPropsType
) => (
  <div className="flex flex-col-reverse sm:flex-row gap-4 w-full items-center justify-around">
    <div className="w-full">
      {/*@ts-ignore*/}
      <SearchSelect
        isMulti
        label="Tipologia"
        value={selectedTypes}
        onChange={(e: any) => setSelectedTypes(e)}
        options={reasonTypes.map(i => ({value: i.id, label: i.des_reason_type}))}
      />
    </div>
    <div className="flex w-full justify-center sm:justify-end">
      <Button onClick={() => setShowAdd(true)}>
        Aggiungi
      </Button>
    </div>
  </div>
)

export const getTimePeriodLabel = (reason: string, timeFrom: string | undefined, timeTo: string | undefined) => {
  let time = []
  let timeFromDes
  if (reason === 'Disponibilità oraria') {
    if (timeFrom) {
      timeFromDes = TIME_OPTIONS.filter(i => i.value === timeFrom)[0].name
      time.push(timeFromDes === 'Mattino' ? '' : `dalle ${timeFromDes}`)
    }
    if (timeTo) {
      const timeToDes = TIME_OPTIONS.filter(i => i.value === timeTo)[0].name
      time.push(`fino alle ${timeToDes}`)
    } else {
      if (timeFromDes !== 'Mattino') {
        time.push('in poi')
      }
    }
  }
  return time
}

export const getCardLabels = (
  absenceReason: AbsenceReason,
  intl: IntlShape,
) => {
  const datFrom = absenceReason.dat_from
  const datTo = absenceReason.dat_to
  const timeFrom = absenceReason.time_from
  const timeTo = absenceReason.time_to
  const reason = absenceReason.reason_type.des_reason_type
  const weekDaysList = (absenceReason.weekdays ?? '').split(';').filter(i => i)
  const weekDays = [0, 7].includes(weekDaysList.length) ? undefined
    : WEEKDAY_OPTIONS.filter(i => weekDaysList.includes(i.value)).map(i => i.name)

  const timeList = getTimePeriodLabel(reason, timeFrom, timeTo)
  let period = [datTo ? weekDays ? `Tutti i ${weekDays.join(', ')} dal` : 'Tutti i giorni dal' : '']
  period.push(datTo ? `${intl.formatDate(datFrom, dateFormat)} al ${intl.formatDate(datTo, dateFormat)}`
    : `Il giorno ${intl.formatDate(datFrom, dateFormatWithWeekday)}`)


  return {reason, timeList, period}
}