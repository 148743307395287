import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, getXYChart} from "@components/Charts/commons";

const getChartHigh = (n) => {
  switch (true) {
    case (n < 2):
      return 'h-20'
    case (n < 4):
      return 'h-32'
    case (n < 6):
      return 'h-48'
    case (n < 9):
      return 'h-64'
    case (n < 12):
      return 'h-96'
    default:
      return 'h-[30rem]'
  }
}


function LevelBarChart({id, data}) {

  useLayoutEffect(
    () => {

      if (!data)
        return

      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)])
      root.locale = am5locales_it_IT;
      root.numberFormatter.set("numberFormat", "#,###.");

      // Create chart
      let chart = getXYChart(root, {layout: root.verticalLayout})
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);

      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );

      legend.markers.template.setAll({
        width: 10,
        height: 10
      });

      legend.labels.template.setAll({
        fontSize: 10,
        fontWeight: "300"
      });

      // Create axes
      let yAxis = chart.yAxes.push(
        chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "des_level",
            renderer: am5xy.AxisRendererY.new(root, {
              minGridDistance: 0
            }),
          })
        ))

      yAxis.data.setAll(data);

      let yRenderer = yAxis.get("renderer");
      yRenderer.grid.template.set("forceHidden", true);
      yRenderer.labels.template.set("fontSize", 8);
      yRenderer.labels.template.set("fontWeight", '300');

      let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.05,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererX.new(root, {}),
        calculateTotals: true
      }))
      let xRenderer = xAxis.get("renderer");
      xRenderer.grid.template.set("forceHidden", true);
      xRenderer.labels.template.set("forceHidden", true);


      // Add series
      function makeSeries(name) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name === 'calendar' ? 'Calendario' : 'Designate',
          xAxis: xAxis,
          yAxis: yAxis,
          adjustBulletPosition: false,
          clustered: false,
          fill: name === 'calendar' ? am5.color("rgb(178,178,178)") : am5.color("rgb(0, 60, 80)"),
          // stroke: am5.color("rgb(255,255,255)"),
          valueXField: `num_games_${name}`,
          categoryYField: "des_level",
          categoryXField: "num_games_calendar",
          tooltip: name === 'calendar' ? null : am5.Tooltip.new(root, {
            labelText: "{categoryY}\n[bold]Calendario: {categoryX}\n{name}: {valueX} ({valueXTotalPercent}%)[/]",
            tooltipY: am5.percent(10),
            fontSize: 8
          })
        }))

        series.columns.template.setAll({
          height: name === 'calendar' ? 3 : 4,
          cornerRadiusTR: 4,
          cornerRadiusBR: 4,
          templateField: "color",
        });

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: 1,
            sprite: am5.Circle.new(root, {
              radius: name === 'calendar' ? 4 : 3,
              fill: series.get("fill")
            })
          })
        })

        if (name === 'calendar') {
          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationX: 1,
              locationY: 0.5,
              sprite: am5.Label.new(root, {
                text: "[bold]{valueX}[/]",
                centerY: am5.p50,
                populateText: true,
                fontSize: 8
              })
            });
          });
        }

        legend.data.push(series);
        series.data.setAll(data);

        series.appear(1000);

        return series
      }


      makeSeries('calendar')
      makeSeries('nominated')


      return () => {
        root.dispose()
      }
    },
    [id, data] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return <div id={id} className={`w-full ${getChartHigh(data.length)}`}/>
}

export default React.memo(LevelBarChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
