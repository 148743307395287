import "./App.css";
import React from "react";
import {IntlProvider} from "react-intl";
import {Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "./ui-components/PrivateRoute";
import Dashboard from "./ui-components/Dashboard";
import Login from "./ui-components/pages/Login";
import Registration from "./ui-components/pages/Registration";
import Notifications from "./ui-components/Notifications";
import menu from "./ui-components/AppNavigation/menu";
import Logout from "./ui-components/pages/Logout";
import NotFound from "./ui-components/pages/NotFound";
import ErrorBoundary from "./ui-components/ErrorBoundary";
import locale from "./locale";
import publicRoutes from "./routes/public";
import usePageViews from "@hooks/usePageViews";

import {DataProvider} from "@services/DataContext/DataContext";
import {NavigationProvider} from "@services/NavigationContext";
import {ApiAuthProvider} from "@services/ApiRequestContext";

function App() {
  usePageViews();

  return (
    <NavigationProvider>
      <ErrorBoundary>
        <IntlProvider
          messages={locale[localStorage.getItem("lang")]}
          locale={localStorage.getItem("lang")}
          defaultLocale="it"
        >
          <Notifications>
            <ApiAuthProvider>
              <Switch>
                <PrivateRoute
                  exact
                  path="/"
                  component={() => <Redirect to={{pathname: "/home"}}/>}
                />
                <Route path="/login" component={Login}/>
                <Route path="/logout" component={Logout}/>
                <Route exact path="/registration/:token" component={Registration}/>

                {publicRoutes.map((route, index) =>
                  <Route key={index} path={route.href} component={route.component}/>
                )}

                <DataProvider>
                  <PrivateRoute
                    path={[
                      ...menu
                        .reduce(
                          (acc, route) => [
                            ...acc,
                            ...(route.dropdown ? route.dropdown : [route]),
                          ],
                          []
                        )
                        .map((route) => route.href),
                      "/profile",
                      "/members/:id_fip_code",
                      "/activities/:id_activity",
                      "/threads/:id_thread",
                      "/decline-game",
                      "/technical-area/quiz/:id_quiz",
                      "/technical-area/quiz/session/:id_quiz",
                      "/nominations/algorithms/:algo_type",
                      "/nominations/algorithms/basic/:id_session"
                    ]}
                    component={Dashboard}
                  />
                </DataProvider>
                <Route path="*" component={NotFound}/>
              </Switch>
            </ApiAuthProvider>
          </Notifications>
        </IntlProvider>
      </ErrorBoundary>
    </NavigationProvider>
  );
}

export default App;
