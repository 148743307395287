import React, {Dispatch, SetStateAction} from "react";

import SearchSelect from "@ui-components/SearchSelect";
import useMembers from "@services/hooks/useMembers";
import {Member} from "@services/types/member";
import {QuizFilterType} from "@components/RefMan/Quiz/types";


export function QuizAuthorFilter({filter, setFilters}: {
  filter: QuizFilterType,
  setFilters: Dispatch<SetStateAction<QuizFilterType>>
}) {
  const members: Member[] = useMembers({onlyActive: true, assigned: true, asObj: false, jobs: ['IST']}) as Member[];
  const memberOptions = members?.map(i => ({value: i.id_fip_code, label: i.member}))

  return (
    <div className="w-full">
        {/*@ts-ignore*/}
        <SearchSelect
          label="Autore"
          value={filter.selectedMember}
          onChange={(e: any) => setFilters({...filter, selectedMember: e})}
          options={memberOptions}
          filterOption={(candidate: any, input: string) => {
            if (input) {
              return candidate.data?.label.toUpperCase().includes(input.toUpperCase())
            }
            return true
          }}
          isClearable={true}
        />
    </div>
  );
}
