import React, {useContext, useEffect, useState} from "react";

// Services
import {getInitActivity, getMissingDataError} from "./Activities.lib";
import AuthenticationService from "@services/AuthenticationService";
import {NotificationsContext} from "@ui-components/Notifications";
import {useHistory} from "react-router-dom";
import {api} from "@services/apiRequest";
import moment from "moment";

// Ui
import DatePicker from "@ui-components/DatePicker";
import Toggle from "@ui-components/Toggle";
import Select from "@ui-components/Select";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Alert from "@ui-components/Alert";
import Input from "@ui-components/Input";

import {AddUpdateActivityDialogPropsType} from "./Activities.type";
import {Activity, ActivityType} from "@services/types/activity";
import {START_SEASON, END_SEASON, LKP_ACTIVITY_TYPES} from "@utils/constants";


export default function AddUpdateActivityDialog(
  {
    onExit,
    updateActivity,
    setActivities,
    refreshActivity
  }: AddUpdateActivityDialogPropsType
) {

  const history = useHistory();
  const {push} = useContext(NotificationsContext);
  const userData = AuthenticationService.getUserData();

  const [loading, setLoading] = useState(false)
  const [missingData, setMissingData] = useState<string | undefined>("Inserisci il nome dell'attività")

  const [activity, setActivity] = useState<Activity>(updateActivity ?? getInitActivity())

  useEffect(() => {
    setMissingData(getMissingDataError(activity))
  }, [activity]) // eslint-disable-line react-hooks/exhaustive-deps

  const addOrUpdateAbsence = () => {
    setLoading(true)
    const payload = {
      ...activity,
      num_questions: activity.flg_technical_test ? activity.num_questions ?? 25 : undefined,
      id_added_user: userData.id,
      id_added_role: localStorage.getItem("refman_role")
    }

    if (updateActivity) {
      api.put(`/activities/${activity.id}`, payload)
        .then(({data: res}) => {
          if (refreshActivity) {
            refreshActivity(res)
          }
          push({title: "Dati aggiornati con successo", type: "success"});
          onExit()
        })
        .catch((err) => {
          console.log(err)
          push({title: "Errore del server", type: "error"});
        })
        .finally(() => setLoading(false))

    } else {
      api.post('/activities', payload)
        .then(({data: activity}) => {
          if (setActivities) {
            setActivities(undefined)
          }
          push({title: "Dati salvati con successo", type: "success"});
          onExit()
          history.push(`/activities/${activity.id}`)
        })
        .catch((err) => {
          console.log(err)
          push({title: "Errore del server", type: "error"});
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <Modal
      maxWidth="w-full sm:max-w-xl"
      onExit={onExit}
    >
      <div className="mb-5 flex flex-col gap-2">
        <h1 className="font-bold sm:text-xl mb-4">{updateActivity ? 'Modifica' : 'Aggiunta'} attività tecnica</h1>
        <div>
          {/*@ts-ignore*/}
          <Input
            label="Nome"
            value={activity.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setActivity({...activity, name: e.target.value})
            }}
            errorMessage="Inserisci testo"
            required
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-8 w-full justify-between">
          <div className="w-full">
            {/*@ts-ignore*/}
            <Select
              label="Tipologia"
              value={activity.activity_type}
              options={Object.entries(LKP_ACTIVITY_TYPES).map(([k, v]) => ({value: k, name: v}))}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setActivity({
                ...activity, activity_type: e.target.value as ActivityType
              })}
            />
          </div>
          <div className="w-full">
            {/*@ts-ignore*/}
            <DatePicker
              value={activity.dat_activity ? new Date(activity.dat_activity) : undefined}
              onChange={(i: string) => setActivity({
                  ...activity, dat_activity: moment(i).format('YYYY-MM-DD')
                }
              )}
              label='Data'
              placeholder="Seleziona data"
              minDate={new Date(START_SEASON)}
              maxDate={new Date(END_SEASON)}
            />
          </div>
        </div>
        <div className="flex flex-row gap-3 sm:gap-8 w-full justify-between mt-2">
          <div className="w-full">
            {/*@ts-ignore*/}
            <Input
              label="Orario inizio"
              type="time"
              value={activity.time_start}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setActivity({...activity, time_start: e.target.value})
              }}
              errorMessage="Inserisci orario"
              required
            />
          </div>
          <div className="w-full">
            {/*@ts-ignore*/}
            <Input
              label="Orario fine"
              type="time"
              value={activity.time_end}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setActivity({...activity, time_end: e.target.value})
              }}
              errorMessage="Inserisci orario"
              required
            />
          </div>
        </div>
        {['RADUNO', 'LESSON', 'GYM'].includes(activity.activity_type) ?
          <div>
            {/*@ts-ignore*/}
            <Input
              label="Luogo"
              value={activity.location}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setActivity({...activity, location: e.target.value})
              }}
              errorMessage="Inserisci dove si svolgerà l'attività tecnica"
              required
            />
          </div>
          : null
        }
        {activity.activity_type === 'REMOTE' ?
          <div>
            {/*@ts-ignore*/}
            <Input
              label="Link meeting online"
              value={activity.link_web}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setActivity({...activity, link_web: e.target.value})
              }}
              errorMessage="Inserisci il link al meeting online"
              required
            />
          </div>
          : null
        }
        <div className="flex flex-row gap-5 sm:gap-8 w-full justify-between items-center -mt-2">
          {/*@ts-ignore*/}
          <Toggle
            // smaller
            // vertical
            label={<span className="mr-3">Test fisico</span>}
            checked={activity.flg_physical_test}
            onChange={() => setActivity({...activity, flg_physical_test: !activity.flg_physical_test})}
          />
          {/*@ts-ignore*/}
          <Toggle
            // smaller
            // vertical
            label={<span className="mr-3">Test tecnico</span>}
            checked={activity.flg_technical_test}
            onChange={() => setActivity({...activity, flg_technical_test: !activity.flg_technical_test})}
          />
          {activity.flg_technical_test ?
            <div className="w-24">
              {/*@ts-ignore*/}
              <Input
                value={activity.num_questions || 25}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setActivity({...activity, num_questions: parseFloat(e.target.value)});
                }}
                label="Domande"
                type="number"
                numberOptions={{
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }}
              />
            </div> : null
          }
        </div>
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between items-center">
        {missingData ?
          <Alert
            type="warning"
            slim
            title={missingData}
          /> : <div/>
        }
        <div>
          <Button onClick={addOrUpdateAbsence} submitting={loading} disabled={missingData !== undefined}>
            {updateActivity ? 'Modifica' : 'Aggiungi'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
