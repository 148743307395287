import React, {useContext, useState} from "react";

import {FormatDateOptions, IntlShape} from "react-intl";
import {NominationSchema, Status} from "@services/types/nomination";

import Button from "@ui-components/Button";
import Tooltip from "@ui-components/Tooltip";
import {PauseCircleIcon} from "@heroicons/react/24/outline";
import {ExclamationTriangleIcon} from "@heroicons/react/24/solid";
import {UseHistoryType} from "@components/RefMan/NominationCard/NominationCard.type";

import {LKP_ROLES, OK_STATUS, TODAY} from "@utils/constants";
import Modal from "@ui-components/Modal";
import Select from "@ui-components/Select";
import {api} from "@services/apiRequest";
import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "@services/DataContext/DataContext";
import Alert from "@ui-components/Alert";

export const getColor = (status: Status) => {
  switch (status) {
    case 'REV':
      return 'text-yellow-500'
    case 'RIF':
      return 'text-red-600'
    case 'TEM':
      return 'text-gray-400'
    default:
      return 'text-am-700'
  }
}

export const dateFormat: FormatDateOptions = {weekday: "short", day: "numeric", month: "short", year: "2-digit"}

const infoStatus = (status: Status, isObs: boolean) => {
  switch (status) {
    case 'TEM':
      return (
        <div className="flex flex-row gap-1 items-center">
          <PauseCircleIcon className="h-5 w-5 mr-1"/>
          <span className="font-bold">TEMPORANEA</span>
        </div>
      )
    case 'TRA':
      return (
        <div className="flex flex-row gap-1 items-center">
          <ExclamationTriangleIcon className="h-5 w-5 mr-1"/>
          <span className="font-bold">DA ACCETTARE</span>
          {isObs ? null : <Tooltip
            placement="left"
            text={<span>La gara va accettata su <b>FOL</b>.<br/>Se è già stata accettata, <b>RefMan</b> lo scoprirà al prossimo aggiornamento</span>}
            customStyle="text-xs normal-case"
            size="small"
          />
          }
        </div>
      )
    case 'REV':
      return <span><b>REVOCATA</b></span>
    case 'RIF':
      return <span><b>RIFIUTATA</b></span>
    default:
      return null
  }
}

const manageObserverNomination = (nom: NominationSchema, intl: IntlShape, fromHome?: boolean) => {
  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(nom.status);

  if (fromHome) {
    return <></>
  }

  const updateData = () => {
    setLoading(true)
    api.put(`/nominations/${nom.cod_game}/${nom.id_fip_code}`, {
      status: status
    })
      .then(() => {
        // @ts-ignore
        dataContext.setNominations({idFipCode: undefined, data: null});
        setShowModal(false);
        push({title: "Salvataggio avvenuto con successo", type: "success"});
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  const options = nom.status === 'TRA'
    ? [{value: 'TRA', name: ''}, {value: 'ACC', name: 'Accettata'}, {value: 'RIF', name: 'Rifiutata'}]
    : [{value: 'ACC', name: 'Accettata'}, {value: 'RIF', name: 'Rifiutata'}]

  return <div>
    <Button styleType="white" className="text-xs" onClick={() => setShowModal(true)}>
      Gestisci
    </Button>
    {showModal
      ? <Modal onExit={() => setShowModal(false)}>
        <h1 className="font-bold text-xl">Gestisci designazione</h1>
        <h2 className="text-sm my-3">
          Gara <b>{nom.game.game_number}</b> di <i>{intl.formatDate(nom.game.dat_game, dateFormat)}</i>
        </h2>
        <Select
          options={options}
          value={status}
          label="Stato designazione"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStatus(e.target.value)}
          customStyles={undefined}
        />
        {status === 'RIF'
          ? <div className="my-8">
            <Alert title="Sei sicuro di voler rifiutare la gara?" text="Non sarà possibile annullare l'operazione"/>
          </div> : ''
        }
        <div className="mt-5 flex flex-row justify-between">
          <Button styleType="white" onClick={() => setShowModal(false)}>
            Annulla
          </Button>
          <Button onClick={updateData} submitting={loading} disabled={status === nom.status}>
            Salva
          </Button>
        </div>
      </Modal> : null
    }
  </div>
}


const declineButton = (nom: NominationSchema, intl: IntlShape, history: UseHistoryType, isObs: boolean, fromHome?: boolean) => {
  if (isObs) {
    return manageObserverNomination(nom, intl, fromHome)
  }
  const disabled = !nom.flg_managed || nom.tms_decline
  let tooltipText
  if (!nom.flg_managed) {
    tooltipText = <span>Non è possibile rifiutare la gara poiché non è gestita dal CR Marche</span>
  }
  if (nom.tms_decline) {
    const decline_day = intl.formatDate(nom.tms_decline, {day: "numeric", month: "long"})
    tooltipText = <span>
      La gara è già stata rifiutata il {decline_day}.<br/>
      Presto sarà gestita dall'Ufficio Designazioni
    </span>
  }
  return (
    <div className="flex flex-row gap-2 items-center">
      {/*@ts-ignore*/}
      <Button disabled={disabled}
              styleType="white"
              className="text-xs"
              onClick={() => history.push(`/decline-game?idFipCode=${nom.id_fip_code}&codGame=${nom.cod_game}`)}
      >
        Rifiuta
      </Button>
      {disabled ?
        <Tooltip
          placement="left"
          text={tooltipText}
          customStyle="text-xs normal-case"
          iconName="InformationCircleIcon"
          size="small"
        />
        : null
      }
    </div>
  )
}

export const getActionNoteBlock = (
  nom: NominationSchema,
  intl: IntlShape,
  history: UseHistoryType,
  isPersonal?: boolean,
  fromHome?: boolean
) => {
  const isObs = ['OBSR', 'OBSU'].includes(nom.role)
  return (
    <div className="w-full py-1 flex flex-col items-center">
      {infoStatus(nom.status, isObs)}
      {isPersonal && ['ACC', 'TRA'].includes(nom.status) && nom.game.dat_game >= TODAY
        ? declineButton(nom, intl, history, isObs, fromHome) : null
      }
    </div>
  )
}

export const getColleagues = (nom: NominationSchema, idFipCode: number) => {
  if (!['ACC', 'TRA', 'TEM'].includes(nom.status)) {
    return null
  }
  const cod_group = nom.member.category.cod_group
  const colleagues = nom.game.nominations.filter(i => i.id_fip_code !== Number(idFipCode)
    && OK_STATUS.includes(i.status)
    && i.member.category.cod_group === cod_group
    && !['OBSR', 'OBSU'].includes(i.role)
  )
  if (!colleagues.length) {
    return <span className="text-xs whitespace-nowrap">{LKP_ROLES[nom.role]}</span>
  }
  let res: React.JSX.Element[] = []
  let first = true
  colleagues.forEach(i => {
    if (!first) {
      res.push(<span key={`e_${i.id_fip_code}`} className="mr-1">,</span>)
    }
    res.push(<b key={i.id_fip_code}>{i.member.tag}</b>)
    first = false
  })
  return (
    <div>
      <span className="text-xs whitespace-nowrap">{LKP_ROLES[nom.role]}, con</span>
      <p className="flex -my-1 truncate overflow-hidden w-full text-xs">{res}</p>
    </div>
  )
}