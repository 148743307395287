import React, {useContext, useEffect, useState} from "react";

import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import {EndSessionModalProps, QuizSessionQuestionSchema, QuizSessionSaveSchema} from "@components/RefMan/Quiz/types";
import {useHistory} from "react-router-dom";
import {useSession} from "@components/RefMan/Quiz/hooks/useSession";
import {NotificationsContext} from "@ui-components/Notifications";
import {ExclamationTriangleIcon} from "@heroicons/react/20/solid";

export function EndSessionModal({onExit, idQuiz, questionsList}: EndSessionModalProps) {
  const [numQuestionToAnswers, setNumQuestionToAnswers] = useState<number>(0);
  // context
  const {push} = useContext(NotificationsContext);

  const pushHistory = useHistory().push;

  const {saveSession} = useSession();


  const handleSaveClick = () => {
    const payload: QuizSessionSaveSchema = {
      id_quiz: idQuiz,
      answers: questionsList.map((e) => {
        return {id_question: e.id_question, des_answers: e.des_answers}
      })
    }
    saveSession(payload)
      .then(() => pushHistory('/technical-area/quiz'))
      .catch(() => {
        push({title: 'Errore del server', type: 'error'});
      });
  }

  const checkNumQuestionToAnswers = (questionList: QuizSessionQuestionSchema[]) => {
    return questionList.filter((e) => e.des_answers.length === 0).length
  }

  useEffect(() => {
    setNumQuestionToAnswers(checkNumQuestionToAnswers(questionsList))
  }, [questionsList, idQuiz]);

  return (
    <Modal
      onExit={onExit}
    >
      {numQuestionToAnswers === 0 ?
        <div>
          <h1 className="font-bold sm:text-xl">Sei sicuro di terminare il quiz?</h1>
          <h3 className="text-xs sm:text-sm my-3">Non sarà possibile annullare l'operazione.</h3>
          <div className="w-full flex flex-col sm:flex-row gap-3 justify-between mt-8">
            <Button styleType="white" onClick={onExit}>
              Annulla
            </Button>
            <Button onClick={() => handleSaveClick()}>
              Conferma
            </Button>
          </div>
        </div> : <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true"/>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Attenzione</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  Non hai risposto a <b>{numQuestionToAnswers}</b> {numQuestionToAnswers > 1 ? 'domande' : 'domanda'}.<br/>
                  Rispondi a tutti le domande prima di terminare il quiz!
                </p>
              </div>
            </div>
          </div>
        </div>
      }
    </Modal>
  );
}
