import { useEffect, useState } from "react";
import { RatingsProgressBarType } from "./ProgressBar.type"


const RatingsProgressBar = ({
    max = 100,
    rating,
    classNames,
    mu = ""
}: RatingsProgressBarType) => {

    const barClassNames = [
        "h-6",
        "text-sm",
        "text-white",
        "flex",
        "justify-center",
        "items-center",
        "bg-am-200",
        "truncate",
    ];

    const [percentage, setPercentage] = useState<number>();

    useEffect(() => {
        if (rating > max) {
            setPercentage(max);
        } else if (rating <= 0) {
            setPercentage(0)
        } else {
            setPercentage(Math.round(rating * 100 / max))
        }
    }, [rating, max]);

    return <div className={classNames}>
        <div className="w-full border rounded-md border-stone-400">
            <div
                style={{ width: `${percentage}%` }}
                className={barClassNames.join(" ")}
            >
                {`${rating} ${mu}`}
            </div>
        </div>
    </div>
}

export default RatingsProgressBar
