import React from "react";

import {getStatusBadgeLabel} from "@components/RefMan/Reports/ReportsList/ObserverReportsList.lib";
import {CalendarIcon, CurrencyEuroIcon, PencilIcon, TruckIcon} from "@heroicons/react/24/outline";
import {RefManListItemType} from "@components/RefMan/RefManList/RefManList.type";
import {dateFormatWithWeekday} from "@pages/Planner/Absences/Absences.lib";
import {GamesCosts, GamesSchema, Nominations} from "@pages/Games/Games.type";
import {LevelSchema} from "@services/types/level";
import {Member} from "@services/types/member";
import Alert from "@ui-components/Alert";
import {TODAY} from "@utils/constants";
import {IntlShape} from "react-intl";


const getGameCost = (intl: IntlShape, game: GamesSchema, keys: (keyof GamesCosts)[]) => {
  return intl.formatNumber(
    keys.reduce((acc, k) => acc + game.costs[0][k], 0),
    {maximumFractionDigits: 0, style: "currency", currency: "EUR"}
  )
}

const groupCost = (intl: IntlShape, game: GamesSchema, label: string, fee_col: keyof GamesCosts, trip_col: keyof GamesCosts) =>
  game.costs[0][fee_col] ? <div className="flex flex-row items-center">
    <span className="mx-1.5 text-xl">&#8226;</span>
    <span className="font-medium mr-1">{label}:</span>
    <span className="font-light">{getGameCost(intl, game, [fee_col, trip_col])}</span>
    {game.costs[0][trip_col] ?
      <div className="flex flex-row items-center gap-1 text-xs text-gray-500 ml-3">
        <TruckIcon className="w-4 h-4"/>
        <span>{getGameCost(intl, game, [trip_col])}</span>
      </div>
      : null
    }
  </div> : null


export const getStatusBadgeClasses = (status: string): string => {
  switch (status) {
    case 'ACC':
      return 'bg-green-500/10 ring-green-500/20'
    case 'TRA':
      return 'bg-blue-400/10 ring-blue-400/30'
    case 'TEM':
      return 'bg-blue-400/10 ring-blue-400/30'
    case 'REV':
      return 'bg-purple-400/10 ring-purple-400/30'
    case 'RIF':
      return 'bg-red-400/10 ring-red-400/20'
    default:
      return 'bg-gray-400/10 ring-gray-400/20'
  }
}

const showNomination = (role: Nominations[], label: string, key: 'tag' | 'member' = 'tag', showStatus: boolean = false) => {
  const isObs = ['O', 'OS'].includes(label)
  if (role.length > 0) {
    const color = role[0].status === 'TEM' ? 'text-gray-500 text-opacity-50' : 'text-am-700'
    return (<div className={`font-bold flex flex-row gap-2 items-center ${color} ${isObs ? 'mt-2 italic' : ''}`}>
      <span className="text-xs font-normal italic">{label}</span>
      <span>{role[0].member[key]}</span>
      {showStatus ?
        // <span className="text-xs font-light border rounded-lg py-0.5 px-1">
        <span
          className={'text-xs font-light rounded-lg py-0.5 px-1 ring-1 ring-inset ' + getStatusBadgeClasses(role[0].status)}>
          {getStatusBadgeLabel(role[0].status)}
        </span>
        : null}
    </div>)
  } else {
    return null
  }
}

export const getNominationRoles = (game: GamesSchema, okStatus: string[]) => {
    const ref1 = game.nominations.filter(i => i.role === 'REF1' && okStatus.includes(i.status))
    const ref2 = game.nominations.filter(i => i.role === 'REF2' && okStatus.includes(i.status))
    const obsr = game.nominations.filter(i => i.role === 'OBSR' && okStatus.includes(i.status))
    const sp = game.nominations.filter(i => i.role === 'SP' && okStatus.includes(i.status))
    const crono = game.nominations.filter(i => i.role === 'CRONO' && okStatus.includes(i.status))
    const a24s = game.nominations.filter(i => i.role === 'A24S' && okStatus.includes(i.status))
    const udcs = game.nominations.filter(i => i.role === 'UDCS' && okStatus.includes(i.status))
    const obsu = game.nominations.filter(i => i.role === 'OBSU' && okStatus.includes(i.status))
  return {ref1, ref2, obsr, sp, crono, a24s, udcs, obsu}
}


export const getListItems = (
  intl: any,
  items: GamesSchema[],
  group: 'ref' | 'udc' | 'all',
  showTemp: boolean,
  showCosts: boolean,
  setManagedGame?: React.Dispatch<React.SetStateAction<GamesSchema | undefined>>
): RefManListItemType[] | [] => {


  const okStatus = showTemp ? ['ACC', 'TEM', 'TRA'] : ['ACC', 'TRA']
  const isObserverView: boolean = !!setManagedGame

  return items.map((game: GamesSchema, index): RefManListItemType => {

    const {ref1, ref2, obsr, sp, crono, a24s, udcs, obsu} = getNominationRoles(game, okStatus)

    const previousObservers = group === 'ref'
      ? game.nominations.filter(i => i.role === 'OBSR' && !okStatus.includes(i.status)).length
      : game.nominations.filter(i => i.role === 'OBSU' && !okStatus.includes(i.status)).length

    return ({
      rowId: `game-${index}`,
      rowElement: <>
        <dt className="font-bold capitalize text-gray-900 flex flex-row items-center gap-2 justify-between">
          <div className="flex flex-row gap-2 items-center">
            <div><CalendarIcon className="h-5 sm:h-7 w-5 sm:w-7"/></div>
            <div className="flex flex-row sm:flex-col whitespace-nowrap items-center sm:items-start gap-2 sm:gap-0">
              <span>{intl.formatDate(game.dat_game, dateFormatWithWeekday)}</span>
              <span className="sm:text-sm font-normal">{game.time_game}</span>
            </div>
          </div>
        </dt>
        <dd
          className="border-t sm:border-0 w-full my-1 sm:my-0 py-2 sm:py-0 flex flex-col gap-1 justify-center sm:col-span-2">
          <div className="flex flex-row items-center gap-2 text-sm ">
            <span className="font-bold text-gray-700">
              # {game.game_number}
            </span>
            <span className="text-gray-900 border rounded-xl py-0.5 px-2 border-gray-300">
              {game.level.des_level_extended}
            </span>
          </div>
          <span className="text-xxs sm:text-xs truncate overflow-hidden w-full">
            {game.team_a.team_alias} <i>vs</i> {game.team_b.team_alias}
          </span>
          {game.city ?
            <span className="text-xxs sm:text-xs truncate overflow-hidden w-full">
              {game.place}, <b>{game.city.city} ({game.city.cod_province})</b>
              </span>
            : null}
        </dd>
        <dd className="flex flex-row w-full sm:col-span-2 items-center">
          {['ref', 'all'].includes(group) ?
            <>
              <div className="w-full flex flex-col gap-1 justify-center mb-1 sm:mb-0">
                <div className="flex flex-col text-sm">
                  {showNomination(ref1, '1°', group === 'all' ? 'tag' : 'member')}
                  {showNomination(ref2, '2°', group === 'all' ? 'tag' : 'member')}
                  {showTemp || game.dat_game <= TODAY
                    ? showNomination(obsr, 'O', group === 'all' ? 'tag' : 'member', isObserverView)
                    : null
                  }
                  {setManagedGame && previousObservers && obsr.length === 0 ?
                    <div className="w-48 mt-2">
                      <Alert type="warning"
                             title={previousObservers > 1 ? previousObservers + ' revoche/rifiuti' : '1 revoca/rifiuto'}
                             extraSlim/>
                    </div>
                    : null
                  }
                </div>
              </div>
            </>
            : null
          }
          {['udc', 'all'].includes(group) ?
            <>
              <div className="w-full flex flex-col gap-1 justify-center">
                <div className="flex flex-col text-sm">
                  {showNomination(sp, 'SP', group === 'all' ? 'tag' : 'member')}
                  {showNomination(crono, 'CR', group === 'all' ? 'tag' : 'member')}
                  {showNomination(a24s, '24', group === 'all' ? 'tag' : 'member')}
                  {showNomination(udcs, 'ST', group === 'all' ? 'tag' : 'member')}
                  {showTemp || game.dat_game <= TODAY
                    ? showNomination(obsu, 'OS', group === 'all' ? 'tag' : 'member', isObserverView)
                    : null
                  }
                </div>
              </div>
            </>
            : null
          }
          {setManagedGame ?
            <div className='min-w-0 w-7 h-7 flex flex-auto justify-center cursor-pointer'
                 onClick={() => {
                   setManagedGame(game)
                 }}
            >
              <PencilIcon title="Gestisci la designazione"/>
            </div>
            : null
          }
        </dd>
        {showCosts && game.costs.length ?
          <dd
            className="w-full my-1 sm:my-0 py-1 flex flex-col sm:flex-row gap-1 sm:gap-10 sm:col-span-5 sm:items-center justify-center bg-gray-100 rounded-lg text-sm">
            <div className="flex flex-row gap-5 sm:gap-10 p-2 sm:p-0 items-center"><CurrencyEuroIcon
              className="h-6 w-6"/>
              <span className="font-bold">{getGameCost(
                intl,
                game,
                ['amt_fee_ref', 'amt_fee_udc', 'amt_fee_obs', 'amt_trip_ref', 'amt_trip_udc', 'amt_trip_obs']
              )}
              </span>
            </div>
            {groupCost(intl, game, 'Arbitri', 'amt_fee_ref', 'amt_trip_ref')}
            {groupCost(intl, game, 'UdC', 'amt_fee_udc', 'amt_trip_udc')}
            {groupCost(intl, game, 'Osservatori', 'amt_fee_obs', 'amt_trip_obs')}
          </dd>
          : null
        }
      </>
    })
  });
}


export const getAllowedObservers = (observers: Member[], level: string, group: 'ref' | 'udc') => {
  let res: { value: number; name: string }[] = []
  observers.forEach((i: Member) => {
    const jobs = i.members_jobs.filter(
      j => {
        const levelList = j.job.levels?.split(';') ?? []
        const allowedRoles = group === 'udc' ? ['IST', 'OBS', 'TUT']
          : ['B2', 'C1', 'B/F', 'D'].includes(level) ? ['IST', 'OBS'] : ['IST', 'TUT']
        return allowedRoles.includes(j.job.job_type)
          && j.job.cod_group === group.toUpperCase()
          && (!j.job.levels ||
            (level === 'B2' && levelList.includes('B2')) ||
            (['C1', 'B/F'].includes(level) && levelList.includes('C')) ||
            (level === 'D' && levelList.includes('D')) ||
            (['B/M', 'A2/F'].includes(level) && levelList.includes('B/M')) ||
            (levelList.includes('REG') && !['B/M', 'A2/F', 'B2', 'C1', 'B/F', 'D'].includes(level))
          )
      })
    if (jobs.length) {
      res.push({value: i.id_fip_code, name: i.member})
    }
  })
  return res
}


export const getMaxLevelOrder = (filterLevelByRole: string, levels: LevelSchema[], group: 'ref' | 'udc') => {
  switch (filterLevelByRole) {
    case 'all':
      return 1
    case 'c':
      return levels.filter(i => i.cod_level === 'C1')[0].level_order
    case 'd':
      return levels.filter(i => i.cod_level === 'D')[0].level_order
    case 'personal':
      return group === 'ref'
        ? levels.filter(i => i.cod_level === 'B2')[0].level_order
        : 1
    default:
      return group === 'ref'
        ? levels.filter(i => i.cod_level === 'PM')[0].level_order
        : levels.filter(i => i.cod_level === 'B2')[0].level_order

  }
}