import React from "react";
import {IntlShape} from "react-intl";
import {api} from "@services/apiRequest";
import {dateFormat} from "@pages/Planner/Absences/Absences.lib";
import {BasicAlgorithmTaskSchema, UserKwargsSchema} from "@pages/Nominations/Algorithms/Algorithms.types";
import {Member} from "@services/types/member";

const runBasicAlgorithmErrors: Record<number, string> = {
  409: "BasicAlgorithmTaskConflictError",
}

export const getSessionStatusColorDescription = (cod_status: string, des_error_message: string | undefined): {
  primaryColor: string,
  backgroundColor: string,
  description: string
} => {
  switch (cod_status) {
    case 'RUNNING':
      return {primaryColor: 'bg-emerald-500', backgroundColor: 'bg-emerald-500/20', description: 'In corso'};
    case 'ACCEPTED':
      return {primaryColor: 'bg-yellow-500', backgroundColor: 'bg-yellow-500/20', description: 'In preparazione'};
    case 'ERROR':
      if (des_error_message === 'stopped_by_user') {
        return {primaryColor: 'bg-red-500', backgroundColor: 'bg-red-500/20', description: 'Stoppato'};
      }
      return {primaryColor: 'bg-red-500', backgroundColor: 'bg-red-500/20', description: 'Fallito'};
    case 'END':
      return {primaryColor: 'bg-blue-500', backgroundColor: 'bg-blue-500/20', description: 'Terminato'};
    default:
      return {primaryColor: 'bg-gray-500', backgroundColor: 'bg-gray-500/20', description: ''};
  }
}


export const SessionMetadata = ({nominators, session, intl}: {
  nominators: Member[],
  session: BasicAlgorithmTaskSchema,
  intl: IntlShape
}) => <div
  className="flex flex-col divide-y border rounded-3xl px-4 py-3 text-xs sm:text-sm -mt-4 sm:mt-0 mb-4">
  <div className="flex flex-wrap items-center gap-x-4 gap-y-2 pb-2">
    <span>
      <span className="mr-1.5">&#8226;</span>
      Creata da <b>{nominators[session.created_by]}</b> il <i>{intl.formatDate(session.tms_created_at, dateFormat)}</i>
    </span>
    {session.updated_by ?
      <span>
        <span className="mr-1.5">&#8226;</span>
        Modificata da <b>{nominators[session.updated_by]}</b> il <i>{intl.formatDate(session.tms_updated_at, dateFormat)}</i>
      </span>
      : null
    }
  </div>
  <div className="flex flex-wrap items-center gap-x-4 gap-y-2 pt-2">
    <span>
      <span className="mr-1.5">&#8226;</span>
      <b>Periodo: </b>{intl.formatDate(session.jsn_input_data?.dat_from)} - {intl.formatDate(session.jsn_input_data?.dat_to)}
    </span>
    <span>
      <span className="mr-1.5">&#8226;</span>
      <b>Province: </b>{session.jsn_input_data?.provinces === 'all' ? 'Tutte' : session.jsn_input_data?.provinces.join(', ')}
    </span>
    <span>
      <span className="mr-1.5">&#8226;</span>
      <b>Campionati: </b>{session.jsn_input_data?.levels === 'all' ? 'Tutti' : session.jsn_input_data?.levels.join(', ')}
    </span>
  </div>
</div>


export async function saveBasicAlgorithmConstraints(
  id: number,
  constraints: UserKwargsSchema,
  push: Function,
) {
  try {
    await api.post('/algorithms/basic/constraints', {
      id: id,
      constraints: constraints,
    })
    push({type: 'success', title: 'Vincoli salvati con successo'})
  } catch (error: any) {
    push({
      type: 'error',
      title: 'Errore del Server durante il salvataggio dei vincoli',
    })
  }
}


export async function runBasicAlgorithm(
  idSession: number,
  push: Function,
  intl: IntlShape,
  setLoadingRunLaunch: React.Dispatch<React.SetStateAction<boolean>>,
  setSession: React.Dispatch<React.SetStateAction<BasicAlgorithmTaskSchema | undefined>>
) {
  try {
    setLoadingRunLaunch(true);
    const {data} = await api.get<BasicAlgorithmTaskSchema>(`/algorithms/basic/start_algorithm/${idSession}`)
    setSession(data)

    push({
      type: 'success',
      title: 'Algoritmo lanciato con successo',
    });

  } catch (error: any) {
    if (error.response.status in runBasicAlgorithmErrors) {
      push({
        type: 'error',
        title: intl.formatMessage({id: runBasicAlgorithmErrors[error.response.status]}),
      });
    } else {
      push({
        type: 'error',
        title: 'Errore generico al lancio dell\'algoritmo Elite',
      });
    }
  } finally {
    setLoadingRunLaunch(false);
  }
}