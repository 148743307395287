import React, {SetStateAction, Dispatch} from "react";

import SearchSelect from "@ui-components/SearchSelect";
import {useTagsFetch} from "@components/RefMan/Quiz/hooks/useTagsFetch";
import {QuizFilterType} from "@components/RefMan/Quiz/types";
import {getTagOption} from "@components/RefMan/Quiz/utils/tag";


export function QuizTagFilter({filter, setFilters}: {
  filter: QuizFilterType,
  setFilters: Dispatch<SetStateAction<QuizFilterType>>
}) {
  // hooks
  const {data} = useTagsFetch();

  return (
    <div className="w-full">
      <SearchSelect
        label="Tag"
        minHeight={undefined}
        value={filter.selectedTags}
        onChange={(e: { value: string }[]) => setFilters({...filter, selectedTags: e})}
        options={getTagOption(data ? data : [])}
        loadOptions={null}
        isMulti={true}
        CustomOption={null}
      />
    </div>
  );
}
