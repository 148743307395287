import { GamesLevelFilter, GamesPeriodFilter } from '@pages/Games/Games.lib';
import { MemberSearch } from '@pages/Members/Members.lib';
import { ReportsFiltersType } from './ReportsFilters.type';

const ReportsFilters = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  _levels,
  selectedLevels,
  setSelectedLevels,
  role,
  filterMember,
  setFilterMember,
  setOffset,
}: ReportsFiltersType) => {
  return (
    <div className='flex flex-col sm:flex-row justify-between gap-3 mb-3'>
      <div className='w-full sm:w-1/3'>
        <GamesPeriodFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} setOffset={setOffset} />
      </div>
      <div className='w-full sm:w-1/3'>
        <GamesLevelFilter levels={_levels} selectedLevels={selectedLevels} setSelectedLevels={setSelectedLevels} setOffset={setOffset} />
      </div>
      {['viewer', 'admin'].includes(role) && (
        <div className='w-full sm:w-1/3'>
          <MemberSearch label='Cerca osservatore' searchKey={filterMember} setSearchKey={setFilterMember} setOffset={setOffset} />
        </div>
      )}
    </div>
  );
};

export default ReportsFilters;
