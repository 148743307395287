import React, {Dispatch, SetStateAction} from "react";

import SearchSelect from "@ui-components/SearchSelect";
import {QuizFilterType} from "@components/RefMan/Quiz/types";


export function QuizStatusFilter({filter, setFilters}: {
  filter: QuizFilterType,
  setFilters: Dispatch<SetStateAction<QuizFilterType>>
}) {

  const statusOptions: { label: string, value: string }[] = [
    {label: "In corso", value: "in_progress"},
    {label: "Terminato", value: "finished"},
    {label: "Non iniziato", value: "not_started"}
  ]

  return (
    <div className="w-full">
      <SearchSelect
        label="Stato"
        minHeight={undefined}
        value={filter.selectedStatus}
        onChange={(e: any) => setFilters({...filter, selectedStatus: e})}
        options={statusOptions}
        loadOptions={null}
        isMulti={false}
        CustomOption={null}
        isClearable={true}
      />
    </div>
  );
}
