import React, {useState, useEffect, useContext} from "react";

// Services
import AuthenticationService from "../../../services/AuthenticationService";
import {NotificationsContext} from "../../Notifications";
import {useIntl, FormattedMessage} from "react-intl";
import ErrorBoundary from "../../ErrorBoundary";
import {api} from "@services/apiRequest";
import _ from "lodash";

// Components
import AddUserDialog from "./AddUserDialog";
import UsersTable from "./UsersTable";

// Data
import useRoles from "@ui-components/pages/Users/hooks/useRoles";
import useMembers from "@services/hooks/useMembers";

// UI
import {UserPlusIcon} from "@heroicons/react/24/solid";
import Placeholder from "@ui-components/Placeholder";
import {PageHeading} from "../../Container";
import Button from "../../Button";

function Users() {

  const intl = useIntl();
  const roles = useRoles();
  const members = useMembers({onlyActive: false, assigned: false, asObj: false})

  const [users, setUsers] = useState();
  const {push} = useContext(NotificationsContext);
  const scopes = AuthenticationService.getUserScopes();
  const [showNewUserDialog, setShowNewUserDialog] = useState(false);

  useEffect(() => {
    const fetchUsers = () => {
      api
        .get('/users')
        .then(({data: users}) => {
          setUsers(
            (users || []).reduce(
              (usersObj, user) => _.set(usersObj, [user.id], user),
              {}
            )
          );
        })
        .catch(() => {
          setUsers({})
          push({
            title: intl.formatMessage({id: "server_error"}),
            type: "error",
          });
        });
    };

    if (!users) {
      fetchUsers();
    }
    // eslint-disable-next-line
  }, [users, push]);

  const toggleNewUserDialog = () => setShowNewUserDialog(!showNewUserDialog);
  const canCreateUser = scopes.indexOf("user:create") >= 0;

  return (
    <>
      <PageHeading
        title={intl.formatMessage({id: "users"})}
        contentActions={
          canCreateUser && roles && members ? (
            <Button
              variant="contained"
              color="primary"
              onClick={toggleNewUserDialog}
            >
              <UserPlusIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              <FormattedMessage id="add"/>
            </Button>
          ) : null
        }
      />

      <ErrorBoundary from="users-table">
        {roles && members ?
          <UsersTable
            push={push}
            users={users}
            setUsers={setUsers}
            roles={roles}
            members={members}
          /> :
          <div className="flex flex-col w-full mb-4 gap-2">
            <Placeholder height="h-10" color="am-200" classNames="rounded-lg"/>
            <Placeholder height="h-96" classNames="rounded-lg"/>
          </div>
        }
      </ErrorBoundary>
      {canCreateUser && roles && members ? (
        <ErrorBoundary from="users-add-dialog">
          <AddUserDialog
            open={showNewUserDialog}
            toggleOpen={toggleNewUserDialog}
            users={users}
            setUsers={setUsers}
            roles={roles}
            members={members}
          />
        </ErrorBoundary>
      ) : null}
    </>
  );
}

export default Users;
