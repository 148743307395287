import {useContext, useEffect} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import AuthenticationService from "../AuthenticationService";
import {DataContext} from "../DataContext/DataContext";
import apiRequest from "../apiRequest";
import _ from "lodash";

const useCategories = ({
                         group = undefined,
                         onlyActive = true,
                         onlyAssignable = false,
                         excludeManager = true,
                         assigned = true,
                         asObj = true
                       }) => {
  const groups = AuthenticationService.getUserGroups();
  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    let closed = false;
    const fetchData = async () => {
      try {
        const params = groups.length === 1 ? `?cod_group=${groups[0]}` : ''
        const data = await apiRequest.get(`/entry/categories${params}`);
        if (!closed) {
          dataContext.setCategories((data || []).reduce((obj, row) => _.set(obj, [row.id], row), {}));
        }
      } catch (error) {
        console.log(error)
        dataContext.setCategories(false);
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if (dataContext.categories === null) {
      fetchData();
    }

    return () => {
      closed = true;
    };
  }, [dataContext.categories, push]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (!dataContext.categories) {
    return undefined
  }

  let res = _.sortBy(Object.values(dataContext.categories), i => i.sub_cat_order);
  if (group) {
    res = res.filter(i => i.cod_group === group.toUpperCase())
  }
  if (onlyActive) {
    res = res.filter(i => i.cod_category !== 'X')
  }
  if (onlyAssignable) {
    res = res.filter(i => !['OSS', 'DIR', 'FORM', 'STAT'].includes(i.cod_sub_category))
  }
  if (excludeManager) {
    res = res.filter(i => i.cod_category !== 'D')
  }
  if (assigned) {
    res = res.filter(i => i.cod_category !== '-')
  }
  if (asObj) {
    return res.reduce((obj, row) => _.set(obj, [row.id], row), {})
  }
  return res;

};

export default useCategories;
