import React from "react";

import {MemberResultProps} from "@pages/Activities/Detail/Detail.type";
import {getMemberAttendance, getMemberPhysicalTest} from "@pages/Activities/Detail/Components/DetailComponents.lib";


export function MemberResult({activity, memberActivity}: MemberResultProps) {

  return (
    <div className="flex flex-col my-5 py-2 gap-5">
      <div className="flex flex-row justify-between border-b border-gray-800">
        <h1 className="text-lg font-bold">Partecipazione</h1>
      </div>
      <div className="flex flex-col sm:flex-row gap-1 sm:items-center">
        <div className="w-full">
          {memberActivity.attendance ? getMemberAttendance(memberActivity.attendance) : null}
        </div>
        <div className="w-full">
          {memberActivity.physical_test_result ? getMemberPhysicalTest(memberActivity.physical_test_result) : null}
        </div>
        <div className="w-full">
          {memberActivity.num_errors || memberActivity.num_errors === 0
            ? <span><b>{memberActivity.num_errors} errori</b> / {activity.num_questions}</span> : null}
        </div>
      </div>
    </div>
  )
}
