import React, {useState} from "react";
import {useIntl} from "react-intl";

import {timeStampFormat} from "@pages/Planner/Absences/Absences.lib";
import {StatusBullet} from "@components/RefMan/StatusBullet";
import {UserCircleIcon} from "@heroicons/react/24/outline";
import Placeholder from "@ui-components/Placeholder";
import Button from "@ui-components/Button";
import Alert from "@ui-components/Alert";

import {getQuizStatusColor, getQuizStatusDescription} from "@components/RefMan/Quiz/QuizList/QuizList.lib";
import {AddSessionModal} from "@components/RefMan/Quiz/Session/AddSession/AddSessionModal";
import RefManList from "@components/RefMan/RefManList/RefManList";
import useMembers from "@services/hooks/useMembers";

import {QuizDetailSessionsProps, QuizSessionMemberSchema} from "@components/RefMan/Quiz/types";


const getListItems = (
  sessions: QuizSessionMemberSchema[]
) => {

  const intl = useIntl();

  return sessions
    .sort((a, b) =>
      a.participant.member.localeCompare(b.participant.member))
    .map((i) => {
      const {primaryColor, backgroundColor} = getQuizStatusColor(i.cod_status);
      return ({
        rowId: `session-${i.id_participant}`,
        rowElement: <>
          <div className="flex flex-col w-full sm:col-span-2">
            <div className="flex flex-row">
              <span>{i.id_participant}</span>
              <span className="mx-1.5">&#8226;</span>
              <span>{i.participant.category.des_sub_category}</span>
            </div>
            <span><b>{i.participant.member}</b></span>
          </div>
          <div className="flex flex-row gap-x-2 items-center w-full my-3">
            <StatusBullet primaryColor={primaryColor} backgroundColor={backgroundColor}/>
            <p className={"text-sm text-gray-500 whitespace-nowrap"}>{getQuizStatusDescription(i.cod_status)}</p>
          </div>
          <div className="flex flex-row sm:flex-col lg:flex-row gap-x-4 w-full items-center sm:items-end lg:items-center justify-between sm:justify-center whitespace-nowrap text-sm sm:col-span-2">
            {i.tms_start ? <span>Inizio: <b>{intl.formatDate(i.tms_start, timeStampFormat)}</b></span> : ''}
            {i.tms_end ? <span>Fine: <b>{intl.formatDate(i.tms_end, timeStampFormat)}</b></span> : null}
          </div>
        </>
      })
    })
}

export function QuizDetailSessions({data, setRefreshData}: QuizDetailSessionsProps) {

  const members = useMembers({})
  const [showManage, setShowManage] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const limit = 10;

  return (
    <>
      <div className="flex justify-center">
        {members ?
          <Button styleType={'white'} className="text-xs" onClick={() => setShowManage(true)}>
            <UserCircleIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true"/>
            Assegnazione tesserati
          </Button>
          : <Placeholder height="h-8" width="w-40" classNames="rounded-lg"/>
        }
      </div>
      <div className="my-5">
        {data.sessions.length ?
          <RefManList
            containerId='quiz-session-list'
            items={getListItems(data.sessions)}
            total={data.sessions.length}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            pagination='frontend'
            rowClassNames={['sm:grid', 'sm:grid-cols-5', '!cursor-default']}
          /> :
          <Alert title="Nessun tesserato assegnato a questo quiz"/>
        }
      </div>
      {showManage ?
        <AddSessionModal
          data={data}
          setRefreshData={setRefreshData}
          onExit={() => setShowManage(false)}
        />
        : null
      }
    </>
  )
}