import React from "react";

import {BriefcaseIcon, CalendarIcon, StarIcon, UserIcon} from "@heroicons/react/24/outline";
import {dateFormatWithWeekday} from "@pages/Planner/Absences/Absences.lib";
import AuthenticationService from "@services/AuthenticationService";

// Types
import {RefManListItemType} from "@components/RefMan/RefManList/RefManList.type";
import {Evaluations, VisibilityLevel} from "@pages/Reports/Reports.type";
import {SelectedLevel} from "@pages/Games/Games.type";
import {LevelSchema} from "@services/types/level";
import {IntlShape} from "react-intl";
import {downloadExcel} from "@utils/downloadExcel";
import {CounterData} from "@pages/Nominations/Analytics/Analytics.type";
import {Team} from "@services/types/team";

export const getRoleFromScopes = () => {
  const scopes = AuthenticationService.getUserScopes();
  const isObserver = scopes.includes('reports:editor');
  const isMember = scopes.includes('reports:personal');
  const isViewer = scopes.includes('reports:viewer');
  const isAdmin = scopes.includes('reports:admin')
  switch (true) {
    case isObserver:
      return "observer";
    case isMember:
      return "member";
    case isViewer:
      return "viewer";
    case isAdmin:
      return "admin";
    default:
      console.warn("[REFMAN-WARNING]", "Damn! I don't know who I am!")
      break;
  }
}


export const getMemberGroupOptions = (group: 'ref' | 'udc', visibilityLevel: VisibilityLevel) => {
  if (group === 'udc') {
    switch (visibilityLevel) {
      case 'all':
        return [
          {value: 'N', label: 'Nazionali', cod_sub_categories: ['A', 'A2', 'B1']},
          {value: 'R', label: 'Regionali', cod_sub_categories: ['R1', 'R2', 'R3']}
        ]
      default:
        return [{value: 'R', label: 'Regionali', cod_sub_categories: ['R1', 'R2', 'R3']}]
    }
  }
  if (group === 'ref') {
    switch (visibilityLevel) {
      case "r":
        return [
          {value: 'AS', label: 'Arbitri Senior', cod_sub_categories: ['AS']},
          {value: 'SG', label: 'Settore Giovanile', cod_sub_categories: ['JR PM', 'JR F1', 'JR F2', 'NEW']}
        ]
      case 'd':
        return [
          {value: 'D', label: 'Serie D', cod_sub_categories: ['DR1']},
          {value: 'AS', label: 'Arbitri Senior', cod_sub_categories: ['AS']},
          {value: 'SG', label: 'Settore Giovanile', cod_sub_categories: ['JR PM', 'JR F1', 'JR F2', 'NEW']}
        ]
      case 'c':
        return [
          {value: 'C', label: 'Serie C', cod_sub_categories: ['C']},
          {value: 'D', label: 'Serie D', cod_sub_categories: ['DR1']},
          {value: 'AS', label: 'Arbitri Senior', cod_sub_categories: ['AS']},
          {value: 'SG', label: 'Settore Giovanile', cod_sub_categories: ['JR PM', 'JR F1', 'JR F2', 'NEW']}
        ]
      default:
        return [
          {value: 'B', label: 'Serie B Int.', cod_sub_categories: ['B2']},
          {value: 'C', label: 'Serie C', cod_sub_categories: ['C']},
          {value: 'D', label: 'Serie D', cod_sub_categories: ['DR1']},
          {value: 'AS', label: 'Arbitri Senior', cod_sub_categories: ['AS']},
          {value: 'SG', label: 'Settore Giovanile', cod_sub_categories: ['JR PM', 'JR F1', 'JR F2', 'NEW']}
        ]
    }
  }
}


export const getLevels = (selectedLevels: SelectedLevel[], _levels: LevelSchema[]) =>
  selectedLevels.length
    ? selectedLevels
    : _levels.map((i: LevelSchema) => ({
      value: i.cod_level,
      label: '',
    }));


export const getEvaluationsItems = (
  data: Evaluations[],
  intl: IntlShape
): RefManListItemType[] | [] => {

  if (!data.length) {
    return []
  }

  return data.map((evaluation: Evaluations): RefManListItemType => {
    return ({
      rowId: `evaluation-${evaluation.game.game_number}-${evaluation.id_member}`,
      rowElement: <>
        <dt
          className="capitalize text-gray-900 flex flex-col items-center gap-1 justify-between sm:col-span-2 text-sm py-1">
          <div className="flex flex-row gap-2 items-center font-semibold">
            <div><CalendarIcon className="h-5 w-5"/></div>
            <div className="whitespace-nowrap">
              {intl.formatDate(evaluation.game.dat_game, dateFormatWithWeekday)}
            </div>
          </div>
          <div className="flex flex-row items-center gap-2 text-sm ">
            <span className="font-bold text-gray-700">
              # {evaluation.game.game_number}
            </span>
            <span className="text-gray-900 border rounded-xl py-0.5 px-2 border-gray-300">
              {evaluation.game.level.des_level_extended}
            </span>
          </div>
        </dt>
        <dd
          className="border-t sm:border-0 w-full my-1 sm:my-0 py-2 sm:py-0 flex flex-row sm:flex-col justify-center sm:col-span-2 text-xxs items-center">
          <span className="truncate overflow-hidden text-center leading-2">
            {evaluation.game.team_a.team_alias}
          </span>
          <span className="italic mx-1 font-semibold">vs</span>
          <span className="truncate overflow-hidden text-center leading-2">
            {evaluation.game.team_b.team_alias}
          </span>
        </dd>
        <dt
          className="capitalize text-gray-900 flex flex-col items-center gap-1 justify-between sm:col-span-2 text-sm py-1">
          <div className="flex flex-row gap-2 items-center text-xs">
            <div><BriefcaseIcon className="h-5 w-5"/></div>
            <div className="whitespace-nowrap">
              {evaluation.observer.member}
            </div>
          </div>
          <div className="flex flex-row gap-2 items-center font-semibold">
            <div><UserIcon className="h-5 w-5"/></div>
            <div className="whitespace-nowrap">
              {evaluation.member.member}
            </div>
          </div>
        </dt>
        <dt className="capitalize text-gray-900 flex flex-col items-center gap-1 justify-center text-sm py-1">
          <div className="flex flex-row gap-1 font-bold">
            <StarIcon className="h-5 w-5"/>
            <span>{evaluation.tags?.voto}</span>
          </div>
          {evaluation.tags?.potenziale ?
            <div className="whitespace-nowrap">Potenzialità: <b>{evaluation.tags.potenziale}</b></div> : null
          }
        </dt>
      </>
    })
  })
}


export const EvaluationDownloadExcel = (filteredData: Evaluations[], intl: IntlShape) => {
  downloadExcel(
    filteredData.map((i) => ({
      member_fip_code: i.member.id_fip_code,
      member: i.member.member,
      observer_fip_code: i.observer.id_fip_code,
      observer: i.observer.member,
      rating: i.tags?.voto,
      potential: i.tags?.potenziale,
      dat_game: intl.formatDate(i.game.dat_game, {day: '2-digit', month: '2-digit', year: 'numeric'}),
      level: i.game.level.des_level_extended,
      game_numer: i.game.game_number,
      team_a: i.game.team_a.team_alias,
      team_b: i.game.team_b.team_alias
    })),
    [
      {field: 'member_fip_code', title: 'Codice Tesserato'},
      {field: 'member', title: 'Tesserato'},
      {field: 'observer_fip_code', title: 'Codice Osservatore'},
      {field: 'observer', title: 'Osservatore'},
      {field: 'rating', title: 'Voto'},
      {field: 'potential', title: 'Potenzialità'},
      {field: 'dat_game', title: 'Data'},
      {field: 'level', title: 'Campionato'},
      {field: 'game_numer', title: 'Gara'},
      {field: 'team_a', title: 'Squadra A'},
      {field: 'team_b', title: 'Squadra B'}
    ],
    'valutazioni'
  );
};


export const getBgColor = (value: number | undefined) => {
  switch (true) {
    case (value || 0) === -1:
      return `!bg-red-700 !bg-opacity-20`
    case (value || 0) === 1:
      return `bg-opacity-20`
    case (value || 0) === 2:
      return `bg-opacity-40`
    case (value || 0) === 3:
      return `bg-opacity-60`
    case (value || 0) >= 3:
      return `bg-opacity-80 font-bold`
    default:
      return 'bg-opacity-0'

  }
}


export const getFilteredTeams = (data: CounterData[], teams: Team[]) => {
  const distinctKeys = Array.from(
    new Set(
      data.map(obj => Object.keys(obj.count)).reduce((acc, keys) => acc.concat(keys), [])
    )
  )

  return teams.filter(i => distinctKeys.includes(String(i.id)))
}