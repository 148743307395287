import React, {useContext, useState} from "react";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";

import {NotificationsContext} from "@ui-components/Notifications";
import {dateFormat} from "@pages/Planner/Absences/Absences.lib";
import {QuizDetailPlaceholder} from "@utils/placeholders";
import {PageHeading} from "@ui-components/Container";
import Tabs from "@ui-components/Tabs";

import {useQuizDetail} from "@components/RefMan/Quiz/hooks/useQuizDetail";

import {
  QuizDetailQuestions,
  QuizDetailResults,
  QuizDetailSessions,
  QuizMetadata,
  QuizActionList,
  QuizStartStopSessionModal,
  QuizCreateModal,
  QuizDeleteModal,
  QuizCopyModal
} from "@components/RefMan/Quiz/QuizManagement/Sections";

import {
  close_quiz_session,
  create_quiz_session,
  delete_quiz,
  duplicate_quiz,
  modify_quiz,
  QuizActionState,
  QuizDetailProps,
  show_quiz
} from "@components/RefMan/Quiz/types";

const TABS = [
  {name: 'Domande', value: 'questions'},
  {name: 'Sessioni', value: 'sessions'},
  {name: 'Risultati', value: 'results'},
]

export function QuizManagement() {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const {id_quiz} = useParams<QuizDetailProps>();
  const idQuiz = Number(id_quiz);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const [action, setAction] = useState<QuizActionState>(show_quiz);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  if (!idQuiz) {
    return null;
  }
  const {data, loading} = useQuizDetail({id_quiz: idQuiz, refresh: refreshData});

  const showCurrentTab = (tabIndex: number) => {
    if (!data)
      return undefined
    switch (tabIndex) {
      case 0:
        return <QuizDetailQuestions data={data} setRefreshData={setRefreshData} push={push}/>;
      case 1:
        return <QuizDetailSessions data={data} setRefreshData={setRefreshData}/>;
      case 2:
        return <QuizDetailResults idQuiz={data.id}/>;
      default:
        throw new Error('tab index out of range')

    }
  }

  return (
    <>
      {!loading && data ?
        <>
          <div className='flex flex-row justify-between'>
            <PageHeading
              historyBackPath="_back"
              title={<div className="flex flex-col">
                <span className="text-lg sm:text-xl font-bold">{data.des_title}</span>
                <span className="text-xs sm:text-sm text-gray-600 font-normal">
                  Creato il {intl.formatDate(data.dat_creation, dateFormat)} da <i>{data.member.member}</i>
                </span>
              </div>}
            />
            <div className='pt-5'>
              <QuizActionList cod_status={data.cod_status} setAction={setAction}/>
            </div>
          </div>
          <QuizMetadata data={data}/>
          <div className="mt-2 mb-6">
            <Tabs
              setActiveTab={setTabIndex}
              active={tabIndex}
              tabs={TABS}
            />
          </div>
          {showCurrentTab(tabIndex)}
          {action === delete_quiz &&
            <QuizDeleteModal
              push={push}
              idQuiz={idQuiz}
              onExit={() => setAction(show_quiz)}
            />
          }
          {action == duplicate_quiz &&
            <QuizCopyModal
              push={push}
              idQuiz={idQuiz}
              onExit={() => setAction(show_quiz)}
            />
          }
          {action === modify_quiz &&
            <QuizCreateModal
              quiz={data}
              onExit={() => {
                setAction(show_quiz)
                setRefreshData(prev => !prev)
              }}
            />
          }
          {(action === create_quiz_session || action === close_quiz_session) &&
            <QuizStartStopSessionModal
              push={push}
              idQuiz={data.id}
              action={action}
              onExit={() => {
                setAction(show_quiz)
                setRefreshData(prev => !prev)
              }}
            />
          }
        </> :
        <QuizDetailPlaceholder/>
      }
    </>
  );
}
