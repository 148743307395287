import React from "react";
import Badge from "@ui-components/Badge";
import {LKP_JOB_TYPES} from "@utils/constants";
import {apiRequest} from "@services/apiRequest";
import CheckboxGroup from "@ui-components/CheckboxGroup";

export const SPEC_COLS_OPTIONS = {
  provinces : ['AN', 'AP', 'FM', 'MC', 'PU'],
  levels : ['A/M', 'B/M', 'B2', 'C', 'D', 'REG'],
}

const UpdateList = ({col, value, onChange}) => {
  const scopes = value ? value.split(";") : []
  const setScopes = (e) => onChange(e.length ? e.join(";") : "")

  return (
    <div>
      <CheckboxGroup
        options={SPEC_COLS_OPTIONS[col].map((o) => ({
          value: o,
          name: o,
        }))}
        values={scopes}
        onChange={(value) => {
          if (scopes.indexOf(value) >= 0) {
            setScopes([...scopes.filter((v) => v !== value)]);
          } else {
            setScopes([...scopes, value]);
          }
        }}
      />
    </div>
  )
};


export const COLUMNS = [
  {
    title: "Tipologia",
    field: "job_type",
    lookup: LKP_JOB_TYPES
  },
  {
    title: "Nome",
    field: "name",
    cellStyle: {minWidth: 250, fontWeight: "bold"},
    headerStyle: {minWidth: 250},
  },
  {
    title: "Gruppo",
    field: "cod_group",
    lookup: {REF: 'Arbitri', UDC: 'UdC', null: '-'}
  },
  {
    title: "Campionati",
    field: "levels",
    editComponent: (props) => <UpdateList col="levels" {...props} />,
    render: ({levels}) =>
      levels ? (
        <div>
          {levels.split(";").map((s) => (
            <Badge classNames="mb-1 mr-1" text={s} key={s}/>
          ))}
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Province",
    field: "provinces",
    editComponent: (props) => <UpdateList col="provinces" {...props} />,
    render: ({provinces}) =>
      provinces ? (
        <div>
          {provinces.split(";").map((s) => (
            <Badge classNames="mb-1 mr-1" text={s} key={s}/>
          ))}
        </div>
      ) : (
        "-"
      ),
  }
]

export const OPTIONS = {
  filtering: true,
  toolbar: false,
  search: false,
  paging: false,
  sorting: true,
  filterCellStyle: {maxWidth: 200}
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    editRow: {
      deleteText: "Vuoi davvero eliminare la riga?",
    },
  },
};


export const onRowUpdate = (newData, oldData, dataContext, push) =>
  new Promise((resolve, reject) => {
    const _newData = {...newData, cod_group: newData.cod_group === 'null' ? undefined : newData.cod_group}
    let isEqual = true;
    Object.entries(_newData).forEach(([key, value]) => {
      if (oldData[key] !== value) {
        isEqual = false;
      }
    });
    if (isEqual) {
      resolve();
    } else {
      apiRequest
        .put(`/jobs/${oldData.id}`, _newData)
        .then((res) => {
          dataContext.setJobs({...dataContext.jobs, [res['id']]: res})
          push({title: "Riga aggiornata con successo", type: "success"});
          resolve();
        })
        .catch(() => {
          push({
            title: "Errore durante l'aggiornamento della riga",
            type: "error",
          });
          reject();
        });
    }
  });

export const onRowDelete = (oldData, dataContext, push) =>
  new Promise((resolve, reject) => {
    apiRequest
      .delete(`/jobs/${encodeURIComponent(oldData.id)}`)
      .then(() => {
        const {[oldData.id]: tmp, ...rest} = dataContext.jobs;
        dataContext.setJobs({...rest});
        push({title: "Riga eliminata con successo", type: "success"});
        resolve();
      })
      .catch(() => {
        push({
          title: "Errore durante l'eliminazione della riga",
          type: "error",
        });
        reject();
      });
  });