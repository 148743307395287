import React from 'react';

import SearchSelect from '@ui-components/SearchSelect';
import DatePicker from '@ui-components/DatePicker';
import {END_SEASON, REGIONAL_PROVINCES, START_SEASON, TODAY} from '@utils/constants';

import {
  GamesLevelFilterProps,
  GamesPeriodFilterProps,
  GamesProvinceFilterProps,
  GamesSchema,
  PaginatedGames,
  SelectedLevel,
  SelectedProvince,
  TextFilter,
} from './Games.type';
import {api} from '@services/apiRequest';
import {getDateStr} from '@pages/Planner/Calendar/Calendar.lib';
import {IntlShape} from 'react-intl';
import {downloadExcel} from '@utils/downloadExcel';

/**
 * @function getData
 *
 * @param showTemp
 * @param withAssignedRole
 * @param {Date} startDate
 * @param {Date} endDate
 * @param textFilters
 * @param {SelectedProvince[]} selectedProvinces
 * @param {SelectedLevel[]} selectedLevels
 * @param {React.Dispatch<PaginatedGames | undefined>} setData
 * @param {any} push
 * @param {number} limit
 * @param {number} offset
 */
export const getData = async (
  showTemp: boolean,
  withAssignedRole: string | undefined,
  startDate: Date,
  endDate: Date,
  textFilters: TextFilter,
  selectedProvinces: SelectedProvince[],
  selectedLevels: SelectedLevel[],
  push: any,
  limit?: number,
  offset?: number,
  setData?: React.Dispatch<PaginatedGames | undefined>
) => {
  let searchParams = {
    show_temp: showTemp.toString(),
    ...(startDate && {start_date: getDateStr(startDate)}),
    ...(withAssignedRole && {with_assigned_role: withAssignedRole}),
    ...(endDate && {end_date: getDateStr(endDate)}),
    ...(textFilters.member && {member: textFilters.member}),
    ...(textFilters.game_number && {game_number: textFilters.game_number}),
    ...(textFilters.city && {city: textFilters.city}),
    ...(textFilters.team && {team: textFilters.team}),
    ...(selectedProvinces.length && {provinces: JSON.stringify(selectedProvinces.map((i) => i.value))}),
    ...(selectedLevels.length && {levels: JSON.stringify(selectedLevels.map((i) => i.value))}),
  };
  if (limit) {
    searchParams = {...searchParams, ...{limit: limit.toString()}};
  }
  if (offset) {
    searchParams = {...searchParams, ...{offset: offset.toString()}};
  }

  try {
    const {data} = await api.get(`/games/with-nominations?${new URLSearchParams(searchParams)}`);
    if (setData) {
      setData(data);
    } else {
      return data;
    }
  } catch (e) {
    push({title: 'Errore del server', type: 'error'});
  }
};

export function GamesPeriodFilter({startDate, setStartDate, endDate, setEndDate, setOffset}: GamesPeriodFilterProps) {
  return (
    <div className='flex w-full flex-row gap-1'>
      <DatePicker
        value={startDate}
        onChange={(e: any) => {
          setStartDate(e);
          if (setOffset) {
            setOffset(0);
          }
        }}
        label='Data inizio'
        minDate={new Date(START_SEASON)}
        maxDate={new Date(END_SEASON)}
      />
      <DatePicker
        value={endDate}
        onChange={(e: any) => {
          setEndDate(e);
          if (setOffset) {
            setOffset(0);
          }
        }}
        label='Data fine'
        minDate={new Date(START_SEASON)}
        maxDate={new Date(END_SEASON)}
      />
    </div>
  );
}

export function GamesProvinceFilter({selectedProvinces, setSelectedProvinces, setOffset}: GamesProvinceFilterProps) {
  // @ts-ignore
  return (
    <SearchSelect
      label='Province'
      options={REGIONAL_PROVINCES.map((i) => ({
        label: i.des_province,
        value: i.cod_province
      })).concat([{label: 'Fuori regione', value: 'XX'}])}
      isMulti
      value={selectedProvinces}
      onChange={(e: SelectedProvince[]) => {
        setSelectedProvinces(e);
        if (setOffset) {
          setOffset(0);
        }
      }}
      loadOptions={undefined}
      CustomOption={undefined}
      minHeight={undefined}
    />
  );
}

export function GamesLevelFilter({levels, selectedLevels, setSelectedLevels, setOffset}: GamesLevelFilterProps) {
  // @ts-ignore
  return (
    <SearchSelect
      label='Campionati'
      options={levels.map((i) => ({label: i.des_level_extended, value: i.cod_level}))}
      isMulti
      value={selectedLevels}
      onChange={(e: SelectedLevel[]) => {
        setSelectedLevels(e);
        if (setOffset) {
          setOffset(0);
        }
      }}
      loadOptions={undefined}
      CustomOption={undefined}
      minHeight={undefined}
    />
  );
}

export const gameDownloadExcel = (filteredData: GamesSchema[], intl: IntlShape, showTemp: boolean) => {
  downloadExcel(
    filteredData.map((game) => {
      const okStatus = showTemp ? ['ACC', 'TEM', 'TRA'] : ['ACC', 'TRA'];

      const ref1 = game.nominations.filter((i) => i.role === 'REF1' && okStatus.includes(i.status));
      const ref2 = game.nominations.filter((i) => i.role === 'REF2' && okStatus.includes(i.status));
      const obsr = game.nominations.filter((i) => i.role === 'OBSR' && okStatus.includes(i.status));
      const sp = game.nominations.filter((i) => i.role === 'SP' && okStatus.includes(i.status));
      const crono = game.nominations.filter((i) => i.role === 'CRONO' && okStatus.includes(i.status));
      const a24s = game.nominations.filter((i) => i.role === 'A24S' && okStatus.includes(i.status));
      const obsu = game.nominations.filter((i) => i.role === 'OBSU' && okStatus.includes(i.status));

      return {
        game_number: game.game_number,
        dat_game: intl.formatDate(game.dat_game, {day: '2-digit', month: '2-digit', year: 'numeric'}),
        time_game: game.time_game,
        cod_level: game.cod_level,
        team_a: game.team_a.team_alias,
        team_b: game.team_b.team_alias,
        city: game.city?.city ?? '',
        cod_province: game.city?.cod_province ?? '',
        ref1: ref1.length > 0 ? ref1[0].member.member : '',
        ref2: ref2.length > 0 ? ref2[0].member.member : '',
        obsr: (showTemp || game.dat_game <= TODAY) && obsr.length > 0 ? obsr[0].member.member : '',
        sp: sp.length > 0 ? sp[0].member.member : '',
        crono: crono.length > 0 ? crono[0].member.member : '',
        a24s: a24s.length > 0 ? a24s[0].member.member : '',
        obsu: (showTemp || game.dat_game <= TODAY) && obsu.length > 0 ? obsu[0].member.member : '',
      };
    }),
    [
      {field: 'game_number', title: 'Gara'},
      {field: 'dat_game', title: 'Data'},
      {field: 'time_game', title: 'Ora'},
      {field: 'cod_level', title: 'Camp'},
      {field: 'team_a', title: 'Squadra A'},
      {field: 'team_b', title: 'Squadra B'},
      {field: 'city', title: 'Città'},
      {field: 'cod_province', title: 'Provincia'},
      {field: 'ref1', title: '1° Arbitro'},
      {field: 'ref2', title: '2° Arbitro'},
      {field: 'obsr', title: 'Oss. Arbitri'},
      {field: 'sp', title: 'Segnapunti'},
      {field: 'crono', title: 'Cronometrista'},
      {field: 'a24s', title: 'Addetto 24 sec'},
      {field: 'obsu', title: 'Oss. UdC'},
    ],
    'gare'
  );
};
