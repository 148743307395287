import React from "react";

const radius = "";
const height = "h-2";

const ProgressBar = ({
  progress,
  colorTop = "bg-gray-500",
  colorBottom = "bg-gray-200",
}) => {
  const underClasses = `w-full ${radius} ${height} ${colorBottom} absolute left-0 right-0`;
  const topClasses = `w-full ${radius} ${height} ${colorTop} z-10 transition-width absolute left-0 right-0`;

  return (
    <div className="w-full relative flex justify-center items-center h-2 my-2">
      <div className={underClasses} color={colorBottom} />
      <div
        className={topClasses}
        style={{ width: progress ? `${progress}%` : "0" }}
      />

      {/* <div className="z-20 absolute left-0 top-0 right-0 bottom-0 m-auto justify-center items-center">
        <p color="#fff" fontSize={15}>{`${progress}%`}</p>
      </div> */}
    </div>
  );
};

export default ProgressBar;
