import React, {useCallback, useContext, useEffect, useState} from "react";
import {NotificationsContext} from "../../Notifications";
import {useIntl, FormattedMessage} from "react-intl";
import {api} from "@services/apiRequest";

import CheckboxGroup from "@ui-components/CheckboxGroup";
import Placeholder from "../../Placeholder";
import Toggle from "../../Toggle";
import Button from "../../Button";
import Input from "../../Input";
import Modal from "../../Modal";
import SearchSelect from "@ui-components/SearchSelect";
import {LKP_GROUP} from "@utils/constants";

function AddUserDialog({open, toggleOpen, users, setUsers, roles, members}) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [selectedMember, setSelectedMember] = useState();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const {push} = useContext(NotificationsContext);

  useEffect(() => {
    if (selectedMember) {
      setName(`${selectedMember.data.firstname} ${selectedMember.data.lastname}`)
      setEmail(selectedMember.data.mail)
      const memberRole = Object.values(roles).filter(i => i.name === selectedMember.data.category.cod_group.toLowerCase())
      if (memberRole && memberRole.length) {
        setSelectedRoles([memberRole[0]['id']])
      }
    }
  }, [selectedMember]);  // eslint-disable-line react-hooks/exhaustive-deps

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!selectedRoles.length) {
          push({
            title: intl.formatMessage({id: "need_to_select_a_role"}),
            type: "error",
          });
          return;
        }

        if (!email || !name) {
          push({
            title: intl.formatMessage({id: "enter_name_or_email"}),
            type: "error",
          });
          return;
        }

        setLoading(true);
        const {data} = await api.post("/users", {
          email,
          full_name: name,
          roles: selectedRoles.sort((a, b) => a - b).join(';'),
          disabled,
          id_fip_code: selectedMember ? selectedMember.data.id_fip_code : null
        });
        setUsers({...users, [data.id]: data});
        toggleOpen();
        push({
          title: intl.formatMessage({id: "user_created_successfully"}),
          type: "success",
        });
      } catch (error) {
        if (error.response.status === 400) {
          push({
            title: "Dati errati o utente già esistente",
            type: "error",
          });
        } else {
          push({
            title: intl.formatMessage({id: "server_error"}),
            type: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [email, name, selectedRoles, disabled, setUsers, toggleOpen, users, push]
  );
  return (
    <div>
      <Modal opened={open} onExit={() => {
        setSelectedMember(undefined)
        setEmail('')
        setName('')
        setSelectedRoles([])
        setDisabled(false)
        toggleOpen()
      }}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            <FormattedMessage id="add_user"/>
          </h3>
        </div>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div>
            <SearchSelect
              label="Tesserato"
              minHeight={50}
              value={selectedMember}
              onChange={(e) => setSelectedMember(e)}
              options={members.map(i => ({
                label: <div className="flex flex-col">
                  <span
                    className="text-xs">{i.id_fip_code} - {LKP_GROUP[i.category.cod_group]} - {i.category.des_category}</span>
                  <span className="font-bold">{i.firstname} {i.lastname}</span>
                </div>,
                value: i.id_fip_code,
                data: i
              }))}
              filterOption={(candidate, input) => {
                if (input) {
                  const _data = candidate.data.data
                  const _label = _data.id_fip_code + _data.member
                  return _label.toUpperCase().includes(input.toUpperCase())
                }
                return true
              }}
            />
          </div>
          <div>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label={intl.formatMessage({
                id: "name_and_surname",
              })}
              type="text"
              errorMessage={intl.formatMessage({
                id: "enter_name_and_surname",
              })}
              required
            />
          </div>
          <div>
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              label="Email"
              type="email"
              errorMessage={intl.formatMessage({
                id: "enter_email",
              })}
              required
            />
          </div>
          <div className="mb-4">
            {roles ? (
              <div>
                <CheckboxGroup
                  horizontal
                  title="Ruoli"
                  options={Object.values(roles).map((role) => ({
                    value: role.id,
                    name: role.description,
                  }))}
                  values={selectedRoles}
                  onChange={(value) => {
                    if (selectedRoles.indexOf(value) >= 0) {
                      setSelectedRoles([...selectedRoles.filter((v) => v !== value)]);
                    } else {
                      setSelectedRoles([...selectedRoles, value]);
                    }
                  }}
                />
              </div>
            ) : (
              <div>
                <Placeholder width="w-4/5"/>
                <Placeholder width="w-3/5"/>
              </div>
            )}
          </div>

          <div className="mb-6">
            <Toggle
              vertical
              label={intl.formatMessage({
                id: "disabled_user",
              })}
              checked={disabled}
              onChange={() => setDisabled(!disabled)}
            />
          </div>
          <div className="mt-4 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              <FormattedMessage id="add"/>
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddUserDialog;
