import React, { useState } from 'react';
import { ReportItemType } from '@components/RefMan/Reports/ReportsList/ObserverReportsList.type';
import ObserverReportsStep from '@components/RefMan/Reports/ReportsStep/ObserverReportsStep';
import { getStepComponent, getStepData } from '@components/RefMan/Reports/ReportsIndex/ObserverReportsIndex.lib';
import { ObserverReportsIndexType, type StepDataType } from '@components/RefMan/Reports/ReportsIndex/ObserverReportsIndex.type';
import { NominationType } from '@components/RefMan/Reports/reports.type';
import ReportsFilters from '../ReportsFilters/ReportsFilters';

const ObserverReportsIndex = ({
  role,
  group,
  reports,
  statusList,
  setStatusList,
  offset,
  setOffset,
  limit,
  setLimit,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  _levels,
  selectedLevels,
  setSelectedLevels,
  filterMember,
  setFilterMember,
  setSearchKey,
}: ObserverReportsIndexType): React.JSX.Element => {
  const [stepId, setStepId] = useState<number>(0);
  const [reportData, setReportData] = useState<ReportItemType | null>(null);
  const [memberData, setMemberData] = useState<NominationType>();
  const stepData: StepDataType[] = getStepData(
    stepId,
    setStepId,
    setReportData,
    reportData,
    setMemberData,
    memberData,
    group,
    role,
    reports,
    statusList,
    setStatusList,
    offset,
    setOffset,
    limit,
    setLimit
  );

  return (
    <div className='observerreportsindex__container mt-2'>
      <div className='observerreportsindex__breadcrumb-container mb-4'>
        <ObserverReportsStep stepId={stepId} reportData={reportData} stepData={stepData} setStepId={setStepId} memberData={memberData} />
      </div>
      {stepId === 0 && (
        <ReportsFilters
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          _levels={_levels}
          selectedLevels={selectedLevels}
          setSelectedLevels={setSelectedLevels}
          role={role}
          filterMember={filterMember}
          setFilterMember={setFilterMember}
          setSearchKey={setSearchKey}
          setOffset={setOffset}
        />
      )}
      <div className='observerreportsindex__component-container'>{getStepComponent(stepData, stepId)}</div>
    </div>
  );
};

export default ObserverReportsIndex;
