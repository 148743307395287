import React, {useContext, useEffect, useState} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "@services/DataContext/DataContext";
import {useHistory} from "react-router-dom";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

import InputMultiLine from "@ui-components/InputMultiLine";
import {PageHeading} from "@ui-components/Container";
import Placeholder from "@ui-components/Placeholder";
import RadioGroup from "@ui-components/RadioGroup";
import Button from "@ui-components/Button";
import Alert from "@ui-components/Alert";

import {dateFormat, getMetadata, closeGameWarning, InfoRevoca} from "./services";
import type {NominationSchema} from "@services/types/nomination";
import Modal from "@ui-components/Modal";

export default function DeclineGame() {

  const intl = useIntl();
  const history = useHistory();
  const dataContext: any = useContext(DataContext);
  const {push} = useContext(NotificationsContext);
  const params = new URLSearchParams(window.location.search);
  const [nomination, setNomination] = useState<NominationSchema | undefined | null>(undefined);
  const [typeDecline, setTypeDecline] = useState<string | undefined>();
  const [reasonDecline, setReasonDecline] = useState<string>("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [sending, setSending] = useState(false);
  const idFipCode = params.get('idFipCode')
  const codGame = params.get('codGame')

  useEffect(() => {
    const fetchData = () => {
      api
        .get(`/nominations?cod_game=${codGame}&id_fip_code=${idFipCode}`)
        .then(({data: nominations}) => {
          const withoutObservers = nominations.items.filter((i: NominationSchema) => !['OBSR', 'OBSU'].includes(i.role))
          if (withoutObservers.length === 1) {
            setNomination(withoutObservers[0]);
          } else {
            console.log('unexpected number or nominations')
            setNomination(null)
          }
        })
        .catch(() => {
          setNomination(null)
          push({title: "Errore del server", type: "error"});
        });
    };

    if (nomination === undefined) {
      fetchData();
    }

  }, [nomination, idFipCode, codGame]) // eslint-disable-line react-hooks/exhaustive-deps

  const sendDecline = () => {
    setSending(true)
    api.post('/nominations/decline', {
      id_fip_code: idFipCode,
      cod_game: codGame,
      reason_decline: reasonDecline,
      type_decline: typeDecline
    })
      .then(() => {
        // @ts-ignore
        dataContext.setNominations({idFipCode: undefined, data: null})
        push({title: "Rinuncia alla gara comunicata con successo", type: "success"});
        history.push('/home')
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setSending(false))
  }


  return (
    <>
      <PageHeading title="Comunicazione rifiuto" historyBackPath="_back"/>
      {nomination === undefined ?
        <div className="flex flex-col w-full mb-4 gap-2">
          <Placeholder height="h-10" classNames="rounded-lg bg-am-700"/>
          <Placeholder height="h-96" classNames="rounded-lg"/>
        </div> : null
      }
      {nomination === null || (nomination && !['ACC', 'TRA'].includes(nomination.status)) ?
        <div className="flex flex-col w-full items-center my-10">
          <Alert
            title="Attenzione"
            text="Non è possibile procedere con l'operazione richiesta. La designazione non è disponibile o non può essere rifiutata."
            type="error"
          />
        </div>
        : null
      }
      {nomination && ['ACC', 'TRA'].includes(nomination.status) ?
        <div className="flex flex-col gap-5">
          <div className="my-5 border-y border-gray-200 divide-y divide-gray-100">
            {getMetadata('Gara', `${nomination.game.game_number} di ${nomination.game.level.des_level_extended}`)}
            {getMetadata('Data e Ora', `${intl.formatDate(nomination.game.dat_game, dateFormat)}, ore ${nomination.game.time_game}`)}
            {getMetadata('Luogo', `${nomination.game.place}, ${nomination.game.city?.city} (${nomination.game.city?.cod_province})`)}
            {getMetadata('Squadre', `${nomination.game.team_a.team_alias} vs ${nomination.game.team_b.team_alias}`)}
          </div>
          {closeGameWarning(nomination, dataContext.group)}
          <h1 className="font-bold text-sm sm:text-lg xl:text-xl">
            Perchè non puoi fare questa gara?
          </h1>
          <RadioGroup
            id="typeDeclineSelection"
            options={[
              {
                value: "REV",
                name: <div className="flex flex-row gap-1">
                  <span>Richiedi revoca</span>
                  <InfoRevoca/>
                </div>
              },
              {value: "RIF", name: "Rifiuto"},
            ]}
            currentValue={typeDecline}
            onChange={(value: string) => {
              setTypeDecline(value);
            }}
          />
          <InputMultiLine
            rows={2}
            value={reasonDecline}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setReasonDecline(e.target.value);
            }}
            label={<span className="font-bold">Indica la motivazione</span>}
            type="text"
          />
          <Button
            className="w-full sm:w-32 mb-10"
            onClick={() => setShowConfirm(true)}
            disabled={!typeDecline || !reasonDecline || reasonDecline.length < 5}
          >
            Invia
          </Button>
        </div>
        : null
      }
      {showConfirm ?
        <Modal
          onExit={() => setShowConfirm(false)}
        >
          <div className="m-5">
            <h1 className="font-bold sm:text-xl">Sei sicuro di voler procedere?</h1>
            <h3 className="text-xs sm:text-sm my-3">Una volta inviata la rinuncia alla gara non sarà possibile annullare
              l'operazione.</h3>
          </div>
          <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
            <Button styleType="white" onClick={() => setShowConfirm(false)}>
              Annulla
            </Button>
            <Button onClick={sendDecline} submitting={sending}>
              Conferma
            </Button>
          </div>
        </Modal>
        : null
      }
    </>
  );
}
