import React from "react";

const Placeholder = ({ classNames = "", width = "w-full", height = "h-5", color = "gray-200" }) => {
  return (
    <div
      data-placeholder
      className={`bg-${color} ${height} ${width} ${classNames} mb-2 rounded-3xl overflow-hidden relative`}
    />
  );
};

export default Placeholder;
