import React, {useEffect, useState} from "react";

import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

// Components
import {
  GameHomeKpi,
  getTotalAggregation,
  getProvinceAggregation,
  getCategoryAggregation
} from "@pages/Home/Sections/Sections.lib";
import NominationBarChart from "@pages/Home/Sections/nominationBarChart";
import NominationDonut from "@pages/Home/Sections/nominationDonut";

// Ui
import Placeholder from "@ui-components/Placeholder";
import RadioGroup from "@ui-components/RadioGroup";

// Types
import {NominationsSectionPropsType, NominationKpiType} from "@pages/Home/Home.type";


export function NominationsSection(
  {
    push,
    group,
    includeExtra,
    selectedLevels,
    selectedProvinces,
    selectedCategories
  }: NominationsSectionPropsType) {

  const intl = useIntl();
  const [nominationsData, setNominationsData] = useState<NominationKpiType | undefined>(undefined);
  const [aggregation, setAggregation] = useState<{ total: any, province: any, categories: any }>(
    {total: undefined, province: undefined, categories: undefined})
  const [selectedView, setSelectedView] = useState<'province' | 'des_sub_category'>('province')

  useEffect(() => {
    if (nominationsData) {
      setAggregation({
        total: getTotalAggregation(nominationsData),
        province: getProvinceAggregation(nominationsData),
        categories: getCategoryAggregation(nominationsData),
      })
    }

  }, [nominationsData])

  useEffect(() => {
    const searchParams = new URLSearchParams({
      cod_group: group,
      ...(!includeExtra && {include_external: "0"}),
      ...(selectedCategories.length && {categories: JSON.stringify(selectedCategories.map(i => i.value))}),
      ...(selectedProvinces.length && {provinces: JSON.stringify(selectedProvinces.map(i => i.value))}),
      ...(selectedLevels.length && {levels: JSON.stringify(selectedLevels.map(i => i.value))}),
    });
    api.get(`/kpi/home-nominations?${searchParams}`)
      .then(({data}) => {
        setNominationsData(data)
      })
      .catch(() => push({type: "error", title: "Errore durante il caricamento"}))

  }, [group, selectedLevels, selectedCategories, selectedProvinces, includeExtra]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="my-5">
      {nominationsData && aggregation.total ?
        <div className="flex flex-col sm:flex-row justify-between gap-6 items-center mt-4 sm:mt-0">
          <div className="w-full sm:w-2/6">
            <NominationDonut
              id="nomination-donut"
              data={Object.entries(aggregation.total).filter(i => i[0] !== 'num_nominations' && i[0] !== 'totMembers').map(([key, value]) => {
                return {
                  category: key.replace("num_", ""),
                  value: value
                }
              })}/>
          </div>
          <div className="w-full sm:w-3/6">
            <div className="flex flex-row items-center gap-3">
              <h1 className="font-bold text-sm px-2 sm:px-0 -mb-2">Media per:</h1>
              <RadioGroup
                id="dimensionSelection"
                horizontal
                options={[
                  {value: "province", name: "Provincia"},
                  {value: "des_sub_category", name: "Categoria"},
                ]}
                currentValue={selectedView}
                onChange={(value: 'province' | 'des_sub_category') => {setSelectedView(value)}}
              />
            </div>
            <NominationBarChart
              id="nomination-bar-chart"
              data={selectedView === 'province' ? aggregation.province: aggregation.categories}
              dimension={selectedView}
            />
          </div>
          <div className="w-full sm:w-1/6 grid grid-cols-2 sm:grid-cols-1 gap-5 justify-between sm:my-4">
            <h1 className="font-bold text-sm px-2 sm:px-0">Medie per tesserato</h1>
            <GameHomeKpi
              intl={intl}
              title="Conferme"
              color="text-am-600"
              value={aggregation.total.num_acc / aggregation.total.totMembers}
              denominator={aggregation.total.num_nominations / aggregation.total.totMembers}
            />
            <GameHomeKpi
              intl={intl}
              title="Revoche"
              color="text-yellow-600"
              value={aggregation.total.num_rev / aggregation.total.totMembers}
              denominator={aggregation.total.num_nominations / aggregation.total.totMembers}
            />
            <GameHomeKpi
              intl={intl}
              title="Rifiuti"
              color="text-red-700"
              value={aggregation.total.num_rif / aggregation.total.totMembers}
              denominator={aggregation.total.num_nominations / aggregation.total.totMembers}
            />
          </div>
        </div>
        : <div className="flex flex-row gap-5">
          <Placeholder height="h-40" classNames="rounded-lg"/>
          <Placeholder height="h-40" classNames="rounded-lg"/>
          <Placeholder height="h-40" classNames="rounded-lg"/>
        </div>
      }
    </div>
  );
}
