import React, {useContext, useEffect, useState} from 'react';

import {NotificationsContext} from '@ui-components/Notifications';
import {END_SEASON, START_SEASON} from "@utils/constants";
import useLevels from '@services/hooks/useLevels';
import {api} from "@services/apiRequest";
import {useIntl} from 'react-intl';

// Ui
import {PageHeading} from '@ui-components/Container';
import Placeholder from '@ui-components/Placeholder';

// Component
import {GamesLevelFilter, GamesPeriodFilter, GamesProvinceFilter} from '@pages/Games/Games.lib';
import {EconomicKpi, sumProperties} from "@pages/Economics/Economics.lib";
import {getListItems} from '@pages/Nominations/Observers/Observers.lib';
import RefManList from '@components/RefMan/RefManList/RefManList';
import {getDateStr} from "@pages/Planner/Calendar/Calendar.lib";

// Type
import {PaginatedGames, SelectedLevel, SelectedProvince} from "@pages/Games/Games.type";
import {RefManListItemType} from '@components/RefMan/RefManList/RefManList.type';
import {CostsKpiType} from "@pages/Economics/Economics.type";
import {LevelSchema} from "@services/types/level";
import EconomicTrendChart from "@pages/Economics/trendChart";
import EconomicLevelChart from "@pages/Economics/levelChart";


export function Economics() {
  const {push} = useContext(NotificationsContext);
  const levels: LevelSchema[] = useLevels({asObj: false, onlyWithTax: true});
  const intl = useIntl();


  const [startDate, setStartDate] = useState<Date>(new Date(START_SEASON));
  const [endDate, setEndDate] = useState<Date>(new Date(END_SEASON));
  const [selectedLevels, setSelectedLevels] = useState<SelectedLevel[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<SelectedProvince[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const limit = 5;

  const [kpi, setKpi] = useState<CostsKpiType | undefined>(undefined);
  const [games, setGames] = useState<PaginatedGames | undefined>(undefined);
  const [itemsList, setItemsList] = useState<RefManListItemType[]>();

  useEffect(() => {
    if (games) {
      setItemsList(getListItems(intl, games.items, 'all', true, true, undefined));
    }
  }, [games]);

  let allowApi = 0
  if (levels) {
    allowApi = 1
  }


  useEffect(() => {
    const searchParams = new URLSearchParams({
      ...(startDate && {start_date: getDateStr(startDate)}),
      ...(endDate && {end_date: getDateStr(endDate)}),
      ...(selectedLevels.length && {levels: JSON.stringify(selectedLevels.map((i) => i.value))}),
      ...(selectedProvinces.length && {provinces: JSON.stringify(selectedProvinces.map((i) => i.value))}),
    });
    api.get(`/costs?${searchParams}`)
      .then(({data}) => {
        setKpi(data)
      })
      .catch(() => push({type: "error", title: "Errore durante il caricamento"}))
  }, [startDate, endDate, selectedLevels, selectedProvinces]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (allowApi) {
      const searchParams = new URLSearchParams({
        with_cost: '1',
        start_date: getDateStr(startDate),
        end_date: getDateStr(endDate),
        limit: limit.toString(),
        offset: offset.toString(),
        levels: selectedLevels.length
          ? JSON.stringify(selectedLevels.map((i) => i.value))
          : JSON.stringify(levels.map(i => i.cod_level)),
        ...(selectedProvinces.length && {provinces: JSON.stringify(selectedProvinces.map((i) => i.value))}),
      });
      api.get(`/games/with-nominations?${searchParams}`)
        .then(({data}) => {
          setGames(data)
        })
        .catch(() => push({type: "error", title: "Errore durante il caricamento"}))
    }
  }, [allowApi, limit, offset, startDate, endDate, selectedLevels, selectedProvinces]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <PageHeading title='Economics'/>
      {levels ? (
        <>
          <div className='flex flex-col sm:flex-row justify-between gap-3'>
            <div className='w-full sm:w-1/3'>
              <GamesPeriodFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setOffset={setOffset}
              />
            </div>
            <div className='w-full sm:w-1/3'>
              <GamesLevelFilter
                levels={levels}
                selectedLevels={selectedLevels}
                setSelectedLevels={setSelectedLevels}
                setOffset={setOffset}
              />
            </div>
            <div className='w-full sm:w-1/3'>
              <GamesProvinceFilter
                selectedProvinces={selectedProvinces}
                setSelectedProvinces={setSelectedProvinces}
                setOffset={setOffset}
              />
            </div>
          </div>
        </>
      ) : (
        <div className='flex flex-row gap-10'>
          <Placeholder height='h-10' classNames='rounded-lg w-1/3'/>
          <Placeholder height='h-10' classNames='rounded-lg w-1/3'/>
          <Placeholder height='h-10' classNames='rounded-lg w-1/3'/>
        </div>
      )}
      {kpi ?
        <div className="flex flex-col sm:flex-row gap-3 my-5">
          <div className="w-full flex flex-col gap-3">
            <div className="flex flex-col gap-4 justify-between">
              <div className="w-full flex flex-row gap-3 justify-between">
                <EconomicKpi
                  intl={intl}
                  title="Gare designate"
                  value={kpi.kpis.num_games_calendar}
                />
                <EconomicKpi
                  intl={intl}
                  title="Tasse gara"
                  value={kpi.kpis.amt_game_tax * 2}
                  color="text-am-700"
                  style="currency"
                />
                <EconomicKpi
                  intl={intl}
                  title="Margine"
                  value={1 - (sumProperties(kpi.kpis, ['amt_fee_ref', 'amt_fee_udc', 'amt_fee_obs', 'amt_trip_ref', 'amt_trip_udc', 'amt_trip_obs']) / (kpi.kpis.amt_game_tax * 2))}
                  color="text-green-700"
                  style="percent"
                />
              </div>
              <div className="w-full flex flex-row gap-2 justify-between">
                <EconomicKpi
                  intl={intl}
                  style="currency"
                  title="Spese totali"
                  color="text-red-700"
                  value={sumProperties(kpi.kpis, ['amt_fee_ref', 'amt_fee_udc', 'amt_fee_obs', 'amt_trip_ref', 'amt_trip_udc', 'amt_trip_obs'])}
                  trip={sumProperties(kpi.kpis, ['amt_trip_ref', 'amt_trip_udc', 'amt_trip_obs'])}
                />
                <EconomicKpi
                  intl={intl}
                  style="currency"
                  title="Arbitri"
                  color="text-yellow-600"
                  value={sumProperties(kpi.kpis, ['amt_fee_ref', 'amt_trip_ref'])}
                  trip={sumProperties(kpi.kpis, ['amt_trip_ref'])}
                />
                <EconomicKpi
                  intl={intl}
                  style="currency"
                  title="UdC"
                  color="text-yellow-600"
                  value={sumProperties(kpi.kpis, ['amt_fee_udc', 'amt_trip_udc'])}
                  trip={sumProperties(kpi.kpis, ['amt_trip_udc'])}
                />
                <EconomicKpi
                  intl={intl}
                  style="currency"
                  title="Osservatori"
                  color="text-yellow-600"
                  value={sumProperties(kpi.kpis, ['amt_fee_obs', 'amt_trip_obs'])}
                  trip={sumProperties(kpi.kpis, ['amt_trip_obs'])}
                />
              </div>
            </div>
            <div>
              <EconomicTrendChart id="economic-trend-chart" data={kpi.trend} intl={intl}/>
            </div>
          </div>
          <div className="w-full sm:w-2/5">
            <EconomicLevelChart id="economic-leve-chart" data={kpi.by_level.reverse()} intl={intl}/>
          </div>
        </div>
        :
        <div className='flex flex-row gap-10 my-5'>
          <Placeholder height='h-80' classNames='rounded-lg'/>
          <Placeholder height='h-80' classNames='rounded-lg'/>
        </div>
      }
      <div className="w-full flex pb-1 border-b-2 border-gray-900">
        <p className="font-bold text-xl">Dettaglio gare</p>
      </div>
      <div className='my-5'>
        <RefManList
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          total={games ? games.total : 0}
          containerId='observer-reports-list'
          items={itemsList}
          rowClassNames={['sm:grid', 'sm:grid-cols-5', 'sm:gap-3', '!cursor-default']}
          pagination='backend'
        />
      </div>
    </>
  );
}
