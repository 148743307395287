import React from "react";
import CheckboxGroup from "../../CheckboxGroup";

const UpdateGroups = ({value, onChange}) => {
  const options = ['ref', 'udc'];
  const scopes = value ? value.split(";") : []
  const setScopes = (e) => onChange(e.length ? e.join(";") : "")

  return (
    <div>
      <CheckboxGroup
        labelClasses="font-bold"
        horizontal
        title="Gruppi"
        options={options.map((o) => ({
          value: o,
          name: o,
        }))}
        values={scopes}
        onChange={(value) => {
          if (scopes.indexOf(value) >= 0) {
            setScopes([...scopes.filter((v) => v !== value)]);
          } else {
            setScopes([...scopes, value]);
          }
        }}
      />
    </div>
  )
};

export default UpdateGroups;
