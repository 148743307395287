import {Fragment, useState} from "react";

// Ui
import {Bars3Icon, ChevronRightIcon, UserIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {Dialog, Transition} from "@headlessui/react";

// Components
import filterRoutesByScopes from "../../utils/filterRoutesByScopes";
import RoleSelector from "./RoleSelector";
import MenuDropdown from "./MenuDropdown";
import Logo from "../../components/Logo";
import MenuLink from "./MenuLink";
import UserBox from "./UserBox";
import menu from "./menu";

// Hooks
import useRoles from "../pages/Users/hooks/useRoles";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AppNavigation({children}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const roles = useRoles();
  const [expandedSidebar, setExpandedSidebar] = useState(true);
  const [scrollEnabled, setScrollEnabled] = useState(false);
  // const userData = AuthenticationService.getUserData();

  const closeSidebarMobile = () => {
    if (!scrollEnabled) {
      setSidebarOpen(false);
    } else {
      setScrollEnabled(false);
    }
  };

  const filterMenuByScope = filterRoutesByScopes(menu);

  const menuItemsNames = filterMenuByScope.map(item => item.name);

  const ticketToBottom = menuItemsNames.includes("audit") ? "" : "mt-auto";

  const openExpandedSidebar = () => {
    setExpandedSidebar(true);
  };

  const menuLink = filterMenuByScope.map((item) => {
    switch (true) {
      case (item.hidden === true):
        return null;
      case (item.dropdown !== undefined):
        return <div
          key={item.name}
          className={`${item.name === "audit" && "w-full mt-auto"}`}
        >
          <MenuDropdown
            {...item}
            clickable={expandedSidebar}
            setClickable={openExpandedSidebar}
            onTouchEnd={closeSidebarMobile}
          />
        </div>;
      default:
        return (
          <div
            key={item.name}
            className={`${item.name === "support" && `w-full ${ticketToBottom} mb-2`}`}
          >
            <MenuLink
              {...item}
              clickable={expandedSidebar}
              setClickable={openExpandedSidebar}
              onTouchEnd={closeSidebarMobile}
            />
          </div>
        );
    }
  }).filter(item => item !== null);

  return (
    <>
      <div className="h-full flex">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600/75"/>
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div
                className="relative flex-1 flex flex-col px-6 max-w-lg w-full bg-am-400 focus:outline-none overflow-y-auto"
                onScroll={() => {
                  setScrollEnabled(true);
                }}
                >
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2">
                    <button
                      type="button"
                      className="ml-1 mr-4 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4">
                  <Logo title/>
                  <nav
                    aria-label="Sidebar"
                    className="mt-2 h-85% flex-col flex justify-between flex-1"
                  >
                    <div className="h-85%">
                      <UserBox roles={roles}/>
                      <RoleSelector roles={roles}/>
                      <div className="flex flex-col h-full pt-10 border-t border-am-700 gap-y-1">
                        {menuLink}
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:shrink-0">
          <div
            className={classNames(
              expandedSidebar ? "w-80" : "w-20",
              "flex flex-col transition-all duration-300"
            )}
          >
            <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-am-400 relative">
              <div
                className={"mt-4 translate-x-4 z-10 absolute right-0 drop-shadow-xl hover:drop-shadow-2xl"}>
                <ChevronRightIcon

                  className={classNames(
                    "shrink-0 flex shrink-0 h-8 p-1 border border-gray-600 bg-gray-300 ml-auto rounded-full hover:bg-gray-100",
                    "transition-[rotate] origin-center duration-300 cursor-pointer",
                    expandedSidebar ? "rotate-180" :
                      "rotate-0")}
                  onClick={() => setExpandedSidebar(!expandedSidebar)}
                />
              </div>
              <div className="overflow-y-auto px-6 flex-1 flex flex-col pt-0 pb-4 pt-0">
                <div className={classNames(expandedSidebar ? "" : "cursor-pointer")} onClick={openExpandedSidebar}>
                  {expandedSidebar
                    ? <Logo title/>
                    : <Logo height='h-12'/>
                  }
                </div>
                <nav
                  className="mt-2 flex-col flex justify-between flex-1"
                  aria-label="Sidebar"
                >
                  <div className="h-full">
                    {expandedSidebar ? <>
                        <UserBox roles={roles}/>
                        <RoleSelector roles={roles}/>
                      </> :
                      <UserIcon className="hover:text-white cursor-pointer pb-3 mb-4 mt-6 h-11 w-auto"
                                onClick={() => setExpandedSidebar(true)}/>}
                    <div className="flex flex-col h-85% pt-8 border-t border-am-700 gap-y-1">
                      {menuLink}
                    </div>
                  </div>
                  <div>
                    {/* <LanguageSelector />
                    <MenuStoreSelector /> */}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
          <div className="lg:hidden">
            <div className="flex items-center justify-between bg-am-400 border-b border-gray-200 py-1.5">
              <div className="-mt-4 ml-2">
                <Logo height="h-10"/>
              </div>
              <div className="px-4">
                <button
                  type="button"
                  className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-3xl text-gray-500 hover:text-gray-900"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                </button>
              </div>
            </div>
          </div>
          <div className="flex-1 relative z-0 flex overflow-hidden max-w-full">
            <div className="flex flex-col flex-1 max-w-full">
              <main
                id="main"
                className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last max-w-full"
              >
                {children}
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppNavigation;
