import React, {useState, useEffect} from 'react';

// Model
import {BasicAlgorithmTaskSchema} from '@pages/Nominations/Algorithms/Algorithms.types';
import {GamesSchema} from '@pages/Games/Games.type';

// Services
import {api} from '@services/apiRequest';
import {Distance, DistancesDict} from "@services/types/city";
import {getDistancesDict} from "@services/hooks/useEliteAlgorithmGames";
import {RelocationSchema} from "@pages/Planner/Calendar/Calendar.type";


function useBasicAlgorithmGames(
  session: BasicAlgorithmTaskSchema | undefined,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) {

  const [games, setGames] = useState<GamesSchema[] | undefined>();
  const [distances, setDistances] = useState<DistancesDict | undefined>();
  const [relocations, setRelocations] = useState<RelocationSchema | undefined>();

  useEffect(() => {

    setLoading(true);

    async function _fetchBasicGames() {
      let payload: { [key: string]: string | string[] } = {}
      if (session) {
        payload['group'] = session.cod_group
        payload['dat_from'] = session.jsn_input_data.dat_from
        payload['dat_to'] = session.jsn_input_data.dat_to
        if (session.jsn_input_data.levels !== 'all') {
          payload['levels'] = session.jsn_input_data.levels
        }
        if (session.jsn_input_data.provinces !== 'all') {
          payload['provinces'] = session.jsn_input_data.provinces
        }
      }
      const {data: _games} = await api.post<GamesSchema[]>('/games/basic', payload);
      const {data: _distances} = await api.post<Distance[]>('/cities/distances', {
        cities: _games.map(i => i.city ? i.city.id : 0)
      });
      const distancesDict: DistancesDict = getDistancesDict(_distances);
      const {data: _relocations} = await api.get<RelocationSchema>(`/calendar/relocations/${payload['group']}/${payload['dat_from']}/${payload['dat_to']}`);

      setRelocations(_relocations);
      setDistances(distancesDict);
      setGames(_games);

      setLoading(false);
    }

    if (session) {
      _fetchBasicGames();
    }

  }, [session]);

  return {games, distances, relocations};
}

export default useBasicAlgorithmGames;