import React, {useContext, useEffect, useState} from "react";

import AuthenticationService from "@services/AuthenticationService";
import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "@services/DataContext/DataContext";
import useCategories from "@services/hooks/useCategories";
import useLevels from "@services/hooks/useLevels";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

import {NominationsSection} from "@pages/Home/Sections/Nominations";
import {MembersSection} from "@pages/Home/Sections/Members";
import {GamesSection} from "@pages/Home/Sections/Games";
import GroupSelection from "@utils/groupSelection";

import Placeholder from "@ui-components/Placeholder";
import {dateFormat} from "./Home.lib";


export function StaffHome() {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const dataContext: any = useContext(DataContext);
  const groups = AuthenticationService.getUserGroups();
  const [lastUpdate, setLastUpdate] = useState(undefined);
  const [includeExtra, setIncludeExtra] = useState<boolean>(true);
  const categories = useCategories({group: dataContext.group, asObj: false});
  const [selectedCategories, setSelectedCategories] = useState<{ data: any, label: any, value: number }[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<{ label: string, value: string }[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<{ label: string, value: string }[]>([]);

  const levels = useLevels({asObj: false, managedGroup: dataContext.group});

  useEffect(() => {
    const fetchData = () => {
      api
        .get("/task/last")
        .then(({data: last}) => {
          setLastUpdate(last)
        })
        .catch(() => push({type: "error", title: "Errore durante il caricamento"}))
    };

    if (lastUpdate === undefined) {
      fetchData();
    }

  }, [lastUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-around my-3 items-center">
        <div>
          {groups.length > 1 ?
            <div className="flex flex-row gap-5 items-center">
              <span className="text-sm font-bold">Settore</span>
              <GroupSelection selectedGroup={dataContext.group} setSelectedGroup={dataContext.setGroup}/>
            </div>
            : <span className="font-bold">Settore {groups[0] === 'ref' ? 'Arbitri': 'Ufficiali di Campo'}</span>
          }
        </div>
        <div className="flex flex-row gap-1 items-center text-xs border rounded-3xl px-2 h-8">
          <span className="font-bold">Ultimo aggiornamento dati:</span>
          <i>{lastUpdate && lastUpdate[dataContext.group] ? intl.formatDate(lastUpdate[dataContext.group], dateFormat) : '-'}</i>
        </div>
      </div>
      <div className="w-full flex pb-1 border-b-2 border-gray-900">
        <p className="font-bold text-2xl">Tesserati</p>
      </div>
      {categories ?
        <MembersSection
          push={push}
          categories={categories}
          group={dataContext.group}
          selectedProvinces={selectedProvinces}
          setSelectedProvinces={setSelectedProvinces}
          includeExtra={includeExtra}
          setIncludeExtra={setIncludeExtra}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
        : <div className="flex flex-col w-full my-4 gap-2">
          <div className="flex flex-row gap-5">
            <Placeholder height="h-16" classNames="rounded-lg w-2/5"/>
            <Placeholder height="h-16" classNames="rounded-lg w-2/5"/>
            <Placeholder height="h-16" classNames="rounded-lg w-1/5"/>
          </div>
          <div className="flex flex-row gap-5">
            <Placeholder height="h-32" classNames="rounded-lg w-1/2"/>
            <Placeholder height="h-32" classNames="rounded-lg w-1/2"/>
          </div>
        </div>
      }
      <div className="w-full flex pb-1 border-b-2 border-gray-900">
        <p className="font-bold text-2xl">Designazioni</p>
      </div>
      {levels ?
        <NominationsSection
          push={push}
          group={dataContext.group}
          includeExtra={includeExtra}
          selectedLevels={selectedLevels}
          selectedProvinces={selectedProvinces}
          selectedCategories={selectedCategories}
        />
        : <div className="flex flex-col w-full my-4 gap-2">
          <div className="flex flex-row gap-5">
            <Placeholder height="h-16" classNames="rounded-lg"/>
          </div>
          <div className="flex flex-row gap-5">
            <Placeholder height="h-40" classNames="rounded-lg"/>
            <Placeholder height="h-40" classNames="rounded-lg"/>
            <Placeholder height="h-40" classNames="rounded-lg"/>
          </div>
        </div>
      }
      <div className="w-full flex pb-1 border-b-2 border-gray-900">
        <p className="font-bold text-2xl">Gare</p>
      </div>
      {levels ?
        <GamesSection
          push={push}
          levels={levels}
          group={dataContext.group}
          selectedLevels={selectedLevels}
          setSelectedLevels={setSelectedLevels}
        />
        : <div className="flex flex-col w-full my-4 gap-2">
          <div className="flex flex-row gap-5">
            <Placeholder height="h-16" classNames="rounded-lg"/>
          </div>
          <div className="flex flex-row gap-5">
            <Placeholder height="h-40" classNames="rounded-lg"/>
            <Placeholder height="h-40" classNames="rounded-lg"/>
            <Placeholder height="h-40" classNames="rounded-lg"/>
          </div>
        </div>
      }
    </div>
  );
}
