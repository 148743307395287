import React from "react";

import {useHistory} from "react-router-dom";
import {useIntl} from "react-intl";

import {CheckCircleIcon, PencilIcon} from "@heroicons/react/24/outline";
import {timeStampFormat} from "@pages/Planner/Absences/Absences.lib";
import NotificationPing from "@ui-components/NotificationPing";
import {Thread} from "@pages/Threads/Threads.type";


export const getListItems = (
  data: Thread[],
  idFipCode: number,
  setUpdateThread: React.Dispatch<React.SetStateAction<Thread | undefined>>
) => {

  const {push} = useHistory()
  const intl = useIntl();

  return data.map((thread) => {
    const isPersonal = thread.id_creator === idFipCode
    const toRead = Boolean(
      (!isPersonal && thread.recipients.filter(i => i.id_member === idFipCode && !i.flg_read).length)
      || (isPersonal && thread.flg_check_needed)
    )

    return ({
      rowId: `thread-${thread.id}`,
      rowOnClick: () => push(`/threads/${thread.id}`),
      rowElement: <div
        className={`p-3 cursor-pointer ${isPersonal ? 'border bg-am-600 bg-opacity-30 hover:bg-white rounded-xl shadow-lg' : ''}`}>
        <div className="flex flex-row justify-between">
          <div className="text-sm">
            <span>Da <b>{thread.creator.member}</b></span>
            <span className="mx-1.5">&#8226;</span>
            <span>{intl.formatDate(thread.tms_creation, timeStampFormat)}</span>
          </div>
          <div className="text-sm flex flex-row gap-2">
            {isPersonal ?
              <div className="cursor-pointer hover:underline hover:font-bold flex flex-row items-center gap-2"
                   onClick={(e) => {
                     e.stopPropagation();
                     setUpdateThread(thread)
                   }}>
                <span className="hidden sm:flex">Modifica</span>
                <PencilIcon className="h-4 w-4 inline-block -mt-1"/>
              </div>
              : null
            }
            {toRead ? <NotificationPing external={4} internal={2}/>
                : isPersonal ? null : <CheckCircleIcon className="h-4 w-4"/>
            }
          </div>
        </div>
        <div className="mt-2 font-medium border-t pt-2">
          {thread.subject}
        </div>
      </div>
    })
  })
}