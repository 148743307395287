import {api} from "@services/apiRequest";
import {IntlShape, useIntl} from "react-intl";
import {useContext, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {QuizSessionSaveSchema} from "@components/RefMan/Quiz/types";

export const useSession = () => {

  // hooks
  const intl: IntlShape = useIntl();

  // context
  const {push} = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);

  const callApi = async (fn: Promise<any>, errorMsg: string) => {
    setLoading(true);
    try {
      return await fn;
    } catch (e) {
      push({type: "error", title: intl.formatMessage({id: "error"}), message: errorMsg});
    } finally {
      setLoading(false);
    }
  }

  const getAll = async () =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.get(`/quizzes/session`);
        resolve(data);
      }),
      "GET_SESSION",
    );

  const activateSession = async (idQuiz: number) =>
    await callApi(
      new Promise(async (resolve) => {
        const payload = {id_quiz: idQuiz}
        const {data} = await api.put(`/quizzes/session`, payload);
        resolve(data);
        resolve(null);
      }),
      "ACTIVATE_SESSION_QUESTION",
    );

  const getQuizQuestionList = async (idQuiz: number) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.get(`/quizzes/session_questions_list/${idQuiz}`);
        resolve(data);
      }),
      "POST_QUESTION",
    );


  const getTimeLeft = async (idQuiz: number) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.get(`/quizzes/session/time_left?id_quiz=${idQuiz}`);
        resolve(data);
      }),
      "GET_TMS_START",
    );

  const saveSession = async (payload: QuizSessionSaveSchema) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.put(`/quizzes/session/save`, payload);
        resolve(data);
        resolve(null);
      }),
      "SAVE_CURRENT_SESSION",
    );


  return {
    getAll,
    activateSession,
    getQuizQuestionList,
    getTimeLeft,
    saveSession,
    loading
  };
}