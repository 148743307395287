import React, {useState} from 'react';

// Components
import SearchSelect from "@ui-components/SearchSelect";
import Button from '@ui-components/Button';
import Modal from "@ui-components/Modal";


// Types
import {GeneralParametersModalProps, Couple} from "@pages/Nominations/Algorithms/Algorithms.types";
import {HandRaisedIcon, HandThumbUpIcon, TrashIcon, UsersIcon} from "@heroicons/react/24/outline";
import Checkbox from "@ui-components/Checkbox";
import Label from "@ui-components/Label";
import Select from "@ui-components/Select";


function GameParametersModal(
  {
    group,
    games,
    userKwargs,
    setUserKwargs,
    setShowGeneralParameters,
    saveParameters,
    setToSave,
    basic
  }: GeneralParametersModalProps) {

  const rolesToMatch = group === 'ref' ? ['ref'] : ['sp', 'crono', 'a24s']
  const limit = group === 'ref' ? 2 * games.length : 3 * games.length

  const memberOptions: { [key: number]: { label: string, value: number } } = {}
  games.forEach(g => {
    g.assignment_options.filter(o => o.admitted_roles
      .filter(r => rolesToMatch.includes(r)).length > 0).forEach(m => {
      memberOptions[m.id_member] = {label: m.member.tag, value: m.id_member}
    })
  })

  let _assigned: { label: string, value: number }[] = [];
  let _forbidden: { label: string, value: number }[] = [];

  // These are members already assigned to specific role, to specific game, or in general
  const roleGameAssigned = (userKwargs?.member_role_game.assign ?? []).map(i => i[0]);
  const gameAssigned = (userKwargs?.member_game.assign ?? []).map(i => i[0]);
  const assigned = (userKwargs?.member.assign ?? []);

  const forbidden = (userKwargs?.member.forbidden ?? []);

  // Controllo se quelli che avevo assegnato sono ancora disponibili
  roleGameAssigned.concat(gameAssigned).concat(assigned).forEach(idFipCode => {
    if (memberOptions[idFipCode]) {
      _assigned.push(memberOptions[idFipCode])
    }
  })

  forbidden.forEach(idFipCode => {
    if (memberOptions[idFipCode]) {
      _forbidden.push(memberOptions[idFipCode])
    }
  })

  const [couple, setCouple] = useState<Couple>({member1: '-', member2: '-'})

  const options = Object.values(memberOptions)
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <Modal maxWidth="w-full sm:max-w-3xl" onExit={() => setShowGeneralParameters(false)}>
      <h1 className="font-bold mb-2 text-xl">Parametri generali</h1>
      <div className="flex flex-col gap-5 my-10">
        {basic ? null :
          <div className="flex flex-col sm:flex-row gap-5 justify-start mb-5">
            <div className="flex flex-row items-center gap-2">
              {/*@ts-ignore*/}
              <Checkbox
                checked={userKwargs.flg_forbidden_same_city_team_a}
                classNames={undefined}
                onChange={() => {
                  setUserKwargs({
                    ...userKwargs,
                    flg_forbidden_same_city_team_a: !userKwargs.flg_forbidden_same_city_team_a
                  })
                  setToSave(true)
                }}
              />
              <span className="text-xs">Evita designazioni nella propria città</span>
            </div>
            <div className="flex flex-row items-center gap-2">
              {/*@ts-ignore*/}
              <Checkbox
                checked={userKwargs.flg_forbidden_same_city_team_b}
                classNames={undefined}
                onChange={() => {
                  setUserKwargs({
                    ...userKwargs,
                    flg_forbidden_same_city_team_b: !userKwargs.flg_forbidden_same_city_team_b
                  })
                  setToSave(true)
                }}
              />
              <span className="text-xs">Evita designazioni della propria città in trasferta</span>
            </div>
          </div>
        }
        <SearchSelect
          label={<span><HandThumbUpIcon
            className="inline-block w-5 mr-1 mb-1 text-am-700"/>Tesserati da designare</span>}
          options={options}
          value={_assigned}
          onChange={(members: { value: number, label: string }[]) => {

            // Avoiding duplications and inconsistency
            const selectedMembers = members.slice(0, limit).map(i => i.value)
              .filter(i => !roleGameAssigned.includes(i) && !gameAssigned.includes(i) && !forbidden.includes(i));

            // Update round-constraint on EliteAlgorithmGames component
            setUserKwargs({...userKwargs, member: {...userKwargs.member, assign: selectedMembers}})
            setToSave(true);
          }}
          isMulti={true}
          loadOptions={null}
          CustomOption={null}
          minHeight={10}
        />
        <SearchSelect
          label={<span><HandRaisedIcon
            className="inline-block w-5 mr-1 mb-1 text-red-600"/>Tesserati da non designare</span>}
          options={options}
          value={_forbidden}
          onChange={(members: { value: number, label: string }[]) => {

            // Avoiding duplications and inconsistency
            const selectedMembers = members.map(i => i.value)
              .filter(i => !roleGameAssigned.includes(i) && !gameAssigned.includes(i) && !assigned.includes(i));

            // Update round-constraint on EliteAlgorithmGames component
            setUserKwargs({...userKwargs, member: {...userKwargs.member, forbidden: selectedMembers}})
            setToSave(true);
          }}
          isMulti={true}
          loadOptions={null}
          CustomOption={null}
          minHeight={10}
        />
        <div>
          <Label>
            <span>
              <UsersIcon className="inline-block w-5 mb-1 text-am-700"/>
              <HandRaisedIcon className="inline-block w-5 mr-1 mb-1 text-red-600"/>
              Coppie da evitare
            </span>
          </Label>
          {userKwargs.member_couples.forbidden.map((couple: number[]) => (
            <div key={`${couple[0]}-${couple[1]}`} className="flex flex-row gap-1 font-medium text-xs mt-1">
              <span className="mx-1">&#8226;</span>
              <span>{memberOptions[couple[0]].label}</span>
              <span className="italic font-normal">con</span>
              <span>{memberOptions[couple[1]].label}</span>
              <TrashIcon
                className="h-4 w-4 ml-1 cursor-pointer"
                onClick={() => {
                  setUserKwargs({
                    ...userKwargs,
                    member_couples: {
                      ...userKwargs.member_couples,
                      forbidden: userKwargs.member_couples.forbidden.filter(i => i[0] !== couple[0] || i[1] !== couple[1])
                    }
                  })
                }}
              />
            </div>
          ))}
          <div className="flex flex-col sm:flex-row gap-3 items-end mt-3">
            <Select
              label="Tesserato 1"
              customStyles={null}
              value={couple.member1}
              options={[{value: '-', name: "-"}].concat(options.map(o => ({value: String(o.value), name: o.label})))}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCouple({...couple, member1: e.target.value})
              }}
            />
            <Select
              label="Tesserato 2"
              customStyles={null}
              value={couple.member2}
              options={[{value: '-', name: "-"}].concat(options.map(o => ({value: String(o.value), name: o.label})))}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCouple({...couple, member2: e.target.value})
              }}
            />
            <Button
              className="mb-1"
              disabled={couple.member1 === '-' || couple.member2 === '-' || couple.member1 === couple.member2}
              onClick={() => {
                setUserKwargs({
                  ...userKwargs,
                  member_couples: {
                    ...userKwargs.member_couples,
                    forbidden: [...userKwargs.member_couples.forbidden, [Number(couple.member1), Number(couple.member2)]]
                  }
                })
                setCouple({member1: '-', member2: '-'})
              }}>
              +
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-5 flex flex-row justify-between">
        <Button styleType="white" onClick={() => setShowGeneralParameters(false)}>
          Annulla
        </Button>
        <Button onClick={() => {
          setShowGeneralParameters(false)
          saveParameters()
        }}>
          Salva
        </Button>
      </div>
    </Modal>
  );
}

export default GameParametersModal;