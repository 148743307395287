import {ObserverReportsReaderRadioType} from "./ObserverReportsReaderRadio.type";
import React from "react";

const ObserverReportsReaderRadio = ({result}: ObserverReportsReaderRadioType) => {
  return <div className="observerreportsreaderradio__container flex flex-col gap-y-1 p-2">
    <div className="observerreportsreaderradio__question text-lg font-semibold mb-3">
      {result.question.title}
    </div>
    <div className="observerreportsreaderradio__question ml-2">
      {result?.question?.options?.[Number(result.value)] && result.question.options[Number(result.value)]}
    </div>
  </div>
}

export default ObserverReportsReaderRadio