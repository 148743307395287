import {getDateStr} from "@pages/Planner/Calendar/Calendar.lib";

import {SelectedCategory, SelectedProvince} from "@pages/Members/Members.type";
import {SelectedLevel} from "@pages/Games/Games.type";
import React from "react";
import {IntlShape} from "react-intl";

export const getPayload = (
  group: string,
  selectedCategories: SelectedCategory[],
  selectedProvinces: SelectedProvince[],
  selectedLevels: SelectedLevel[],
  startDate: Date | undefined,
  endDate: Date | undefined,
  selectedNominators: SelectedProvince[]
) => {
  return {
    cod_group: group.toUpperCase(),
    ...(selectedCategories.length && {cod_sub_category: selectedCategories.map(i => i.data.cod_sub_category)}),
    ...(selectedNominators.length && {nominators: selectedNominators.map(i => i.value)}),
    ...(selectedProvinces.length && {des_province: selectedProvinces.map(i => i.value)}),
    ...(selectedLevels.length && {cod_level: selectedLevels.map(i => i.value)}),
    ...(startDate && {start_date: getDateStr(startDate)}),
    ...(endDate && {end_date: getDateStr(endDate)}),
  }
}


export const Kpi = (
  {intl, title, Icon, value, perc, color}: {
    intl: IntlShape,
    title: string;
    Icon: any,
    value: number,
    perc?: number,
    color: string
  }) =>
  <div className={`flex flex-row justify-between min-w-fit w-full sm:w-40 p-3 pb-2 border rounded-xl ${color}`}>
    <div className="flex flex-col">
      <span className="font-semibold text-gray-600 text-sm sm:text-normal">{title}</span>
      <span className="text-xl font-bold">{intl.formatNumber(value, {maximumFractionDigits: 1})}</span>
    </div>
    <div className="flex flex-col items-end text-lg gap-1">
      <Icon className="w-5 text-gray-600"/>
      {perc ? <span>{intl.formatNumber(perc, {style: 'percent', maximumFractionDigits: 1})}</span> : null}
    </div>
  </div>

