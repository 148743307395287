import React, {useContext, useState} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";

import {AssignListMembers} from "@components/RefMan/AssignListMembers";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import {AddSessionModalProps} from "@components/RefMan/Quiz/types";

export function AddSessionModal({onExit, data, setRefreshData}: AddSessionModalProps) {

  // context
  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [inserted, setInserted] = useState<number[] | undefined>(data.sessions.map(i => i.id_participant))

  const updateData = () => {
    setLoading(true)
    api.post(`/quizzes/session/participants`, {
      id_quiz: data.id,
      members: inserted
    })
      .then(() => {
        push({title: "Dati salvati con successo", type: "success"});
        setRefreshData(prev => !prev)
        onExit();
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  const description = () => <div className="flex flex-col">
    <span className="font-bold sm:text-xl">Assegnazione tesserati</span>
  </div>

  return (
    <Modal
      maxWidth="w-full sm:max-w-4xl"
      onExit={onExit}
    >
      {inserted ?
        <AssignListMembers
          membersList={inserted}
          setMembersList={setInserted}
          description={description()}
        /> : null
      }
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between mt-10">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button onClick={updateData} submitting={loading}>
          Salva
        </Button>
      </div>
    </Modal>
  );
}
