import {ObserverReportsFormInputTextType} from "./ObserverReportsFormInputText.type";
import React from "react";
import {
  onTextChange
} from "@components/RefMan/Reports/ReportsForm/ReportsFormInputText/ObserverReportsFormInputText.lib";

const ObserverReportsFormInputText = ({
                                        data,
                                        itemId,
                                        sectionId,
                                        results,
                                        setResults,
                                        setIsFormChanged,
                                      }: ObserverReportsFormInputTextType) => {
  return <div className="observerreportsforminputtext__container">
    <div className="observerreportsforminputtext__header border-b border-gray-200 pb-3">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {`${data.title} `}
        <span className="text-red-600">
          {(data.isRequired === undefined || data.isRequired) && "*"}
        </span>
      </h3>
    </div>
    <div className="observerreportsforminputtext__body pt-3">
      <div className="col-span-full">
        <div className="mt-2">
          <textarea
            id={`textarea-${sectionId.toString()}-${itemId.toString()}`}
            name={`textarea-${sectionId.toString()}-${itemId.toString()}`}
            rows={data.rows}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-am-600 sm:text-sm sm:leading-6"
            defaultValue={results?.[data.id]?.value ? results?.[data.id]?.value : ""}
            onChange={(e) => {
              onTextChange(e, data, results, setResults, sectionId)
              setIsFormChanged(true);
            }}
          />
        </div>
      </div>
    </div>
  </div>
}

export default ObserverReportsFormInputText;