import React from "react";

interface StatusBulletProps {
  primaryColor: string;
  backgroundColor: string;
}

export function StatusBullet({primaryColor, backgroundColor}: StatusBulletProps) {
  return (
    <>
      <div className="flex items-center gap-x-1.5">
        <div className={`flex-none rounded-full ${backgroundColor} p-1`}>
          <div className={`h-2 w-2 rounded-full ${primaryColor}`}>
          </div>
        </div>
      </div>
    </>
  )
}
