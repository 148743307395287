import React, {Dispatch} from "react";

type Nullable<T> = T | null;

export const quiz_cod_type_to_notify = 'TO NOTIFY';
export const quiz_cod_type_not_started = 'NOT STARTED';
export const quiz_cod_type_in_progress = 'IN PROGRESS';
export const quiz_cod_type_finished = 'FINISHED';


export type codStatusType =
  typeof quiz_cod_type_to_notify |
  typeof quiz_cod_type_not_started |
  typeof quiz_cod_type_in_progress |
  typeof quiz_cod_type_finished;

export interface QuizListBaseResponseType {
  id: number;
  id_creator: number;
  des_title: string;
  tms_deadline: Nullable<Date>;
  cod_status: codStatusType;
  flg_timer: boolean;
  time_duration: Nullable<string>;
  dat_creation: Date;
  num_to_notify_sessions: Nullable<number>;
  num_not_started_sessions: Nullable<number>;
  num_in_progress_sessions: Nullable<number>;
  num_finished_sessions: Nullable<number>;
  num_questions: Nullable<number>
  tag_list: string[];
  member: {
    member: string;
    tag: string;
  }
}

export interface QuizListPaginatedBaseResponseType {
  total: number;
  items: QuizListBaseResponseType[];
}

export interface QuizListProps {
  showPrivateQuiz: boolean;
}

export interface QuestionTagType {
  cod_tag: string;
}

export interface QuizListPayloadType {
  id_creator: Nullable<number>;
  des_title: Nullable<string>;
  cod_status: Nullable<string>;
  tags: string[];
  flg_private: Nullable<boolean>;
  dat_creation: Nullable<string>;
  ordering: Nullable<string>;
  offset: Nullable<number>;
  limit: Nullable<number>;
}

export interface QuizFilterType {
  selectedStatus: { value: string } | null;
  selectedMember: { value: number } | null;
  searchTitle: string | null;
  selectedTags: { value: string }[];
  limit: number;
  offset: number;
}


export interface QuestionBaseType {
  des_question: string;
  des_answers: string[];
  des_correct_answers: string[];
  cod_type: string;
  tags: QuestionTagType[];
  flg_private: boolean;
  flg_deprecated: boolean;
  des_regulatory_reference: string | null;
  id_quiz?: number,
}

export interface QuestionType extends QuestionBaseType {
  id: number;
}

export interface QuestionDetailedType extends QuestionType {
  dat_creation: string;
  member: {
    member: string;
  }
}

export const create_quiz_session = 'Crea sessione';
export const close_quiz_session = 'Termina sessione';
export const duplicate_quiz = 'Duplica';
export const modify_quiz = 'Modifica';
export const delete_quiz = 'Elimina';
export const show_quiz = 'Visualizza';

export type QuizDetailProps = {
  id_quiz: string | undefined;
}

export type QuizActionState =
  typeof show_quiz |
  typeof create_quiz_session |
  typeof close_quiz_session |
  typeof duplicate_quiz |
  typeof modify_quiz |
  typeof delete_quiz |
  typeof show_quiz;


export interface ActionListType {
  name: QuizActionState;
  description: string;
  icon: any;
}

export interface QuizActionListProps {
  cod_status: string;
  setAction: (value: (((prevState: QuizActionState) => QuizActionState) | QuizActionState)) => void;
}

export interface RefreshDataProps {
  setRefreshData: Dispatch<React.SetStateAction<boolean>>;
}

export interface QuizQuestionProps extends RefreshDataProps {
  idQuiz: number;
  order: number;
  question: QuestionType;
  editSort: boolean;
  numQuestions: number;
  setQuestions: Dispatch<React.SetStateAction<Association[]>>
}

export interface DeleteQuestionFromQuizProps extends RefreshDataProps {
  onExit: () => void;
  idQuiz: number;
  idQuestion: number;
}

export type CreateOrUpdateQuestionModalProps = {
  idQuiz?: number;
  onExit: () => void;
  setRefreshData?: Dispatch<React.SetStateAction<boolean>>;
} & ({ edit: true; idQuestion: number; } | { edit: false })

export interface AddQuestionToQuizModalProps extends RefreshDataProps {
  onExit: () => void;
  idQuiz: number | undefined;
}

export interface AddQuestionToQuizProps extends RefreshDataProps {
  idQuiz: number;
}


export interface filterAllQuestionsPayloadSchema {
  tags?: string[];
  des_question?: string;
  ordering: Nullable<string>;
  offset: Nullable<number>;
  limit: Nullable<number>;
}

export interface QuestionListPaginatedDetailResponseType {
  total: number;
  items: QuestionDetailedType[];
}

export interface DeleteQuizProps {
  push: any;
  onExit: () => void;
  idQuiz: number;
}

export interface StartStopSessionProps {
  push: any;
  onExit: () => void;
  idQuiz: number;
  action: typeof create_quiz_session | typeof close_quiz_session;
}

export interface NumQuizzesByQuestionIdSchema {
  num_not_started: number;
  num_in_progress: number;
  num_finished: number;
}

export interface Association {
  order: number;
  question: QuestionType
}

export interface QuizDetailResponseType extends QuizListBaseResponseType {
  associations: Association[];
  sessions: QuizSessionMemberSchema[];
}

export interface QuizDetailQuestionsProps {
  push: any;
  data: QuizDetailResponseType;
  setRefreshData: Dispatch<React.SetStateAction<boolean>>;
}

export interface QuizDetailSessionsProps {
  data: QuizDetailResponseType;
  setRefreshData: Dispatch<React.SetStateAction<boolean>>;
}

interface QuizSessionBaseSchema {
  cod_status: codStatusType;
  tms_start: Nullable<string>;
  tms_end: Nullable<string>;
  id_quiz: number
  id_participant: number;
}

export interface QuizSessionMemberSchema extends QuizSessionBaseSchema {
  participant: {
    tag: string;
    member: string;
    category: {
      des_sub_category: string;
    }
  }
}

export interface QuizSessionListSchema extends QuizSessionBaseSchema {
  quiz: QuizListBaseResponseType;
}

export interface StartQuizSessionProps {
  onExit: () => void;
  idQuiz: number;
}

export interface QuizSessionQuestionSchema {
  id_question: number;
  des_answers: string[];
  question: {
    des_question: string;
    des_answers: string[];
  }
}

export interface EndSessionModalProps {
  onExit: () => void;
  idQuiz: number;
  questionsList: QuizSessionQuestionSchema[];
}

export interface AddSessionModalProps {
  onExit: () => void;
  data: QuizDetailResponseType;
  setRefreshData: Dispatch<React.SetStateAction<boolean>>;
}


export interface QuizSessionSaveSchema {
  id_quiz: number;
  answers: {
    id_question: number;
    des_answers: string[];
  }[];
}


export interface CreateNewQuizPayload {
  des_title: string;
  tms_deadline?: string;
  flg_timer: boolean;
  time_duration?: string;
}


export interface QuizCommonResultsTableData extends QuizSessionBaseSchema {
  participant: {
    id: number;
    member: string;
    tag: string;
  }
  num_correct_answers: number;
  num_question: number;
  answers: [
    {
      des_answers: string[];
      is_correct: boolean;
      question_order: number;
      id_question: number;
      question: {
        des_question: string;
        des_answers: string[];
      }
    }
  ]
}


export interface QuizCreateModalProps {
  onExit: () => void;
  quiz?: QuizListBaseResponseType
}


export interface QuizResultDetailProps {
  id_quiz: string | undefined;
  id_participant: string | undefined;
}

export interface QuizResultKpi {
  quiz: QuizDetailResponseType
  num_correct_answers: number
  num_wrong_answers: number
  common_avg_num_correct_answers: number
  common_avg_num_wrong_answers: number
}