import React from "react";
import {NominationSchema} from "@services/types/nomination";
import Alert from "@ui-components/Alert";
import {TODAY} from "@utils/constants";
import Tooltip from "@ui-components/Tooltip";
import {FormatDateOptions} from "react-intl";

export const dateFormat: FormatDateOptions = {day: "numeric", month: "long", year: "2-digit"}

export const getMetadata = (title: string, value: string | number) =>
  <div className="p-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
    <dt className="text-sm font-medium text-gray-900">{title}</dt>
    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{value}</dd>
  </div>

export const closeGameWarning = (nomination: NominationSchema, group: 'ref' | 'udc') => {
  const datGame = new Date(nomination.game.dat_game).getTime()
  const datToday = new Date(TODAY).getTime()
  const deltaDays = (datGame - datToday) / (1000 * 3600 * 24)
  const nominator = group === 'ref'
    ? nomination.game.nominator_ref?.member ?? ''
    : nomination.game.nominator_udc?.member ?? ''
  if (deltaDays < 3) {
    return <Alert
      title="Manca poco alla gara!"
      text={<span>Ricordati che in questo caso devi anche contattare il designatore, che in questo caso è <b>{nominator}</b></span>}
      type="warning"
    />
  }
  return <Alert
    title={<span>Il designatore di questa gara è <b>{nominator}</b></span>}
    text="Sei comunque in tempo per inviare la comunicazione dell'assenza alla gara tramite RefMan."
  />
}

const infoRevoca = <span>
  Chiedi la revoca della gara se <b>la tua assegna è giustificata</b> (malattia, impegni CIA, altra convocazione, ecc).<br/><br/>
  Il designatore potrà comunque valutare diversamente.<br/><br/>
  In caso di malattia potresti dover inviare un certificato medico all'Ufficio Designazioni.
</span>

export const InfoRevoca = () => {
  return (<Tooltip
      placement="bottom"
      text={infoRevoca}
      customStyle="text-xs normal-case"
      size="small"
      iconName="InformationCircleIcon"
    />
  )
}