import React from "react";

const PoweredBy = () => {
  return (
    <div className="flex flex-col justify-center mt-14 text-xs text-center">
      <span className="font-medium">Referee Manager Platform</span>
      <span className="font-light">Stagione 2024/2025</span>
    </div>
  );
};

export default PoweredBy;
