import { api } from '@services/apiRequest';
import { Member } from '@services/types/member';
import { SelectedLevel } from '@pages/Games/Games.type';
import { Evaluations, MemberGroup } from '@pages/Reports/Reports.type';
import { ObserversListObjectTypes, MembersListObjectTypes } from './Ratings.type';
import {ReportType} from "@components/RefMan/Reports/ReportsList/ObserverReportsList.type";
import {SelectedProvince} from "@pages/Members/Members.type";

/**
 * @function getFilteredData
 *
 * @param data
 * @param memberGroup
 * @param selectedLevels
 * @param selectedObservers
 * @param selectedProvinces
 */
export const getFilteredData = (
  data: Evaluations[] | undefined,
  memberGroup: MemberGroup | undefined,
  selectedLevels: SelectedLevel[],
  selectedObservers: any[],
  selectedProvinces: SelectedProvince[]
) => {
  if (!data) return [];

  let evaluationsData = data;

  // Member Group
  if (memberGroup) {
    evaluationsData = getEvaluationsDataByMemberGroups(data, memberGroup);
  }

  // Levels
  if (selectedLevels && selectedLevels.length > 0) {
    evaluationsData = getEvaluationsDataByMemberGroupsAndLevels(data, selectedLevels);
  }

  // Observers
  if (selectedObservers && selectedObservers.length > 0) {
    evaluationsData = getEvaluationsDataByMemberGroupsAndLevelsAndObservers(data, selectedObservers);
  }

  // Provinces
  if (selectedProvinces.length) {
    evaluationsData = evaluationsData.filter(i => selectedProvinces.map(c => c.value).includes(i.member.city.des_province))
  }

  return evaluationsData;
};

export const getEvaluationsDataByMemberGroups = (data: Evaluations[], memberGroup: MemberGroup) => {
  return data.filter((evaluations: Evaluations) => memberGroup.cod_sub_categories.includes(evaluations.member.category.cod_sub_category));
};

export const getEvaluationsDataByMemberGroupsAndLevels = (data: Evaluations[], selectedLevels: SelectedLevel[]) => {
  const levels = selectedLevels.map((levelObject) => levelObject.value);
  return data.filter((evaluations: Evaluations) => levels.includes(evaluations.game.cod_level));
};

export const getEvaluationsDataByMemberGroupsAndLevelsAndObservers = (data: Evaluations[], selectedObservers: any[]) => {
  const observers = selectedObservers.map((observerObject) => observerObject.data.id_fip_code);
  return data.filter((evaluations: Evaluations) => observers.includes(evaluations.observer.id_fip_code));
};

/**
 * @function getCurrentLevels
 *
 * @param filteredData
 */
export const getCurrentLevels = (filteredData: Evaluations[]) => Array.from(new Set(filteredData)).map((i) => i.game.cod_level);

/**
 * @function getCurrentObservers
 *
 * @param filteredData
 */
export const getCurrentObservers = (filteredData: Evaluations[]) =>
  filteredData
    .reduce((acc: Member[], i) => {
      if (!acc.find((item) => item.id_fip_code === i.id_observer)) {
        acc.push(i.observer);
      }
      return acc;
    }, [])
    .sort((a, b) => a.member.localeCompare(b.member));

/**
 * @function getListsData
 *
 * @param filteredData
 * @returns
 */
export const getListsData = (filteredData: Evaluations[]) => {
  const observers: ObserversListObjectTypes = {};
  const members: MembersListObjectTypes = {};

  filteredData.forEach((rowData: Evaluations) => {
    // Member
    if (rowData?.member.id_fip_code) {
      if (members[rowData.member.id_fip_code]) {
        members[rowData.member.id_fip_code].count++;
        members[rowData.member.id_fip_code].vote += rowData?.tags?.voto ? Number(rowData.tags.voto) : 0;
        members[rowData.member.id_fip_code].average =
          Math.round((members[rowData.member.id_fip_code].vote / members[rowData.member.id_fip_code].count) * 100) / 100;
        members[rowData.member.id_fip_code].votes?.push({
          vote: rowData?.tags?.voto ? Number(rowData.tags.voto) : 0,
          potential: rowData?.tags?.potenziale,
          date: rowData.game.dat_game,
          game: {
            date: rowData.game.dat_game,
            level: rowData.game.cod_level,
            teams: `${rowData.game.team_a.team_alias} - ${rowData.game.team_b.team_alias}`,
          },
          role: rowData.role,
          observer: rowData.observer.member,
          idMember: rowData.id_member,
          codGame: rowData.cod_game,
        });
      } else {
        members[rowData.member.id_fip_code] = {
          idFipCode: rowData.member.id_fip_code,
          name: rowData.member.member,
          firstname: rowData.member.firstname,
          lastname: rowData.member.lastname,
          count: 1,
          vote: rowData?.tags?.voto ? Number(rowData.tags.voto) : 0,
          average: rowData?.tags?.voto ? Number(rowData.tags.voto) : 0,
          votes: [],
        };
        members[rowData.member.id_fip_code].votes?.push({
          vote: rowData?.tags?.voto ? Number(rowData.tags.voto) : 0,
          potential: rowData?.tags?.potenziale,
          date: rowData.game.dat_game,
          game: {
            date: rowData.game.dat_game,
            level: rowData.game.cod_level,
            teams: `${rowData.game.team_a.team_alias} - ${rowData.game.team_b.team_alias}`,
          },
          role: rowData.role,
          observer: rowData.observer.member,
          idMember: rowData.id_member,
          codGame: rowData.cod_game,
        });
      }
    }
    // Observer
    if (rowData?.observer?.id_fip_code) {
      if (observers[rowData.observer.id_fip_code]) {
        observers[rowData.observer.id_fip_code].count++;
        observers[rowData.observer.id_fip_code].vote += rowData?.tags?.voto ? Number(rowData.tags.voto) : 0;
        observers[rowData.observer.id_fip_code].average =
          Math.round((observers[rowData.observer.id_fip_code].vote / observers[rowData.observer.id_fip_code].count) * 100) / 100;
      } else {
        observers[rowData.observer.id_fip_code] = {
          idFipCode: rowData.member.id_fip_code,
          name: rowData.observer.member,
          firstname: rowData.observer.firstname,
          lastname: rowData.observer.lastname,
          count: 1,
          vote: rowData?.tags?.voto ? Number(rowData.tags.voto) : 0,
          average: rowData?.tags?.voto ? Number(rowData.tags.voto) : 0,
        };
      }
    }
  });

  // Sort
  const observersData = Object.values(observers).sort((a, b) => b.average - a.average);
  const membersData = Object.values(members).sort((a, b) => b.average - a.average);

  return [observersData, membersData];
};

/**
 * @function getEvaluationsData
 *
 * @param {string} codGame Id of observer
 * @param {number} idMember Id of member
 * @return {Promise<ReportType | null>} Evaluations data on success, null on failure
 */
export const getEvaluationsData = async (codGame: string, idMember: number): Promise<ReportType | null> => {
  try {
    const searchParams = new URLSearchParams({
      only_ok: '1',
      with_report: '1',
      id_fip_code: String(idMember),
      cod_game: codGame
    });

    const { data } = await api.get(`/nominations?${searchParams}`);
    return data;
  } catch (error) {
    console.error('[REFMAN-ERROR]', 'Error retrieving observer evaluations data.', error);
    return null;
  }
};
