import React, { useEffect, useState } from 'react';

// Services
import AuthenticationService from '@services/AuthenticationService';
import { START_SEASON } from '@utils/constants';

// Components
import { getObserverReportsList, getReportsStatus } from '@components/RefMan/Reports/ReportsList/ObserverReportsList.lib';
import ObserverReportsIndex from '@components/RefMan/Reports/ReportsIndex/ObserverReportsIndex';
import { getMaxLevelOrder } from '@pages/Nominations/Observers/Observers.lib';
import { getLevels } from '@pages/Reports/Reports.lib';

// Types
import { ReportStatus, ReportType } from '@components/RefMan/Reports/ReportsList/ObserverReportsList.type';
import { RatingRole, VisibilityLevel } from '@pages/Reports/Reports.type';
import { SelectedLevel } from '@pages/Games/Games.type';
import { LevelSchema } from '@services/types/level';

let searchSelectTimeout: NodeJS.Timeout;

export const ReportGames = (
  {
    role,
    group,
    levels,
    visibilityLevel
  }: {
    role: RatingRole;
    group: 'ref' | 'udc';
    levels: LevelSchema[];
    visibilityLevel: VisibilityLevel
  }) => {

  const idFipCode = AuthenticationService.getUserFipCode();
  const [reports, setReports] = useState<ReportType | false>(false);
  const [statusList, setStatusList] = useState<ReportStatus[] | undefined>(undefined);

  let initialStartDate = new Date();
  initialStartDate.setDate(initialStartDate.getDate() - 30);
  const [startDate, setStartDate] = useState<Date>(['member', 'observer'].includes(role) ? new Date(START_SEASON) : initialStartDate);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [filterMember, setFilterMember] = useState<string>();

  const [selectedLevels, setSelectedLevels] = useState<SelectedLevel[]>([]);

  let allowApi = 0;

  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);

  let _levels = levels?.filter((i) => i[`survey_model_${group}`]) ?? [];
  if (_levels.length) {
    if (group === 'ref' && visibilityLevel === 'all') {
      _levels = _levels.filter((i) => i.survey_model_ref === 1);
    } else if (visibilityLevel !== 'total') {
      const maxLevelOrder = getMaxLevelOrder(visibilityLevel, _levels, group);
      _levels = _levels.filter((i) => i.level_order >= maxLevelOrder);
    }
    allowApi = 1;
  }

  useEffect(() => {
    void (async () => {
      if (idFipCode !== false && group && allowApi) {
        setReports(false);
        if (!statusList) {
          const reportsStatus: ReportStatus[] = await getReportsStatus(idFipCode, role);
          setStatusList(reportsStatus);
        }
        const _selectedLevels = getLevels(selectedLevels, _levels);
        const observerReports: ReportType | false = await getObserverReportsList(
          idFipCode,
          group,
          role,
          startDate,
          endDate,
          _selectedLevels,
          filterMember,
          offset,
          limit
        );
        setReports(observerReports);
      }
    })();
  }, [allowApi, startDate, endDate, idFipCode, group, selectedLevels, limit, offset]);

  useEffect(() => {
    if (searchSelectTimeout) {
      clearTimeout(searchSelectTimeout);
    }
    if (idFipCode !== false && group && allowApi) {
      searchSelectTimeout = setTimeout(() => {
        void (async () => {
          const _selectedLevels = getLevels(selectedLevels, _levels);
          const observerReports: ReportType | false = await getObserverReportsList(
            idFipCode,
            group,
            role,
            startDate,
            endDate,
            _selectedLevels,
            filterMember,
            offset,
            limit
          );
          setReports(observerReports);
        })();
      }, 600);
    }
  }, [filterMember]);

  return (
    <ObserverReportsIndex
      role={role}
      group={group}
      reports={reports}
      statusList={statusList}
      setStatusList={setStatusList}
      offset={offset}
      setOffset={setOffset}
      limit={limit}
      setLimit={setLimit}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      _levels={_levels}
      selectedLevels={selectedLevels}
      setSelectedLevels={setSelectedLevels}
      filterMember={filterMember}
      setFilterMember={setFilterMember}
      setSearchKey={setFilterMember}
    />
  );
};
