import React, {useContext, useEffect, useState} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {useHistory} from "react-router-dom";
import moment from "moment/moment";

import {useQuiz} from "@components/RefMan/Quiz/hooks/useQuiz";
import DatePicker from "@ui-components/DatePicker";
import Toggle from "@ui-components/Toggle";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Input from "@ui-components/Input";

import {QuizCreateModalProps} from "@components/RefMan/Quiz/types";
import {END_SEASON, START_SEASON} from "@utils/constants";


interface payloadType {
  des_title: undefined | string;
  dat_deadline: undefined | string;
  time_deadline: undefined | string;
  flg_timer: boolean;
  time_duration_hour: undefined | number;
  time_duration_minutes: undefined | number;
}

export function QuizCreateModal({onExit, quiz}: QuizCreateModalProps) {

  const {push} = useContext(NotificationsContext);

  const [payload, setPayload] = useState<payloadType>({
    des_title: quiz ? quiz.des_title : undefined,
    dat_deadline: quiz ? moment(quiz.tms_deadline).format('YYYY-MM-DD') : undefined,
    time_deadline: quiz ? moment(quiz.tms_deadline).format('HH:mm') : undefined,
    flg_timer: quiz ? quiz.flg_timer : false,
    time_duration_hour: quiz && quiz.time_duration ? Number(quiz.time_duration.slice(0, 2)) : undefined,
    time_duration_minutes: quiz && quiz.time_duration ? Number(quiz.time_duration.slice(3, 5)) : undefined,
  });

  const history = useHistory();
  const {createNewQuiz, updateQuiz, loading} = useQuiz();


  useEffect(() => {
    // Resetto la durata
    if (!payload.flg_timer) {
      setPayload({...payload, time_duration_hour: undefined, time_duration_minutes: undefined})
    }
  }, [payload.flg_timer]);

  const handleSubmitClick = () => {
    if (payload.des_title) {
      const payload_data = {
        des_title: payload.des_title,
        flg_timer: payload.flg_timer,
      }
      if (payload.dat_deadline !== undefined && payload.time_deadline !== undefined) {
        // @ts-ignore
        payload_data['tms_deadline'] = payload.dat_deadline + 'T' + payload.time_deadline + ':00';
      }
      if (payload.time_duration_hour !== undefined && payload.time_duration_minutes !== undefined) {
        // @ts-ignore
        payload_data['time_duration'] = payload.time_duration_hour + ':' + payload.time_duration_minutes + ':00.000';
      }
      if (quiz) {
        updateQuiz(quiz.id, payload_data)
          .then(() => {
            push({title: "Dati modificati", type: "success"});
            onExit()
          })
          .catch(() => push({title: "Errore imprevisto", type: "error"}))
      } else {
        createNewQuiz(payload_data)
          .then((res) => {
            onExit();
            push({title: "Quiz creato correttamente", type: "success"});
            history.push(`/technical-area/quiz/${res}`)
          })
          .catch(() => push({title: "Errore imprevisto", type: "error"}))
      }
    }
  }

  return (
    <Modal
      onExit={onExit}
    >
      <div className="mb-5">
        <h1 className="font-bold sm:text-xl">Crea un nuovo quiz</h1>
      </div>
      <div className="w-full">
        <label className="block uppercase tracking-wide text-xs font-bold mb-2">
          Titolo del quiz
        </label>
        <Input
          label=''
          value={payload.des_title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPayload({
            ...payload,
            des_title: e.target.value
          })}
          onClear={() => setPayload({...payload, des_title: undefined})}
          name={undefined}
          validation={(value: string) => value !== ''}
          required={true}
          errorMessage={true}
          showCalendar={undefined}/>
      </div>
      <div className="w-full">

      </div>
      <div className='flex flex-col mb-2'>
        <label className="block uppercase tracking-wide text-xs font-bold">
          Deadline
        </label>
        <div className="flex flex-row gap-3 sm:gap-8 w-full justify-between mt-2">
          <div className="w-full">
            <DatePicker
              value={payload.dat_deadline ? new Date(payload.dat_deadline) : undefined}
              onChange={(i: string) => setPayload({
                  ...payload, dat_deadline: moment(i).format('YYYY-MM-DD')
                }
              )}
              label='Data'
              placeholder="Seleziona data"
              minDate={new Date(START_SEASON)}
              maxDate={new Date(END_SEASON)}
            />
          </div>
          <div className="w-full">
            <Input
              label="Ora"
              type="time"
              value={payload.time_deadline}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPayload({...payload, time_deadline: e.target.value});
              }}
              errorMessage="Inserisci orario"
              required
              name={undefined}
              validation={undefined}
              showCalendar={undefined}
              onClear={() => setPayload({...payload, time_deadline: undefined})}
            />
          </div>
        </div>
      </div>
      <div className='flex flex-row gap-x-4 mb-3'>
        <div className='w-full'>
          <label className="block uppercase tracking-wide text-xs font-bold mb-2">
            A tempo
          </label>
          <Toggle
            checked={payload.flg_timer}
            onChange={() => setPayload({...payload, flg_timer: !payload.flg_timer})}
            vertical={false}
            label={undefined}
            description={undefined}
            smaller={undefined}
          />
        </div>
        <div>
          <label className="block uppercase tracking-wide text-xs font-bold mb-2">
            Ore
          </label>
          <Input
            label=''
            value={payload.time_duration_hour}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPayload({
              ...payload,
              time_duration_hour: parseInt(e.target.value)
            })}
            type="number"
            numberOptions={{
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }}
            onClear={() => setPayload({...payload, time_duration_hour: undefined})}
            name={undefined}
            disabled={!payload.flg_timer}
            validation={false}
            required={false}
            errorMessage={undefined}
            showCalendar={undefined}/>
        </div>
        <div>
          <label className="block uppercase tracking-wide text-xs font-bold mb-2">
            Minuti
          </label>
          <Input
            label=''
            value={payload.time_duration_minutes}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPayload({
              ...payload,
              time_duration_minutes: parseInt(e.target.value)
            })}
            onClear={() => setPayload({...payload, time_duration_minutes: undefined})}
            type="number"
            numberOptions={{
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }}
            name={undefined}
            disabled={!payload.flg_timer}
            validation={undefined}
            required={false}
            errorMessage={undefined}
            showCalendar={undefined}/>
        </div>
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button
          onClick={() => handleSubmitClick()} submitting={loading}
          disabled={!payload.des_title}>
          Conferma
        </Button>
      </div>
    </Modal>
  );
}
