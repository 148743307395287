import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import italianProvincesGeodata from "@amcharts/amcharts5-geodata/italyProvincesLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


function ProvinceHeatMap({id, data}) {

  useLayoutEffect(() => {

    am5.addLicense("AM5M328990290"); // amcharts map license

    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        projection: am5map.geoMercator(),
        layout: root.horizontalLayout,
        maxZoomLevel: 1
      })
    );

    const currentProvinces = data.map(j => j.id)

    let italySeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: {
          ...italianProvincesGeodata,
          features: italianProvincesGeodata.features.filter(i => currentProvinces.includes(i.id))
        },
        calculateAggregates: true,
        valueField: "value", // This is the field that must match with the HeatMapSampleData value key
        fill: am5.color("#a8a8a8"),
      })
    );

    // Enable hover functionality
    italySeries.mapPolygons.template.setAll({
      tooltipText: "[bold]{name}[/]: {value}",
      interactive: true, // nonScalingStroke: true,
      strokeWidth: 0.5,
    });

    // Set hover fill color
    italySeries.mapPolygons.template.states.create("hover", {
      fill: am5.color("#b2b2b2"),
    });

    // Set Heatmap colors
    italySeries.set("heatRules", [
      {
        target: italySeries.mapPolygons.template,
        dataField: "value",
        min: am5.Color.brighten(am5.color("rgb(151,186,208)"), 0),
        max: am5.Color.brighten(am5.color("rgb(0, 60, 80)"), 0),
        key: "fill",
      },
    ]);

    // Add heat map data
    italySeries.data.setAll(data);

    let bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        valueField: "value",
        calculateAggregates: true,
        polygonIdField: "id"
      })
    );

    let circleTemplate = am5.Template.new({});

    bubbleSeries.bullets.push(function (root) {
      let container = am5.Container.new(root, {});

      container.children.push(
        am5.Circle.new(root, {
          radius: 13,
          fillOpacity: 0.8,
          fill: am5.color("rgb(255,255,255)"),
          // cursorOverStyle: "pointer",
          // tooltipText: `{name}: [bold]{value}[/]`
        }, circleTemplate)
      );

      return am5.Bullet.new(root, {
        sprite: container
      });
    });

    bubbleSeries.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          text: "{value}",
          fontSize: 11,
          fontWeight: "bold",
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: "center"
        })
      });
    });

    bubbleSeries.data.setAll(data);


    return () => {
      root.dispose();
    };
  }, [data, id]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} className="w-full h-80"/>;
}

export default React.memo(ProvinceHeatMap, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data);
});
