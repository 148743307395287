import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";

// Services
import AuthenticationService from "@services/AuthenticationService";
import {getMemberOptions} from "@utils/index";
import {ALL_STATUS} from "@utils/constants";
import _ from "lodash";

// Hooks
import useNominations from "@services/hooks/useNominations";
import useMembers from "@services/hooks/useMembers";
import useLevels from "@services/hooks/useLevels";

// Components
import {PersonalNominationTotalKpi} from "@components/RefMan/PersonalNominationTotalKpi";
import {NominationCard} from "@components/RefMan/NominationCard";
import NominationPersonaReportTrendChart from "./trendChart";
import NominationPersonaReportLevelChart from "./levelChart";

// Ui
import SearchSelect from "@ui-components/SearchSelect";
import Placeholder from "@ui-components/Placeholder";
import {PageHeading} from "@ui-components/Container";
import RadioGroup from "@ui-components/RadioGroup";

// Services
import {
  aggregateNominationByMonthStatus,
  aggregateNominationByLevelStatus
} from "./services";


export default function NominationPersonalReport() {

  // Se non è member, allora è un responsabile, che deve vedere gli analytics del numero di gare di ciascuno
  // e poi selezionare un tesserato e vedere lo stesso dettaglio che vede il member
  const levels = useLevels({});
  const scopes = AuthenticationService.getUserScopes();
  const groups = AuthenticationService.getUserGroups();
  const includeTemp = scopes.includes('games:temp');
  const isMember = scopes.includes('home:personal');
  const {state} = useLocation();

  const idFipCode = state?.idFipCode ? Number(state.idFipCode) : isMember ? AuthenticationService.getUserFipCode() : undefined
  const members = useMembers({onlyActive: true, assigned: true, asObj: false})
  const memberOptions = getMemberOptions(members)

  const initValue = isMember ? {value: idFipCode} : idFipCode ? memberOptions.filter(i => i.value === idFipCode)[0] : undefined
  const [selectedMember, setSelectedMember] = useState(initValue)
  const nominations = useNominations({
    idFipCode: selectedMember?.value,
    includeTemp: includeTemp,
    role: scopes.includes('reports:editor') ? groups[0] === 'ref' ? 'OBSR' : 'OBSU'
      : selectedMember?.data && selectedMember.data.category.cod_sub_category === 'OSS'
        ? selectedMember.data.category.cod_group === 'REF' ? 'OBSR' : 'OBSU' : null
  })
  const [levelFilter, setLevelFilter] = useState({options: [], selection: []})
  const [data, setData] = useState([])
  const [selectedView, setSelectedView] = useState('month')
  const [chartData, setChartData] = useState({month: undefined, level: undefined})

  useEffect(() => {
    if (nominations && levels) {
      const presentLevelCodes = _.uniq(nominations.map(i => i.game.level.cod_level))
      const filteredLevels = Object.values(levels).filter(i => presentLevelCodes.includes(i.cod_level))
      setLevelFilter({
        options: filteredLevels.map(i => ({label: i.des_level, value: i.cod_level})),
        selection: levelFilter.selection.filter(i => presentLevelCodes.includes(i.value))
      })
    }
  }, [nominations, levels])

  useEffect(() => {
    if (nominations) {
      const tmp = levelFilter.selection.length
        ? nominations.filter(i => levelFilter.selection.map(l => l.value).includes(i.game.cod_level))
        : nominations
      setData(tmp.sort((a, b) => a.game.dat_game.localeCompare(b.game.dat_game)).reverse())
    } else {
      setData([])
    }
  }, [levelFilter.selection, nominations])

  useEffect(() => {
    if (data) {
      setChartData({
        month: aggregateNominationByMonthStatus(data),
        level: aggregateNominationByLevelStatus(data)
      })
    }

  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageHeading title="Scheda attività" historyBackPath="_back"/>
      {isMember || !memberOptions ? null :
        <div className="my-5">
          <SearchSelect
            label="Tesserato"
            minHeight={50}
            value={selectedMember}
            onChange={(e) => setSelectedMember(e)}
            options={memberOptions}
            filterOption={(candidate, input) => {
              if (input) {
                const _data = candidate.data.data
                const _label = _data.id_fip_code + _data.member
                return _label.toUpperCase().includes(input.toUpperCase())
              }
              return true
            }}
          />
        </div>
      }
      {selectedMember && data ?
        <div className="flex flex-col gap-3">
          <div>
            <SearchSelect
              label="Filtra campionati"
              options={levelFilter.options}
              isMulti
              value={levelFilter.selection}
              onChange={(e) => {
                setLevelFilter({...levelFilter, selection: e});
              }}
            />
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="flex w-full md:w-1/3 justify-center">
              <PersonalNominationTotalKpi nominations={data} vertical/>
            </div>
            <div className="flex flex-col w-full md:w-2/3 items-center">
              <div>
                <RadioGroup
                  id="chartTypeSelection"
                  horizontal
                  options={[
                    {value: "month", name: "Mese"},
                    {value: "level", name: "Campionato"},
                  ]}
                  currentValue={selectedView}
                  onChange={(value) => {
                    setSelectedView(value);
                  }}
                />
              </div>
              {chartData[selectedView] ?
                <div className="w-full">
                  {selectedView === 'month' ?
                    <NominationPersonaReportTrendChart
                      id="nominationTrendChart"
                      data={chartData.month}
                      categories={scopes.includes('games:temp') ? ALL_STATUS : ALL_STATUS.filter(i => i !== 'TEM')}
                    /> :
                    <NominationPersonaReportLevelChart
                      id="nominationLevelChart"
                      data={chartData.level}
                      categories={scopes.includes('games:temp') ? ALL_STATUS : ALL_STATUS.filter(i => i !== 'TEM')}
                    />
                  }
                </div>
                : <Placeholder height="h-72" classNames="rounded-lg"/>
              }
            </div>
          </div>
          <div>
            <div className="text-lg font-bold border-b border-gray-900 py-1">Elenco designazioni ({data.length})</div>
            <div className="p-1 w-full flex flex-col divide-y divide-gray-300 h-96 overflow-y-scroll mb-10">
              {data.map(i => <div key={i.cod_game}>
                  <NominationCard nom={i} idFipCode={selectedMember.value} isPersonal={isMember}/>
                </div>
              )}
            </div>
          </div>

        </div>
        : null
      }
    </>
  );
}
