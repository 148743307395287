import React, { useContext, useEffect, useState } from 'react';

// Services
import { NotificationsContext } from '@ui-components/Notifications';
import { api } from '@services/apiRequest';
import {useIntl} from "react-intl";

// Data
import useMembers from '@services/hooks/useMembers';
import useCities from "@services/hooks/useCities";

// Components
import RefManList from '@components/RefMan/RefManList/RefManList';
import { MemberSearch } from '@pages/Members/Members.lib';
import { getListItems } from './Relocations.lib';

// Ui
import { PlusIcon } from '@heroicons/react/24/outline';
import Placeholder from '@ui-components/Placeholder';
import { PageHeading } from '@ui-components/Container';
import Button from '@ui-components/Button';
import Alert from '@ui-components/Alert';
import Modal from '@ui-components/Modal';

// Types
import {City} from "@services/types/city";
import {Relocation} from './Relocations.type';
import { AddUpdateRelocationDialog } from '@pages/Planner/Relocations/AddUpdateRelocationDialog';



export function PlannerRelocations() {
  const { push } = useContext(NotificationsContext);
  const intl = useIntl();
  const cities: City[] = useCities();
  const members = useMembers({ onlyActive: true, assigned: true, asObj: false });

  const [relocations, setRelocations] = useState<Relocation[] | undefined>(undefined);
  const [filterMember, setFilterMember] = useState<string>();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteRelocation, setDeleteRelocation] = useState<Relocation | undefined>();
  const [offset, setOffset] = useState<number>(0);
  const [filteredRelocations, setFilteredRelocations] = useState<Relocation[] | []>([]);
  const limit = 10;

  const [updateRelocation, setUpdateRelocation] = useState<Relocation | undefined>(undefined);

  useEffect(() => {
    const fetchData = () => {
      api
        .get('/relocations')
        .then(({ data }) => {
          setRelocations(data.reverse());
        })
        .catch(() => {
          push({ title: 'Errore del server', type: 'error' });
        });
    };

    if (relocations === undefined) {
      fetchData();
    }
  }, [relocations]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (relocations) {
      if (filterMember) {
        setFilteredRelocations(relocations.filter((i) => i.member.member.toLowerCase().match(filterMember.toLowerCase())));
      } else {
        setFilteredRelocations(relocations);
      }
    } else {
      setFilteredRelocations([]);
    }
  }, [relocations, filterMember]);

  const sendDelete = () => {
    if (deleteRelocation) {
      setLoading(true);
      api
        .delete(`/relocations/${deleteRelocation.id}`)
        .then(() => {
          setRelocations((relocations ?? []).filter((i) => i.id !== deleteRelocation.id));
          push({ title: 'Elemento eliminato con successo', type: 'success' });
          setDeleteRelocation(undefined);
        })
        .catch(() => {
          push({ title: 'Errore del server', type: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <PageHeading title="Gestione tesserati fuori sede" historyBackPath='_back' />
      {relocations ? (
        <div className='my-5'>
          <div className='mb-5 flex flex-row justify-between gap-10 items-center'>
            <div className='w-full'>
              <MemberSearch label='Cerca tesserato' searchKey={filterMember} setSearchKey={setFilterMember} />
            </div>
            <div>
              <Button onClick={() => setShowAdd(true)}>
                <PlusIcon className='w-5 h-5 mr-3' /> Aggiungi
              </Button>
            </div>
          </div>
          {filteredRelocations.length > 0 ? (
            <div className='mb-10'>
              <RefManList
                offset={offset}
                setOffset={setOffset}
                limit={limit}
                total={filteredRelocations.length}
                containerId='observer-reports-list'
                items={getListItems(intl, filteredRelocations, setDeleteRelocation, setUpdateRelocation)}
                rowClassNames={['sm:grid', 'sm:grid-cols-8', '!cursor-default']}
                pagination='frontend'
              />
            </div>
          ) : (
            <div className='my-10'>
              <Alert title='Nessun elemento registrato' />
            </div>
          )}
        </div>
      ) : (
        <div className='flex flex-col w-full my-10 gap-2'>
          <Placeholder height='h-12' classNames='rounded-lg' />
          {[...new Array(5)].map((each, i) => <Placeholder key={i} height='h-20' classNames='rounded-lg bg-am-600' />)}
        </div>
      )}
      {(showAdd || updateRelocation) && members && cities ? (
        <AddUpdateRelocationDialog
          setShowAdd={setShowAdd}
          setRelocations={setRelocations}
          members={members}
          updateRelocation={updateRelocation}
          setUpdateRelocation={setUpdateRelocation}
          cities={cities}
        />
      ) : null}
      {deleteRelocation ? (
        <Modal onExit={() => setDeleteRelocation(undefined)}>
          <div className='m-5'>
            <h1 className='font-bold sm:text-xl'>Confermi di voler eliminare l'elemento?</h1>
            <h3 className='text-xs sm:text-sm my-3'>Non sarà possibile annullare l'operazione.</h3>
          </div>
          <div className='w-full flex flex-col sm:flex-row gap-3 justify-between'>
            <Button styleType='white' onClick={() => setDeleteRelocation(undefined)}>
              Annulla
            </Button>
            <Button onClick={sendDelete} submitting={loading}>
              Conferma
            </Button>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
