import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useContext, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {RefreshDataProps} from "@components/RefMan/Quiz/types";

export const useQuizQuestion = ({setRefreshData}: RefreshDataProps) => {

  // hooks
  const intl = useIntl();

  // context
  const {push} = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);

  // handlers
  const callApi = async (fn: Promise<any>, errorMsg: string) => {
    setLoading(true);
    try {
      return await fn;
    } catch (e) {
      push({type: "error", title: intl.formatMessage({id: "error"}), message: errorMsg});
    } finally {
      setLoading(false);
      setRefreshData(prev => !prev);
    }
  }

  const delete_ = async (idQuiz: number, idQuestion: number) =>
    await callApi(
      new Promise(async (resolve) => {
        await api.delete(`/quizzes/${idQuiz}/${idQuestion}`);
        resolve(null);
      }),
      "DELETE_QUIZ_QUESTION",
    );

  return {delete_, loading};
}