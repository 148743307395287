import React, {useContext, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";

// Ui
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import {TestEmailDialogProps} from "../Detail.type";
import Alert from "@ui-components/Alert";
import Input from "@ui-components/Input";

export function TestEmailDialog({onExit, activity}: TestEmailDialogProps) {

  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [personalEmail, setPersonalEmail] = useState<string | undefined>(undefined);

  const isInvitationIncomplete = () => {
    return !activity.invitation_email || !activity.invitation_email.subject || !activity.invitation_email.content
  }

  const sendDelete = () => {
    setLoading(true)
    api.get(`/activities/send-invitation-test/${activity.id}/${personalEmail}`)
      .then(() => {
        push({title: "Mail di test inviata", type: "success"});
        onExit();
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      onExit={onExit}
    >
      <div className="flex flex-col gap-5">
        <h1 className="font-bold sm:text-xl mr-5">Vuoi inviare una mail di test della convocazione?</h1>
        <div>
          {/*@ts-ignore*/}
          <Input
            value={personalEmail}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPersonalEmail(e.target.value);
            }}
            label="Email"
            placeholder="Inserisci un indirizzo email"
            type="email"
            errorMessage="Email non formattata correttamente"
            required
          />
        </div>
      </div>
      {isInvitationIncomplete() ?
        <div className="my-5">
          <Alert type="warning" text="La convocazione non è completa, devi inserire oggetto e corpo del messaggio"/>
        </div> : null
      }
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between mt-5">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button onClick={sendDelete} submitting={loading} disabled={isInvitationIncomplete()}>
          Conferma
        </Button>
      </div>
    </Modal>
  );
}
