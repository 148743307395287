import Counter from "../Counter";

function SmallNotification({counter, classStyle = "ml-2 -mt-4", enableAnimation = false, color = "bg-red-400"}: {
  counter: number,
  classStyle?: string,
  enableAnimation?: boolean,
  color?: string,
}) {
  return (
    counter > 0 ? <div
      key={counter}
      className={`flex font-medium justify-center items-center text-xs text-white text-content-semi-fit p-1 h-4 rounded-full ${color} ${classStyle}`}
    >
      {enableAnimation ? <Counter number={counter} duration={0.5}/> : counter}
    </div> : <></>
  )
}

export default SmallNotification;