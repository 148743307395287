import React, {useContext, useEffect, useState} from "react";

// Services
import AuthenticationService from "@services/AuthenticationService";
import {NotificationsContext} from "@ui-components/Notifications";
import useRoles from "@ui-components/pages/Users/hooks/useRoles";
import {useHistory, useParams} from "react-router-dom";
import useMembers from "@services/hooks/useMembers";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

// Ui
import {PageHeading} from "@ui-components/Container";

// Components

import {
  AddParticipantsDialog,
  AssignInstructorsDialog,
  DeleteActivityDialog,
  DetailMetaData,
  Instructors,
  Participants,
  Invitation,
  Ratings
} from "./Components";

import AddUpdateActivityDialog from "@pages/Activities/AddUpdateActivityDialog";
import {ActivityDetailPlaceHolder} from "@pages/Activities/Activities.lib";

// Type
import {DetailParams} from "@pages/Activities/Detail/Detail.type";
import {Activity, MemberActivityBasic} from "@services/types/activity";
import {Member} from "@services/types/member";

import {LKP_ACTIVITY_TYPES} from "@utils/constants";
import {MemberResult} from "@pages/Activities/Detail/Components/MemberResult";


export function ActivityDetail() {

  const intl = useIntl();
  const roles = useRoles();
  const history = useHistory();
  const scopes = AuthenticationService.getUserScopes();
  const userIdFipCode = AuthenticationService.getUserFipCode();
  const canCreate = scopes.includes('activities:create');
  const viewMode = scopes.includes('activities:personal');


  const membersByUserId = useMembers({onlyActive: true, assigned: true, asObj: false, byUserId: true})
  const instructors: Member[] = useMembers({assigned: false, asObj: false, jobs: ['IST']});
  const {push} = useContext(NotificationsContext);
  const {id_activity} = useParams<DetailParams>();
  const idActivity = Number(id_activity);

  const [activity, setActivity] = useState<Activity>();
  const [memberActivity, setMemberActivity] = useState<MemberActivityBasic>();
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showInstructors, setShowInstructors] = useState<boolean>(false);
  const [addParticipants, setAddParticipants] = useState<boolean>(false);

  useEffect(() => {
    if (activity && viewMode) {
      setMemberActivity(activity.members_activities.filter(i => i.id_fip_code === userIdFipCode)[0])
    }
  }, [activity])

  useEffect(() => {
    const fetchData = () => {
      api.get(`/activities/${idActivity}`)
        .then(({data: activity}) => {
          setActivity(activity);
        })
        .catch((err) => {
          console.log(err)
          history.push('/activities')
          push({title: "Non è stato possibile recuperare i dati dell'attività tecnica", type: "error"});
        });
    };

    if (!activity && id_activity) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [activity]);


  let insertedBy = ''
  if (membersByUserId && roles && activity && activity.id_added_user && activity.id_added_role) {
    insertedBy = `da ${membersByUserId[activity.id_added_user]} (${roles[activity.id_added_role]['description']})`
  }

  return (
    <>
      {activity && roles && (!viewMode || memberActivity) ?
        <>
          <div>
            <PageHeading
              title={<div className="flex flex-col">
                <span className="text-lg sm:text-xl font-bold">{activity.name}</span>
                <span className="text-sm sm:text-md font-normal">{LKP_ACTIVITY_TYPES[activity.activity_type]}</span>
              </div>}
              historyBackPath="_back"
            />
            <DetailMetaData
              intl={intl}
              activity={activity}
              setShowUpdate={setShowUpdate}
              setShowDelete={setShowDelete}
              insertedBy={insertedBy}
              viewMode={viewMode}
            />
            <Instructors
              activity={activity}
              viewMode={viewMode}
              canCreate={canCreate}
              setShowInstructors={setShowInstructors}
            />
            <Invitation
              activity={activity}
              viewMode={viewMode}
              canCreate={canCreate}
              setActivity={setActivity}
              memberActivity={memberActivity}
            />
            {viewMode ?
              memberActivity ?
                <MemberResult
                  activity={activity}
                  memberActivity={memberActivity}
                />
                : null :
              <Participants
                intl={intl}
                activity={activity}
                canCreate={canCreate}
                setActivity={setActivity}
                setAddParticipants={setAddParticipants}
              />
            }
            <Ratings
              intl={intl}
              viewMode={viewMode}
              activity={activity}
              canCreate={canCreate}
              setActivity={setActivity}
              memberActivity={memberActivity}
            />
          </div>
          {showUpdate ?
            <AddUpdateActivityDialog
              onExit={() => {
                setShowUpdate(false)
              }}
              updateActivity={activity}
              refreshActivity={setActivity}
            /> : null
          }
          {activity.id && showDelete ?
            <DeleteActivityDialog
              onExit={() => setShowDelete(false)}
              id={activity.id}
            /> : null
          }
          {showInstructors ?
            <AssignInstructorsDialog
              activity={activity}
              instructors={instructors}
              setActivity={setActivity}
              onExit={() => setShowInstructors(false)}
            /> : null
          }
          {addParticipants ?
            <AddParticipantsDialog
              activity={activity}
              setActivity={setActivity}
              onExit={() => setAddParticipants(false)}
            /> : null
          }
        </>
        : <ActivityDetailPlaceHolder/>
      }
    </>
  )
}
