import React, {useState} from "react";

import AuthenticationService from "../../../services/AuthenticationService";
import { api } from "@services/apiRequest";
import { useIntl } from "react-intl";
import _ from "lodash";

import { getColumns, OPTIONS, getLocalization } from "./Params";
import MaterialTable from "@material-table/core";
import { tableIcons } from "../../TableIcons";
import TableStyle from "../../TableStyle";


function UsersTable({ users, setUsers, roles, members, push }) {

  const lkpMembers = members.filter(i => i.id_user)
    .reduce((obj, i) => _.set(obj, [i.id_user], i.id_fip_code), {})

  const intl = useIntl();
  const [columns] = useState(getColumns(intl, roles, lkpMembers))
  const [localization] = useState(getLocalization(intl))

  const scopes = AuthenticationService.getUserScopes();

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      if (isEqual) {
        resolve();
      } else {
        api
          .post(`/users/${oldData.id}`, newData)
          .then(() => {
            const { [oldData.id]: tmp, ...rest } = users
            setUsers({ ...rest, [newData.id]: newData })
            push({
              title: intl.formatMessage({
                id: "user_updated_successfully",
              }),
              type: "success",
            });
            resolve();
          })
          .catch((err) => {
            console.error("Error during user update!", err);
            push({
              title: intl.formatMessage({
                id: "server_error",
              }),
              type: "error",
            });
            reject();
          });
        }
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      api
        .delete(`/users/${encodeURIComponent(newData.id)}`)
        .then(() => {
          const { [newData.id]: tmp, ...rest } = users;
          setUsers({ ...rest });
          push({
            title: intl.formatMessage({
              id: "user_deleted_successfully",
            }),
            type: "success",
          });
          resolve();
        })
        .catch((err) => {
          console.error("Error during user delete!", err);
          push({
            title: intl.formatMessage({
              id: "server_error",
            }),
            type: "error",
          });
          reject();
        });
    });

  return (
    <TableStyle>
      <MaterialTable
        columns={columns}
        data={users ? Object.values(users) : []}
        icons={tableIcons}
        isLoading={users === undefined}
        options={OPTIONS}
        localization={localization}
        editable={{
          ...(scopes.indexOf("user:update") >= 0
            ? { onRowUpdate: _onRowUpdate }
            : {}),
          ...(scopes.indexOf("user:delete") >= 0
            ? { onRowDelete: _onRowDelete }
            : {}),
        }}
      />
    </TableStyle>
  );
}

export default React.memo(UsersTable, (props, nextProps) => {
  // Render component only when users change
  return _.isEqual(props.users, nextProps.users);
});
