import React, {useEffect, useState} from "react";

// Services
import {TODAY} from "@utils/constants";
import {useIntl} from "react-intl";

// Components
import {getMemberAttendance, MemberAnswerCard} from "@pages/Activities/Detail/Components/DetailComponents.lib";

// Ui
import {dateFormat} from "@components/RefMan/NominationCard/NominationCard.lib";
import {ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {ArrowLongRightIcon} from "@heroicons/react/24/solid";
import Placeholder from "@ui-components/Placeholder";
import Alert from "@ui-components/Alert";


const MemberDetailActivities = ({activities, history}) => {
  const intl = useIntl();
  const [showSections, setShowSections] = useState({future: true, passate: false});
  const [data, setData] = useState({future: [], passate: []});


  useEffect(() => {
    if (activities) {
      setData({
        future: activities.filter(i => i.activity.dat_activity >= TODAY).sort((a, b) => a.activity.dat_activity.localeCompare(b.activity.dat_activity)),
        passate: activities.filter(i => i.activity.dat_activity < TODAY).sort((a, b) => a.activity.dat_activity.localeCompare(b.activity.dat_activity)).reverse()
      })
    }
  }, [activities])


  return (
    <div className="flex flex-col w-full gap-5">
      <div className="w-full flex flex-row justify-between items-center pb-1 border-b-2 border-gray-900">
        <p className="font-bold text-2xl">Attività tecnica</p>
        <p
          className="cursor-pointer hover:underline hover:font-bold"
          onClick={() => history.push({pathname: '/activities'})}
        >
          Vai al dettaglio <ArrowLongRightIcon className="inline-block h-4 w-4"/>
        </p>
      </div>
      {activities ?
        <>
          {activities.length > 0 ?
            <div className="w-full flex flex-col gap-2 items-center">
              {['future', 'passate'].map(i => (
                <div className="w-full flex flex-col py-3" key={i}>
                  <div
                    className="flex flex-row cursor-pointer text-gray-900 items-center gap-2"
                    onClick={() => {
                      setShowSections({...showSections, [i]: !showSections[i]})
                    }}
                  >
                    {showSections[i]
                      ? <ChevronDownIcon className="h-5 w-5"/>
                      : <ChevronRightIcon className="h-5 w-5"/>
                    }
                    <span className="text-md font-bold">
                      {i === 'future' ? 'Prossimi incontri' : 'Incontri passati'} ({data[i].length})
                    </span>
                  </div>
                  {showSections[i] ?
                    data[i].length ?
                      <div className="p-1 w-full flex flex-col divide-y divide-gray-300">
                        {data[i].map(d => (
                          <div key={d.activity.id}
                               className="py-2 flex flex-col sm:flex-row sm:justify-between text-am-600 gap-2">
                            <div className="w-full sm:w-2/3 flex flex-col text-sm">
                              <span className="capitalize">
                                <b>{intl.formatDate(d.activity.dat_activity, dateFormat)}</b>, {d.activity.time_start} - {d.activity.time_end}
                              </span>
                              <span className="text-xs">{d.activity.name}</span>
                            </div>
                            <div className="w-full sm:w-1/3">
                              {d.attendance ?
                                <div>
                                  {getMemberAttendance(d.attendance)}
                                </div> :
                                <MemberAnswerCard
                                  isTeacher={d.activity_role === 'TEACHER'}
                                  originalAnswer={d.flg_accepted}
                                  originalReason={undefined}
                                  isHome
                                />
                              }
                            </div>
                          </div>
                        ))}
                      </div> :
                      <div className="my-3">
                        <Alert title="Nessuna attività tecnica" slim />
                      </div>
                    : null
                  }
                </div>
              ))}

            </div>
            :
            <div>
              <Alert title="Nessuna attività tecnica presente"/>
            </div>
          }
        </>
        :
        <div className="flex flex-col w-full mb-4 gap-2">
          <div className="flex flex-row gap-10">
            <Placeholder height="h-16" classNames="rounded-lg w-1/3"/>
            <Placeholder height="h-16" classNames="rounded-lg w-1/3"/>
            <Placeholder height="h-16" classNames="rounded-lg w-1/3"/>
          </div>
          <Placeholder height="h-5" classNames="rounded-lg"/>
          <Placeholder height="h-36" classNames="rounded-lg"/>
        </div>
      }
    </div>
  )
}

export default MemberDetailActivities