import React, {useEffect, useState, useContext} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";

import {useQuizResult} from "@components/RefMan/Quiz/hooks/useQuizResult";

import {
  QuizResultDetailProps,
  QuizCommonResultsTableData,
  QuizResultKpi,
} from "@components/RefMan/Quiz/types";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {GameHomeKpi} from "@pages/Home/Sections/Sections.lib";
import Placeholder from "@ui-components/Placeholder";
import {PageHeading} from "@ui-components/Container";
import Alert from "@ui-components/Alert";


const baseStyle = 'flex justify-center items-center text-center w-12 min-w-fit rounded-lg border border-am-700 py-1 px-2 uppercase text-xxs font-semibold'


export function QuizPersonalResults() {

  const {id_quiz} = useParams<QuizResultDetailProps>();
  const idQuiz = Number(id_quiz);

  const [data, setData] = useState<QuizCommonResultsTableData | undefined>(undefined);
  const [kpi, setKpi] = useState<QuizResultKpi | undefined>(undefined);

  const questions = kpi ? kpi.quiz.associations
    .sort((a, b) => a.order - b.order)
    .map((a, index) => ({...a, order: index + 1})) : null

  const {getDetail, getSessionKpi} = useQuizResult();
  const {push} = useContext(NotificationsContext);
  const intl = useIntl();

  useEffect(() => {
    getDetail(idQuiz)
      .then((res) => setData(res))
      .catch(() => push("Qualcosa è andato storto"))

    getSessionKpi(idQuiz)
      .then((res) => setKpi(res))
      .catch(() => push("Qualcosa è andato storto"))
  }, []);

  return <>
    <div className='flex flex-row justify-between'>
      <PageHeading
        title={<div className="flex flex-col">
          <span className="text-lg sm:text-xl font-bold">{kpi?.quiz.des_title}</span>
        </div>}
        historyBackPath="_back"
      />
    </div>
    {kpi ?
      <div className="mx-auto mt-4 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
          <GameHomeKpi
            intl={intl}
            title="Domande"
            color={'text-am-700'}
            value={kpi.num_correct_answers + kpi.num_wrong_answers}
          />
          <GameHomeKpi
            intl={intl}
            title="Risposte corrette"
            color={'text-green-700'}
            denominator={(kpi.num_correct_answers + kpi.num_wrong_answers)}
            value={kpi.num_correct_answers}
          />
          <GameHomeKpi
            intl={intl}
            title="Rrrori"
            color={'text-red-700'}
            denominator={(kpi.num_correct_answers + kpi.num_wrong_answers)}
            value={kpi.num_wrong_answers}
          />
          <GameHomeKpi
            intl={intl}
            title="Errori degli altri utenti"
            denominator={(kpi.common_avg_num_wrong_answers + kpi.common_avg_num_correct_answers)}
            value={kpi.common_avg_num_wrong_answers}
          />
        </div>
      </div>
      : <div className="flex flex-row gap-5">
        <Placeholder height="h-32" classNames="rounded-lg w-1/5"/>
        <Placeholder height="h-32" classNames="rounded-lg w-2/5"/>
        <Placeholder height="h-32" classNames="rounded-lg w-2/5"/>
      </div>
    }
    {data && kpi && questions ?
      kpi.quiz.associations.length === 0 ?
        <div className="my-10">
          <Alert title="Non ci sono domande in questo quiz!"/>
        </div> :
        <div className="my-8">
          <dl className="divide-y border-gray-900">
            {questions.map(q => (
              <div className="flex flex-col w-full py-4">
                <span className="text-base leading-7">
                  <b className="font-bold">{q.order}.</b> {q.question.des_question}
                </span>
                <div className="flex flex-row gap-2 mt-2 overflow-x-auto mr-3">
                  {q.question.des_answers.map((ans) => {
                    const isRealCorrect = q.question.des_correct_answers.includes(ans)
                    const isUserAnswer = ans === data.answers
                      .filter(a => a.id_question === q.question.id)[0].des_answers[0]
                    return (
                      <div key={ans} className={`${baseStyle} ${isRealCorrect ? 'bg-am-600 text-white' : ''}`}>
                        {ans}
                        {isUserAnswer
                          ? isRealCorrect
                            ? <CheckCircleIcon className="w-4 inline-block ml-2 text-green-300"/>
                            : <XCircleIcon className="w-4 inline-block ml-2 text-red-600"/> : null
                        }
                      </div>
                    )
                  })}
                </div>
                {/*todo: aggiungere riferimento regolamentare della risposta*/}
              </div>
            ))}
          </dl>
        </div>

      : <Placeholder height="h-96" classNames="rounded-lg w-1/5"/>
    }
  </>

}
