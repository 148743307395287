import React from "react";
import {QuestionItemType} from "@utils/questionnaires/type/questionnaire.type";
import {ResultsType} from "@components/RefMan/Reports/ReportsForm/ReportsForm.type";

/**
 * @function onTextChange
 *
 * @param {React.ChangeEvent<HTMLTextAreaElement>} e Input text on change event
 * @param {QuestionItemType} data Current report data
 * @param {ResultsType} results Current results data
 * @param {React.Dispatch<React.SetStateAction<ResultsType>>} setResults Update results data
 * @param {string} sectionId Id of section
 */
export const onTextChange = (
  e: React.ChangeEvent<HTMLTextAreaElement>,
  data: QuestionItemType,
  results: ResultsType | undefined,
  setResults: React.Dispatch<React.SetStateAction<ResultsType | undefined>>,
  sectionId: number
) => {

  const resultsCopy = {...results};
  resultsCopy[data.id] = {
    id: data.id,
    idSection: sectionId,
    value: e.target.value,
    isRequired: (data.isRequired === undefined || data.isRequired),
    isPrivate: (data.isPrivate !== undefined && data.isPrivate),
    tag: data.tag,
    type: "text-input"
  }
  setResults({
    ...results,
    ...resultsCopy
  });
}