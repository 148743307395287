import React, {useContext, useEffect, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";


import {getPayload} from "@pages/Nominations/Analytics/Analytics.lib";
import Placeholder from "@ui-components/Placeholder";
import Alert from "@ui-components/Alert";

import {CounterData, EmploymentProps, TypeCount} from "@pages/Nominations/Analytics/Analytics.type";
import {getBgColor, getFilteredTeams} from "@pages/Reports/Reports.lib";
import RadioGroup from "@ui-components/RadioGroup";
import useTeams from "@services/hooks/useTeams";
import {Team} from "@services/types/team";
import {LKP_ROLES} from "@utils/constants";

export const AnalyticsCounters = (
  {
    group,
    selectedCategories,
    selectedProvinces,
    selectedLevels,
    startDate,
    endDate
  }: EmploymentProps
) => {

  const {push} = useContext(NotificationsContext);
  const [data, setData] = useState<CounterData[] | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false);
  const [typeCount, setTypeCount] = useState<TypeCount>('members');
  const teams: Team[] = useTeams();
  const filteredTeams = typeCount === 'teams' && data ? getFilteredTeams(data, teams) : teams
  const roles = group === 'ref' ? ['ref1', 'ref2', 'unknown'] : ['sp', 'crono', 'a24s', 'unknown']

  useEffect(() => {
    setLoading(true)
    const payload = getPayload(
      group, selectedCategories, selectedProvinces, selectedLevels, startDate, endDate, []
    )
    api.post(`/analytics/count-${typeCount}`, payload)
      .then(({data}) => setData(data))
      .catch(() => {
        setData([])
        push({title: 'Errore del server', type: 'error'})
      })
      .finally(() => setLoading(false))

  }, [group, selectedCategories, selectedProvinces, selectedLevels, startDate, endDate, typeCount]);

  const columnHeader = (key: string | number, title: string) => (
    <th key={key} scope="col"
        className="whitespace-nowrap h-16 text-xs font-semibold px-2 align-bottom pb-4">
      <div className={`-rotate-90 w-6 ${title.length > 21 ? 'text-xxs' : ''}`}>{title}</div>
    </th>
  )

  const columnBody = (colorValue: number, key: number | string, value: number | string) => (
    <td
      className={`p-1 text-sm bg-am-700 ${getBgColor(colorValue)}`}
      key={key}
    >
      <div className="flex flex-wrap justify-center gap-1 items-center">
        {value}
      </div>
    </td>
  )

  return (
    <div>
      <div className="-mt-5 mb-5 flex flex-col items-center">
        <RadioGroup
          id="type"
          horizontal
          options={[
            {value: "members", name: "Colleghi"},
            {value: "teams", name: "Squadre"},
            {value: "roles", name: "Ruoli"},
          ]}
          currentValue={typeCount}
          onChange={(value: 'members' | 'teams' | 'roles') => {
            setTypeCount(value);
          }}
        />
      </div>
      {loading || !data ? <Placeholder height="h-80" classNames="rounded-lg"/> :
        data.length ?
          <div className="overflow-x-auto flex flex-col h-70vh my-5">
            <div className="inline-block min-w-full pb-2 align-middle flex-grow overflow-auto">
              <table className="">
                <thead className="sticky top-0 z-30">
                <tr className="divide-x divide-gray-100 bg-white border-b">
                  <th scope="col" className="sticky bg-white left-0 py-3.5 pl-4 sm:pl-6 z-10">
                  </th>
                  <th scope="col"
                      className={`bg-gray-100 whitespace-nowrap ${typeCount === 'roles' ? 'h-28' : 'h-40'} text-xs font-semibold px-2 align-bottom pb-4`}>
                    <div className="-rotate-90 w-6">TOTALE</div>
                  </th>
                  {typeCount === 'members'
                    ? data.map(i => columnHeader(i.member.id_fip_code, i.member.tag))
                    : typeCount === 'teams'
                      ? filteredTeams.map(i => columnHeader(i.id, `${i.cod_level} | ${i.team_alias}`))
                      : roles.map(i => columnHeader(i, LKP_ROLES[i.toUpperCase()]))
                  }
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {data.map((i) => (
                  <tr key={i.member.id_fip_code} className="divide-x divide-gray-100">
                    <td className="sticky left-0 z-20 bg-white text-sm font-light text-gray-900">
                      <div>
                        <div className="whitespace-nowrap pl-4 pr-3 sm:pl-6">
                        <span className="block font-bold">
                          {i.member.tag}
                        </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-sm bg-gray-100 font-bold">
                      <div className="text-center">
                        {typeCount === 'members'
                          ? i.count[i.member.id_fip_code]
                          : Object.values(i.count).reduce((acc, i) => acc + i, 0)
                        }
                      </div>
                    </td>
                    {typeCount === 'members'
                      ? data.map(j =>
                        columnBody(
                          j.member.id_fip_code === i.member.id_fip_code ? -1 : i.count[j.member.id_fip_code],
                          j.member.id_fip_code,
                          j.member.id_fip_code === i.member.id_fip_code ? i.count[-1] ?? '' : i.count[j.member.id_fip_code] ?? ''
                        )
                      )
                      : typeCount === 'teams'
                        ? filteredTeams.map(j => columnBody(i.count[j.id], j.id, i.count[j.id] ?? ''))
                        : roles.map(j => columnBody(i.count[j], j, i.count[j] ?? ''))
                    }
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
          :
          <Alert title="Nessun dato presente"/>
      }
    </div>
  )
}