import React, {useEffect, useState} from "react";

import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

// Components
import GameTrendChart from "@pages/Home/Sections/gameTrendChart";
import LevelBarChart from "@pages/Home/Sections/levelBarChart";
import {GamesLevelFilter} from "@pages/Games/Games.lib";
import {GameHomeKpi} from "./Sections.lib";

// Ui
import Placeholder from "@ui-components/Placeholder";

// Types
import {GamesSectionPropsType, GameKpiType} from "@pages/Home/Home.type";


export function GamesSection(
  {
    push,
    group,
    levels,
    selectedLevels,
    setSelectedLevels
  }: GamesSectionPropsType) {

  const intl = useIntl();
  const [gamesData, setGamesData] = useState<GameKpiType | undefined>(undefined);

  useEffect(() => {
    const _selectedLevels = selectedLevels.length ? selectedLevels : levels.map(i => ({value: i.cod_level, label: ''}))
    const searchParams = new URLSearchParams({
      cod_group: group,
      levels: JSON.stringify(_selectedLevels.map(i => i.value))
    });
    api.get(`/kpi/home-games?${searchParams}`)
      .then(({data}) => {
        setGamesData(data)
      })
      .catch(() => push({type: "error", title: "Errore durante il caricamento"}))

  }, [group, selectedLevels]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="my-5">
      <div className="flex flex-col sm:flex-row justify-between sm:gap-6">
        <div className="w-full">
          <GamesLevelFilter
            levels={levels}
            selectedLevels={selectedLevels}
            setSelectedLevels={setSelectedLevels}
          />
        </div>
      </div>
      {gamesData ?
        <div className="flex flex-col sm:flex-row justify-between gap-6 items-center mt-4 sm:mt-0">
          <div className="w-full sm:w-1/2 flex flex-col gap-4">
            <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-5 justify-between sm:my-4">
              <GameHomeKpi
                intl={intl}
                title="Gare a calendario"
                value={gamesData.kpis.num_games_calendar}
              />
              <GameHomeKpi
                intl={intl}
                color="text-am-600"
                title="Gare effettuate"
                value={gamesData.kpis.num_games_nominated + gamesData.kpis.num_games_empty}
                denominator={gamesData.kpis.num_games_calendar}
              />
              <GameHomeKpi
                intl={intl}
                color="text-green-700"
                title="Di cui designate"
                value={gamesData.kpis.num_games_nominated}
                denominator={gamesData.kpis.num_games_nominated + gamesData.kpis.num_games_empty}
              />
              {group === 'ref' && gamesData.kpis.num_games_single ?
                <GameHomeKpi
                  intl={intl}
                  color="text-yellow-600"
                  title="Di cui in singolo"
                  value={gamesData.kpis.num_games_single}
                  denominator={gamesData.kpis.num_games_nominated + gamesData.kpis.num_games_empty}
                /> : null
              }
              {gamesData.kpis.num_games_empty ?
                <GameHomeKpi
                  intl={intl}
                  color="text-red-700"
                  title="Di cui scoperte"
                  value={gamesData.kpis.num_games_empty}
                  denominator={gamesData.kpis.num_games_nominated + gamesData.kpis.num_games_empty}
                /> : null
              }
            </div>
            <GameTrendChart
              id="home-game-trend-chart"
              data={gamesData.trend}
              categories={group === 'ref' ? ['double', 'single', 'empty'] : ['triple', 'double', 'single', 'empty']}
            />
          </div>
          <div className="w-full sm:w-1/2">
            <LevelBarChart
              id="home-game-level-bar-chart"
              data={gamesData.by_level.reverse()}
            />
          </div>
        </div>
        : <div className="flex flex-row gap-5">
          <Placeholder height="h-32" classNames="rounded-lg w-1/5"/>
          <Placeholder height="h-32" classNames="rounded-lg w-2/5"/>
          <Placeholder height="h-32" classNames="rounded-lg w-2/5"/>
        </div>
      }
    </div>
  );
}
