import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router";
import { AuthenticationService } from "@services/AuthenticationService";

import { LOGIN_URL } from "@services/AuthenticationService";

const PrivateRoute = ({
  component: Component,
  scopes: pageScopes,
  ...rest
}) => {
  const { pathname } = useLocation();
  const isAuthenticated = AuthenticationService.isUserAuthenticated();

  if (!isAuthenticated) {
    return (
      <Redirect push to={{ pathname: LOGIN_URL, state: { toPath: pathname } }} />
    );
  }

  const scopes = AuthenticationService.getUserScopes();

  if (pageScopes && !pageScopes.some((r) => scopes.indexOf(r) >= 0)) {
    return (
      <Redirect push to={{ pathname: "/home", state: { from: pathname } }} />
    );
  }

  return <Route {...rest} component={Component} />;
};

export default PrivateRoute;
