import React, { useEffect } from 'react';

// Components
import { getListItems } from './ObserverReportsList.lib';
import RefManList from '../../RefManList/RefManList';

// Types
import { type ObserverReportsListType } from './ObserverReportsList.type';

const ObserverReportsList = ({
  setStepId,
  setReportData,
  role,
  reports,
  statusList,
  offset,
  setOffset,
  limit,
  setLimit,
}: ObserverReportsListType): React.JSX.Element => {
  useEffect(() => {
    setLimit(5);
  }, []);

  return (
    <div className='observerreports__container pt-4'>
      <div className='mb-10'>
        <RefManList
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          total={reports && reports.total ? reports.total : 0}
          containerId='observer-reports-list'
          items={getListItems(reports, setStepId, setReportData, role, statusList)}
          rowClassNames={['sm:grid', 'sm:grid-cols-5', 'sm:gap-3', '!cursor-default']}
          pagination='backend'
        />
      </div>
    </div>
  );
};

export default ObserverReportsList;
