import React, {useCallback, useState} from "react";

// Services
import apiRequest from "../../../services/apiRequest";

import Input from "../../../ui-components/Input";
import Modal from "../../../ui-components/Modal";
import Button from "../../../ui-components/Button";

function AddCategoryDialog({group, open, toggleOpen, dataContext, push}) {
  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState({
    cod_group: group.toUpperCase(),
    cod_category: "",
    des_category: "",
    cod_sub_category: "",
    des_sub_category: "",
    sub_cat_order: 0
  });

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        let missing = false
        Object.values(row).forEach(v => {
          if (!v) {
            missing = true
          }
        })

        if (missing) {
          push({
            title: "Dati mancanti",
            type: "error",
          });
          return;
        }

        setLoading(true);
        const newRow = await apiRequest.post("/entry/categories", row);
        dataContext.setCategories({...dataContext.categories, [newRow['id']]: newRow});
        toggleOpen();
        push({
          title: "Elemento aggiunto correttamente",
          type: "success",
        });
      } catch (error) {
        console.log(error)
        push({
          title: "Errore imprevisto",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [row] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div>
      <Modal opened={open} onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            Aggiungi nuova categoria
          </h3>
        </div>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div className="flex flex-col gap-1">
            <div className="flex flex-col sm:flex-row gap-3">
              <div className="flex flex-col gap-2 w-full sm:w-1/3">
                <h3>Codice gruppo</h3>
                <Input
                  value={row.cod_category}
                  onChange={(e) => {
                    setRow({...row, cod_category: e.target.value})
                  }}
                  errorMessage="Inserisci testo"
                  required
                />
              </div>
              <div className="flex flex-col gap-2 w-full sm:w-2/3">
                <h3>Descrizione gruppo</h3>
                <Input
                  value={row.des_category}
                  onChange={(e) => {
                    setRow({...row, des_category: e.target.value})
                  }}
                  errorMessage="Inserisci testo"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-3">
              <div className="flex flex-col gap-2 w-full sm:w-1/3">
                <h3>Codice categoria</h3>
                <Input
                  value={row.cod_sub_category}
                  onChange={(e) => {
                    setRow({...row, cod_sub_category: e.target.value})
                  }}
                  errorMessage="Inserisci testo"
                  required
                />
              </div>
              <div className="flex flex-col gap-2 w-full sm:w-2/3">
                <h3>Descrizione categoria</h3>
                <Input
                  value={row.des_sub_category}
                  onChange={(e) => {
                    setRow({...row, des_sub_category: e.target.value})
                  }}
                  errorMessage="Inserisci testo"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-3">
              <div className="flex flex-col gap-2 w-full sm:w-1/3">
                <h3>Ordine</h3>
                <Input
                  value={row.sub_cat_order}
                  onChange={(e) => {
                    setRow({...row, sub_cat_order: parseFloat(e.target.value)})
                  }}
                  type="number"
                  numberOptions={{
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex items-end justify-end">
            <Button submitting={loading} type="submit">
              Aggiungi
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddCategoryDialog;
