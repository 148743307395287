import {LKP_ROLES, REPORTS_STATUS} from "@utils/constants";
import React, {useEffect, useState} from "react";
import {ObserverReportsItemType} from "./ObserverReportsItem.type";
import {EvaluationsResultsDataType} from "@components/RefMan/Reports/ReportsMatch/ObserverReportsMatch.type";
import {getButtons} from "@components/RefMan/Reports/ReportsMatch/ObserverReportsItem/ObserverReportsItem.lib";

const ObserverReportsItem = ({
                               nomination,
                               evaluationResultsData,
                               setMemberData,
                               setStepId,
                              idObserver,
                              role,
                             }: ObserverReportsItemType) => {
  const [results, setResults] = useState<EvaluationsResultsDataType>()

  if (nomination.id_fip_code === idObserver) {
    return <></>
  }
  /**
   * Evaluation results data handler
   */
  useEffect(() => {
    const results: EvaluationsResultsDataType | undefined = evaluationResultsData.find((evaluationResult: EvaluationsResultsDataType) =>
      (evaluationResult.id_member === nomination.id_fip_code))
    if (results) {
      setResults(results)
    }
  }, [evaluationResultsData])

  const handleFillFormClick = () => {
    if (!results?.status || (results?.status && results?.status.toLowerCase() !== "approved")) {
      setMemberData(nomination);
      setStepId(2);
    }
  }

  const handleReadFormClick = () => {
    if (results?.status && results?.status.toLowerCase() === "approved") {
      setMemberData(nomination);
      setStepId(3);
    }
  }

  const handleReopenFormClick = () => {
    if (results?.status && results?.status.toLowerCase() === "approved") {
      setMemberData(nomination);
      setStepId(2);
    }
  }

  return <div className="observerreportsmatch__member border p-4 md:p-6 rounded-xl overflow-hidden shadow-xl mb-10">
    <div
      className="observerreportsmatch__member-header border-b border-gray-400 pb-1 mb-4 uppercase text-center">
      Rapporto di <strong>{nomination.member.member}</strong>
    </div>
    <div className="observerreportsmatch__member-body mt-4 text-left">
      <strong>Ruolo:</strong> {LKP_ROLES[nomination.role]}
    </div>
    <div className="observerreportsmatch__member-body mt-2 text-left">
      <strong>Stato:</strong> {results?.status ? REPORTS_STATUS[results.status.toLowerCase()] : REPORTS_STATUS["todo"]}
    </div>
    <div className="observerreportsmatch__member-body mt-5 text-center">
      {getButtons(role, results, handleReadFormClick, handleFillFormClick, handleReopenFormClick)}
    </div>
  </div>
}

export default ObserverReportsItem;