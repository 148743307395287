import {useContext, useEffect} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "../DataContext/DataContext";
import apiRequest from "../apiRequest";
import AuthenticationService from "@services/AuthenticationService";

const useEvaluations = ({idFipCode}) => {

  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  const scopes = AuthenticationService.getUserScopes();
  const _idFipCode = idFipCode || AuthenticationService.getUserFipCode();
  const countToRead = scopes.includes('reports:personal');

  useEffect(() => {
    let closed = false;
    const fetchData = async () => {
      try {
        const data = await apiRequest.get(`/evaluations?exclude_result=1&${scopes.includes('reports:editor') ? 'id_observer' : 'id_member'}=${_idFipCode}`);
        if (!closed) {
          dataContext.setEvaluations({
            idFipCode: _idFipCode,
            toRead: countToRead ? data.filter(i => !i.flg_read).length : 0,
            data: data
          });
        }
      } catch (error) {
        console.log(error)
        dataContext.setEvaluations({idFipCode: _idFipCode, data: false, toRead: 0});
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if ((countToRead || idFipCode) && _idFipCode && (!dataContext.evaluations.data === null || dataContext.evaluations.idFipCode !== _idFipCode)) {
      fetchData();
    }

    return () => {
      closed = true;
    };
  }, [_idFipCode, dataContext.evaluations]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (!dataContext.evaluations.data || dataContext.evaluations.idFipCode !== _idFipCode) {
    return undefined
  }
  return dataContext.evaluations.data;

};

export default useEvaluations;
