import React from "react";
import AuthenticationService from "@services/AuthenticationService";
import {QuizManagement} from "@components/RefMan/Quiz/QuizManagement";
import {QuizPersonalResults} from "@components/RefMan/Quiz/QuizPersonalResult";

export function QuizDetail() {

  const scopes = AuthenticationService.getUserScopes();
  const viewMode: 'creator' | 'reader' = scopes.includes('quizzes:create') ? 'creator' : 'reader';


  return (
    <>
      {viewMode === 'creator'
        ? <QuizManagement />
        : <QuizPersonalResults />
      }
    </>
  );
}
