import React, {useCallback, useContext, useState} from "react";

// Services
import {api} from "@services/apiRequest";
import {NotificationsContext} from "../../Notifications";

// UI
import Modal from "../../Modal";
import Button from "../../Button";
import Select from "../../Select";
import InputMultiLine from "../../InputMultiLine";


function EditDialog({toggleOpen, ticket, setTicketList}) {

  const [loading, setLoading] = useState(false);
  const {push} = useContext(NotificationsContext);
  const [data, setData] = useState({
    status: ticket.status,
    comment: ticket.comment ?? ''
  });

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        setLoading(true);
        // eslint-disable-next-line
        api
          .put(`/tickets/${ticket.id}`, data)
          .then(({data: newTicket}) => {
            setTicketList((prev) => prev.map(i => i.id === ticket.id ? newTicket : i))
            toggleOpen();
            push({title: "Dati inviati correttamente", type: "success"})
          })
          .catch((error) => {
            console.log(error)
            push({title: "Errore imprevisto", type: "error"})
          })
          .finally(() => setLoading(false))

      } catch (error) {
        console.log(error)
        push({title: "Errore imprevisto", type: "error"});
      }
    },
    [data] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Modal onExit={toggleOpen}>
      <h1 className="font-bold text-xl">Gestione ticket</h1>
      <h2 className="text-sm my-3 font-medium">
        {ticket.subject}
      </h2>
      <Select
        options={[
          {value: 'OPEN', name: 'Aperto'},
          {value: 'WIP', name: 'In lavorazione'},
          {value: 'CLOSED', name: 'Chiuso'},
          {value: 'STUCK', name: 'Sospeso'},
        ]}
        value={data.status}
        label="Stato"
        onChange={(e) => setData({...data, status: e.target.value})}
      />
      <div className="mt-3">
        <InputMultiLine
          rows={5}
          value={data.comment}
          onChange={(e) => {
            setData({...data, comment: e.target.value});
          }}
          label="Commento"
          type="text"
          errorMessage="Inserisci commento"
          required
        />
      </div>
      <div className="mt-5 flex flex-row justify-between">
        <Button styleType="white" onClick={toggleOpen}>
          Annulla
        </Button>
        <Button onClick={_onSubmit} submitting={loading} disabled={data.status === ticket.status && !data.comment}>
          Salva
        </Button>
      </div>
    </Modal>
  );
}

export default EditDialog;
