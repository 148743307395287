import React, {useCallback, useState} from "react";

// Services
import apiRequest from "../../../services/apiRequest";
import moment from "moment/moment";

import Select from "@ui-components/Select";
import Input from "../../../ui-components/Input";
import Modal from "../../../ui-components/Modal";
import Button from "../../../ui-components/Button";
import DatePicker from "@ui-components/DatePicker";
import useCities from "@services/hooks/useCities";

function AddExternalMemberDialog({group, toggleOpen, dataContext, categories, push}) {

  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState({
    cod_group: group.toUpperCase(),
    id_fip_code: undefined,
    id_category: undefined,
    id_city: undefined,
    dat_born: undefined,
    lastname: "",
    firstname: "",
    phone: "",
    mail: ""
  });

  const cities = useCities();
  const optionsCities = (cities || [])
    .sort((a, b) => a.city.localeCompare(b.city))
    .map(i => ({value: i.id, name: `${i.city} (${i.cod_province})`}))

  const optionsCategories = Object.values(categories)
    .sort((a, b) => a.sub_cat_order - b.sub_cat_order)
    .map(i => ({value: i.id, name: `${i.des_category} - ${i.des_sub_category}`}))

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        let missing = false
        Object.values(row).forEach(v => {
          if (!v) {
            missing = true
          }
        })

        if (missing) {
          push({
            title: "Dati mancanti",
            type: "error",
          });
          return;
        }

        setLoading(true);
        const payload = {
          ...row,
          flg_external: true,
          flg_in_fol: false,
          flg_scrape: true,
          member: `${row.lastname} ${row.firstname}`,
          tag: `${row.lastname} ${row.firstname.slice(0, 1)}.`,
          status_membership: 'RINNOVATO'
        }
        const res = await apiRequest.post("/members", payload);
        dataContext.setMembers({...dataContext.members, [res['id_fip_code']]: res})
        toggleOpen();
        push({
          title: "Tesserato aggiunto correttamente",
          type: "success",
        });
      } catch (error) {
        console.log(error)
        push({
          title: "Errore imprevisto",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [row] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div>
      <Modal opened={true} onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            Aggiungi tesserato esterno
          </h3>
        </div>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div className="flex flex-col gap-1">
            <div className="flex flex-col sm:flex-row gap-3">
              <div className="w-full sm:w-1/2">
                <Input
                  label="Cognome"
                  value={row.lastname}
                  onChange={(e) => {
                    setRow({...row, lastname: e.target.value})
                  }}
                  errorMessage="Inserisci testo"
                  required
                />
              </div>
              <div className="w-full sm:w-1/2">
                <Input
                  label="Nome"
                  value={row.firstname}
                  onChange={(e) => {
                    setRow({...row, firstname: e.target.value})
                  }}
                  errorMessage="Inserisci testo"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-3">
              <div className="w-full sm:w-1/2">
                <Input
                  label="Tessera"
                  value={row.id_fip_code}
                  onChange={(e) => {
                    setRow({...row, id_fip_code: e.target.value})
                  }}
                  type="number"
                  numberOptions={{
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }}
                  required
                />
              </div>
              <div className="w-full sm:w-1/2">
                {/*@ts-ignore*/}
                <DatePicker
                  value={row.dat_born ? new Date(row.dat_born) : undefined}
                  onChange={(i) => setRow({...row, dat_born: moment(i).format('YYYY-MM-DD')})}
                  label='Data di nascita'
                  placeholder="Seleziona data"
                  minDate={null}
                  // maxDate={new Date(END_SEASON)}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-3">
              <div className="w-full sm:w-1/3">
                <Input
                  label="Telefono"
                  value={row.phone}
                  onChange={(e) => {
                    setRow({...row, phone: e.target.value})
                  }}
                  errorMessage="Inserisci testo"
                  required
                />
              </div>
              <div className="w-full sm:w-2/3">
                <Input
                  label="Email"
                  type="email"
                  value={row.mail}
                  onChange={(e) => {
                    setRow({...row, mail: e.target.value})
                  }}
                  errorMessage="Inserisci un indirizzo mail valido"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-3">
              <div className="w-full sm:w-1/2">
                {/*@ts-ignore*/}
                <Select
                  label="Categoria"
                  value={row.id_category}
                  options={[{value: undefined, name: ''}].concat(optionsCategories)}
                  onChange={(e) => setRow({
                    ...row, id_category: Number(e.target.value)
                  })}
                />
              </div>
              <div className="w-full sm:w-1/2">
                {/*@ts-ignore*/}
                <Select
                  label="Città"
                  value={row.id_city}
                  options={[{value: undefined, name: ''}].concat(optionsCities)}
                  onChange={(e) => setRow({
                    ...row, id_city: Number(e.target.value)
                  })}
                />
              </div>
            </div>
          </div>
          <div className="flex items-end justify-end mt-5">
            <Button submitting={loading} type="submit" disabled={Object.values(row).filter(i => !i).length > 0}>
              Aggiungi
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddExternalMemberDialog;
