import { RefManListItemType, RefManListType } from '@components/RefMan/RefManList/RefManList.type';
import { useEffect, useState } from 'react';
import Placeholder from '@ui-components/Placeholder';
import { onPreviousClick, onNextClick } from '@components/RefMan/RefManList/RefManList.lib';
import { getFooterText } from '@components/RefMan/RefManList/RefManList.lib';

const RefManList = ({
  containerId,
  items,
  setOffset,
  containerClassNames,
  rowClassNames,
  offset = 0,
  limit = 5,
  total,
  pagination = 'frontend',
}: RefManListType) => {
  const [containerClassNamesString, setContainerClassNamesString] = useState<string>();
  const [rowClassNamesString, setRowClassNamesString] = useState<string>();
  const [rows, setRows] = useState<RefManListItemType[]>();

  /**
   * Handle ITEMS changes
   */
  useEffect(() => {
    switch (pagination) {
      case 'frontend':
        if (items && items.length > 0) {
          setRows(items.slice(offset, offset + limit));
        }
        if (items && items.length === 0) {
          setRows([])
        }
        break;
      case 'backend':
        if (items) {
          setRows(items);
        } else {
          setRows(undefined);
        }
    }
  }, [items]);

  /**
   * CONTAINER class names
   */
  useEffect(() => {
    setContainerClassNamesString(
      'refmanlist__row flex flex-col gap-y-3 pb-16 sm:pb-1' + (containerClassNames ? ' ' + containerClassNames.join(' ') : '')
    );
  }, [containerClassNames]);

  /**
   * ROW class names
   */
  useEffect(() => {
    setRowClassNamesString(
      'refmanlist__row p-3' +
        (rowClassNames ? ' ' + rowClassNames.join(' ') + ' ' : '') +
        'cursor-pointer border bg-gray-50 hover:bg-white rounded-xl shadow-lg'
    );
  }, [rowClassNames]);

  return (
    <div id={containerId || ''} className='refmanlist__container'>
      <div className={containerClassNamesString}>
        {rows && rows.length > 0 ? (
          rows.map(({ rowId, rowElement, rowOnClick }: RefManListItemType, index: number) => (
            <div
              id={rowId}
              key={index}
              onClick={(e) => {
                rowOnClick && rowOnClick(e, rowId);
              }}
              className={rowClassNamesString}
            >
              {rowElement}
            </div>
          ))
        ) : rows && rows.length === 0 ? (
          <div className='flex flex-col items-center justify-center h-40'>
            <p className='text-lg text-gray-700'>Nessun risultato...</p>
          </div>
        ) : (
          [...new Array(limit)].map((each, i) => <Placeholder key={i} height='h-20' classNames='rounded-lg bg-am-600' />)
        )}
      </div>
      {total / limit > 1 && rows && rows.length > 0 && (
        <nav
          className='items-center justify-between border-t border-gray-200 bg-am-400 sm:bg-white px-4 py-3 sm:px-6 mt-4 fixed sm:relative left-0 sm:left-auto right-0 sm:right-auto bottom-0 sm:bottom-auto'
          aria-label='Pagination'
        >
          <div className='hidden sm:block'>
            <p className='text-sm text-gray-700'>{getFooterText(offset, limit, total)}</p>
          </div>
          <div className='flex flex-1 justify-between sm:justify-end'>
            <div
              className={
                'relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus-visible:outline-offset-0' +
                (offset - limit >= 0 ? ' cursor-pointer hover:bg-gray-50' : ' cursor-not-allowed')
              }
              onClick={() => {
                onPreviousClick(offset, limit, setRows, setOffset, pagination);
              }}
            >
              Precedente
            </div>
            <div
              className={
                'relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus-visible:outline-offset-0' +
                (offset + limit < total ? ' cursor-pointer hover:bg-gray-50' : ' cursor-not-allowed')
              }
              onClick={() => {
                onNextClick(offset, limit, total, setRows, setOffset, pagination);
              }}
            >
              Successiva
            </div>
          </div>
        </nav>
      )}
    </div>
  );
};

export default RefManList;
