import jwtDecode from "jwt-decode";
import { api, refreshApiRequest } from "@services/apiRequest";

export const REFRESH_TOKEN_ENDPOINT_URL = "/auth/refresh-token";
export const LOGIN_ENDPOINT_URL = "/auth/login";
export const OAUTH2_LOGIN_ENDPOINT_URL = "/oauth2/login";
export const LOGIN_URL = "/login";

export class AuthenticationService {
  static AUTH_STORAGE_PATH = "logged_user";
  // Deprecated
  // AUTH_TIME IS NOT USED ANYMORE, we respect exp field in jwt
  static AUTH_TIME = 2 * 3600; // 2h respect jwt format in seconds

  static getUserData() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));

    if (authObj) {
      return authObj;
    }

    return null;
  }

  static logout_and_redirect(redirectCallback) {
    this.logout();
    if (window.location.pathname !== LOGIN_URL) {
      this.logout();
      if (typeof redirectCallback === "function") {
        redirectCallback(LOGIN_URL);
      } else {
        window.location.href = LOGIN_URL;
      }
    }
  }

  static getUserScopes() {
    const token = this.getAuthToken();

    if (token) {
      const decoded = jwtDecode(token)
      return decoded.scopes || decoded[`scopes_${localStorage.getItem('refman_role')}`] || []
    }

    return [];
  }

  static getUserGroups() {
    const token = this.getAuthToken();

    if (token) {
      const decoded = jwtDecode(token)
      return decoded.groups || decoded[`groups_${localStorage.getItem('refman_role')}`] || []
    }

    return [];
  }

  static getUserLevel() {
    const token = this.getAuthToken();

    if (token) {
      const decoded = jwtDecode(token)
      return decoded.level || decoded[`level_${localStorage.getItem('refman_role')}`] || []
    }

    return [];
  }

  static getUserFipCode() {
    const token = this.getAuthToken();

    if (token) {
      const decoded = jwtDecode(token)
      return decoded.id_fip_code || undefined
    }

    return [];
  }

  static getNowEpoch() {
    return Date.now() / 1000; // respect jwt format in seconds
  }

  static isTokenExpired() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));
    if (!authObj) {
      return false;
    }
    if (!authObj.expires) {
      return false;
    }
    if (authObj && authObj.expires_at) {
      return authObj.expires_at < this.getNowEpoch();
    }
    return true;
  }

  static isRefreshTokenExpired() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));
    if (!authObj) {
      return false;
    }
    if (!authObj.expires) {
      return false;
    }
    if (authObj && authObj.expires_at_refresh) {
      return authObj.expires_at_refresh < this.getNowEpoch();
    }
    return true;
  }

  static getAuthToken() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));

    if (authObj && authObj?.access_token) {
      return authObj.access_token;
    }
    return undefined;
  }

  static getRefreshToken() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));

    if (authObj && authObj?.refresh_token) {
      return authObj.refresh_token;
    }

    return undefined;
  }

  static getExpiresAt(token, expires = true) {
    if (!token) {
      return undefined;
    }
    const now = this.getNowEpoch();

    const { exp } = jwtDecode(token);
    return exp || (expires ? now + this.AUTH_TIME : -1);
  }

  /**
   *
   * @param {string} access_token
   * @param {string} [refresh_token]
   * @param {object} userData
   * @param {boolean} expires
   */
  static setAuthToken(access_token, refresh_token, userData, expires = true) {

    const expires_at = this.getExpiresAt(access_token, expires);
    const expires_at_refresh = this.getExpiresAt(refresh_token, expires);

    const authObj = {
      ...userData,
      access_token,
      refresh_token,
      expires,
      expires_at,
      expires_at_refresh,
    };

    localStorage.setItem(this.AUTH_STORAGE_PATH, JSON.stringify(authObj));
  }

  static deleteAuthToken() {
    localStorage.removeItem(this.AUTH_STORAGE_PATH);
  }

  static async login(username, password, toPath = "/home") {
    const data = new FormData();
    data.append("username", username);
    data.append("password", password);

    const {data: { access_token, refresh_token, user_data }} = await api.post(
      LOGIN_ENDPOINT_URL,
      data,
      {
        publicRequest: true,
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    // if it's the first time the user logs in, set the default role
    // or In case of users with different roles in the same browser
    if (!user_data.roles) {
      this.logout_and_redirect();
    }

    const userRoles = user_data.roles.split(';')
    const currentRole = localStorage.getItem("refman_role")
    if (!currentRole || !userRoles.includes(currentRole)) {
      localStorage.setItem("refman_role", userRoles[0])
    }

    const {data: token} = await api.get(
      `/auth/get-token/${localStorage.getItem("refman_role")}`,
      {
        publicRequest: true,
        headers: {"Authorization": `Bearer ${access_token}`},
      }
    )

    if (token) {
      this.setAuthToken(token, refresh_token, user_data);
    } else {
      this.logout_and_redirect();
      return;
    }


    window.location = toPath;
  }

  static async getOauth2Url() {
    return api
      .get(OAUTH2_LOGIN_ENDPOINT_URL, { publicRequest: true })
      .then((data) => {
        let url = new URL("authorize", data["oauth_authority"]);
        const searchParams = new URLSearchParams({
          client_id: data["client_id"],
          scope: data["scope"],
          response_type: data["response_type"],
          redirect_uri: data["redirect_uri"],
          prompt: "select_account",
        });
        url.search = searchParams.toString();
        return url;
      });
  }

  static logout() {
    this.deleteAuthToken();
  }

  static isUserAuthenticated() {
    const access_token = this.getAuthToken();
    return !!access_token;
  }

  static authHeader() {
    if (this.isUserAuthenticated())
      return {
        Authorization: `Bearer ${this.getAuthToken()}`,
      };

    return false;
  }

  static async refreshToken() {
    const refresh_token = AuthenticationService.getRefreshToken();
    if (!refresh_token) {
      return null;
    }
    const data = new FormData();
    data.append("refresh_token", refresh_token);
    data.append("grant_type", "refresh_token");
    const response = await refreshApiRequest.post(
      REFRESH_TOKEN_ENDPOINT_URL,
      data,
      {
        publicRequest: true,
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    const {
      access_token: new_access_token,
      refresh_token: new_refresh_token,
      user_data,
    } = response.data;

    const {data: token} = await api.get(
      `/auth/get-token/${localStorage.getItem("refman_role")}`,
      {
        publicRequest: true,
        headers: {"Authorization": `Bearer ${new_access_token}`},
      }
    )

    if (token) {
      this.setAuthToken(token, new_refresh_token, user_data);
    }
    return token;
  }
}

export default AuthenticationService;
