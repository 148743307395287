import React, {Fragment} from "react";

import {Popover, Transition} from '@headlessui/react'
import {
  ClipboardDocumentListIcon,
  EllipsisHorizontalIcon,
  FireIcon,
  FlagIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import {
  ActionListType,
  close_quiz_session,
  create_quiz_session,
  delete_quiz,
  duplicate_quiz,
  modify_quiz,
  quiz_cod_type_finished,
  quiz_cod_type_in_progress,
  QuizActionListProps
} from "@components/RefMan/Quiz/types";

const actionList: ActionListType[] = [
  {
    name: create_quiz_session,
    description: 'Sottoponi il quiz ai tesserati',
    icon: FireIcon,
  },
  {
    name: close_quiz_session,
    description: 'Termina la sessione corrente',
    icon: FlagIcon,
  },
  {
    name: duplicate_quiz,
    description: 'Duplica questo quiz',
    icon: ClipboardDocumentListIcon,
  },
  {
    name: modify_quiz,
    description: "Modifica il quiz",
    icon: PencilSquareIcon,
  },
  {
    name: delete_quiz,
    description: 'Elimina il quiz',
    icon: TrashIcon,
  },
]

function checkIfActionIsAllowed(cod_status: string, action: string) {
  switch (action) {
    case "Crea sessione":
      return cod_status !== quiz_cod_type_finished;
    case "Termina sessione":
      return cod_status === quiz_cod_type_in_progress;
    // case "Modifica":
    //   return cod_status === quiz_cod_type_not_started;
    // case "Elimina":
    //   return cod_status === quiz_cod_type_not_started;
    default:
      return true;
  }
}

export function QuizActionList({cod_status, setAction}: QuizActionListProps) {

  return <Popover className="relative">
    <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 border-2 rounded-full border-am-700">
      <EllipsisHorizontalIcon className="h-8 w-8"/>
    </Popover.Button>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel className="absolute top-2 right-0 z-10 mt-5 flex px-4">
        <div
          className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
          <div className="p-1">
            {actionList.map((item) => (
              checkIfActionIsAllowed(cod_status, item.name) &&
              <div key={item.name}
                   className="group relative flex gap-x-6 rounded-lg p-2 hover:bg-gray-50 cursor-pointer"
                   onClick={() => setAction(item.name)}
              >
                <div
                  className="mt-1 flex h-9 w-9 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                  <item.icon className="h-6 w-6 text-gray-600 group-hover:text-am-700" aria-hidden="true"/>
                </div>
                <div>
                  <p className="font-semibold text-gray-900">
                    {item.name}
                    <span className="absolute inset-0"/>
                  </p>
                  <p className="mt-1 text-gray-600">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
}
