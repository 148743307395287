import {ObserverReportsReaderInputTextType} from "./ObserverReportsReaderInputText.type";
import React from "react";

const ObserverReportsReaderInputText = ({result}: ObserverReportsReaderInputTextType) => {
  return <div className="observerreportsreaderinputtext__container flex flex-col gap-y-2 p-2">
    <div className="observerreportsreaderinputtext__question text-lg font-semibold mb-3">
      {result.question.title}
    </div>
    <div className="observerreportsreaderinputtext__question ml-2">
      {result.value}
    </div>
  </div>
}

export default ObserverReportsReaderInputText