import { useContext, useEffect, useState } from "react";
import { NotificationsContext } from "../../../Notifications";
import { api } from "@services/apiRequest";
import _ from "lodash";

const useRoles = () => {
  const [roles, setRoles] = useState(null);
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    let closed = false;
    const fetchRoles = async () => {
      try {
        const { data } = await api.get("/roles");
        
        if (typeof data !== "object") {
          throw new Error("not object");
        }

        if (!closed) {
          setRoles((data || []).reduce((obj, i) => _.set(obj, [i.id], i), {}));
        }

      } catch (error) {
        console.log(error)
        setRoles([])
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if (roles === null) {
      fetchRoles();
    }

    return () => {
      closed = true;
    };
  }, [roles, push]);

  return roles;
};

export default useRoles;
