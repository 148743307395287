import React, {useContext, useEffect, useState} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {useParams} from "react-router-dom";

import {InboxArrowDownIcon} from "@heroicons/react/24/outline";
import {PageHeading} from "@ui-components/Container";
import Button from "@ui-components/Button";
import {EndSessionModal} from "@components/RefMan/Quiz/Session/EndSession/EndSessionModal";
import {SessionQuestion} from "@components/RefMan/Quiz/Session/Question/SessionQuestion";
import {TimeOut} from "@components/RefMan/Quiz/Session/EndSession/TimeOut";
import {SessionTimer} from "@components/RefMan/Quiz/Session/Frame/Timer";

import {useSession} from "@components/RefMan/Quiz/hooks/useSession";
import {useQuiz} from "@components/RefMan/Quiz/hooks/useQuiz";

import {QuizListBaseResponseType, QuizSessionQuestionSchema} from "@components/RefMan/Quiz/types";

type QuizSessionProps = {
  id_quiz: string | undefined;
}

export function SessionFrame() {
  // context
  const {push} = useContext(NotificationsContext);

  const {id_quiz} = useParams<QuizSessionProps>();
  const idQuiz = Number(id_quiz);

  const [quiz, setQuiz] = useState<QuizListBaseResponseType>();
  const [questionsList, setQuestionsList] = useState<QuizSessionQuestionSchema[]>([]);
  const [numSecTimer, setNumSecTimer] = useState<number | undefined>(-1);
  const [timeout, setTimeout] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<QuizSessionQuestionSchema | undefined>();
  const [showEndSession, setShowEndSession] = useState<boolean>(false);
  const {getQuizQuestionList, getTimeLeft, loading} = useSession();
  const {getQuiz} = useQuiz();

  useEffect(() => {
    if (idQuiz) {

      getQuiz(idQuiz)
        .then(res => {
          setQuiz(res)
        })
        .catch(() => {
          push({title: 'Errore del server', type: 'error'});
        });

      // Ritorna la lista di domande relative al quiz
      getQuizQuestionList(idQuiz)
        .then(res => {
          setQuestionsList(res);
          if (res)
            setCurrentQuestion(res[0]);
        })
        .catch(() => {
          push({title: 'Errore del server', type: 'error'});
        });

      // Ritorna il numero di secondi che mancano al termine della sessione
      getTimeLeft(idQuiz)
        .then(res => {
          setNumSecTimer(res);
        })
        .catch(() => {
          push({title: 'Errore del server', type: 'error'});
        });
    }
  }, [idQuiz]);

  if (!idQuiz) {
    return null;
  }

  const currentQuestionIndex: number | undefined = currentQuestion ?
    questionsList.findIndex(el => el.id_question === currentQuestion.id_question) : undefined


  if (loading) {
    return <></>
  }

  return <>
    <PageHeading
      title={<div className="w-full text-lg font-bold">{quiz?.des_title}</div>}
      contentActions={<div className="py-2">
        <SessionTimer numSecTimer={numSecTimer} setTimeout={setTimeout}/>
      </div>}
      historyBackPath="_back"
    />
    {timeout ? <TimeOut/> :
      <div className="flex flex-col items-center gap-5">
        <div className="bg-white flex flex-col items-center">
          {currentQuestion &&
            <SessionQuestion
              questionsList={questionsList}
              setQuestionsList={setQuestionsList}
              activeIdQuestion={currentQuestion.id_question}
            />
          }
          {currentQuestionIndex !== undefined &&
            <div className={"flex flex-row justify-between mt-5"}>
              <Button
                styleType='white'
                disabled={currentQuestionIndex === 0}
                onClick={() => setCurrentQuestion(questionsList[currentQuestionIndex - 1])}
              >
                PREV
              </Button>
              <Button
                styleType='white'
                disabled={currentQuestionIndex === questionsList.length - 1}
                onClick={() => setCurrentQuestion(questionsList[currentQuestionIndex + 1])}
              >
                NEXT
              </Button>
            </div>
          }
        </div>
        {currentQuestionIndex === questionsList.length - 1 ?
          <div className="my-5">
            <Button
              onClick={() => setShowEndSession(true)}
            >
              Termina quiz
              <InboxArrowDownIcon className="ml-1.5 mr-1.5 h-5 w-5" aria-hidden="true"/>
            </Button>
          </div> : null
        }
      </div>
    }
    {showEndSession ?
      <EndSessionModal
        idQuiz={idQuiz}
        questionsList={questionsList}
        onExit={() => setShowEndSession(false)}
      />
      : null
    }
  </>
}
