import React, {useState, useEffect} from "react";

// Components
import {getMemberGroupOptions, getEvaluationsItems, EvaluationDownloadExcel} from "@pages/Reports/Reports.lib";
import RefManList from "@components/RefMan/RefManList/RefManList";
import {GamesLevelFilter} from "@pages/Games/Games.lib";
import {getMemberOptions} from "@utils/index";

// Ui
import {ArrowPathIcon, InboxArrowDownIcon} from "@heroicons/react/24/outline";
import SearchSelect from "@ui-components/SearchSelect";
import Placeholder from "@ui-components/Placeholder";

// Type
import {VisibilityLevel, MemberGroup, Evaluations, MemberFilter} from "@pages/Reports/Reports.type";
import {SelectedLevel} from "@pages/Games/Games.type";
import {LevelSchema} from "@services/types/level";
import {Member} from "@services/types/member";
import {useIntl} from "react-intl";


export const ReportEvaluations = (
  {
    data,
    group,
    levels,
    visibilityLevel
  }: {
    group: 'ref' | 'udc',
    levels: LevelSchema[],
    data: Evaluations[] | undefined,
    visibilityLevel: VisibilityLevel
  }) => {

  const intl = useIntl();
  const [memberGroup, setMemberGroup] = useState<MemberGroup[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<SelectedLevel[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<MemberFilter[]>([]);
  const [selectedObservers, setSelectedObservers] = useState<MemberFilter[]>([]);
  const [excelDownloadLoading, setExcelDownloadLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    setMemberGroup([])
    setSelectedMembers([])
    setSelectedObservers([])
    setSelectedLevels([])
  }, [group])

  const currentCategories = (memberGroup).reduce((acc: string[], i) => acc.concat(i.cod_sub_categories), [])
  const filteredData = data ? data.filter((i: Evaluations) => !memberGroup.length || currentCategories.includes(i.member.category.cod_sub_category)) : []
  const currentLevels = Array.from(new Set(filteredData)).map(i => i.game.cod_level)

  const currentMembers = filteredData.reduce((acc: Member[], i) => {
    if (!acc.find((item) => item.id_fip_code === i.id_member)) {
      acc.push(i.member)
    }
    return acc
  }, []).sort((a, b) => a.member.localeCompare(b.member))

  const currentObservers = filteredData.reduce((acc: Member[], i) => {
    if (!acc.find((item) => item.id_fip_code === i.id_observer)) {
      acc.push(i.observer)
    }
    return acc
  }, []).sort((a, b) => a.member.localeCompare(b.member))


  const listData = filteredData.filter(i =>
    (!selectedLevels.length || selectedLevels.map(i => i.value).includes(i.game.cod_level)) &&
    (!selectedMembers.length || selectedMembers.map(i => i.data.id_fip_code).includes(i.id_member)) &&
    (!selectedObservers.length || selectedObservers.map(i => i.data.id_fip_code).includes(i.id_observer))
  )

  const handleGameDownloadExcelClick = async () => {
    setExcelDownloadLoading(true);
    EvaluationDownloadExcel(listData, intl);
    setExcelDownloadLoading(false);
  }

  return <div>
    {data && levels ?
      <>
        <div className="flex flex-col sm:flex-row justify-between gap-2 sm:gap-6 items-center">
          <div className="w-full">
            {/*@ts-ignore*/}
            <SearchSelect
              label="Gruppo"
              isMulti
              value={memberGroup}
              options={getMemberGroupOptions(group, visibilityLevel)}
              onChange={(v: MemberGroup[]) => {
                setMemberGroup(v)
              }}
            />
          </div>
          <div className="w-full">
            <GamesLevelFilter
              levels={levels.filter((i: LevelSchema) => currentLevels.includes(i.cod_level))}
              selectedLevels={selectedLevels}
              setSelectedLevels={setSelectedLevels}
            />
          </div>
          <div className="w-full">
            {/*@ts-ignore*/}
            <SearchSelect
              label="Tesserati"
              isMulti
              value={selectedMembers}
              options={getMemberOptions(currentMembers, false)}
              onChange={(v: MemberFilter[]) => {
                setSelectedMembers(v)
              }}
              filterOption={(candidate: { data: { data: any; }; }, input: string) => {
                if (input) {
                  const _data = candidate.data.data
                  const _label = _data.id_fip_code + _data.member
                  return _label.toUpperCase().includes(input.toUpperCase())
                }
                return true
              }}
            />
          </div>
          <div className="w-full">
            {/*@ts-ignore*/}
            <SearchSelect
              label="Osservatori"
              isMulti
              value={selectedObservers}
              options={getMemberOptions(currentObservers, false)}
              onChange={(v: MemberFilter[]) => {
                setSelectedObservers(v)
              }}
            />
          </div>
          <div className='mx-2 cursor-pointer' onClick={handleGameDownloadExcelClick}>
            {excelDownloadLoading ?
              <ArrowPathIcon className='w-7 animate-spin'/> :
              <InboxArrowDownIcon className='w-7'/>}
          </div>
        </div>
        <div className="mt-5">
          <RefManList
            limit={10}
            offset={offset}
            setOffset={setOffset}
            items={getEvaluationsItems(listData, intl)}
            total={listData.length}
            pagination="frontend"
            rowClassNames={['sm:grid', 'sm:grid-cols-7', 'sm:gap-3', '!cursor-default']}
          />
        </div>
      </> :
      <div className="mt-5 flex flex-col w-full mb-4 gap-2">
        <div className="flex flex-row gap-10">
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
        </div>
        <Placeholder height="h-96" classNames="rounded-lg"/>
      </div>
    }
  </div>
}