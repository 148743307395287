// noinspection OverlyComplexFunctionJS

import ObserverReportsFormRadio from "@components/RefMan/Reports/ReportsForm/ReportsFormRadio/ObserverReportsFormRadio";
import ObserverReportsFormSelect
  from "@components/RefMan/Reports/ReportsForm/ReportsFormSelect/ObserverReportsFormSelect";
import ObserverReportsFormInputText
  from "@components/RefMan/Reports/ReportsForm/ReportsFormInputText/ObserverReportsFormInputText";
import React from "react";
import {QuestionItemType, questionnaireType} from "@utils/questionnaires/type/questionnaire.type";
import {
  IsSectionCompletedType,
  IsSectionReadyType,
  ResultsType,
  SavingResultsType
} from "@components/RefMan/Reports/ReportsForm/ReportsForm.type";
import {api} from "@services/apiRequest";
import {EvaluationsResultsDataType} from "@components/RefMan/Reports/ReportsMatch/ObserverReportsMatch.type";
import {ReportItemType, ReportType} from "@components/RefMan/Reports/ReportsList/ObserverReportsList.type";
import {NominationType} from "@components/RefMan/Reports/reports.type";
import ReportsFormTotalScore from "@components/RefMan/Reports/ReportsForm/ReportsFormTotalScore/ReportsFormTotalScore";

/**
 * @function getQuestionComponent
 *
 * @param {QuestionItemType} item Current report data
 * @param {number} sectionIndex Id of current section
 * @param  {number} itemIndex Id of current item
 * @param  {ResultsType} results Current results data
 * @param {React.Dispatch<React.SetStateAction<ResultsType>>} setResults Update results data
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsFormChanged Update isFormChanged flag
 * @return {React.ReactElement} Component to render
 */
export const getQuestionComponent = (
  item: QuestionItemType,
  sectionIndex: number,
  itemIndex: number,
  results: ResultsType | undefined,
  setResults: React.Dispatch<React.SetStateAction<ResultsType | undefined>>,
  setIsFormChanged: React.Dispatch<React.SetStateAction<boolean>>,
): React.ReactElement => {
  switch (item.type) {
    case "radio":
      return <ObserverReportsFormRadio
        data={item}
        itemId={itemIndex}
        sectionId={sectionIndex}
        results={results}
        setResults={setResults}
        setIsFormChanged={setIsFormChanged}
      />
    case "select":
      return <ObserverReportsFormSelect
        data={item}
        itemId={itemIndex}
        sectionId={sectionIndex}
        results={results}
        setResults={setResults}
        setIsFormChanged={setIsFormChanged}
      />
    case "text-input":
      return <ObserverReportsFormInputText
        data={item}
        itemId={itemIndex}
        sectionId={sectionIndex}
        results={results}
        setResults={setResults}
        setIsFormChanged={setIsFormChanged}
      />
    case "total-score-function":
      return <ReportsFormTotalScore
        sectionId={sectionIndex}
        data={item}
        results={results}
        setResults={setResults}
      />
    default:
      console.error("[REFMAN-ERROR]", `Unknown type of question component (${item.type})`);
      return <></>
  }
}

/**
 * @function setEvaluationsData
 *
 * @param {string} codGame Code of game
 * @param {number} idObserver Id of observer
 * @param {number} idMember Id of member
 * @param {ResultsType} results Current results data
 * @param status
 * @param {boolean} isNew True if is a new record, false otherwise
 * @return {Promise<SavingResultsType | null>} New evaluations data on success, null on failure
 */
export const setEvaluationsData = async (
  codGame: string,
  idObserver: number,
  idMember: number,
  results: ResultsType,
  status: string,
  isNew: boolean = false,
): Promise<SavingResultsType | null> => {
  try {
    if (isNew) {
      const {data} = await api.post(`/evaluations`, {
        cod_game: codGame,
        id_observer: idObserver,
        id_member: idMember,
        status: status,
        results: results,
      })
      return data;
    } else {
      const {data} = await api.put(`/evaluations/${codGame}/${idObserver}/${idMember}`, {
        status: status,
        results: results,
      })
      return data;
    }
  } catch (error) {
    console.error("[REFMAN-ERROR]", 'Error retrieving observer evaluations data.', error)
    return null
  }
}

/**
 * @function getEvaluationsData
 *
 * @param {string} codGame Code of game
 * @param {string} idObserver Id of observer
 * @param {number} idMember Id of member
 * @return {Promise<EvaluationsResultsDataType[] | null>} Evaluations data on success, null on failure
 */
export const getEvaluationsData = async (
  codGame: string,
  idObserver: number,
  idMember: number
): Promise<EvaluationsResultsDataType[] | null> => {
  try {
    const {data} = await api.get(`/evaluations?cod_game=${codGame}&id_observer=${idObserver}&id_member=${idMember}`)
    return data
  } catch (error) {
    console.error("[REFMAN-ERROR]", 'Error retrieving observer evaluations data.', error)
    return null
  }
}

/**
 * @function saveButtonClickHandler
 *
 * @param {string} codGame Code of game
 * @param {string} idObserver Id of observer
 * @param {number} idMember Id of member
 * @param {ResultsType} results Current results data
 * @return {Promise<boolean>} True on success, false otherwise
 */
export const saveButtonClickHandler = async (
  codGame: string,
  idObserver: number,
  idMember: number,
  results: ResultsType,
): Promise<boolean> => {
  const result = await setEvaluationsData(codGame, idObserver, idMember, results, "WIP")
  return result !== null
}

/**
 * @function isSectionCompleted
 *
 * @param {number} currentSection Section to check
 * @param {ResultsType | undefined} results Current results data
 * @param {questionnaireType} questionnaires Current questionnaire
 */
export const isSectionCompleted: IsSectionCompletedType = (
  currentSection: number,
  results: ResultsType | undefined,
  questionnaires: questionnaireType,
): boolean => {
  try {
    questionnaires.sections[currentSection].items.forEach((sectionData: QuestionItemType) => {
      if (results?.[sectionData.id] === undefined) {
        throw new Error();
      }
      if (!results[sectionData.id].value) {
        throw new Error();
      }
    })
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * @function isSectionReady
 *
 * @param {number} currentSection Section to check
 * @param {ResultsType | undefined} results Current results data
 * @param {questionnaireType} questionnaires Current questionnaire
 */
export const isSectionReady: IsSectionReadyType = (
  currentSection: number,
  results: ResultsType | undefined,
  questionnaires: questionnaireType,
): boolean => {
  try {
    questionnaires.sections[currentSection].items.forEach((sectionData: QuestionItemType) => {
      if (results?.[sectionData.id] === undefined) {
        throw new Error();
      }
      if (results[sectionData.id].isRequired && !results[sectionData.id].value) {
        throw new Error();
      }
    })
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * @function handleSaveClick
 *
 * @param {ReportType | null} reportData Report data
 * @param {NominationType | undefined} memberData Member data
 * @param {ResultsType | undefined} results Current results data
 * @param push
 * @return {Promise<void>}
 */
export const handleSaveClick = async (
  reportData: ReportItemType | null,
  memberData: NominationType | undefined,
  results: ResultsType | undefined,
  push: any
): Promise<void> => {
  if (
    reportData &&
    memberData &&
    results &&
    await saveButtonClickHandler(reportData.cod_game, reportData.id_fip_code, memberData.id_fip_code, results)
  ) {
    push({title: "Valutazione salvata con successo", type: "info"});
  } else {
    push({title: "Errore nel salvataggio della valutazione", type: "error"});
  }
}

/**
 * @function handleConfirmClick
 *
 * @param {questionnaireType} questionnaires Current questionnaire
 * @param {ReportType | null} reportData Report data
 * @param {NominationType | undefined} memberData Member data
 * @param {ResultsType | undefined} results Current results data
 * @param push
 * @return {Promise<boolean>}
 */
export const handleConfirmClick = async (
  questionnaires: questionnaireType,
  reportData: ReportItemType | null,
  memberData: NominationType | undefined,
  results: ResultsType | undefined,
  push: any,
): Promise<boolean> => {
  let readySection = 0;
  questionnaires.sections.forEach((_, sectionId: number) => {
    if (isSectionReady(sectionId, results, questionnaires)) {
      readySection++;
    }
  })
  if (readySection < questionnaires.sections.length) {
    push({
      title: "Impossibile salvare la valutazione: non tutte domande obbligatorie sono state completate",
      type: "error"
    });
    return false;
  }
  if (!reportData || !memberData || !results) {
    push({title: "Errore nel salvataggio della valutazione", type: "error"});
    return false;
  }
  if (!confirm("Sei sicuro di voler confermare la valutazione?")) {
    return false;
  }
  if (await setEvaluationsData(
    reportData.cod_game,
    reportData.id_fip_code,
    memberData.id_fip_code,
    results,
    "APPROVED") === null) {
    push({title: "Errore nel salvataggio della valutazione", type: "error"})
    return false;
  } else {
    push({title: "Valutazione approvata con successo", type: "info"})
    return true;
  }
}