import React, {useContext, useEffect, useState} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

import {
  getDatesBetween, getFilteredMembers, getDateStr, nominationTooltip,
  absenceTooltip, playerTooltip, activityTooltip
} from "./Calendar.lib";
import {CalendarTableProps, CalendarData} from "./Calendar.type";
import {Member} from "@services/types/member";
import {ExclamationTriangleIcon, NoSymbolIcon} from "@heroicons/react/24/outline";


export function CalendarTable(
  {
    members,
    selectedGroup,
    selectedCategories,
    selectedProvinces,
    searchKey,
    startDate,
    endDate
  }: CalendarTableProps) {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const period = getDatesBetween(startDate, endDate)
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);

  const [data, setData] = useState<CalendarData>(
    {nominations: {}, absences: {}, players: {}, activities: {}, relocations: {}}
  )

  useEffect(() => {
    api
      .get(`/calendar/${selectedGroup.toUpperCase()}/${getDateStr(startDate)}/${getDateStr(endDate)}`)
      .then(({data: calendar}) => {
        setData(calendar)
      })
      .catch(() => {
        push({title: "Errore del server", type: "error"});
      });

  }, [selectedGroup, startDate, endDate])

  useEffect(() => {
    setFilteredMembers(
      getFilteredMembers(
        members,
        selectedCategories,
        selectedProvinces,
        searchKey,
        data,
        getDateStr(startDate),
        getDateStr(endDate)
      )
    )
  }, [data, startDate, endDate, selectedCategories, selectedProvinces, searchKey])

  return (
    <div className="overflow-x-auto flex flex-col h-70vh mb-5">
      <div className="inline-block min-w-full pb-2 align-middle flex-grow overflow-auto">
        <table className="min-w-full">
          <thead className="sticky top-0 z-30">
          <tr className="divide-x divide-am-400 divide-opacity-70">
            <th scope="col"
                className="sticky left-0 bg-am-600 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">
              Tesserato
            </th>
            {period.map(d =>
              <th
                key={d.toDateString()}
                scope="col"
                className={`${[0, 6].includes(d.getDay()) ? 'bg-am-600 ' : 'bg-am-400'} px-3 py-1 text-sm font-semibold text-white text-center whitespace-nowrap`}
              >
                {intl.formatDate(d, {day: "2-digit", month: "short"})}
              </th>
            )}
          </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          {filteredMembers.map((i) => (
            <tr key={i.id_fip_code + i.city.city} className="divide-x divide-gray-50">
              <td className="sticky left-0 z-20 bg-white text-sm font-light text-gray-900">
                <div className="border-r">
                  <div className="whitespace-nowrap pl-4 pr-3 sm:pl-6">
                    <span className="text-xs font-medium">
                      {i.id_fip_code} - {i.category.des_sub_category}
                    </span>
                    {data.relocations[i.id_fip_code] && data.relocations[i.id_fip_code].cities.filter(x => x.id === i.city.id).length
                      ? <span className="block text-xxs -mt-1 -mb-1 text-red-600">
                          <ExclamationTriangleIcon className="inline-block w-3"/> {i.city.city} ({i.city.cod_province})
                        </span>
                      : <span className="block text-xxs -mt-1 -mb-1">
                          {i.city.city} ({i.city.cod_province})
                        </span>
                    }
                    <span className="block font-bold">
                      {i.tag}
                    </span>
                  </div>
                </div>
              </td>
              {period.map(x => {
                const d = getDateStr(x)
                let isRelocated = false
                if (data.relocations[i.id_fip_code]) {
                  const cities = data.relocations[i.id_fip_code].cities.filter(x => x.id === i.city.id)
                  const days = cities.length ? cities[0].days : data.relocations[i.id_fip_code].original_days
                  isRelocated = !days.includes(d)
                }

                return <td className={`${[0, 6].includes(x.getDay()) ? 'bg-gray-50' : ''} whitespace-nowrap p-1 text-sm text-gray-500`} key={d}>
                  {isRelocated ?
                    <div className="flex items-center justify-center">
                      <NoSymbolIcon className="w-5 text-gray-300"/>
                    </div>
                    :
                    <div className="flex flex-wrap justify-center gap-1 items-center">
                      {data.activities?.[d]?.[String(i.id_fip_code)]?.map(a =>
                        activityTooltip(a)
                      )}
                      {data.players?.[d]?.[String(i.id_fip_code)]?.map(p =>
                        playerTooltip(p)
                      )}
                      {data.nominations?.[d]?.[String(i.id_fip_code)]?.map(n =>
                        nominationTooltip(n)
                      )}
                      {data.absences?.[d]?.[String(i.id_fip_code)]?.map(a =>
                        absenceTooltip(a)
                      )}
                    </div>
                  }
                </td>
                }
              )}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
