import {useContext, useEffect} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "../DataContext/DataContext";
import apiRequest from "../apiRequest";

const useAbsences = ({idFipCode}) => {

  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    let closed = false;
    const fetchData = async () => {
      try {
        const data = await apiRequest.get(`/absences?id_fip_code=${idFipCode}`);
        if (!closed) {
          dataContext.setAbsences({idFipCode: idFipCode, data: data});
        }
      } catch (error) {
        console.log(error)
        dataContext.setAbsences({idFipCode: idFipCode, data: false});
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if (idFipCode && (!dataContext.absences.data === null || dataContext.absences.idFipCode !== idFipCode)) {
      fetchData();
    }

    return () => {
      closed = true;
    };
  }, [idFipCode, dataContext.absences]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (!dataContext.absences.data || dataContext.absences.idFipCode !== idFipCode) {
    return undefined
  }
  return dataContext.absences.data;

};

export default useAbsences;
