import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from "react-router-dom";
import {api} from "@services/apiRequest";


// Ui
import {PageHeading} from '@ui-components/Container';
import Button from "@ui-components/Button";

// Components
import BasicAlgorithm from './BasicAlgorithm';
import EliteAlgorithm from './EliteAlgorithm';

import {VisibilityLevel} from "@pages/Reports/Reports.type";
type AlgoTypeProps = {
    algo_type: string | undefined;
}

function Algorithms() {
  const {algo_type} = useParams<AlgoTypeProps>();
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(algo_type === 'elite' ? 1 : 0);
  const [visibilityLevel, setVisibilityLevel] = useState<VisibilityLevel | undefined>(undefined);

  useEffect(() => {
    history.replace(`/nominations/algorithms/${selectedTab ? 'elite' : 'basic'}`);
  }, [selectedTab, history]);

  useEffect(() => {
    api.get(`/roles/${localStorage.getItem('refman_role')}`)
      .then(({data}) => {
        setVisibilityLevel(data.level)
      })
  }, [])

  const _renderSelectedTabComponent = () => {
    switch (selectedTab) {
      case 0:
        return <BasicAlgorithm/>
      default:
        return <EliteAlgorithm/>
    }
  }

  return (
    <div>
      <PageHeading
        title='Algoritmi'
        historyBackPath='_back'
        contentActions={
          <div className="flex flex-row gap-1">
            <Button
              styleType={selectedTab === 0 ? 'default' : 'white'}
              onClick={() => setSelectedTab(0)}
            >
              Basic
            </Button>
            <Button
              styleType={selectedTab === 1 ? 'default' : 'white'}
              disabled={!visibilityLevel || visibilityLevel === 'r'}
              onClick={() => setSelectedTab(1)}
            >
              Elite
            </Button>
          </div>
        }
      />
      <div className='flex flex-col gap-y-5'>
        {_renderSelectedTabComponent()}
      </div>

    </div>
  );
}

export default Algorithms;