import React, { useEffect, useState } from 'react'

import { EvaluationsResultsDataType, type ObserverReportsMatchType } from './ObserverReportsMatch.type'
import ObserverGameInfoItem from "./ObserverGameInfoItem/ObserverGameInfoItem";
import ObserverReportsItem from "./ObserverReportsItem/ObserverReportsItem"
import { getDateFormatted } from '@utils/lib/dates';
import { getRefs } from "@components/RefMan/Reports/reports.lib";
import { getEvaluationsResultsData } from "./ObserverReportsMatch.lib";
import Placeholder from "@ui-components/Placeholder";
import { LKP_ROLES } from "@utils/constants";

const ObserverReportsMatch = ({
  setStepId,
  reportData,
  setMemberData,
  role
}: ObserverReportsMatchType): React.JSX.Element => {
  const [evaluationResultsData, setEvaluationResultsData] = useState<EvaluationsResultsDataType[]>([])
  const [loaded, setLoaded] = useState(false)

  /**
   * Evaluations results data handler
   */
  useEffect(() => {
    void (async () => {
      if (reportData) {
        const results: EvaluationsResultsDataType[] = await getEvaluationsResultsData(reportData.cod_game, reportData.id_fip_code)
        setEvaluationResultsData(results)
        setLoaded(true)
      }
    })()
  }, [reportData])

  return <div className="observerreportsmatch__container">
    {!reportData ? <Placeholder height='h-80' /> :
      <div
        className="observerreportsmatch__game-info container border p-4 md:p-6 md:rounded-xl overflow-hidden shadow-xl">
        <div
          className="observerreportsmatch__game-info-header text-xl text-center uppercase mb-4 border-b border-gray-400 pb-3 text-gray-900">
          <strong>Dettagli gara</strong>
        </div>
        <ul
          className="observerreportsmatch__game-info-body flex flex-wrap flex-row justify-center md:justify-evenly pt-2 gap-4">
          <ObserverGameInfoItem
            title={"Camp."}
            text={reportData.game.cod_level}
            classNames={"w-24"}
          />
          <ObserverGameInfoItem
            title={"Gara"}
            text={String(reportData.game.game_number)}
            classNames={"w-24"}
          />
          <ObserverGameInfoItem
            title={"Squadre"}
            text={`${reportData.game.team_a.team_alias} vs ${reportData.game.team_b.team_alias}`}
            capitalizeText={true}
          />
          <ObserverGameInfoItem
            title={"Campo"}
            text={`${reportData.game.place} - ${reportData.game.city?.city} (${reportData.game.city?.cod_province})`}
          />
          <ObserverGameInfoItem
            title={"Data"}
            text={`${getDateFormatted(reportData.game.dat_game)} - ${reportData.game.time_game}`}
          />
        </ul>
        <ul
          className="observerreportsmatch__game-info-body flex flex-wrap flex-row justify-center md:justify-evenly pt-2 gap-4 mt-3">
          <ObserverGameInfoItem
            key={-1}
            title="Osservatore"
            text={reportData.member.member}
          />
          {getRefs(reportData.game.nominations, reportData.member.category.cod_group).map((ref, index) => (
            <ObserverGameInfoItem
              key={index}
              title={LKP_ROLES[ref.role]}
              text={ref.nomination.member.member}
            />
          ))}
        </ul>
      </div>}
    {reportData && loaded ?
      <div
        className="observerreportsmatch__members container mt-6 flex flex-wrap flex-row justify-center md:justify-around pt-2 gap-4">
        {getRefs(reportData.game.nominations, reportData.member.category.cod_group).map(({ nomination }, index: number) =>
          <ObserverReportsItem
            key={index}
            nomination={nomination}
            evaluationResultsData={evaluationResultsData}
            setMemberData={setMemberData}
            setStepId={setStepId}
            idObserver={reportData?.id_fip_code}
            role={role}
          />)}
      </div> : <Placeholder height="h-52" classNames="mt-6" />}
  </div>
}

export default ObserverReportsMatch
