import {api} from "@services/apiRequest";
import {useContext, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {IntlShape, useIntl} from "react-intl";
import {
  CreateNewQuizPayload, quiz_cod_type_in_progress, quiz_cod_type_finished,
  QuizListPaginatedBaseResponseType,
  QuizListBaseResponseType,
  QuizListPayloadType
} from "@components/RefMan/Quiz/types";

export const useQuiz = () => {

  // hooks
  const intl: IntlShape = useIntl();

  // context
  const {push} = useContext(NotificationsContext);

  const basePath = "/quizzes";

  const [loading, setLoading] = useState(false);
  const callApi = async (fn: Promise<any>, errorMsg: string) => {
    setLoading(true);
    try {
      return await fn;
    } catch (e) {
      push({type: "error", title: intl.formatMessage({id: "error"}), message: errorMsg});
    } finally {
      setLoading(false);
    }
  }

  const getAllQuiz = async (payload: QuizListPayloadType) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.post<QuizListPaginatedBaseResponseType>(`/quizzes/all`, payload);
        resolve(data);
      }),
      "ERROR_GET_ALL_QUIZ",
    );

    const getQuiz = async (idQuiz: number) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.get<QuizListBaseResponseType>(`${basePath}/${idQuiz}`);
        resolve(data);
      }),
      "ERROR_GET_QUIZ",
    );

  const deleteQuiz = async (idQuiz: number) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.delete(`${basePath}/${idQuiz}`);
        resolve(data);
      }),
      "ERROR_DELETE_QUIZ",
    );

  const copyQuiz = async (idQuiz: number, desTitle: string) =>
    await callApi(
      new Promise(async (resolve) => {
        const payload = {id_quiz: idQuiz, des_title: desTitle}
        const {data} = await api.post(`${basePath}/copy`, payload);
        resolve(data);
      }),
      "ERROR_COPY_QUIZ",
    );

  const createNewQuiz = async (payload: CreateNewQuizPayload) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.post(`${basePath}`, payload);
        resolve(data);
      }),
      "ERROR_CREATE_NEW_QUIZ",
    );

  const updateQuiz = async (idQuiz: number, payload: CreateNewQuizPayload) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.put(`${basePath}/${idQuiz}`, payload);
        resolve(data);
      }),
      "ERROR_UPDATE_QUIZ",
    );

  const startStopQuizSession = async (idQuiz: number, status: typeof quiz_cod_type_in_progress | typeof quiz_cod_type_finished) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.put(`${basePath}/${idQuiz}/${status}`);
        resolve(data);
      }),
      "ERROR_START_STOP_QUIZ_SESSION",
    );

  return {getQuiz, getAllQuiz, deleteQuiz, copyQuiz, createNewQuiz, updateQuiz, startStopQuizSession, loading};

}