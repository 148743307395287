import React, {useCallback, useEffect, useState} from "react";

// Services
import apiRequest from "@services/apiRequest";
import {LKP_JOB_TYPES} from "@utils/constants";
import {SPEC_COLS_OPTIONS} from "./services";

// Ui
import Button from "@ui-components/Button";
import Input from "@ui-components/Input";
import Modal from "@ui-components/Modal";
import Select from "@ui-components/Select";
import RadioGroup from "@ui-components/RadioGroup";
import CheckboxGroup from "@ui-components/CheckboxGroup";
import Alert from "@ui-components/Alert";

const initState = {
  job_type: undefined,
  name: "",
  cod_group: undefined,
  levels: undefined,
  provinces: undefined
}

function AddJobDialog({open, toggleOpen, dataContext, push}) {
  const [loading, setLoading] = useState(false);

  const [specifyColumns, setSpecifyColumns] = useState({levels: 'all', provinces: 'all'})
  const [missingData, setMissingData] = useState("Inserisci il nome dell'incarico")
  const [row, setRow] = useState(initState);

  useEffect(() => {
    let res = row
    if (specifyColumns.levels === 'all') {
      res = {...row, levels: undefined}
    }
    if (specifyColumns.provinces === 'all') {
      res = {...row, provinces: undefined}
    }
    setRow(res)
  }, [specifyColumns]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let err
    if (specifyColumns.provinces !== 'all' && !row.provinces) {
      err = "Devi specificare almeno una provincia"
    }
    if (specifyColumns.levels !== 'all' && !row.levels) {
      err = "Devi specificare almeno un campionato"
    }
    if (row.job_type && row.job_type !== 'CIA' && !row.cod_group) {
      err = "Devi specificare il gruppo"
    }
    if (!row.job_type) {
      err = "Specifica il tipo di ruolo"
    }
    if (!row.name) {
      err = "Inserisci il nome dell'incarico"
    }
    setMissingData(err)
  }, [row]) // eslint-disable-line react-hooks/exhaustive-deps

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        setLoading(true);
        const newRow = await apiRequest.post("/jobs", row);
        dataContext.setJobs({...dataContext.jobs, [newRow['id']]: newRow});
        toggleOpen();
        push({
          title: "Elemento aggiunto correttamente",
          type: "success",
        });
      } catch (error) {
        console.log(error)
        push({
          title: "Errore imprevisto",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [row] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div>
      <Modal opened={open} onExit={() => {
        setSpecifyColumns({levels: 'all', provinces: 'all'})
        setRow(initState)
        toggleOpen()
      }}>
        <h3 className="text-center text-2xl font-extrabold text-gray-900 mt-6 sm:mt-0">
          Aggiungi nuovo incarico
        </h3>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div className="flex flex-col gap-2 my-6">
            <Input
              label="Nome"
              value={row.name}
              onChange={(e) => {
                setRow({...row, name: e.target.value})
              }}
              errorMessage="Inserisci testo"
              required
            />
            <Select
              label="Tipologia"
              value={row.job_type}
              options={[{value: undefined, name: "-"}].concat(
                Object.entries(LKP_JOB_TYPES).map(([k, v]) => ({value: k, name: v}))
              )}
              onChange={(e) => {
                setRow({...row, job_type: e.target.value})
              }}
            />
            <div className="mt-2 mb-4">
              <Select
                label="Gruppo"
                value={row.cod_group}
                options={[{value: undefined, name: "-"}, {value: 'REF', name: 'Arbitri'}, {value: 'UDC', name: 'UdC'}]}
                onChange={(e) => {
                  setRow({...row, cod_group: e.target.value})
                }}
              />
            </div>
            {Object.keys(specifyColumns).map(i =>
              <div key={i} className="flex flex-col">
                <RadioGroup
                  title={i === 'levels' ? 'Campionati' : 'Province'}
                  id={`specify${i}`}
                  horizontal
                  options={[
                    {value: "all", name: "Nessun limite"},
                    {value: "select", name: "Seleziona"},
                  ]}
                  currentValue={specifyColumns[i]}
                  onChange={(value) => {
                    setSpecifyColumns({...specifyColumns, [i]: value})
                  }}
                />
                {specifyColumns[i] === 'select' ?
                  <div>
                    <CheckboxGroup
                      horizontal
                      options={SPEC_COLS_OPTIONS[i].map((o) => ({value: o, name: o}))}
                      values={row[i] ? row[i].split(";") : []}
                      onChange={(value) => {
                        const current = row[i] ? row[i].split(";") : []
                        let res
                        if (current.indexOf(value) >= 0) {
                          res = [...current.filter((v) => v !== value)];
                        } else {
                          res = [...current, value];
                        }
                        setRow({...row, [i]: res.length ? res.join(";") : undefined})
                      }}
                    />
                  </div> : null}
              </div>
            )}
          </div>
          <div className="w-full flex flex-col sm:flex-row gap-3 justify-between items-center">
            {missingData ? <Alert type="warning" slim title={missingData}/> : <div/>}
            <Button type="submit" submitting={loading} disabled={missingData !== undefined}>
              Aggiungi
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddJobDialog;
