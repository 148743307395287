import React from "react";

import {EditorState} from 'draft-js';
import {Modifier, RichUtils} from "draft-js";
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';

export const NOTE_CLS = 'bg-gray-50 border border-gray-100 rounded-md shadow px-3 py-2 overflow-hidden flex-shrink-0';

export const DEFAULT_NOTE = {
  id: undefined,
  content: EditorState.createEmpty(),
  mentions: []
}

export const newNoteWithMention = (mention: { id: number, name: string }) => {
  const state = EditorState.createEmpty();
  const stateWithEntity = state.getCurrentContent().createEntity(
    'mention',
    'IMMUTABLE',
    {
      mention: {
        id: mention.id,
        name: mention.name
      },
    },
  )
  const entityKey = stateWithEntity.getLastCreatedEntityKey()
  const stateWithText = Modifier.insertText(stateWithEntity, state.getSelection(), mention.name, undefined, entityKey);
  return ({
    id: undefined,
    content: EditorState.moveFocusToEnd(EditorState.push(state, stateWithText, 'insert-characters')),
    mentions: []
  })
}

export const EDITOR_CONTROLS = {
  bold: {
    component: <span className="font-serif font-bold">B</span>,
    onClick: (state: any) => RichUtils.toggleInlineStyle(state, 'BOLD'),
  },
  italic: {
    component: <span className="font-serif italic">I</span>,
    onClick: (state: any) => RichUtils.toggleInlineStyle(state, 'ITALIC'),
  },
  underline: {
    component: <span className="font-serif underline">U</span>,
    onClick: (state: any) => RichUtils.toggleInlineStyle(state, 'UNDERLINE'),
  },
  mention: {
    component: <span>@</span>,
    onClick: (state: any) => {
      const currentContent = state.getCurrentContent(),
        currentSelection = state.getSelection();
      const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        '@'
      );
      console.log(newContent)
      return EditorState.push(state, newContent, 'insert-characters');
    }
  },
};

export const EDITOR_CONTROLS_INIT = Object.keys(EDITOR_CONTROLS).reduce(
  (prev, key) => ({...prev, [key]: false}),
  {}
);


export const getControl = (key: string) => {
  switch (key) {
    case 'b':
      return 'bold'
    case 'i':
      return 'italic'
    case 'u':
      return 'underline'
    default:
      return;
  }
}
