import React, {useContext, useState} from 'react';

import {NotificationsContext} from "@ui-components/Notifications";
import {api} from '@services/apiRequest';

import {CheckCircleIcon, HandRaisedIcon} from "@heroicons/react/24/outline";
import RadioGroup from "@ui-components/RadioGroup";
import Tooltip from "@ui-components/Tooltip";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Input from "@ui-components/Input";

import {RefManListItemType} from '@components/RefMan/RefManList/RefManList.type';
import {LevelSchema} from "@services/types/level";
import {Member} from '@services/types/member';
import Checkbox from "@ui-components/Checkbox";


export const getListItems = (
  data: Member[],
  selectedGroup: 'ref' | 'udc',
  setSelectedMember: React.Dispatch<React.SetStateAction<Member | undefined>>,
): RefManListItemType[] | [] => {
  if (!data.length) {
    return [];
  }

  return data.map((member: Member): RefManListItemType => {
    return {
      rowId: `member-${member.id_fip_code}`,
      rowOnClick: () => setSelectedMember(member),
      rowElement: (
        <>
          <dt className='flex flex-col'>
            <div className="flex flex-row gap-2 text-xs">
              <span>{member.id_fip_code}</span>
              <span className="font-bold">&#8226;</span>
              <span>{member.category.des_sub_category}</span>
            </div>
            <span className='font-bold'>{member.member}</span>
            {selectedGroup === 'ref' ?
              <span className='font-light text-sm mt-1'>
                {member.city.city} ({member.city.cod_province})
              </span> : null
            }
          </dt>
          {selectedGroup === 'ref' ?
            <dt className="flex flex-col gap-2 items-center text-xs">
              {member.flg_alone
                ? <CheckCircleIcon className="w-6 text-green-600"/>
                : <HandRaisedIcon className="w-6 text-red-600"/>
              }
              {member.flg_alone
                ? <span>Può arbitrare da solo</span>
                : <span>Non può arbitrare da solo</span>
              }
            </dt> :
            <dt>
              <span className='font-light text-sm'>
                {member.city.city} ({member.city.cod_province})
              </span>
            </dt>
          }
          <dt className="flex flex-col items-center text-xs">
            <span className="text-2xl font-bold">{member.max_week_games ?? 0}</span>
            <span>gare a settimana</span>
          </dt>
          <dt className="flex flex-col items-center text-xs">
            <span
              className="text-2xl font-bold">{Object.values(member.allowed_role_levels ?? {}).filter(i => i.length > 0).length}</span>
            <span>campionati abilitati</span>
          </dt>
        </>
      ),
    };
  });
};

export const UpdateMemberParameterDialog = (
  {
    member,
    dataContext,
    levelOptions,
    setSelectedMember,
  }: {
    member: Member,
    dataContext: any,
    levelOptions: LevelSchema[],
    setSelectedMember: React.Dispatch<React.SetStateAction<Member | undefined>>,
  }) => {

  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);
  const [alone, setAlone] = useState<'0' | '1'>(member.flg_alone ? '1' : '0');
  const [weekGames, setWeekGames] = useState<number>(member.max_week_games ?? 0);
  const [roleLevels, setRoleLevels] = useState<{ [key: string]: string[] }>(member.allowed_role_levels ?? {})

  const updateData = () => {
    setLoading(true)
    api
      .put(`/members/${member.id_fip_code}`, {
        ...member,
        flg_alone: Boolean(Number(alone)),
        max_week_games: weekGames,
        allowed_role_levels: roleLevels
      })
      .then(({data: res}) => {
        dataContext.setMembers({...dataContext.members, [res.id_fip_code]: res})
        push({title: 'Elemento eliminato con successo', type: 'success'});
        setSelectedMember(undefined);
      })
      .catch(() => {
        push({title: 'Errore del server', type: 'error'});
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return <Modal onExit={() => setSelectedMember(undefined)}>
    <h1 className="font-bold text-xl">{member.member}</h1>
    <h2 className="flex flex-row gap-2 text-sm my-3">
      <span>{member.id_fip_code}</span>
      <span className="font-bold">&#8226;</span>
      <span>{member.category.des_sub_category}</span>
      <span className="font-bold">&#8226;</span>
      <span>{member.city.city} ({member.city.cod_province})</span>
    </h2>
    <div className={dataContext.group === 'ref' ? 'px-10' : ''}>
      {member.category.cod_group === 'REF' ?
        <div className="flex flex-col sm:flex-row items-center mt-6 sm:mt-3 sm:gap-3">
          <span className="font-bold text-sm mt-2">Può arbitrare da solo?</span>
          <RadioGroup
            horizontal
            currentValue={alone}
            options={[{value: "1", name: "Sì"}, {value: "0", name: "No"}]}
            onChange={(value: '0' | '1') => {
              setAlone(value);
            }}
          />
        </div> : null
      }
      <div className="flex flex-col sm:flex-row items-center mt-5 gap-1 sm:gap-3">
        <span className="font-bold text-sm">Gare a settimana</span>
        <Tooltip
          placement="bottom"
          text="RefMan cercherà di non assegnare un numero maggiore di partite a settimana, savo assoluta necessità"
          customStyle="text-xs normal-case -mb-1"
          size="small"
          iconName="InformationCircleIcon"
        />
        <div className="w-16 h-12 sm:ml-2">
          {/*@ts-ignore*/}
          <Input
            type="number"
            value={weekGames}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setWeekGames(Number(e.target.value))
            }}
            numberOptions={{minimumFractionDigits: 0, maximumFractionDigits: 0}}
          />
        </div>
      </div>
      <div className="flex flex-col text-sm my-5 divide-y">
        <div className="flex flex-row justify-between items-center mb-3">
          <span className="font-bold">Campionati</span>
          <div>
            <Button
              styleType="secondary"
              className="text-xxs h-6"
              onClick={() => {
                if (Object.keys(roleLevels).length > 0) {
                  setRoleLevels({})
                } else {
                  const val = dataContext.group === 'ref' ? ['ref'] : ['sp', 'crono', 'a24s']
                  setRoleLevels(levelOptions.reduce((acc, i) => ({...acc, [i.cod_level]: val}), {}))
                }
              }}
            >
              {Object.keys(roleLevels).length > 0 ? 'Rimuovi tutti' : 'Seleziona tutti'}
            </Button>
          </div>
        </div>
        {dataContext.group === 'ref'
          ? levelOptions.map(i => {
            const selected = (roleLevels[i.cod_level] ?? []).length
            return (
              <div key={i.cod_level} className="flex flex-row py-1 justify-between">
                <span>{i.des_level_extended}</span>
                <Checkbox
                  readOnly={false}
                  id={`check-${i.cod_level}`}
                  name={`check-${i.cod_level}`}
                  checked={selected}
                  classNames={''}
                  onChange={() => {
                    if (selected) {
                      const {[i.cod_level]: tmp, ...rest} = roleLevels;
                      setRoleLevels(rest)
                    } else {
                      setRoleLevels({...roleLevels, [i.cod_level]: ['ref']})
                    }
                  }}
                />
              </div>
            )
          })
          : levelOptions.map(i => {
            const selected = roleLevels[i.cod_level] ?? []
            return (
              <div key={i.cod_level} className="grid grid-cols-6 py-2">
                <span className="col-span-2">{i.des_level_extended}</span>
                <div className="flex flex-row gap-1.5 items-center">
                  <span className="text-xxs">Tutti</span>
                  <Checkbox
                    readOnly={false}
                    id={`all-${i.cod_level}`}
                    name={`all-${i.cod_level}`}
                    checked={selected.length === 3}
                    classNames={''}
                    onChange={() => {
                      if (selected.length === 3) {
                        const {[i.cod_level]: tmp, ...rest} = roleLevels;
                        setRoleLevels(rest)
                      } else {
                        setRoleLevels({...roleLevels, [i.cod_level]: ['sp', 'crono', 'a24s']})
                      }
                    }}
                  />
                </div>
                {['sp', 'crono', 'a24s'].map(level =>
                  <div className="flex flex-row gap-1.5 items-center justify-center">
                    <span className="text-xxs uppercase">{level}</span>
                    <Checkbox
                      readOnly={false}
                      id={`all-${i.cod_level}`}
                      name={`all-${i.cod_level}`}
                      checked={selected.includes(level)}
                      classNames={''}
                      onChange={() => {
                        if (selected.includes(level)) {
                          setRoleLevels({
                            ...roleLevels,
                            [i.cod_level]: roleLevels[i.cod_level].filter(x => x !== level)
                          })
                        } else {
                          setRoleLevels({...roleLevels, [i.cod_level]: (roleLevels[i.cod_level] ?? []).concat([level])})
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )
          })
        }
      </div>
    </div>
    <div className="mt-5 flex flex-row justify-between">
      <Button styleType="white" onClick={() => setSelectedMember(undefined)}>
        Esci
      </Button>
      <Button onClick={updateData} submitting={loading}>
        Salva
      </Button>
    </div>
  </Modal>
}
