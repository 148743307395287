import React, {useCallback, useContext, useState} from "react";

// Services
import AuthenticationService from "@services/AuthenticationService";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";

// Ui
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Input from "@ui-components/Input";

// Editor
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {EDITOR_TOOLBAR} from "@utils/constants";

import {UpdateInvitationDialogProps} from "@pages/Activities/Detail/Detail.type";
import {InvitationEmail} from "@services/types/activity";
import FilePicker from "@ui-components/FilePicker";
import {CloudArrowDownIcon, TrashIcon} from "@heroicons/react/24/outline";

export function UpdateInvitationDialog({onExit, activity, setActivity, viewMode}: UpdateInvitationDialogProps) {

  const {push} = useContext(NotificationsContext);
  const userData = AuthenticationService.getUserData();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<InvitationEmail | undefined>(activity.invitation_email);

  const [attachments, setAttachments] = useState({files: [], fileSizeExceeded: false});
  const [downloading, setDownloading] = useState<string | undefined>(undefined);

  const updateInvitation = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setLoading(true);

        let formData = new FormData();
        let newFileNames: string[] = [];
        if (attachments.files.length && !attachments.fileSizeExceeded) {
          attachments.files.forEach(elem => {// @ts-ignore
            formData.append("attachments", elem, elem.name);
            // @ts-ignore
            newFileNames.push(elem.name)
          })
        }

        if (formData.has('attachments')) {
          await api.post(`/activities/new-attachments/${activity.id}`, formData)
        }

        const payload = {
          ...activity,
          invitation_email: {...data, files: Array.from(new Set([...data?.files ?? [], ...newFileNames]))},
          id_added_user: userData.id,
          id_added_role: localStorage.getItem("refman_role")
        }

        const {data: res} = await api.put(`/activities/${activity.id}`, payload);
        setActivity(res)
        push({title: "Salvataggio avvenuto con successo", type: "success"});
        onExit();
      } catch (error) {
        push({title: "Errore del server", type: "error"});
      } finally {
        setLoading(false);
      }
    },
    [attachments, data] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const downloadAttachment = (i: string) => {
    setDownloading(i)
    api.post(`/aws-signed-url`, {
      prefix: `meetings/attachments/${activity.id}/${i}`,
      method: "GET",
    }).then(({data: url}) => {
      const link = document.createElement("a");
      link.href = url;
      link.click();
    }).catch(() => push({title: "Errore durante il download", type: "error"}))
      .finally(() => setDownloading(undefined))
  }


  return (
    <Modal
      maxWidth="w-full sm:max-w-4xl"
      onExit={onExit}
    >
      <div className="w-full flex flex-col">
        <h1 className="font-bold sm:text-xl mr-5 mb-5">
          {viewMode ? 'Convocazione' : 'Personalizza la convocazione'}
        </h1>
        <span className="font-bold text-sm">Oggetto</span>
        {viewMode ?
          <p className="mt-2 mb-5 bg-gray-100 p-2 rounded-lg">{data?.subject ?? ''}</p> :
          // @ts-ignore
          <Input
            required={true}
            value={data?.subject ?? ''}
            errorMessage="Inserisci l'oggetto della mail"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData({...data, subject: e.target.value})}
          />
        }
        <span className="font-bold text-sm mb-1">Messaggio</span>
        {viewMode ?
          <div className="mt-1 mb-5 bg-gray-100 p-2 rounded-lg"
               dangerouslySetInnerHTML={{__html: data?.content ?? ''}}/> :
          <div className="h-64">
            <CKEditor
              config={{toolbar: EDITOR_TOOLBAR}}
              // @ts-ignore
              editor={ClassicEditor}
              data={data?.content}
              onChange={(_, editor) => {
                // @ts-ignore
                setData({...data, content: editor.getData()})
              }}
              onReady={(editor) => {
                if (editor) {
                  // @ts-ignore
                  editor.editing.view.change(writer => writer.setStyle("height", "200px", editor.editing.view.document.getRoot()))
                }
              }}
            />
          </div>
        }
        {data?.files?.length ?
          <div className="flex flex-col gap-2 mb-5">
            <span className="font-bold text-sm">Allegati</span>
            {data.files.map(i => (
              <div className="flex flex-row gap-2 items-center" key={i}>
                {viewMode ? null :
                  <TrashIcon
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => setData({...data, files: (data?.files ?? []).filter(f => f !== i)})}
                  />
                }
                <span className="text-xs">{i}</span>
                <div>
                  <Button styleType="white" submitting={downloading === i} className="h-8">
                    <CloudArrowDownIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => downloadAttachment(i)}
                    />
                  </Button>
                </div>
              </div>
            ))
            }
          </div> : null
        }
        {viewMode ? null : <span className="font-bold text-sm my-1">Aggiungi allegati</span>}
        {viewMode ? null : <FilePicker
          fileChosen={attachments.files}
          setFileChosen={(values: any) => setAttachments({...attachments, files: values})}
          maxSize={2}
          alertSizeExceeded={(bool: boolean) => setAttachments({...attachments, fileSizeExceeded: bool})}
          labelCustomStyle={{color: 'black'}}
        />}
      </div>
      {viewMode ? null : <div className="mt-5 w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button onClick={updateInvitation} submitting={loading} disabled={!data?.subject || !data?.content}>
          Salva
        </Button>
      </div>}
    </Modal>
  );
}
