import React, {useContext, useEffect, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

// Ui
import Placeholder from "@ui-components/Placeholder";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

// Components
import {AssignListMembers} from "@components/RefMan/AssignListMembers";
import {getCardLabels} from "@pages/Planner/Absences/Absences.lib";

// Types
import {AssignMemberDialogPropsType} from "./Absences.type";
import {AbsenceBasic} from "@services/types/absence";


export default function AssignMemberDialog({onExit, userData, absenceReason, setReasons}: AssignMemberDialogPropsType) {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const {reason, timeList, period} = getCardLabels(absenceReason, intl);

  // @ts-ignore

  const [inserted, setInserted] = useState<number[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAbsences = () => {
      api
        .get(`/absences?id_absence_reason=${absenceReason.id}`)
        .then(({data}: { data: AbsenceBasic[] }) => {
          setInserted(data.map(i => i.id_fip_code))
        })
        .catch(() => {
          push({title: "Errore del server", type: "error"});
        });
    };

    if (inserted === undefined) {
      fetchAbsences();
    }

  }, [inserted]) // eslint-disable-line react-hooks/exhaustive-deps

  const saveUpdates = () => {
    setLoading(true)
    api.post("/absences/assign", {
        members: inserted,
        id_absence_reason: absenceReason.id,
        id_added_user: userData.id,
        id_added_role: localStorage.getItem("refman_role")
      })
      .then(() => {
        setReasons(undefined);
        push({title: "Dati salvati con successo", type: "success"});
        onExit();
      })
      .catch(() => {
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  const description = () => <div className="flex flex-col">
    <span className="font-bold sm:text-xl">Gestione tesserati</span>
    <span className="w-full text-xs sm:text-sm my-1">
      {reason}{timeList.filter(i => i).length ? `, ${timeList.join(' ')}` : ''}, {period.join(' ')}
    </span>
  </div>

  return (
    <Modal
      // maxWidth="w-full sm:max-w-3xl sm:min-w-3xl"
      maxWidth="sm:max-w-4xl"
      onExit={onExit}
    >
      {inserted ?
        <div>
          <AssignListMembers
            membersList={inserted}
            setMembersList={setInserted}
            description={description()}
          />
          <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
            <Button styleType="white" onClick={onExit}>
              Annulla
            </Button>
            <Button onClick={saveUpdates} submitting={loading}>
              Salva
            </Button>
          </div>
        </div> :
        <div className="mt-5 flex flex-col w-full mb-4 gap-2">
          <div className="flex flex-row gap-10">
            <Placeholder height="h-10" classNames="rounded-lg w-1/3"/>
            <Placeholder height="h-10" classNames="rounded-lg w-1/3"/>
            <Placeholder height="h-10" classNames="rounded-lg w-1/3"/>
          </div>
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-96" classNames="rounded-lg"/>
        </div>
      }
    </Modal>
  );
}
