import React, { useContext, useEffect, useState } from 'react';

// Services
import { NotificationsContext } from '@ui-components/Notifications';
import { api } from '@services/apiRequest';

// Data
import useMembers from '@services/hooks/useMembers';
import useLevels from '@services/hooks/useLevels';
import useTeams from '@services/hooks/useTeams';

// Components
import RefManList from '@components/RefMan/RefManList/RefManList';
import { MemberSearch } from '@pages/Members/Members.lib';
import { getListItems } from './Incompatibilities.lib';

// Ui
import { PlusIcon } from '@heroicons/react/24/outline';
import Placeholder from '@ui-components/Placeholder';
import { PageHeading } from '@ui-components/Container';
import Button from '@ui-components/Button';
import Alert from '@ui-components/Alert';
import Modal from '@ui-components/Modal';

// Types
import { Incompatibility } from './Incompatibilities.type';
import { AddIncompatibilityDialog} from "@pages/Nominations/Incompatibilities/AddIncompatibilityDialog";

export function NominationsIncompatibilities() {
  const { push } = useContext(NotificationsContext);
  const teams = useTeams();
  const levels = useLevels({ onlyManaged: true, asObj: false });
  const members = useMembers({ onlyActive: true, assigned: true, asObj: false });

  const [Incompatibilities, setIncompatibilities] = useState<Incompatibility[] | undefined>(undefined);
  const [filterMember, setFilterMember] = useState<string>();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteIncompatibility, setDeleteIncompatibility] = useState<Incompatibility | undefined>();
  const [offset, setOffset] = useState<number>(0);
  const [filtered, setFiltered] = useState<Incompatibility[] | []>([]);
  const limit = 10;

  useEffect(() => {
    const fetchData = () => {
      api
        .get('/incompatibilities')
        .then(({ data }) => {
          setIncompatibilities(data.reverse());
        })
        .catch(() => {
          push({ title: 'Errore del server', type: 'error' });
        });
    };

    if (Incompatibilities === undefined) {
      fetchData();
    }
  }, [Incompatibilities]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Incompatibilities) {
      if (filterMember) {
        setFiltered(Incompatibilities.filter((i) => i.member.member.toLowerCase().match(filterMember.toLowerCase())));
      } else {
        setFiltered(Incompatibilities);
      }
    } else {
      setFiltered([]);
    }
  }, [Incompatibilities, filterMember]);

  const sendDelete = () => {
    if (deleteIncompatibility) {
      setLoading(true);
      api
        .delete(`/incompatibilities/${deleteIncompatibility.id_fip_code}/${deleteIncompatibility.id_team}`)
        .then(() => {
          setIncompatibilities((Incompatibilities ?? []).filter((i) => i.id_fip_code !== deleteIncompatibility.id_fip_code || i.id_team !== deleteIncompatibility.id_team));
          push({ title: 'Elemento eliminato con successo', type: 'success' });
          setDeleteIncompatibility(undefined);
        })
        .catch(() => {
          push({ title: 'Errore del server', type: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <PageHeading title="Incompatibilità" historyBackPath='_back' />
      {Incompatibilities ? (
        <div className='my-5'>
          <div className='mb-5 flex flex-row justify-between gap-10 items-center'>
            <div className='w-full'>
              <MemberSearch label='Cerca tesserato' searchKey={filterMember} setSearchKey={setFilterMember} />
            </div>
            <div>
              <Button onClick={() => setShowAdd(true)}>
                <PlusIcon className='w-5 h-5 mr-3' /> Aggiungi
              </Button>
            </div>
          </div>
          {filtered.length > 0 ? (
            <div className='mb-10'>
              <RefManList
                offset={offset}
                setOffset={setOffset}
                limit={limit}
                total={filtered.length}
                containerId='observer-reports-list'
                items={getListItems(filtered, setDeleteIncompatibility)}
                rowClassNames={['sm:grid', 'sm:grid-cols-5', '!cursor-default']}
                pagination='frontend'
              />
            </div>
          ) : (
            <div className='my-10'>
              <Alert title='Nessuna incompatibilità registrata' />
            </div>
          )}
        </div>
      ) : (
        <div className='flex flex-col w-full my-10 gap-2'>
          <Placeholder height='h-12' classNames='rounded-lg' />
          {[...new Array(5)].map((each, i) => <Placeholder key={i} height='h-20' classNames='rounded-lg bg-am-600' />)}
        </div>
      )}
      {showAdd && members && levels && teams ? (
        <AddIncompatibilityDialog
          setShowAdd={setShowAdd}
          setIncompatibilities={setIncompatibilities}
          members={members}
          levels={levels}
          teams={teams}
        />
      ) : null}
      {deleteIncompatibility ? (
        <Modal onExit={() => setDeleteIncompatibility(undefined)}>
          <div className='m-5'>
            <h1 className='font-bold sm:text-xl'>Confermi di voler eliminare l'elemento?</h1>
            <h3 className='text-xs sm:text-sm my-3'>Non sarà possibile annullare l'operazione.</h3>
          </div>
          <div className='w-full flex flex-col sm:flex-row gap-3 justify-between'>
            <Button styleType='white' onClick={() => setDeleteIncompatibility(undefined)}>
              Annulla
            </Button>
            <Button onClick={sendDelete} submitting={loading}>
              Conferma
            </Button>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
