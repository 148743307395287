import React, {useContext, useEffect, useState} from "react";

// Services
import {DataContext} from "@services/DataContext/DataContext";

// Table
import {tableIcons} from "@ui-components/TableIcons";
import TableStyle from "@ui-components/TableStyle";
import MaterialTable from "@material-table/core";

import {COLUMNS, OPTIONS, LOCALIZATION} from "./services";
import {onRowUpdate} from "./services";

// Ui
import Placeholder from "@ui-components/Placeholder";


export default function EntryLevels({push, levels}) {

  const dataContext = useContext(DataContext);
  const [data, setData] = useState();

  useEffect(() => {
    if (levels) {
      setData(Object.values(levels))
    }
  }, [levels]);

  const _onRowUpdate = (newData, oldData) => onRowUpdate(newData, oldData, dataContext, push);
  return (
    <>
      {data ?
        <TableStyle>
          <MaterialTable
            columns={COLUMNS}
            data={data}
            icons={tableIcons}
            options={OPTIONS}
            localization={LOCALIZATION}
            editable={{
              onRowUpdate: _onRowUpdate
            }}
          />
        </TableStyle> :
        <div className="flex flex-col w-full mb-4 gap-2">
          <Placeholder height="h-10" classNames="rounded-lg bg-am-700"/>
          <Placeholder height="h-96" classNames="rounded-lg"/>
        </div>
      }
    </>
  );
}
