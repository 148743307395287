import {api} from '@services/apiRequest'
import {ReportItemType, ReportType} from "@components/RefMan/Reports/ReportsList/ObserverReportsList.type";
import {NominationType} from "../reports.type"
import {EvaluationsResultsDataType} from "@components/RefMan/Reports/ReportsMatch/ObserverReportsMatch.type";

/**
 * @function getMembersToEvaluate
 *
 * @param {ReportType | null} reportData
 * @return {NominationType[]} List of nomination to evaluate
 */
export const getMembersToEvaluate = (reportData: ReportItemType | null): NominationType[] => {

  if (!reportData?.member?.category?.cod_group) {
    return [];
  }

  if (!reportData?.game?.nominations && reportData.game.nominations.length > 0) {
    return [];
  }

  let roleToEvaluate: string[];

  if (reportData.member.category.cod_group.search(/ref/gi) !== -1) {
    roleToEvaluate = ['ref1', 'ref2'];
  } else if (reportData.member.category.cod_group.search(/udc/gi) !== -1) {
    roleToEvaluate = ['sp', 'crono', 'a24s'];
  } else {
    return [];
  }

  return reportData.game.nominations.map((nomination: NominationType) =>
    (roleToEvaluate.findIndex((role: string) => role.toLowerCase() === nomination.role.toLowerCase()) !== -1) ?
      // @ts-ignore
      nomination : null).filter((nomination: NominationType) => nomination !== null)
}


/**
 * @function getEvaluationsResultsData
 *
 * @param {string} codeGame Code of game
 * @param {number} idObserver Id of current observer
 */
export const getEvaluationsResultsData = async (codeGame: string, idObserver: number): Promise<EvaluationsResultsDataType[]> => {
  try {
    const {data} = await api.get(`/evaluations?cod_game=${codeGame}&id_observer=${idObserver}`)
    return data
  } catch (error) {
    console.error('Error retrieving reports by game and observer.', error)
    return []
  }
}