import React, {useContext, useEffect, useState} from 'react';

import {NotificationsContext} from '@ui-components/Notifications';
import {useIntl} from 'react-intl';

// Model & Hooks
import {EliteAlgorithmGamesProps, EliteAlgorithmTaskSchema, UserKwargsSchema} from './Algorithms.types';
import useEliteAlgorithmGames from '@services/hooks/useEliteAlgorithmGames';
import {GamesSchema} from '@pages/Games/Games.type';

// Components
import GeneralParametersModal from "@pages/Nominations/Algorithms/Components/GeneralParametersModal";
import GameParametersModal from "@pages/Nominations/Algorithms/Components/GameParametersModal";
import {AdjustmentsHorizontalIcon, InboxArrowDownIcon} from "@heroicons/react/24/outline";
import {getListItems} from "@pages/Nominations/Algorithms/Components/GameList";
import RefManList from '@components/RefMan/RefManList/RefManList';
import Placeholder from '@ui-components/Placeholder';
import Button from '@ui-components/Button';
import Alert from '@ui-components/Alert';

// Services
import saveEliteAlgorithmConstraints, {
  fetchEliteAlgorithmStatus,
  algoDownloadExcel,
  getInitUserKwargs,
  runEliteAlgorithm,
  CostTotalKpi
} from "@pages/Nominations/Algorithms/EliteAlgorithm.services";
import {DistancesDict} from "@services/types/city";
import {RelocationSchema} from "@pages/Planner/Calendar/Calendar.type";


function EliteAlgorithmGames(
  {
    designator_level,
    des_round,
    group,
    sortBy
  }: EliteAlgorithmGamesProps) {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const [offset, setOffset] = useState<number>(0);
  const [toSave, setToSave] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [running, setRunning] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  const {games, distances, relocations}: {
    games: GamesSchema[] | undefined,
    distances: DistancesDict | undefined,
    relocations: RelocationSchema | undefined
  } = useEliteAlgorithmGames(designator_level, des_round, group, setLoading);

  const [userKwargs, setUserKwargs] = useState<UserKwargsSchema>();
  const [taskData, setTaskData] = useState<EliteAlgorithmTaskSchema>();

  const [showGeneralParameters, setShowGeneralParameters] = useState<boolean>(false);
  const [showGameParameters, setShowGameParameters] = useState<string | undefined>(undefined);

  const sortedGames = games?.length ? sortBy === 'game'
    ? [...games].sort((a, b) => a.game_number - b.game_number)
    : games : undefined


  useEffect(() => {
    if (taskData && !fetching) {
      setUserKwargs(getInitUserKwargs(taskData.jsn_input_data))
    }
  }, [taskData]);

  useEffect(() => {
    setToSave(true);
  }, [designator_level, des_round]);

  useEffect(() => {   // Checks if the algorithm is already running and get taskData
    if (designator_level && des_round && !saving) {
      fetchEliteAlgorithmStatus(designator_level, des_round, push, intl, setFetching, false, setTaskData);
    }
  }, [designator_level, des_round, saving])

  useEffect(() => {   // If the algorithm is running, activates polling mode to refresh page when it finishes
    if (fetching) {
      fetchEliteAlgorithmStatus(designator_level, des_round, push, intl, setFetching, true, setTaskData);
    }
  }, [fetching])

  const saveParameters = () => {
    if (userKwargs) {
      setSaving(true)
      saveEliteAlgorithmConstraints(designator_level, des_round, userKwargs, push)
        .then(() => setToSave(false))
        .finally(() => setSaving(false))
    }
  }

  return (
    <div className='mb-8'>
      {games && distances && relocations && sortedGames && taskData && userKwargs && !loading ?
        <>
          <div className="flex flex-col-reverse sm:flex-row justify-between items-center mb-4 gap-3">
            <div>
              <Button styleType="white" className="text-xs h-8" onClick={() => setShowGeneralParameters(true)}>
                <AdjustmentsHorizontalIcon className="w-5 inline-block mr-1"/>
                Parametri
              </Button>
            </div>
            {
              fetching ? <div className="w-full sm:w-64"><Placeholder height="h-8" classNames="rounded-full"/></div>
                : taskData.cod_status === 'END' || (taskData.cod_status === 'ACCEPTED' && taskData.jsn_result?.solution_by_game)
                  ? <CostTotalKpi intl={intl} games={taskData.jsn_result?.solution_by_game}/>
                  : null
            }
            <div className='flex flex-row gap-x-2 justify-center items-center sm:justify-end'>
              {fetching ?
                <Alert title="Algoritmo in esecuzione" type='warning' slim/>
                : taskData?.cod_status === 'ERROR'
                  ? <Alert title="Algoritmo fallito" type='error' slim/>
                  : taskData.cod_status === 'END' || (taskData.cod_status === 'ACCEPTED' && taskData.jsn_result?.solution_by_game)
                    ? <Alert title="Algoritmo eseguito" type="success" slim/>
                    : null
              }
              <Button
                submitting={saving}
                className="text-xs h-8"
                disabled={fetching || !toSave}
                onClick={saveParameters}
              >
                Salva
              </Button>
              <Button
                submitting={running}
                className="text-xs h-8"
                disabled={fetching || toSave}
                onClick={() => runEliteAlgorithm(
                  designator_level,
                  des_round,
                  group,
                  push,
                  intl,
                  setRunning,
                  setFetching,
                )}
              >
                Run
              </Button>
              <div
                className='mx-2 cursor-pointer'
                onClick={() => algoDownloadExcel(sortedGames, group, taskData?.jsn_result, intl, `Turno ${designator_level} ${des_round}`)}
              >
                <InboxArrowDownIcon className='w-7'/>
              </div>
            </div>
          </div>
          <RefManList
            containerId="elite-algorithm-games"
            items={getListItems(
              intl,
              sortedGames,
              distances,
              relocations,
              group,
              userKwargs,
              taskData,
              fetching,
              setUserKwargs,
              setToSave,
              setShowGameParameters,
              'elite'
            )}
            limit={20}
            offset={offset}
            setOffset={setOffset}
            total={games.length}
            rowClassNames={['sm:grid', 'sm:grid-cols-10', 'sm:gap-5', '!cursor-default']}
          />
          {showGameParameters ?
            <GameParametersModal
              intl={intl}
              group={group}
              distances={distances}
              relocations={relocations}
              game={games.filter(i => i.cod_game == showGameParameters)[0]}
              userKwargs={userKwargs}
              setUserKwargs={setUserKwargs}
              setShowGameParameters={setShowGameParameters}
              saveParameters={saveParameters}
              setToSave={setToSave}
            />
            : null
          }
          {showGeneralParameters ?
            <GeneralParametersModal
              group={group}
              games={games}
              setShowGeneralParameters={setShowGeneralParameters}
              userKwargs={userKwargs}
              setUserKwargs={setUserKwargs}
              saveParameters={saveParameters}
              setToSave={setToSave}
            />
            : null
          }
        </>
        :
        [...new Array(5)].map((_, index) =>
          <Placeholder key={index} height="h-32" classNames="rounded-lg bg-am-600 my-3"/>
        )
      }
    </div>
  );
}

export default EliteAlgorithmGames;