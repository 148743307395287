import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const THEME = createTheme({
  palette: {},
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: "none",
        backgroundColor: "#f5f5f5",
      },
    },
    MuiTable: {
      root: {
        padding: "0",
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: "#00475C",
        borderRadius: 0,
        textTransform: "uppercase",
        color: "white",
      },
    },
    MuiInputBase: {
      root: {
        border: "1px solid #00475C",
        borderRadius: "0.5rem",
        padding: "0 3px",
        overflow: "hidden",
        "&&&:before": { borderBottom: "0 !important", content: "none" },
        "&&&:after": { borderBottom: "0 !important", content: "none" },
        "&:focus": { border: "0", outlineOffset: "0", boxShadow: "none" },
      },
      inputTypeSearch: {
        "&:focus": { border: "0", outlineOffset: "0", boxShadow: "none" },
      },
      input: {
        "&:focus": { border: "0", outlineOffset: "0", boxShadow: "none" },
      },
    },
    MuiInputAdornment: {
      root: {
        marginLeft: "4px",
      },
    },
    MuiTableRow: {
      root: {
        border: "none",
        "&:hover": { backgroundColor: "#ffffff" },
      },
    },
    MuiFormControl: {
      root: {
        "&&&:before": { borderBottom: "none" },
        "&&:after": { borderBottom: "none" },
        // padding: "0.5em 0em !important",
      },
    },
    MuiFormControlLabel: {
      root: {
        "&$root": {
          marginLeft: 6
        }
      }
    },
    MuiCheckbox: {
      root: {
        marginLeft: 6
      },
      colorSecondary: {
        color: "#00475C",
        "&$checked": {
          color: "#00475C"
        }
      },
    },
    MuiTableCell: {
      root: {
        border: "none",
        padding: "0.6em 1em !important",
        fontSize: "13px !important",
        textAlign: "left !important"
      },
      head: {
        backgroundColor: "#00475C !important",
        padding: "1em 1em !important",
        fontWeight: "bold",
        border: "none",
        borderRadius: 0,
        color: "#f5f5f5",
      },
    },
    MuiTableSortLabel: {
      root: {
        "&:hover": { color: "#f5f5f5" },
      },
      active: {
        color: "#f5f5f5 !important"
      },
      icon: {
        color: "#f5f5f5 !important"
      }
    },
    MuiIconButton: {
      root: {
        padding: "5px"
      }
    }
  },
});

const TableStyle = ({ children }) => {
  return (
    <div className="rounded-xl overflow-hidden mb-5">
      <ThemeProvider theme={THEME}>{children}</ThemeProvider>
    </div>
  );
};

export default TableStyle;
