import React from "react";

import {CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import Tooltip from "@ui-components/Tooltip";
import Alert from "@ui-components/Alert";

import {MemberAnswerCardProps} from "@pages/Activities/Detail/Detail.type";
import {PhysicalTestResult} from "@services/types/activity";


export const attendanceOptions = [
  {value: '-', name: 'Registrare presenza'},
  {value: 'PRESENT', name: 'Presente'},
  {value: 'ABSENT', name: 'Assente'},
  {value: 'JUSTIFIED', name: 'Assente giustificato'},
];

export const answerOptions = [
  {value: '-', name: 'Comunicare risposta'},
  {value: true, name: 'Sarò presente'},
  {value: false, name: 'Non potrò partecipare'}
];

export const physicalTestOptions = [
  {value: '-', name: 'Risultato Test Fisico'},
  {value: 'PASSED', name: 'Superato'},
  {value: 'PARTIAL', name: 'Superato parzialmente'},
  {value: 'FAILED', name: 'Non superato'},
]


export const getMemberAnswer = (flg_accepted: boolean | undefined, reason_decline: string | undefined) => {
  const commonStyle = 'flex flex-row gap-1 border px-1 py-0.5 rounded-lg'
  switch (flg_accepted) {
    case true:
      return (
        <div>
          <div className={`${commonStyle} border-green-800 text-green-800 bg-green-200 bg-opacity-20 items-center`}>
            <div><CheckCircleIcon className="h-4 w-4"/></div>
            <span className="text-xxs">Presente</span>
          </div>
        </div>
      )
    case false:
      return (
        <div className="flex flex-row items-center gap-1 whitespace-normal">
          <div className={`${commonStyle} border-red-800 text-red-800 bg-red-200 bg-opacity-20 items-center`}>
            <div><XCircleIcon className="h-4 w-4"/></div>
            <span className="text-xxs">Assente</span>
          </div>
          <Tooltip
            placement="bottom"
            text={reason_decline}
            customStyle="text-xs normal-case"
            size="small"
            iconName="InformationCircleIcon"
          />
        </div>
      )
    default:
      return (
        <div>
          <div className={`${commonStyle} border-am-800 text-am-800 bg-am-200 bg-opacity-20 items-center`}>
            <div><QuestionMarkCircleIcon className="h-4 w-4"/></div>
            <span className="text-xxs">In attesa</span>
          </div>
        </div>
      )
  }
}

export const getMemberAttendance = (attendance: string | null) => {
  switch (attendance) {
    case 'PRESENT':
      return <span className="text-md font-bold text-green-800 items-center">Presente</span>
    case 'ABSENT':
      return <span className="text-md font-bold text-red-800 items-center">Assente</span>
    case 'JUSTIFIED':
      return <span className="text-md font-bold text-yellow-500 items-center">Assente giustificato</span>
    default:
      return <span className="text-xs text-gray-300 items-center">Registrare presenza</span>
  }
}

export const getMemberPhysicalTest = (physical_test_result: PhysicalTestResult) => {
  switch (physical_test_result) {
    case 'PASSED':
      return <span className="text-md font-bold text-green-800 items-center">T.F. superato</span>
    case 'FAILED':
      return <span className="text-md font-bold text-red-800 items-center">T.F. non superato</span>
    case 'PARTIAL':
      return <span className="text-md font-bold text-yellow-500 items-center">T.F. superato parzialmente</span>
    default:
      return <span className="text-xs text-gray-300 items-center">Registrare esito test fisico</span>
  }
}

export const getKpi = (title: string, value: number | string) => (
  <span className="border-l-4 pl-3 mb-2 whitespace-nowrap text-lg">{title} <b>{value}</b></span>
)

export function MemberAnswerCard(
  {
    originalAnswer,
    originalReason,
    isHome,
    isTeacher
  }: MemberAnswerCardProps) {

  const basicStyle = `p-2 border rounded-lg max-w-xs flex flex-row gap-3 justify-center ${isHome ? 'text-xxs' : ''}`
  const answerYesStyle = 'border-green-800 text-green-800 bg-green-200 bg-opacity-20'
  const answerNoStyle = 'border-red-800 text-red-800 bg-red-200 bg-opacity-20'

  return (
    <div>
      {isTeacher ? <div className="text-md font-bold text-am-700 items-center">Formatore</div>
        : originalAnswer === undefined || originalAnswer === null
          ? <div className="max-w-xs"><Alert type="warning" title="Mancata risposta" extraSlim={isHome}/></div> :
          <div
            className={`${basicStyle} ${originalAnswer ? answerYesStyle : answerNoStyle}`}>
            <span>{originalAnswer ? 'Partecipazione confermata' : 'Comunicata assenza'}</span>
            {originalReason ?
              <Tooltip
                placement="bottom"
                text={originalReason}
                customStyle="text-xs normal-case"
                size="small"
                iconName="InformationCircleIcon"
              /> : null
            }
          </div>
      }
    </div>
  )
}