import React, {useContext, useEffect, useState} from 'react';

// Services
import AuthenticationService from "@services/AuthenticationService";
import {dateFormat} from "@pages/Planner/Absences/Absences.lib";
import {DataContext} from "@services/DataContext/DataContext";
import {useIntl} from "react-intl";

// Components
import CreateUpdateBasicSessionModal from "@pages/Nominations/Algorithms/Components/CreateUpdateBasicSessionModal";
import {PlusCircleIcon} from "@heroicons/react/24/outline";
import {AlgoBasicPlaceholder} from "@utils/placeholders";
import SearchSelect from "@ui-components/SearchSelect";
import GroupSelection from "@utils/groupSelection";
import Button from "@ui-components/Button";

// Hooks
import useBasicTasks from "@services/hooks/useBasicTasks";
import useMembers from "@services/hooks/useMembers";

// Types
import {BasicAlgorithmTaskSchema, SelectedSession} from "@pages/Nominations/Algorithms/Algorithms.types";
import {Member} from "@services/types/member";
import {useHistory} from "react-router-dom";

function BasicAlgorithm() {

  const intl = useIntl();
  const history = useHistory();
  const groups = AuthenticationService.getUserGroups();
  const dataContext: any = useContext(DataContext);
  const tasks: BasicAlgorithmTaskSchema[] | undefined = useBasicTasks(dataContext.group);
  const [selectedSession, setSelectedSession] = useState<SelectedSession | undefined>(undefined)
  const nominators: Member[] = useMembers({assigned: false, byUserId: true});
  const [showCreateSession, setShowCreateSession] = useState<boolean>(false);

  useEffect(() => {
    setSelectedSession(undefined)
  }, [dataContext.group]);

  return (
    <>
      {tasks && nominators ?
        <div className="mb-5">
          <div className="flex flex-col justify-between items-center">
            {groups.length > 1 ?
              <div className="w-full flex justify-center mr-6">
                <GroupSelection
                  selectedGroup={dataContext.group}
                  setSelectedGroup={dataContext.setGroup}
                />
              </div> : null
            }
            <div className="w-full mb-4">
              <SearchSelect
                label='Sessioni'
                options={tasks.map((i) => ({
                  label: (
                    <div className='flex flex-col'>
                      <span className='text-xs'>Sessione {i.id}</span>
                      <span>
                        Creata da <b>{nominators[i.created_by]}</b> il <i>{intl.formatDate(i.tms_created_at, dateFormat)}</i>
                      </span>
                    </div>
                  ),
                  value: i.id,
                  data: i,
                }))}
                filterOption={(candidate: { data: SelectedSession }, input: string) => {
                  if (input) {
                    const _data = candidate.data.data;
                    const _label = _data.created_by + _data.tms_created_at;
                    return _label.toUpperCase().includes(input.toUpperCase());
                  }
                  return true;
                }}
                isMulti={false}
                value={selectedSession ?? null}
                onChange={(e: SelectedSession) => {
                  setSelectedSession(e);
                  history.push(`/nominations/algorithms/basic/${e.value}`)
                }}
                loadOptions={undefined}
                CustomOption={undefined}
                minHeight={50}
              />
            </div>
            {selectedSession ? null :
              <div className="w-full flex justify-center">
                <Button styleType="white" onClick={() => setShowCreateSession(true)}>
                  <PlusCircleIcon className="mr-1 w-5 inline-block"/>Nuova sessione
                </Button>
              </div>
            }
          </div>
        </div>
        : <AlgoBasicPlaceholder/>
      }
      {showCreateSession ?
        <CreateUpdateBasicSessionModal
          group={dataContext.group.toUpperCase()}
          setShowCreateSession={setShowCreateSession}
        />
        : null
      }
    </>
  )
}

export default BasicAlgorithm;