import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, getXYChart} from "@components/Charts/commons";
import {sumProperties} from "@pages/Economics/Economics.lib";


const LKP_STATUS = {
  amt_game_tax: 'Tasse gara',
  amt_total_fee: 'Gettoni',
  amt_total_trip: 'Trasferte'
}

const COLORS = {
  amt_game_tax: am5.color("rgb(0, 60, 80)"),
  amt_total_trip: am5.color("rgb(222,99,54)"),
  amt_total_fee: am5.color("rgb(222,164,54)"),
  empty: am5.color("rgb(146,148,150)"),
}

const getChartHigh = (n) => {
  switch (true) {
    case (n < 6):
      return 'h-48'
    case (n < 9):
      return 'h-64'
    case (n < 12):
      return 'h-96'
    default:
      return 'h-[30rem]'
  }
}

function EconomicLevelChart({id, data, intl}) {

  useLayoutEffect(
    () => {

      if (!data)
        return

      const _data = data.map(i => ({
        ...i,
        amt_game_tax: i.amt_game_tax * 2,
        amt_total_fee: sumProperties(i, ['amt_fee_ref', 'amt_fee_udc', 'amt_fee_obs']),
        amt_total_trip: sumProperties(i, ['amt_trip_ref', 'amt_trip_udc', 'amt_trip_obs']),
        margin: 1 - (sumProperties(i, ['amt_fee_ref', 'amt_fee_udc', 'amt_fee_obs', 'amt_trip_ref', 'amt_trip_udc', 'amt_trip_obs']) / (i.amt_game_tax * 2))
      }))

      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)])
      root.locale = am5locales_it_IT;
      root.numberFormatter.set("numberFormat", "#,###.");
      root.dateFormatter.set("dateFormat", "MMM yyyy")

      // Create chart
      let chart = getXYChart(root, {layout: root.verticalLayout})
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);

      // Create axes
      let yAxis = chart.yAxes.push(
        chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "des_level",
            renderer: am5xy.AxisRendererY.new(root, {
              minGridDistance: 0
            }),
          })
        ))

      yAxis.data.setAll(data);

      let yRenderer = yAxis.get("renderer");
      yRenderer.labels.template.set("fontSize", 9);
      yRenderer.labels.template.set("fontWeight", '400');

      let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.2,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererX.new(root, {}),
        calculateTotals: true
      }))
      let xRenderer = xAxis.get("renderer");
      xRenderer.grid.template.set("forceHidden", true);
      xRenderer.labels.template.set("forceHidden", true);


      // Add series
      function makeSeries(name) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: LKP_STATUS[name],
          xAxis: xAxis,
          yAxis: yAxis,
          fill: COLORS[name],
          stroke: am5.color("rgb(255,255,255)"),
          valueXField: name,
          categoryYField: "des_level",
          stacked: name === 'amt_total_trip' || name === 'margin',
          tooltip: name !== 'amt_game_tax' ? null : am5.Tooltip.new(root, {
            labelText: "",
            tooltipY: am5.percent(10),
            fontSize: 8
          })
        }))

        if (name === 'amt_game_tax') {
          series.get("tooltip").adapters.add("labelText", function (text, target) {
            const dataItem = target.dataItem
            let newText = ''
            if (dataItem) {
              const fee_and_trip = intl.formatNumber(dataItem.dataContext.amt_total_fee + dataItem.dataContext.amt_total_trip)
              const tax = intl.formatNumber(dataItem.dataContext.amt_game_tax)
              const trip = intl.formatNumber(dataItem.dataContext.amt_total_trip)
              const margin = intl.formatNumber(dataItem.dataContext.margin, {style: 'percent', maximumFractionDigits: 0})
              newText = `[bold]${dataItem.dataContext.des_level}[/]\n` +
                `Gare: [bold]${dataItem.dataContext.num_games_calendar}[/]\n` +
                `Tasse gara: [bold]${tax}€[/]\n` +
                `Totale spese: [bold]${fee_and_trip}€[/]\n` +
                `Trasferte: [bold]${trip}€[/]\n` +
                `Margine: [bold]${margin}[/]`
            }
            return newText
          })
        }


        series.columns.template.setAll({
          fillOpacity: 0.65,
          visible: name !== 'margin',
          cornerRadiusTL: 2,
          cornerRadiusTR: 2,
          cornerRadiusBL: 2,
          cornerRadiusBR: 2,
        });

        if (name === 'margin') {
          series.bullets.push(function (target, series, dataItem) {
            const margin = intl.formatNumber(dataItem.dataContext.margin, {style: 'percent', maximumFractionDigits: 0})
            return am5.Bullet.new(root, {
              locationY: 1,
              locationX: 0,
              sprite: am5.Label.new(root, {
                text: `[#35b00 fontWeight: 800]${margin}[/]`,
                centerX: am5.p0,
                centerY: am5.percent(50),
                populateText: true,
                fontSize: 12
              })
            });
          });
        }

        series.data.setAll(_data);
        series.appear(1000);

        return series
      }

      makeSeries('amt_total_fee')
      makeSeries('amt_total_trip')
      makeSeries('amt_game_tax')
      makeSeries('margin')


      return () => {
        root.dispose()
      }
    },
    [id, data] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return <div id={id} className={`w-full ${getChartHigh(data.length)}`}/>
}

export default React.memo(EconomicLevelChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
