import {ObserverReportsFormRadioType} from "./ObserverReportsFormRadio.type";
import React, {useEffect, useState} from "react";
import {
  handleRadioOnChange
} from "@components/RefMan/Reports/ReportsForm/ReportsFormRadio/ObserverReportsFormRadio.lib";
import {QuestionItemType} from "@utils/questionnaires/type/questionnaire.type";

const ObserverReportsFormRadio = ({
                                    data,
                                    itemId,
                                    sectionId,
                                    results,
                                    setResults,
                                    setIsFormChanged,
                                  }: ObserverReportsFormRadioType) => {
  const [radioData, setRadioData] = useState<QuestionItemType>();

  useEffect(() => {
    setRadioData(
      results?.[data.id] ?
        {
          ...data,
          ...{value: `radio-${sectionId.toString()}-${itemId.toString()}-${results[data.id].value}`}
        } :
        data
    )
  }, [data, results])

  return <div className="observerreportsformradio__container">
    <div className="observerreportsformradio__header border-b border-gray-200 pb-4">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {`${data.title} `}
        <span className="text-red-600">
          {(data.isRequired === undefined || data.isRequired) && "*"}
        </span>
      </h3>
    </div>
    <div className="observerreportsformradio__body pt-2">
      <fieldset className="pt-3">
        <div className="flex flex-col md:flex-row gap-5">
          {radioData?.options && radioData.options.map((option, index) =>
            <div key={index} className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id={`radio-${sectionId.toString()}-${itemId.toString()}-${index.toString()}`}
                  aria-describedby={`description-${index.toString()}`}
                  name={`radio-${sectionId.toString()}-${itemId.toString()}-${index.toString()}`}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-am-600 focus:ring-am-600 cursor-pointer"
                  onChange={(e) => {
                    handleRadioOnChange(e, data, results, setResults, sectionId)
                    setIsFormChanged(true);
                  }}
                  checked={`radio-${sectionId.toString()}-${itemId.toString()}-${index.toString()}` === radioData.value}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label
                  htmlFor={`radio-${sectionId.toString()}-${itemId.toString()}-${index.toString()}`}
                  className="font-medium text-gray-900  cursor-pointer"
                >
                  {option}
                </label>
              </div>
            </div>
          )}
        </div>
      </fieldset>
    </div>
  </div>
}

export default ObserverReportsFormRadio