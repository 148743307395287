import React from "react";
import {QuizSessionQuestionSchema} from "@components/RefMan/Quiz/types";


interface SessionQuestionProps {
  questionsList: QuizSessionQuestionSchema[],
  setQuestionsList: React.Dispatch<React.SetStateAction<QuizSessionQuestionSchema[]>>,
  activeIdQuestion: number
}

export function SessionQuestion({questionsList, setQuestionsList, activeIdQuestion}: SessionQuestionProps) {

  const activeQuestion = questionsList.find((x) => x.id_question === activeIdQuestion);

  const updateQuestionAnswer = (newDesAns: string[]) => {
    const updatedList = questionsList.map(question => {
      if (question.id_question === activeIdQuestion) {

        return {...question, des_answers: newDesAns[0] === question.des_answers[0] ? [] : newDesAns};
      }
      return question;
    });
    setQuestionsList(updatedList);
  }

  if (!activeQuestion)
    return <></>

  return <div className="text-center">
    <h2 className="text-3xl font-bold text-gray-800">
      Domanda {questionsList.findIndex((el) => el.id_question === activeIdQuestion) + 1}
    </h2>
    <div className="min-w-700">
      <p className="text-xl my-8 min-w-1000">{activeQuestion.question.des_question}</p>
    </div>
    <div className="grid grid-cols-1 gap-4 mb-4">
      {activeQuestion.question.des_answers.map((ans) => (
        <button
          key={ans}
          className={`flex items-center justify-center w-full px-4 py-4 font-bold leading-6 uppercase duration-100 
          transform border-2 rounded-3xl cursor-pointer border-am-600 focus:outline-none sm:w-auto sm:px-6 border-text 
          hover:shadow-lg hover:-translate-y-1 ${activeQuestion.des_answers[0] === ans ? "bg-am-700 text-white" : ""}`}
          onClick={() => updateQuestionAnswer([ans])}
        >
          {ans}
        </button>
      ))}
    </div>
  </div>
}