import React from "react";
import {useHistory} from "react-router-dom";

import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Alert from "@ui-components/Alert";

import {useQuiz} from "@components/RefMan/Quiz/hooks/useQuiz";
import {DeleteQuizProps} from "@components/RefMan/Quiz/types";


export function QuizDeleteModal({push, onExit, idQuiz}: DeleteQuizProps) {

  const {deleteQuiz, loading} = useQuiz();

  const history = useHistory();

  return (
    <Modal onExit={onExit}>
      <h1 className="font-bold sm:text-xl">Confermi di voler eliminare il quiz?</h1>
      <div className="my-8">
        <Alert type="warning" title="Non sarà possibile annullare l'operazione"/>
      </div>

      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button
          onClick={() => deleteQuiz(idQuiz)
            .then(() => {
              onExit();
              push({title: "Quiz eliminato correttamente", type: "success"});
              history.push(`/technical-area/quiz`)
            })
            .catch(() => push({title: "Errore imprevisto", type: "success"}))
          }
          submitting={loading}>
          Conferma
        </Button>
      </div>
    </Modal>
  );
}
