import React, {useContext, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications"
import {getMemberOptions} from "@utils/index";
import {api} from "@services/apiRequest";


// Ui
import SearchSelect from "@ui-components/SearchSelect";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

// Types
import {SelectedLevel} from "@pages/Games/Games.type";
import {AddIncompatibilityDialogType} from "./Incompatibilities.type";
import {LevelSchema} from "@services/types/level";


export function AddIncompatibilityDialog(
  {
    setShowAdd,
    setIncompatibilities,
    members,
    levels,
    teams
  }: AddIncompatibilityDialogType) {

  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState<boolean>(false);

  const memberOptions = getMemberOptions(members)

  const [selectedMember, setSelectedMember] = useState<{ value: number; data: any } | undefined>(undefined)
  const [selectedLevel, setSelectedLevel] = useState<SelectedLevel | undefined>(undefined);
  const [selectedTeam, setSelectedTeam] = useState<{ value: number; label: string } | null>(null);

  const exitAdd = () => {
    setShowAdd(false)
    setSelectedLevel(undefined)
    setSelectedMember(undefined)
  }

  const addIncompatibility = () => {
    if (selectedMember && selectedTeam) {
      setLoading(true)
      api.post(`/incompatibilities`, {id_fip_code: selectedMember.value, id_team: selectedTeam.value})
        .then(({data}) => {
          setIncompatibilities((prev) => [data, ...(prev ?? [])])
          push({title: "Elemento aggiunto", type: "success"})
          exitAdd()
        })
        .catch((err) => {
          if (err.response.status === 400) {
            push({title: "Incompatibilità già inserito per questa squadra", type: "error"});
          } else {
            push({title: "Errore del server", type: "error"});
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <Modal onExit={exitAdd}>
      <div className="my-5">
        <h1 className="font-bold sm:text-xl">
          Aggiungi incompatibilità
        </h1>
        <div className="flex flex-col gap-2 my-6">
          {/*@ts-ignore*/}
          <SearchSelect
            label="Tesserato"
            minHeight={50}
            value={selectedMember}
            onChange={(e: any) => setSelectedMember(e)}
            options={memberOptions}
            filterOption={(candidate: { data: { data: any; }; }, input: string) => {
              if (input) {
                const _data = candidate.data.data
                const _label = _data.id_fip_code + _data.member
                return _label.toUpperCase().includes(input.toUpperCase())
              }
              return true
            }}
          />
          {/*@ts-ignore*/}
          <SearchSelect
            label="Campionato"
            options={levels.map((i: LevelSchema) => ({label: i.des_level_extended, value: i.cod_level}))}
            value={selectedLevel}
            onChange={(e: SelectedLevel) => {
              setSelectedTeam(null);
              setSelectedLevel(e);
            }}
          />
          {selectedLevel ?
            // @ts-ignore
            <SearchSelect
              label="Società"
              options={teams
                .filter((i: any) => i.cod_level === selectedLevel?.value)
                .map((i: any) => ({label: i.team_alias, value: i.id}))
              }
              value={selectedTeam}
              onChange={(e: { value: number; label: string }) => {
                setSelectedTeam(e);
              }}
            /> : null
          }
        </div>
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={exitAdd}>
          Annulla
        </Button>
        <Button onClick={addIncompatibility} submitting={loading} disabled={!selectedMember || !selectedTeam}>
          Aggiungi
        </Button>
      </div>
    </Modal>
  );
}
