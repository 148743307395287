import {type StepDataType} from '@components/RefMan/Reports/ReportsIndex/ObserverReportsIndex.type'
import {ChevronRightIcon, HomeIcon} from '@heroicons/react/24/outline'
import {type ObserverReportsStepType} from '@components/RefMan/Reports/ReportsStep/ObserverReportsStep.type'
import React from 'react'
import {getStepTextClass} from "@components/RefMan/Reports/ReportsList/ObserverReportsList.lib";

const ObserverReportsStep = ({
                               stepData,
                               stepId,
                               setStepId,
                               reportData,
                               memberData
                             }: ObserverReportsStepType): React.JSX.Element => {
  return <nav className="flex" aria-label="Breadcrumb">
    <ol role="list" className="flex items-center space-x-4">
      {stepData.map((currentStep: StepDataType, index: number) => !currentStep.hidden && (
        <li key={index}>
          <div className="flex items-center">
            {index === 0
              ? <HomeIcon className="h-7 w-7 flex-shrink-0 text-gray-400" aria-hidden="true"/>
              : <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>}
            <div
              className={getStepTextClass(index, currentStep.id, stepId, reportData !== null, memberData !== undefined)}
              onClick={() => {
                if (
                  index === 0 ||
                  (index === 1 && reportData) ||
                  (index === 2 && reportData && memberData)
                ) {
                  setStepId(currentStep.id)
                }
              }}
            >
              {currentStep.name}
            </div>
          </div>
        </li>
      ))}
    </ol>
  </nav>
}

export default ObserverReportsStep
