import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {getPieChart, addLicense, STATUS_COLORS} from "@components/Charts/commons";


function NominationDonut({id, data}) {

  useLayoutEffect(() => {

    if (!data)
      return

    const _data = data.map(i => ({
      ...i,
      name: {'acc': 'Conferme', 'rev': 'Revoche', 'rif': 'Rifiuti'}[i.category],
      settings: {fill: STATUS_COLORS[i.category.toUpperCase()], stroke: "rgb(255,255,255)"}}))

    addLicense();

    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    let chart = getPieChart(root)

    // Add series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "name",
      alignLabels: false,
      radius: am5.percent(40)
    }))

    series.slices.template.setAll({
      templateField: "settings",
      fillOpacity: 0.65,
      tooltipText: `[bold]{name}[/]\n {value} designazioni \n {valuePercentTotal.formatNumber('0.')}%`
    })

    // Disabling labels and ticks
    // series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);
    series.labels.template.setAll({
      maxWidth: 120,
      oversizedBehavior: "truncate", // to truncate labels, use "truncate", else "wrap"
      text: "[bold fontSize:10px]{name}[/]\n[fontSize:14px]{valuePercentTotal.formatNumber('0.')}%[/][/]",
    });


    series.data.setAll(_data)

    chart.seriesContainer.children.push(
      am5.Label.new(root, {
        textAlign: "center",
        centerY: am5.p50,
        centerX: am5.p50,
        text: `[bold fontSize:24px]${_data.reduce((acc, i) => acc + i.value, 0)}[/]`
      })
    );

    // Make stuff animate on load
    series.appear(1000)

    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} className="w-full h-80"/>
}

export default React.memo(NominationDonut, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
