import React, {useContext, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications"
import {api} from "@services/apiRequest";

// Utils
import {END_SEASON, START_SEASON, WEEKDAY_OPTIONS} from "@utils/constants";
import {getDateStr} from "@pages/Planner/Calendar/Calendar.lib";
import {getMemberOptions} from "@utils/index";

// Ui
import CheckboxGroup from "@ui-components/CheckboxGroup";
import SearchSelect from "@ui-components/SearchSelect";
import DatePicker from "@ui-components/DatePicker";
import RadioGroup from "@ui-components/RadioGroup";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

// Types
import {AddRelocationDialogType} from "./Relocations.type";


export function AddUpdateRelocationDialog(
  {
    setShowAdd,
    setRelocations,
    members,
    updateRelocation,
    setUpdateRelocation,
    cities
  }: AddRelocationDialogType) {

  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState<boolean>(false);

  const memberOptions = getMemberOptions(members)
  const initMember = updateRelocation ? memberOptions.filter((i: { value: any; }) => i.value === updateRelocation.id_fip_code)[0] : undefined
  const [selectedMember, setSelectedMember] = useState<{ value: number; data: any } | undefined>(initMember);

  const optionsCities = cities.sort((a, b) => a.city.localeCompare(b.city))
    .map(i => ({value: i.id, label: `${i.city} (${i.cod_province})`, des_province: i.des_province}))

  const initCity = updateRelocation ? optionsCities.filter(i => Number(i.value) === updateRelocation.id_city)[0] : undefined
  const [selectedCity, setSelectedCity] = useState<{ value: number; label: string } | undefined>(initCity);

  const [startDate, setStartDate] = useState(new Date(updateRelocation ? updateRelocation.dat_from : START_SEASON))
  const [endDate, setEndDate] = useState(new Date(updateRelocation ? updateRelocation.dat_to : END_SEASON))

  const [allOrSomeDays, setAllOrSomeDays] = useState<'all' | 'some'>(updateRelocation?.weekdays ? 'some' : 'all')
  const [weekdays, setWeekdays] = useState<string>(updateRelocation?.weekdays ?? '')

  const exitAdd = () => {
    setShowAdd(false)
    setUpdateRelocation(undefined)
    setSelectedMember(undefined)
    setSelectedCity(undefined)
  }

  const addRelocation = () => {
    setLoading(true)
    if (selectedMember && selectedCity && startDate && endDate) {
      let payload = {
        id_fip_code: selectedMember.value,
        id_city: selectedCity.value,
        dat_from: getDateStr(startDate),
        dat_to: getDateStr(endDate),
        ...(allOrSomeDays === 'some' && weekdays && {weekdays: weekdays}),
        ...(updateRelocation && {id: updateRelocation.id}),
      }
      api.post(`/relocations`, payload)
        .then(({data}) => {
          setRelocations((prev) => [data, ...(prev ?? []).filter(i => i.id !== data.id)])
          push({title: "Elemento aggiunto", type: "success"})
          exitAdd()
        })
        .catch(() => {
          push({title: "Errore del server", type: "error"})
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <Modal onExit={exitAdd}>
      <div className="my-5">
        <h1 className="font-bold sm:text-xl">
          Aggiunta elemento
        </h1>
        <div className="flex flex-col gap-2 my-6">
          {/*@ts-ignore*/}
          <SearchSelect
            label="Tesserato"
            minHeight={50}
            value={selectedMember}
            onChange={(e: any) => setSelectedMember(e)}
            options={memberOptions}
            filterOption={(candidate: { data: { data: any; }; }, input: string) => {
              if (input) {
                const _data = candidate.data.data
                const _label = _data.id_fip_code + _data.member
                return _label.toUpperCase().includes(input.toUpperCase())
              }
              return true
            }}
          />
          {/*@ts-ignore*/}
          <SearchSelect
            label="Città"
            options={optionsCities}
            value={selectedCity}
            onChange={(e: { value: number; label: string }) => {
              setSelectedCity(e);
            }}
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-5 -mt-2 mb-6">
          {/*@ts-ignore*/}
          <DatePicker
            value={startDate}
            onChange={(e: any) => setStartDate(e)}
            label='Data di inizio'
            placeholder="Seleziona data"
            maxDate={new Date(END_SEASON)}
            minDate={new Date(START_SEASON)}
          />
          {/*@ts-ignore*/}
          <DatePicker
            value={endDate}
            onChange={(e: any) => setEndDate(e)}
            label='Data di fine'
            placeholder="Seleziona data"
            maxDate={new Date(END_SEASON)}
            minDate={new Date(START_SEASON)}
          />
        </div>
        <div>
          <RadioGroup
            id="allOrSomeDaysSelection"
            horizontal
            options={[
              {value: "all", name: "Tutti i giorni"},
              {value: "some", name: "Seleziona giorni"},
            ]}
            currentValue={allOrSomeDays}
            onChange={(value: 'all' | 'some') => {
              setAllOrSomeDays(value)
            }}
          />
        </div>
        {allOrSomeDays === 'some' ?
          <div className="my-4">
            {/*@ts-ignore*/}
            <CheckboxGroup
              horizontal
              title="Giorni della settimana"
              options={WEEKDAY_OPTIONS}
              values={weekdays.split(';')}
              onChange={(value: string) => {
                const currentList = (weekdays).split(';').filter(i => i)
                if (currentList.includes(value)) {
                  setWeekdays([...currentList.filter((v) => v !== value)].join(';'))
                } else {
                  setWeekdays([...currentList, value].join(';'))
                }
              }}
            />
          </div> : null
        }
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={exitAdd}>
          Annulla
        </Button>
        <Button onClick={addRelocation} submitting={loading} disabled={!selectedMember || !selectedCity}>
          Salva
        </Button>
      </div>
    </Modal>
  );
}
