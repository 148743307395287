import React from "react";
import {QuestionItemType} from "@utils/questionnaires/type/questionnaire.type";
import {ResultsType} from "@components/RefMan/Reports/ReportsForm/ReportsForm.type";

/**
 * @function handleRadioOnChange
 *
 * @param {React.FormEvent<HTMLInputElement>} e Radio on change event
 * @param {QuestionItemType} data Current report data
 * @param {ResultsType} results Current results data
 * @param {React.Dispatch<React.SetStateAction<ResultsType>>} setResults Update results data
 * @param {number} sectionId Id of section
 */
export const handleRadioOnChange = (
  e: React.FormEvent<HTMLInputElement>,
  data: QuestionItemType,
  results: ResultsType | undefined,
  setResults: React.Dispatch<React.SetStateAction<ResultsType | undefined>>,
  sectionId: number,
): void => {
  const idParts = e.currentTarget.id.split("-");
  const resultsCopy = {...results};
  resultsCopy[data.id] = {
    id: data.id,
    value: idParts[idParts.length - 1],
    isRequired: (data.isRequired === undefined || data.isRequired),
    isPrivate: (data.isPrivate !== undefined && data.isPrivate),
    tag: data.tag,
    idSection: sectionId,
    type: "radio"
  }
  setResults({
    ...results,
    ...resultsCopy
  });
}