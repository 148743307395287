import React from "react";
import {QuestionItemType} from "@utils/questionnaires/type/questionnaire.type";
import {ResultsType} from "@components/RefMan/Reports/ReportsForm/ReportsForm.type";

/**
 * @function classNames
 *
 * @param {string[]} classes Array of CSS classes
 * @return {string} CSS classes
 */
export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(' ')
}

/**
 * @function onSelectChange
 *
 * @param {string} value Current value selected
 * @param {ResultsType} results Current results data
 * @param {React.Dispatch<React.SetStateAction<ResultsType>>} setResults Update results data
 * @param {QuestionItemType} data Current report data
 * @param {number} sectionId Id of section
 */
export const onSelectChange = (
  value: string,
  results: ResultsType | undefined,
  setResults: React.Dispatch<React.SetStateAction<ResultsType | undefined>>,
  data: QuestionItemType,
  sectionId: number,
) => {
  const resultsCopy = {...results};
  resultsCopy[data.id] = {
    id: data.id,
    value: value,
    isRequired: (data.isRequired === undefined || data.isRequired),
    isPrivate: (data.isPrivate !== undefined && data.isPrivate),
    tag: data.tag,
    idSection: sectionId,
    type: "select"
  }
  setResults({
    ...results,
    ...resultsCopy
  });
}