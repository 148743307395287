import React, {useContext, useEffect, useState} from 'react';

// Services
import AuthenticationService from '../../services/AuthenticationService';
import useCategories from '../../services/hooks/useCategories';
import { DataContext } from '@services/DataContext/DataContext';
import useMembers from '../../services/hooks/useMembers';
import GroupSelection from '../../utils/groupSelection';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

// Components
import { CategoryFilter, MemberSearch, ProvinceFilter, MembersKpi, memberDownloadExcel } from './Members.lib';
import RefManList from '@components/RefMan/RefManList/RefManList';
import { getListItems, getFilteredData } from './Members.lib';

// Ui
import { PageHeading } from '@ui-components/Container';
import Placeholder from '@ui-components/Placeholder';
import { InboxArrowDownIcon } from '@heroicons/react/24/outline';
import useMembersPics from "@services/hooks/useMembersPics";

export function Members() {
  const intl = useIntl();
  const history = useHistory();
  const dataContext: any = useContext(DataContext);
  const scopes = AuthenticationService.getUserScopes();
  const showPrivateData = scopes.includes('member:total');

  const [selectedCategories, setSelectedCategories] = useState<{ data: any; label: any; value: number }[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<{ label: string; value: string }[]>([]);
  const [searchKey, setSearchKey] = useState<string>();
  const [offset, setOffset] = useState<number>(0);
  const limit = 10;

  // @ts-ignore
  const members = useMembers({ group: dataContext.group, asObj: false });
  // @ts-ignore
  const picsUrls = useMembersPics();
  // @ts-ignore
  const categories = useCategories({ group: dataContext.group, asObj: false });

  const filteredData = getFilteredData(members, selectedCategories, selectedProvinces, searchKey);

  useEffect(() => {
    setOffset(0)
  }, [dataContext.group, selectedCategories, selectedProvinces]);

  return (
    <>
      <PageHeading
        title='Tesserati'
        contentActions={
          <div className='mr-6 mt-2'>
            <GroupSelection selectedGroup={dataContext.group} setSelectedGroup={dataContext.setGroup} />
          </div>
        }
      />
      {picsUrls && filteredData && categories ? (
        <div className='flex flex-col gap-3'>
          <div className='flex flex-col sm:flex-row justify-between sm:gap-6'>
            <div className='w-full sm:w-1/3'>
              <CategoryFilter categories={categories} selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} />
            </div>
            <div className='w-full sm:w-1/3'>
              <ProvinceFilter members={members} selectedProvinces={selectedProvinces} setSelectedProvinces={setSelectedProvinces} />
            </div>
            <div className='w-full sm:w-1/3 flex flex-row items-center gap-4'>
              <div className='w-full'>
                <MemberSearch label='Cerca' searchKey={searchKey} setSearchKey={setSearchKey} setOffset={setOffset} />
              </div>
              {showPrivateData ? (
                <div className='mx-2 cursor-pointer' onClick={() => memberDownloadExcel(filteredData, intl)}>
                  <InboxArrowDownIcon className='w-7' />
                </div>
              ) : null}
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            {showPrivateData ? <MembersKpi data={filteredData} intl={intl} /> : null}
            <RefManList
              offset={offset}
              setOffset={setOffset}
              limit={limit}
              containerId='members-list'
              items={getListItems(filteredData, showPrivateData, dataContext.group, history, intl, picsUrls)}
              total={filteredData.length}
              rowClassNames={[
                'sm:grid',
                showPrivateData ? 'sm:grid-cols-5' : 'sm:grid-cols-3',
                'sm:gap-3',
                'items-center',
                showPrivateData ? '' : '!cursor-default',
              ]}
              pagination='frontend'
            />
          </div>
        </div>
      ) : (
        <div className='flex flex-col w-full mb-4 gap-2'>
          <div className='flex flex-row gap-10'>
            <Placeholder height='h-16' classNames='rounded-lg w-1/3' />
            <Placeholder height='h-16' classNames='rounded-lg w-1/3' />
            <Placeholder height='h-16' classNames='rounded-lg w-1/3' />
          </div>
          <Placeholder height='h-20' color='am-200' classNames='rounded-lg' />
          <Placeholder height='h-16' classNames='rounded-lg mt-5' />
          <Placeholder height='h-16' classNames='rounded-lg' />
          <Placeholder height='h-16' classNames='rounded-lg' />
          <Placeholder height='h-16' classNames='rounded-lg' />
          <Placeholder height='h-16' classNames='rounded-lg' />
          <Placeholder height='h-16' classNames='rounded-lg' />
          <Placeholder height='h-16' classNames='rounded-lg' />
          <Placeholder height='h-16' classNames='rounded-lg' />
        </div>
      )}
    </>
  );
}
