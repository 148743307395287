import React from "react";

// Services
import {LKP_ACTIVITY_TYPES} from "@utils/constants";
import {useHistory} from "react-router-dom";
import {useIntl} from "react-intl";

// Ui
import {getMemberAttendance, MemberAnswerCard} from "@pages/Activities/Detail/Components/DetailComponents.lib";
import {ArrowLongRightIcon, CalendarIcon, LinkIcon, MapPinIcon, UserIcon} from "@heroicons/react/24/outline";
import Placeholder from "@ui-components/Placeholder";

// Types
import {dateFormatWithWeekday, timeStampFormat} from "@pages/Planner/Absences/Absences.lib";
import {RefManListItemType} from "@components/RefMan/RefManList/RefManList.type";
import {Activity, ActivityType} from "@services/types/activity";
import {Role} from "@services/types/role";


export const getInitActivity = () => ({
  activity_type: 'LESSON' as ActivityType,
  name: '',
  dat_activity: '',
  time_start: '',
  time_end: '',
  flg_physical_test: false,
  flg_technical_test: false,
  members_activities: []
})

export const getMissingDataError = (
  activity: Activity,
) => {
  let err
  if (['RADUNO', 'LESSON', 'GYM'].includes(activity.activity_type) && !activity.location) {
    err = "Indica dove si svolgerà l'attività"
  }
  if (activity.activity_type === 'REMOTE' && !activity.link_web) {
    err = "Inserisci il link al meeting online"
  }
  if (!activity.time_start || !activity.time_end) {
    err = 'Inserisci gli orari di inizio e fine'
  }
  if (!activity.dat_activity) {
    err = 'Seleziona la data'
  }
  if ((activity.name ?? '').length < 5) {
    err = "Inserisci il nome dell'attività"
  }
  return err
}

export const ActivityPlaceHolder = () => (
  <div className="flex flex-col w-full mt-10 mb-4 gap-2">
    <Placeholder height="h-20" classNames="rounded-lg"/>
    <Placeholder height="h-20" classNames="rounded-lg"/>
    <Placeholder height="h-20" classNames="rounded-lg"/>
    <Placeholder height="h-20" classNames="rounded-lg"/>
    <Placeholder height="h-20" classNames="rounded-lg"/>
  </div>
)

export const ActivityDetailPlaceHolder = () => (
  <div className="flex flex-col w-full mt-10 mb-4 gap-2">
    <Placeholder height="h-20" classNames="rounded-lg"/>
    <Placeholder height="h-96" classNames="rounded-lg"/>
  </div>
)

export const getListItems = (
  activities: Activity[] | undefined,
  membersByUserId: string[],
  roles: Role[] | null,
  userIdFipCode: number | undefined
): RefManListItemType[] | [] => {

  const intl = useIntl();
  const {push} = useHistory()

  return (
    activities !== undefined &&
    activities.length > 0 &&
    membersByUserId) ?
    activities.map((activity: Activity, index): RefManListItemType => {

      let originalAnswer: boolean | undefined
      let originalReason: string | undefined
      let attendance: string | undefined | null
      if (userIdFipCode) {
        originalAnswer = activity?.flg_accepted
        originalReason = activity?.reason_decline
        attendance = activity?.attendance
      }

      return ({
        rowId: `technical-activity-${index}`,
        rowOnClick: () => push(`/activities/${activity.id}`),
        rowElement: <>
          <dt className="font-bold capitalize text-gray-900 flex flex-row items-center gap-2 justify-between">
            <div className="flex flex-row gap-2 items-center">
              <div><CalendarIcon className="h-7 w-7"/></div>
              <div className="flex flex-col whitespace-nowrap">
                <span>{intl.formatDate(activity.dat_activity, dateFormatWithWeekday)}</span>
                <span className="text-sm font-normal">{activity.time_start} - {activity.time_end}</span>
              </div>
            </div>
            {userIdFipCode ? null :
              <div className="text-xl sm:text-2xl font-bold w-20">
                <span className="rounded-full border py-1 px-2 whitespace-nowrap">
                  {activity?.num_students ?? 0}
                  <UserIcon className="h-4 w-4 inline-block -mt-0.5 ml-1"/>
                </span>
              </div>
            }
          </dt>
          {activity.link_web ?
            <dt className="font-bold capitalize text-gray-900 flex flex-row items-center gap-2 my-2 sm:my-0">
              <div><LinkIcon className="h-6 w-6"/></div>
              <a
                className="text-sm font-normal hover:font-bold text-gray-900"
                href={activity.link_web}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </dt> :
            <dt className="font-bold capitalize text-gray-900 flex flex-row items-center gap-2 my-2 sm:my-0">
              <div><MapPinIcon className="h-6 w-6"/></div>
              <span className="text-xs font-normal">{activity.location}</span>
            </dt>
          }
          <dd className="mt-1 sm:mt-0 flex flex-col justify-center">
            <span className="text-gray-900">{LKP_ACTIVITY_TYPES[activity.activity_type]}</span>
            <span className="text-sm font-bold text-gray-700">{activity.name}</span>
          </dd>
          <dd
            className="mt-1 text-sm text-gray-700 mt-3 sm:mt-0 flex flex-row justify-between gap-4 items-center">
            {userIdFipCode
              ? attendance ? getMemberAttendance(attendance) :
                <MemberAnswerCard originalAnswer={originalAnswer} originalReason={originalReason} /> :
              <div className="flex flex-col">
                <span className="text-xxs sm:text-end leading-3">
                  {`Aggiornata il ${intl.formatDate(activity.tms_update, timeStampFormat)}`}
                </span>
                <span className="text-xxs sm:text-end leading-3">
                  {(roles && activity.id_added_user && activity.id_added_role) ?
                    `da ${membersByUserId[activity.id_added_user]} (${roles[activity.id_added_role]['description']})` : ""
                  }
                </span>
              </div>
            }
            <div className="text-sm">
              <ArrowLongRightIcon className="h-8 w-8 ml-1"/>
            </div>
          </dd>
        </>
      })
    }) : [];
}