import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, getXYChart, STATUS_COLORS} from "@components/Charts/commons";

const LKP_DIMENSIONS = {
  'acc': 'Gare',
  'rev': 'Revoche',
  'rif': 'Rifiuti',
  'ref1': '1° Arbitro',
  'ref2': '2° Arbitro',
  'sp': 'Segnapunti',
  'crono': 'Cronometrista',
  'a24s': "Addetto 24''",
  'udcs': "Statistico",
  'unknown': 'Sconosciuto',
}

const getChartHigh = (n) => {
  switch (true) {
    case (n < 4):
      return 'h-48'
    case (n < 8):
      return 'h-72'
    case (n < 12):
      return 'h-80'
    case (n < 15):
      return 'h-96'
    case (n < 25):
      return 'h-70vh'
    case (n < 35):
      return 'h-screen'
    case (n < 60):
      return 'h-150vh'
    default:
      return 'h-200vh'
  }
}


function EmploymentBarChart({id, data, intl, group}) {

  useLayoutEffect(
    () => {

      if (!data)
        return

      const _data = data.map(i => {
        const { detail, ...resultObject } = i;
        return {...resultObject, id_fip_code: i.member.id_fip_code, ...detail, zero: 0}
      }).reverse()

      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)])
      root.locale = am5locales_it_IT;
      root.numberFormatter.set("numberFormat", "#,###.#");

      // Create chart
      let chart = getXYChart(root, {layout: root.verticalLayout})
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);

      let legend = chart.children.unshift(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );

      legend.markers.template.setAll({
        width: 10,
        height: 10
      });

      legend.labels.template.setAll({
        fontSize: 10,
        fontWeight: "300"
      });

      // Create axes
      // CategoryAxis: is the only axis type that requires its own data as well as data field set up.
      let yAxis = chart.yAxes.push(
        chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: 'id_fip_code',
            renderer: am5xy.AxisRendererY.new(root, {
              minGridDistance: 0,
            }),
          })
        ))

      yAxis.get("renderer").labels.template.adapters.add("text", function (text, target) {
        if (target.dataItem && target.dataItem.dataContext) {
          const member = target.dataItem.dataContext.member
          return `[fontSize:8px]${member.id_fip_code} \u2022 ${member.category.cod_sub_category} \u2022 ${member.city.cod_province}[/]\n`
            + `[bold]${member.tag}[/]`
        }
        return text;
      });

      yAxis.data.setAll(_data);

      let yRenderer = yAxis.get("renderer");
      // yRenderer.grid.template.set("forceHidden", true);
      yRenderer.labels.template.set("fontSize", 12);
      yRenderer.labels.template.set("textAlign", 'right');
      yRenderer.labels.template.set("lineHeight", 1);
      yRenderer.labels.template.set("oversizedBehavior", 'truncate');
      yRenderer.labels.template.set("maxWidth", 110);

      let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        extraMax: 0.05,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererX.new(root, {}),
        calculateTotals: true
      }))
      let xRenderer = xAxis.get("renderer");
      xRenderer.grid.template.set("forceHidden", true);
      xRenderer.labels.template.set("forceHidden", true);


      // Add series
      function makeSeries(name) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: LKP_DIMENSIONS[name],
          xAxis: xAxis,
          yAxis: yAxis,
          fill: STATUS_COLORS[name.toUpperCase()],
          stroke: am5.color("rgb(255,255,255)"),
          valueXField: name === 'acc' ? 'zero' : name,
          excludeFromTotal: ['rif', 'rev'].includes(name),
          categoryYField: 'id_fip_code',
          stacked: true,
          tooltip: name === 'acc' ? null : am5.Tooltip.new(root, {
            labelText: "{categoryY}\n[bold]{name}: {valueX} ({valueXTotalPercent}%)[/]",
            tooltipY: am5.percent(10),
            fontSize: 8
          })
        }))

        if (name !== 'acc') {
          series.get("tooltip").adapters.add("labelText", function (text, target) {
            const dataItem = target.dataItem
            let newText = ''
            if (dataItem) {
              const value = Math.abs(dataItem.dataContext[name])
              const percentage = intl.formatNumber(Math.abs(dataItem._settings.valueXTotalPercent), {maximumFractionDigits: 1})
              newText = `[bold]${dataItem.dataContext.member.member}[/]\n{name}: [bold]${value}[/]`
              if (!['rif', 'rev'].includes(name)) {
                newText += ` (${percentage}%)`
              }
            }
            return newText
          })
        }

        series.columns.template.setAll({
          fillOpacity: 0.7,
          visible: name !== 'acc',
          cornerRadiusTL: 2,
          cornerRadiusTR: 2,
          cornerRadiusBL: 2,
          cornerRadiusBR: 2
        });

        if (name === 'acc') {
          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationX: 0.5,
              sprite: am5.Label.new(root, {
                text: "[bold]{valueXTotal}[/]",
                centerY: am5.p50,
                centerX: am5.p0,
                populateText: true,
                fontSize: 11
              })
            });
          });

        }

        legend.data.push(series);
        series.data.setAll(_data);

        if (name === 'acc') {
          series.get("legendDataItem").get("itemContainer").hide();
        }

        series.appear(1000);

        return series
      }

      makeSeries('rif')
      makeSeries('rev')
      if (group === 'ref') {
        makeSeries('ref1')
        makeSeries('ref2')
      } else {
        makeSeries('sp')
        makeSeries('crono')
        makeSeries('a24s')
        makeSeries('udcs')
      }
      makeSeries('unknown')
      makeSeries('acc')


      return () => {
        root.dispose()
      }
    },
    [id, data] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return <div id={id} className={`w-full ${getChartHigh(data.length)}`}/>
}

export default React.memo(EmploymentBarChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
