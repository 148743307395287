/**
 * @function capitalizeSentence
 *
 * @param {string} sentence Sentence to capitalize (every word)
 * @returns {string} Sentence with every word capitalize
 */
export const capitalizeSentence = (sentence: string): string =>
  sentence
    .toLowerCase()
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ')