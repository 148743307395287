import React, {useEffect, useState} from "react";

import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Alert from "@ui-components/Alert";

import {useQuiz} from "@components/RefMan/Quiz/hooks/useQuiz";
import {
  close_quiz_session,
  create_quiz_session,
  quiz_cod_type_finished,
  quiz_cod_type_in_progress,
  StartStopSessionProps
} from "@components/RefMan/Quiz/types";
import {api} from "@services/apiRequest";


export function QuizStartStopSessionModal({push, onExit, idQuiz, action}: StartStopSessionProps) {

  const {startStopQuizSession, loading} = useQuiz();
  const [polling, setPolling] = useState({des: "", try: 0});

  useEffect(() => {
    if (polling.des === 'pending') {
      console.log(polling)
      setTimeout(() => {
        api.get(`/quizzes/check-notification/${idQuiz}`)
          .then(({data: allInvited}) => {
            if (allInvited) {
              setPolling({des: "done", try: 0});
              push({
                title: "Sessione creata con successo e utenti notificati",
                type: "success"
              });
              onExit();
            } else {
              if (polling.try > 12) {
                push({title: "Non è stato possibile creare la sessione per tutti i partecipanti"});
                setPolling({des: "done", try: 0});
              } else {
                setPolling({des: "pending", try: polling.try + 1})
              }
            }
          })
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polling])

  return (
    <Modal onExit={onExit}>
      <h1 className="font-bold sm:text-xl">
        Confermi di voler {action === create_quiz_session ? 'iniziare' : 'terminare'} la sessione?
      </h1>
      <div className="my-8">
        {action === create_quiz_session
          ? <Alert
            title="Tutti i tesserati associati nel tab Sessioni riceveranno una notifica e potranno svolgere il quiz"
            text="Se hai aggiunto nuovi tesserati, solo questi riceveranno la notifica"
          />
          : <Alert
            type="warning"
            title="Non sarà possibile annullare l'operazione"
            text="Eventuali quiz non terminati dai tesserati risulteranno non eseguiti"
          />
        }
      </div>

      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button
          submitting={loading || polling.des === 'pending'}
          onClick={() => {
            startStopQuizSession(
              idQuiz, action === create_quiz_session ? quiz_cod_type_in_progress : quiz_cod_type_finished
            ).then(() => {
              if (action === close_quiz_session) {
                push({ title: 'Operazione effettuata con successo', type: 'success' })
                onExit()
              } else {
                setPolling({des: "pending", try: 1})
              }
            })
              .catch(() => push({ title: 'Errore del server', type: 'error' }))
          }}
        >
          Conferma
        </Button>
      </div>
    </Modal>
  );
}
