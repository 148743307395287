import React, {useContext, useEffect, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";

// Components
import {AbsenceFilterAndAddButton, AbsencePlaceHolder} from "@pages/Planner/Absences/Absences.lib";
import AddUpdateAbsenceDialog from "@pages/Planner/Absences/AddUpdateAbsenceDialog";
import DeleteAbsenceDialog from "@pages/Planner/Absences/DeleteAbsenceDialog";
import {AbsenceGroupCard} from "./AbsenceGroupCard";

// Ui
import Alert from "@ui-components/Alert";

// Types
import {GroupAbsencePropsType} from "@pages/Planner/Absences/Absences.type";
import {AbsenceReason} from "@services/types/absence";
import AssignMemberDialog from "@pages/Planner/Absences/AssignMemberDialog";


export function GroupAbsences({roles, userData, reasonTypes, membersByUserId}: GroupAbsencePropsType) {

  const {push} = useContext(NotificationsContext);
  const [selectedTypes, setSelectedTypes] = useState<{ label: string, value: number }[]>([]);
  const [reasons, setReasons] = useState<AbsenceReason[]>();
  const [data, setData] = useState<AbsenceReason[]>();

  const [updateReason, setUpdateReason] = useState<AbsenceReason | undefined>()
  const [deleteReason, setDeleteReason] = useState<AbsenceReason | undefined>()
  const [assignReason, setAssignReason] = useState<AbsenceReason | undefined>()
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    const fetchAbsences = () => {
      api
        .get("/absences/reasons?flg_group=1")
        .then(({data: reasons}) => {
          setReasons(reasons)
        })
        .catch(() => {
          push({title: "Errore del server", type: "error"});
        });
    };

    if (reasons === undefined) {
      fetchAbsences();
    }

  }, [reasons]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reasonTypes && reasons) {
      setData(selectedTypes.length > 0
        ? reasons.filter(i => selectedTypes.map(t => t.value).includes(i.reason_type.id))
        : reasons
      )
    }
  }, [reasonTypes, reasons, selectedTypes])

  return (
    <div className="my-5 sm:my-8">
      {data && membersByUserId && roles ?
        <div className="flex flex-col gap-3">
          <AbsenceFilterAndAddButton
            selectedTypes={selectedTypes}
            setSelectedTypes={setSelectedTypes}
            reasonTypes={reasonTypes}
            setShowAdd={setShowAdd}
          />
          <div>
            {data.length ?
              <div className="p-1 w-full flex flex-col divide-y divide-gray-300 h-3/4 overflow-y-scroll mb-10">
                {data.map(i =>
                  <div key={i.id}>
                    <AbsenceGroupCard
                      absenceReason={i}
                      membersByUserId={membersByUserId}
                      setUpdateReason={setUpdateReason}
                      setDeleteReason={setDeleteReason}
                      setAssignReason={setAssignReason}
                      roles={roles}
                    />
                  </div>
                )}
              </div> :
              <div className="my-10 sm:mx-10">
                <Alert title="Non sono presenti indisponibilità di gruppo registrate"/>
              </div>
            }
          </div>
        </div>
        : <AbsencePlaceHolder/>
      }
      {reasons && (showAdd || updateReason) ? <div>
        <AddUpdateAbsenceDialog
          onExit={() => {
            setUpdateReason(undefined)
            setShowAdd(false)
          }}
          updateReason={updateReason}
          reasonTypes={reasonTypes}
          setReasons={setReasons}
          isMember={false}
        />
      </div> : null}
      {reasons && deleteReason ?
        <DeleteAbsenceDialog
          onExit={() => {
            setDeleteReason(undefined)
          }}
          deleteReason={deleteReason}
          setReasons={setReasons}
        /> : null
      }
      {assignReason ?
        <AssignMemberDialog
          onExit={() => setAssignReason(undefined)}
          absenceReason={assignReason}
          setReasons={setReasons}
          userData={userData}
        /> : null
      }
    </div>
  );
}
