import {ObserverReportsFormSelectType} from "./ObserverReportsFormSelect.type";
import {Listbox, Transition} from "@headlessui/react";
import {ChevronUpDownIcon} from "@heroicons/react/24/solid";
import {CheckIcon} from "@heroicons/react/20/solid";
import React, {Fragment} from "react";
import {
  classNames,
  onSelectChange
} from "@components/RefMan/Reports/ReportsForm/ReportsFormSelect/ObserverReportsFormSelect.lib";

const ObserverReportsFormSelect = ({
                                     data,
                                     itemId,
                                     sectionId,
                                     results,
                                     setResults,
                                     setIsFormChanged,
                                   }: ObserverReportsFormSelectType) => {

  return <div className="observerreportsformselect__container">
    <div className="observerreportsformselect__header border-b border-gray-200 pb-3">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {`${data.title} `}
        <span className="text-red-600">
          {(data.isRequired === undefined || data.isRequired) && "*"}
        </span>
      </h3>
    </div>
    <div className="observerreportsformselect__body pt-3">
      <Listbox value={results?.[data.id]?.value ? results?.[data.id]?.value : "Seleziona"}
               onChange={(value: string) => {
                 onSelectChange(value, results, setResults, data, sectionId)
                 setIsFormChanged(true);
               }}>
        {({open}) => (
          <div className="relative mt-2">
            <Listbox.Button
              className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-am-600 sm:text-sm sm:leading-6">
              <span
                className="block truncate">{results?.[data.id]?.value ? results?.[data.id]?.value : "Seleziona"}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {data.options && data.options.map((option: string | number, index: number) => (
                  <Listbox.Option
                    key={index}
                    className={({active}) =>
                      classNames(
                        active ? 'bg-am-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({selected, active}) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option}
                        </span>
                        {selected && (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-am-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  </div>
}

export default ObserverReportsFormSelect;