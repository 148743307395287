import React, {useEffect, useState} from "react";

// Services
import {dateFormatWithWeekday} from "@pages/Planner/Absences/Absences.lib";
import {LKP_ACTIVITY_TYPES, TODAY} from "@utils/constants";
import {useIntl} from "react-intl";

// Components

// Ui
import {CalendarIcon, MapPinIcon} from "@heroicons/react/24/outline";
import {ArrowLongRightIcon} from "@heroicons/react/24/solid";
import Placeholder from "@ui-components/Placeholder";
import Alert from "@ui-components/Alert";


const MemberDetailAgenda = ({history, nominations, activities}) => {

  const intl = useIntl();
  const [data, setData] = useState(undefined);

  useEffect(() => {
    if (nominations && activities) {
      let res = activities.filter(i => i.activity.dat_activity >= TODAY).map(i => ({
          type: LKP_ACTIVITY_TYPES[i.activity.activity_type],
          date: i.activity.dat_activity,
          time: i.activity.time_start,
          location: i.activity.location,
          detail: i.activity.name
        })
      ).concat(nominations.filter(i => i.game.dat_game >= TODAY && ['ACC', 'TRA'].includes(i.status)).map(i => ({
            type: `Gara ${i.game.level.des_level}`,
            date: i.game.dat_game,
            time: i.game.time_game,
            location: i.game.city ? `${i.game.city.city} (${i.game.city.cod_province})` : '',
            detail: `${i.game.team_a.team_alias} - ${i.game.team_b.team_alias}`
          })
        )
      )

      setData(res.sort((a, b) => a.date.localeCompare(b.date)).slice(0, 5))
    }

  }, [nominations, activities])


  return (
    <div className="flex flex-col w-full gap-5">
      <div className="w-full flex flex-row justify-between items-center pb-1 border-b-2 border-gray-900">
        <p className="font-bold text-2xl">Prossimi impegni</p>
        <p
          className="cursor-pointer hover:underline hover:font-bold"
          onClick={() => history.push({pathname: '/planner/agenda'})}
        >
          Vai al dettaglio <ArrowLongRightIcon className="inline-block h-4 w-4"/>
        </p>
      </div>
      {data ?
        <>
          {data.length > 0 ?
            <div className="w-full flex flex-col divide-y divide-gray-300">
              {data.map((i, index) =>
                <div key={index} className="grid sm:grid-cols-4 gap-4 py-4 sm:py-2">
                  <div className="flex flex-row gap-2 items-center">
                    <div><CalendarIcon className="h-7 w-7"/></div>
                    <div className="flex flex-col whitespace-nowrap capitalize">
                      <span className="text-sm font-medium">{intl.formatDate(i.date, dateFormatWithWeekday)}</span>
                      <span className="text-xs font-light leading-3">{i.time}</span>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <div>{i.location ? <MapPinIcon className="h-7 w-7"/> : null}</div>
                    <span className="text-xs font-light">{i.location}</span>
                  </div>
                  <div className="flex flex-col sm:col-span-2">
                    <span className="text-sm font-medium">{i.type}</span>
                    <span className="text-xs font-light leading-3">{i.detail}</span>
                  </div>
                </div>
              )}
            </div>
            :
            <div className="mt-5 mb-10">
              <Alert title="Non hai impegni in programma"/>
            </div>
          }
        </>
        :
        <div className="flex flex-col w-full mb-4 gap-2">
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
        </div>
      }
    </div>
  )
}

export default MemberDetailAgenda;
