import React from "react";
import _ from "lodash";
import Tooltip from "@ui-components/Tooltip";
import {apiRequest} from "@services/apiRequest";
import {LKP_GROUP, LKP_MEMBERSHIP} from "@utils/constants";
import {ExclamationCircleIcon} from "@heroicons/react/24/solid";

export const getColumns = (group, data, categories, tableFilters) => {
  if (!data || !categories) {
    return []
  }
  let columns = [
    {
      title: "Tessera",
      field: "id_fip_code",
      editable: ( _ ,rowData ) => rowData.flg_external
    },
    {
      title: LKP_GROUP[group.toUpperCase()],
      field: "member",
      render: ({ member }) => <b>{member}</b>,
      defaultSort: "asc",
      cellStyle: { width: 200, minWidth: 200 },
      headerStyle: { width: 200, minWidth: 200 },
      editable: ( _ ,rowData ) => rowData.flg_external
    },
    {
      title: "Categoria",
      field: "id_category",
      lookup: _.sortBy(Object.values(categories), i => i.sub_cat_order)
        .reduce((obj, i) => _.set(obj, [i.id], [i.des_sub_category]), {}),
      render: rowData => <div>{rowData.category.cod_sub_category === '-'
        ? <ExclamationCircleIcon className="h-5 w-5 text-red-400"/>
        : rowData.category.des_sub_category}
      </div>
    },
    {
      title: "Prov",
      field: "city.cod_province",
      lookup: [...new Set(Object.values(data).map(i => i.city.cod_province))].sort()
        .reduce((obj, i) => _.set(obj, [i], i), {}),
      editable: "never"
    },
    {
      title: "Tesseramento",
      field: "status_membership",
      lookup: LKP_MEMBERSHIP,
      editable: ( _ ,rowData ) => rowData.flg_external
    },
    {
      title: <div className="flex-col flex items-center -my-2">
        <h3>Esterno</h3>
        <Tooltip
          placement="bottom"
          text="È un tesserato extra CIA Marche ma che fa attività in regione"
          customStyle="text-xs normal-case"
          size="extra-small"
        />
      </div>,
      field: "flg_external",
      type: "boolean",
      editable: "never"
    },
    {
      title: <div className="flex-col flex items-center -my-2">
        <h3>Scrape</h3>
        <Tooltip
          placement="bottom"
          text="Indica se occorre scaricare la scheda attività per eventuali designazioni extra regione"
          customStyle="text-xs normal-case"
          size="extra-small"
        />
      </div>,
      field: "flg_scrape",
      type: "boolean"
    }
  ]

  if (tableFilters) {
    tableFilters.forEach(i => {
      columns = columns.map(c => (c.title === i.column.title ? {...c, defaultFilter: i.value} : c))
    })
  }
  return columns
}

export const OPTIONS = {
  filtering: true,
  toolbar: true,
  paging: true,
  sorting: true,
  showTitle: false,
  pageSize: 10,
  filterCellStyle: {maxWidth: 200}
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    editRow: {
      deleteText: "Vuoi davvero eliminare la riga?",
    },
  },
};


export const onRowUpdate = (newData, oldData, dataContext, push) =>
  new Promise((resolve, reject) => {
    let isEqual = true;
    Object.entries(newData).forEach(([key, value]) => {
      if (oldData[key] !== value) {
        isEqual = false;
      }
    });
    if (isEqual) {
      resolve();
    } else {
      apiRequest
        .put(`/members/${oldData.id_fip_code}`, newData)
        .then((res) => {
          dataContext.setMembers({...dataContext.members, [res['id_fip_code']]: res})
          push({title: "Elemento aggiornato con successo", type: "success", timeout: 600});
          resolve();
        })
        .catch(() => {
          push({
            title: "Errore durante l'aggiornamento",
            type: "error",
          });
          reject();
        });
    }
  });

export const onRowDelete = (oldData, dataContext, push) =>
  new Promise((resolve, reject) => {
    apiRequest
      .delete(`/members/${encodeURIComponent(oldData.id_fip_code)}`)
      .then(() => {
        const {[oldData.id_fip_code]: tmp, ...rest} = dataContext.members;
        dataContext.setMembers({...rest});
        push({title: "Riga eliminata con successo", type: "success"});
        resolve();
      })
      .catch(() => {
        push({
          title: "Errore durante l'eliminazione della riga",
          type: "error",
        });
        reject();
      });
  });