import React, { useContext, useState } from 'react';

// Services
import AuthenticationService from '@services/AuthenticationService';
import useMembers from '@services/hooks/useMembers';

// Ui
import Placeholder from '@ui-components/Placeholder';
import { PlusIcon } from '@heroicons/react/24/outline';
import { PageHeading } from '@ui-components/Container';
import Button from '@ui-components/Button';
import Alert from '@ui-components/Alert';

// Components
import RefManList from '../../components/RefMan/RefManList/RefManList';
import AddUpdateThread from '@pages/Threads/AddUpdateThread';
import { getListItems } from './Threads.lib';

// Types
import { Thread } from './Threads.type';
import { DataContext } from '@services/DataContext/DataContext';

export function Threads() {
  const userIdFipCode = AuthenticationService.getUserFipCode();
  const scopes = AuthenticationService.getUserScopes();
  const dataContext = useContext(DataContext);

  // @ts-ignore
  const threads: Thread[] = dataContext.threads?.all ?? [];
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [updateThread, setUpdateThread] = useState<Thread | undefined>(undefined);
  const members = useMembers({});

  const [offset, setOffset] = useState<number>(0);
  const limit = 5;

  return (
    <>
      {showAdd || updateThread ? (
        <AddUpdateThread
          existingThread={updateThread}
          onExit={() => {
            setShowAdd(false);
            setUpdateThread(undefined);
          }}
        />
      ) : (
        <div>
          <PageHeading
            title='Comunicazioni'
            historyBackPath='_back'
            contentActions={
              members && scopes.includes('threads:create') ? (
                <Button onClick={() => setShowAdd(true)}>
                  <PlusIcon className='w-5 h-5 mr-1' /> Nuova
                </Button>
              ) : null
            }
          />
          {threads ? (
            threads.length ? (
              <div className='my-5'>
                <RefManList
                  containerId='observer-reports-list'
                  items={getListItems(threads, userIdFipCode, setUpdateThread)}
                  total={threads.length}
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  pagination='frontend'
                />
              </div>
            ) : (
              <div className='my-10'>
                <Alert title='Nessuna comunicazione' />
              </div>
            )
          ) : (
            <div className='flex flex-col w-full my-10 gap-2'>
              <Placeholder height='h-20' classNames='rounded-lg' />
              <Placeholder height='h-20' classNames='rounded-lg' />
              <Placeholder height='h-20' classNames='rounded-lg' />
              <Placeholder height='h-20' classNames='rounded-lg' />
            </div>
          )}
        </div>
      )}
    </>
  );
}
