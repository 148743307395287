import React, {Dispatch, useState} from "react";
import {useIntl} from "react-intl";
import {PencilSquareIcon} from '@heroicons/react/24/outline'
import {CreateOrUpdateQuestionModal} from "@components/RefMan/Quiz/Question/QuizAction";
import {QuestionListPaginatedDetailResponseType} from "@components/RefMan/Quiz/types";

interface QuestionListProps {
  questionList: QuestionListPaginatedDetailResponseType;
  selectedQuestions?: number[];
  setSelectedQuestions?: Dispatch<React.SetStateAction<number[]>>;
  editable: boolean;
}

function getAnswerColor(answer: string, correctAnswer: string[]) {
  const baseStyle = 'flex justify-center items-center text-center w-12 min-w-fit rounded-lg border border-am-700 py-1 px-2 uppercase text-xxs  font-semibold'
  if (correctAnswer.includes(answer)) {
    return "bg-am-600 text-white " + baseStyle;
  }
  return baseStyle;
}

export function QuestionList({questionList, selectedQuestions, setSelectedQuestions, editable}: QuestionListProps) {

  const intl = useIntl();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editIdQuestion, setEditIdQuestion] = useState<number | undefined>();

  return (
    <div>
      <ul role="list" className="divide-y divide-gray-100">
        {questionList.items.map((question) => (
          <li
            key={question.id}
            className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
          >
            <div className="flex flex-1">
              <div>
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {question.des_question}
                </p>
                <div className="flex flex-row gap-2 mt-2 overflow-x-auto mr-3">
                  {question.des_answers.map((ans) => (
                    <div key={ans} className={getAnswerColor(ans, question.des_correct_answers)}>
                      {ans}
                    </div>
                  ))}
                </div>
                {/*mettiamo anche un warning se la domanda è deprecata!*/}
                <div className="my-2 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  #TODO #Lista #Tag
                </div>
                <div className="mt-2 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>
                    {question.member.member}
                    <span className="mx-1.5">&#8226;</span>
                    {intl.formatDate(question.dat_creation)}
                  </p>
                </div>
              </div>
            </div>
            {editable &&
              <div className="cursor-pointer">
                <PencilSquareIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                  onClick={() => {
                    setShowEditModal(true);
                    setEditIdQuestion(question.id)
                  }}
                />
              </div>
            }
            {selectedQuestions && setSelectedQuestions &&
              <div className="ml-3 flex flex-none h-6 items-center">
                <input
                  key={question.id}
                  id="comments"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-am-700 focus:ring-am-700"
                  onClick={() => {
                    setSelectedQuestions([...selectedQuestions, question.id])
                  }}
                />
              </div>
            }
          </li>
        ))}
      </ul>
      {showEditModal && editIdQuestion && <CreateOrUpdateQuestionModal
        onExit={() => {
          setShowEditModal(false);
          setEditIdQuestion(undefined);
        }}
        edit={true}
        idQuestion={editIdQuestion}
      />}
    </div>
  )
}