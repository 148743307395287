import React, {useContext, useEffect, useState} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import _ from "lodash";

import {BellAlertIcon, EnvelopeIcon, PencilIcon, UserPlusIcon} from "@heroicons/react/24/outline";
import Button from "@ui-components/Button";
import Select from "@ui-components/Select";
import Alert from "@ui-components/Alert";
import Modal from "@ui-components/Modal";
import Input from "@ui-components/Input";

import {getMemberAnswer, getMemberAttendance, getMemberPhysicalTest, getKpi} from "./DetailComponents.lib";
import {attendanceOptions, physicalTestOptions} from "./DetailComponents.lib";
import {ParticipantRowProps, ParticipantsProps} from "../Detail.type";
import {MemberActivityWithMember} from "@services/types/activity";
import {dateFormat} from "@pages/Planner/Absences/Absences.lib";
import Checkbox from "@ui-components/Checkbox";


function ParticipantRow({i, intl, data, setData, activity, editMode}: ParticipantRowProps) {
  const basicStyle = "p-2 sm:grid sm:gap-4 border bg-gray-50 hover:bg-white rounded-xl text-sm"
  const oneTest = activity.flg_physical_test || activity.flg_technical_test
  const twoTest = activity.flg_physical_test && activity.flg_technical_test

  return (
    <div className={`${basicStyle} ${twoTest ? 'sm:grid-cols-3 xl:grid-cols-7' : oneTest ? 'sm:grid-cols-3 xl:grid-cols-7' : 'sm:grid-cols-3'}`}>
      <dt className={`flex flex-col ${oneTest ? 'sm:col-span-2' : ''}`}>
        <span className="text-xs">{i.member.id_fip_code} - {i.member.category.des_sub_category}</span>
        <span className="font-bold">{i.member.member}</span>
      </dt>
      <dt className={`flex flex-row gap-3 sm:gap-6 items-center sm:justify-end xl:justify-start ${oneTest ? 'xl:col-span-2' : ''}`}>
        <div className="text-gray-900 flex flex-row sm:flex-col gap-3 sm:gap-0 sm:justify-center">
          {editMode && !i.tms_invitation ?
            <div className="flex flex-row items-center gap-2">
              {/*@ts-ignore*/}
              <Checkbox
                checked={i.force_invitation}
                classNames={undefined}
                onChange={() => setData({...data, [i.id_fip_code]: {...i, force_invitation: !i.force_invitation}})}
              />
              <span className="text-xs">Segna come convocato e presente</span>
            </div> :
            <div className="flex flex-row gap-2 my-2 sm:my-0">
              <div><EnvelopeIcon className="h-4 w-4"/></div>
              <span className="text-xxs">
                {i.tms_invitation ? `Convocato il ${intl.formatDate(i.tms_invitation, dateFormat)}` : '-'}
              </span>
            </div>
          }
          {i.tms_reminder ?
            <div className="flex flex-row gap-2 my-2 sm:my-0">
              <div><BellAlertIcon className="h-4 w-4"/></div>
              <span className="text-xxs">
            Sollecitato il {intl.formatDate(i.tms_reminder, dateFormat)}
          </span>
            </div> : null
          }
        </div>
        {i.tms_invitation ? getMemberAnswer(i.flg_accepted, i.reason_decline) : null}
      </dt>
      <dt className={`flex flex-row items-center my-2 sm:my-0 ${oneTest ? 'sm:col-span-3' : ''}`}>
        <div className={`${oneTest ? 'w-full' : ''}`}>
          {editMode ?
            <div className="-my-1">
              <Select
                options={attendanceOptions}
                value={i.attendance || '-'}
                label={undefined}
                //@ts-ignore
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData({
                  ...data,
                  [i.id_fip_code]: {...i, attendance: e.target.value === '-' ? null : e.target.value}
                })}
                customStyles={undefined}
              />
            </div> : getMemberAttendance(i.attendance)
          }
        </div>
        <div className={`pl-3 ${oneTest ? 'w-full' : ''}`}>
          {activity.flg_physical_test ?
            editMode ?
              <Select
                options={physicalTestOptions}
                value={i.physical_test_result || '-'}
                label={undefined}
                //@ts-ignore
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData({
                  ...data,
                  [i.id_fip_code]: {...i, physical_test_result: e.target.value === '-' ? null : e.target.value}
                })}
                customStyles={undefined}
              /> : getMemberPhysicalTest(i.physical_test_result)
            : null
          }
        </div>
        {activity.flg_technical_test ?
          <div className={`w-48 ${editMode ? '-mb-2' : ''} pl-3`}>
            {editMode ?
              //@ts-ignore
              <Input
                type="number"
                onClear={() => setData({
                  ...data, [i.id_fip_code]: {...i, num_errors: null}
                })}
                placeholder="errori"
                value={i.num_errors !== null && i.num_errors !== undefined ? i.num_errors : ''}
                //@ts-ignore
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData({
                  ...data, [i.id_fip_code]: {...i,
                    num_errors: Number(e.target.value) < 0 ? null : Number(e.target.value)
                  }
                })}
                numberOptions={{minimumFractionDigits: 0, maximumFractionDigits: 0}}
              />
              : i.num_errors || i.num_errors === 0
                ? <span><b>{i.num_errors} errori</b> / {activity.num_questions}</span>
                : <span className="text-xs text-gray-300 items-center">Registrare quiz</span>

            }
          </div> : null
        }
      </dt>
    </div>
  )
}


export function Participants({intl, activity, setActivity, setAddParticipants, canCreate}: ParticipantsProps) {

  const {push} = useContext(NotificationsContext);

  const participants = activity.members_activities.filter(i => i.activity_role === 'STUDENT')
  const notInvited = participants.filter(i => !i.tms_invitation)
  const [data, setData] = useState<{ [key: number]: MemberActivityWithMember }>(participants
    .reduce((obj, i) => _.set(obj, [i.id_fip_code], i), {}))
  const [showSendInvitation, setShowSendInvitation] = useState<boolean>(false);
  const [showSendReminder, setShowSendReminder] = useState<boolean>(false);
  const [reminderDays, setReminderDays] = useState<number>(2);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [polling, setPolling] = useState({des: "", try: 0})

  const totalErrors = participants.reduce((tot, i) => tot + (i.num_errors ?? 0), 0)
  const den = participants.filter(i => i.num_errors !== undefined && i.num_errors !== null).length
  const avgErrors = totalErrors / den

  const oneTest = activity.flg_physical_test || activity.flg_technical_test
  const twoTest = activity.flg_physical_test && activity.flg_technical_test

  const isInvitationIncomplete = () => {
    return !activity.invitation_email || !activity.invitation_email.subject || !activity.invitation_email.content
  }

  const sendConvocation = () => {
    setLoading(true)
    api.get(`/activities/send-invitation/${activity.id}`)
      .then(() => {
        setPolling({des: "pending", try: 1})
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
        push({title: "Errore del server", type: "error"});
      })
  }

  useEffect(() => {
    if (polling.des === 'pending') {
      console.log(polling)
      setTimeout(() => {
        api.get(`/activities/check-invitation/${activity.id}`)
          .then(({data: allInvited}) => {
            if (allInvited) {
              setLoading(false);
              setActivity(undefined);
              setShowSendInvitation(false);
              setPolling({des: "done", try: 0});
              push({title: `Convocazione inviata a ${notInvited.length} tesserati`, type: "success"});
            } else {
              if (polling.try > 12) {
                push({title: "Non è stato possibile inviare la convocazione a tutti i partecipanti, controllare la lista"});
                setLoading(false);
                setActivity(undefined);
                setShowSendInvitation(false);
                setPolling({des: "done", try: 0});
              } else {
                setPolling({des: "pending", try: polling.try + 1})
              }
            }
          })
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polling])

  const sendReminder = () => {
    setLoading(true)
    api.get(`/activities/send-reminder/${activity.id}/${reminderDays}`)
      .then(({data}) => {
        setActivity(undefined);
        setShowSendReminder(false);
        push({title: `Reminder inviato a ${data} tesserati`, type: "success"});
      })
      .catch((err) => {
        if (err.response.status === 404) {
          push({title: "Non ci sono tesserati da sollecitare", type: "error"});
        } else {
          push({title: "Errore del server", type: "error"});
        }
      })
      .finally(() => setLoading(false))
  }

  const updateData = () => {
    setLoading(true)
    api.put("/activities/members/update-data", Object.values(data))
      .then(() => {
        setActivity(undefined);
        push({title: "Dati aggiornati", type: "success"});
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="flex flex-col mt-5 sm:mt-8 py-2 gap-3">
      <div className="flex flex-row justify-between border-b border-gray-800">
        <h1 className="text-lg font-bold">Convocati {participants.length ? `(${participants.length})` : ''}</h1>
        {canCreate ? <span className="font-medium flex flex-col sm:flex-row sm:divide-x divide-gray-400 pb-1 items-end">
          {participants.length ?
            <span
              className="sm:pr-3 text-am-700 cursor-pointer hover:font-bold"
              onClick={() => setShowSendReminder(true)}
            >
              <BellAlertIcon className="h-4 w-4 inline-block -mt-1"/> Invia sollecito
            </span> : null
          }
          <span
            className="sm:px-3 text-am-700 cursor-pointer hover:font-bold"
            onClick={() => setAddParticipants(true)}
          >
            <UserPlusIcon className="h-4 w-4 inline-block -mt-1"/> Gestisci convocati
          </span>
          {editMode ?
            <div className="flex pl-3 gap-2">
              <Button className="text-xs h-8" submitting={loading} onClick={updateData}>
                Salva
              </Button>
              <Button className="text-xs h-8" styleType="white" onClick={() => setEditMode(false)}>
                Esci
              </Button>
            </div> :
            <span
              className="sm:pl-3 text-am-700 cursor-pointer hover:font-bold"
              onClick={() => setEditMode(true)}
            >
            <PencilIcon className="h-4 w-4 inline-block -mt-1"/> Aggiorna dati
          </span>
          }
        </span> : null}
      </div>
      {notInvited.length ?
        <div className="my-2">
          <Alert
            slim
            type="warning"
            title={`${notInvited.length} tesserati non hanno ricevuto la convocazione`}
            text={<span className="font-bold hover:underline cursor-pointer"
                        onClick={() => setShowSendInvitation(true)}>
                <EnvelopeIcon className="h-4 w-4 inline-block -mt-1"/> Invia convocazione</span>}
          />
        </div> : null
      }
      <div className={`grid grid-cols-2 ${twoTest ? 'sm:grid-cols-3 xl:grid-cols-6' : oneTest ? 'sm:grid-cols-3 xl:grid-cols-5' : 'sm:grid-cols-4'} text-xl justify-between mt-3`}>
        {getKpi('Tesserati', participants.length)}
        {getKpi('Convocati', participants.length - notInvited.length)}
        {getKpi('Risposte mancanti', participants.filter(i => i.flg_accepted === undefined).length)}
        {getKpi('Presenti', participants.filter(i => i.attendance === 'PRESENT').length)}
        {activity.flg_physical_test ?
          getKpi('T.F. superati', participants.filter(i => i.physical_test_result === 'PASSED').length)
          : null
        }
        {activity.flg_technical_test ?
          getKpi('Media errori', den ? intl.formatNumber(avgErrors, {maximumFractionDigits: 1}) : '-')
          : null
        }
      </div>
      <div className="flex flex-col py-2 gap-1">
        {participants.length ?
          Object.values(data)
            .sort((a, b) => {
              const compareGroup = a.member.category.cod_group.localeCompare(b.member.category.cod_group)
              const compareCategory = a.member.category.sub_cat_order - b.member.category.sub_cat_order
              const compareName = a.member.member.localeCompare(b.member.member)
              return compareGroup || compareCategory || compareName
            })
            .map(i =>
              <div key={i.id_fip_code} className="flex flex-col whitespace-nowrap">
                {/*TODO add list component with pagination*/}
                <ParticipantRow
                  i={i}
                  intl={intl}
                  data={data}
                  setData={setData}
                  activity={activity}
                  editMode={editMode}
                />
              </div>
            ) :
          <div className="my-5">
            <Alert title="Nessun tesserato convocato per questa attività tecnica"/>
          </div>
        }
      </div>
      {showSendInvitation ?
        <Modal onExit={() => setShowSendInvitation(false)}>
          <div className="m-5">
            <h1 className="font-bold sm:text-xl">Sei sicuro di voler inviare la convocazione?</h1>
            <h3 className="text-xs sm:text-sm my-3">
              Tutti i tesserati non ancora convocati ({notInvited.length}) riceveranno la mail di convocazione
              all'attività tecnica.
            </h3>
            <div className="my-6">
              <Alert title="Il processo potrebbe durare diversi secondi, in base al numero dei convocati"/>
            </div>
          </div>
          <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
            <Button styleType="white" onClick={() => setShowSendInvitation(false)}>
              Annulla
            </Button>
            <Button onClick={sendConvocation} submitting={loading} disabled={isInvitationIncomplete()}>
              Invia
            </Button>
          </div>
        </Modal> : null
      }
      {showSendReminder ?
        <Modal onExit={() => setShowSendReminder(false)}>
          <div>
            <h1 className="font-bold sm:text-xl mr-5 mb-5">Vuoi inviare un reminder a chi ancora non ha risposto?</h1>
            {/*@ts-ignore*/}
            <Input
              type="number"
              label="Giorni minimi di ritardo"
              value={reminderDays}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setReminderDays(Number(e.target.value) > 0 ? Number(e.target.value) : 1)
              }}
              numberOptions={{minimumFractionDigits: 0, maximumFractionDigits: 0}}
            />
            <h3 className="text-xs sm:text-sm my-5">
              Tutti i convocati che non hanno risposto da almeno {reminderDays} giorni riceveranno un messaggio di
              sollecito
            </h3>
          </div>
          <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
            <Button styleType="white" onClick={() => setShowSendReminder(false)}>
              Annulla
            </Button>
            <Button onClick={sendReminder} submitting={loading}>
              Invia
            </Button>
          </div>
        </Modal> : null
      }
    </div>
  )
}