import React, {useContext, useState} from "react";

import {QuestionListFrame} from "@components/RefMan/Quiz/Question/QuestionList";
import {useTagsFetch} from "@components/RefMan/Quiz/hooks/useTagsFetch";
import {useQuestion} from "@components/RefMan/Quiz/hooks/useQuestion";
import {NotificationsContext} from "@ui-components/Notifications";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import {AddQuestionToQuizModalProps} from "@components/RefMan/Quiz/types";

export function SelectQuestionFromListModal(
  {
    onExit,
    idQuiz,
    setRefreshData
  }: AddQuestionToQuizModalProps) {

  const {push} = useContext(NotificationsContext);
  const [selectedQuestions, setSelectedQuestions] = useState<number[]>([]);

  // hooks
  // TODO
  const {data} = useTagsFetch();
  const {associateQuestionToQuiz} = useQuestion();

  const onSaveClickHandle = () => {
    if (idQuiz && selectedQuestions.length > 0) {
      associateQuestionToQuiz(idQuiz, selectedQuestions)
        .then(() => {
          setRefreshData(prev => !prev);
          onExit();
        })
        .catch((err) => {
          console.log(err);
          push({title: "Errore del server", type: "error"})
        });
    }
  }

  return (
    <Modal
      maxWidth="w-full sm:max-w-4xl"
      onExit={onExit}
    >
      <div className="mb-5 flex flex-col gap-2">
        <h1 className="font-bold sm:text-xl mb-4">Anagrafica domande</h1>
      </div>
      <QuestionListFrame
        editable={false}
        showFilter={true}
        selectable={true}
        selectedQuestions={selectedQuestions}
        setSelectedQuestions={setSelectedQuestions}
      />
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button onClick={onSaveClickHandle}>
          Aggiungi
        </Button>
      </div>
    </Modal>
  );
}
