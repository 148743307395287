import React from 'react';

// Components
import SearchSelect from "@ui-components/SearchSelect";
import Button from '@ui-components/Button';
import Modal from "@ui-components/Modal";

// Services
import {
  assignConstraintToGame,
  getGameAssignmentOptions,
  getOptionWithTeamCounters
} from "@pages/Nominations/Algorithms/EliteAlgorithm.services";
import {dateFormatWithWeekday} from "@pages/Planner/Absences/Absences.lib";

// Types
import {
  GameParametersModalProps,
  SearchSelectAssignmentOptionSchema
} from "@pages/Nominations/Algorithms/Algorithms.types";
import {HandRaisedIcon, HandThumbUpIcon} from "@heroicons/react/24/outline";


function GameParametersModal(
  {
    intl,
    group,
    game,
    distances,
    relocations,
    userKwargs,
    setUserKwargs,
    setShowGameParameters,
    saveParameters,
    setToSave
  }: GameParametersModalProps) {

  let _selected: { label: string, value: number }[] = [];
  let _selectedForbidden: { label: string, value: number }[] = [];

  // These are members already assigned to specific roles in the game
  const RoleGameAssigned = (userKwargs?.member_role_game.assign.filter(i => i[2] === game.cod_game) ?? []).map(i => i[0]);

  // These are members already assigned and forbidden to the game
  const gameAssigned = (userKwargs?.member_game.assign.filter(i => i[1] === game.cod_game) ?? []).map(i => i[0]);
  const gameForbidden = (userKwargs?.member_game.forbidden.filter(i => i[1] === game.cod_game) ?? []).map(i => i[0]);

  // Controllo se quelli che avevo assegnato sono ancora disponibili
  RoleGameAssigned.concat(gameAssigned).forEach(idFipCode => {
    const memberOption = game.assignment_options.filter(i => i.id_member === idFipCode)
    if (memberOption.length) {
      _selected.push({label: memberOption[0].member.tag, value: idFipCode})
    }
  })

  gameForbidden.forEach(idFipCode => {
    const memberOption = game.assignment_options.filter(i => i.id_member === idFipCode)
    if (memberOption.length) {
      _selectedForbidden.push({label: memberOption[0].member.tag, value: idFipCode})
    }
  })

  const rolesToMatch = group === 'ref' ? ['ref'] : ['sp', 'crono', 'a24s']
  const limit = group === 'ref' ? 2 : 3

  return (
    <Modal onExit={() => setShowGameParameters(undefined)}>
      <h1 className="font-bold mb-2">Parametri gara <span className="text-gray-700">#{game.game_number}</span></h1>
      <h1 className="text-sm">
        di <b>{intl.formatDate(game.dat_game, dateFormatWithWeekday)}</b> tra <i>{game.team_a.team_alias}</i> e <i>{game.team_b.team_alias}</i>
      </h1>
      <div className="flex flex-col gap-3 mt-8 mb-5">
        <SearchSelect
          label={<span><HandThumbUpIcon className="inline-block w-5 mr-1 mb-1 text-am-700"/>Tesserati da designare</span>}
            options={getGameAssignmentOptions(game.assignment_options, userKwargs.member_role_game.assign, rolesToMatch)}
          value={_selected}
          onChange={(members: { value: number, label: string }[]) => {

            // Trick to get only one 2 or 3 selectable at time
            const selectedMembers = members.slice(0, limit).map(i => i.value)
              .filter(i => !RoleGameAssigned.includes(i) && !gameForbidden.includes(i));

            // Update round-constraint on EliteAlgorithmGames component
            assignConstraintToGame(game, userKwargs, setUserKwargs, selectedMembers, 'assign');
            setToSave(true);
          }}
          isMulti={true}
          loadOptions={null}
          CustomOption={(props: { data?: SearchSelectAssignmentOptionSchema; innerProps?: any; innerRef?: any; }) => {
            return getOptionWithTeamCounters(intl, props, distances, relocations, game.city?.id ?? 0, game.dat_game)
          }}
          minHeight={10}
        />
        <SearchSelect
          label={<span><HandRaisedIcon className="inline-block w-5 mr-1 mb-1 text-red-600"/>Tesserati da non designare</span>}
          options={getGameAssignmentOptions(game.assignment_options, userKwargs.member_role_game.assign, rolesToMatch)}
          value={_selectedForbidden}
          onChange={(members: { value: number, label: string }[]) => {
            // Trick to get only one single option selectable at time

            const selectedMembers = members.map(i => i.value)
            .filter(i => !gameAssigned.includes(i));

            // Update round-constraint on EliteAlgorithmGames component
            assignConstraintToGame(game, userKwargs, setUserKwargs, selectedMembers, 'forbidden');
            setToSave(true);
          }}
          isMulti={true}
          loadOptions={null}
          CustomOption={(props: { data?: SearchSelectAssignmentOptionSchema; innerProps?: any; innerRef?: any; }) => {
            return getOptionWithTeamCounters(intl, props, distances, relocations, game.city?.id ?? 0, game.dat_game)
          }}
          minHeight={10}
        />
      </div>
      <div className="mt-5 flex flex-row justify-between">
        <Button styleType="white" onClick={() => setShowGameParameters(undefined)}>
          Annulla
        </Button>
        <Button onClick={() => {
          setShowGameParameters(undefined)
          saveParameters()
        }}>
          Salva
        </Button>
      </div>
    </Modal>
  );
}

export default GameParametersModal;