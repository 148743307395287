import React from "react";

import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import {DeleteQuizProps} from "@components/RefMan/Quiz/types";
import {useQuiz} from "@components/RefMan/Quiz/hooks/useQuiz";
import {useHistory} from "react-router-dom";
import Input from "@ui-components/Input";


export function QuizCopyModal({push, onExit, idQuiz}: DeleteQuizProps) {

  const {copyQuiz, loading} = useQuiz();
  const [desTitle, setDesTitle] = React.useState<string | undefined>(undefined);

  const history = useHistory();

  return (
    <Modal onExit={onExit}>
      <h1 className="font-bold sm:text-xl">Confermi di voler copiare il quiz?</h1>
      <div className="w-full my-8">
        <label className="block uppercase tracking-wide text-xs font-bold mb-2">
          Nome del nuovo quiz
        </label>
        <Input
          label=''
          value={desTitle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDesTitle(
            e.target.value
          )}
          onClear={() => setDesTitle(undefined)}
          name={undefined}
          validation={(value: string) => value !== ''}
          required={true}
          errorMessage={true}
          showCalendar={undefined}/>
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button
          disabled={!desTitle}
          submitting={loading}
          onClick={() => {
            if (desTitle)
              copyQuiz(idQuiz, desTitle)
                .then(() => {
                  onExit();
                  push({title: "Quiz duplicato correttamente", type: "success"});
                  history.push(`/technical-area/quiz`)
                })
                .catch(() => push({title: "Errore imprevisto", type: "success"}))
          }}>
          Conferma
        </Button>
      </div>
    </Modal>
  );
}
