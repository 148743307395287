import React, { useContext, useEffect, useState } from 'react';

// Services
import AuthenticationService from '@services/AuthenticationService';
import { NotificationsContext } from '@ui-components/Notifications';
import { api } from '@services/apiRequest';

// Data
import useMembers from '@services/hooks/useMembers';
import useLevels from '@services/hooks/useLevels';
import useTeams from '@services/hooks/useTeams';

// Components
import RefManList from '@components/RefMan/RefManList/RefManList';
import { MemberSearch } from '@pages/Members/Members.lib';
import { getListItems } from './Players.lib';

// Ui
import { PlusIcon } from '@heroicons/react/24/outline';
import Placeholder from '@ui-components/Placeholder';
import { PageHeading } from '@ui-components/Container';
import Button from '@ui-components/Button';
import Alert from '@ui-components/Alert';
import Modal from '@ui-components/Modal';

// Types
import { Player } from './Players.type';
import { AddPlayerDialog } from '@pages/Planner/Players/AddPlayerDialog';

export function PlannerPlayers() {
  const { push } = useContext(NotificationsContext);
  const scopes = AuthenticationService.getUserScopes();
  const isMember = scopes.includes('absences:personal');
  const idFipCode = isMember ? AuthenticationService.getUserFipCode() : undefined;
  const teams = useTeams();
  const levels = useLevels({ onlyManaged: true, asObj: false });
  const members = useMembers({ onlyActive: true, assigned: true, asObj: false });

  const [players, setPlayers] = useState<Player[] | undefined>(undefined);
  const [filterMember, setFilterMember] = useState<string>();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deletePlayer, setDeletePlayer] = useState<Player | undefined>();
  const [offset, setOffset] = useState<number>(0);
  const [filteredPlayers, setFilteredPlayers] = useState<Player[] | []>([]);
  const limit = 10;

  useEffect(() => {
    const fetchData = () => {
      api
        .get(isMember ? `/players?id_fip_code=${idFipCode}` : '/players')
        .then(({ data }) => {
          setPlayers(data.reverse());
        })
        .catch(() => {
          push({ title: 'Errore del server', type: 'error' });
        });
    };

    if (players === undefined) {
      fetchData();
    }
  }, [players]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (players) {
      if (filterMember) {
        setFilteredPlayers(players.filter((i) => i.member.member.toLowerCase().match(filterMember.toLowerCase())));
      } else {
        setFilteredPlayers(players);
      }
    } else {
      setFilteredPlayers([]);
    }
  }, [players, filterMember]);

  const sendDelete = () => {
    if (deletePlayer) {
      setLoading(true);
      api
        .delete(`/players/${deletePlayer.id_fip_code}/${deletePlayer.id_team}`)
        .then(() => {
          setPlayers((players ?? []).filter((i) => i.id_fip_code !== deletePlayer.id_fip_code || i.id_team !== deletePlayer.id_team));
          push({ title: 'Elemento eliminato con successo', type: 'success' });
          setDeletePlayer(undefined);
        })
        .catch(() => {
          push({ title: 'Errore del server', type: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <PageHeading title={isMember ? 'Attività da giocatore' : 'Giocatori'} historyBackPath='_back' />
      {players ? (
        <div className='my-5'>
          {isMember ? null : (
            <div className='mb-5 flex flex-row justify-between gap-10 items-center'>
              <div className='w-full'>
                <MemberSearch label='Cerca tesserato' searchKey={filterMember} setSearchKey={setFilterMember} />
              </div>
              <div>
                <Button onClick={() => setShowAdd(true)}>
                  <PlusIcon className='w-5 h-5 mr-3' /> Aggiungi
                </Button>
              </div>
            </div>
          )}
          {filteredPlayers.length > 0 ? (
            <div className='mb-10'>
              <RefManList
                offset={offset}
                setOffset={setOffset}
                limit={limit}
                total={filteredPlayers.length}
                containerId='observer-reports-list'
                items={getListItems(filteredPlayers, isMember, setDeletePlayer)}
                rowClassNames={['sm:grid', 'sm:grid-cols-5', '!cursor-default']}
                pagination='frontend'
              />
            </div>
          ) : (
            <div className='my-10'>
              <Alert title='Nessuna squadra indicata per attività da giocatore' />
            </div>
          )}
          {isMember ? (
            <Button onClick={() => setShowAdd(true)}>
              <PlusIcon className='w-5 h-5 mr-3' /> Squadra
            </Button>
          ) : null}
        </div>
      ) : (
        <div className='flex flex-col w-full my-10 gap-2'>
          <Placeholder height='h-12' classNames='rounded-lg' />
          {isMember ? (
            <Placeholder height='h-24' classNames='rounded-lg' />
          ) : (
            [...new Array(5)].map((each, i) => <Placeholder key={i} height='h-20' classNames='rounded-lg bg-am-600' />)
          )}
        </div>
      )}
      {showAdd && members && levels && teams ? (
        <AddPlayerDialog
          setShowAdd={setShowAdd}
          setPlayers={setPlayers}
          isMember={isMember}
          idFipCode={idFipCode}
          members={members}
          levels={levels}
          teams={teams}
        />
      ) : null}
      {deletePlayer ? (
        <Modal onExit={() => setDeletePlayer(undefined)}>
          <div className='m-5'>
            <h1 className='font-bold sm:text-xl'>Confermi di voler eliminare l'elemento?</h1>
            <h3 className='text-xs sm:text-sm my-3'>Non sarà possibile annullare l'operazione.</h3>
          </div>
          <div className='w-full flex flex-col sm:flex-row gap-3 justify-between'>
            <Button styleType='white' onClick={() => setDeletePlayer(undefined)}>
              Annulla
            </Button>
            <Button onClick={sendDelete} submitting={loading}>
              Conferma
            </Button>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
