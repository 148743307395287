import React from "react";
import Icon from "./Icon";
import SmallNotification from "./SmallNotification";

const buttonStyles = {
  default: "text-white bg-am-600 hover:bg-am-700",
  white: "text-gray-700 bg-white hover:bg-gray-50",
  secondary: "text-am-700 bg-indigo-100 hover:bg-indigo-200",
  danger: "text-white bg-red-600 hover:bg-red-700",
};

const IconButton = ({
                      classNames = "",
                      styleType = "default",
                      iconName,
                      label = "",
                      notification = 0,
                      disabled = false,
                      ...rest
                    }) => {

  return (

    <button
      className={`flex justify-center content-center uppercase border border-transparent font-medium rounded-full shadow-sm focus:outline-none active:opacity-70 ${buttonStyles[styleType]} ${disabled && "opacity-20 pointer-events-none"} ${notification && 'relative z-0'} ${classNames}`}
      {...rest}
    >
      {notification > 0 &&
        <SmallNotification classStyle="-right-4 -top-2 z-20 absolute" counter={notification} enableAnimation/>}
      <div className="flex flex-col items-center justify-center">
        <Icon iconName={iconName}/>
        {label && <h4 className="text-tiny text-center">{label}</h4>}
      </div>
    </button>
  );
};

export default IconButton;
