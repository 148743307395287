import React, {useState, useEffect} from 'react';

// Model
import {DesignatorLevelType} from '@pages/Nominations/Algorithms/Algorithms.types';
import {GamesSchema} from '@pages/Games/Games.type';

// Services
import {api} from '@services/apiRequest';
import {Distance, DistancesDict} from "@services/types/city";
import {RelocationSchema} from "@pages/Planner/Calendar/Calendar.type";


export const getDistancesDict = (_distances: Distance[]) => {
  return _distances.reduce((acc, {id_city_a, id_city_b, km, toll}) => {
        if (!acc[id_city_a]) acc[id_city_a] = {};
        acc[id_city_a][id_city_b] = {km, toll};
        return acc;
      }, {} as DistancesDict);
}

function useEliteAlgorithmGames(
  designator_level: DesignatorLevelType,
  des_round: string,
  group: 'ref' | 'udc',
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  const [games, setGames] = useState<GamesSchema[]>();
  const [distances, setDistances] = useState<DistancesDict | undefined>();
  const [relocations, setRelocations] = useState<RelocationSchema | undefined>();

  useEffect(() => {
    setLoading(true);

    async function _fetchEliteGames() {
      const params = new URLSearchParams();
      params.append('designator_level', designator_level as string);
      params.append('des_round', des_round);
      params.append('group', group.toUpperCase());

      const {data: _games} = await api.get<GamesSchema[]>(`/games/elite?${params}`);
      const {data: _distances} = await api.post<Distance[]>('/cities/distances', {
        cities: _games.map(i => i.city ? i.city.id : 0)
      });

      const distancesDict: DistancesDict = getDistancesDict(_distances)

      if (_games.length) {
        const {minDate, maxDate} = _games.reduce((acc, game) => {
          if (game.dat_game < acc.minDate) {acc.minDate = game.dat_game}
          if (game.dat_game > acc.maxDate) {acc.maxDate = game.dat_game}
          return acc;
        }, {minDate: _games[0].dat_game, maxDate: _games[0].dat_game});
        const {data: _relocations} = await api.get<RelocationSchema>(`/calendar/relocations/${group.toUpperCase()}/${minDate}/${maxDate}`);
        setRelocations(_relocations)
      } else {
        setRelocations({})
      }

      setDistances(distancesDict);
      setGames(_games);
      setLoading(false);
    }

    _fetchEliteGames();
  }, [designator_level, des_round]);

  return {games, distances, relocations};
}

export default useEliteAlgorithmGames;