import React, {useContext, useEffect, useState} from "react";

import AuthenticationService from "@services/AuthenticationService";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";

// Ui
import {PencilIcon, EnvelopeIcon} from "@heroicons/react/24/outline";
import Select from "@ui-components/Select";
import Button from "@ui-components/Button";
import Alert from "@ui-components/Alert";
import Input from "@ui-components/Input";

import {UpdateInvitationDialog} from "./UpdateInvitationDialog";
import {TestEmailDialog} from "./TestEmailDialog";

import {answerOptions, MemberAnswerCard} from "./DetailComponents.lib";
import {InvitationProps} from "@pages/Activities/Detail/Detail.type";


export function Invitation({activity, setActivity, viewMode, canCreate, memberActivity}: InvitationProps) {

  const {push} = useContext(NotificationsContext);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [testMail, setTestMail] = useState<boolean>(false);
  const [showInvitation, setShowInvitation] = useState<boolean>(false);
  const [changeAnswer, setChangeAnswer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState<boolean | undefined>(undefined);
  const [reason, setReason] = useState<string | undefined>(undefined);
  const userIdFipCode = AuthenticationService.getUserFipCode();

  const allowChangeAnswer = new Date(activity.dat_activity) > new Date()

  useEffect(() => {
    if (viewMode && memberActivity) {
      setAnswer(memberActivity?.flg_accepted)
      setReason(memberActivity?.reason_decline)
    }
  }, [viewMode, memberActivity])

  const sendAnswer = () => {
    setLoading(true)
    api.put(`/activities/members/${userIdFipCode}/${activity.id}`, {
      flg_accepted: answer,
      reason_decline: reason
    })
      .then(() => {
        setActivity(undefined);
        push({title: "Dati aggiornati", type: "success"});
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="flex flex-col mb-5 py-2 gap-3 text-sm">
      <div className="flex justify-between border-b border-gray-800">
        <h1 className="text-lg font-bold">Convocazione</h1>
        <span className="font-medium flex flex-col sm:flex-row sm:divide-x divide-gray-400 pb-1 items-end">
          {viewMode ?
            changeAnswer ?
              <div className="flex pl-3 gap-2">
                <Button className="text-xs h-8" submitting={loading} onClick={sendAnswer}
                        disabled={!answer && !reason}>
                  Salva
                </Button>
                <Button className="text-xs h-8" styleType="white" onClick={() => setChangeAnswer(false)}>
                  Esci
                </Button>
              </div>
              :
              <span
                className={`${allowChangeAnswer ? 'text-am-700 cursor-pointer hover:font-bold' : 'text-gray-400'}`}
                onClick={() => {
                  if (allowChangeAnswer) {
                    setChangeAnswer(true)
                  }
                }}>
              <PencilIcon className="h-4 w-4 inline-block -mt-1"/> Modifica risposta
            </span>
            :
            canCreate ? <span className="sm:pr-3 text-am-700 cursor-pointer hover:font-bold" onClick={() => setEditMode(true)}>
              <PencilIcon className="h-4 w-4 inline-block -mt-1"/> Modifica
            </span> : null
          }
          {viewMode || !canCreate ? null :
            <span className="sm:pl-3 text-am-700 cursor-pointer hover:font-bold" onClick={() => setTestMail(true)}>
              <EnvelopeIcon className="h-4 w-4 inline-block -mt-1"/> Invia mail di test
            </span>
          }
        </span>
      </div>

      {viewMode ?
        <div className="my-2 flex flex-col sm:flex-row gap-5">
          <div className="w-full">
            <Button styleType="white" onClick={() => setShowInvitation(true)}>
              Rivedi convocazione
            </Button>
          </div>
          <div className="w-full">
            {changeAnswer
              ? <div className="flex flex-col">
                <div>
                  <Select
                    options={answerOptions}
                    value={answer === undefined || answer === null ? '-' : answer}
                    label={undefined}
                    //@ts-ignore
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setReason(undefined)
                      if (e.target.value === '-') {
                        setAnswer(undefined)
                      } else {
                        setAnswer(e.target.value === 'true')
                      }
                    }}
                    customStyles={undefined}
                  />
                </div>
                {answer === false ?
                  <div className="w-full">
                    {/*@ts-ignore*/}
                    <Input
                      value={reason}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setReason(e.target.value)
                      }}
                      placeholder="Inserisci la motivazione"
                      type="text"
                      errorMessage="Indica la motivazione"
                      required
                    />
                  </div>
                  : null
                }
              </div>
              : <MemberAnswerCard
                originalAnswer={memberActivity?.flg_accepted}
                originalReason={memberActivity?.reason_decline}
              />
            }
          </div>
        </div>
        :
        <div className="w-full my-2">
          {!activity.invitation_email || !activity.invitation_email.subject || !activity.invitation_email.content
            ? <Alert type="warning" title="Inserire il testo della convocazione"/>
            : <Alert type="success" title={<div className="flex flex-col sm:flex-row items-center sm: gap-5">
              <span>Mail di convocazione archiviata</span>
              <div>
                <Button styleType="white" className="h-8 -mt-1" onClick={() => setShowInvitation(true)}>Vedi</Button>
              </div>
            </div>
            }/>
          }
        </div>
      }

      {editMode || showInvitation ?
        <UpdateInvitationDialog
          onExit={() => {
            setEditMode(false)
            setShowInvitation(false)
          }}
          activity={activity}
          setActivity={setActivity}
          viewMode={showInvitation}
        /> : null
      }
      {testMail ?
        <TestEmailDialog
          onExit={() => setTestMail(false)}
          activity={activity}
        /> : null
      }
    </div>
  )
}
