import React from "react";
import types from "./constants/status-types-config";

const Alert = ({
                 text,
                 title,
                 type = "info",
                 onConfirm,
                 confirmText = "OK",
                 onDismiss,
                 dismissText = "ANNULLA",
                 slim,
                 extraSlim
               } : {
  text?: any,
  title?: any,
  type?: string,
  onConfirm?: any,
  confirmText?: string,
  onDismiss?: any,
  dismissText?: string,
  slim?: boolean,
  extraSlim?: boolean,
}) => {
  // @ts-ignore
  if (!types[type]) {
    throw new Error("missing alert type");
  }

  const {
    Icon,
    classes: {
      light,
      textRegular,
      textExtraBold,
      textBold,
      hoverLight,
      focusOffset,
      focus,
    },
      // @ts-ignore
  } = types[type];

  return (
    <div className={`rounded-3xl ${light} px-4 ${extraSlim || slim ? "py-2" : "py-4"}`}>
      <div className="flex">
        <div className="shrink-0">
          <Icon className={`${extraSlim ? 'h-3 w-3' : slim ? 'h-4 w-4' : 'h-5 w-5'} ${textRegular}`} aria-hidden="true"/>
        </div>
        <div className="ml-3">
          {title ? (
            <h3 className={`text-sm font-medium ${textExtraBold} ${extraSlim ? 'text-xxs leading-3' : slim ? 'text-xs leading-4' : ''}`}>{title}</h3>
          ) : null}
          {text ? (
            <div className={`mt-2 text-sm ${textBold} ${extraSlim ? 'text-xxs' : slim ? 'text-xs' : ''}`}>
              <p>{text}</p>
            </div>
          ) : null}
          {onConfirm || onDismiss ? (
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                {onConfirm ? (
                  <button
                    onClick={onConfirm}
                    type="button"
                    className={`${light} px-2 py-1.5 rounded-3xl text-sm font-medium ${textExtraBold} ${hoverLight} focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusOffset} ${focus}`}
                  >
                    {confirmText}
                  </button>
                ) : null}
                {onDismiss ? (
                  <button
                    onClick={onDismiss}
                    type="button"
                    className={`ml-3 ${light} px-2 py-1.5 rounded-3xl text-sm font-medium ${textExtraBold} ${hoverLight} focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusOffset} ${focus}`}
                  >
                    {dismissText}
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Alert;
