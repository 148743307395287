import React, {useContext, useEffect, useState} from 'react';

// Services
import {DataContext} from '@services/DataContext/DataContext';
import AuthenticationService from '@services/AuthenticationService';

// Hooks & Model
import useLevels from '@services/hooks/useLevels';
import useChampionshipRounds from '@services/hooks/useChampionshipRounds';
import {LevelSchemaWithDesignator, DesignatorLevelType, ChampionshipOptions} from './Algorithms.types';

// Components
import Select from '@ui-components/Select';
import EliteAlgorithmGames from './EliteAlgorithmGames';
import {AlgoElitePlaceholder} from "@utils/placeholders";
import RadioGroup from "@ui-components/RadioGroup";
import Label from "@ui-components/Label";


function EliteAlgorithm() {
  const dataContext: any = useContext(DataContext);

  const [designatorLevelSelected, setDesignatorLevelSelected] = useState<DesignatorLevelType>();
  const [designatorLevelOptions, setDesignatorLevelOptions] = useState<DesignatorLevelType[]>();
  const [roundSelected, setRoundSelected] = useState<string>();
  const [sortBy, setSortBy] = useState<'datetime' | 'game'>('datetime');
  const levels: LevelSchemaWithDesignator[] = useLevels({asObj: false, managedGroup: dataContext.group});
  const availableRounds: string[] | undefined = useChampionshipRounds(designatorLevelSelected);

  useEffect(() => {
    if (!designatorLevelOptions && levels) {
      const userLevel = AuthenticationService.getUserLevel().toUpperCase();
      const _designatorLevelOptions: DesignatorLevelType[] =
        ['ALL', 'TOTAL'].includes(userLevel) ?
          levels.filter(i => i.designator_level !== null).map(i => i.designator_level) :
          levels.filter(i => i.designator_level === userLevel).map(i => i.designator_level)
      ;

      // Remove duplicates
      const optionsWithoutDuplicates: DesignatorLevelType[] = _designatorLevelOptions.filter((item, index) => _designatorLevelOptions.indexOf(item) === index);
      setDesignatorLevelOptions(optionsWithoutDuplicates);

      // if only one option, automatically select it
      if (optionsWithoutDuplicates.length === 1) {
        setDesignatorLevelSelected(optionsWithoutDuplicates[0]);
      }
    }
  }, [designatorLevelOptions, levels])

  return (
    <>
      {designatorLevelOptions ?
        <div className='flex flex-col items-center gap-y-8 w-full'>
          <div className='flex flex-row gap-x-10'>
            <div className='flex gap-x-2 items-center'>
              <Select
                label="Campionato"
                options={
                  [{name: "", value: ""}]
                    .concat(
                      ChampionshipOptions.filter(elem => designatorLevelOptions.includes(elem.value as DesignatorLevelType))
                    )
                }
                value={designatorLevelSelected ?? ""}
                onChange={(elem: React.ChangeEvent<HTMLInputElement>) => {
                  setRoundSelected(undefined);
                  setDesignatorLevelSelected(elem.currentTarget.value as DesignatorLevelType);
                }}
                customStyles={{minWidth: '160px'}}
              />
            </div>
            <div className='flex gap-x-2 items-center'>
              <Select
                disabled={!designatorLevelSelected || !availableRounds}
                label="Turno"
                options={
                  [{name: "", value: ""}]
                    .concat(
                      (availableRounds ?? []).map(elem => ({name: elem, value: elem}))
                    )
                }
                value={roundSelected ?? ""}
                onChange={(elem: React.ChangeEvent<HTMLInputElement>) => setRoundSelected(elem.currentTarget.value)}
                customStyles={{minWidth: '160px'}}
              />
            </div>
            {designatorLevelSelected && roundSelected ?
              <div className="w-full">
                <Label>Ordina per</Label>
                <div className="-mt-2">
                  <RadioGroup
                    id="sortBy"
                    horizontal
                    options={[
                      {value: "datetime", name: "Data e ora"},
                      {value: "game", name: "Numero gara"},
                    ]}
                    currentValue={sortBy}
                    onChange={(value: 'datetime' | 'game') => {
                      setSortBy(value)
                    }}
                  />
                </div>
              </div> : null
            }
          </div>
          <div className='w-full'>
            {designatorLevelSelected && roundSelected ?
              <EliteAlgorithmGames
                designator_level={designatorLevelSelected}
                des_round={roundSelected}
                group={dataContext.group}
                sortBy={sortBy}
              /> :
              <div className='text-center font-bold'>
                Seleziona il campionato e il turno per vedere le gare in programma
              </div>
            }
          </div>
        </div>
        : <AlgoElitePlaceholder/>
      }
    </>
  );
}

export default EliteAlgorithm;