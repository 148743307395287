import React, {useContext, useState} from "react";

import AuthenticationService from "@services/AuthenticationService";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";

import {BellAlertIcon, PencilIcon} from "@heroicons/react/24/outline";
import Button from "@ui-components/Button";

import {RatingsProps} from "@pages/Activities/Detail/Detail.type";
import Select from "@ui-components/Select";
import Input from "@ui-components/Input";
import Alert from "@ui-components/Alert";
import Modal from "@ui-components/Modal";


export function Ratings({intl, activity, setActivity, viewMode, memberActivity, canCreate}: RatingsProps) {

  const {push} = useContext(NotificationsContext);
  const userIdFipCode = AuthenticationService.getUserFipCode();

  const feedback = activity.members_activities.filter(i => i.num_rating !== undefined && i.num_rating !== null)
  const total = feedback.reduce((acc, i) => acc + (i.num_rating ? i.num_rating : 0), 0)

  const [showSendReminder, setShowSendReminder] = useState<boolean>(false);
  const [reminderDays, setReminderDays] = useState<number>(2);

  let endDate = new Date(activity.dat_activity);
  endDate.setDate(endDate.getDate() + 20);
  const allowChangeFeedback = endDate > new Date() && new Date(activity.dat_activity) < new Date()
  const [changeFeedback, setChangeFeedback] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState<number>(memberActivity?.num_rating ?? 0);
  const [comment, setComment] = useState<string | undefined>(memberActivity?.comment);

  const sendFeedback = () => {
    setLoading(true)
    api.put(`/activities/members/${userIdFipCode}/${activity.id}`, {
      num_rating: rating,
      comment: comment,
      flg_accepted: memberActivity?.flg_accepted,
      reason_decline: memberActivity?.reason_decline
    })
      .then(() => {
        setActivity(undefined);
        push({title: "Feedback salvato", type: "success"});
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  const sendReminder = () => {
    setLoading(true)
    api.get(`/activities/send-reminder-feedback/${activity.id}/${reminderDays}`)
      .then(({data}) => {
        setActivity(undefined);
        setShowSendReminder(false);
        push({title: `Reminder inviato a ${data} tesserati`, type: "success"});
      })
      .catch((err) => {
        if (err.response.status === 404) {
          push({title: "Non ci sono tesserati da sollecitare", type: "error"});
        } else {
          push({title: "Errore del server", type: "error"});
        }
      })
      .finally(() => setLoading(false))
  }


  return (
    <div className="flex flex-col my-5 py-2 gap-3">
      <div className="flex flex-row justify-between border-b border-gray-800">
        <h1 className="text-lg font-bold">
          <span>{viewMode ? 'Lascia il tuo feedback' : `Feedback (${feedback.length}) `}</span>
          <span className="ml-2">{feedback.length && total && !viewMode ? `Media: ${intl.formatNumber(total / feedback.length, {maximumFractionDigits: 1})}` : ''}</span>
        </h1>
        <span className="font-medium flex flex-col sm:flex-row sm:divide-x divide-gray-400 pb-1 items-end">
          {viewMode ?
            changeFeedback ?
              <div className="flex pl-3 gap-2">
                <Button className="text-xs h-8" submitting={loading}
                        onClick={sendFeedback} disabled={!rating || !comment}>
                  Salva
                </Button>
                <Button className="text-xs h-8" styleType="white" onClick={() => setChangeFeedback(false)}>
                  Esci
                </Button>
              </div>
              :
              <span
                className={`text-sm ${allowChangeFeedback ? 'text-am-700 cursor-pointer hover:font-bold' : 'text-gray-400'}`}
                onClick={() => {
                  if (allowChangeFeedback) {
                    setChangeFeedback(true)
                  }
                }}
              >
                <PencilIcon className="h-4 w-4 inline-block -mt-1 mr-1"/>
                {memberActivity?.num_rating ? 'Modifica feedback' : 'Inserisci feedback'}
            </span>
            :
            null
          }
          {!viewMode && canCreate ?
            <span
              className="sm:pr-3 text-am-700 cursor-pointer hover:font-bold"
              onClick={() => setShowSendReminder(true)}
            >
              <BellAlertIcon className="h-4 w-4 inline-block -mt-1"/> Invia sollecito
            </span> : null
          }
        </span>
      </div>

      {viewMode ?
        <div className="w-full">
          {changeFeedback
            ? <div className="flex flex-col sm:flex-row items-center justify-between gap-3 sm:gap-6">
              <div className="w-full sm:w-32 sm:-mt-3">
                <Select
                  options={[{value: 0, name: ""}].concat([...new Array(10)].map((each, i) => ({
                    value: i + 1,
                    name: String(i + 1)
                  })))}
                  value={rating}
                  label="Giudizio"
                  //@ts-ignore
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setRating(0)
                    if (e.target.value) {
                      setRating(Number(e.target.value))
                    }
                  }}
                  customStyles={undefined}
                />
              </div>
              <div className="w-full">
                {/*@ts-ignore*/}
                <Input
                  label="Commento"
                  value={comment}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setComment(e.target.value)
                  }}
                  placeholder="Breve commento"
                  type="text"
                  errorMessage="Inserisci un commento"
                  required
                />
              </div>
            </div>
            : <div>
              {memberActivity?.num_rating
                ? <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 sm:items-center">
                  <span className="text-xl font-bold rounded-full py-1 text-center border w-12">{memberActivity.num_rating}</span>
                  <span className="italic">{memberActivity.comment}</span>
                </div>
                : <div className="my-2">
                  {new Date(activity.dat_activity) < new Date()
                    ? <Alert type="warning" title={allowChangeFeedback
                      ? "Inserisci il tuo feedback!" : "Non hai lasciato il tuo feedback - troppo tardi"}
                    />
                    : <Alert type="warning" title="Potrai inserire il tuo feedback dopo la lezione"/>
                  }
                </div>
              }
            </div>
          }
        </div>
        :
        <div className="w-full my-2 flex flex-col divide-y divide-gray-300">
          {feedback.map(i =>
            <div key={i.id_fip_code} className="flex flex-row gap-2 item-center py-1">
              <div className="w-12">
                <div className={`text-xl font-bold rounded-full py-1 text-center border bg-opacity-30 ${(i.num_rating || 0) > 7 ? 'bg-green-200' : (i.num_rating || 0) > 5 ? 'bg-yellow-200' : 'bg-red-200'}`}>
                  {i.num_rating}
                </div>
              </div>
              <div className="w-full flex flex-col">
                <span className="font-medium text-sm">{i.member.member}</span>
                <span className="italic text-sm">{i.comment}</span>
              </div>
            </div>)
          }
        </div>
      }

      {showSendReminder ?
        <Modal onExit={() => setShowSendReminder(false)}>
          <div>
            <h1 className="font-bold sm:text-xl mr-5 mb-5">Vuoi inviare un reminder a chi ancora non ha lasciato il feedback?</h1>
            {/*@ts-ignore*/}
            <Input
              type="number"
              label="Giorni minimi di ritardo"
              value={reminderDays}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setReminderDays(Number(e.target.value) > 0 ? Number(e.target.value) : 1)
              }}
              numberOptions={{minimumFractionDigits: 0, maximumFractionDigits: 0}}
            />
            <h3 className="text-xs sm:text-sm my-5">
              Tutti i presenti che non hanno lasciato un feedback da almeno {reminderDays} giorni riceveranno un messaggio di sollecito
            </h3>
          </div>
          <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
            <Button styleType="white" onClick={() => setShowSendReminder(false)}>
              Annulla
            </Button>
            <Button onClick={sendReminder} submitting={loading}>
              Invia
            </Button>
          </div>
        </Modal> : null
      }

    </div>
  )
}
