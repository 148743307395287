import React, {useContext, useEffect, useState} from "react";

import {useIntl, FormatNumberOptions} from "react-intl";
import Placeholder from "@ui-components/Placeholder";
import {NotificationsContext} from "@ui-components/Notifications";
import {QuizCommonResultsTableData} from "@components/RefMan/Quiz/types";
import {useQuizResult} from "@components/RefMan/Quiz/hooks/useQuizResult";
import {QuizCommonResultsQuestionTooltip} from "@components/RefMan/Quiz/QuizPersonalResult";

const percStyle: FormatNumberOptions = {style: "percent", maximumFractionDigits: 0}

export function QuizDetailResults({idQuiz}: { idQuiz: number }) {

  const [data, setData] = useState<QuizCommonResultsTableData[]>();
  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const {getDetail} = useQuizResult();

  const questionErrors: {[key: number]: number} = (data ?? []).reduce((acc, i) => {
    if (i.cod_status === 'FINISHED') {
      return i.answers.reduce((res: {[key: number]: number}, j) => {
        const err = j.is_correct ? 0 : 1
        return ({...res, [j.id_question]: (res[j.id_question] ?? 0) + err})
      }, acc)
    }
    return acc
  }, {})

  useEffect(() => {
    getDetail(idQuiz)
      .then((res) => setData(res))
      .catch(() => push({type: "error", title: "Errore durante il caricamento"}))
  }, []);

  const calculateTotalErrors = () => {

    const _data = (data ?? []).filter(q => q.cod_status === 'FINISHED')
    if (!_data)
      return '-'

    let errors = 0
    let questions = 0
    _data.forEach(q => {
      errors += q.num_question - q.num_correct_answers
      questions += q.num_question
    })

    return <span>{intl.formatNumber(errors / _data.length, {maximumFractionDigits: 1})} <i className="ml-2 font-light">
      ({intl.formatNumber(errors / questions, percStyle)})
    </i></span>
  }

  const getColumns = () => {
    if (data) {
      return data[0].answers.sort((a, b) => a.question_order - b.question_order).map((col) => col.id_question)
    }
  }

  const columns = getColumns();

  return (
    <div>
      {data ?
        <>
          <div className="overflow-x-auto flex flex-col h-70vh my-5 mt-10 ml-5">
            <div className="inline-block min-w-full pb-2 align-middle flex-grow overflow-auto">
              <table>
                <thead className="sticky top-0 z-30">
                <tr className="divide-x divide-am-400 divide-opacity-70">
                  <th scope="col"
                      className="sticky left-0 bg-am-600 py-3.5 px-2 sm:px-4 text-left text-sm font-semibold text-white">
                    Tesserato
                  </th>
                  <th scope="col"
                      className="left-0 bg-am-600 py-3.5 px-2 sm:px-4 text-center text-sm font-semibold text-white">
                    Errori
                  </th>
                  {columns?.map((_, index) =>
                    <th key={index} scope="col"
                        className='bg-am-400 px-4 py-1 text-sm font-semibold text-white text-center whitespace-nowra hover:cursor-pointer'>
                      {index + 1}
                    </th>
                  )}
                </tr>
                </thead>
                <tbody className="bg-white">
                {data.sort((a, b) => a.participant.tag.localeCompare(b.participant.tag)).map((q) => (
                  <tr key={q.participant.tag} className="divide-x divide-gray-50">
                    <td className="sticky left-0 z-20 bg-white text-sm font-light text-gray-900">
                      <div className="">
                        <div className="whitespace-nowrap px-2 sm:px-4">
                           <span className={`block font-bold ${q.cod_status === 'FINISHED' ? '' : 'text-gray-300'}`}>
                            {q.participant.tag}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="left-0 bg-white text-sm font-light text-gray-900">
                      <div className="">
                        <div className="whitespace-nowrap px-2 sm:px-4 text-center">
                          {q.cod_status === 'FINISHED' ?
                            <span className="block font-bold text-center">
                              {q.num_question - q.num_correct_answers}
                              <i className="ml-2 font-light">
                                ({intl.formatNumber((q.num_question - q.num_correct_answers) / q.num_question, percStyle)})
                              </i>
                            </span>
                            : <span className="text-gray-300 text-center">-</span>
                          }
                        </div>
                      </div>
                    </td>
                    {columns && columns.map((c) => {
                      const ans = q.answers.filter(a => a.id_question === c)[0]
                      return (
                        <td
                          key={`${q.participant.id} - ${c}`}
                          className="whitespace-nowrap px-2 sm:px-4 py-1.5 text-center text-gray-300"
                        >
                          {/*todo: improve style!*/}
                          {q.cod_status !== 'FINISHED'
                            ? '-'
                            : <QuizCommonResultsQuestionTooltip
                              is_correct={ans.is_correct}
                              des_answers={ans.des_answers}
                              des_question={ans.question.des_question}
                            />
                          }
                        </td>
                      )
                    })}
                  </tr>
                ))}
                <tr>
                  <td className="sticky left-0 z-20 bg-white text-sm font-light text-gray-900 border-t pt-2">
                    <div className="">
                      <div className="whitespace-nowrap px-2 sm:px-4">
                        <span className="block font-bold text-am-700">
                          Media
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="left-0 bg-white text-sm border-t pt-2">
                    <div className="">
                      <div className="whitespace-nowrap px-2 sm:px-4">
                          <span className="block font-bold text-center text-am-700">
                            {calculateTotalErrors()}
                          </span>
                      </div>
                    </div>
                  </td>
                  {columns && columns.map((c) => {
                    return (
                      <td key={`errors-${c}`} className="left-0 bg-white text-sm border-t pt-2">
                        <div className="">
                          <div className="whitespace-nowrap px-2 sm:px-4">
                          <span className="block font-bold text-center text-am-700">
                            {questionErrors[c]}
                          </span>
                          </div>
                        </div>
                      </td>
                    )
                  })}
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </> :
        <Placeholder height="h-72" classNames="rounded-lg"/>
      }
    </div>
  )
}