import apiRequest from "../../../services/apiRequest";

export const COLUMNS = [
  {
    title: "Cod. Gruppo",
    field: "cod_category"
  },
  {
    title: "Des. Gruppo",
    field: "des_category"
  },
  {
    title: "Cod. Categoria",
    field: "cod_sub_category"
  },
  {
    title: "Des. Categoria",
    field: "des_sub_category"
  },
  {
    title: "Ordine",
    field: "sub_cat_order",
    type: "numeric",
    defaultSort: "asc",
  }
]

export const OPTIONS = {
  filtering: false,
  toolbar: true,
  paging: false,
  sorting: true,
  showTitle: false,
  filterCellStyle: {maxWidth: 200}
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    editRow: {
      deleteText: "Vuoi davvero eliminare la riga?",
    },
  },
};


export const onRowUpdate = (newData, oldData, dataContext, push) =>
  new Promise((resolve, reject) => {
    let isEqual = true;
    Object.entries(newData).forEach(([key, value]) => {
      if (oldData[key] !== value) {
        isEqual = false;
      }
    });
    if (isEqual) {
      resolve();
    } else {
      apiRequest
        .put(`/entry/categories/${oldData.id}`, newData)
        .then((res) => {
          dataContext.setCategories({...dataContext.categories, [res['id']]: res})
          push({title: "Riga aggiornata con successo", type: "success"});
          resolve();
        })
        .catch(() => {
          push({
            title: "Errore durante l'aggiornamento della riga",
            type: "error",
          });
          reject();
        });
    }
  });

export const onRowDelete = (oldData, dataContext, push) =>
  new Promise((resolve, reject) => {
    apiRequest
      .delete(`/entry/categories/${encodeURIComponent(oldData.id)}`)
      .then(() => {
        const {[oldData.id]: tmp, ...rest} = dataContext.categories;
        dataContext.setCategories({...rest});
        push({title: "Riga eliminata con successo", type: "success"});
        resolve();
      })
      .catch(() => {
        push({
          title: "Errore durante l'eliminazione della riga",
          type: "error",
        });
        reject();
      });
  });