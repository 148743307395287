import React from "react";
import AuthenticationService from "@services/AuthenticationService";
import MemberDetail from "@pages/Members/Detail";
import {StaffHome} from "@pages/Home/StaffHome";


function Home() {

  const scopes = AuthenticationService.getUserScopes();

  return (
    <div>
      <div className="flex flex-col">
        {scopes.includes('home:personal') ?
          <MemberDetail showMemberData={false}/>
          :
          <StaffHome/>
        }
      </div>
    </div>
  );
}

export default Home;
