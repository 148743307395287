import React, {Dispatch, SetStateAction} from "react";

import {QuizAuthorFilter} from "@components/RefMan/Quiz/QuizFilters/QuizAuthorFilter";
import {QuizStatusFilter} from "@components/RefMan/Quiz/QuizFilters/QuizStatusFilter";
import {QuizTitleFilter} from "@components/RefMan/Quiz/QuizFilters/QuizTitleFilter";
import {QuizTagFilter} from "@components/RefMan/Quiz/QuizFilters/QuizTagFilter";
import {QuizFilterType} from "@components/RefMan/Quiz/types";

export function QuizFilters({filter, setFilters, showPrivateQuiz}: {
  filter: QuizFilterType,
  setFilters: Dispatch<SetStateAction<QuizFilterType>>
  showPrivateQuiz: boolean;
}) {
  return (
    <div className={'flex flex-col sm:flex-row gap-3 w-full justify-between'}>
      {!showPrivateQuiz && <QuizAuthorFilter filter={filter} setFilters={setFilters}/>}
      <QuizStatusFilter filter={filter} setFilters={setFilters}/>
      <QuizTagFilter filter={filter} setFilters={setFilters}/>
      <QuizTitleFilter filter={filter} setFilters={setFilters}/>
    </div>
  );
}
