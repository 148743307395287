import React from "react";
import {PageHeading} from "@ui-components/Container";


export default function PlannerAgenda() {

  return (
    <>
      <PageHeading title="Agenda" historyBackPath="_back" />
      <div className="mt-10 italic">Coming soon</div>
    </>
  );
}
