import React, {ReactElement} from "react";
import {useHistory} from "react-router-dom";
import {ArrowLongLeftIcon} from "@heroicons/react/24/solid";

const Container = ({children}: { children: ReactElement }) =>
  <div className="container mx-auto px-2 sm:px-4 md:px-8 max-w-7xl">
    {children}
  </div>

const PageHeading = ({title, contentActions, historyBackPath}: {
  title?: any,
  contentActions?: any,
  historyBackPath?: string
}) => {

  const history = useHistory();

  return (
    <div className="py-4 flex items-center justify-stretch gap-x-20 gap-y-6 flex-wrap flex-col sm:flex-row">
      <div className="flex-1 w-full sm:w-auto">
        <h1
          className="text-xl sm:text-2xl lg:text-3xl font-extrabold leading-7 text-black-500 whitespace-nowrap flex gap-4 items-center">
          {historyBackPath
            && <ArrowLongLeftIcon
              className="inline-block h-9 w-9 cursor-pointer"
              onClick={() => {
                if (String(historyBackPath) === '_back') {
                  history.goBack()
                } else {
                  history.push(historyBackPath)
                }
              }}
            />}
          <span>{title}</span>
        </h1>
      </div>
      <div className="ml-auto flex flex-wrap gap-2 flex-col w-full sm:flex-row sm:w-auto">
        {contentActions}
      </div>
    </div>
  );
};

const PageSection = ({title, description, children}: { title: string, description?: string, children?: any }) => (
  <div className="mb-12">
    {title && (
      <>
        <div className="mb-1">
          <div className="space-y-1">
            <h3 className="text-lg leading-6 font-medium text-am-800">
              {title}
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">{description}</p>
          </div>
        </div>
        <div className="mb-5">
          <div className="w-full border-t border-gray-300"/>
        </div>
      </>
    )}
    {children}
  </div>
);

export default Container;
export {Container, PageHeading, PageSection};
