import React, {useContext, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";

// Ui
import {PlusCircleIcon, TrashIcon} from "@heroicons/react/24/outline";
import SearchSelect from "@ui-components/SearchSelect";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Alert from "@ui-components/Alert";

import {AssignInstructorsDialogProps} from "../Detail.type";
import Toggle from "@ui-components/Toggle";


export function AssignInstructorsDialog({onExit, activity, setActivity, instructors}: AssignInstructorsDialogProps) {

  const {push} = useContext(NotificationsContext);
  const [add, setAdd] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);
  const [notifyTeachers, setNotifyTeachers] = useState<boolean>(false);

  const assignedInstructors = activity.members_activities.filter(i => i.activity_role === 'TEACHER')

  const [added, setAdded] = useState<{ value: number; member: string; job: string; }[]>(assignedInstructors.map(i => ({
    value: i.id_fip_code,
    member: i.member.member,
    job: i.member.members_jobs.filter(j => j.job.job_type === 'IST')[0].job.name
  })))

  const updateData = () => {
    setLoading(true)
    api.post(`/activities/instructors`, {
      id_activity: activity.id,
      flg_teacher_presence: activity.flg_teacher_presence ?? false,
      flg_notify_teachers: notifyTeachers,
      members: added.map(i => i.value)
    })
      .then(() => {
        push({title: "Dati salvati con successo", type: "success"});
        setActivity(undefined);
        onExit();
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      onExit={onExit}
    >
      <div className="flex flex-col gap-2">
        <h1 className="font-bold sm:text-xl">Assegnazione istruttori</h1>
        <Toggle
          smaller
          vertical
          description="Conterà come presenza come Arbitro/Udc"
          label={"Registra anche la presenza degli Istruttori"}
          checked={activity.flg_teacher_presence}
          onChange={() => setActivity({...activity, flg_teacher_presence: !(activity.flg_teacher_presence ?? false)})}
        />
        <Toggle
          smaller
          vertical
          description="Solo chi non è stato già notificato riceverà una mail"
          label={"Invia notifica agli Istruttori incaricati"}
          checked={notifyTeachers}
          onChange={() => setNotifyTeachers(!notifyTeachers)}
        />
        {added.length > 0 ?
          <div className="flex flex-col gap-2 my-5">
            <span className="font-normal text-xs">Istruttori indicati:</span>
            <ul>
              {added.map(i => <li key={i.value} className="text-xl font-bold text my-1">
                <TrashIcon
                  className="h-5 w-5 inline-block mr-3 -mt-1 cursor-pointer"
                  onClick={() => setAdded(added.filter(a => a.value !== i.value))}
                />
                {i.member}
              </li>)}
            </ul>
          </div>
          : <div className="my-5">
            <Alert type="warning" title="Nessun istruttore assegnato a questa attività tecnica"/>
          </div>
        }
        <div
          className="border-t pt-3 flex flex-row gap-3 justify-center hover:font-bold cursor-pointer"
          onClick={() => setAdd(true)}
        >
          <span>Aggiungi un istruttore</span>
          <PlusCircleIcon className="shrink w-6 h-6"/>
        </div>
        {add ?
          <div>
            {/*@ts-ignore*/}
            <SearchSelect
              label="Istruttore"
              options={instructors.map(i => ({
                value: i.id_fip_code,
                label: i.member,
                member: i.member,
                job: i.members_jobs.filter(j => j.job.job_type === 'IST')[0].job.name
              }))}
              onChange={(v: { value: number; job: string; member: string; }) => {
                setAdded([...added, v])
                setAdd(false)
              }}
              value={undefined}
              //@ts-ignore
              CustomOption={({data, innerProps, innerRef}) =>
                <article ref={innerRef} {...innerProps} className="px-4 py-2 text-sm cursor-pointer hover:bg-blue-100">
                  {data.job}<br/><b>{data.label}</b>
                </article>}
            />
          </div>
          : null
        }
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between mt-10">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button onClick={updateData} submitting={loading}>
          Salva
        </Button>
      </div>
    </Modal>
  );
}
