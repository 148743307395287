import React, {useContext, useEffect, useState} from "react";

import {useQuestion} from "@components/RefMan/Quiz/hooks/useQuestion";
import {NotificationsContext} from "@ui-components/Notifications";

// Ui
import {TrashIcon, PlusIcon} from "@heroicons/react/24/outline";
import Placeholder from "@ui-components/Placeholder";
import Toggle from "@ui-components/Toggle";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Input from "@ui-components/Input";

import {
  CreateOrUpdateQuestionModalProps,
  NumQuizzesByQuestionIdSchema,
  QuestionBaseType
} from "@components/RefMan/Quiz/types";
import UpdateAlertQuestion from "@components/RefMan/Quiz/Question/QuizAction/CreateOrUpdate/UpdateAlertQuestion";


export function CreateOrUpdateQuestionModal(props: CreateOrUpdateQuestionModalProps) {

  const {push} = useContext(NotificationsContext);

  const [data, setData] = useState<QuestionBaseType>({
    des_question: '',
    des_answers: ['Sì', 'No'],
    des_correct_answers: [],
    // todo: per ora sempre forzato a text
    cod_type: 'text',
    // todo: aggiungi logica tag
    tags: [],
    flg_private: true,
    flg_deprecated: false,
    des_regulatory_reference: null,
    id_quiz: props.idQuiz,
  });

  const [numQuizzesInfo, setNumQuizzesInfo] = useState<NumQuizzesByQuestionIdSchema | undefined>();

  const {create, getQuestionById, update, getNumQuizzesByQuestionId, loading} = useQuestion();

  useEffect(() => {
    if (props.edit && props.idQuestion) {
      getQuestionById(props.idQuestion)
        .then((res) => {
          setData(res)
        })
        .catch(() => push({type: 'error', title: 'Qualcosa è andato storto'}));
    }
  }, [props.idQuiz]);

  useEffect(() => {
    if (props.edit && props.idQuestion) {
      getNumQuizzesByQuestionId(props.idQuestion)
        .then((res) => {
          setNumQuizzesInfo(res)
        })
        .catch(() => push({type: 'error', title: 'Qualcosa è andato storto'}));
    }
  }, [props.edit && props.idQuestion]);


  const isSaveDisabled = (data: QuestionBaseType) => {
    return data.des_correct_answers.length === 0
      || data.des_question.length === 0
      || data.des_answers.filter(i => i !== '').length < 2
      || data.des_answers.filter(i => i === '').length > 0
      || data.des_answers.length === data.des_correct_answers.length
  }

  return (
    <Modal
      maxWidth="w-full sm:max-w-4xl"
      onExit={props.onExit}
    >
      {loading ? <Placeholder height="h-96" classNames="rounded-lg"/> :
        <div>
          <div className="mb-5 flex flex-col gap-2">
            <h1 className="font-bold sm:text-xl mb-4">{!props.edit ? "Crea una nuova domanda" : "Modifica"} </h1>
            {numQuizzesInfo &&
              <UpdateAlertQuestion {...numQuizzesInfo}/>
            }
            <div className="w-full">
              <label className="block uppercase tracking-wide text-xs font-bold mb-2">
                Testo della domanda
              </label>
              <Input
                label=''
                value={data.des_question}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData({
                  ...data, des_question: e.target.value
                })}
                type={'textarea'}
                rows={5}
                onClear={() => setData({...data, des_question: ''})}
                name={undefined}
                validation={(value: string) => value !== ''}
                required={true}
                errorMessage={true}
                showCalendar={undefined}/>
            </div>
          </div>
          <div className="w-full">
            <label className="block uppercase tracking-wide text-xs font-bold mb-2">
              Risposte
            </label>
            {data.des_answers.map((value, index) => (
              <div key={index} className="mb-3">
                <div className={'flex flex-row gap-4 items-center'}>
                  <div className="-mb-4 w-full">
                    <Input
                      label={''}
                      value={value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const updatedTextBoxes = [...data.des_answers];
                        const prevValue = updatedTextBoxes[index]

                        let newCorrectAnswer = data.des_correct_answers.filter(i => i !== prevValue)
                        if (data.des_correct_answers.includes(prevValue)) {
                          newCorrectAnswer = newCorrectAnswer.concat([e.target.value])
                        }
                        updatedTextBoxes[index] = e.target.value;
                        setData({...data, des_answers: updatedTextBoxes, des_correct_answers: newCorrectAnswer});
                      }}
                      onClear={undefined}
                      name={undefined}
                      required={undefined}
                      errorMessage={undefined}
                      showCalendar={undefined}
                      validation={undefined}
                      key={index}
                    />
                  </div>
                  <div className="-mt-3">
                    <Toggle
                      label={''}
                      key={`${index}-toggle`}
                      checked={data.des_correct_answers.includes(value)}
                      onChange={() => {
                        if (data.des_correct_answers.includes(value)) {
                          setData({
                            ...data,
                            des_correct_answers: data.des_correct_answers.filter(i => i !== value)
                          });
                        } else {
                          setData({...data, des_correct_answers: data.des_correct_answers.concat([value])});
                        }
                      }}
                      vertical
                      description={undefined}
                      disabled={value.trim() === ''}
                      smaller
                    />
                  </div>
                  <button
                    key={`${index}-remove-button`}
                    onClick={() => {
                      setData({
                        ...data,
                        des_answers: data.des_answers.filter(i => i !== value),
                        des_correct_answers: data.des_correct_answers.filter(i => i !== value)
                      });
                    }}
                  >
                    <TrashIcon className='h-6 w-6 cursor-pointer text-gray-500 italic' key={index}/>
                  </button>
                </div>
              </div>
            ))}
            <Button
              styleType='white'
              className="text-xs mt-3"
              onClick={() => {
                setData({...data, des_answers: [...data.des_answers, '']})
              }}><PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true"/>Aggiungi risposta
            </Button>
          </div>
          <div className="w-full mt-10">
            <label className="block uppercase tracking-wide text-xs font-bold mb-2">
              Ulteriori informazioni
            </label>
            <div className='flex flex-col sm:flex-row gap-5 w-full justify-between sm:items-center -mt-2 mb-5'>
              <Toggle
                label={<span className="mr-2">Privata</span>}
                checked={data.flg_private}
                onChange={() => {
                  setData({...data, flg_private: !data.flg_private})
                }}
                vertical
                description={undefined}
                smaller
              />
              <Toggle
                label={<span className="mr-2">Deprecata</span>}
                checked={data.flg_deprecated}
                onChange={() => {
                  setData({...data, flg_deprecated: !data.flg_deprecated})
                }}
                vertical
                description={undefined}
                smaller
              />
              <div className='grow max-w-sm'>
                <Input
                  label={'Referenza del regolamento'}
                  value={data.des_regulatory_reference}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setData({...data, des_regulatory_reference: e.target.value})
                  }}
                  onClear={undefined}
                  name={undefined}
                  required={undefined}
                  errorMessage={undefined}
                  showCalendar={undefined}
                  validation={undefined}
                />
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
            <Button styleType="white" onClick={props.onExit}>
              Annulla
            </Button>
            {!props.edit ?
              <Button
                disabled={isSaveDisabled(data)}
                onClick={() => create(data)
                  .then(() => {
                    if (props.setRefreshData) {
                      props.setRefreshData(prev => !prev);
                    }
                    props.onExit();
                  })
                  .catch(() => {
                    push({type: 'error', title: 'Qualcosa è andato storto'})
                  })}>
                Crea
              </Button> : props.idQuestion && numQuizzesInfo &&
              <Button
                disabled={isSaveDisabled(data)}
                onClick={() => update(data, props.idQuestion)
                  .then(() => {
                    if (props.setRefreshData)
                      props.setRefreshData(prev => !prev);
                    props.onExit();
                  })
                  .catch(() => {
                    push({type: 'error', title: 'Qualcosa è andato storto'})
                  })}>
                Salva
              </Button>
            }
          </div>
        </div>
      }
    </Modal>
  );
}
