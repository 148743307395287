import React, {useContext, useEffect, useState} from "react";

import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {NotificationsContext} from "@ui-components/Notifications";
import {useSession} from "@components/RefMan/Quiz/hooks/useSession";
import {RefManListItemType} from "@components/RefMan/RefManList/RefManList.type";

import {
  PlayIcon,
  ClockIcon,
  ForwardIcon,
  CalendarIcon,
  UserCircleIcon,
  PresentationChartBarIcon,
} from "@heroicons/react/24/outline";
import {
  codStatusType,
  quiz_cod_type_finished,
  quiz_cod_type_in_progress,
  quiz_cod_type_not_started,
  QuizSessionListSchema
} from "@components/RefMan/Quiz/types";

import {getQuizStatusColor, getQuizStatusDescription} from "@components/RefMan/Quiz/QuizList/QuizList.lib";
import {StartSessionModal} from "@components/RefMan/Quiz/Session/StartSession";
import RefManList from "@components/RefMan/RefManList/RefManList";
import {dateFormat} from "@pages/Planner/Absences/Absences.lib";
import {StatusBullet} from "@components/RefMan/StatusBullet";
import {QuizPlaceholder} from "@utils/placeholders";
import {Tooltip} from "@material-ui/core";
import Alert from "@ui-components/Alert";


export function QuizSessionList() {

  // context
  const {push} = useContext(NotificationsContext);
  const [offset, setOffset] = useState<number>(0);
  const [sessionList, setSessionList] = useState<QuizSessionListSchema[] | undefined>(undefined);
  const [showStartSession, setShowStartSession] = useState<boolean>(false);
  const [startSessionQuizId, setStartSessionQuizId] = useState<number | undefined>();

  const {getAll} = useSession();
  const pushHistory = useHistory().push;

  useEffect(() => {
    getAll()
      .then((res) => setSessionList(res))
      .catch(() => {
        push({title: 'Errore del server', type: 'error'});
      });
  }, []);

  const actionCell = (sessionStatus: codStatusType, quizStatus: codStatusType, idQuiz: number) => {
    // Se il quiz è stato chiuso e la sessione, puoi solamente visualizzare i risultati
    if (quizStatus === quiz_cod_type_finished && sessionStatus === quiz_cod_type_finished) {
      return <Tooltip title="Mostra risultati">
        <button
          className='rounded-full p-1 border-am-600 border-2'
          onClick={() => {
            pushHistory(`/technical-area/quiz/${idQuiz}`)
          }}
        >
          <PresentationChartBarIcon className='h-5 w-5 cursor-pointer text-am-600'/>
        </button>
      </Tooltip>
    }
    // Se il quiz non è stato chiuso ma la sessione è stata chiusa, attendi che l'istruttore termini il quiz
    if (quizStatus !== quiz_cod_type_finished && sessionStatus === quiz_cod_type_finished) {
      return <Tooltip title="Attendi che l'istruttore pubblichi i risultati">
        <button
          className='rounded-full p-1 border-am-600 border-2 cursor-auto'
        >
          <ClockIcon className='h-5 w-5 text-am-600 cursor-auto'/>
        </button>
      </Tooltip>
    }
    // Se il quiz è stato aperto e la sessione non è ancora stata attivata, allora puoi iniziare il quiz
    if (quizStatus === quiz_cod_type_in_progress && sessionStatus === quiz_cod_type_not_started) {
      return <Tooltip title="Inizia il quiz">
        <button
          className='rounded-full p-1 border-am-600 border-2'
          onClick={() => {
            setShowStartSession(true);
            setStartSessionQuizId(idQuiz);
          }}
        >
          <PlayIcon className='h-5 w-5 cursor-pointer text-am-600'/>
        </button>
      </Tooltip>
    }
    // Se il quiz è stato aperto e la sessione è già stata attivata, allora puoi continuare il quiz
    if (quizStatus === quiz_cod_type_in_progress && sessionStatus === quiz_cod_type_in_progress) {
      return <Tooltip title="Continua il quiz">
        <button
          className={'rounded-full p-1 border-am-600 border-2'}
          onClick={() => {
            pushHistory(`/technical-area/quiz/session/${idQuiz}`)
          }}
        >
          <ForwardIcon className='h-5 w-5 cursor-pointer text-am-600'/>
        </button>
      </Tooltip>
    }
    return <></>
  }

  const getListItems = (
    sessions: QuizSessionListSchema[]
  ): RefManListItemType[] => {

    const intl = useIntl();

    return sessions.sort((a, b) => a.id_quiz - b.id_quiz)
      .map((s): RefManListItemType => {
        const {primaryColor, backgroundColor} = getQuizStatusColor(s.cod_status);
        return ({
          rowId: `${s.id_quiz}`,
          rowElement: <>
            <div className="flex flex-col gap-y-2 pb-3 sm:pb-0 sm:col-span-4">
              <p className="font-bold text-am-700">{s.quiz.des_title}</p>
              <div className={"flex flex-row gap-x-2"}>
                <StatusBullet primaryColor={primaryColor} backgroundColor={backgroundColor}/>
                <p className={"text-sm text-gray-500"}>{getQuizStatusDescription(s.cod_status)}</p>
              </div>
            </div>
            <div className="text-sm text-gray-500 flex flex-col gap-y-2 py-3 sm:py-0 sm:col-span-3">
              <div className="flex flex-row gap-x-2 mr-2 items-center">
                <UserCircleIcon className="h-5 text-am-700"/>
                <p>
                  Creato il <span
                  className="font-semibold">{intl.formatDate(s.quiz.dat_creation, dateFormat)}</span><br/>
                  da <span className="font-semibold">{s.quiz.member.member}</span>
                </p>
              </div>
            </div>
            <div className="text-sm text-gray-500 flex flex-col gap-y-2 py-3 sm:py-0 sm:col-span-3">
              <div className="flex flex-row gap-x-2 items-center whitespace-nowrap">
                <CalendarIcon className="h-5 text-am-700"/>
                {s.quiz.tms_deadline
                  ? <div className="flex flex-col">
                  <span>
                    Scadenza: <b>{intl.formatDate(s.quiz.tms_deadline,
                    {...dateFormat, hour: '2-digit', minute: '2-digit'})}</b>
                  </span>
                  </div> : 'Nessuna scadenza'}
              </div>
              <div className="flex flex-row gap-x-2">
                <ClockIcon className="h-5 text-am-700"/>
                {s.quiz.time_duration ? <span>Durata: <b>{s.quiz.time_duration}</b></span>
                  : 'Nessun limite di tempo'}
              </div>
            </div>
            <div>
              {actionCell(s.cod_status, s.quiz.cod_status, s.id_quiz)}
            </div>
            <div className="hidden sm:flex sm:grid-cols-4 py-3 sm:py-0">
              {(s.quiz.tag_list ?? []).map((tag: string) => {
                return <span className="inline-flex items-center rounded-md bg-green-50 mx-2 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                {tag}
              </span>
              })}
            </div>
          </>
        })
      })
  }

  return (
    <>
      <div className="my-8">
        {sessionList ?
          sessionList.length ?
            <div>
              <RefManList
                limit={10}
                containerId="observer-reports-list"
                items={getListItems(sessionList)}
                rowClassNames={["sm:grid", "sm:grid-cols-11", "items-center", '!cursor-default']}
                pagination='frontend'
                offset={offset}
                setOffset={setOffset}
                total={sessionList.length}
              />
            </div>
            : <Alert title="Nessun quiz presente"/>
          : <QuizPlaceholder/>
        }
      </div>
      {showStartSession && startSessionQuizId &&
        <StartSessionModal
          idQuiz={startSessionQuizId}
          onExit={() => setShowStartSession(!showStartSession)}
        />
      }
    </>
  )
}