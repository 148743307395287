import {apiRequest} from "@services/apiRequest";


export const getColumns = (filteredJobs) => [
  {
    title: "Tessera",
    field: "id_fip_code"
  },
  {
    title: "Tesserato",
    field: "member",
    cellStyle: {minWidth: 250, fontWeight: "bold"},
    headerStyle: {minWidth: 250},
  },
  {
    title: "Ruolo",
    field: "id_job",
    lookup: filteredJobs.reduce((acc, i) => {
      acc[i.id] = i.name;
      return acc
    }, {})
  }
]

export const OPTIONS = {
  filtering: true,
  toolbar: false,
  search: false,
  paging: false,
  sorting: true,
  filterCellStyle: {maxWidth: 200}
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    editRow: {
      deleteText: "Vuoi davvero eliminare la riga?",
    },
  },
};


export const onRowDelete = (oldData, dataContext, push) =>
  new Promise((resolve, reject) => {
    apiRequest
      .delete(`/jobs/members/${oldData.id_fip_code}/${oldData.id_job}`)
      .then(() => {
        const member = dataContext.members[oldData.id_fip_code]
        dataContext.setMembers({...dataContext.members, [oldData.id_fip_code]: {
          ...member, members_jobs: member.members_jobs.filter(i => i.id_job !== oldData.id_job)
          }})
        push({title: "Riga eliminata con successo", type: "success"});
        resolve();
      })
      .catch(() => {
        push({
          title: "Errore durante l'eliminazione della riga",
          type: "error",
        });
        reject();
      });
  });