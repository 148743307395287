import React, {useContext, useState} from "react";

// Services
import AuthenticationService from "@services/AuthenticationService";
import {NotificationsContext} from "@ui-components/Notifications";
import useCategories from "@services/hooks/useCategories";
import useMembers from "@services/hooks/useMembers";
import useLevels from "@services/hooks/useLevels";
import useJobs from "@services/hooks/useJobs";

// Components
import GroupSelection from "@utils/groupSelection";
import EntryCategories from "./Categories";
import EntryMembers from "./Members";
import EntryLevels from "./Levels";
import EntryJobs from "./Jobs";

// UI
import {PageHeading} from "@ui-components/Container";
import Tabs from "@ui-components/Tabs";


export default function Entry() {

  const { push } = useContext(NotificationsContext);
  const [tabIndex, setTabIndex] = useState(0);
  const groups = AuthenticationService.getUserGroups();
  const [selectedGroup, setSelectedGroup] = useState(groups[0]);

  const members = useMembers({group: selectedGroup, onlyActive: false, assigned: false, onlyInternal: false})
  const categories = useCategories({group: selectedGroup, onlyActive: false, assigned: false});
  const levels = useLevels({asObj: true});
  const jobs = useJobs()

  let levelsDuplicates = 0
  if (levels) {
    let orders = []
    Object.values(levels).forEach(i => {
      if (orders.includes(i.level_order)) {
        levelsDuplicates += 1
      }
      orders.push(i.level_order)
    })
  }

  const showCurrentTab = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return <EntryMembers push={push} group={selectedGroup} members={members} categories={categories} />;
      case 1:
        return <EntryJobs push={push} group={selectedGroup} jobs={jobs} members={members} />;
      case 2:
        return <EntryCategories push={push} group={selectedGroup} categories={categories} />;
      case 3:
        return <EntryLevels push={push} levels={levels} />;
      default:
        throw new Error('tab index out of range')

    }
  }

  return (
    <>
      <PageHeading
        title="Settings"
        contentActions={groups.length > 1 ?
          <div className="mr-6">
            <GroupSelection selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}/>
          </div> : null
        }
      />
      <div className="flex justify-around sm:-mt-8 mb-8">
        <Tabs
          setActiveTab={setTabIndex}
          active={tabIndex}
          tabs={[
            {
              name: "Tesserati",
              notifications: members
                ? Object.values(members).filter(i => i.category.cod_category === '-').length : null
            },
            {
              name: "Incarichi"
            },
            {
              name: "Categorie"
            },
            {
              name: 'Campionati',
              notifications: levelsDuplicates
            }
          ]}
        />
      </div>
      {showCurrentTab(tabIndex)}
    </>
  );
}
