import {ReportsFormTotalScoreType} from "./ReportsFormTotalScore.type";
import React, {useEffect, useState} from "react";
import {
  getTotalScore,
  saveTotal
} from "@components/RefMan/Reports/ReportsForm/ReportsFormTotalScore/ReportsFormTotalScore.lib";

const ReportsFormTotalScore = ({
                                 sectionId,
                                 data,
                                 results,
                                 setResults,
                               }: ReportsFormTotalScoreType) => {
  const [score, setScore] = useState(0)

  /**
   * Handle results changes
   */
  useEffect(() => {
    if (results) {
      setScore(getTotalScore(results));
    }
  }, [results]);

  /**
   * Saving score on changes
   */
  useEffect(() => {
    saveTotal(score, data, results, setResults, sectionId)
  }, [score]);

  return <div className="reportsformtotalscore__container">
    <div className="reportsformtotalscore__header border-b border-gray-200 pb-3">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {`${data.title} `}
      </h3>
    </div>
    <div className="reportsformtotalscore__body pt-3">
      <div className="col-span-full">
        <div className="mt-2 text-sm text-gray-900">
          {score.toString()}
        </div>
      </div>
    </div>
  </div>
}

export default ReportsFormTotalScore;