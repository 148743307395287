import React, {useState} from "react";

import {AddQuestionToQuiz} from "@components/RefMan/Quiz/Question/AddQuestionToQuiz";
import {QuizQuestion} from "@components/RefMan/Quiz/Question/QuizQuestion";
import Alert from "@ui-components/Alert";

import {Association, QuizDetailQuestionsProps} from "@components/RefMan/Quiz/types";
import Button from "@ui-components/Button";
import {ArrowsUpDownIcon, CheckIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {apiRequest} from "@services/apiRequest";


export function QuizDetailQuestions({data, setRefreshData, push}: QuizDetailQuestionsProps) {

  const [editSort, setEditSort] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<Association[]>(
    data.associations
      .sort((a, b) => a.order - b.order)
      .map((a, index) => ({...a, order: index + 1}))
  )

  const updateSort = () => {
    setLoading(true)
    const payload = {
      id_quiz: data.id,
      questions: questions.map(i => ({id_question: i.question.id, order: i.order}))
    }
    apiRequest
      .post('/quizzes/associations/reorder', payload)
      .then(() => setEditSort(false))
      .catch(() => push({title: "Errore imprevisto", type: "error"}))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between">
        <AddQuestionToQuiz idQuiz={data.id} setRefreshData={setRefreshData}/>
        <div>
          {editSort
            ? <div className="flex flex-row gap-2">
              <Button onClick={updateSort} submitting={loading}>
                <CheckIcon className="w-5 inline-block mr-2"/>Salva
              </Button>
              <Button styleType="white" onClick={() => setEditSort(false)}>
                <XMarkIcon className="w-5 inline-block mr-2"/>Chiudi
              </Button>
            </div>
            : <Button styleType="white" onClick={() => setEditSort(true)}>
              <ArrowsUpDownIcon className="w-5 inline-block mr-2"/>Riordina
            </Button>
          }
        </div>
      </div>
      {data.associations.length === 0 ?
        <div className="my-10">
          <Alert title="Non ci sono domande in questo quiz!"/>
        </div> :
        <div className="h-55vh overflow-y-auto p-3 border my-3 rounded-2xl">
          <dl className="divide-y border-gray-900">
            {questions.map(q => (
              <QuizQuestion
                key={q.question.id}
                question={q.question}
                idQuiz={data.id}
                order={q.order}
                setRefreshData={setRefreshData}
                editSort={editSort}
                numQuestions={data.associations.length}
                setQuestions={setQuestions}
              />
            ))}
          </dl>
        </div>
      }
    </>
  )
}