import React, {useRef, useContext, useState} from "react";

// Services
import {CROP_ASPECT_RATIO, CROP_ASPECT_RATIO_TOLERANCE} from "@utils/constants";
import {NotificationsContext} from "@ui-components/Notifications";
import {apiRequest} from "@services/apiRequest";
import axios from "axios";

// Components
import PrivateImage from "@components/PrivateImage";
import ImageCropper from "./ImageCropper";

// UI
import {ExclamationTriangleIcon, ScissorsIcon, UserCircleIcon} from "@heroicons/react/24/outline";
import {PlusIcon} from "@heroicons/react/24/solid";
import ProgressBar from "@components/ProgressBar";
import Button from "@ui-components/Button";


const re = /(?:\.([^.]+))?$/;
const maxSize = 1024

const ImageUploader = ({idFipCode}) => {

  const hiddenImageInputRef = useRef();
  const {push} = useContext(NotificationsContext);

  const [crop, setCrop] = useState();
  const [signedUrl, setSignedUrl] = useState();
  const [progress, setProgress] = useState(0);
  const [notCompliantImage, setNotCompliantImage] = useState(false);
  const [cropUploadProgresses, setCropUploadProgresses] = useState(false);

  const [refreshImgUrl, setRefreshImgUrl] = useState(`members/${idFipCode}.png`);

  function getImageSize(url, callback) {
    const img = new Image();
    img.src = url;
    img.onload = function () {
      callback(this.width, this.height);
    }
  }

  const checkUploadedImage = (file) => {

    let error = null;
    const format = re.exec(file.name)[1];

    if (!format)
      error = "Unknown file format";

    if (maxSize) {
      const size = file.size;
      const kb = Math.round(size / 1024);
      if (maxSize < kb)
        error = "File size too big";
    }

    return {format, error};
  }


  const uploadImage = async (file, format, onUploadStart, onUploadProgress, onSafeEnd) => {

    // --- get signed url to be used to upload image to S3
    const {url, fields} = await apiRequest.post(`/aws-signed-url`, {
      content_type: file.type,
      format: format.split('?')[0],
      prefix: `members/${idFipCode}`,
      method: "POST",
    });

    // --- prepare s3 upload request's payload
    const formData = new FormData();
    Object.keys(fields).map((key) => {
      return formData.append(key, fields[key]);
    });
    // Actual file has to be appended last.
    formData.append("file", file);

    // --- upload image to s3
    onUploadStart();
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "max-age=31536000",
      },
      onUploadProgress: onUploadProgress
    };
    try {
      await axios.post(url, formData, options);
      console.log('done')
      setRefreshImgUrl(undefined)
      setRefreshImgUrl(`members/${idFipCode}.png`)
    } catch (error) {
      console.log(error);
      push({
        title: "Something went wrong",
        type: "error",
      });
      return false;
    } finally {
      onSafeEnd();
    }
    return true;
  }


  const uploadCrop = async (file) => {
    // replace s3 image with crop
    const success = await uploadImage(
      file,
      re.exec(signedUrl).slice(-1)[0],
      () => {
        setCropUploadProgresses(0)
      },
      (progressEvent) => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        setCropUploadProgresses(percentCompleted);
      },
      () => {
        // remove current image url from crops in 'sending' state
        setCropUploadProgresses(undefined)
      },
      signedUrl
    );

    if (success) {
      // close crop utility if everything went fine
      setCrop(undefined)
      // remove target image from non-compliant ones
      setNotCompliantImage(false);
    }
  }


  return (
    <div className="flex flex-col items-center gap-2">
      <h1 className="font-bold">Modifica immagine profilo</h1>
      <div className="flex flex-col items-center gap-2">

        {/* Hidden file uploader, triggered by fancy "new image" button */}
        <input
          accept="image/jpeg,image/png"
          ref={hiddenImageInputRef}
          type="file"
          style={{display: "none"}}
          onChange={async (e) => {
            // retrieve uploaded image from event
            const file = e.target.files[0];
            e.target.value = null;

            // check if uploaded image is compliant
            const {format, error} = checkUploadedImage(file);
            if (error) {
              push({title: error, type: "error"});
              return;
            }

            // upload file to S3
            await uploadImage(
              file,
              format,
              () => {
                setProgress(0)
              },
              (progressEvent) => {
                const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
              },
              () => {
                setProgress(0)
              }
            )
          }}
        />

        <div
          className={`cursor-pointer mt-2 mb-10 flex items-center justify-center border-4 w-24`}
          style={{aspectRatio: CROP_ASPECT_RATIO.toString()}}
          onClick={(e) => {
            e.preventDefault();
            hiddenImageInputRef.current.click();
          }}
        >
          {progress ? (
            <ProgressBar progress={progress}/>
          ) : (
            <div className="flex flex-col justify-center gap-2 items-center content-center text-gray-300">
              <span>Upload</span>
              <div className="w-8 h-8">
                <PlusIcon/>
              </div>
            </div>
          )}
        </div>

        <div className={`border-4 overflow-hidden w-48 lg:w-56`} style={{aspectRatio: CROP_ASPECT_RATIO.toString()}}>
          {/* -------------------- Preview -------------------- */}
          <PrivateImage
            image_url={refreshImgUrl}
            tag="div"
            className="w-full h-full bg-cover bg-center"
            onRetrievedSignedURL={(sUrl) => {
              // collect the signed url into the state
              setSignedUrl(sUrl)
              // mark image as non-compliant if its aspect ratio is different from the preferred
              getImageSize(sUrl, (w, h) => {
                if (Math.abs(w / h - CROP_ASPECT_RATIO) < CROP_ASPECT_RATIO_TOLERANCE)
                  return;
                setNotCompliantImage(true);
              })
            }}
            altReturn={
              <UserCircleIcon className="w-48 h-48 lg:w-56 lg:h-56 opacity-20"/>
            }
          />
        </div>
        <div className="flex-grow">
          {
            notCompliantImage && (
              <div className="flex items-center gap-1 text-xs text-yellow-500 my-2">
                <ExclamationTriangleIcon className="w-3"/>
                <span>Dimensione non standard</span>
              </div>
            )
          }
        </div>
        <div className="flex-grow">
          <Button full styleType="default" onClick={() => {
            setCrop(true)
          }} disabled={!signedUrl}>
            <ScissorsIcon className="w-4 mr-2"/>
            Ritaglia
          </Button>
        </div>

        {/* ==================== Crop ==================== */}
        {
          crop && (
            <div className="mt-1">
              <div className="w-full pl-16">
                <svg width="15" height="15" className="fill-current text-gray-100">
                  <polygon points="7.5 2.25, 15 15, 0 15"/>
                </svg>
              </div>
              <div className="py-3 px-4 bg-gray-100 border-4 border-t-0 rounded-2xl">
                <p className="mb-2 flex gap-2 items-center text-base">
                  <ScissorsIcon className="w-4"/>
                  <span className="font-bold">Ritaglia immagine</span>
                </p>
                <ImageCropper
                  originalImage={signedUrl}
                  // mimeType={mime.lookup(signedUrl)}
                  cropState={crop}
                  onCropChange={(crop) => {
                    setCrop(crop)
                  }}
                  aspectRatio={CROP_ASPECT_RATIO}
                  onConfirm={async (file) => {
                    await uploadCrop(file)
                  }}
                  onCancel={() => {
                    setCrop(undefined)
                  }}
                  submitting={cropUploadProgresses}
                />
              </div>
            </div>
          )
        }

      </div>
    </div>
  );
};

export default ImageUploader;