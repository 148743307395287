import {useContext, useEffect} from "react";

import AuthenticationService from "@services/AuthenticationService";
import {DataContext} from "../DataContext/DataContext";
import apiRequest from "../apiRequest";

const useThreads = () => {
  const userIdFipCode = AuthenticationService.getUserFipCode();
  const dataContext = useContext(DataContext);

  useEffect(() => {
    let closed = false;
    const fetchData = async () => {
      try {
        const data = await apiRequest.get(`/threads`);
        if (!closed) {
          dataContext.setThreads({
            all: data,
            countToRead: data.filter(i => i.recipients.filter(j => j.id_member === userIdFipCode && !j.flg_read).length > 0).length +
              data.filter(i => i.id_creator === userIdFipCode && i.flg_check_needed).length
          });
        }
      } catch (error) {
        console.log(error)
        dataContext.setThreads(false);
      }
    };

    if (dataContext.threads === null) {
      fetchData();
    }

    return () => {
      closed = true;
    };
  }, [dataContext.threads]);  // eslint-disable-line react-hooks/exhaustive-deps
};

export default useThreads;
