import React from 'react'
import { type TypesLogoProps } from './logo.types'

const Logo = ({
  height = 'h-24',
  width = 'w-auto',
  title = false,
  imagePath = process.env.PUBLIC_URL + '/favicon.ico'
}: TypesLogoProps): React.JSX.Element =>
    <div className="flex flex-col shrink-0 mt-4 items-center m-0">
        <img
            className={`${height} ${width} object-contain mx-auto`}
            src={imagePath}
            alt="logo"
        />
        {title ? <span className="font-bold">RefMan</span> : null}
    </div>

export default Logo
