import React from "react";
import {
  ObserverGameInfoItemType
} from "@components/RefMan/Reports/ReportsMatch/ObserverGameInfoItem/ObserverGameInfoItem.type";
import {capitalizeSentence} from "@utils/lib/string";

const ObserverGameInfoItem = ({
                                title,
                                text,
                                capitalizeTitle = false,
                                capitalizeText = false,
                                classNames = "",
                              }: ObserverGameInfoItemType) => {
  return <li
    className={"observerreportsmatch__game-info-item overflow-hidden rounded-md border bg-white" + (classNames ? ` ${classNames}` : "")}>
    <div
      className="flex items-center justify-center border-b border-gray-900/5 bg-gray-100 px-4 py-1 text-center text-sm">
      <strong>{capitalizeTitle ? capitalizeSentence(title) : title}</strong>
    </div>
    <div className="divide-y divide-gray-100 px-4 py-2 leading-6 text-center text-sm">
      {capitalizeText ? capitalizeSentence(text) : text}
    </div>
  </li>
}

export default ObserverGameInfoItem;