import { RefManListItemType, PaginationType } from '@components/RefMan/RefManList/RefManList.type';

export const onPreviousClick = (
  offset: number,
  limit: number,
  setRows: React.Dispatch<React.SetStateAction<RefManListItemType[] | undefined>>,
  setOffset: React.Dispatch<React.SetStateAction<number>>,
  pagination: PaginationType
) => {
  if (offset - limit >= 0) {
    if (pagination === 'backend') {
      setRows(undefined);
    }
    setOffset(offset - limit);
  }
};

export const onNextClick = (
  offset: number,
  limit: number,
  total: number,
  setRows: React.Dispatch<React.SetStateAction<RefManListItemType[] | undefined>>,
  setOffset: React.Dispatch<React.SetStateAction<number>>,
  pagination: PaginationType
) => {
  if (offset + limit < total) {
    if (pagination === 'backend') {
      setRows(undefined);
    }
    setOffset(offset + limit);
  }
};

export const getFooterText = (offset: number, limit: number, total: number) =>
  `Risultati da ${offset + 1} a ${offset + limit <= total ? offset + limit : total} di ${total} totali`;
