import AuthenticationService from "@services/AuthenticationService";

import {
  HomeIcon,
  IdentificationIcon,
  GlobeAltIcon,
  BriefcaseIcon,
  AdjustmentsVerticalIcon,
  CalendarIcon,
  ClipboardDocumentListIcon,
  CurrencyEuroIcon,
  Cog6ToothIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";

import Home from "../pages/Home";
import Entry from "../pages/Entry";
import Reports from "../pages/Reports";
import PlannerAgenda from "@pages/Planner/Agenda";
import NominationChanges from "../pages/Nominations/Changes";
import NominationPersonalReport from "@pages/Nominations/PersonalReport";
import Algorithms from "@pages/Nominations/Algorithms";

import { Games } from "@pages/Games";
import { Members } from "@pages/Members";
import { Economics } from "@pages/Economics";
import { Activities } from "@pages/Activities";
import { PlannerPlayers } from "@pages/Planner/Players";
import { PlannerAbsences } from "@pages/Planner/Absences";
import { PlannerCalendar } from "@pages/Planner/Calendar";
import { PlannerRelocations } from "@pages/Planner/Relocations";
import { NominationAnalytics } from "@pages/Nominations/Analytics";
import { NominationObservers } from "@pages/Nominations/Observers";
import { NominationsParameters } from "@pages/Nominations/Parameters";
import { NominationsIncompatibilities } from "@pages/Nominations/Incompatibilities";
import { Threads } from "@pages/Threads";
import { TechnicalAreaQuiz } from "@pages/TechnicalArea/Quiz";
import { SmartSearch } from "@pages/TechnicalArea/SmartSearch";

const scopes = AuthenticationService.getUserScopes();
const isPersonalUser = scopes.includes("home:personal");

const routes = [
  {
    name: "Home",
    href: "/home",
    Icon: HomeIcon,
    component: Home,
    hidden: false,
  },
  {
    name: "members",
    href: "/members",
    Icon: IdentificationIcon,
    component: Members,
    hidden: isPersonalUser,
  },
  {
    name: "games",
    href: "/games",
    Icon: GlobeAltIcon,
    component: Games,
    hidden: isPersonalUser,
  },
  {
    name: "nominations",
    href: "/nominations/personal-report",
    Icon: AdjustmentsVerticalIcon,
    component: NominationPersonalReport,
    hidden: !isPersonalUser,
  },
  {
    name: "nominations",
    Icon: AdjustmentsVerticalIcon,
    hidden: isPersonalUser,
    dropdown: [
      {
        scopes: ["nominations:create"],
        href: "/nominations/algorithms",
        name: "algorithms",
        component: Algorithms,
      },
      {
        scopes: ["nominations:create"],
        href: "/nominations/changes",
        name: "changes",
        component: NominationChanges,
      },
      {
        scopes: ["home:personal", "home:basic", "home:total"],
        href: "/nominations/personal-report",
        name: "all-personal-report",
        component: NominationPersonalReport,
      },
      {
        scopes: ["nominations:read", "home:total"],
        href: "/nominations/analytics",
        name: "analytics",
        component: NominationAnalytics,
      },
      {
        scopes: ["reports:admin"],
        href: "/nominations/observers",
        name: "observers",
        component: NominationObservers,
      },
      {
        scopes: ["nominations:create"],
        href: "/nominations/incompatibilities",
        name: "incompatibilities",
        component: NominationsIncompatibilities,
      },
      {
        scopes: ["nominations:create", "entry:members"],
        href: "/nominations/members-parameters",
        name: "members-parameters",
        component: NominationsParameters,
      },
    ],
  },
  {
    name: "planner",
    Icon: CalendarIcon,
    dropdown: [
      {
        scopes: ["home:personal"],
        href: "/planner/agenda",
        name: "agenda",
        component: PlannerAgenda,
        hidden: true,
      },
      {
        scopes: ["absences:read"],
        href: "/planner/calendar",
        name: "calendar",
        component: PlannerCalendar,
      },
      {
        scopes: ["absences:personal", "absences:create"],
        href: "/planner/absences",
        name: "absences",
        component: PlannerAbsences,
      },
      {
        scopes: ["absences:personal", "absences:create"],
        href: "/planner/players",
        name: "players",
        component: PlannerPlayers,
      },
      {
        scopes: ["absences:create"],
        href: "/planner/relocations",
        name: "relocations",
        component: PlannerRelocations,
      },
    ],
  },
  {
    name: "activities",
    href: "/activities",
    Icon: BriefcaseIcon,
    component: Activities,
    hidden: false,
  },
  {
    name: "reports",
    href: "/reports",
    scopes: [
      "reports:editor",
      "reports:viewer",
      "reports:personal",
      "reports:admin",
    ],
    Icon: ClipboardDocumentListIcon,
    component: Reports,
    hidden: false,
  },
  {
    name: "economics",
    href: "/economics",
    scopes: ["economics:read"],
    Icon: CurrencyEuroIcon,
    component: Economics,
    hidden: false,
  },
  {
    name: "technical-area",
    Icon: CalendarIcon,
    dropdown: [
      {
        scopes: ["quizzes:read"],
        href: "/technical-area/quiz",
        name: "quiz",
        component: TechnicalAreaQuiz,
      },
      {
        scopes: ["quizzes:read"],
        href: "/technical-area/smart-search",
        name: "Smart Search",
        component: SmartSearch,
      },
    ],
  },
  {
    name: "entry",
    href: "/entry",
    scopes: ["entry:members"],
    Icon: Cog6ToothIcon,
    component: Entry,
    hidden: false,
  },
  {
    name: "all-games",
    href: "/games",
    Icon: GlobeAltIcon,
    component: Games,
    hidden: !isPersonalUser,
  },
  {
    name: "colleagues",
    href: "/members",
    Icon: IdentificationIcon,
    component: Members,
    hidden: !isPersonalUser,
  },
  {
    name: "communications",
    href: "/threads",
    Icon: ChatBubbleLeftRightIcon,
    component: Threads,
  },
];

export default routes;
