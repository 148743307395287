import React from "react";
import {IntlShape} from "react-intl";
import {BasicKpis} from "@pages/Economics/Economics.type";
import {TruckIcon} from "@heroicons/react/24/outline";

export function sumProperties(obj: BasicKpis, properties: (keyof BasicKpis)[]): number {
  return properties.reduce((sum, prop) => sum + obj[prop], 0);
}

export const EconomicKpi = (
  {
    intl,
    title,
    value,
    trip,
    color,
    style,
  }: {
    intl: IntlShape,
    title: string,
    value: number,
    trip?: number,
    color?: string,
    style?: 'currency' | 'percent' | undefined
  }) => {
  return (
    <div key={title} className="w-full flex flex-col border-l-4 pl-4">
      <span className="text-xs sm:text-sm">{title}</span>
      <span className={`font-bold text-lg sm:text-xl ${color ?? ''}`}>
        {intl.formatNumber(value, {maximumFractionDigits: 0, currency: "EUR", style: style})}
      </span>
      {trip ?
        <div className="flex flex-row gap-2">
          <TruckIcon className={`w-4 ${color ?? ''}`}/>
          <span className={`font-bold text-xs sm:text-sm ${color ?? ''}`}>
            {intl.formatNumber(trip, {maximumFractionDigits: 0, currency: "EUR", style: style})}
          </span>
        </div> : null
      }
    </div>
  )
}
