import {api} from "@services/apiRequest";
import {IntlShape, useIntl} from "react-intl";
import {useContext, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {
  filterAllQuestionsPayloadSchema,
  QuestionBaseType,
  QuestionType
} from "@components/RefMan/Quiz/types";

export const useQuestion = () => {

  // hooks
  const intl: IntlShape = useIntl();

  // context
  const {push} = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);

  const callApi = async (fn: Promise<any>, errorMsg: string) => {
    setLoading(true);
    try {
      return await fn;
    } catch (e) {
      push({type: "error", title: intl.formatMessage({id: "error"}), message: errorMsg});
    } finally {
      setLoading(false);
    }
  }

  const create = async (question: QuestionBaseType) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.post(`/quizzes/question`, question);
        resolve(data);
      }),
      "CREATE_QUESTION",
    );

  const update = async (question: QuestionBaseType, idQuestion: number) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.put(`/quizzes/question/${idQuestion}`, question);
        resolve(data);
      }),
      "UPDATE_QUESTION",
    );
  const getAll = async (payload: filterAllQuestionsPayloadSchema) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.post(`/quizzes/questions/all`, payload);
        resolve(data);
      }),
      "POST_QUESTION",
    );

  const associateQuestionToQuiz = async (id_quiz: number, id_questions: number[]) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.post(`/quizzes/quiz-question-associations`, {id_quiz, id_questions});
        resolve(data);
      }),
      "ERROR_ASSOCIATE_QUESTION_TO_QUIZ",
    );

  const getQuestionById = async (id_question: number) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.get<QuestionType>(`/quizzes/question/${id_question}`);
        resolve(data);
      }),
      "ERROR_GET_QUESTION_BY_ID",
    );

  const getNumQuizzesByQuestionId = async (id_question: number) =>
    await callApi(
      new Promise(async (resolve) => {
        const {data} = await api.get<QuestionType>(`/quizzes/question/num_quizzes/${id_question}`);
        resolve(data);
      }),
      "ERROR_GET_NUM_QUIZZES_BY_QUESTION_ID",
    );

  return {getAll, getQuestionById, create, update, associateQuestionToQuiz, getNumQuizzesByQuestionId, loading};

}