import _ from 'lodash'
import {ALL_STATUS} from "@utils/constants";
import type {NominationSchema} from "@services/types/nomination";

export const aggregateNominationByMonthStatus = (data: NominationSchema[]) => {

  let res: ({ month: string } & { [key: string]: number })[] = []
  ALL_STATUS.forEach(s => {
    const tmp = _(data
        .map(i => ({status: i.status, month: i.game.dat_game.slice(0, -2) + '02'}))
        .filter(i => i.status === s)
    ).groupBy('month').map((items, month) => {
      return ({
        month: month,
        [s]: items.length,
        total: 0
      })
    })
        .value()
    res = _.values(_.merge(_.keyBy(res, 'month'), _.keyBy(tmp, 'month')));
  })
  return _.sortBy(res, i => i.month)
}

export const aggregateNominationByLevelStatus = (data: NominationSchema[]) => {
  let res: ({ level: string } & { [key: string]: number })[] = []
  ALL_STATUS.forEach(s => {
    const tmp = _(data
        .map(i => ({status: i.status, level: i.game.level.des_level, order: i.game.level.level_order}))
        .filter(i => i.status === s)
    ).groupBy('level').map((items, level) => {
      return ({
        level: level,
        order: items[0].order,
        [s]: items.length,
        total: 0
      })
    })
        .value()
    res = _.values(_.merge(_.keyBy(res, 'level'), _.keyBy(tmp, 'level')));
  })
  return _.sortBy(res, i => i.order).reverse()
}