import React, {useEffect, useState} from "react";

// Services
import {getMaxLevelFromNominations} from "@utils/index";
import {OK_STATUS, TODAY} from "@utils/constants";

// Components
import {PersonalNominationTotalKpi} from "@components/RefMan/PersonalNominationTotalKpi";
import {NominationCard} from "@components/RefMan/NominationCard";

// Ui
import {ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {ArrowLongRightIcon} from "@heroicons/react/24/solid";
import Placeholder from "@ui-components/Placeholder";
import Toggle from "@ui-components/Toggle";
import Button from "@ui-components/Button";
import Alert from "@ui-components/Alert";


const MemberDetailNomination = ({idFipCode, isPersonalHome, nominations, history}) => {

  const [maxLevel, setMaxLevel] = useState();
  const [includeRev, setIncludeRev] = useState(false);
  const [showSections, setShowSections] = useState({future: true, passate: false});
  const [showMore, setShowMore] = useState({future: false, passate: false});
  const [games, setGames] = useState({future: [], passate: []});

  useEffect(() => {
    if (nominations) {
      setMaxLevel(getMaxLevelFromNominations(nominations))
    }
  }, [nominations])

  useEffect(() => {
    if (nominations) {
      const tmp = includeRev ? nominations : nominations.filter(i => OK_STATUS.includes(i.status))
      setGames({
        future: tmp.filter(i => i.game.dat_game >= TODAY).sort((a, b) => a.game.dat_game.localeCompare(b.game.dat_game)),
        passate: tmp.filter(i => i.game.dat_game < TODAY).sort((a, b) => a.game.dat_game.localeCompare(b.game.dat_game)).reverse()
      })
    }
  }, [nominations, includeRev])


  return (
    <div className="flex flex-col w-full gap-5">
      <div className="w-full flex flex-row justify-between items-center text-gray-800 pb-1 border-b-2 border-gray-900">
        <p className="font-bold text-2xl">Designazioni</p>
        <p
          className="cursor-pointer hover:underline hover:font-bold"
          onClick={() => history.push({pathname: '/nominations/personal-report', state: {idFipCode: idFipCode}})}
        >
          Vai al dettaglio <ArrowLongRightIcon className="inline-block h-4 w-4"/>
        </p>
      </div>
      {nominations && maxLevel ?
        <>
          {nominations.length > 0 ?
            <div className="w-full flex flex-col gap-2 items-center">
              <PersonalNominationTotalKpi nominations={nominations} maxLevel={maxLevel}/>
              <div>
                <Toggle
                  smaller
                  vertical
                  label={<span className="mr-3">Includi revoche e rifiuti</span>}
                  checked={includeRev}
                  onChange={() => setIncludeRev(!includeRev)}
                />
              </div>
              {['future', 'passate'].map(i => (
                <div className="w-full flex flex-col py-3" key={i}>
                  <div
                    className="flex flex-row cursor-pointer text-gray-900 items-center gap-2"
                    onClick={() => {
                      setShowMore({...showMore, [i]: false})
                      setShowSections({...showSections, [i]: !showSections[i]})
                    }}
                  >
                    {showSections[i]
                      ? <ChevronDownIcon className="h-5 w-5"/>
                      : <ChevronRightIcon className="h-5 w-5"/>
                    }
                    <span className="text-md font-bold">
                  Gare {i} ({games[i].length})
                </span>
                  </div>
                  {showSections[i] ?
                    <div className="flex flex-col gap-2">
                      <div className="p-1 w-full flex flex-col divide-y divide-gray-300">
                        {games[i].slice(0, showMore[i] ? undefined : 3).map(g => (
                          <div key={g.cod_game}>
                            {/*todo use list component*/}
                            <NominationCard nom={g} idFipCode={idFipCode} isPersonal={isPersonalHome} fromHome={isPersonalHome}/>
                          </div>
                        ))}
                      </div>
                      <div>
                        <Button
                          onClick={() => setShowMore({...showMore, [i]: !showMore[i]})}
                          styleType="white">
                          {showMore[i] ? 'Mostra meno' : 'Mostra tutte'}
                        </Button>
                      </div>
                    </div>
                    : null
                  }
                </div>
              ))}

            </div>
            :
            <div>
              <Alert title="Nessuna designazione presente"/>
            </div>
          }
        </>
        :
        <div className="flex flex-col w-full mb-4 gap-2">
          <div className="flex flex-row gap-10">
            <Placeholder height="h-16" classNames="rounded-lg w-1/3"/>
            <Placeholder height="h-16" classNames="rounded-lg w-1/3"/>
            <Placeholder height="h-16" classNames="rounded-lg w-1/3"/>
          </div>
          <Placeholder height="h-5" classNames="rounded-lg"/>
          <Placeholder height="h-36" classNames="rounded-lg"/>
        </div>
      }
    </div>
  )
}

export default MemberDetailNomination