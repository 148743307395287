import {useContext, useEffect, useState} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import AuthenticationService from "../AuthenticationService";
import {DataContext} from "../DataContext/DataContext";
import apiRequest from "../apiRequest";
import _ from "lodash";

const useMembers = ({
                      group = undefined,
                      onlyActive = true,
                      excludeManager = true,
                      onlyAssignable = false,
                      onlyInternal = false,
                      assigned = true,
                      asObj = true,
                      byUserId = false,
                      jobs = []
                    }) => {

  const [userScopes] = useState(AuthenticationService.getUserScopes());
  // const groups = AuthenticationService.getUserGroups();
  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    let closed = false;
    const fetchMembers = async () => {
      try {
        // const params = groups.length === 1 ? `?cod_group=${groups[0]}` : ''
        // const data = await apiRequest.get(`/members${params}`);
        const data = await apiRequest.get("/members");
        if (!closed) {
          dataContext.setMembers((data || []).reduce((obj, row) => _.set(obj, [row.id_fip_code], row), {}));
        }
      } catch (error) {
        console.log(error)
        dataContext.setMembers(false);
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if (dataContext.members === null) {
      if (userScopes.indexOf("member:basic") >= 0) {
        fetchMembers();
      } else {
        dataContext.setMembers(false);
        push({
          title: "Scope member:basic non trovato",
          type: "error",
          fixed: true,
        });
      }
    }

    return () => {
      closed = true;
    };
  }, [userScopes, dataContext.members, push]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (!dataContext.members) {
    return undefined
  }
  let res = Object.values(dataContext.members).sort((a, b) => a.member.localeCompare(b.member));
  if (group) {
    res = res.filter(i => i.category.cod_group === group.toUpperCase())
  }
  if (onlyActive) {
    res = res.filter(i => i.category.cod_category !== 'X')
  }
  if (excludeManager) {
    res = res.filter(i => i.category.cod_category !== 'D')
  }
  if (onlyInternal) {
    res = res.filter(i => !i.flg_external)
  }
  if (onlyAssignable) {
    res = res.filter(i => !['OSS', 'DIR', 'FOR', 'STAT'].includes(i.cod_sub_category))
  }
  if (assigned) {
    res = res.filter(i => i.category.cod_category !== '-')
  }
  if (jobs.length) {
    res = res.filter(i => (i.members_jobs || []).filter(j => jobs.includes(j.job.job_type)).length > 0)
  }
  if (byUserId) {
    return res.filter(i => i.id_user).reduce((obj, i) => _.set(obj, [i.id_user], i.tag), {})
  }
  if (asObj) {
    return res.reduce((obj, row) => _.set(obj, [row.id_fip_code], row), {})
  }
  return res;
};

export default useMembers;
