import React, {useContext, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "@services/DataContext/DataContext";
import {api} from "@services/apiRequest";

// Ui
import {CheckIcon, TrashIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {AssignListMembers} from "@components/RefMan/AssignListMembers";
import {PageHeading} from "@ui-components/Container";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Alert from "@ui-components/Alert";
import Input from "@ui-components/Input";
import Tabs from "@ui-components/Tabs";

// Type
import {AddUpdateThreadPropsType} from "@pages/Threads/Threads.type";


export default function AddUpdateThread(
  {
    existingThread,
    onExit,
  }: AddUpdateThreadPropsType
) {

  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [subject, setSubject] = useState<string>(existingThread ? existingThread.subject : '');
  const [recipient, setRecipient] = useState<number[] | undefined>(existingThread?.recipients.filter(i => !i.flg_cc).map(i => i.id_member))
  const [recipientCC, setRecipientCC] = useState<number[] | undefined>(existingThread?.recipients.filter(i => i.flg_cc).map(i => i.id_member))

  const saveNewThread = () => {
    setLoading(true)
    api.post('/threads', {
      id: existingThread ? existingThread.id : null,
      subject: subject,
      recipient: (recipient ?? []),
      recipientCC: (recipientCC ?? []).filter(i => !(recipient ?? []).includes(i))
    })
      .then(({data}) => {
        // @ts-ignore
        dataContext.setThreads({...dataContext.threads, all: [...dataContext.threads.all.filter(i => i.id !== data.id), data].sort((a, b) => b.tms_creation.localeCompare(a.tms_creation))})
        push({title: "Dati aggiornati con successo", type: "success"})
        onExit()
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore nel salvataggio", type: "error"})
      })
      .finally(() => setLoading(false))
  }

  const sendDelete = () => {
    setDeleting(true)
    api.delete(`/threads/${existingThread?.id}`)
      .then(() => {
        // @ts-ignore
        dataContext.setThreads({...dataContext.threads, all: dataContext.threads.all.filter(i => i.id !== existingThread?.id)})
        onExit()
      })
      .catch(() => push({title: "Errore imprevisto", type: "error"}))
      .finally(() => setDeleting(false))
  }

  return <>
    <PageHeading
      title={existingThread ? "Modifica comunicazione" : "Nuova comunicazione"}
      contentActions={
        <div className="flex flex-row gap-2 justify-between sm:justify-end">
          <Button onClick={onExit} styleType="white">
            <XMarkIcon className="w-5 h-5 mr-1"/> Esci
          </Button>
          <Button onClick={saveNewThread} submitting={loading} disabled={!subject}>
            <CheckIcon className="w-5 h-5 mr-1"/> Salva
          </Button>
        </div>
      }
    />
    <div className="mt-3 flex flex-col">
      <div className="flex flex-col gap-2">
        <span className="font-bold">Oggetto</span>
        {/*@ts-ignore*/}
        <Input
          value={subject}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSubject(e.target.value)
          }}
          errorMessage="Inserisci un oggetto"
          required
        />
      </div>
      <div className="flex flex-row justify-between items-center">
        <Tabs
          setActiveTab={setTabIndex}
          active={tabIndex}
          tabs={[
            {
              name: `Destinatari (${(recipient ?? []).length})`
            },
            {
              name: `Per conoscenza (${(recipientCC ?? []).length})`
            }
          ]}
        />
        {existingThread ?
          <div className="flex justify-end">
            <div className="cursor-pointer text-red-700 hover:underline hover:font-bold"
                 onClick={() => setShowDelete(true)}>
              <TrashIcon className="h-5 w-5 inline-block -mt-1"/> Elimina
            </div>
          </div> : null
        }
      </div>
      {tabIndex === 0 ?
        <AssignListMembers
          membersList={recipient ?? []}
          setMembersList={setRecipient}
          description={<b>Destinatari della comunicazione</b>}
        /> :
        <AssignListMembers
          membersList={recipientCC ?? []}
          setMembersList={setRecipientCC}
          description={<b>Tesserati da inserire per conoscenza</b>}
        />
      }
    </div>
    {showDelete ?
      <Modal
        onExit={() => setShowDelete(false)}
      >
        <div className="my-5">
          <h1 className="font-bold sm:text-xl mb-5">Sei sicuro di voler eliminare la comunicazione?</h1>
          <Alert title="Non sarà possibile recuperare i dati"/>
        </div>
        <div className="w-full flex flex-col sm:flex-row gap-3 justify-between mt-5">
          <Button styleType="white" onClick={() => setShowDelete(false)}>
            Annulla
          </Button>
          <Button onClick={sendDelete} submitting={deleting}>
            Conferma
          </Button>
        </div>
      </Modal>
      : null
    }
  </>;
}
