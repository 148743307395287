import React, {useContext, useEffect, useState} from 'react';

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {useHistory, useParams} from "react-router-dom";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

// Components
import CreateUpdateBasicSessionModal from "@pages/Nominations/Algorithms/Components/CreateUpdateBasicSessionModal";
import StopDeleteBasicSessionModal from "@pages/Nominations/Algorithms/Components/StopDeleteBasicSessionModal";
import GeneralParametersModal from "@pages/Nominations/Algorithms/Components/GeneralParametersModal";
import GameParametersModal from "@pages/Nominations/Algorithms/Components/GameParametersModal";
import {getListItems} from "@pages/Nominations/Algorithms/Components/GameList";
import {StatusBullet} from "@components/RefMan/StatusBullet";
import {BasicSessionPlaceholder} from "@utils/placeholders";

// Ui
import {
  AdjustmentsHorizontalIcon,
  InboxArrowDownIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import RefManList from "@components/RefMan/RefManList/RefManList";
import Placeholder from "@ui-components/Placeholder";
import {PageHeading} from "@ui-components/Container";
import RadioGroup from "@ui-components/RadioGroup";
import Button from "@ui-components/Button";
import Label from "@ui-components/Label";

// Utils
import {
  getSessionStatusColorDescription,
  saveBasicAlgorithmConstraints,
  runBasicAlgorithm,
  SessionMetadata,
} from "@pages/Nominations/Algorithms/BasicAlgorithm.services";
import {algoDownloadExcel} from "@pages/Nominations/Algorithms/EliteAlgorithm.services";

// Hooks
import useBasicAlgorithmGames from "@services/hooks/useBasicAlgorithmGames";
import useMembers from "@services/hooks/useMembers";
import useLevels from "@services/hooks/useLevels";

// Types
import {BasicAlgorithmTaskSchema, UserKwargsSchema} from "@pages/Nominations/Algorithms/Algorithms.types";
import {GamesSchema, SelectedLevel, SelectedProvince} from "@pages/Games/Games.type";
import {GamesLevelFilter, GamesProvinceFilter} from "@pages/Games/Games.lib";
import {RelocationSchema} from "@pages/Planner/Calendar/Calendar.type";
import {DistancesDict} from "@services/types/city";
import {LevelSchema} from "@services/types/level";
import {Member} from "@services/types/member";


function BasicAlgorithmSession() {

  const intl = useIntl();
  const history = useHistory();
  const {push} = useContext(NotificationsContext);
  const {id_session} = useParams<{ id_session: string }>();
  const idSession = Number(id_session);

  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [stopDelete, setStopDelete] = useState<'stop' | 'delete' | undefined>(undefined);
  const nominators: Member[] = useMembers({assigned: false, byUserId: true});
  const [session, setSession] = useState<BasicAlgorithmTaskSchema>()
  const [userKwargs, setUserKwargs] = useState<UserKwargsSchema>();

  const [offset, setOffset] = useState<number>(0);
  const [toSave, setToSave] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [running, setRunning] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<'datetime' | 'game'>('datetime');

  const {games, distances, relocations}: {
    games: GamesSchema[] | undefined,
    distances: DistancesDict | undefined,
    relocations: RelocationSchema | undefined
  } = useBasicAlgorithmGames(session, setLoading);

  const levels: LevelSchema[] = useLevels({asObj: false, onlyManaged: true});

  const _levels = (!levels || !session) ? undefined :
    session.jsn_input_data.levels === 'all'
      ? levels.filter(i => session.cod_group === 'REF' ? i.flg_model_basic_ref : i.flg_model_basic_udc)
      : levels.filter(i => session.jsn_input_data.levels.includes(i.cod_level))

  const [showGeneralParameters, setShowGeneralParameters] = useState<boolean>(false);
  const [showGameParameters, setShowGameParameters] = useState<string | undefined>(undefined);

  const [selectedLevels, setSelectedLevels] = useState<SelectedLevel[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<SelectedProvince[]>([]);
  const [filteredGames, setFilteredGames] = useState<GamesSchema[]>([])

  useEffect(() => {
    const sortedGames = sortBy === 'game'
      ? [...(games ?? [])].sort((a, b) => a.game_number - b.game_number)
      : (games ?? [])
    setFilteredGames(sortedGames.filter(i =>
      (selectedProvinces.length === 0 || selectedProvinces.map(i => i.value).includes(i.city?.cod_province ?? '')) &&
      (selectedLevels.length === 0 || selectedLevels.map(i => i.value).includes(i.cod_level))
    ))
  }, [games, selectedLevels, selectedProvinces, sortBy]);

  useEffect(() => {
    const fetchData = () => {
      api.get(`/algorithms/basic/${idSession}`)
        .then(({data}) => {
          setSession(data);
        })
        .catch((err) => {
          console.log(err)
          history.push('/nominations/algorithms/basic')
          push({title: "Non è stato possibile recuperare i dati della sessione", type: "error"});
        });
    };

    if (!session && id_session) {
      fetchData();
    }

    if (session && session.cod_status === 'RUNNING') {
      const intervalID = setInterval(async () => {
        try {
          const {data} = await api.get<BasicAlgorithmTaskSchema>(`/algorithms/basic/${idSession}`);
          if (data.cod_status !== 'RUNNING') {
            setSession(data);
            clearInterval(intervalID);
          }
        } catch {
          console.log('error in updating task status')
        }
      }, 15000);
    }
    // eslint-disable-next-line
  }, [session]);

  useEffect(() => {
    if (session) {
      setUserKwargs(session.jsn_input_data)
    }
  }, [session]);

  const saveParameters = () => {
    if (session && userKwargs) {
      setSaving(true)
      saveBasicAlgorithmConstraints(session.id, userKwargs, push)
        .then(() => setToSave(false))
        .finally(() => setSaving(false))
    }
  }

  const {
    primaryColor,
    backgroundColor,
    description
  } = getSessionStatusColorDescription(session?.cod_status ?? '', session?.des_error_message);

  return (
    <>
      <PageHeading
        title={`Sessione ${idSession}`}
        historyBackPath="/nominations/algorithms/basic"
        contentActions={<div className="flex flex-row gap-1">
          <Button onClick={() => setShowUpdate(true)} styleType="white" className="text-xs h-8">
            <PencilIcon className='w-4 h-4 mr-2'/> Modifica
          </Button>
          <Button onClick={() => setStopDelete('delete')} styleType="white" className="text-xs h-8">
            <TrashIcon className="w-4 h-4"/>
          </Button>
        </div>
        }
      />
      {session && nominators ?
        <div>
          <SessionMetadata nominators={nominators} session={session} intl={intl}/>
          <div className="flex flex-col-reverse sm:flex-row justify-between items-center mb-4 gap-3">
            <div>
              <Button styleType="white" className="text-xs h-8" onClick={() => setShowGeneralParameters(true)}>
                <AdjustmentsHorizontalIcon className="w-5 inline-block mr-1"/>
                Parametri
              </Button>
            </div>
            <div className='flex flex-row gap-x-2 justify-center items-center sm:justify-end'>
              <div className="flex flex-row gap-x-2 items-center">
                <StatusBullet primaryColor={primaryColor} backgroundColor={backgroundColor}/>
                <p className={"text-gray-500 whitespace-nowrap"}>{description}</p>
              </div>
              <Button
                submitting={saving}
                className="text-xs h-8"
                disabled={!toSave}
                onClick={saveParameters}
              >
                Salva
              </Button>
              <Button
                submitting={running}
                className="text-xs h-8"
                disabled={toSave || session.cod_status === 'RUNNING'}
                onClick={() => runBasicAlgorithm(
                  idSession,
                  push,
                  intl,
                  setRunning,
                  setSession
                )}
              >
                Run
              </Button>
              {session.cod_status === 'RUNNING' ?
                <Button styleType="white" onClick={() => setStopDelete('stop')}>
                  <XCircleIcon className="w-4 h-4 text-red-700"/>
                </Button> : null
              }
            </div>
          </div>
          {games && distances && relocations && filteredGames && _levels && userKwargs && !loading ?
            <div>
              <div className='flex flex-col sm:flex-row justify-between gap-3 sm:items-center'>
                <div className='w-full z-30'>
                  <GamesLevelFilter
                    levels={_levels}
                    selectedLevels={selectedLevels}
                    setSelectedLevels={setSelectedLevels}
                    setOffset={setOffset}
                  />
                </div>
                <div className='w-full z-30'>
                  <GamesProvinceFilter
                    selectedProvinces={selectedProvinces}
                    setSelectedProvinces={setSelectedProvinces}
                    setOffset={setOffset}
                  />
                </div>
                <div className="w-full">
                  <Label>Ordina per</Label>
                  <div className="-mt-2">
                    <RadioGroup
                      id="sortBy"
                      horizontal
                      options={[
                        {value: "datetime", name: "Data e ora"},
                        {value: "game", name: "Numero gara"},
                      ]}
                      currentValue={sortBy}
                      onChange={(value: 'datetime' | 'game') => {
                        setSortBy(value)
                      }}
                    />
                  </div>
                </div>
                <div
                  className='mx-2 cursor-pointer'
                  onClick={() => algoDownloadExcel(
                    filteredGames,
                    session?.cod_group.toLowerCase() as 'ref' | 'udc',
                    session?.jsn_result, intl,
                    `Sessione ${session.id}`
                  )}
                >
                  <InboxArrowDownIcon className='w-7'/>
                </div>
              </div>
              <RefManList
                containerId="basic-algorithm-games"
                items={getListItems(
                  intl,
                  filteredGames,
                  distances,
                  relocations,
                  session.cod_group.toLowerCase() as 'ref' | 'udc',
                  userKwargs,
                  session,
                  session.cod_status === 'RUNNING',
                  setUserKwargs,
                  setToSave,
                  setShowGameParameters,
                  'basic'
                )}
                limit={10}
                offset={offset}
                setOffset={setOffset}
                total={games.length}
                rowClassNames={['sm:grid', 'sm:grid-cols-10', 'sm:gap-3', '!cursor-default']}
              />
              {showGameParameters ?
                <GameParametersModal
                  intl={intl}
                  group={session.cod_group.toLowerCase() as 'ref' | 'udc'}
                  game={games.filter(i => i.cod_game == showGameParameters)[0]}
                  distances={distances}
                  relocations={relocations}
                  userKwargs={userKwargs}
                  setUserKwargs={setUserKwargs}
                  setShowGameParameters={setShowGameParameters}
                  saveParameters={saveParameters}
                  setToSave={setToSave}
                />
                : null
              }
              {showGeneralParameters ?
                <GeneralParametersModal
                  group={session.cod_group.toLowerCase() as 'ref' | 'udc'}
                  games={games}
                  setShowGeneralParameters={setShowGeneralParameters}
                  userKwargs={userKwargs}
                  setUserKwargs={setUserKwargs}
                  saveParameters={saveParameters}
                  setToSave={setToSave}
                  basic
                />
                : null
              }
            </div>
            :
            [...new Array(5)].map((_, index) =>
              <Placeholder key={index} height="h-32" classNames="rounded-lg bg-am-600 my-3"/>
            )
          }
          {showUpdate ?
            <CreateUpdateBasicSessionModal
              group={session.cod_group}
              session={session}
              setSession={setSession}
              setShowCreateSession={setShowUpdate}
            />
            : null
          }
          {stopDelete ?
            <StopDeleteBasicSessionModal
              session={session}
              stopDelete={stopDelete}
              setStopDelete={setStopDelete}
              setSession={setSession}
            /> : null
          }
        </div>
        : <BasicSessionPlaceholder/>
      }
    </>
  )
}

export default BasicAlgorithmSession;