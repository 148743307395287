import React from "react";
import {FormatNumberOptions, IntlShape} from "react-intl";
import {api} from "@services/apiRequest";

// Model
import {
  DesignatorLevelType,
  EliteAlgorithmTaskSchema,
  AlgorithmRunStatus,
  AssignmentOptionSchema,
  SearchSelectAssignmentOptionSchema,
  UserKwargsSchema, SolutionByGame, JsnResult
} from "./Algorithms.types";
import {GamesSchema} from "@pages/Games/Games.type";
import {BanknotesIcon, CurrencyEuroIcon, ScaleIcon, TruckIcon} from "@heroicons/react/24/outline";
import {CounterGame} from "@pages/Nominations/Algorithms/Components/ConstraintsAssignmentCard";
import {START_SEASON} from "@utils/constants";
import {downloadExcel} from "@utils/downloadExcel";
import {DistancesDict} from "@services/types/city";
import {RelocationSchema} from "@pages/Planner/Calendar/Calendar.type";

const runEliteAlgorithmErrors: Record<number, string> = { // FIXME: get this error from backend X-Errors
  412: "EliteAlgorithmNotFoundError",
  409: "EliteAlgorithmTaskConflictError",
  406: "EliteAlgorithmMaxTaskError",
}

export default async function saveEliteAlgorithmConstraints(
  designator_level: DesignatorLevelType,
  des_round: string,
  userKwargs: UserKwargsSchema,
  push: Function,
) {
  try {
    await api.post('/algorithms/elite/constraints', {
      designator_level: designator_level,
      des_round: des_round,
      constraints: userKwargs,
    })
    push({type: 'success', title: 'Vincoli salvati con successo'})
  } catch (error: any) {
    push({
      type: 'error',
      title: 'Errore del Server durante il salvataggio dei vincoli',
    })
  }
}

export async function runEliteAlgorithm(
  designator_level: DesignatorLevelType,
  des_round: string,
  group: 'ref' | 'udc',
  push: Function,
  intl: IntlShape,
  setLoadingRunLaunch: React.Dispatch<React.SetStateAction<boolean>>,
  setFetchRunStatus: React.Dispatch<React.SetStateAction<boolean>>,
) {
  try {
    setLoadingRunLaunch(true);
    await api.post('/algorithms/elite', {
      designator_level: designator_level,
      des_round: des_round,
      group: group.toUpperCase()
    })

    push({
      type: 'success',
      title: 'Algoritmo Elite lanciato con successo',
    });

    setFetchRunStatus(true);
  } catch (error: any) {
    if (error.response.status in runEliteAlgorithmErrors) {
      push({
        type: 'error',
        title: intl.formatMessage({id: runEliteAlgorithmErrors[error.response.status]}),
      });
    } else {
      push({
        type: 'error',
        title: 'Errore generico al lancio dell\'algoritmo Elite',
      });
    }
  } finally {
    setLoadingRunLaunch(false);
  }
}

export async function fetchEliteAlgorithmStatus(
  designator_level: DesignatorLevelType,
  des_round: string,
  push: Function,
  intl: IntlShape,
  setFetchRunStatus: React.Dispatch<React.SetStateAction<boolean>>,
  pollingMode: boolean,
  setTaskData: React.Dispatch<React.SetStateAction<EliteAlgorithmTaskSchema | undefined>>
) {
  const params = new URLSearchParams();
  params.append('designator_level', designator_level as string);
  params.append('des_round', des_round);

  if (!pollingMode) {
    // fetch only once: called at first time, when EliteAlgorithmGames is rendered
    try {
      const {data} = await api.get<EliteAlgorithmTaskSchema>(`algorithms/elite?${params}`);
      if (data) {
        setTaskData(data)
      } else {
        setTaskData({
          designator_level: designator_level as string,
          des_round: des_round,
          cod_status: AlgorithmRunStatus.ACCEPTED
        })
      }

      // data could be undefined because task may not be on database already
      data ? setFetchRunStatus(data.cod_status === AlgorithmRunStatus.RUNNING) : setFetchRunStatus(false);
    } catch {
      push({
        type: "error",
        title: intl.formatMessage({id: "error_elite_algorithm_task_reading"})
      });
    }
  } else {
    // fetch in polling mode: called every 5 seconds, after an elite algorithm is launched
    const intervalID = setInterval(async () => {

      let taskState: EliteAlgorithmTaskSchema | undefined;

      try {
        const {data} = await api.get<EliteAlgorithmTaskSchema>(`algorithms/elite?${params}`);
        setTaskData(data);
        taskState = data;
      } catch {
        push({
          type: "error",
          title: intl.formatMessage({id: "error_elite_algorithm_task_reading"})
        });
      }

      if (taskState && taskState.cod_status !== AlgorithmRunStatus.RUNNING) {
        clearInterval(intervalID);
        setFetchRunStatus(false);
      }
    }, 5000);
  }
}

export function assignConstraintToGameRole(
  game: GamesSchema,
  role: string,
  userKwargs: UserKwargsSchema,
  setUserKwargs: React.Dispatch<UserKwargsSchema>,
  selectedMemberFipCode: number | undefined,
) {

  const constraintList = userKwargs.member_role_game.assign;
  let newConstraintList = constraintList.filter(i => i[1] !== role || i[2] !== game.cod_game);

  const updateAssignedForGame = userKwargs.member_game.assign.filter(i => i[1] === game.cod_game && i[0] !== selectedMemberFipCode);
  const updateForbiddenForGame = userKwargs.member_game.forbidden.filter(i => i[1] !== game.cod_game || i[0] !== selectedMemberFipCode);

  if (selectedMemberFipCode) {
    newConstraintList.push([selectedMemberFipCode, role, game.cod_game])
  }
  setUserKwargs({
    ...userKwargs,
    member_game: {
      assign: updateAssignedForGame,
      forbidden: updateForbiddenForGame
    },
    member_role_game: {
      ...userKwargs.member_role_game,
      assign: newConstraintList
    }
  });
}

export function assignConstraintToGame(
  game: GamesSchema,
  userKwargs: UserKwargsSchema,
  setUserKwargs: React.Dispatch<UserKwargsSchema>,
  selectedMembers: number[],
  type: 'assign' | 'forbidden'
) {

  const constraintList = userKwargs.member_game[type];
  let newConstraintList = constraintList.filter(i => i[1] !== game.cod_game)
    .concat(selectedMembers.map(i => [i, game.cod_game]))

  setUserKwargs({...userKwargs, member_game: {...userKwargs.member_game, [type]: newConstraintList}})

}


function _compareReserveAndOtherGamesOptions(a: AssignmentOptionSchema, b: AssignmentOptionSchema) {
  // "Reserves" will go last, "Assigned to Other Games" will go in the middle, "Common" options will go first
  if (a.flg_reserve && !b.flg_reserve) return 1;
  else if (!a.flg_reserve && b.flg_reserve) return -1;
  else if (a.other_games && !b.other_games) return 1;
  else if (!a.other_games && b.other_games) return -1;
  else return 0; // If none of the conditions match, return 0 to not change the "common" order
}

export function getGameAssignmentOptions(
  options: AssignmentOptionSchema[],
  memberRoleGameAssigned: any[],
  rolesToMarch: string[],
  relocations?: RelocationSchema,
  distances?: DistancesDict,
  gameCityId?: number,
  datGame?: string
): SearchSelectAssignmentOptionSchema[] {
  if (!options.length) {
    return [{label: '', value: 0, isDisabled: true}];
  } else {

    // Step 1: filter assignment options by role
    let optionsFiltered = options.filter(opt =>
      opt.admitted_roles.filter(i => rolesToMarch.includes(i)).length > 0);

    // Step 2: filter assignment options if the member is already assigned to another game in this round
    optionsFiltered = optionsFiltered.map(opt => ({
      ...opt,
      isDisabled: memberRoleGameAssigned.map(i => i[0]).includes(opt.id_member) || (opt.other_games ?? []).length > 0
    }));

    if (distances && relocations && datGame) {
      optionsFiltered = optionsFiltered.sort((a, b) => {
        // I want order by distance
        const gameCity = gameCityId ?? 0

        const memberCityA = !relocations[a.id_member] || relocations[a.id_member].original_days.includes(datGame)
          ? a.member.id_city
          : relocations[a.id_member].cities.filter(i => i.days.includes(datGame))[0].id
        let kmA = 999
        const cityAA = gameCity > memberCityA ? memberCityA : gameCity
        const cityAB = gameCity > memberCityA ? gameCity : memberCityA
        const _kmA = ((distances[cityAA] || {})[cityAB] || {}).km
        if (_kmA !== undefined) {
          kmA = _kmA
        }

        const memberCityB = !relocations[b.id_member] || relocations[b.id_member].original_days.includes(datGame)
          ? b.member.id_city
          : relocations[b.id_member].cities.filter(i => i.days.includes(datGame))[0].id
        let kmB = 999
        const cityBA = gameCity > memberCityB ? memberCityB : gameCity
        const cityBB = gameCity > memberCityB ? gameCity : memberCityB
        const _kmB = ((distances[cityBA] || {})[cityBB] || {}).km
        if (_kmB !== undefined) {
          kmB = _kmB
        }

        return _compareReserveAndOtherGamesOptions(a, b) || kmA - kmB

      });
    } else {
      optionsFiltered = optionsFiltered.sort((a, b) => {
        // I want first wo officiated less the two teams
        const teamCounterA = a.num_nominations_a + a.num_nominations_b
        const teamCounterB = b.num_nominations_a + b.num_nominations_b
        // Given the same number of past games, prefer the bigger delta in days with the last game
        const today = new Date()
        const dateAA = new Date(a.dat_last_game_a ?? START_SEASON)
        const daysAA = today.getTime() - dateAA.getTime()
        const dateAB = new Date(a.dat_last_game_b ?? START_SEASON)
        const daysAB = today.getTime() - dateAB.getTime()
        const daysA = Math.min(daysAA, daysAB)
        const dateBA = new Date(b.dat_last_game_a ?? START_SEASON)
        const daysBA = today.getTime() - dateBA.getTime()
        const dateBB = new Date(b.dat_last_game_b ?? START_SEASON)
        const daysBB = today.getTime() - dateBB.getTime()
        const daysB = Math.min(daysBA, daysBB)

        return _compareReserveAndOtherGamesOptions(a, b) || teamCounterA - teamCounterB || daysB - daysA
      });
    }

    // Step 3: return options in SearchSelectAssignmentOptionSchema[] format
    return [{label: '', value: 0, isDisabled: true}].concat(optionsFiltered.map(opt => ({
      value: opt.member.id_fip_code,
      label: opt.member.tag,
      isDisabled: opt.isDisabled,
      option: opt,
    })))
  }
}

export const getInitUserKwargs = (inputData: any): UserKwargsSchema => ({
  flg_assign_all_games: inputData?.flg_assign_all_games ?? false,
  flg_forbidden_same_city_team_a: inputData?.flg_forbidden_same_city_team_a ?? false,
  flg_forbidden_same_city_team_b: inputData?.flg_forbidden_same_city_team_b ?? false,
  member_couples: {
    assign: inputData?.member_couples?.assign ?? [],
    forbidden: inputData?.member_couples?.forbidden ?? [],
  },
  member_role: {
    assign: inputData?.member_role?.assign ?? [],
    forbidden: inputData?.member_role?.forbidden ?? [],
  },
  member_role_game: {
    assign: inputData?.member_role_game?.assign ?? [],
    forbidden: inputData?.member_role_game?.forbidden ?? [],
  },
  member_game: {
    assign: inputData?.member_game?.assign ?? [],
    forbidden: inputData?.member_game?.forbidden ?? [],
  },
  member: {
    assign: inputData?.member?.assign ?? [],
    forbidden: inputData?.member?.forbidden ?? [],
  },
});


const currencyStyle: FormatNumberOptions = {maximumFractionDigits: 0, style: "currency", currency: "EUR"}

export const CostKpi = ({intl, gameKpi}: {
  intl: IntlShape,
  gameKpi: SolutionByGame | undefined
}) => {
  if (gameKpi) {
    return (
      <div className="flex flex-row sm:flex-col items-center gap-2 sm:gap-1">
        <div className="text-sm font-bold">
          <CurrencyEuroIcon className="w-4 sm:w-5 inline-block mr-1"/>
          {intl.formatNumber(gameKpi.fee + gameKpi.travel_costs + gameKpi.extra_fee, currencyStyle)}
        </div>
        <div className="text-xxs">
          <BanknotesIcon className="w-3 sm:w-4 inline-block mr-0.5"/>
          {intl.formatNumber(gameKpi.fee + gameKpi.extra_fee, currencyStyle)}
        </div>
        <div className="text-xxs">
          <TruckIcon className="w-3 sm:w-4 inline-block mr-0.5"/>
          {intl.formatNumber(gameKpi.travel_costs, currencyStyle)}
        </div>
      </div>
    )
  }
  return null
}


export const CostTotalKpi = ({intl, games}: {
  intl: IntlShape,
  games: { [_: string]: SolutionByGame } | undefined
}) => {
  if (games) {
    const gameTax = Object.values(games).reduce((acc, i) => acc + i.game_tax * 2, 0)
    const fee = Object.values(games).reduce((acc, i) => acc + i.fee + i.extra_fee, 0)
    const travel = Object.values(games).reduce((acc, i) => acc + i.travel_costs, 0)
    return (
      <div className="flex flex-row items-center gap-3">
        <div className="text-sm font-bold mr-5 text-am-700">
          <ScaleIcon className="w-4 sm:w-5 inline-block mr-1"/>
          {intl.formatNumber(gameTax, currencyStyle)}
        </div>
        <div className="text-sm font-bold">
          <CurrencyEuroIcon className="w-4 sm:w-5 inline-block mr-1"/>
          {intl.formatNumber(fee + travel, currencyStyle)} ({intl.formatNumber((fee + travel) / gameTax, {
          style: 'percent',
          maximumFractionDigits: 1
        })})
        </div>
        <div className="text-xs">
          <BanknotesIcon className="w-3 sm:w-4 inline-block mr-0.5"/>
          {intl.formatNumber(fee, currencyStyle)}
        </div>
        <div className="text-xs">
          <TruckIcon className="w-3 sm:w-4 inline-block mr-0.5"/>
          {intl.formatNumber(travel, currencyStyle)}
        </div>
      </div>
    )
  }
  return null
}


export const getOptionWithTeamCounters = (
  intl: IntlShape,
  props: { data?: SearchSelectAssignmentOptionSchema; innerProps?: any; innerRef?: any; },
  distances: DistancesDict,
  relocations: RelocationSchema,
  gameCityId: number,
  datGame: string
) => {
  const {innerProps, innerRef} = props;
  const otherGame = props.data?.option?.other_games;
  const isReserve: boolean = props.data?.option?.flg_reserve ?? false;

  if (!props.data?.option) {
    return <></>;
  }
  const idMember = props.data.option.id_member
  const city = !relocations[idMember] || relocations[idMember].original_days.includes(datGame)
    ? props.data.option.member.city
    : relocations[idMember].cities.filter(i => i.days.includes(datGame))[0]
  let km: string | number = '-'
  if (gameCityId && distances) {
    const memberCity = Number(city.id)
    const cityA = gameCityId > memberCity ? memberCity : gameCityId
    const cityB = gameCityId > memberCity ? gameCityId : memberCity
    const _km = ((distances[cityA] || {})[cityB] || {}).km
    if (_km !== undefined) {
      km = _km
    }
  }

  return (
    <article
       ref={innerRef}
       {...innerProps}
       className={
          `px-3 pt-1 text-sm 
          ${props.data.isDisabled ? "text-slate-400" : "cursor-pointer hover:bg-blue-100"}
          ${otherGame ? "bg-red-200" : ""}
          ${isReserve ? "bg-yellow-100" : ""}
          `
       }
    >
      <strong>{props.data.option.member.member}</strong>
      {distances ?
        <div className="text-xs text-gray-700">
          di {city.city} ({city.cod_province})
          <span className="mx-1.5">&#8226;</span>
          {km} km
        </div> : null
      }
      <div className="flex flex-col gap-y-2">
        <div className='mt-1 flex flex-row justify-between gap-2'>
          <CounterGame option={props.data.option} team='a' intl={intl}/>
          <span>-</span>
          <CounterGame option={props.data.option} team='b' intl={intl}/>
        </div>

        {otherGame ?
          <div className="italic text-xs -mt-1 mb-1 text-gray-600">
            Gara di <strong>{otherGame[0].cod_level}</strong> a <strong>{otherGame[0].city} ({otherGame[0].cod_province})</strong>, {otherGame[0].time_game}
          </div> :
          <></>
        }
      </div>
    </article>
  )
}

export const algoDownloadExcel = (data: GamesSchema[], group: 'ref' | 'udc', output: JsnResult | undefined, intl: IntlShape, title: string) => {

  const content = data.map((game) => {
    let row = {
      game_number: game.game_number,
      dat_game: intl.formatDate(game.dat_game, {day: '2-digit', month: '2-digit', year: 'numeric'}),
      time_game: game.time_game,
      cod_level: game.cod_level,
      team_a: game.team_a.team_alias,
      team_b: game.team_b.team_alias,
      city: game.city?.city ?? '',
      cod_province: game.city?.cod_province ?? '',
      ref1: '',
      ref2: '',
      sp: '',
      crono: '',
      a24s: '',
    }
    if (group === 'ref') {
      row.ref1 = output?.solution_by_game[game.cod_game].ref1 ?? ''
      row.ref2 = output?.solution_by_game[game.cod_game].ref2 ?? ''
    } else {
      row.sp = output?.solution_by_game[game.cod_game].sp ?? ''
      row.crono = output?.solution_by_game[game.cod_game].crono ?? ''
      row.a24s = output?.solution_by_game[game.cod_game].a24s ?? ''
    }
    return row
  })

  let fields = [
    {field: 'game_number', title: 'Gara'},
    {field: 'dat_game', title: 'Data'},
    {field: 'time_game', title: 'Ora'},
    {field: 'cod_level', title: 'Camp'},
    {field: 'team_a', title: 'Squadra A'},
    {field: 'team_b', title: 'Squadra B'},
    {field: 'city', title: 'Città'},
    {field: 'cod_province', title: 'Provincia'},
  ]

  if (group === 'ref') {
    fields.push({field: 'ref1', title: '1 Arbitro'})
    fields.push({field: 'ref2', title: '2° Arbitro'})
  } else {
    fields.push({field: 'sp', title: 'Segnapunti'})
    fields.push({field: 'crono', title: 'Cronometrista'})
    fields.push({field: 'a24s', title: 'Addetto 24'})
  }

  downloadExcel(content, fields, title);
};