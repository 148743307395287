import React from "react";
import {LKP_GROUP} from "@utils/constants";
import {NominationSchema} from "@services/types/nomination";
import {Member} from "@services/types/member";

export const getDateOfISOWeek = (weekNumber: number, year: number) => {
  // given week number and year, return a Date object
  // with day as the first day of the week
  let simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  let dow = simple.getDay();
  let ISOweekStart = simple;
  if (dow <= 4)
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}


export function getMaxLevelFromNominations(nominations: NominationSchema[]) {
  if (nominations.length === 0) {
    return true
  }
  let tmpMaxLevel
  let tmpMaxLevelOrder = 100
  nominations.forEach(i => {
    if (i.game.level.level_order < tmpMaxLevelOrder) {
      tmpMaxLevelOrder = i.game.level.level_order
      tmpMaxLevel = i.game.level.des_level
    }
  })
  return tmpMaxLevel
}


export const getMemberOptions = (members: Member[], showGroup: boolean = true) =>   {
  return members ? members.map(i => ({
    label: <div className="flex flex-col">
      <span className="text-xs">
        {i.id_fip_code} {showGroup ? '- ' + LKP_GROUP[i.category.cod_group] : ''} - {i.category.des_sub_category}
      </span>
      <span className="font-bold">{i.member}</span>
    </div>,
    value: i.id_fip_code,
    data: i
  })) : []
}