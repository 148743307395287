import React, { useContext, useEffect, useState } from 'react';

// Services
import AuthenticationService from '@services/AuthenticationService';
import { NotificationsContext } from '@ui-components/Notifications';
import { api } from '@services/apiRequest';

// Hooks
import useRoles from '@ui-components/pages/Users/hooks/useRoles';
import useMembers from '@services/hooks/useMembers';

// Ui
import { PageHeading } from '@ui-components/Container';
import Button from '@ui-components/Button';
import Alert from '@ui-components/Alert';

// Components
import AddUpdateActivityDialog from './AddUpdateActivityDialog';
import { ActivityPlaceHolder, getListItems } from './Activities.lib';
import RefManList from '../../components/RefMan/RefManList/RefManList';

// Types
import { Activity } from '@services/types/activity';

export function Activities() {
  const roles = useRoles();
  const scopes = AuthenticationService.getUserScopes();
  const personalMode = scopes.includes('activities:personal');
  const userIdFipCode = personalMode ? AuthenticationService.getUserFipCode() : undefined;
  const membersByUserId = useMembers({
    onlyActive: true, assigned: true, asObj: false, byUserId: true, excludeManager: false });

  const { push } = useContext(NotificationsContext);
  const [activities, setActivities] = useState<Activity[]>();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const limit = 5;

  useEffect(() => {
    const fetchData = () => {
      api
        .get('/activities')
        .then(({ data: activities }) => {
          setActivities(activities);
        })
        .catch(() => {
          push({ title: 'Errore del server', type: 'error' });
        });
    };

    if (activities === undefined) {
      fetchData();
    }
  }, [activities]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageHeading
        title='Attività tecnica'
        historyBackPath='_back'
        contentActions={
          scopes.includes('activities:personal') ? null : (
            <Button onClick={() => setShowAdd(true)} disabled={!scopes.includes('activities:create')}>
              Aggiungi
            </Button>
          )
        }
      />
      {membersByUserId && activities ? (
        activities.length ? (
          <div className='my-5'>
            <RefManList
              offset={offset}
              setOffset={setOffset}
              limit={limit}
              total={activities.length}
              containerId='observer-reports-list'
              items={getListItems(activities, membersByUserId, roles, userIdFipCode)}
              rowClassNames={['sm:grid', 'sm:grid-cols-4', 'sm:gap-4']}
              pagination='frontend'
            />
          </div>
        ) : (
          <div className='my-10 sm:mx-10'>
            <Alert title='Nessuna attività tecnica in programma' />
          </div>
        )
      ) : (
        <ActivityPlaceHolder />
      )}
      {showAdd ? (
        <AddUpdateActivityDialog
          onExit={() => {
            setShowAdd(false);
          }}
          setActivities={setActivities}
        />
      ) : null}
    </>
  );
}
