import React, {useState, useRef, useContext} from "react";
import ReactCrop, {centerCrop, makeAspectCrop} from "react-image-crop";
import {useDebounceEffect} from "@services/hooks/useDebounceEffect";
import {NotificationsContext} from "@ui-components/Notifications";
import {canvasPreview} from "@utils/canvasPreview";
import "react-image-crop/dist/ReactCrop.css";

// UI
import Button from "@ui-components/Button";


const ImageCropper = ({
    originalImage,
    // mimeType,
    cropState,
    onCropChange,
    children,
    imageDesc,
    onConfirm=null,
    onCancel=null,
    aspectRatio=null,
    submitting=false
}) => {

  const { push } = useContext(NotificationsContext);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  // IMPORTANT: keep this variable as state instead even if it doesn't use the setter method. If you use a constant
  // instead, the browser will non-stop try to re-download the associated image causing an unnecessary network traffic!
  const [openingEpoch, setOpeningEpoch] = useState(new Date().getTime());  // eslint-disable-line no-unused-vars
  const [completedCrop, setCompletedCrop] = useState();

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop
        )
      }
    },
    100,
    [completedCrop],
  )

  const onCropImageLoad = (image) => {
    /*  If aspect ratio hasn't been set, we don't need to do anything on image load (the user will be cropping the
     *  image on their own); otherwise, we want to place a pre-built crop in the middle of the original image having
     *  the aforementioned aspect ratio
     */

    if (aspectRatio === null)
      return;

    const { naturalWidth: width, naturalHeight: height } = image;
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          [aspectRatio > 1 ? 'width' : 'height']: 100
        },
        aspectRatio,
        width,
        height
      ),
      width,
      height
    )

    onCropChange(crop);
  }

  // IMPORTANT: this is needed in order to avoid cors-related issues in webkit-based browsers (e.g. Chrome or Safari),
  // since they tend to cache cross-origin requests without including the Origin header.
  // More information about the issue can be found here: https://blog.keul.it/chrome-cors-issue-due-to-cache/
  const originalImageNoCache = `${originalImage}&tms=${openingEpoch}`;

  return <>
    {/* -------------------- Cropper -------------------- */}
    <ReactCrop
      crop={cropState}
      aspect={aspectRatio}
      onChange={(crop, percentageCrop) => {
        onCropChange(aspectRatio === null ? crop : percentageCrop);
      }}
      onComplete={(c) => {
        setCompletedCrop(c)
      }}
      className="w-full"
    >
      <img
        ref={imgRef}
        src={originalImageNoCache}
        alt={!!imageDesc ? imageDesc : 'No description'}
        crossOrigin="anonymous"
        onLoad={({ currentTarget }) => { onCropImageLoad(currentTarget); }}
        className="w-full h-auto"
      />
    </ReactCrop>
    {/* -------------------- Extra content (if any) -------------------- */}
    {
      children
    }
    {/* -------------------- Controls -------------------- */}
    {
      onConfirm && onCancel && (
        <div className="flex gap-2 mt-1">
          {
            onCancel && (
              <div className="flex-grow">
                <Button full styleType="empty" onClick={onCancel}>
                  Cancella
                </Button>
              </div>
            )
          }
          {
            onConfirm && (
              <div className="flex-grow">
                <Button
                  full
                  onClick={() => {
                    previewCanvasRef.current.toBlob((blob) => {
                      if (!blob) {
                        push({type: 'error', title: 'Errore imprevisto'});
                        return;
                      }
                      // const file = new File([blob], "cropped-image", {type: mimeType});
                      const file = new File([blob], "cropped-image");
                      onConfirm(file);
                    });
                  }}
                  submitting={submitting}
                >
                  Conferma
                </Button>
              </div>
            )
          }
        </div>
      )
    }
    {/* -------------------- Hidden canvas -------------------- */}
    <canvas
      ref={previewCanvasRef}
      className="hidden"
      style={{objectFit: 'contain', width: completedCrop?.width, height: completedCrop?.height}}
    />
  </>
}

export default ImageCropper;