import React from "react";
import {useIntl} from "react-intl";
import {PencilIcon, TrashIcon} from "@heroicons/react/24/outline";
import {getCardLabels, timeStampFormat} from "../Absences.lib";
import {AbsenceCardPropsType} from "./AbsenceCard.type";


export function AbsenceCard({
  absence,
  isMember,
  currentUserId,
  membersByUserId,
  setUpdateAbsence,
  setDeleteAbsence,
  roles
}: AbsenceCardPropsType) {

  const intl = useIntl();
  const {reason, timeList, period} = getCardLabels(absence.absence_reason, intl)
  const addedByOther = currentUserId !== absence.id_added_user
  const editable = !isMember || !addedByOther

  let insertedBy = ''
  if (addedByOther && roles) {
    insertedBy = `da ${membersByUserId[absence.id_added_user]} (${roles[absence.id_added_role]['description']})`
  }
  const metadata = `Aggiornata il ${intl.formatDate(absence.tms_update, timeStampFormat)} ${insertedBy}`

  const editStyle = 'text-am-700 cursor-pointer hover:font-bold'

  return (
    <div key={absence.id_absence_reason} className="p-3 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium sm:col-span-3 text-gray-900 flex flex-col">
        <span>{reason}{timeList.filter(i => i).length ? `, ${timeList.join(' ')}` : ''}</span>
        <span className="font-bold">{period.join(' ')}</span>
      </dt>
      <dd className="mt-1 text-sm italic text-gray-700 sm:col-span-2 sm:mt-0 flex flex-col justify-center">
        {absence.absence_reason.des_absence}
      </dd>
      <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 mt-3 sm:mt-0 flex flex-col sm:justify-between sm:items-end">
        <span className="text-xxs sm:text-end leading-3">{metadata}</span>
        <span className="font-medium mt-2 flex flex-row divide-x divide-gray-200">
          <span
            className={`pr-2 ${editable ? editStyle : 'text-gray-300' }`}
            onClick={editable ? () => setUpdateAbsence(absence) : undefined}
          >
            <PencilIcon className="h-3 w-3 inline-block -mt-0.5"/> Aggiorna
          </span>
          <span
            className={`pl-2 ${editable ? editStyle : 'text-gray-300'} `}
            onClick={editable ? () => setDeleteAbsence(absence) : undefined}
          >
            <TrashIcon className="h-3 w-3 inline-block -mt-0.5"/> Elimina
          </span>
        </span>
      </dd>
    </div>
  )
}
