import React, {useContext, useEffect, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";

// Ui
import SearchSelect from "@ui-components/SearchSelect";
import Placeholder from "@ui-components/Placeholder";
import Input from "@ui-components/Input";


import {QuestionListPaginatedDetailResponseType} from "@components/RefMan/Quiz/types";
import {QuestionList} from "@components/RefMan/Quiz/Question/QuestionList";
import {useTagsFetch} from "@components/RefMan/Quiz/hooks/useTagsFetch";
import {useQuestion} from "@components/RefMan/Quiz/hooks/useQuestion";
import {getTagOption} from "@components/RefMan/Quiz/utils/tag";
import Button from "@ui-components/Button";
import {CreateOrUpdateQuestionModal} from "@components/RefMan/Quiz/Question/QuizAction";

interface SelectQuestionFromListFilters {
  desQuestion: string | undefined,
  tagList: {
    value: string,
    label: string
  }[] | undefined,
}

type SelectableQuestion = {
  selectable: true;
  selectedQuestions: number[];
  setSelectedQuestions: React.Dispatch<React.SetStateAction<number[]>>
}

type SelectQuestionProps = {
  showFilter: boolean
  editable: boolean
} & (SelectableQuestion | { selectable: false })

export function QuestionListFrame(props: SelectQuestionProps) {

  const {push} = useContext(NotificationsContext);

  // state
  const [filters, setFilters] = useState<SelectQuestionFromListFilters>({
    desQuestion: undefined,
    tagList: undefined,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [questionList, setQuestionList] = useState<QuestionListPaginatedDetailResponseType>({total: 0, items: []});
  const [showAddQuestionModal, setShowAddQuestionModal] = useState<boolean>(false);
  const [refreshData, setRefreshData] = React.useState<boolean>(true);

  // hooks
  const {data} = useTagsFetch();
  const {getAll} = useQuestion();

  // Todo: usa refman list
  useEffect(() => {
    setLoading(true);
    getAll({
      ordering: null,
      limit: 10,
      offset: 0,
      tags: filters.tagList ? filters.tagList.map((e: {
        value: string,
        label: string
      }) => e.value) : [],
      des_question: filters.desQuestion,
    })
      .then((res: QuestionListPaginatedDetailResponseType) => setQuestionList(res))
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }, [filters, refreshData]);

  return (
    <>
      {props.showFilter &&
        <div className='mt-4 flex flex-col sm:flex-row justify-between gap-x-3'>
          <div className="w-full sm:w-2/3">
            <Input
              name={'QuestionDescriptionFilter'}
              required={false}
              validation={undefined}
              errorMessage={undefined}
              showCalendar={undefined}
              onClear={undefined}
              value={filters.desQuestion}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFilters({...filters, desQuestion: e.target.value});
              }}
              label="Testo della domanda"
            />
          </div>
          <div className="w-full sm:w-1/3">
            <SearchSelect
              label="Tag"
              minHeight={30}
              value={filters.tagList}
              onChange={(e: {
                value: string,
                label: string
              }[]) => setFilters({...filters, tagList: e})}
              options={getTagOption(data ? data : [])}
              loadOptions={null}
              isMulti={true}
              CustomOption={null}
            />
          </div>
          <div className='flex justify-center items-center'>
            <Button onClick={() => setShowAddQuestionModal(true)}>
              Aggiungi
            </Button>
          </div>
        </div>
      }
      {loading ?
        <Placeholder height='h-96'/> :
        <QuestionList
          questionList={questionList}
          setSelectedQuestions={props.selectable ? props.setSelectedQuestions : undefined}
          selectedQuestions={props.selectable ? props.selectedQuestions : undefined}
          editable={props.editable}
        />
      }
      {showAddQuestionModal &&
        <CreateOrUpdateQuestionModal
          edit={false}
          onExit={() => setShowAddQuestionModal(false)}
          setRefreshData={setRefreshData}
        />
      }
    </>
  );
}
