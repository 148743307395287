import React, {useContext, useState} from 'react';

// Components
import Button from '@ui-components/Button';
import Alert from "@ui-components/Alert";
import Modal from "@ui-components/Modal";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {useHistory} from "react-router-dom";
import {api} from '@services/apiRequest';

// Types
import {BasicAlgorithmTaskSchema, DeleteSessionModalProps} from "@pages/Nominations/Algorithms/Algorithms.types";


function StopDeleteBasicSessionModal(
  {
    session,
    stopDelete,
    setStopDelete,
    setSession
  }: DeleteSessionModalProps) {

  const history = useHistory();
  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);


  const deleteSession = () => {
    setLoading(true)
    if (stopDelete === 'stop') {
      api.get(`/algorithms/basic/stop_algorithm/${session.id}`)
        .then(({data}: {data: BasicAlgorithmTaskSchema}) => {
          setStopDelete(undefined)
          if (setSession) {
            setSession(data)
          }
        })
        .catch(() => push({type: "error", title: "Errore durante il caricamento"}))
        .finally(() => setLoading(false))
    } else {
      api.delete(`/algorithms/basic/${session.id}`)
        .then(() => {
          setStopDelete(undefined)
          history.push(`/nominations/algorithms/basic`)
        })
        .catch(() => push({type: "error", title: "Errore durante il caricamento"}))
        .finally(() => setLoading(false))
    }
  }


  return (
    <Modal onExit={() => setStopDelete(undefined)}>
      {stopDelete === 'delete' && session.cod_status === 'RUNNING' ?
        <Alert
          title="Impossibile eliminare la sessione"
          text="L'algoritmo è in corso, prima è necessario stopparlo"
          type='error'
        />
        : <div>
          <h1 className="font-bold mb-5">Sei sicuro di voler {stopDelete === 'stop' ? 'stoppare' : 'eliminare'} la
            sessione?</h1>
          <Alert title="Non sarà possibile annullare l'operazione"/>
        </div>
      }
      <div className="mt-5 flex flex-row justify-between">
        <Button styleType="white" onClick={() => setStopDelete(undefined)}>
          Annulla
        </Button>
        <Button
          disabled={stopDelete === 'delete' && session.cod_status === 'RUNNING'}
          submitting={loading}
          onClick={deleteSession}
        >
          Conferma
        </Button>
      </div>
    </Modal>
  );
}

export default StopDeleteBasicSessionModal;
