import React, {Fragment, useEffect, useState} from "react";
import {useIntl, FormattedMessage} from "react-intl";

// Services
import AuthenticationService from "../../../services/AuthenticationService";
import { api } from "@services/apiRequest";
import {Redirect} from "react-router";

import AuthHeader from "../../layout/AuthHeader";
import PoweredBy from "../../layout/PoweredBy";
import Button from "../../Button";
import Input from "../../Input";
import Alert from "../../Alert";
import Modal from "../../Modal";

function Login(props) {
  const intl = useIntl();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [error, setError] = useState("");
  const [errorForgot, setErrorForgot] = useState("");
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const setOnError = (msg) => setError(msg);
  const setOffError = () => setError("");
  const setOnErrorForgot = (msg) => setErrorForgot(msg);
  const setOffErrorForgot = () => setErrorForgot("");

  const [toPath, ] = useState(
    props.location.state &&
    props.location.state.toPath &&
    props.location.state.toPath !== '/' ? props.location.state.toPath : "/home"
  )

  useEffect(() => {

    if (window.location.href.includes("unauthorized_user")) {
      setOnError(intl.formatMessage({id: "access_denied"}));
    }
  }, [intl]);

  function toggleForgot() {
    setEmailSent(false);
    setOpenForgot(!openForgot);
  }

  async function recoverPassword(email) {
    try {
      await api.post(
        "/users/password/forgot",
        {email},
        {publicRequest: true}
      );
      setOffErrorForgot();
      setEmailSent(true);
    } catch (error) {
      console.log(error)
      setOnErrorForgot(intl.formatMessage({id: "server_error"}));
    }
  }

  const handleRecoverPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    await recoverPassword(email);
    setLoading(false);
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      if (!username || !password) {
        return;
      }
      setLoading(true);
      setOffError();
      await AuthenticationService.login(username.trim(), password, toPath);
    } catch (error) {
      console.log(error)
      setOnError(intl.formatMessage({id: "wrong_email_or_password"}));
      setPassword("");
      setLoading(false);
    }
  };

  if (AuthenticationService.isUserAuthenticated()) {
    return <Redirect to={toPath} />;
  }

  return (
    <Fragment>
      <div className="bg-am-400 min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <AuthHeader
          title={intl.formatMessage({id: "log_into_your_account"})}
        />
        <div className="mt-8 mx-auto w-full max-w-md">
          <div className="bg-white py-8 px-10 shadow rounded-3xl">
            <form action="#" method="POST" onSubmit={handleLogin}>
              <div>
                <Input
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  label="Email"
                  type="email"
                  required
                  errorMessage={intl.formatMessage({id: "enter_email"})}
                />
              </div>

              <div>
                <Input
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  required
                />
              </div>

              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-am-600 focus:ring-am-500 border-gray-300 rounded"
                    onChange={(e) => {
                      setShowPassword(e.target.checked);
                    }}
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    <FormattedMessage id="show_password"/>
                  </label>
                </div>
                <div className="text-sm">
                  <p
                    onClick={toggleForgot}
                    className="font-medium text-am-600 hover:text-am-500 cursor-pointer"
                  >
                    <FormattedMessage id="forgot_password"/>
                  </p>
                </div>
              </div>

              <div>
                <Button full type="submit" submitting={loading}>
                  Sign in
                </Button>
              </div>
              {error ? (
                <div className="mt-4">
                  <Alert slim type="error" title={error}/>
                </div>
              ) : null}
            </form>
          </div>
          <PoweredBy/>
        </div>

      </div>
      <Modal opened={openForgot} onExit={() => toggleForgot()}>
        <div className="w-full flex-col flex items-center my-6">
          <h3 className=" text-center text-2xl font-extrabold text-gray-900">
            <FormattedMessage id="restore_password"/>
          </h3>
          <p className="mt-2 text-center text-sm text-gray-600 mb-4 font-medium">
            <FormattedMessage id="restore_password"/>
          </p>
          <form
            action="#"
            method="POST"
            className="w-full max-w-sm"
            onSubmit={handleRecoverPassword}
          >
            <div className="my-4">
              <Input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                label="Email"
                type="email"
                required
              />
            </div>
            <div>
              <Button full type="submit" submitting={loading}>
                <FormattedMessage id="submit_password"/>
              </Button>
            </div>
            {errorForgot ? (
              <div className="mt-4">
                <Alert slim type="error" title={errorForgot}/>
              </div>
            ) : null}
            {emailSent ? (
              <div className="mt-4">
                <Alert
                  slim
                  type="success"
                  title={`${intl.formatMessage({id: "forgot_password_msg"})}`}
                />
              </div>
            ) : null}
          </form>
        </div>
      </Modal>
    </Fragment>
  );
}

export default Login;
