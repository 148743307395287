import React from "react";
import Badge from "@ui-components/Badge";
import UpdateRoles from "./UpdateRoles";
// import SelectRole from "./SelectRole";

export const getColumns = (intl, allRoles, lkpMembers) => [
  {
    title: "E-mail",
    field: "email",
  },
  {
    title: intl.formatMessage({id: "name"}),
    field: "full_name",
    render: ({full_name}) => <b>{full_name}</b>,
  },
  {
    title: intl.formatMessage({id: "role"}),
    field: "roles",
    cellStyle: {minWidth: 250},
    headerStyle: {minWidth: 250},
    render: ({roles}) =>
      roles ? (
        <div>
          {roles.split(";").map((s) => (
            <Badge classNames="mb-1 mr-1" text={allRoles[s]['description']} key={s}/>
          ))}
        </div>
      ) : ("-"),
    editComponent: (props) => <UpdateRoles allRoles={allRoles} {...props} />
  },
  {
    title: "Tesserato",
    editable: "never",
    render: ({id}) => <b>{lkpMembers[id] || '-'}</b>
  },
  {
    title: intl.formatMessage({id: "disabled"}),
    field: "disabled",
    type: "boolean",
  },
];

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: false,
  toolbar: false,
};

export const getLocalization = (intl) => ({
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: intl.formatMessage({id: "delete_user"}),
    },
  },
  pagination: {
    labelDisplayedRows: intl.formatMessage({id: "users_displayed_rows"}),
    labelRowsSelect: intl.formatMessage({id: "users"}),
  },
});
