import React from "react";
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";

import {HashtagIcon} from "@heroicons/react/24/outline";
import {getColor, getActionNoteBlock, getColleagues, dateFormat} from "./NominationCard.lib";
import {NominationCardPropsType} from "@components/RefMan/NominationCard/NominationCard.type";


export function NominationCard({nom, idFipCode, isPersonal, fromHome}: NominationCardPropsType) {
  const intl = useIntl();
  const history = useHistory();
  return (
    <div key={nom.cod_game} className={`py-2 grid grid-cols-1 sm:grid-cols-7 xl:grid-cols-11 items-center ${getColor(nom.status)}`}>
      <div className="w-full p-1 flex flex-row xl:flex-col gap-2 xl:gap-0 items-center xl:items-start sm:col-span-2 xl:col-span-1">
        <span className="text-xl font-bold">{nom.game.level.des_level}</span>
        <span className="text-xs font-light xl:text-sm xl:-mt-1 whitespace-nowrap">
          <HashtagIcon className="inline-block w-3 h-3 -mt-0.5"/>
          {nom.game.game_number}
        </span>
        <div className="w-full p-1 flex sm:hidden flex-row gap-2 justify-end">
          <span className="text-sm font-bold capitalize">
            {intl.formatDate(nom.game.dat_game, dateFormat)}
          </span>
          <span className="text-sm font-light">
            {nom.game.time_game}
          </span>
        </div>
      </div>
      <div className="w-full p-1 flex flex-col gap-1 items-start sm:col-span-3 xl:col-span-4">
        <span className="text-xxs sm:text-xs truncate overflow-hidden w-full">
          {nom.game.team_a.team_alias} <i>vs</i> {nom.game.team_b.team_alias}</span>
        {nom.game.city ?
          <span className="text-xs sm:text-sm truncate overflow-hidden w-full">
            {nom.game.place}, <b>{nom.game.city.city} ({nom.game.city.cod_province})</b>
          </span>
          : null}
      </div>
      <div className="w-full p-1 hidden sm:flex sm:col-span-2 xl:hidden">
        {getActionNoteBlock(nom, intl, history, isPersonal)}
      </div>
      <div className="w-full p-1 hidden sm:flex flex-row xl:flex-col gap-2 xl:gap-0 items-start sm:col-span-2">
        <span className="text-sm xl:text-normal font-bold capitalize">
          {intl.formatDate(nom.game.dat_game, dateFormat)}
        </span>
        <span className="text-sm font-light">
          {nom.game.time_game}
        </span>
      </div>
      <div className="w-full flex flex-row xl:flex-col gap-1 items-center sm:col-span-3 xl:col-span-2 xl:items-start">
        {getColleagues(nom, idFipCode)}
      </div>
      <div className="w-full p-1 flex sm:hidden xl:flex text-xs xl:col-span-2">
        {getActionNoteBlock(nom, intl, history, isPersonal, fromHome)}
      </div>
    </div>
  )
}
