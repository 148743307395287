import React, {useState} from "react";

import RefManList from "@components/RefMan/RefManList/RefManList";
import Placeholder from "../../../ui-components/Placeholder";
import Alert from "@ui-components/Alert";
import {useIntl} from "react-intl";
import {timeStampFormat} from "@pages/Planner/Absences/Absences.lib";
import {BellAlertIcon, BellIcon, ExclamationTriangleIcon, LightBulbIcon, PencilIcon} from "@heroicons/react/24/outline";

const lkpStatus = {
  OPEN: <span className="font-bold border rounded-2xl px-3 py-1 bg-orange-200 bg-opacity-30">Aperto</span>,
  WIP: <span className="font-bold border rounded-2xl px-3 py-1 bg-yellow-200 bg-opacity-30">In lavorazione</span>,
  STUCK: <span className="font-bold border rounded-2xl px-3 py-1 bg-red-200 bg-opacity-30">Sospeso</span>,
  CLOSED: <span className="font-bold border rounded-2xl px-3 py-1 bg-green-200 bg-opacity-30">Risolto</span>
}

const lkpPriority = {
  LOW: <span className="font-bold"><BellIcon className="inline-block h-5 w-5 text-am-800 -mt-1"/> Priorità bassa</span>,
  MEDIUM: <span className="font-bold"><BellAlertIcon className="inline-block h-5 w-5 text-yellow-600 -mt-1"/> Priorità media</span>,
  HIGH: <span className="font-bold"><ExclamationTriangleIcon className="inline-block h-5 w-5 -mt-1 text-red-800"/> Priorità alta</span>,
  PROPOSAL: <span className="font-bold"><LightBulbIcon className="inline-block h-5 w-5 text-green-800 -mt-1"/> Proposta</span>
}

const lkpCategory = {
  SERVICE_NOT_AVAILABLE: "Dati non disponibili",
  UNEXPECTED_RESULTS: "Dati errati",
  SYSTEM_BREAK: "Sistema in blocco",
  OTHER: "Categoria generica"
}

const getListItems = (data, intl, isAdmin, setShowEditTicket) => {

  if (!data.length) {
    return []
  }

  return data.map((ticket) => {
    return ({
      rowId: `ticket-${ticket.id}`,
      rowElement: <>
        <dt className="flex flex-col">
          <span className="text-xs">{`Creato il ${intl.formatDate(ticket.tms_creation, timeStampFormat)}`}</span>
          {isAdmin ?<span className="text-xs">{`da ${ticket.user.full_name}`}</span> : null}
        </dt>
        <dt>
          {lkpStatus[ticket.status]}
        </dt>
        <dt>
          {lkpPriority[ticket.priority]}
        </dt>
        <dt>
          <div className="flex flex-row justify-between">
          <span className="text-sm italic text-gray-700">
            {lkpCategory[ticket.category]}
          </span>
            {isAdmin ? <PencilIcon className="cursor-pointer w-5 h-5" onClick={() => setShowEditTicket(ticket)} /> : null}
          </div>
        </dt>
        <dt className="flex flex-col w-full mt-1 sm:col-span-4 gap-1">
          <span className="text-sm font-bold truncate">{ticket.subject}</span>
          <span className="text-xs break-normal">{ticket.content}</span>
          {ticket.comment ? <span className="text-sm font-bold mt-5">Commento da RefMan</span> : null}
          {ticket.comment ? <span className="text-xs">{ticket.comment}</span> : null}
        </dt>
      </>
    })
  })
}

function TicketList({ticketList, isAdmin, setShowEditTicket}) {
  const [offset, setOffset] = useState(0);
  const intl = useIntl();
  return (
    <div>
      {ticketList ?
        <div className="py-5">
          {(ticketList || []).length ?
            <RefManList
              offset={offset}
              setOffset={setOffset}
              limit={5}
              containerId="ticket-list"
              items={getListItems(ticketList || [], intl, isAdmin, setShowEditTicket)}
              rowClassNames={[
                "sm:grid",
                "sm:grid-cols-4",
                "sm:gap-2",
                "!cursor-default"
              ]}
              total={ticketList? ticketList.length : 0}
              pagination="frontend"
            />
            :
            <div><Alert title="Non ci sono tuoi ticket aperti"/></div>}
        </div>
        : <div className="flex flex-col w-full mb-4 gap-2">
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
          <Placeholder height="h-10" classNames="rounded-lg"/>
        </div>
      }
    </div>
  );
}

export default TicketList;
