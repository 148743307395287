import React from "react";
import {UserPlusIcon} from "@heroicons/react/24/outline";
import {MemberPic} from "@pages/Members/Members.lib";
import Alert from "@ui-components/Alert";


import {InstructorsProps} from "@pages/Activities/Detail/Detail.type";


export function Instructors({activity, setShowInstructors, viewMode, canCreate}: InstructorsProps) {

  const instructors = activity.members_activities.filter(i => i.activity_role === 'TEACHER')

  return (
    <div className="flex flex-col mt-5 sm:mt-8 py-2">
      <div className="flex flex-row justify-between border-b border-gray-800">
        <h1 className="text-lg font-bold">Istruttori {instructors.length ? `(${instructors.length})` : ''}</h1>
        {viewMode || !canCreate ? null :
          <span className="text-am-700 cursor-pointer hover:font-bold" onClick={() => setShowInstructors(true)}>
            <UserPlusIcon className="h-4 w-4 inline-block -mt-1"/> Gestisci
          </span>
        }
      </div>

      <div className="flex flex-wrap text-xs py-4 gap-4">
        {instructors.length ?
          instructors.map(i =>
            <div key={i.id_fip_code} className="flex flex-col items-center whitespace-nowrap">
              <MemberPic idFipCode={i.id_fip_code} dimensions="w-12 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20"/>
              <span className="font-bold mt-2 mb-1 ">{i.member.member}</span>
              <span>{i.member.members_jobs.filter(i => i.job.job_type === 'IST')[0].job.name}</span>
            </div>
          ) :
          <div className="w-full my-2">
            <Alert type="warning" title="Nessun istruttore assegnato a questa attività tecnica"/>
          </div>
        }
      </div>
    </div>
  )
}
