import React, {useContext, useEffect, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

import {getPayload} from "@pages/Nominations/Analytics/Analytics.lib";
import Placeholder from "@ui-components/Placeholder";
import Alert from "@ui-components/Alert";


import {LevelData, LevelProps} from "@pages/Nominations/Analytics/Analytics.type";
import RadioGroup from "@ui-components/RadioGroup";
import useLevels from "@services/hooks/useLevels";
import {LevelSchema} from "@services/types/level";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";

export const AnalyticsLevels = (
  {
    group,
    selectedNominators,
    selectedProvinces,
    selectedLevels,
    startDate,
    endDate
  }: LevelProps
) => {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const [data, setData] = useState<LevelData[] | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false);
  const [view, setView] = useState<'status' | 'coverage'>('status');

  const levels: {[key: string]: LevelSchema} = useLevels({});
  const _data = levels && data
    ? data.map(i => ({...i,
      label: levels[i.cod_level].des_level_extended,
      order: levels[i.cod_level].level_order
    })).sort((a, b) => a.order - b.order)
    : undefined

  const total = data ? [data.reduce((acc, i) => {
    let res = {...acc}
    Object.keys(i).filter(k => k.startsWith('num_')).forEach(k => {
      // @ts-ignore
      res[k] = (res[k] ?? 0) + (i[k] ?? 0)
    })
    return res
  }, {cod_level: 'tot', label: 'TOTALE'})] : undefined

  const allData = total && _data ? total.concat(_data) : undefined

  const COLUMNS = view === 'status' ?
    [
      {key: 'num_nominations', label: 'Designazioni', bgColor: ''},
      {key: 'num_rif', label: 'Rifiuti', bgColor: 'bg-red-100', denominator: 'num_nominations'},
      {key: 'num_rev', label: 'Revoche', bgColor: 'bg-yellow-100', denominator: 'num_nominations'},
      {key: 'num_acc', label: 'Accettate', bgColor: 'bg-green-100', denominator: 'num_nominations'},
      {key: 'num_tem', label: 'Temporanee', bgColor: '', alert: true},
      {key: 'num_tra', label: 'Trasmesse', bgColor: '', alert: true},
    ] :
    [
      {key: 'num_games_calendar', label: 'Calendario', bgColor: ''},
      {key: 'num_covered_past', label: 'Già designate', bgColor: ''},
      {key: 'num_triple_past', label: 'In triplo', bgColor: 'bg-blue-100', denominator: 'num_covered_past', hidden: group === 'ref'},
      {key: 'num_double_past', label: 'In doppio', bgColor: 'bg-green-100', denominator: 'num_covered_past'},
      {key: 'num_single_past', label: 'In singolo', bgColor: 'bg-yellow-100', denominator: 'num_covered_past'},
      {key: 'num_missed_past', label: 'Scoperte', bgColor: 'bg-red-100', denominator: 'num_covered_past'},
      {
        key: 'num_triple_future', bgColor: '', hidden: group === 'ref',
        label: <span><small className="font-normal">prossime gare</small><br/>In triplo</span>
      },
      {
        key: 'num_double_future', bgColor: '',
        label: <span><small className="font-normal">prossime gare</small><br/>In doppio</span>
      },
      {
        key: 'num_single_future', bgColor: '', alert: true,
        label: <span><small className="font-normal">prossime gare</small><br/>In singolo</span>
      },
      {
        key: 'num_missed_future', bgColor: '', alert: true,
        label: <span><small className="font-normal">prossimi 10 giorni</small><br/>Scoperte</span>
      },
    ].filter(i => !i.hidden)

  useEffect(() => {
    setLoading(true)
    const payload = getPayload(
      group, [], selectedProvinces, selectedLevels, startDate, endDate, selectedNominators
    )
    api.post('/analytics/levels', payload)
      .then(({data}) => setData(data))
      .catch(() => {
        setData([])
        push({title: 'Errore del server', type: 'error'})
      })
      .finally(() => setLoading(false))

  }, [group, selectedNominators, selectedProvinces, selectedLevels, startDate, endDate]);


  return (
    <div>
      <div className="-mt-5 mb-5 flex flex-col items-center">
        <RadioGroup
          id="type"
          horizontal
          options={[
            {value: "status", name: "Stato designazioni"},
            {value: "coverage", name: "Copertura"}
          ]}
          currentValue={view}
          onChange={(value: 'status' | 'coverage') => {
            setView(value);
          }}
        />
      </div>
      {loading || !allData ? <Placeholder height="h-80" classNames="rounded-lg"/> :
        allData.length ?
          <div className="overflow-x-auto flex flex-col h-70vh my-5">
            <div className="inline-block min-w-full pb-2 align-middle flex-grow overflow-auto">
              <table className="w-full">
                <thead className="sticky top-0 z-30">
                <tr className="border-b">
                  <th scope="col" className="sticky bg-gray-200 text-sm font-semibold left-0 p-2 z-10">
                    Campionato
                  </th>
                  {COLUMNS.map(c => (
                    <th key={c.key} scope="col" className="whitespace-nowrap text-sm font-semibold p-2 bg-gray-200">
                      <div>{c.label}</div>
                    </th>
                  ))}
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {allData.map((i) => (
                  <tr key={i.cod_level} className={`divide-x divide-gray-100 ${i.cod_level === 'tot' ? 'border-b-2 border-b-am-800' : ''}`}>
                    <td className="sticky left-0 z-20 bg-white text-sm font-light text-gray-900">
                      <div>
                        <div className="whitespace-nowrap pl-4 pr-3 sm:pl-6">
                        <span className="block font-bold">
                          {i.label}
                        </span>
                        </div>
                      </div>
                    </td>
                    {COLUMNS.map(c => (
                      <td
                        className={`p-1 ${c.bgColor}`}
                        key={c.key}
                      >
                        <div className="flex flex-wrap justify-center gap-1 items-center font-medium whitespace-nowrap">
                          {/*@ts-ignore*/}
                          {i[c.key]}
                          {c.denominator ?
                            <span className="ml-2 text-xs font-light text-gray-600">
                              {/*@ts-ignore*/}
                              ({intl.formatNumber(i[c.key] / i[c.denominator], {style: 'percent', maximumFractionDigits: 0})})
                            </span> : null
                          }
                          {/*@ts-ignore*/}
                          {c.alert && i[c.key] > 0 ? <ExclamationTriangleIcon className="w-4 text-yellow-500 -mr-6 ml-1" /> : null}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
          :
          <Alert title="Nessun dato presente"/>
      }
    </div>
  )
}