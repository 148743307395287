import React from "react";
import {IntlShape} from "react-intl";
import {NominationKpiType} from "@pages/Home/Home.type";

const getColorByCategory = (codCategory: string) => {
  switch (true) {
    case codCategory === 'N':
      return "text-[#003c50d9]"
    case codCategory === 'E':
      return "text-[#929496]"
    case ['S', 'R'].includes(codCategory):
      return "text-[#dea436]"
    case ['O', 'F'].includes(codCategory):
      return "text-red-700"
    default:
      return "text-[#91be88]"
  }
}

export const MemberHomeKpi = (
  {title, value, codCategory}: { title: string, value: string | number, codCategory?: string }) => {
  const color = codCategory ? getColorByCategory(codCategory) : ''
  return (
    <div key={title} className="flex flex-col border-l-4 pl-4">
      <span className={`text-sm ${codCategory ? '' : 'font-bold'}`}>{title}</span>
      <span className={`font-bold text-xl ${color}`}>{value}</span>
    </div>
  )
}

export const GameHomeKpi = (
  {
    intl,
    title,
    value,
    denominator,
    color
  }: { intl: IntlShape, title: string, value: number, denominator?: number, color?: string }) => {
  return (
    <div key={title} className="flex flex-col border-l-4 pl-4">
      <span className={`text-sm`}>{title}</span>
      <span className={`font-bold text-xl ${color ?? ''}`}>
        {intl.formatNumber(value, {maximumFractionDigits: 1})} {denominator ? `(${intl.formatNumber(value / denominator * 100, {maximumFractionDigits: 1})}%)` : ''}
      </span>
    </div>
  )
}


export const getTotalAggregation = (nominationsData: NominationKpiType) => {
  let res = nominationsData.detail.reduce((result, item) => {
    const {status_group, num_nominations} = item;
    const key = `num_${status_group}`;
    // @ts-ignore
    result[key] = (result[key] || 0) + num_nominations;
    // @ts-ignore
    result['num_nominations'] = (result['num_nominations'] || 0) + num_nominations;
    return result;
  }, {})
  // @ts-ignore
  res['totMembers'] = nominationsData.members.reduce((acc, i) => acc + i.num_members, 0)
  return res
}


export const getProvinceAggregation = (nominationsData: NominationKpiType) => {
  // @ts-ignore
  const members: any = nominationsData.members.reduce((acc, i) => ({...acc, [i.province]: (acc[i.province] || 0) + i.num_members}), {})

  return nominationsData.detail.reduce((result, item) => {
    const {province, status_group, num_nominations} = item;
    const value = num_nominations / (members[province] || 0)
    // @ts-ignore
    const existingEntry = result.find(entry => entry.province === province);
    if (existingEntry) {
      // @ts-ignore
      existingEntry[`num_${status_group}`] = (existingEntry[`num_${status_group}`] || 0) + value;
    } else {
      // Create a new entry if it doesn't exist
      const newEntry = {province, [`num_${status_group}`]: value, num_total: 0};
      // @ts-ignore
      result.push(newEntry);
    }
    // @ts-ignore
    return result.sort((a, b) => b.province.localeCompare(a.province));
  }, []);
}


export const getCategoryAggregation = (nominationsData: NominationKpiType) => {
  // @ts-ignore
  const members: any = nominationsData.members.reduce((acc, i) => ({...acc, [i.des_sub_category]: (acc[i.des_sub_category] || 0) + i.num_members}), {})

  return nominationsData.detail.reduce((result, item) => {
    const {des_sub_category, sub_cat_order, status_group, num_nominations} = item;
    const value = num_nominations / (members[des_sub_category] || 0)
    // @ts-ignore
    const existingEntry = result.find(entry => entry.des_sub_category === des_sub_category);

    if (existingEntry) {
      // @ts-ignore
      existingEntry[`num_${status_group}`] = (existingEntry[`num_${status_group}`] || 0) + value;
    } else {
      // Create a new entry if it doesn't exist
      const newEntry = {sub_cat_order, des_sub_category, [`num_${status_group}`]: value, num_total: 0};
      // @ts-ignore
      result.push(newEntry);
    }
    // @ts-ignore
    return result.sort((a, b) => b.sub_cat_order - a.sub_cat_order);
  }, []);
}