import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

// Services
import AuthenticationService from "@services/AuthenticationService";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";

// Components
import MemberDetailNomination from "@pages/Members/Detail/nominations";
import MemberDetailActivities from "@pages/Members/Detail/activities";
import MemberDetailRatings from "@pages/Members/Detail/ratings";
import ImageUploader from "@pages/Members/Detail/imageUploader";
import MemberInfo from "@pages/Members/Detail/memberInfo";
import {MemberPic} from "@pages/Members/Members.lib";

// Ui
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import {PageHeading} from "@ui-components/Container";
import Placeholder from "@ui-components/Placeholder";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

// Data
import useNominations from "@services/hooks/useNominations";
import MemberDetailAgenda from "@pages/Members/Detail/agenda";
import useEvaluations from "@services/hooks/useEvaluations";


export default function MemberDetail({showMemberData = true, showMemberActivity = true}) {

  const {push} = useContext(NotificationsContext);
  const history = useHistory();

  // we must use this when we use this component as homepage for ref and udc
  const userIdFipCode = AuthenticationService.getUserFipCode();
  const groups = AuthenticationService.getUserGroups();
  const scopes = AuthenticationService.getUserScopes()
  // we have id_fip_code from params if we are in members/40954, and it wins
  const {id_fip_code} = useParams();
  const idFipCode = id_fip_code || userIdFipCode
  const isPersonalHome = !id_fip_code && showMemberActivity

  const [data, setData] = useState();
  const [editImage, setEditImage] = useState(false);
  const [activities, setActivities] = useState(undefined);
  const [refreshImgIdFipCode, setRefreshImgIdFipCode] = useState(idFipCode);

  const nominations = useNominations({
    idFipCode: idFipCode,
    includeTemp: scopes.includes('games:temp'),
    role: scopes.includes('reports:editor') ? groups[0] === 'ref' ? 'OBSR' : 'OBSU' : null
  })

  const ratings = useEvaluations({idFipCode: idFipCode})

  useEffect(() => {
    const fetchMemberData = () => {
      api.get(`/members/${idFipCode}`)
        .then(({data: member}) => {
          setData(member);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            push({title: "Tesserato non esistente", type: "error"});
            history.push('/members')
          } else if (err.response.status === 400) {
            push({title: "Autorizzazione negata", type: "error"});
            history.push('/members')
          } else {
            push({title: "Errore imprevisto", type: "error"});
          }
        });
    };

    if (!data) {
      fetchMemberData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = () => {
      api
        .get(`/activities/members?id_fip_code=${idFipCode}`)
        .then(({data: activities}) => {
          setActivities(activities)
        })
        .catch(() => {
          push({title: "Errore del server", type: "error"})
        });
    };
    if (activities === undefined) {
      fetchData();
    }
  }, [activities]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {data ?
        <div>
          {showMemberActivity ?
            <PageHeading
              title={!isPersonalHome ? data.member :
                <span className="text-sm font-normal">
                  Hi <b>{data.firstname}</b>! Welcome back to <b>RefMan</b>
                </span>
              }
              historyBackPath={isPersonalHome ? null : "/members"}
            /> : null
          }
          {isPersonalHome ?
            <div className="flex w-full py-5">
              <MemberDetailAgenda
                history={history}
                nominations={nominations}
                activities={activities}
              />
            </div>
            : null
          }
          {showMemberData ?
            <div className="flex flex-col xl:flex-row gap-10 xl:gap-5 justify-around xl:mt-5 mb-5">
              <div className="flex w-full xl:w-1/3 justify-center flex-col gap-2">
                <MemberPic idFipCode={refreshImgIdFipCode}/>
                <div className="flex w-full justify-center">
                  <PencilSquareIcon
                    className="h-5- w-5 opacity-20 hover:opacity-60 cursor-pointer"
                    onClick={() => setEditImage(true)}
                  />
                </div>
              </div>
              <MemberInfo data={data}/>
            </div>
            : null
          }
          {showMemberActivity ?
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-2 mb-10">
              <div className="flex w-full md:col-span-2 py-5 mb-5">
                <MemberDetailNomination
                  idFipCode={idFipCode}
                  isPersonalHome={isPersonalHome}
                  nominations={nominations}
                  history={history}
                />
              </div>
              <div className="flex w-full py-5 pr-5">
                <MemberDetailActivities
                  activities={activities}
                  history={history}
                />
              </div>
              <div className="flex w-full py-5 pl-5">
                <MemberDetailRatings
                  ratings={ratings}
                  history={history}
                  isObserver={scopes.includes('reports:editor')}
                />
              </div>
            </div>
            : null
          }
        </div>
        :
        <div className="flex flex-col w-full my-4 gap-3">
          {showMemberData ?
            <div className="flex flex-col sm:flex-row gap-5">
              <Placeholder height="h-48" classNames="rounded-lg w-1/3"/>
              <Placeholder height="h-48" classNames="rounded-lg w-1/3"/>
              <Placeholder height="h-48" classNames="rounded-lg w-1/3"/>
            </div>
            : null
          }
          {showMemberActivity ?
            <div className="flex flex-col w-full gap-3">
              <Placeholder height="h-56" classNames="rounded-lg"/>
              <Placeholder height="h-56" classNames="rounded-lg"/>
              <Placeholder height="h-56" classNames="rounded-lg"/>
            </div> : null
          }
        </div>
      }
      {editImage ?
        <Modal maxWidth="sm:max-w-md" onExit={() => setEditImage(false)}>
          <ImageUploader
            idFipCode={idFipCode}
          />
          <div className="flex-grow mt-10">
            <Button full styleType="white" onClick={() => {
              setRefreshImgIdFipCode(undefined)
              setEditImage(false)
              setTimeout(() => {
                setRefreshImgIdFipCode(idFipCode)
              }, 1000)

            }}>
              Salva
            </Button>
          </div>
        </Modal>
        : null
      }
    </>
  );
}
