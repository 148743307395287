import {CROP_ASPECT_RATIO} from "@utils/constants";

const ImageFromUrl = ({
                        signedUrl,
                        className,
                        altReturn = null,
                        fixedAspectRatio = true,
                        ...rest
                      }) => {

  if (!signedUrl) {
    return altReturn;
  }

  return (
    <div
      className={className}
      style={{
        backgroundImage: `url(${signedUrl})`,
        ...(fixedAspectRatio ? {aspectRatio: CROP_ASPECT_RATIO.toString(), height: 'auto'} : {})
      }}
      {...rest}
    />
  )
}
export default ImageFromUrl;