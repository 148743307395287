import { PageHeading } from "@ui-components/Container";
import Chat from "./Chat";

export function SmartSearch() {
  return (
    <>
      <PageHeading title="Smart Search" />
      <Chat />
    </>
  );
}
