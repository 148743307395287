import React from "react";
import {QuestionItemType} from "@utils/questionnaires/type/questionnaire.type";
import {ResultsType, ResultType} from "@components/RefMan/Reports/ReportsForm/ReportsForm.type";

/**
 * @function saveTotal
 *
 * @param {number} total Total score
 * @param {QuestionItemType} data Current report data
 * @param {ResultsType} results Current results data
 * @param {React.Dispatch<React.SetStateAction<ResultsType>>} setResults Update results data
 * @param {string} sectionId Id of section
 */
export const saveTotal = (
  total: number,
  data: QuestionItemType,
  results: ResultsType | undefined,
  setResults: React.Dispatch<React.SetStateAction<ResultsType | undefined>>,
  sectionId: number
) => {

  const resultsCopy = {...results};
  resultsCopy[data.id] = {
    id: data.id,
    idSection: sectionId,
    value: total.toString(),
    isRequired: (data.isRequired === undefined || data.isRequired),
    isPrivate: (data.isPrivate !== undefined && data.isPrivate),
    tag: data.tag,
    type: "total-score-function"
  }
  setResults({
    ...results,
    ...resultsCopy
  });
}

/**
 * @function getTotalScore
 *
 * @param {ResultsType} results Current results
 * @return {number} Total score
 */
export const getTotalScore = (results: ResultsType) => {
  const resultsParsed: any = [];
  let total = 0;
  Object.values(results).forEach((result: ResultType) => {
    if (result.type === "radio") {
      if (resultsParsed[result.idSection] === undefined) {
        resultsParsed[result.idSection] = 0;
      }
      switch (result.value) {
        case "0":
          resultsParsed[result.idSection] += 1;
          break;
        case "1":
          resultsParsed[result.idSection] += 2;
          break;
        case "2":
          resultsParsed[result.idSection] += 3;
          break;
        case "3":
          resultsParsed[result.idSection] += 4;
          break;
        case "4":
          resultsParsed[result.idSection] += 5;
          break;
        case "5":
          resultsParsed[result.idSection] += 3;
          break;
      }
    }
  })
  Object.keys(resultsParsed).forEach((key: string) => {
    switch (key) {
      case '0':
        total += (resultsParsed[key] + 2) * 0.1;
        break;
      case '1':
        total += (resultsParsed[key] + 2) * 0.25;
        break;
      case '2':
        total += resultsParsed[key] * 0.25;
        break;
      case '3':
        total += resultsParsed[key] * 0.4;
        break;
    }
  })
  return total * 5;
}