import React from "react";
import {QuizQuestionProps} from "@components/RefMan/Quiz/types";
import {TrashIcon, PencilSquareIcon, ArrowUpIcon, ArrowDownIcon} from '@heroicons/react/24/outline'
import {CreateOrUpdateQuestionModal, DeleteQuestionFromQuizModal} from "@components/RefMan/Quiz/Question/QuizAction";

function getAnswerColor(answer: string, correctAnswer: string[]) {
  const baseStyle = 'flex justify-center items-center text-center w-16 min-w-fit rounded-lg border border-am-700 p-2 uppercase text-xs font-semibold'
  if (correctAnswer.includes(answer)) {
    return "bg-am-600 text-white " + baseStyle;
  }
  return baseStyle;
}

interface EditQuestionModalProps {
  show: boolean;
  idQuestion: number | undefined;
}

export function QuizQuestion(
  {
    question,
    order,
    idQuiz,
    setRefreshData,
    editSort,
    numQuestions,
    setQuestions
  }: QuizQuestionProps) {

  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [showEditQuestionModal, setShowEditQuestionModal] =
    React.useState<EditQuestionModalProps>({show: false, idQuestion: undefined});

  const moveQuestion = (direction: 'up' | 'down') => {
    if (direction === 'up' && order > 1) {
      setQuestions(prev => prev
        .map(i => ({...i, order: i.order === order ? order - 1 : i.order === order - 1 ? order : i.order}))
        .sort((a, b) => a.order - b.order)
        .map((a, index) => ({...a, order: index + 1})))
    }
    if (direction === 'down' && order < numQuestions) {
      setQuestions(prev => prev
        .map(i => ({...i, order: i.order === order ? order + 1 : i.order === order + 1 ? order : i.order}))
        .sort((a, b) => a.order - b.order)
        .map((a, index) => ({...a, order: index + 1})))
    }
  }

  return (
    <>
      <div className="flex flex-row py-4 gap-x-2">
        {editSort ? <div className="flex flex-col justify-center mr-4 gap-2">
          <ArrowUpIcon
            className={`w-5 ${order > 1 ? 'cursor-pointer' : 'text-gray-300'}`}
            onClick={() => {moveQuestion('up')}}
          />
          <ArrowDownIcon
            className={`w-5 ${order < numQuestions ? 'cursor-pointer' : 'text-gray-300'}`}
            onClick={() => {moveQuestion('down')}}
          />
        </div> : null
        }
        <div className="flex flex-col w-full">
          <span className="text-base leading-7">
            <b className="font-bold">{order}.</b> {question.des_question}
          </span>
          <div className="flex flex-row gap-2 mt-2 overflow-x-auto mr-3">
            {question.des_answers.map((ans) => (
              <div key={ans} className={getAnswerColor(ans, question.des_correct_answers)}>
                {ans}
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-row gap-x-1 text-gray-500'>
          <button onClick={() => setShowEditQuestionModal({idQuestion: question.id, show: true})}>
            <PencilSquareIcon className='h-6 w-6 cursor-pointer'/>
          </button>
          <button onClick={() => setShowDeleteModal(true)}>
            <TrashIcon className='h-6 w-6 cursor-pointer'/>
          </button>
        </div>
      </div>
      {showDeleteModal &&
        <DeleteQuestionFromQuizModal
          onExit={() => setShowDeleteModal(false)}
          idQuiz={idQuiz}
          idQuestion={question.id}
          setRefreshData={setRefreshData}
        />}
      {showEditQuestionModal.show && showEditQuestionModal.idQuestion &&
        <CreateOrUpdateQuestionModal
          edit={true}
          idQuiz={idQuiz}
          setRefreshData={setRefreshData}
          idQuestion={showEditQuestionModal.idQuestion}
          onExit={() => setShowEditQuestionModal({show: false, idQuestion: undefined})}
        />
      }
    </>
  )
}