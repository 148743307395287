import { RolesType, type StepDataType } from '@components/RefMan/Reports/ReportsIndex/ObserverReportsIndex.type';
import ObserverReportsList from '@components/RefMan/Reports/ReportsList/ObserverReportsList';
import ObserverReportsMatch from '@components/RefMan/Reports/ReportsMatch/ObserverReportsMatch';
import React, { type JSX } from 'react';
import { ReportItemType, ReportStatus, type ReportType } from '@components/RefMan/Reports/ReportsList/ObserverReportsList.type';
import { NominationType } from '@components/RefMan/Reports/reports.type';
import ReportsForm from '@components/RefMan/Reports/ReportsForm/ReportsForm';
import ObserverReportsReader from '@components/RefMan/Reports/ReportsReader/ObserverReportsReader';

/**
 * @function getStepData
 *
 * @param stepId
 * @param {React.Dispatch<React.SetStateAction<number>>} setStepId Update the step id
 * @param {React.Dispatch<React.SetStateAction<ReportType | null>>} setReportData Update the report data
 * @param {ReportType | null} reportData Report data
 * @param {React.Dispatch<React.SetStateAction<NominationType | null>>} setMemberData Update the member data
 * @param {NominationType | null} memberData Member data
 * @param {'group' | 'udc'} group
 * @param {RolesType} role Role of the current user
 * @param {ReportType} reports
 * @param {ReportStatus[]} statusList
 * @param {React.Dispatch<React.SetStateAction<ReportStatus[] | undefined>>} setStatusList
 * @param {number} offset
 * @param {number} setOffset
 * @param {number} limit
 * @param {number} setLimit
 */
export const getStepData = (
  stepId: number,
  setStepId: React.Dispatch<React.SetStateAction<number>>,
  setReportData: React.Dispatch<React.SetStateAction<ReportItemType | null>>,
  reportData: ReportItemType | null,
  setMemberData: React.Dispatch<React.SetStateAction<NominationType | undefined>>,
  memberData: NominationType | undefined,
  group: 'ref' | 'udc',
  role: RolesType,
  reports: ReportType | false | undefined,
  statusList: ReportStatus[] | undefined,
  setStatusList: React.Dispatch<React.SetStateAction<ReportStatus[] | undefined>>,
  offset: number,
  setOffset: React.Dispatch<React.SetStateAction<number>>,
  limit: number,
  setLimit: React.Dispatch<React.SetStateAction<number>>
): StepDataType[] => [
    {
      id: 0,
      name: ['observer', 'member'].includes(role) ? 'Le mie gare' : 'Tutte le gare',
      component: (
        <ObserverReportsList
          setStepId={setStepId}
          setReportData={setReportData}
          group={group}
          role={role}
          reports={reports}
          statusList={statusList}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          setLimit={setLimit}
        />
      ),
      hidden: false,
    },
    {
      id: 1,
      name: 'Dati gara',
      component: <ObserverReportsMatch setStepId={setStepId} reportData={reportData} setMemberData={setMemberData} role={role} />,
      hidden: stepId < 1 || role === 'member',
    },
    {
      id: 2,
      name: 'Compilazione rapporto',
      component: <ReportsForm setStepId={setStepId} reportData={reportData} memberData={memberData} />,
      hidden: stepId < 2 || stepId !== 2,
    },
    {
      id: 3,
      name: 'Lettura rapporto',
      component: <ObserverReportsReader reportData={reportData} memberData={memberData} role={role} />,
      hidden: stepId < 3 || stepId !== 3,
    },
    {
      id: 4,
      name: 'Lettura rapporto',
      component: <ObserverReportsReader reportData={reportData} memberData={memberData} role={role} setStatusList={setStatusList} />,
      hidden: stepId < 3 || stepId !== 4,
    },
  ];

/**
 * @function getStepComponent
 *
 * @param {StepDataType[]} stepData Content of the step
 * @param {number} stepId Current step id
 * @return {JSX.Element} Selected step component
 */
export const getStepComponent = (stepData: StepDataType[], stepId: number): JSX.Element => {
  const currentStepData = stepData.find((step) => step.id === stepId);
  return currentStepData !== undefined ? currentStepData.component : <></>;
};
