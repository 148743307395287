import React from "react";
import {RefManListItemType} from "@components/RefMan/RefManList/RefManList.type";
import {Relocation} from "@pages/Planner/Relocations/Relocations.type";
import {PencilIcon, TrashIcon} from "@heroicons/react/24/outline";
import {LKP_GROUP, WEEKDAY_OPTIONS} from "@utils/constants";
import {dateFormat} from "@pages/Planner/Absences/Absences.lib";
import {IntlShape} from "react-intl";


export const getListItems = (
  intl: IntlShape,
  items: Relocation[],
  setDeleteRelocation: React.Dispatch<React.SetStateAction<Relocation | undefined>>,
  setUpdateRelocation: React.Dispatch<React.SetStateAction<Relocation | undefined>>
): RefManListItemType[] | [] => {

  return items.map((relocation: Relocation, index): RefManListItemType => {

    const weekDaysList = (relocation.weekdays ?? '').split(';').filter(i => i)
    const weekDays = [0, 7].includes(weekDaysList.length) ? undefined
      : WEEKDAY_OPTIONS.filter(i => weekDaysList.includes(i.value)).map(i => i.name)

    let period = [weekDays ? `Tutti i ${weekDays.join(', ')} dal` : 'Tutti i giorni dal']
    period.push(`${intl.formatDate(relocation.dat_from, dateFormat)} al ${intl.formatDate(relocation.dat_to, dateFormat)}`)

    return ({
      rowId: `game-${index}`,
      rowElement: <>
        <dt className="capitalize text-gray-900 flex flex-col sm:col-span-2 mb-3 sm:mb-0">
          <span
            className="text-sm">{LKP_GROUP[relocation.member.category.cod_group]} - {relocation.member.category.des_sub_category}</span>
          <span
            className="text-xs"><b>{relocation.id_fip_code}</b>, {relocation.member.city.city} ({relocation.member.city.cod_province})</span>
          <span className="font-bold text-am-700">{relocation.member.member}</span>
        </dt>
        <dt className="flex flex-col justify-center sm:col-span-3 mb-3 sm:mb-0">
          <span className="text-sm">{period.join(' ')}</span>
        </dt>
        <dt className="flex flex-col justify-center sm:col-span-2 mb-3 sm:mb-0">
          <span className="font-bold text-am-700">{relocation.city.city} ({relocation.city.cod_province})</span>
        </dt>
        <dt className="flex flex-col gap-1 sm:justify-center">
          <div className="cursor-pointer flex flex-row gap-2 hover:underline hover:font-bold"
               onClick={() => setUpdateRelocation(relocation)}>
            <PencilIcon className="w-5 h-5"/> Modifica
          </div>
          <div className="cursor-pointer flex flex-row gap-2 hover:underline hover:font-bold"
               onClick={() => setDeleteRelocation(relocation)}>
            <TrashIcon className="w-5 h-5"/> Elimina
          </div>
        </dt>
      </>
    })
  });
}