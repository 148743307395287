import React, {useContext, useState} from "react";

// Services
import AuthenticationService from "@services/AuthenticationService";
import {DataContext} from "@services/DataContext/DataContext";
import {END_SEASON, START_SEASON} from "@utils/constants";

// Data
import useCategories from "@services/hooks/useCategories";
import useMembers from "@services/hooks/useMembers";

// Components
import {CategoryFilter, MemberSearch, ProvinceFilter} from "@pages/Members/Members.lib";
import {CalendarFilterPlaceholder, CalendarPlaceholder} from "@utils/placeholders";
import GroupSelection from "@utils/groupSelection";
import {CalendarTable} from "./Table";

// Ui
import {PageHeading} from "@ui-components/Container";
import DatePicker from "@ui-components/DatePicker";
import Alert from "@ui-components/Alert";

// Types
import {SelectedCategory, SelectedProvince} from "@pages/Members/Members.type";
import {Category, Member} from "@services/types/member";


export function PlannerCalendar() {
  const groups = AuthenticationService.getUserGroups();

  const dataContext: any = useContext(DataContext);
  const [selectedCategories, setSelectedCategories] = useState<SelectedCategory[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<SelectedProvince[]>([]);
  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);

  let initialEndDate = new Date();
  initialEndDate.setDate(initialEndDate.getDate() + 30);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(initialEndDate);

  // @ts-ignore
  const members: Member[] = useMembers({group: dataContext.group, asObj: false});
  // @ts-ignore
  const categories: Category[] = useCategories({group: dataContext.group, asObj: false});

  return (
    <>
      <PageHeading
        title="Calendario"
        historyBackPath="_back"
        contentActions={groups.length > 1 ?
          <div className="mr-6 -mt-4 sm:mt-2">
            <GroupSelection selectedGroup={dataContext.group} setSelectedGroup={dataContext.setGroup}/>
          </div> : null
        }
      />
      {members && categories ?
        <div className="flex flex-col sm:gap-2 mb-5">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col sm:flex-row justify-between sm:gap-3 z-50">
              <div className="w-full sm:w-1/3">
                <CategoryFilter
                  categories={categories}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                />
              </div>
              <div className="w-full sm:w-1/3">
                <ProvinceFilter
                  members={members}
                  selectedProvinces={selectedProvinces}
                  setSelectedProvinces={setSelectedProvinces}
                />
              </div>
              <div className="w-full sm:w-1/4">
                <MemberSearch
                  label="Cerca"
                  searchKey={searchKey}
                  setSearchKey={setSearchKey}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-5 justify-center z-40">
            <div>
              <DatePicker
                value={startDate}
                onChange={setStartDate}
                label="Data inizio"
                minDate={new Date(START_SEASON)}
                maxDate={new Date(END_SEASON)}
              />
            </div>
            <div>
              <DatePicker
                value={endDate}
                onChange={setEndDate}
                label="Data fine"
                minDate={new Date(START_SEASON)}
                maxDate={new Date(END_SEASON)}
              />
            </div>
          </div>
        </div>
        : <CalendarFilterPlaceholder />
      }
      {members && categories ?
        startDate > endDate ?
          <div>
            <Alert title="Amico, inserisci un periodo valido!" type="warning"/>
          </div> :
          <CalendarTable
            members={members}
            selectedGroup={dataContext.group}
            selectedCategories={selectedCategories}
            selectedProvinces={selectedProvinces}
            searchKey={searchKey}
            startDate={startDate}
            endDate={endDate}
          />
        : <CalendarPlaceholder />
      }
    </>
  );
}
