import React, {useContext, useEffect, useState} from "react";

// Services
import AuthenticationService from "@services/AuthenticationService";
import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "@services/DataContext/DataContext";
import {useHistory, useParams} from "react-router-dom";
import {api} from "@services/apiRequest";

// Ui
import {
  BellAlertIcon,
  CheckCircleIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon
} from "@heroicons/react/24/outline";
import Placeholder from "@ui-components/Placeholder";
import {PageHeading} from "@ui-components/Container";
import Tooltip from "@ui-components/Tooltip";
import Toggle from "@ui-components/Toggle";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Alert from "@ui-components/Alert";
import Input from "@ui-components/Input";

// Components
import {MessageList} from "@pages/Threads/Detail/Detail.lib";
import {Message} from "@pages/Threads/Detail/Message";

// Type
import {Thread, MessageSchema, BasicMember} from "@pages/Threads/Threads.type";


export function ThreadDetail() {

  const history = useHistory();
  const dataContext = useContext(DataContext);
  const {push} = useContext(NotificationsContext);
  const userIdFipCode = AuthenticationService.getUserFipCode();

  const {id_thread} = useParams<{ id_thread: string | undefined; }>();
  const idThread = Number(id_thread);

  const [thread, setThread] = useState<Thread | undefined>(undefined);
  const isOwner: boolean = Boolean(thread && thread.id_creator === userIdFipCode);
  const isInitMessage: boolean = Boolean(thread && (thread.messages ?? []).length === 0)
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [editMessage, setEditMessage] = useState<MessageSchema | undefined>(undefined);
  const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [reminderDays, setReminderDays] = useState<number>(2);
  const [sendNotificationMail, setSendNotificationMail] = useState<boolean>(true);
  const [showSendType, setShowSendType] = useState<'send' | 'remind' | undefined>(undefined);
  const [showListMember, setShowListMember] = useState<{ type: string, list: BasicMember[] | undefined }>({type: '', list: undefined});
  const [polling, setPolling] = useState({des: "", try: 0});
  const [counters, setCounters] = useState<any>(undefined);
  const [newMention, setNewMention] = useState<{ id: number, name: string } | undefined>(undefined);

  const countToSend = thread?.recipients.filter(i => !i.tms_sent).length ?? 0

  useEffect(() => {
    if (thread) {
      setCounters({
        notCc: {
          all: thread.recipients.filter(i => !i.flg_cc).map(i => i.member),
          read: thread.recipients.filter(i => !i.flg_cc && i.flg_read).map(i => i.member),
          notRead: thread.recipients.filter(i => !i.flg_cc && !i.flg_read).map(i => i.member),
        },
        cc: {
          all: thread.recipients.filter(i => i.flg_cc).map(i => i.member),
          read: thread.recipients.filter(i => i.flg_cc && i.flg_read).map(i => i.member),
          notRead: thread.recipients.filter(i => i.flg_cc && !i.flg_read).map(i => i.member),
        }
      })
    }
  }, [thread])

  useEffect(() => {
    // @ts-ignore
    if (thread && dataContext.threads) {
      // @ts-ignore
      const tmp = [thread].concat(dataContext.threads.all.filter(i => i.id !== idThread)).sort((a, b) => b.tms_creation.localeCompare(a.tms_creation))
      // @ts-ignore
      dataContext.setThreads({
        all: tmp,
        // @ts-ignore
        countToRead: tmp.filter(i => i.recipients.filter(j => j.id_member === userIdFipCode && !j.flg_read).length > 0).length
          + tmp.filter(i => i.id_creator === userIdFipCode && i.flg_check_needed).length
      });
    }
  }, [thread])

  useEffect(() => {
    const fetchData = () => {
      api.get(`/threads/${idThread}`)
        .then(({data}) => {
          setThread(data);
        })
        .catch((err) => {
          console.log(err)
          history.push('/threads')
          push({title: "Non è stato possibile recuperare i dati della comunicazione", type: "error"});
        });
    };

    if (id_thread) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [refresh]);

  const sendDelete = () => {
    setLoading(true)
    api.delete(`/threads/message/${idToDelete}`)
      .then(() => {
        setIdToDelete(undefined)
        setRefresh((prev) => !prev)
      })
      .catch(() => push({title: "Errore imprevisto", type: "error"}))
      .finally(() => {
        setLoading(false)
      })
  }

  const sendMessage = () => {
    setLoading(true)
    api.post(`/threads/send-message`, {
      id_thread: idThread,
      send_type: showSendType,
      reminder_days: reminderDays,
      send_mail: sendNotificationMail,
      subject: thread?.subject,
      author: thread?.creator.member
    })
      .then(() => {
        setPolling({des: "pending", try: 1})
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
        push({title: "Errore del server", type: "error"});
      })
  }

  useEffect(() => {
    if (polling.des === 'pending') {
      console.log(polling)
      setTimeout(() => {
        api.get(`/threads/check-notification/${idThread}`)
          .then(({data: allInvited}) => {
            if (allInvited) {
              setLoading(false);
              setRefresh(!refresh)
              setPolling({des: "done", try: 0});
              push({
                title: showSendType === 'remind' ? 'Sollecito inviato' : `Comunicazione inviata a ${countToSend} tesserat${countToSend > 1 ? 'i' : 'o'}`,
                type: "success"
              });
              setShowSendType(undefined)
            } else {
              if (polling.try > 12) {
                push({title: "Non è stato possibile inviare la comunicazione a tutti i partecipanti"});
                setLoading(false);
                setRefresh(!refresh);
                setPolling({des: "done", try: 0});
              } else {
                setPolling({des: "pending", try: polling.try + 1})
              }
            }
          })
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polling])

  const getModalListTitle = (titleType: string) => {
    switch (titleType) {
      case 'all':
        return <h1 className="text-am-700"><UserCircleIcon className="w-5 inline-block -mt-1"/> Destinatari</h1>
      case 'read':
        return <h1 className="text-green-800"><CheckCircleIcon className="w-5 inline-block -mt-1"/> Hanno visualizzato
        </h1>
      case 'wait':
        return <h1 className="text-orange-700"><QuestionMarkCircleIcon className="w-5 inline-block -mt-1"/> In attesa
        </h1>
      default:
        return null
    }
  }

  return (
    <>
      {thread ?
        <>
          <PageHeading
            title={<div className="flex flex-col">
              <span className="text-md sm:text-lg font-extrabold">Torna alle comunicazioni</span>
            </div>}
            historyBackPath="/threads"
          />
          <div className="flex flex-col border-b my-5 pb-1">
            <div className="font-bold text-lg text-am-700 w-full">
              {thread.subject}
            </div>
            {isOwner ? null : <div className="flex flex-row gap-1 items-center my-2">
              <span className="text-xs italic text-gray-500 -mt-1">Visualizzato</span>
              <Tooltip
                placement="right"
                text="Il mittente saprà che hai visualizzato questa comunicazione"
                customStyle="text-xs normal-case"
                iconName="InformationCircleIcon"
                size="small"
              />
            </div>}
          </div>
          {isOwner ?
            <div className="flex flex-col gap-2 mb-5">
              {thread.recipients.length === 0 ?
                <Alert
                  type="warning"
                  title="Non hai ancora definito i destinatari della comunicazione"
                  text="Torna alla comunicazione e clicca su Modifica per aggiungerli"
                /> : null
              }
              {isInitMessage ?
                <Alert
                  type="warning"
                  title="Inserisci un messaggio iniziale per poter inviare la comunicazione"
                />
                : (countToSend > 0 || ((thread?.recipients || []).length - countToSend > 0)) ?
                  <div className={`flex flex-col sm:flex-row sm:items-center ${countToSend > 0 ? 'sm:justify-between' : 'justify-center'} sm:gap-10`}>
                    {countToSend > 0 ? <div className="w-full">
                      <Alert
                        title={`${countToSend} destinatar${countToSend > 1 ? 'i' : 'o'} non ${countToSend > 1 ? 'hanno' : 'ha'} ancora ricevuto la comunicazione`}/>
                    </div> : null }
                    <div className="flex flex-row gap-2 mt-3 sm:mt-0">
                      {countToSend > 0 ?
                        <div>
                          <Button onClick={() => setShowSendType('send')}>
                            <EnvelopeIcon className="w-4 h-4 mr-2"/>
                            Invia
                          </Button>
                        </div> : null
                      }
                      {(thread?.recipients || []).length - countToSend > 0 ?
                        <div>
                          <Button styleType="white" onClick={() => setShowSendType('remind')}>
                            <BellAlertIcon className="w-4 h-4 mr-2"/>
                            Sollecita
                          </Button>
                        </div> : null
                      }
                    </div>
                  </div>
                  : null
              }
              {thread.recipients.length > 0 && counters ?
                <div className="flex flex-col sm:flex-row justify-around">
                  {[false, true].map(
                    (f: boolean) => {
                      const kpi = f ? counters.cc : counters.notCc
                      return (
                        <div className="flex flex-col" key={f ? 'cc' : 'notCc'}>
                          <span
                            className={`font-bold text-am-700 mb-1 ${kpi.all.length ? 'cursor-pointer hover:underline' : ''}`}
                            onClick={() => setShowListMember({type: 'all', list: kpi.all.length ? kpi.all : undefined})}
                          >
                            <UserCircleIcon className="w-5 inline-block mr-2 -mt-1"/>
                            {kpi.all.length} {f ? 'Per conoscenza' : 'Destinatari'}
                          </span>
                          <span
                            className={`text-sm text-green-800 ${kpi.read.length ? 'cursor-pointer hover:underline' : ''}`}
                            onClick={() => setShowListMember({
                              type: 'read',
                              list: kpi.read.length ? kpi.read : undefined
                            })}
                          >
                            <CheckCircleIcon className="w-4 inline-block mr-1 -mt-1"/>
                            {kpi.read.length} hanno visualizzato
                          </span>
                          <span
                            className={`text-sm text-orange-700  ${kpi.notRead.length ? 'cursor-pointer hover:underline' : ''}`}
                            onClick={() => setShowListMember({
                              type: 'wait',
                              list: kpi.notRead.length ? kpi.notRead : undefined
                            })}
                          >
                            <QuestionMarkCircleIcon className="w-4 inline-block mr-1 -mt-1"/>
                            {kpi.notRead.length} in attesa
                          </span>
                        </div>
                      )
                    }
                  )}
                </div> : null
              }
            </div> : null
          }
          {thread.messages && thread.messages.length > 0 && <>
            {isOwner ? <hr className="mt-4 mb-3"/> : null}
            <MessageList
              push={push}
              thread={thread}
              userIdFipCode={userIdFipCode}
              editMessage={editMessage}
              setEditMessage={setEditMessage}
              setIdToDelete={setIdToDelete}
              setRefresh={setRefresh}
              setNewMention={setNewMention}
              setShowEditor={setShowEditor}
            />
          </>
          }
          {showEditor ? null :
            <div className="mb-5">
              <Button onClick={() => setShowEditor(true)}>
              <span
                className="text-xs">{isInitMessage ? 'Crea il messaggio' : isOwner ? 'Nuovo messaggio' : 'Rispondi'}</span>
              </Button>
            </div>
          }
          {showEditor ?
            <Message
              push={push}
              idThread={idThread}
              creator={thread.creator}
              recipients={thread.recipients}
              setRefresh={setRefresh}
              editMessage={editMessage}
              newMention={newMention}
              onExit={() => {
                setNewMention(undefined)
                setShowEditor(false)
                setEditMessage(undefined)
              }}
            /> : null
          }
        </>
        : <div className="flex flex-col w-full mt-10 mb-4 gap-2">
          <Placeholder height="h-20" classNames="rounded-lg"/>
          <Placeholder height="h-96" classNames="rounded-lg"/>
        </div>
      }
      {idToDelete ?
        <Modal
          onExit={() => setIdToDelete(undefined)}
        >
          <div className="my-5">
            <h1 className="font-bold sm:text-xl mb-5">Sei sicuro di voler eliminare il messaggio?</h1>
            <Alert title="Non sarà possibile recuperarlo"/>
          </div>
          <div className="w-full flex flex-col sm:flex-row gap-3 justify-between mt-5">
            <Button styleType="white" onClick={() => setIdToDelete(undefined)}>
              Annulla
            </Button>
            <Button onClick={sendDelete} submitting={loading}>
              Conferma
            </Button>
          </div>
        </Modal>
        : null
      }
      {
        showSendType ?
          <Modal onExit={() => setShowSendType(undefined)}>
            <div>
              <h1 className="font-bold sm:text-xl mr-5 mb-5">
                {showSendType === 'send'
                  ? 'Sicuro di voler inviare la comunicazione?'
                  : 'Vuoi inviare un reminder a chi ancora non ha visualizzato la comunicazione?'
                }
              </h1>
              {showSendType === 'remind' ?
                // @ts-ignore
                <Input
                  type="number"
                  label="Giorni minimi di ritardo"
                  value={reminderDays}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setReminderDays(Number(e.target.value) > 0 ? Number(e.target.value) : 1)
                  }}
                  numberOptions={{minimumFractionDigits: 0, maximumFractionDigits: 0}}
                /> : null
              }
              <h3 className="text-xs sm:text-sm my-5">
                {showSendType === 'send'
                  ? <span>La comunicazione sarà resa visibile a tutti i destinatari su <b>RefMan</b></span>
                  : `Tutti i destinatari che non hanno risposto da almeno ${reminderDays} giorni riceveranno un messaggio di sollecito`
                }
              </h3>
              {showSendType === 'send' ?
                <div className="flex">
                  {/*@ts-ignore*/}
                  <Toggle
                    label={<span className="mr-2">Invia anche una mail di notifica</span>}
                    checked={sendNotificationMail}
                    onChange={() => setSendNotificationMail(!sendNotificationMail)}
                  />
                </div> : null
              }
            </div>
            <div className="w-full flex flex-col sm:flex-row gap-3 justify-between mt-5">
              <Button styleType="white" onClick={() => setShowSendType(undefined)}>
                Annulla
              </Button>
              <Button onClick={sendMessage} submitting={loading}>
                Conferma
              </Button>
            </div>
          </Modal> : null
      }
      {showListMember.list ?
        <Modal onExit={() => setShowListMember({type: '', list: undefined})}>
          <div className="font-bold mb-3">{getModalListTitle(showListMember.type)}</div>
          <div className="flex flex-col divide-y divide-gray-300 mx-5">
            {showListMember.list.map(i => i.member).sort().map(i => (
              <span key={i} className="flex flex-row items-center py-2 text-sm font-medium">{i}</span>
            ))}
          </div>
        </Modal> : null
      }
    </>
  )
}
