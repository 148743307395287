import React from "react";
import {
  CalendarIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  LinkIcon,
  MapPinIcon,
  SpeakerWaveIcon
} from "@heroicons/react/24/solid";
import {PencilIcon, TrashIcon} from "@heroicons/react/24/outline";


import {DetailMetaDataProps} from "@pages/Activities/Detail/Detail.type";
import {dateFormatExtended, timeStampFormat} from "@pages/Planner/Absences/Absences.lib";

const iconSmall = "w-4 sm:w-6 h-4 sm:h-6 text-am-700"
const iconBig = "w-5 sm:w-7 h-5 sm:h-7 text-am-700"


export function DetailMetaData(
  {
    intl,
    activity,
    setShowUpdate,
    setShowDelete,
    insertedBy,
    viewMode
  }: DetailMetaDataProps) {
  return (
    <div className="flex flex-col -mt-2 sm:mt-2 border p-3 border-gray-800 rounded-lg">
      <div
        className="flex flex-col sm:flex-row gap-2 sm:gap-6 font-medium justify-between text-sm sm:text-normal">
        <div className="flex flex-row gap-2 items-center capitalize">
          <div><CalendarIcon className={iconBig}/></div>
          <span className="font-bold">{intl.formatDate(activity.dat_activity, dateFormatExtended)}</span>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div><ClockIcon className={iconBig}/></div>
          <span className="whitespace-nowrap">{activity.time_start} - {activity.time_end}</span>
        </div>
        {activity.link_web ?
          <div className="flex flex-row gap-2 items-center">
            <div><LinkIcon className={iconBig}/></div>
            <a
              className="text-sm font-normal hover:font-bold text-gray-900"
              href={activity.link_web}
              target="_blank"
              rel="noreferrer"
            >
              Link
            </a>
          </div> :
          <div className="flex flex-row gap-2 items-center">
            <div><MapPinIcon className={iconBig}/></div>
            <span>{activity.location}</span>
          </div>
        }
      </div>
      {activity.flg_technical_test || activity.flg_physical_test ?
        <div className="flex flex-row gap-5 sm:gap-20 mt-3 text-xs sm:text-sm">
          {activity.flg_physical_test ?
            <div className="flex flex-row gap-2 items-center">
              <div><SpeakerWaveIcon className={iconSmall}/></div>
              <span>Test Fisico</span>
            </div> : null
          }
          {activity.flg_technical_test ?
            <div className="flex flex-row gap-2 items-center">
              <div><ClipboardDocumentListIcon className={iconSmall}/></div>
              <span>Test Tecnico ({activity.num_questions} domande)</span>
            </div> : null
          }
        </div>
        : null
      }
      <div className="mt-5 border-t flex flex-col sm:flex-row sm:items-center justify-between pt-2">
        <div className="text-xs mb-2 sm:mb-0">
          Aggiornata il {intl.formatDate(activity.tms_update, timeStampFormat)} {insertedBy}
        </div>
        {viewMode ? null :
          <div className="flex flex-row divide-x divide-gray-300">
            <span
              className="pr-3 text-am-700 cursor-pointer hover:font-bold"
              onClick={() => setShowUpdate(true)}
            >
              <PencilIcon className="h-4 w-4 inline-block -mt-1"/> Aggiorna
            </span>
              <span
                className="pl-3 text-am-700 cursor-pointer hover:font-bold"
                onClick={() => setShowDelete(true)}
              >
              <TrashIcon className="h-4 w-4 inline-block -mt-1"/> Elimina
            </span>
          </div>
        }
      </div>
    </div>
  )
}
