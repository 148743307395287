import {ReportsFormType, ResultsType} from "./ReportsForm.type";
import {
  getEvaluationsData,
  getQuestionComponent,
  handleConfirmClick,
  handleSaveClick,
  isSectionCompleted,
  setEvaluationsData
} from "./ReportsForm.lib";
import React, {useContext, useEffect, useState} from "react";
import ObserverReportsFormSteps from "@components/RefMan/Reports/ReportsForm/ReportsFormSteps/ObserverReportsFormSteps";
import {EvaluationsResultsDataType} from "@components/RefMan/Reports/ReportsMatch/ObserverReportsMatch.type";
import Placeholder from "@ui-components/Placeholder";
import {NotificationsContext} from "@ui-components/Notifications";
import {questionnaireType} from "@utils/questionnaires/type/questionnaire.type";
import {getQuestionnaire} from "@components/RefMan/Reports/reports.lib";
import {LKP_ROLES} from "@utils/constants";

const ReportsForm = ({setStepId, reportData, memberData}: ReportsFormType) => {
  const [currentSection, setCurrentSection] = useState<number>(0);
  const [completedSections, setCompletedSections] = useState<number[]>([])
  const [results, setResults] = useState<ResultsType>()
  const [evaluationData, setEvaluationData] = useState<EvaluationsResultsDataType | null>(null)
  const [evaluation, setEvaluation] = useState<ResultsType | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false)
  const [questionnaires, setQuestionnaires] = useState<questionnaireType>()
  const {push} = useContext(NotificationsContext);

  /**
   * Save evaluation data on section change
   */
  useEffect(() => {
    void (async () => {
      setIsLoading(true);
      if (reportData && memberData) {
        if (isFormChanged && evaluation && results) {
          const savingResults = await setEvaluationsData(
            reportData.cod_game,
            reportData.id_fip_code,
            memberData.id_fip_code,
            results,
            "WIP"
          )
          if (savingResults?.results) {
            setEvaluation(savingResults.results)
          } else {
            push({title: "Errore nel salvataggio dei dati", type: "error"});
          }
        } else if (isFormChanged && results) {
          const savingResults = await setEvaluationsData(
            reportData.cod_game,
            reportData.id_fip_code,
            memberData.id_fip_code,
            results,
            "WIP",
            true
          )
          if (savingResults?.results) {
            setEvaluation(savingResults.results)
          } else {
            push({title: "Errore nel salvataggio dei dati", type: "error"});
          }
        }
      }
      setIsFormChanged(false);
      setIsLoading(false);
    })()
  }, [currentSection])

  /**
   * Retrieving questionnaire
   */
  useEffect(() => {
    (async () => {
      const results = await getQuestionnaire(reportData, 'observer', memberData?.role ?? '');
      setQuestionnaires(results);
    })();
  }, [reportData, memberData]);

  /**
   * Load evaluation data on form loading
   */
  useEffect(() => {
    void (async () => {
      setIsLoading(true);
      if (reportData && memberData) {
        const evaluationDataRequest: EvaluationsResultsDataType[] | null = await getEvaluationsData(
          reportData.cod_game,
          reportData.id_fip_code,
          memberData.id_fip_code
        )
        if (evaluationDataRequest) {
          setEvaluationData(evaluationDataRequest[0]);
          if (evaluationDataRequest?.[0]?.results) {
            setEvaluation(evaluationDataRequest[0].results)
            setResults(evaluationDataRequest[0].results)
          }
        } else {
          push({title: "Errore nel recuperare i dati", type: "error"});
        }
      }
      setIsLoading(false);
    })()
  }, [])

  /**
   * Check for filled evaluations
   */
  useEffect(() => {
    if (results && questionnaires) {
      const completedSectionsCopy = [...completedSections];
      questionnaires.sections.forEach((_, sectionId) => {
        if (isSectionCompleted(sectionId, results, questionnaires)) {
          if (!completedSections.includes(sectionId)) {
            completedSectionsCopy.push(sectionId)
          }
        } else if (completedSections.includes(sectionId)) {
          completedSectionsCopy.splice(completedSections.indexOf(sectionId), 1);
        }
      })
      setCompletedSections(completedSectionsCopy);
    }
  }, [results, questionnaires]);

  return questionnaires ? <div className="mt-5">
    <span>Rapporto di: <b>{memberData?.member.member} ({memberData?.member ? LKP_ROLES[memberData.role] : ''})</b></span>
    <div className="observerreportsform__container border rounded-xl shadow-xl mt-8 mb-10">
      {questionnaires.sections.length > 1 &&
        <div className="observerreportsform__steps-container py-3 px-4 border-b hidden md:block">
          <ObserverReportsFormSteps
            currentSection={currentSection}
            completedSections={completedSections}
            setCurrentSection={setCurrentSection}
            questionnaires={questionnaires}
          />
        </div>}
      <div className="observerreportsform__section-container pt-5 px-4 pb-4 bg-gray-100">
        {questionnaires && questionnaires.sections.map((section, sectionIndex) =>
          <div
            key={sectionIndex}
            className={"observerreportsform__section-box bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 text-xl p-3" + (sectionIndex !== currentSection ? " hidden" : "")}
          >
            <h2 className="observerreportsform__section-title text-xl">
              {section.title}
            </h2>
            <div className="observerreportsform__section-questions flex flex-col gap-y-9 mt-5 mb-4 mx-4">
              {isLoading ?
                <Placeholder height='h-80'/> :
                section.items.map((item, itemIndex) =>
                  <div
                    key={itemIndex}
                    className="observerreportsform__section-question"
                  >
                    {getQuestionComponent(
                      item,
                      sectionIndex,
                      itemIndex,
                      results,
                      setResults,
                      setIsFormChanged
                    )}
                  </div>
                )}
            </div>
          </div>)}
      </div>
      <div className="observerreportsform__section-footer flex justify-end pt-4 bg-gray-100">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 m-3 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={currentSection <= 0}
          onClick={() => {
            if (currentSection > 0) {
              setCurrentSection(currentSection - 1);
            }
          }}
        >
          Indietro
        </button>
        <button
          type="button"
          className={"rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 m-3 disabled:opacity-50 disabled:cursor-not-allowed" + ((questionnaires.sections.length > 1 && currentSection < questionnaires.sections.length - 1) || " hidden")}
          disabled={currentSection >= questionnaires.sections.length - 1}
          onClick={() => {
            if (currentSection < questionnaires.sections.length - 1) {
              setCurrentSection(currentSection + 1);
            }
          }}
        >
          Avanti
        </button>
        <button
          type="button"
          className={"rounded-md bg-am-500 px-3 py-2 text-sm text-white font-semibold shadow-sm hover:bg-am-400 m-3 disabled:opacity-50 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-green-600" + ((questionnaires.sections.length > 1 && currentSection >= questionnaires.sections.length - 1) || " hidden")}
          onClick={async () => {
            await handleSaveClick(reportData, memberData, results, push)
          }}
        >
          Salva
        </button>
        <button
          type="button"
          className={"rounded-md bg-am-600 px-3 py-2 text-sm text-white font-semibold shadow-sm hover:bg-am-400 m-3 disabled:opacity-50 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-green-600" + (currentSection >= questionnaires.sections.length - 1 || " hidden")}
          onClick={async () => {
            const result = await handleConfirmClick(questionnaires, reportData, memberData, results, push);
            if (result) {
              setStepId(1);
            }
          }}
        >
          Approva
        </button>
      </div>
    </div>
  </div> : <></>;
}

export default ReportsForm;

