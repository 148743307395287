import { useEffect } from 'react';
import { MembersListType, RatingsChartType, VotesType } from './Ratings.type';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { addLicense } from '@components/Charts/commons';
import { getDateFormatted } from '@utils/lib/dates';
import { LKP_ROLES } from '@utils/constants';
import './Ratings.style.css';

const RatingsChart = ({ data, setEvaluation }: RatingsChartType) => {
  useEffect(() => {
    if (data) {
      addLicense();
      const root = am5.Root.new('member-chart');
      root.setThemes([am5themes_Animated.new(root)]);
      renderChart(root, data);
      return () => {
        root.dispose();
      };
    }
  }, [data]);

  /**
   * @function parseData
   *
   * @param {MembersListType} data
   * @returns Array
   */
  const parseData = (data: MembersListType) =>
    data.votes
      ? data.votes
        .map((vote: VotesType) => ({
          date: new Date(vote.date).getTime(),
          vote: vote.vote,
          potential: vote.potential,
          game: vote.game,
          observer: vote.observer,
          role: vote.role,
          codGame: vote.codGame,
          idMember: vote.idMember,
        }))
        .sort((a, b) => a.date - b.date)
      : [];

  /**
   * @function renderChart
   *
   * @param {Root} root Chart instance
   * @param {MembersListType} data Data to render in chart
   * @return void
   */
  const renderChart = (root: am5.Root, data: MembersListType) => {
    // Chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        pinchZoomX: false,
      })
    );

    // Cursor
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
      })
    );
    cursor.lineY.set('visible', true);

    // X Axes
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        extraMin: 0.1,
        extraMax: 0.1,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          // @ts-ignore
          // minorGridEnabled: true,
          minGridDistance: 100,
          // minorLabelsEnabled: true
        }),
      })
    );

    // Y Axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxPrecision: 0,
        min: data.average <= 10 ? 1 : 55,
        max: data.average <= 10 ? 11 : 82,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    let yRenderer = yAxis.get('renderer');
    yRenderer.grid.template.set('forceHidden', true);

    // Series
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'vote',
        valueXField: 'date',
        stroke: am5.color('rgb(0, 60, 80)'),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    // Tooltip content
    const tooltip = series.get('tooltip');
    if (tooltip) {
      tooltip.adapters.add('labelHTML', function (text, target) {
        let targetDataItem = target?.dataItem?.dataContext as VotesType | undefined;
        if (targetDataItem) {
          return `<div style="text-align:left;"
                ${targetDataItem?.game?.date ? '<p><strong>Data</strong>: ' + getDateFormatted(targetDataItem.game.date) + '</p>' : ''}
                ${targetDataItem?.game?.level ? '<p><strong>Campionato</strong>: ' + targetDataItem.game.level + '</p>' : ''}
                ${targetDataItem?.game?.teams ? '<p><strong>Squadre</strong>: ' + targetDataItem.game.teams + '</p>' : ''}
                ${targetDataItem?.observer ? '<p><strong>Osservatore</strong>: ' + targetDataItem.observer + '</p>' : ''}
                ${targetDataItem?.role && LKP_ROLES[targetDataItem.role] ? '<p><strong>Ruolo</strong>: ' + LKP_ROLES[targetDataItem.role] + '</p>' : ''}
                ${targetDataItem?.vote ? '<p><strong>Voto</strong>: ' + targetDataItem?.vote + '</p>' : ''}
                ${targetDataItem?.potential ? '<p><strong>Potenzialità</strong>: ' + targetDataItem?.potential + '</p>' : ''}
            <div>`;
        }
        return text;
      });
    }

    // Bullets
    series.bullets.push(function () {
      const bulletCircle = am5.Circle.new(root, {
        radius: 18,
        fill: am5.color('rgb(0, 60, 80)'),
        cursorOverStyle: "pointer",
      });
      bulletCircle.events.on('click', function (ev: any) {
        setEvaluation({
          codGame: ev.target.dataItem?.dataContext.codGame,
          idMember: ev.target.dataItem?.dataContext.idMember,
        });
      });
      return am5.Bullet.new(root, {
        sprite: bulletCircle,
      });
    });

    series.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          text: '{valueY}',
          fontSize: 11,
          fontWeight: 'bold',
          fill: am5.color('rgb(255,255,255)'),
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          populateText: true,
        }),
      });
    });

    series.data.setAll(parseData(data));

    // Chart Appear
    series.appear(1000);
    chart.appear(1000, 100);
  };

  return <div id='member-chart'></div>;
};

export default RatingsChart;
