import React, {useContext, useState} from 'react';

// Components
import {BasicSessionModalPlaceholder} from "@utils/placeholders";
import CheckboxGroup from "@ui-components/CheckboxGroup";
import DatePicker from "@ui-components/DatePicker";
import RadioGroup from "@ui-components/RadioGroup";
import Button from '@ui-components/Button';
import Modal from "@ui-components/Modal";

// Services
import useLevels from "@services/hooks/useLevels";
import {api} from '@services/apiRequest';
import moment from "moment/moment";

// Types
import {
  BasicAlgorithmTaskSchema,
  BasicInputData,
  BasicSessionDetail,
  CreateBasicSessionModalProps
} from "@pages/Nominations/Algorithms/Algorithms.types";
import {LevelSchema} from "@services/types/level";

import {SPEC_COLS_OPTIONS} from "@pages/Entry/Jobs/ManageJob/services";
import {END_SEASON} from "@utils/constants";
import {NotificationsContext} from "@ui-components/Notifications";
import {useHistory} from "react-router-dom";


function CreateUpdateBasicSessionModal(
  {
    group,
    session,
    setSession,
    setShowCreateSession
  }: CreateBasicSessionModalProps) {

  const history = useHistory();
  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);
  const levels = useLevels({asObj: false})
  const levelOptions: LevelSchema[] = levels ?
    levels.filter((i: LevelSchema) => group === 'REF' ? i.flg_model_basic_ref : i.flg_model_basic_udc) : undefined

  const [data, setData] = useState<BasicInputData>(session?.jsn_input_data ?? {
    levels: 'all',
    provinces: 'all',
    dat_from: '',
    dat_to: '',
    flg_assign_all_games: false,
    flg_forbidden_same_city_team_a: false,
    flg_forbidden_same_city_team_b: false,
    member_role_game: {assign: [], forbidden: []},
    member_role: {assign: [], forbidden: []},
    member_game: {assign: [], forbidden: []},
    member: {assign: [], forbidden: []},
    member_couples: {assign: [], forbidden: []},
  })

  const [details, setDetails] = useState<BasicSessionDetail>({
    levels: session && session.jsn_input_data?.levels !== 'all' ? 'some' : 'all',
    provinces: session && session.jsn_input_data?.provinces !== 'all' ? 'some' : 'all'
  })


  const createUpdateSession = () => {
    setLoading(true)
    if (session && setSession) {
      api.post(`/algorithms/basic/constraints`, {id: session.id, constraints: data})
        .then(({data}) => {
          setShowCreateSession(false)
          setSession({...session, jsn_input_data: data})
        })
        .catch(() => push({type: "error", title: "Errore durante il caricamento"}))
        .finally(() => setLoading(false))
    } else {
      api.post<BasicAlgorithmTaskSchema>('/algorithms/basic', {
        cod_group: group,
        jsn_input_data: data
      })
        .then(({data}) => {
          push({type: "success", title: "Sessione creata con successo"})
          setShowCreateSession(false)
          history.push(`/nominations/algorithms/basic/${data.id}`)

        })
        .catch(() => push({type: "error", title: "Errore durante il caricamento"}))
        .finally(() => setLoading(false))
    }
  }

  return (
    <Modal onExit={() => setShowCreateSession(false)}>
      <h1 className="font-bold mb-5">{session ? 'Modifica' : 'Crea nuova'} sessione</h1>
      {levelOptions ?
        <div>
          <div className="flex flex-col sm:flex-row gap-2 my-6">
            {/*@ts-ignore*/}
            <DatePicker
              value={data.dat_from ? new Date(data.dat_from) : undefined}
              onChange={(i: string) => setData({
                ...data, dat_from: moment(i).format('YYYY-MM-DD')
              })}
              label="Data di inizio"
              placeholder="Seleziona data"
              maxDate={new Date(END_SEASON)}
            />
            {/*@ts-ignore*/}
            <DatePicker
              value={data.dat_to ? new Date(data.dat_to) : undefined}
              onChange={(i: string) => setData({
                ...data, dat_to: moment(i).format('YYYY-MM-DD')
              })}
              label="Data di fine"
              placeholder="Seleziona data"
              maxDate={new Date(END_SEASON)}
            />
          </div>
          <div className="my-5">
            <RadioGroup
              id="detailLevels"
              horizontal
              options={[
                {value: "all", name: "Tutti i campionati"},
                {value: "some", name: "Seleziona campionati"},
              ]}
              currentValue={details.levels}
              onChange={(value: 'all' | 'some') => {
                setDetails({...details, levels: value})
                setData({
                  ...data, levels: value === 'some'
                    ? levelOptions.map(i => i.cod_level) : 'all'
                })
              }}
            />
          </div>
          {details.levels === 'some' ?
            <div>
              {/*@ts-ignore*/}
              <CheckboxGroup
                horizontal
                options={levelOptions.map(i => ({value: i.cod_level, name: i.cod_level}))}
                values={data.levels === 'all' ? [] : data.levels}
                onChange={(value: string) => {
                  const prev = data.levels === 'all' ? [] : data.levels
                  if (data.levels.includes(value)) {
                    setData({...data, levels: prev.filter(i => i !== value)})
                  } else {
                    setData({...data, levels: [...prev, value]})
                  }
                }}
              />
            </div>
            : null
          }
          <div className="my-5">
            <RadioGroup
              id="detailProvinces"
              horizontal
              options={[
                {value: "all", name: "Tutte le province"},
                {value: "some", name: "Seleziona province"},
              ]}
              currentValue={details.provinces}
              onChange={(value: 'all' | 'some') => {
                setDetails({...details, provinces: value})
                setData({...data, provinces: value === 'some' ? SPEC_COLS_OPTIONS.provinces : 'all'})
              }}
            />
          </div>
          {details.provinces === 'some' ?
            <div>
              {/*@ts-ignore*/}
              <CheckboxGroup
                horizontal
                options={SPEC_COLS_OPTIONS.provinces.map(i => ({value: i, name: i}))}
                values={data.provinces === 'all' ? [] : data.provinces}
                onChange={(value: string) => {
                  const prev = data.provinces === 'all' ? [] : data.provinces
                  if (data.provinces.includes(value)) {
                    setData({...data, provinces: prev.filter(i => i !== value)})
                  } else {
                    setData({...data, provinces: [...prev, value]})
                  }
                }}
              />
            </div>
            : null
          }
          <div className="mt-5 flex flex-row justify-between">
            <Button styleType="white" onClick={() => setShowCreateSession(false)}>
              Annulla
            </Button>
            <Button
              submitting={loading}
              disabled={!data.dat_to || !data.dat_from}
              onClick={createUpdateSession}
            >
              {session ? 'Salva' : 'Crea'}
            </Button>
          </div>
        </div>
        : <BasicSessionModalPlaceholder/>
      }

    </Modal>
  );
}

export default CreateUpdateBasicSessionModal;