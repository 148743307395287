import React from 'react';

// Constants & Model
import {AssignmentOptionSchema, RolesDescription} from '../Algorithms.types';
import {ConstraintsAssignmentCardTypes} from '../Algorithms.types';
import {SearchSelectAssignmentOptionSchema} from '../Algorithms.types';
import {IntlShape} from "react-intl";

// Components
import {
  assignConstraintToGameRole,
  getGameAssignmentOptions,
  getOptionWithTeamCounters
} from "@pages/Nominations/Algorithms/EliteAlgorithm.services";
import SearchSelect from '@ui-components/SearchSelect';
import Placeholder from "@ui-components/Placeholder";
import Badge from "@ui-components/Badge";


export function CounterGame({option, team, intl}: {
  option: AssignmentOptionSchema,
  team: 'a' | 'b',
  intl: IntlShape
}) {
  const lastData = option[`dat_last_game_${team}`]
  return <div className='flex flex-row gap-1 w-full justify-center items-center'>
    <span className="font-medium">{option[`num_nominations_${team}`]}</span>
    {lastData ? <span className="italic text-gray-500 text-xs">({intl.formatDate(lastData)})</span> : null}
  </div>
}

function ConstraintsAssignmentCard(
  {
    intl,
    role,
    game,
    distances,
    relocations,
    okStatus,
    options,
    userKwargs,
    setUserKwargs,
    setToSave,
    taskData,
    fetching,
    algoType
  }: ConstraintsAssignmentCardTypes) {

  const alreadyAssigned = userKwargs?.member_role_game.assign.filter(i => i[2] === game.cod_game && i[1] === role);
  // Controllo se quello che avevo salvato è ancora un'opzione disponibile
  let _selected: { label: string, value: number } | undefined = undefined;
  if (alreadyAssigned.length) {
    const idFipCode = alreadyAssigned[0][0]
    const memberOption = options.filter(i => i.id_member === idFipCode)
    if (memberOption.length) {
      _selected = {label: memberOption[0].member.tag, value: idFipCode}
    }
  }

  const currentNomination = game.nominations
    .filter(i => i.role === role.toUpperCase() && okStatus.includes(i.status))[0]
  const currentMember = currentNomination?.member.tag ?? ''
  const tempTag = currentNomination && currentNomination.status === 'TEM' ? '[T]' : ''

  const rolesToMatch = ['ref1', 'ref2'].includes(role) ? ['ref'] : [role]

  return (
    <div className="mx-auto flex flex-col gap-y-2 text-xs max-w-xs">
      <div className="flex flex-col gap-1">
        <div className="flex flex-row justify-between items-end">
          <div className="font-bold">{RolesDescription[role]}</div>
          <span className="text-sm font-bold italic mx-1 text-gray-500">
            {currentMember} {tempTag}
          </span>
        </div>
        <div className="-mb-1">
          <SearchSelect
            label={undefined}
            options={getGameAssignmentOptions(
              options,
              userKwargs.member_role_game.assign,
              rolesToMatch,
              algoType === 'basic' ? relocations : undefined,
              algoType === 'basic' ? distances : undefined,
              algoType === 'basic' ? game.city?.id ?? 0 : undefined,
              algoType === 'basic' ? game.dat_game : undefined,
            )}
            value={_selected}
            onChange={(members: { value: number, label: string }[]) => {
              // Trick to get only one single option selectable at time
              const selectedMember = members.length ? members[members.length - 1] : undefined;
              // Update round-constraint on EliteAlgorithmGames component
              assignConstraintToGameRole(game, role, userKwargs, setUserKwargs, selectedMember?.value);
              setToSave(true);
            }}
            isMulti={true}
            loadOptions={null}
            CustomOption={(props: { data?: SearchSelectAssignmentOptionSchema; innerProps?: any; innerRef?: any; }) => {
              return getOptionWithTeamCounters(intl, props, distances, relocations, game.city?.id ?? 0, game.dat_game)
            }}
            minHeight={10}
          />
        </div>
        {(taskData.cod_status === 'RUNNING' || fetching)
          ? <Placeholder height="h-6" classNames="rounded-full"/>
          : taskData.cod_status === 'END' || (taskData.cod_status === 'ACCEPTED' && taskData.jsn_result?.solution_by_game[game.cod_game])
            ? <div>
              {taskData.jsn_result?.solution_by_game[game.cod_game]
                ? <Badge text={taskData.jsn_result?.solution_by_game[game.cod_game][role]}/>
                : currentMember ? <Badge text={currentMember} type="success"/> : <div className="h-5"/>
              }
            </div>
            : null
        }
      </div>
    </div>
  );
}

export default ConstraintsAssignmentCard;