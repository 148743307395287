import React from "react";
import {PlusIcon, ClipboardDocumentListIcon} from '@heroicons/react/20/solid'
import {AddQuestionToQuizProps} from "@components/RefMan/Quiz/types";
import Button from "@ui-components/Button";
import {CreateOrUpdateQuestionModal} from "@components/RefMan/Quiz/Question/QuizAction";
import {
  SelectQuestionFromListModal
} from "@components/RefMan/Quiz/Question/AddQuestionToQuiz/SelectQuestionFromListModal";


export function AddQuestionToQuiz({idQuiz, setRefreshData}: AddQuestionToQuizProps) {

  const [showCreateNewQuestion, setShowCreateNewQuestion] = React.useState<boolean>(false);
  const [showAddExistingQuestion, setShowAddExistingQuestion] = React.useState<boolean>(false);

  return (
    <>
      <div className='flex flex-row justify-center gap-x-4'>
        <Button styleType={'white'} className="text-xs" onClick={() => setShowCreateNewQuestion(true)}>
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true"/>
          Crea nuova domanda
        </Button>
        <Button styleType={'white'} className="text-xs" onClick={() => setShowAddExistingQuestion(true)}>
          <ClipboardDocumentListIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true"/>
          Aggiungi domanda dal catalogo
        </Button>
      </div>
      {showCreateNewQuestion &&
        <CreateOrUpdateQuestionModal
          onExit={() => setShowCreateNewQuestion(false)}
          idQuiz={idQuiz}
          setRefreshData={setRefreshData}
          edit={false}
        />}
      {showAddExistingQuestion &&
        <SelectQuestionFromListModal
          onExit={() => setShowAddExistingQuestion(false)}
          idQuiz={idQuiz}
          setRefreshData={setRefreshData}
        />}
    </>
  )
}