import React, {useContext, useState} from "react";

import AuthenticationService from "@services/AuthenticationService";
import {DataContext} from "@services/DataContext/DataContext";

// Data
import useCategories from "@services/hooks/useCategories";
import useMembers from "@services/hooks/useMembers";
import useLevels from "@services/hooks/useLevels";

// Components
import {AnalyticsEmployment} from "@pages/Nominations/Analytics/Sections/Employment";
import {CategoryFilter, ProvinceFilter} from "@pages/Members/Members.lib";
import {CalendarFilterPlaceholder} from "@utils/placeholders";
import {END_SEASON, START_SEASON} from "@utils/constants";
import {GamesLevelFilter} from "@pages/Games/Games.lib";
import GroupSelection from "@utils/groupSelection";

// Ui
import {PageHeading} from "@ui-components/Container";
import DatePicker from "@ui-components/DatePicker";
import RadioGroup from "@ui-components/RadioGroup";
import Tabs from "@ui-components/Tabs";

import {SelectedCategory, SelectedProvince} from "@pages/Members/Members.type";
import {Category, Member} from "@services/types/member";
import {SelectedLevel} from "@pages/Games/Games.type";
import {AnalyticsCounters} from "@pages/Nominations/Analytics/Sections/Counters";
import {AnalyticsLevels} from "@pages/Nominations/Analytics/Sections/Levels";
import SearchSelect from "@ui-components/SearchSelect";


const TABS = [
  {name: 'Impiego', value: 'employment'},
  {name: 'Contatori', value: 'counters'},
  {name: 'Campionati', value: 'levels'},
]

export function NominationAnalytics() {

  const groups = AuthenticationService.getUserGroups();
  const dataContext: any = useContext(DataContext);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const levels = useLevels({asObj: false});
  const categories: Category[] = useCategories({group: dataContext.group, asObj: false, onlyAssignable: true});
  const members: Member[] = useMembers({group: dataContext.group, asObj: false});


  const [selectedCategories, setSelectedCategories] = useState<SelectedCategory[]>([]);
  const [selectedNominators, setSelectedNominators] = useState<SelectedProvince[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<SelectedProvince[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<SelectedLevel[]>([]);

  const [period, setPeriod] = useState<'all' | 'custom'>('all');
  let initialStartDate = new Date();
  initialStartDate.setDate(initialStartDate.getDate() - 30);
  const [startDate, setStartDate] = useState<Date>(initialStartDate);
  const [endDate, setEndDate] = useState<Date>(new Date());

  const nominators = members
    ? members.filter(i => (i.members_jobs || [])
      .filter(j => j.job.job_type === 'DES').length > 0)
      .map(i => ({value: i.id_fip_code, label: i.tag}))
    : undefined;

  const showCurrentTab = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return <AnalyticsEmployment
          group={dataContext.group}
          selectedCategories={selectedCategories}
          selectedProvinces={selectedProvinces}
          selectedLevels={selectedLevels}
          startDate={period === 'custom' ? startDate : undefined}
          endDate={period === 'custom' ? endDate : undefined}
        />
      case 1:
        return <AnalyticsCounters
          group={dataContext.group}
          selectedCategories={selectedCategories}
          selectedProvinces={selectedProvinces}
          selectedLevels={selectedLevels}
          startDate={period === 'custom' ? startDate : undefined}
          endDate={period === 'custom' ? endDate : undefined}
        />
      case 2:
        return <AnalyticsLevels
          group={dataContext.group}
          selectedNominators={selectedNominators}
          selectedProvinces={selectedProvinces}
          selectedLevels={selectedLevels}
          startDate={period === 'custom' ? startDate : undefined}
          endDate={period === 'custom' ? endDate : undefined}
        />
    }
  }

  return (
    <>
      <PageHeading
        title="Analisi designazioni"
        historyBackPath="_back"
        contentActions={groups.length > 1 ?
          <div className="mr-6 -mt-4 sm:mt-2">
            <GroupSelection selectedGroup={dataContext.group} setSelectedGroup={dataContext.setGroup}/>
          </div> : null
        }
      />
      <div className="-mt-4 sm:-mt-2 mb-5">
        <Tabs
          setActiveTab={setTabIndex}
          active={tabIndex}
          tabs={TABS}
        />
      </div>
      {members && categories && nominators ?
        <div className="flex flex-col sm:gap-2 mb-5">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col sm:flex-row justify-between sm:gap-3 z-50">
              <div className="w-full sm:w-1/3">
                {tabIndex < 2 ?
                  <CategoryFilter
                    categories={categories}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                  /> :
                  <SearchSelect
                    label='Designatori'
                    options={nominators}
                    isMulti
                    value={selectedNominators}
                    onChange={(e: SelectedProvince[]) => {
                      setSelectedNominators(e);
                    }}
                    loadOptions={undefined}
                    CustomOption={undefined}
                    minHeight={undefined}
                  />
                }
              </div>
              <div className="w-full sm:w-1/3">
                <ProvinceFilter
                  members={members}
                  selectedProvinces={selectedProvinces}
                  setSelectedProvinces={setSelectedProvinces}
                />
              </div>
              <div className="w-full sm:w-1/3">
                <GamesLevelFilter
                  levels={levels}
                  selectedLevels={selectedLevels}
                  setSelectedLevels={setSelectedLevels}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-center items-center">
            <div className="sm:mx-10">
              <RadioGroup
                id="period"
                horizontal
                options={[
                  {value: "all", name: "Intera stagione"},
                  {value: "custom", name: "Periodo custom"},
                ]}
                currentValue={period}
                onChange={(value: 'all' | 'custom') => {
                  setPeriod(value);
                }}
              />
            </div>
            {period === 'custom' ?
              <div className="flex flex-row gap-5 justify-center z-40">
                <div className="sm:w-40">
                  <DatePicker
                    value={startDate}
                    onChange={setStartDate}
                    label="Data inizio"
                    minDate={new Date(START_SEASON)}
                    maxDate={new Date(END_SEASON)}
                  />
                </div>
                <div className="sm:w-40">
                  <DatePicker
                    value={endDate}
                    onChange={setEndDate}
                    label="Data fine"
                    minDate={new Date(START_SEASON)}
                    maxDate={new Date(END_SEASON)}
                  />
                </div>
              </div> : null
            }
          </div>
        </div>
        : <CalendarFilterPlaceholder/>
      }
      {showCurrentTab(tabIndex)}
    </>
  );
}
