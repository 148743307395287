import React, {useCallback, useState} from "react";

// Services
import apiRequest from "@services/apiRequest";

// Ui
import Modal from "@ui-components/Modal";
import Button from "@ui-components/Button";
import SearchSelect from "@ui-components/SearchSelect";


function AssignDialog({open, toggleOpen, dataContext, push, filteredJobs, members}) {
  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const [selectedJob, setSelectedJob] = useState();

  const onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        setLoading(true);
        const res = await apiRequest.post("/jobs/members", {
          id_fip_code: selectedMember?.value,
          id_job: selectedJob?.value
        });
        dataContext.setMembers({...dataContext.members, [res['id_fip_code']]: res})
        toggleOpen();
        push({
          title: "Elemento aggiunto correttamente",
          type: "success",
        });
      } catch (error) {
        console.log(error)
        push({
          title: "Errore imprevisto",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [selectedMember, selectedJob] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div>
      <Modal opened={open} onExit={() => {
        setSelectedMember(undefined)
        setSelectedJob(undefined)
        toggleOpen()
      }}>
        <h3 className="text-center text-2xl font-extrabold text-gray-900 mt-6 sm:mt-0">
          Assegna incarico
        </h3>
        <div className="flex flex-col gap-2 my-6">
          {members ?
            <SearchSelect
              label="Tesserato"
              value={selectedMember}
              onChange={(e) => setSelectedMember(e)}
              options={Object.values(members).sort((a, b) => a.member.localeCompare(b.member)).map(i => ({
                label: <span>{i.id_fip_code} - <b>{i.member}</b></span>,
                value: i.id_fip_code,
                data: i
              }))}
              filterOption={(candidate, input) => {
                if (input) {
                  const _data = candidate.data.data
                  const _label = _data.id_fip_code + _data.member
                  return _label.toUpperCase().includes(input.toUpperCase())
                }
                return true
              }}
            /> : null
          }
          {filteredJobs ?
            <SearchSelect
              label="Incarico"
              value={selectedJob}
              options={filteredJobs.map(i => ({value: i.id, label: i.name}))}
              onChange={(e) => setSelectedJob(e)}
            /> : null
          }
        </div>
        <div className="flex items-end justify-end">
          <Button
            submitting={loading}
            disabled={!selectedMember || !selectedJob}
            onClick={onSubmit}
          >
            Assegna
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default AssignDialog;
