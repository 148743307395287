import React from "react";
import Placeholder from "@ui-components/Placeholder";

export function MembersPlaceholder() {
  return (
    <div className='flex flex-col w-full mb-4 gap-2'>
      <div className='flex flex-row gap-10'>
        <Placeholder height='h-16' classNames='rounded-lg w-1/3'/>
        <Placeholder height='h-16' classNames='rounded-lg w-1/3'/>
        <Placeholder height='h-16' classNames='rounded-lg w-1/3'/>
      </div>
      <Placeholder height='h-20' color='am-200' classNames='rounded-lg'/>
      <Placeholder height='h-16' classNames='rounded-lg mt-5'/>
      <Placeholder height='h-16' classNames='rounded-lg'/>
      <Placeholder height='h-16' classNames='rounded-lg'/>
      <Placeholder height='h-16' classNames='rounded-lg'/>
      <Placeholder height='h-16' classNames='rounded-lg'/>
      <Placeholder height='h-16' classNames='rounded-lg'/>
      <Placeholder height='h-16' classNames='rounded-lg'/>
      <Placeholder height='h-16' classNames='rounded-lg'/>
    </div>
  )
}


export function QuizPlaceholder() {
  return (
    <div className='flex flex-col w-full mb-4 gap-2'>
      {[...new Array(6)].map((_, i) =>
        <Placeholder key={i} height="h-24" classNames="rounded-lg"/>
      )}
    </div>
  )
}

export function QuizDetailPlaceholder() {
  return (
    <div className='flex flex-col w-full my-5 gap-2'>
      <Placeholder height='h-16' classNames='rounded-lg'/>
      <Placeholder height='h-20' classNames='rounded-lg'/>
      <div className='flex flex-row gap-10 px-20 mb-5'>
        <Placeholder height='h-12' classNames='rounded-lg'/>
        <Placeholder height='h-12' classNames='rounded-lg'/>
      </div>
      {[...new Array(6)].map((_, i) =>
        <Placeholder key={i} height="h-16" classNames="rounded-lg"/>
      )}
    </div>
  )
}

export function CalendarFilterPlaceholder() {
  return (
    <div className="flex flex-col gap-3 mb-10">
      <div className="flex flex-row gap-10">
        <Placeholder height="h-10" classNames="rounded-lg w-1/3"/>
        <Placeholder height="h-10" classNames="rounded-lg w-1/3"/>
        <Placeholder height="h-10" classNames="rounded-lg w-1/3"/>
      </div>
      <div className="flex flex-row justify-center gap-10">
        <Placeholder width="w-64" height="h-10" classNames="rounded-lg"/>
        <Placeholder width="w-64" height="h-10" classNames="rounded-lg"/>
      </div>
    </div>
  )
}

export function CalendarPlaceholder() {
  return (
    <div className="flex flex-col gap-1">
      <Placeholder key={-1} height="h-10" color="am-200" classNames="rounded-lg"/>
      {[...new Array(11)].map((_, i) =>
        <Placeholder key={i} height="h-12" classNames="rounded-lg bg-am-600"/>
      )}
    </div>
  )
}

export function AlgoElitePlaceholder() {
  return (
    <div className="flex flex-col gap-3 mb-5">
      <div className="flex flex-row gap-10 justify-center my-5">
        <Placeholder width="w-64" height="h-10" classNames="rounded-lg"/>
        <Placeholder width="w-64" height="h-10" classNames="rounded-lg"/>
      </div>
      <Placeholder width="w-full" height="h-32" classNames="rounded-lg"/>
      <Placeholder width="w-full" height="h-32" classNames="rounded-lg"/>
      <Placeholder width="w-full" height="h-32" classNames="rounded-lg"/>
      <Placeholder width="w-full" height="h-32" classNames="rounded-lg"/>
      <Placeholder width="w-full" height="h-32" classNames="rounded-lg"/>
    </div>
  )
}


export function AlgoBasicPlaceholder() {
  return (
    <div className="flex flex-col gap-3 mb-5">
      <div className="flex flex-row gap-10 justify-center my-5">
        <Placeholder width="w-64" height="h-10" classNames="rounded-lg"/>
        <Placeholder width="w-64" height="h-10" classNames="rounded-lg"/>
      </div>
      <Placeholder width="w-full" height="h-32" classNames="rounded-lg"/>
      <Placeholder width="w-full" height="h-32" classNames="rounded-lg"/>
      <Placeholder width="w-full" height="h-32" classNames="rounded-lg"/>
      <Placeholder width="w-full" height="h-32" classNames="rounded-lg"/>
      <Placeholder width="w-full" height="h-32" classNames="rounded-lg"/>
    </div>
  )
}


export function BasicSessionModalPlaceholder() {
  return (
    <div className="flex flex-col gap-3 mb-5">
      <div className="flex flex-row gap-5 justify-center mt-5">
        <Placeholder width="w-32" height="h-10" classNames="rounded-lg"/>
        <Placeholder width="w-32" height="h-10" classNames="rounded-lg"/>
      </div>
      <Placeholder width="w-full" height="h-12" classNames="rounded-lg"/>
      <Placeholder width="w-full" height="h-12" classNames="rounded-lg"/>
      <Placeholder width="w-full" height="h-12" classNames="rounded-lg"/>
    </div>
  )
}

export function BasicSessionPlaceholder() {
  return (
    <div className="flex flex-col gap-3 mb-5">
      <Placeholder width="w-full" height="h-12" classNames="rounded-lg"/>
      <Placeholder width="w-full" height="h-12" classNames="rounded-lg"/>
      <div className="flex flex-row gap-5 justify-center">
        <Placeholder width="w-32" height="h-10" classNames="rounded-lg"/>
        <Placeholder width="w-32" height="h-10" classNames="rounded-lg"/>
        <Placeholder width="w-32" height="h-10" classNames="rounded-lg"/>
      </div>
      {[...new Array(6)].map((_, i) =>
        <Placeholder key={i} height="h-24" classNames="rounded-lg bg-am-600"/>
      )}
    </div>
  )
}