import {useEffect, useState, useContext} from "react";
import {QuestionTagType} from "@components/RefMan/Quiz/types";
import {useIntl} from "react-intl";
import {NotificationsContext} from "@ui-components/Notifications";

export const useTagsFetch = () => {

    // hooks
    const intl = useIntl();

    // context
    const {push} = useContext(NotificationsContext);

    // state
    const [data, setData] = useState<QuestionTagType[]>();
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     (async () => {
    //         setLoading(true);
    //         try {
    //             const {data} = await api.get<QuestionTagType[]>('/quiz/tags');
    //             setData(data);
    //         } catch (e) {
    //             push({type: 'error', title: intl.formatMessage({id: 'server_error'}), message: 'QUESTION TAGS'});
    //         } finally {
    //             setLoading(false);
    //         }
    //     })();
    // }, [intl, push]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                setData([{cod_tag: 'foul'}, {cod_tag: 'point'}]);
            } catch (e) {
                push({type: 'error', title: intl.formatMessage({id: 'server_error'}), message: 'QUESTION TAGS'});
            } finally {
                setLoading(false);
            }
        })();
    }, [intl, push]);

    return {data, loading};
}