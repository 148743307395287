import {useContext, useEffect} from "react";

import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "../DataContext/DataContext";
import apiRequest from "../apiRequest";

const useMembersPics = () => {

  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    let closed = false;
    const fetchMembersPics = async () => {
      try {
        const data = await apiRequest.get("/all-images-urls");
        if (!closed) {
          dataContext.setPicsUrls(data);
        }
      } catch (error) {
        console.log(error)
        dataContext.setPicsUrls(false);
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if (dataContext.picsUrls === null) {
      fetchMembersPics();
    }

    return () => {
      closed = true;
    };
  }, [dataContext.picsUrls, push]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (!dataContext.picsUrls) {
    return undefined
  }
  return dataContext.picsUrls;
};

export default useMembersPics;
