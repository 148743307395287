import React, {useContext, useEffect, useState} from "react";

// Services
import AuthenticationService from "@services/AuthenticationService";
import {DataContext} from "@services/DataContext/DataContext";

// Ui
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import RadioGroup from "@ui-components/RadioGroup";
import Button from "@ui-components/Button";

// Components
import AddJobDialog from "./ManageJob/AddJobDialog";
import AssignDialog from "./Assign/AssignDialog";
import ManageJob from "./ManageJob";
import Assign from "./Assign";


export default function EntryJobs({push, group, jobs, members}) {
  const scopes = AuthenticationService.getUserScopes();
  const dataContext = useContext(DataContext);
  const [selectedView, setSelectedView] = useState('assign');
  const [showAssign, setShowAssign] = useState(false);
  const [showAddJob, setShowAddJob] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState();

  useEffect(() => {
    if (jobs) {
      setFilteredJobs(Object.values(jobs)
        .filter(i => i.cod_group === group.toUpperCase() || !i.cod_group)
      )
    }
  }, [jobs, group]);

  return (
    <>
      <div className="my-4 flex flex-col sm:flex-row justify-between items-center">
        <RadioGroup
          id="viewSelection"
          horizontal
          options={[
            {value: "assign", name: "Assegnazione"},
            {value: "job", name: "Gestione incarichi"},
          ]}
          currentValue={selectedView}
          onChange={(value) => {
            setSelectedView(value);
          }}
        />
        <div>
          {selectedView === 'assign' ?
            <Button
              styleType="secondary"
              onClick={() => setShowAssign(true)}
              disabled={!scopes.includes('role:update')}
            >
              <PlusCircleIcon className="-ml-0.5 mr-2 h-4 w-4"/>
              Assegna
            </Button> :
            <Button
              styleType="secondary"
              onClick={() => setShowAddJob(true)}
              disabled={!scopes.includes('role:update')}
            >
              <PlusCircleIcon className="-ml-0.5 mr-2 h-4 w-4"/>
              Aggiungi incarico
            </Button>
          }
        </div>
      </div>
      {selectedView === 'assign' ?
        <Assign
          group={group}
          push={push}
          filteredJobs={filteredJobs}
          members={members}
          scopes={scopes}
        /> :
        <ManageJob
          group={group}
          push={push}
          filteredJobs={filteredJobs}
          scopes={scopes}
        />
      }
      {scopes.indexOf(`role:update`) >= 0 ?
        <div>
          <AddJobDialog
            open={showAddJob}
            toggleOpen={() => setShowAddJob(false)}
            dataContext={dataContext}
            push={push}
          />
          <AssignDialog
            open={showAssign}
            toggleOpen={() => setShowAssign(false)}
            dataContext={dataContext}
            filteredJobs={filteredJobs}
            members={members}
            push={push}
          />
        </div>
        : null
      }
    </>
  );
}
