import React from "react";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";

interface QuizCommonResultsQuestionTooltipProps {
  is_correct: boolean;
  des_question: string | undefined;
  des_answers: string[];
}

export function QuizCommonResultsQuestionTooltip(
  {
    is_correct,
    des_question,
    des_answers
  }: QuizCommonResultsQuestionTooltipProps) {
  const basic = 'pointer-events-none absolute z-50 top-7 w-max opacity-0 transition-opacity group-hover:opacity-100'
  const custom = 'text-xs font-medium bg-gray-50 rounded-lg'

  return (
    <>
      <div className='group relative w-max cursor-pointer '>
        <span data-tooltip-target="tooltip-default">
          {is_correct ?
            <CheckCircleIcon className='stroke-green-500 h-5 w-5'/> :
            <XCircleIcon className='stroke-red-500 h-5 w-5'/>
          }
        </span>
        <span className={`${basic} ${custom} text-am-700 backdrop-blur-md`}>
          <b>{des_question}</b><br/>
          <h3>{des_answers}</h3>
        </span>
      </div>
    </>
  )
}
