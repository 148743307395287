import React from "react";
import {IntlShape} from "react-intl";
import {StatusBullet} from "@components/RefMan/StatusBullet";
import {RefManListItemType} from "@components/RefMan/RefManList/RefManList.type";
import {UserCircleIcon, ClockIcon, CalendarIcon, QuestionMarkCircleIcon} from "@heroicons/react/24/outline";
import {
  quiz_cod_type_finished,
  quiz_cod_type_in_progress,
  quiz_cod_type_not_started, quiz_cod_type_to_notify,
  QuizListBaseResponseType,
  QuizListPaginatedBaseResponseType
} from "@components/RefMan/Quiz/types";
import {UseHistoryType} from "@components/RefMan/NominationCard/NominationCard.type";
import {dateFormat} from "@pages/Planner/Absences/Absences.lib";

export const getQuizStatusColor = (cod_status: string): {
  primaryColor: string,
  backgroundColor: string
} => {
  switch (cod_status) {
    case quiz_cod_type_in_progress:
      return {primaryColor: 'bg-emerald-500', backgroundColor: 'bg-emerald-500/20'};
    case quiz_cod_type_not_started:
      return {primaryColor: 'bg-yellow-500', backgroundColor: 'bg-yellow-500/20'};
    case quiz_cod_type_finished:
      return {primaryColor: 'bg-red-500', backgroundColor: 'bg-red-500/20'};
    default:
      return {primaryColor: 'bg-gray-500', backgroundColor: 'bg-gray-500/20'};
  }
}

export const getQuizStatusDescription = (cod_status: string) => {
  switch (cod_status) {
    case quiz_cod_type_in_progress:
      return 'In corso';
    case quiz_cod_type_to_notify:
      return 'Da notificare';
    case quiz_cod_type_not_started:
      return 'Non iniziato';
    case quiz_cod_type_finished:
      return 'Terminato';
    default:
      return '';
  }
}

export const getListItems = (
  itemsList: QuizListPaginatedBaseResponseType,
  history: UseHistoryType,
  intl: IntlShape
): RefManListItemType[] => {

  return itemsList.items.map((quiz: QuizListBaseResponseType): RefManListItemType => {
    const {primaryColor, backgroundColor} = getQuizStatusColor(quiz.cod_status);
    return ({
      rowId: `${quiz.id}`,
      rowOnClick: () => history.push(`/technical-area/quiz/${quiz.id}`),
      rowElement:
        <>
          <div className="flex flex-col gap-y-2 pb-3 sm:pb-0">
            <p className="font-bold text-am-700">{quiz.des_title}</p>
            <div className={"flex flex-row gap-x-2"}>
              <StatusBullet primaryColor={primaryColor} backgroundColor={backgroundColor}/>
              <p className={"text-sm text-gray-500"}>{getQuizStatusDescription(quiz.cod_status)}</p>
            </div>
          </div>
          <div className="text-sm text-gray-500 flex flex-col gap-y-2 py-3 sm:py-0">
            <div className="flex flex-row gap-x-2 mr-2 items-center">
              <UserCircleIcon className="h-5 text-am-700"/>
              <p>
                Creato il <span className="font-semibold">{intl.formatDate(quiz.dat_creation, dateFormat)}</span><br/>
                da <span className="font-semibold">{quiz.member.member}</span>
              </p>
            </div>
            <div className="flex flex-row gap-x-2">
              <QuestionMarkCircleIcon className="h-5 text-am-700"/>
              <span className="font-semibold -mr-1">{quiz.num_questions ?? 0}</span>domande
            </div>
          </div>
          <div className="text-sm text-gray-500 flex flex-col gap-y-2 py-3 sm:py-0">
            <div className="flex flex-row gap-x-2 items-center whitespace-nowrap">
              <CalendarIcon className="h-5 text-am-700"/>
              {quiz.tms_deadline
                ? <div className="flex flex-col">
                  <span>Scadenza:</span>
                  <span>{intl.formatDate(quiz.tms_deadline, {...dateFormat, hour: '2-digit', minute: '2-digit'})}</span>
                </div> : 'Nessuna scadenza'}
            </div>
            <div className="flex flex-row gap-x-2">
              <ClockIcon className="h-5 text-am-700"/>
              {quiz.time_duration ? quiz.time_duration : 'Nessun limite di tempo'}
            </div>
          </div>
          <div className="text-xs text-gray-500 flex flex-col py-3 sm:py-0">
            <p className="text-sm font-semibold mb-1 text-am-700">
              Sessioni: {(quiz.num_to_notify_sessions ?? 0) + (quiz.num_not_started_sessions ?? 0) + (quiz.num_in_progress_sessions ?? 0) + (quiz.num_finished_sessions ?? 0)}
            </p>
            <div className="grid grid-cols-2">
              <div><span className="font-semibold mr-1">{quiz.num_to_notify_sessions ?? 0}</span>da notificare</div>
              <div><span className="font-semibold mr-1">{quiz.num_not_started_sessions ?? 0}</span>non iniziate</div>
              <div><span className="font-semibold mr-1">{quiz.num_in_progress_sessions ?? 0}</span>attive</div>
              <div><span className="font-semibold mr-1">{quiz.num_finished_sessions ?? 0}</span>chiuse</div>
            </div>
          </div>
          <div className="sm:grid-cols-4 py-3 sm:py-0">
            {quiz.tag_list.map((tag: string) => {
              return <span
                className="inline-flex items-center rounded-md bg-green-50 mx-2 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                {tag}
              </span>
            })}
          </div>
        </>
    })
  })
};