import React, {useContext} from "react";

import {useSession} from "@components/RefMan/Quiz/hooks/useSession";
import {NotificationsContext} from "@ui-components/Notifications";
import {useHistory} from "react-router-dom";

import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import {StartQuizSessionProps} from "@components/RefMan/Quiz/types";


export function StartSessionModal({onExit, idQuiz}: StartQuizSessionProps) {
  // context
  const {push} = useContext(NotificationsContext);

  const pushHistory = useHistory().push;

  const {activateSession} = useSession();

  return (
    <Modal
      onExit={onExit}
    >
      <div className="mb-5">
        <h1 className="font-bold sm:text-xl">Sei sicuro di iniziare il quiz?</h1>
        <h3 className="text-xs sm:text-sm my-3">Non sarà possibile annullare l'operazione.</h3>
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button onClick={() => {
          activateSession(idQuiz)
            .then(() => pushHistory(`/technical-area/quiz/session/${idQuiz}`))
            .catch(() => {
              push({title: 'Errore del server', type: 'error'});
            });
        }}>
          Conferma
        </Button>
      </div>
    </Modal>
  );
}
