import { capitalizeSentence } from './string';

/**
 * @function getDateFormatted
 *
 * @param {string} date Date of match to format
 * @returns {string} Date of match formatted
 */
export const getDateFormatted = (date: string): string =>
  capitalizeSentence(
    new Date(date).toLocaleDateString('it-IT', {
      weekday: 'short',
      year: '2-digit',
      month: 'short',
      day: 'numeric',
    })
  );
