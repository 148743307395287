import React, {useContext, useEffect, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

import {ArchiveBoxIcon, ArchiveBoxXMarkIcon, IdentificationIcon, CheckBadgeIcon} from "@heroicons/react/24/outline";
import EmploymentBarChart from "@pages/Nominations/Analytics/Sections/employmentBarChart";
import {getPayload, Kpi} from "@pages/Nominations/Analytics/Analytics.lib";
import Placeholder from "@ui-components/Placeholder";
import Alert from "@ui-components/Alert";


import {EmploymentData, EmploymentProps} from "@pages/Nominations/Analytics/Analytics.type";

export const AnalyticsEmployment = (
  {
    group,
    selectedCategories,
    selectedProvinces,
    selectedLevels,
    startDate,
    endDate
  }: EmploymentProps
) => {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const [data, setData] = useState<EmploymentData[] | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false);

  const acc = data ? data.reduce((acc, i) => acc + i.acc, 0) / data.length : 0
  const rif = data ? - data.reduce((acc, i) => acc + i.rif, 0) / data.length : 0
  const rev = data ? - data.reduce((acc, i) => acc + i.rev, 0) / data.length : 0

  useEffect(() => {
    setLoading(true)
    const payload = getPayload(
      group, selectedCategories, selectedProvinces, selectedLevels, startDate, endDate, []
    )
    api.post('/analytics/employment', payload)
      .then(({data}) => setData(data))
      .catch(() => {
        setData([])
        push({ title: 'Errore del server', type: 'error' })
      })
      .finally(() => setLoading(false))

  }, [group, selectedCategories, selectedProvinces, selectedLevels, startDate, endDate]);


  return (
    <div>
      {loading || !data ? <Placeholder height="h-80" classNames="rounded-lg"/> :
        data.length ? <div>
          <div className="grid grid-cols-2 sm:grid-cols-4 mb-5 justify-items-center gap-1">
            <Kpi
              intl={intl}
              title='Tesserati'
              Icon={IdentificationIcon}
              value={data.length}
              color="text-gray-700"
            />
            <Kpi
              intl={intl}
              title='Media rifiuti'
              Icon={ArchiveBoxXMarkIcon}
              value={Math.abs(rif)}
              perc={Math.abs(rif) / (acc + rif + rev)}
              color="text-red-700 text-opacity-80"
            />
            <Kpi
              intl={intl}
              title='Media revoche'
              Icon={ArchiveBoxIcon}
              value={Math.abs(rev)}
              perc={Math.abs(rev) / (acc + rif + rev)}
              color="text-yellow-600"
            />
            <Kpi
              intl={intl}
              title='Media gare'
              Icon={CheckBadgeIcon}
              value={acc}
              perc={acc / (acc + rif + rev)}
              color="text-am-700"
            />
          </div>
          <EmploymentBarChart
            id="employment"
            data={data.slice(0, 60)}
            intl={intl}
            group={group}
          />
          {data.length > 60 ? <div className="m-5 italic text-gray-600 text-xs">
            Sono presenti altri risultati, applica i filtri per visualizzare l'intera lista
          </div> : null}
        </div> :
          <Alert title="Nessun dato presente"/>
      }
    </div>
  )
}