import {getColor} from "@components/RefMan/NominationCard/NominationCard.lib";
import moment from "moment";

import {SelectedCategory, SelectedProvince} from "@pages/Members/Members.type";
import {Member} from "@services/types/member";
import {Absence, Activity, CalendarData, Nomination, Player} from "./Calendar.type";
import {AcademicCapIcon, ClockIcon, GlobeAltIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {getTimePeriodLabel} from "@pages/Planner/Absences/Absences.lib";
import {LKP_ACTIVITY_TYPES, LKP_ROLES} from "@utils/constants";
import React from "react";

export const getFilteredMembers = (
  members: Member[],
  selectedCategories: SelectedCategory[],
  selectedProvinces: SelectedProvince[],
  searchKey: string | undefined,
  data: CalendarData,
  startDate: string,
  endDate: string
) => {
  let tmp = members;
  if (selectedCategories.length) {
    tmp = tmp.filter(i => selectedCategories.map(c => c.value).includes(i.id_category))
  }
  if (searchKey && searchKey?.length >= 3) {
    tmp = tmp.filter(i => {
      const _key = i.member + String(i.id_fip_code) + i.city.city
      return _key.toLowerCase().includes(searchKey.toLowerCase())
    })
  }

  // Aggiungo le città duplicate in base alle sedi differenti nel periodo
  Object.entries(data.relocations).forEach(([k, v]) => {
    const orig = tmp.filter(i => i.id_fip_code === Number(k))[0]
    if (orig) {
      if (v.original_days.length === 0) {
        tmp = tmp.filter(i => i.id_fip_code !== Number(k))
      }
      tmp = tmp.concat(v.cities.map(c => ({...orig, city: c})))
    }
  })

  if (selectedProvinces.length) {
    tmp = tmp.filter(i => selectedProvinces.map(c => c.value).includes(i.city.des_province))
  }


  // remove members with all period absences
  tmp = tmp.filter(i => {
    return !data.absences[startDate] || !data.absences[startDate][i.id_fip_code]
      || !data.absences[endDate] || !data.absences[endDate][i.id_fip_code]
      || data.absences[startDate][i.id_fip_code][0].des_reason_type !== 'Aspettativa'
      || data.absences[endDate][i.id_fip_code][0].des_reason_type !== 'Aspettativa'
  })

  return tmp.sort((a, b) => {
    const compareCategory = a.category.sub_cat_order - b.category.sub_cat_order
    const compareName = a.member.localeCompare(b.member)
    const compareProvince = a.city.cod_province.localeCompare(b.city.cod_province)
    return compareCategory || compareName || compareProvince
  })
}

export const getDatesBetween = (startDate: Date, endDate: Date) => {
  let dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};


export const getDateStr = (date: Date) => {
  return moment(date).format("YYYY-MM-DD")
}


const basic = 'pointer-events-none absolute z-50 top-7 -left-36 w-max opacity-0 transition-opacity group-hover:opacity-100'
const custom = 'py-2 px-3 text-xs font-medium bg-gray-50 rounded-lg'
export const nominationTooltip = (nomination: Nomination) => {
  const color = getColor(nomination.status)
  return (
    <div className={`${color} font-bold border py-0.5 px-1 rounded-full text-xs`} key={nomination.game_number}>
      <div className="group relative w-max cursor-pointer">
        <span>{nomination.cod_level}</span>
        <span className={`${basic} ${custom} ${color}`}
        >
          <i>{nomination.status}</i> - <b>{nomination.cod_level}</b> - Gara <b>{nomination.game_number}</b>
          {nomination.time_game ? ' - Ore ' + nomination.time_game : ''}
          <br/>
          {nomination.role ? <><small><b>{LKP_ROLES[nomination.role]}</b></small><br/></> : null}
          <small>{nomination.team_a} <i>vs</i> {nomination.team_b}</small><br/>
          {nomination.city ? `${nomination.city} (${nomination.cod_province})` : null}
        </span>
      </div>
    </div>
  )
}

export const activityTooltip = (activity: Activity) => {
  return (
    <div className="font-bold border py-0.5 px-1 rounded-full text-xs" key={activity.id_fip_code}>
      <div className="group relative w-max cursor-pointer">
        <span><AcademicCapIcon className="h-4 w-4"/></span>
        <span className={`${basic} ${custom}`}
        >
          <b>{LKP_ACTIVITY_TYPES[activity.activity_type]}</b>
          <span className="mx-1.5">&#8226;</span>
          Dalle {activity.time_start} alle {activity.time_end}
          <br/>
          <small>{activity.name}</small>
        </span>
      </div>
    </div>
  )
}

export const playerTooltip = (player: Player) => {
  return (
    <div className="font-bold border py-0.5 px-1 rounded-full text-xs" key={player.game_number}>
      <div className="group relative w-max cursor-pointer">
        <span><GlobeAltIcon className="h-4 w-4"/></span>
        <span className={`${basic} ${custom}`}
        >
          Gioca in <b>{player.cod_level}</b> - Gara <b>{player.game_number}</b>
          {player.time_game ? ' - Ore ' + player.time_game : ''}
          <br/>
          <small>{player.team_a} <i>vs</i> {player.team_b}</small><br/>
          {player.city ? `${player.city} (${player.cod_province})` : null}
        </span>
      </div>
    </div>
  )
}

export const absenceTooltip = (absence: Absence) => {
  // const isTime = absence.des_reason_type === 'Disponibilità oraria'
  const timePeriod = getTimePeriodLabel(absence.des_reason_type, absence.time_from, absence.time_to)
  // const color = getColor(nomination.status)
  return (
    <div className="" key={absence.des_absence}>
      <div className="group relative w-max cursor-pointer">
        <span>
          {timePeriod.length
            ? <ClockIcon className="h-4 w-4 text-yellow-600"/>
            : <XCircleIcon className="h-4 w-4 text-red-600"/>
          }
        </span>
        <span className={`${basic} ${custom}`}>
          <b>{absence.des_reason_type}</b> {timePeriod.length ? timePeriod.join(' ') : ''}<br/>
          <small><i>{absence.des_absence}</i></small>
        </span>
      </div>
    </div>
  )
}