import React from "react";
import {RefManListItemType} from "@components/RefMan/RefManList/RefManList.type";
import {Player} from "@pages/Planner/Players/Players.type";
import {TrashIcon} from "@heroicons/react/24/outline";
import {LKP_GROUP} from "@utils/constants";


export const getListItems = (
  items: Player[],
  isMember: boolean,
  setDeletePlayer: React.Dispatch<React.SetStateAction<Player | undefined>>
): RefManListItemType[] | [] => {

  return items.map((player: Player, index): RefManListItemType => {

    return ({
      rowId: `game-${index}`,
      rowElement: <>
        {isMember ?
          <dt className="capitalize text-gray-900 flex flex-col sm:col-span-2 mb-3 sm:mb-0">
            <span>Campionato</span>
            <span className="text-2xl font-bold text-am-700">{player.team.cod_level}</span>
          </dt> :
          <dt className="capitalize text-gray-900 flex flex-col sm:col-span-2 mb-3 sm:mb-0">
            <span className="text-sm">{LKP_GROUP[player.member.category.cod_group]} - {player.member.category.des_sub_category}</span>
            <span className="text-xs"><b>{player.id_fip_code}</b>, {player.member.city.city} ({player.member.city.cod_province})</span>
            <span className="font-bold text-am-700">{player.member.member}</span>
          </dt>
        }
        {isMember ?
          <dt className="capitalize text-gray-900 flex flex-col sm:col-span-2 mb-3 sm:mb-0">
            <span>Squadra</span>
            <span className="text-xl font-bold text-am-700">{player.team.team_alias}</span>
          </dt> :
          <dt className="flex flex-col justify-center sm:col-span-2 mb-3 sm:mb-0">
            <span>Campionato <b>{player.team.cod_level}</b></span>
            <span className="font-bold text-am-700">{player.team.team_alias}</span>
          </dt>
        }
        <dt className="flex items-center sm:justify-center">
          <div className="cursor-pointer flex flex-row gap-2 hover:underline hover:font-bold" onClick={() => setDeletePlayer(player)}>
            <TrashIcon className="w-5 h-5"/> Elimina
          </div>
        </dt>
      </>
    })
  });
}