import React, {useState, useRef, useMemo, useEffect} from "react";

import createMentionPlugin, {defaultSuggestionsFilter, MentionData} from '@draft-js-plugins/mention';
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import '@draft-js-plugins/mention/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import Editor from "@draft-js-plugins/editor";

import {EDITOR_CONTROLS, EDITOR_CONTROLS_INIT, DEFAULT_NOTE, getControl, newNoteWithMention} from "./Message.lib";
import {MessagePropsType, Thread} from "@pages/Threads/Threads.type";

import {apiRequest} from "@services/apiRequest";
import Button from "@ui-components/Button";


export function Message(
  {
    push,
    idThread,
    setRefresh,
    editMessage,
    creator,
    recipients,
    onExit,
    readOnly,
    newMention
  }: MessagePropsType
) {

  const allUsersInvolved = [{id_member: creator.id_fip_code, member: {member: creator.member, mail: creator.mail}}]
    .concat(recipients)

  const [editorModifiers, setEditorModifiers] = useState(EDITOR_CONTROLS_INIT);
  const [current, setCurrent] = useState(editMessage
    ? {
      id: editMessage.id,
      content: EditorState.createWithContent(convertFromRaw(editMessage.val_content)),
      mentions: editMessage.lst_mentions
    } : newMention ? newNoteWithMention(newMention) : DEFAULT_NOTE
  );

  const [userSuggestionsOpen, setUserSuggestionsOpen] = useState<boolean>(false);
  const [mentionsSuggestions, setMentionsSuggestions] = useState<MentionData[]>(allUsersInvolved.map(i => ({
    id: i.id_member,
    name: i.member.member
  })));
  const [submitting, setSubmitting] = useState<boolean>(false);
  const editor = useRef(null);

  const handleKeyPress = ({ctrlKey, metaKey, key}: any) => {
    // exit if not editing a note or CTRL/CMD key is not pressed
    if (!current || !(ctrlKey || metaKey))
      return;
    // determine operation to be executed based on the key pressed with CTRL/CMD
    let control = getControl(key);
    if (!control) {
      return;
    }
    // @ts-ignore
    setEditorModifiers((prev) => ({...prev, [control]: !prev[control]}))
    // @ts-ignore
    setCurrent((prev: any) => ({...prev, content: EDITOR_CONTROLS[control].onClick(prev.content)}));
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const isCurrentEmpty = !current?.content.getCurrentContent().hasText();
  const {MentionSuggestions, plugins} = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionComponent: ({children}: any) => (
        <span className="cursor-default bg-blue-100 text-gray-600 px-0.5">
          {children}
        </span>
      ),
    });
    // eslint-disable-next-line no-shadow
    const {MentionSuggestions} = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return {plugins, MentionSuggestions};
  }, []);

  const sendEmailNotificationToUsers = async (messageId: number, recipients: number[]) => {
    await apiRequest.post(`threads/notify-mentions`, {
      id_thread: idThread,
      id_message: messageId,
      recipients: recipients
    });
  }

  const saveCurrentMessage = async () => {
    if (!current || isCurrentEmpty)
      return;

    // extract mentions from content
    let rawEditorContent, currentMentions: any, currentMentionsIds
    try {
      rawEditorContent = convertToRaw(current.content.getCurrentContent());
      currentMentions = Object.values(rawEditorContent.entityMap).reduce(
        (prev, {data: {mention}}) => ({...prev, [mention.id]: mention.name}),
        {}
      );
      currentMentionsIds = Object.keys(currentMentions).map((sid) => parseInt(sid));
    } catch (e) {
      push({type: 'error', title: "Errore imprevisto"})
      console.log(e);
      return;
    }

    setSubmitting(true);
    try {
      let messageId = current.id;
      const flgSendNotification = Boolean(!messageId)
      if (messageId) {
        // --- CASE update existing note
        await apiRequest.put(`/threads/message/${messageId}`, {
          val_content: rawEditorContent,
          lst_mentions: currentMentionsIds
        });
      } else {
        // --- CASE New Message
        const result: Thread = await apiRequest.post(`/threads/message`, {
          id_thread: idThread,
          val_content: rawEditorContent,
          lst_mentions: currentMentionsIds,
        });
        messageId = result.id
      }
      push({type: 'success', title: "Messaggio salvato"});
      setRefresh((prev) => !prev)
      onExit();

      // --- send email notification (not in case of edit) and only if users already sees the communication
      const notifiableIds = recipients.filter(i => i.tms_sent).map(i => i.id_member).concat([creator.id_fip_code])
      const idToNotify = currentMentionsIds.filter(i => notifiableIds.includes(i))
      if (flgSendNotification && idToNotify.length > 0) {
        await sendEmailNotificationToUsers(messageId, idToNotify);
      }
    } catch (err) {
      push({type: 'error', title: "Errore imprevisto"})
      console.log(err);
      return;
    } finally {
      setSubmitting(false);
    }
  }


  // @ts-ignore
  return <div
    className={`${readOnly ? '' : 'bg-am-600 bg-opacity-20 border border-gray-100 rounded-md shadow my-5 py-2 px-3'} overflow-hidden flex-shrink-0`}>
    {readOnly ? null : <p className="font-semibold">
      {editMessage ? 'Modifica messaggio' : 'Nuovo messaggio'}
    </p>
    }
    <div className="my-2">
      {readOnly ? null : <div className="flex items-center gap-2 border-b pb-1 mb-3">
        <div className="text-xs text-gray-500">
          Formattazione:
        </div>
        {
          Object.entries(editorModifiers).map(([key, active]) => (
            <div
              key={key}
              className={`cursor-pointer w-3 text-center ${active ? 'text-am-800 hover:text-gray-700' : 'text-gray-400 hover:text-gray-600'}`}
              onMouseDown={(e) => {
                e.preventDefault();
                if (key !== 'mention')
                  // @ts-ignore
                  setEditorModifiers((prev) => ({...prev, [key]: !prev[key]}))
                // @ts-ignore
                setCurrent((prev: any) => ({...prev, content: EDITOR_CONTROLS[key].onClick(prev.content)}
                ));
              }}
            >
              {/*@ts-ignore*/}
              {EDITOR_CONTROLS[key].component}
            </div>
          ))
        }
      </div>
      }
      <Editor
        ref={editor}
        editorKey={'editor'}
        editorState={current.content}
        plugins={plugins}
        onChange={(state) => {
          setCurrent((prev: any) => ({...prev, content: state}));
        }}
        placeholder="Inserisci qui il testo"
        readOnly={readOnly}
      />
      <MentionSuggestions
        open={userSuggestionsOpen}
        onOpenChange={(open) => {
          setUserSuggestionsOpen(open);
        }}
        suggestions={mentionsSuggestions}
        onSearchChange={({value}) => {
          setMentionsSuggestions(defaultSuggestionsFilter(value, allUsersInvolved.map(i => ({
            id: i.id_member,
            name: i.member.member
          }))));
        }}
      />
    </div>
    {readOnly ? null : <div className="flex gap-2 items-center justify-end mb-1">
      <Button
        //@ts-ignore
        type="button"
        styleType="empty"
        onClick={() => {
          onExit()
        }}
      >
        <span className="text-xs">Cancella</span>
      </Button>
      <Button
        //@ts-ignore
        type="button"
        onClick={async () => {
          await saveCurrentMessage();
        }}
        disabled={!current || isCurrentEmpty}
        submitting={submitting}
      >
        <span className="text-xs">Salva</span>
      </Button>
    </div>
    }
  </div>;
}
