import React, {useEffect, useState} from "react";

// Services
import AuthenticationService from "@services/AuthenticationService";
import {getMemberOptions} from "@utils/index";

// Hooks
import useAbsences from "@services/hooks/useAbsences";

// Components
import {AbsenceFilterAndAddButton, AbsencePlaceHolder} from "@pages/Planner/Absences/Absences.lib";
import DeleteAbsenceDialog from "@pages/Planner/Absences/DeleteAbsenceDialog";
import {AbsenceCard} from "./AbsenceCard";

// Ui
import AddUpdateAbsenceDialog from "./AddUpdateAbsenceDialog";
import SearchSelect from "@ui-components/SearchSelect";
import Alert from "@ui-components/Alert";

// Types
import {Absence} from "@services/types/absence";
import {SingleAbsencePropsType} from "@pages/Planner/Absences/Absences.type";


export function SingleAbsences(
  {
    roles,
    members,
    isMember,
    userData,
    reasonTypes,
    membersByUserId
  }: SingleAbsencePropsType) {

  const idFipCode = isMember ? AuthenticationService.getUserFipCode() : undefined

  const memberOptions = getMemberOptions(members)
  const initValue = isMember ? {value: idFipCode, data: {id_user: userData.id}} : idFipCode
    ? memberOptions.filter((i: { value: any; }) => i.value === idFipCode)[0] : undefined

  const [selectedMember, setSelectedMember] = useState(initValue)
  const absences: Absence[] = useAbsences({idFipCode: selectedMember?.value})

  const [selectedTypes, setSelectedTypes] = useState<{ label: string, value: number }[]>([])
  const [updateAbsence, setUpdateAbsence] = useState<Absence | undefined>()
  const [deleteAbsence, setDeleteAbsence] = useState<Absence | undefined>()
  const [data, setData] = useState<Absence[] | undefined>()
  const [showAdd, setShowAdd] = useState(false)

  useEffect(() => {
    if (reasonTypes && absences) {
      setData(selectedTypes.length > 0
        ? absences.filter(i => selectedTypes.map(t => t.value).includes(i.absence_reason.reason_type.id))
        : absences
      )
    }
  }, [reasonTypes, absences, selectedTypes])

  return (
    <>
      {isMember || !memberOptions ? null :
        <div className="my-5 sm:my-8">
          {/*@ts-ignore*/}
          <SearchSelect
            label="Tesserato"
            minHeight={50}
            value={selectedMember}
            onChange={(e: any) => setSelectedMember(e)}
            options={memberOptions}
            filterOption={(candidate: { data: { data: any; }; }, input: string) => {
              if (input) {
                const _data = candidate.data.data
                const _label = _data.id_fip_code + _data.member
                return _label.toUpperCase().includes(input.toUpperCase())
              }
              return true
            }}
          />
        </div>
      }
      {selectedMember ? <>
        {data && membersByUserId ?
          <div className="flex flex-col gap-3">
            <AbsenceFilterAndAddButton
              selectedTypes={selectedTypes}
              setSelectedTypes={setSelectedTypes}
              reasonTypes={reasonTypes}
              setShowAdd={setShowAdd}
            />
            <div>
              {data.length ?
                <div className="p-1 w-full flex flex-col divide-y divide-gray-300 h-3/4 overflow-y-scroll mb-10">
                  {data.map(i =>
                    <div key={i.id_absence_reason}>
                      <AbsenceCard
                        absence={i}
                        isMember={isMember}
                        currentUserId={selectedMember?.data?.id_user}
                        membersByUserId={membersByUserId}
                        setUpdateAbsence={setUpdateAbsence}
                        setDeleteAbsence={setDeleteAbsence}
                        roles={roles}
                      />
                    </div>
                  )}
                </div> :
                <div className="my-10 sm:mx-10">
                  <Alert title="Non sono presenti indisponibilità registrate"/>
                </div>
              }
            </div>
          </div>
          : <AbsencePlaceHolder/>
        }
      </> : null}
      {selectedMember && (showAdd || updateAbsence) ? <div>
        <AddUpdateAbsenceDialog
          onExit={() => {
            setShowAdd(false)
            setUpdateAbsence(undefined)
          }}
          updateAbsence={updateAbsence}
          reasonTypes={reasonTypes}
          idFipCode={selectedMember.value}
          currentUserId={userData.id}
          isMember={isMember}
        />
      </div> : null}
      {deleteAbsence ?
        <DeleteAbsenceDialog
          onExit={() => setDeleteAbsence(undefined)}
          deleteAbsence={deleteAbsence}
        /> : null
      }
    </>
  );
}
