import {useEffect, useState} from "react";
import {CROP_ASPECT_RATIO} from "@utils/constants";
import {apiRequest} from "@services/apiRequest";


const PrivateImage = ({
                        image_url,
                        className,
                        tag,
                        altReturn = null,
                        waitingContent = null,
                        onRetrievedSignedURL = () => {},
                        fixedAspectRatio = true,
                        ...rest
                      }) => {

  const [signedImageUrl, setSignedImageUrl] = useState(undefined);

  useEffect(() => {
    const fetchSignedUrl = async () => {
      if (!image_url) {
        return;
      }

      try {
        const url = await apiRequest.post(`/aws-signed-url`, {
          content_type: '',
          format: '',
          prefix: image_url.split(".com/").pop(),
          method: 'GET'
        });

        setSignedImageUrl(url)
        onRetrievedSignedURL(url)
      } catch (error) {
        console.log("errore", error);
        setSignedImageUrl('missing')
      }
    }
    fetchSignedUrl();
    //  eslint-disable-next-line
  }, [image_url]);


  if (!signedImageUrl) {
    return waitingContent;
  }

  if (signedImageUrl === 'missing') {
    return altReturn
  }

  if (tag === 'div') {
    return (
      <div
        className={className}
        style={{
          backgroundImage: `url(${signedImageUrl})`,
          ...(fixedAspectRatio ? {aspectRatio: CROP_ASPECT_RATIO.toString(), height: 'auto'} : {})
        }}
        {...rest}
      />
    )
  } else if (tag === 'img') {
    return (
      <img
        src={signedImageUrl}
        alt={signedImageUrl}
        style={{
          maxWidth: "100%",
          height: "auto",
          ...(fixedAspectRatio ? {aspectRatio: CROP_ASPECT_RATIO.toString()} : {})
        }}
        {...rest}
      />
    )
  }
}
export default PrivateImage;