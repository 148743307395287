import React, {useContext, useState} from 'react';

// Services
import AuthenticationService from "@services/AuthenticationService";
import {DataContext} from "@services/DataContext/DataContext";

// Data
import useCategories from "@services/hooks/useCategories";
import useMembers from '@services/hooks/useMembers';
import useLevels from '@services/hooks/useLevels';

// Components
import {CategoryFilter, getFilteredData, MemberSearch, ProvinceFilter} from '@pages/Members/Members.lib';
import {getListItems, UpdateMemberParameterDialog} from "@pages/Nominations/Parameters/Parameters.lib";
import RefManList from '@components/RefMan/RefManList/RefManList';
import {MembersPlaceholder} from "@utils/placeholders";
import GroupSelection from "@utils/groupSelection";

// Ui
import {PageHeading} from '@ui-components/Container';
import {Member} from "@services/types/member";
import {LevelSchema} from "@services/types/level";

// Types

export function NominationsParameters() {
  const dataContext: any = useContext(DataContext);
  const groups = AuthenticationService.getUserGroups();

  const levels = useLevels({onlyManaged: true, asObj: false});
  const categories = useCategories({group: dataContext.group, asObj: false, onlyAssignable: true});
  const members = useMembers({
    group: dataContext.group,
    onlyActive: true,
    assigned: true,
    asObj: false,
    onlyAssignable: true
  });

  const [selectedCategories, setSelectedCategories] = useState<{ data: any; label: any; value: number }[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<{ label: string; value: string }[]>([]);
  const [searchKey, setSearchKey] = useState<string>();
  const [offset, setOffset] = useState<number>(0);
  const limit = 10;

  const [selectedMember, setSelectedMember] = useState<Member | undefined>(undefined);

  const filteredData = getFilteredData(members, selectedCategories, selectedProvinces, searchKey);

  return (
    <>
      <PageHeading
        title="Parametri designazioni"
        historyBackPath='_back'
        contentActions={groups.length > 1 ?
          <div className="mr-6 -mt-4 sm:mt-2">
            <GroupSelection selectedGroup={dataContext.group} setSelectedGroup={dataContext.setGroup}/>
          </div> : null
        }
      />
      {filteredData && categories ? (
        <div className='flex flex-col gap-3'>
          <div className='flex flex-col sm:flex-row justify-between sm:gap-6'>
            <div className='w-full sm:w-1/3'>
              <CategoryFilter categories={categories} selectedCategories={selectedCategories}
                              setSelectedCategories={setSelectedCategories}/>
            </div>
            <div className='w-full sm:w-1/3'>
              <ProvinceFilter members={members} selectedProvinces={selectedProvinces}
                              setSelectedProvinces={setSelectedProvinces}/>
            </div>
            <div className='w-full sm:w-1/3 flex flex-row items-center gap-4'>
              <div className='w-full'>
                <MemberSearch label='Cerca' searchKey={searchKey} setSearchKey={setSearchKey}/>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <RefManList
              limit={limit}
              offset={offset}
              setOffset={setOffset}
              containerId='members-parameters-list'
              items={getListItems(filteredData, dataContext.group, setSelectedMember)}
              total={filteredData.length}
              rowClassNames={['sm:grid', 'sm:gap-2', 'items-center', 'sm:grid-cols-4']}
              pagination='frontend'
            />
          </div>
          {selectedMember ?
            <UpdateMemberParameterDialog
              member={selectedMember}
              dataContext={dataContext}
              setSelectedMember={setSelectedMember}
              levelOptions={levels.filter((i: LevelSchema) => dataContext.group === 'ref' ? i.survey_model_ref : i.survey_model_udc)}
            />
            : null
          }
        </div>
      ) : <MembersPlaceholder/>}
    </>
  );
}
