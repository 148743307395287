import {FormatDateOptions} from "react-intl";


export const dateFormat: FormatDateOptions = {
  weekday: "short",
  day: "numeric",
  month: "short",
  year: "2-digit",
  hour: "numeric",
  minute: "numeric"
}
