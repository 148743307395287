import React, {useContext, useEffect, useState} from "react";

// Services
import {DataContext} from "@services/DataContext/DataContext";
import useCategories from "@services/hooks/useCategories";
import useMembers from "@services/hooks/useMembers";

// Ui
import {MagnifyingGlassIcon, PlusCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import CheckboxGroup from "@ui-components/CheckboxGroup";
import Input from "@ui-components/Input";
import Alert from "@ui-components/Alert";

import {CategoryFilter, ProvinceFilter} from "@pages/Members/Members.lib";
import GroupSelection from "@utils/groupSelection";

import {AssignListMembersPropsType} from "./AssignListMembers.type";
import {Member} from "@services/types/member";


export function AssignListMembers({membersList, setMembersList, description}: AssignListMembersPropsType) {

  const dataContext: any = useContext(DataContext);

  // @ts-ignore
  const members = useMembers({
    group: dataContext.group,
    excludeManager: false,
    asObj: false
  });
  // @ts-ignore
  const categories = useCategories({
    group: dataContext.group,
    excludeManager: false,
    asObj: false
  });

  const [selectedCategories, setSelectedCategories] = useState<{ data: any, label: any, value: number }[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<{ label: string, value: string }[]>([]);
  const [searchKey, setSearchKey] = useState<string>();
  const [options, setOptions] = useState<{ value: number; name: any }[]>([]);

  useEffect(() => {
    if (members) {
      let tmp = members.sort((a: Member, b: Member) => a.member.localeCompare(b.member))
      if (selectedCategories.length) {
        tmp = tmp.filter((i: Member) => selectedCategories.map(c => c.value).includes(i.id_category))
      }
      if (selectedProvinces.length) {
        tmp = tmp.filter((i: Member) => selectedProvinces.map(c => c.value).includes(i.city.des_province))
      }
      if (searchKey && searchKey?.length >= 3) {
        tmp = tmp.filter((i: Member) => {
          const _key = i.member + String(i.id_fip_code) + i.city.city + i.mail + String(i.phone)
          return _key.toLowerCase().includes(searchKey.toLowerCase())
        })
      }
      setOptions(tmp.map((i: Member) => ({
        value: i.id_fip_code,
        name: <div className="flex flex-col sm:flex-row sm:gap-2 sm:items-end">
          <span>{i.id_fip_code} - <b>{i.member}</b>,</span>
          <span className="italic text-xs">{i.category.des_sub_category} ({i.city.des_province})</span>
        </div>
      })))
    }

  }, [dataContext.group, selectedCategories, selectedProvinces, searchKey]) // eslint-disable-line react-hooks/exhaustive-deps

  const currentSelection = membersList.filter(i => options.map(x => x.value).includes(i)).length

  return (
    <div>
      {members && categories ?
        <div className="my-5 flex flex-col">
          <div className="flex flex-col sm:flex-row justify-between items-start mt-2">
            {description}
            <div className="mr-5">
              {/*@ts-ignore*/}
              <GroupSelection selectedGroup={dataContext.group} setSelectedGroup={dataContext.setGroup}/>
            </div>
          </div>
          <div className="mt-4 flex flex-col sm:flex-row justify-between">
            <div className="w-full sm:w-1/3">
              <CategoryFilter
                categories={categories}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
              />
            </div>
            <div className="w-full sm:w-1/3">
              <ProvinceFilter
                members={members}
                selectedProvinces={selectedProvinces}
                setSelectedProvinces={setSelectedProvinces}
              />
            </div>
            <div className="w-full sm:w-1/4">
              {/*@ts-ignore*/}
              <Input
                value={searchKey}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchKey(e.target.value)
                }}
                label={
                  <div className="flex flex-row gap-2">
                    <MagnifyingGlassIcon className="h-4 w-4 mt-0.5 -mb-3"/>
                    <span>Cerca</span>
                  </div>
                }
              />
            </div>
          </div>
          {options.length ?
            <div className="flex flex-col">
              <div className="w-full text-center font-bold text-gray-400 my-2">
                Tesserati inseriti:
                <span className="font-xl text-gray-900 ml-1">{currentSelection} </span> su
                <span className="font-xl text-gray-900"> {options.length}</span>
              </div>
              <div className="w-full mb-2 flex flex-col items-center h-96 overflow-y-scroll shadow-lg rounded-lg">
                {currentSelection === options.length ?
                  <div
                    className="my-5 text-am-700 cursor-pointer font-medium hover:font-bold"
                    onClick={() => setMembersList(membersList.filter(i => !options.map(x => x.value).includes(i)))}
                  >
                    <span><XCircleIcon className="h-5 w-5 inline-block -mt-0.5"/> Rimuovi tutti</span>
                  </div> :
                  <div
                    className="my-5 text-am-700 cursor-pointer font-medium hover:font-bold"
                    onClick={() =>
                      setMembersList(Array.from(new Set([...membersList, ...options.map(i => i.value)])))
                    }
                  >
                    <span><PlusCircleIcon className="h-5 w-5 inline-block -mt-0.5"/> Aggiungi tutti</span>
                  </div>
                }
                {/*@ts-ignore*/}
                <CheckboxGroup
                  options={options}
                  values={membersList}
                  onChange={(value: number) => {
                    if (membersList.indexOf(value) >= 0) {
                      setMembersList([...membersList.filter((v) => v !== value)]);
                    } else {
                      setMembersList([...membersList, value]);
                    }
                  }}
                />
              </div>
            </div>
            : <div className="my-10 sm:mx-10">
              <Alert title="Nessun tesserato tra i filtri selezionati"/>
            </div>
          }
        </div>
        : null
      }
    </div>
  );
}
