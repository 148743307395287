import React, {useCallback, useContext, useState} from "react";

// Services
import {api} from "@services/apiRequest";
import {NotificationsContext} from "../../Notifications";
import {FormattedMessage, useIntl} from "react-intl";

// UI
import Input from "../../Input";
import Select from "../../Select";
import Modal from "../../Modal";
import Button from "../../Button";
import InputMultiLine from "../../InputMultiLine";
// import FilePicker from "../../FilePicker";
import AuthenticationService from "../../../services/AuthenticationService";


function MapLanguageKey(intl) {
  const options_category = [
    {
      value: "SERVICE_NOT_AVAILABLE",
      name: intl.formatMessage({id: "category_one"}),
      map: "infrastructural",
    },
    {
      value: "UNEXPECTED_RESULTS",
      name: intl.formatMessage({id: "category_three"}),
      map: "algorithmic",
    },
    {
      value: "SYSTEM_BREAK",
      name: intl.formatMessage({id: "category_four"}),
      map: "infrastructural",
    },
    {
      value: "OTHER",
      name: intl.formatMessage({id: "category_five"}),
      map: "software",
    },
  ];
  const options_risk = [
    {value: "LOW", name: intl.formatMessage({id: "risk_one"})},
    {value: "MEDIUM", name: intl.formatMessage({id: "risk_two"})},
    {value: "HIGH", name: intl.formatMessage({id: "risk_three"})},
    {value: "PROPOSAL", name: intl.formatMessage({id: "risk_four"})},
  ];
  return {options_category, options_risk};
}

function DialogTicket({opened, toggleOpen, setTicketList, setShowSuccessResponse}) {
  const userData = AuthenticationService.getUserData();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {push} = useContext(NotificationsContext);
  const [row, setRow] = useState({
    category: undefined,
    priority: undefined,
    subject: undefined,
    content: undefined,
    user_id: userData.id,
    files: undefined,
    fileSizeExceeded: false
  });
  const option = MapLanguageKey(intl);

  function cleanRow() {
    setRow({
      category: undefined,
      priority: undefined,
      subject: undefined,
      content: undefined,
    user_id: userData.id,
      files: undefined,
      fileSizeExceeded: false
    });
  }

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (
          !row.category ||
          !row.priority ||
          !row.subject ||
          !row.content
        ) {
          push({
            title: "Dati mancanti",
            type: "error",
          });
          return;
        }

        if (row.user_email === "") {
          push({
            title: "missing_sender_mail",
            type: "error"
          });
          return;
        }

        setLoading(true);
        let formData = new FormData();

        if (row.files && !row.fileSizeExceeded) {
          row.files.forEach(elem => {
            formData.append("files", elem, elem.name);
          })
        }

        Object.keys(row).forEach((key) => {
          if (key !== 'files')
            formData.append(key, row[key]);
        });

        // eslint-disable-next-line
        api
          .post("/tickets", formData)
          .then(() => {
            setLoading(false);
            toggleOpen();
            setTicketList(undefined);
            setShowSuccessResponse(true);
            cleanRow();
          })
          .catch((error) => {
            console.log(error)
            push({title: "Errore imprevisto", type: "error"})
            setLoading(false);
          });

      } catch (error) {
        console.log(error)
        push({title: "Errore imprevisto", type: "error"});
      }
    },
    [row] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div>
      <Modal opened={opened} onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            <FormattedMessage id="modal_title"/>
          </h3>
        </div>

        <form id="new-ticket-form" onSubmit={_onSubmit} className="flex flex-col gap-y-5">
          <div>
            <Select
              customStyles={{borderRadius: '6px'}}
              options={[
                {value: "problemType", name: intl.formatMessage({id: "select"})},
              ].concat(option.options_category)}
              value={row.category}
              onChange={(e) => {
                setRow({...row, category: e.target.value});
              }}
              label={intl.formatMessage({id: "type_problem"})}
              errorMessage="Seleziona un'opzione dal menu a tendina."
              required
            />
          </div>
          <div>
            <Select
              customStyles={{borderRadius: '6px'}}
              options={[
                {value: "problemDescription", name: intl.formatMessage({id: "select"})},
              ].concat(option.options_risk)}
              value={row.priority}
              onChange={(e) =>
                setRow({...row, priority: e.target.value})
              }
              label={intl.formatMessage({id: "measure_problem"})}
              errorMessage={intl.formatMessage({id: "select_an_option"})}
              required
            />
          </div>
          <div>
            <Input
              value={row.subject}
              onChange={(e) => {
                setRow({...row, subject: e.target.value});
              }}
              label={intl.formatMessage({id: "subject_problem"})}
              type="text"
              errorMessage={intl.formatMessage({id: "error_message"})}
              required
            />
          </div>
          <div className="-mt-3">
            <InputMultiLine
              rows={5}
              value={row.content}
              onChange={(e) => {
                setRow({...row, content: e.target.value});
              }}
              label={intl.formatMessage({id: "describe_problem"})}
              type="text"
              errorMessage={intl.formatMessage({id: "error_message"})}
              required
            />
          </div>
          {/*<div className="-mt-2">*/}
          {/*  <FilePicker*/}
          {/*    fileChosen={row.files}*/}
          {/*    setFileChosen={values => setRow({...row, files: values})}*/}
          {/*    maxSize={1}*/}
          {/*    alertSizeExceeded={bool => setRow({...row, fileSizeExceeded: bool})}*/}
          {/*    labelCustomStyle={{color: 'black'}}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="flex items-end justify-end">
            <Button submitting={loading} type="submit">
              <FormattedMessage id="send_problem"/>
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default DialogTicket;
