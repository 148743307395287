import React, {useEffect, useState} from "react";

// Services
import AuthenticationService from "@services/AuthenticationService";
import useRoles from "@ui-components/pages/Users/hooks/useRoles";
import useReasonTypes from "@services/hooks/useReasonTypes";
import useMembers from "@services/hooks/useMembers";

// Components
import {SingleAbsences} from "./SingeAbsences";
import {GroupAbsences} from "./GroupAbsences";

// Ui
import {PageHeading} from "@ui-components/Container";
import Tabs from "@ui-components/Tabs";

// Types
import {ReasonType} from "@services/types/absence";

const TABS = [
  {name: 'Singole', value: 'single'},
  {name: 'Di gruppo', value: 'group'},
]


export function PlannerAbsences() {

  const scopes = AuthenticationService.getUserScopes();
  const userData = AuthenticationService.getUserData();
  const isMember = scopes.includes('absences:personal');

  const url = new URL(window.location.href)
  const typeParam = url.searchParams.get('type')

  const [tabIndex, setTabIndex] = useState<number>(typeParam ? TABS.map(i => i.value).indexOf(typeParam) : 0)

  const roles = useRoles()
  const reasonTypes: ReasonType[] = useReasonTypes()
  const members = useMembers({onlyActive: true, assigned: true, asObj: false})
  const membersByUserId = useMembers({onlyActive: true, assigned: true, asObj: false, byUserId: true})

  useEffect(() => {
    if (!isMember) {
      url.searchParams.set('type', TABS[tabIndex].value)
      window.history.pushState(null, '', url)
    }
  }, [tabIndex, isMember]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageHeading title="Gestione indisponibilità" historyBackPath="_back"/>
      {isMember ? null :
        <div className="-mt-4 sm:mt-0">
          <Tabs
            setActiveTab={setTabIndex}
            active={tabIndex}
            tabs={TABS}
          />
        </div>
      }
      {tabIndex === 0 ?
        <SingleAbsences
          roles={roles}
          members={members}
          isMember={isMember}
          userData={userData}
          reasonTypes={reasonTypes}
          membersByUserId={membersByUserId}
        /> :
        <GroupAbsences
          roles={roles}
          userData={userData}
          reasonTypes={reasonTypes}
          membersByUserId={membersByUserId}
        />
      }
    </>
  );
}
