import React from "react";

import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import {useQuizQuestion} from "@components/RefMan/Quiz/hooks/useQuizQuestion";
import {DeleteQuestionFromQuizProps} from "@components/RefMan/Quiz/types";


export function DeleteQuestionFromQuizModal({onExit, idQuiz, idQuestion, setRefreshData}: DeleteQuestionFromQuizProps) {

  const {delete_, loading} = useQuizQuestion({setRefreshData});

  return (
    <Modal
      onExit={onExit}
    >
      <div className="m-5">
        <h1 className="font-bold sm:text-xl">Confermi di voler la domanda dal quiz?</h1>
        <h3 className="text-xs sm:text-sm my-3">Non sarà possibile annullare l'operazione.</h3>
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button onClick={() => delete_(idQuiz, idQuestion).then(onExit)} submitting={loading}>
          Conferma
        </Button>
      </div>
    </Modal>
  );
}
