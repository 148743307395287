import React, {useContext, useState} from "react";

// Services
import {getStatusBadgeLabel} from "@components/RefMan/Reports/ReportsList/ObserverReportsList.lib";
import {getAllowedObservers, getNominationRoles} from "@pages/Nominations/Observers/Observers.lib";
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";

// Ui
import Select from "@ui-components/Select";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import {ObserverNominationPropsType} from "@pages/Nominations/Observers/Observers.type";
import Tooltip from "@ui-components/Tooltip";


export default function ObserverNominationDialog(
  {
    onExit,
    group,
    game,
    observers,
    setRefresh
  }: ObserverNominationPropsType
) {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);

  const allowedObservers = getAllowedObservers(observers, game.cod_level, group);

  const okStatus = ['ACC', 'TRA']
  const {ref1, ref2, obsr, sp, crono, a24s, obsu} = getNominationRoles(game, okStatus)

  const previousObservers = group === 'ref'
    ? game.nominations.filter(i => i.role === 'OBSR' && !okStatus.includes(i.status))
    : game.nominations.filter(i => i.role === 'OBSU' && !okStatus.includes(i.status))

  const [selectedObserver, setSelectedObserver] = useState<number>(group === 'ref'
    ? obsr.length ? obsr[0].member.id_fip_code : 0
    : obsu.length ? obsu[0].member.id_fip_code : 0
  )

  const send = () => {
    setLoading(true)
    const payload = {
      role: group === 'ref' ? 'OBSR' : 'OBSU',
      cod_game: game.cod_game,
      id_fip_code: selectedObserver,
      game: game,
      observer_mail: selectedObserver ? observers.filter(i => i.id_fip_code === selectedObserver)[0].mail : ''
    }
    api.post('/nominations/observer', payload)
      .then(() => {
        push({title: "Dati inviati correttamente", type: "success"})
        setRefresh((prev) => !prev)
        onExit()
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      maxWidth="w-full sm:max-w-xl"
      onExit={onExit}
    >
      <div className="mb-5 flex flex-col gap-2">
        <h1 className="font-bold sm:text-xl mb-1">Designazione osservatore {group === 'ref' ? 'arbitri' : 'UdC'}</h1>
        <h2 className="text-sm">
          Gara <b>{game.game_number}</b> di <b>{game.level.des_level_extended}</b> del <b>{intl.formatDate(game.dat_game)}</b>
        </h2>
        <h2 className="text-sm">
          <b>Squadre:</b> <i className="text-xs">{game.team_a.team_alias}</i> vs <i
          className="text-xs">{game.team_b.team_alias}</i>
        </h2>
        {group === 'ref' ?
          <div className="text-sm">
            {ref1.length ? <h2><b>1° Arbitro:</b> {ref1[0].member.member}</h2> : null}
            {ref2.length ? <h2><b>2° Arbitro:</b> {ref2[0].member.member}</h2> : null}
          </div>
          :
          <div className="text-sm">
            {sp.length ? <h2><b>Segnapunti:</b> {sp[0].member.member}</h2> : null}
            {crono.length ? <h2><b>Cronometrista:</b> {crono[0].member.member}</h2> : null}
            {a24s.length ? <h2><b>Addetto 24 '':</b> {a24s[0].member.member}</h2> : null}
          </div>
        }
      </div>
      {previousObservers.length ?
        <div className="flex flex-col my-6 gap-1">
          <span className="text-sm font-medium text-gray-700 mb-1">Designazioni precedenti</span>
          {previousObservers.map(i => <li className="text-sm" key={i.member.id_fip_code}>
            {i.member.member} ({getStatusBadgeLabel(i.status)})
          </li>)}
        </div> : null
      }

      <span className="text-sm font-medium text-gray-700 mb-1">Osservatore</span>
      <div className="flex flex-row gap-4 items-center">
        <div className="w-full">
          <Select
            options={[{value: 0, name: ""}].concat(allowedObservers)}
            value={selectedObserver}
            label={null}
            //@ts-ignore
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedObserver(0)
              if (e.target.value) {
                setSelectedObserver(Number(e.target.value))
              }
            }}
            customStyles={undefined}
          />
        </div>
        <Tooltip
          placement="left"
          text={<span>Per revocare la gara, selezionare la riga vuota (o il sostituto) e salvare.<br/><br/>Inviando nuovamente una designazione trasmessa, l'osservatore riceverà un sollecito.</span>}
          customStyle="text-xs normal-case"
          iconName="InformationCircleIcon"
          size="small"
        />
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between mt-10">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button onClick={send} submitting={loading}>
          Invia
        </Button>
      </div>
    </Modal>
  );
}
