import React, { useState, ChangeEvent, KeyboardEvent } from "react";

import { api } from "@services/apiRequest";
import { AxiosResponse } from "axios";
import Input from "@ui-components/Input";
import Button from "@ui-components/Button";

interface Message {
  text: string;
  isUser: boolean;
}

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>("");

  const handleSendMessage = async (): Promise<void> => {
    if (inputText.trim() === "") {
      return;
    }

    setInputText("");
    setLoading(true);

    const response: string = await api
      .get<string>(`/smartsearch?question=${inputText}`)
      .then((res) => res.data);

    setLoading(false);

    // Aggiungi il messaggio dell'utente e la risposta alla lista dei messaggi
    setMessages([
      ...messages,
      { text: inputText, isUser: true },
      { text: response, isUser: false },
    ]);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputText(event.target.value);
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 overflow-y-auto px-4 py-2">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-2 ${
              message.isUser
                ? "self-end flex justify-end"
                : "self-start flex justify-start"
            }`}
          >
            <div
              className={`p-3 rounded-lg ${
                message.isUser
                  ? "bg-blue-500 text-white mr-2 max-w-xl"
                  : "bg-gray-300 ml-2 max-w-xl"
              }`}
            >
              {message.text}
            </div>
          </div>
        ))}
      </div>
      <div className="sticky bottom-0 w-full p-4 flex flex-row items-center gap-2">
        <div className="w-full">
          {/*@ts-ignore*/}
          <Input
            value={inputText}
            onChange={handleInputChange}
            showErrorDialog={false}
            placeholder="Scrivi un messaggio..."
          />
        </div>
        <div className="py-2">
          <Button
            styleType="white"
            className="text-md"
            submitting={loading}
            onClick={handleSendMessage}
          >
            Invia
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
