import moment from "moment";
import {Group} from "@services/types/member";

export const API_URL = "/api";

export const TODAY = moment(new Date()).format('YYYY-MM-DD');
export const ALL_STATUS = ['ACC', 'TEM', 'TRA', 'REV', 'RIF']
export const OK_STATUS = ['ACC', 'TEM', 'TRA']
export const LKP_STATUS: Record<string, string> = {
  ACC: 'Accettate',
  TEM: 'Temporanee',
  TRA: 'Trasmesse',
  REV: 'Revoche',
  RIF: 'Rifiuti'
}

export const LKP_GROUP: { [g in Group]: string } = {
  'REF': 'Arbitro',
  'UDC': 'UdC'
}

export const LKP_ROLES: Record<string, string> = {
  'REF1': '1° Arbitro',
  'REF2': '2° Arbitro',
  'SP': 'Segnapunti',
  'CRONO': 'Cronometrista',
  'A24S': 'Addetto 24"',
  'UDCS': 'UdC Statistico',
  'OBSR': 'Osservatore Arbitri',
  'OBSU': 'Osservatore Udc',
  'UNKNOWN': 'Sconosciuto'
}

export const REPORTS_STATUS: Record<string, string> = {
  'wip': 'In lavorazione',
  'done': 'Concluso',
  'approved': 'Approvato',
  'todo': 'Da compilare',
}

export const LKP_MEMBERSHIP = {
  'RINNOVATO': 'Rinnovato',
  'IN CORSO': 'In corso',
  'DA RINNOVARE': 'Da rinnovare'
}

export const CALENDAR_MODE = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly"
}

export const CROP_ASPECT_RATIO = 16 / 16;
export const CROP_ASPECT_RATIO_TOLERANCE = 0.01;

export const REGIONAL_PROVINCES = [
  {cod_province: 'PU', des_province: 'Pesaro e Urbino'},
  {cod_province: 'AN', des_province: 'Ancona'},
  {cod_province: 'MC', des_province: 'Macerata'},
  {cod_province: 'FM', des_province: 'Fermo'},
  {cod_province: 'AP', des_province: 'Ascoli Piceno'},
]

export const START_SEASON = '2024-09-01'
export const END_SEASON = '2025-06-30'

export const TIME_OPTIONS = [
  {value: '', name: "Nessun limite"},
  {value: '09', name: "Mattino"},
  {value: '15', name: "15:00"},
  {value: '153', name: "15:30"},
  {value: '16', name: "16:00"},
  {value: '163', name: "16:30"},
  {value: '17', name: "17:00"},
  {value: '173', name: "17:30"},
  {value: '18', name: "18:00"},
  {value: '183', name: "18:30"},
  {value: '19', name: "19:00"},
  {value: '193', name: "19:30"},
  {value: '20', name: "20:00"},
  {value: '203', name: "20:30"},
  {value: '21', name: "21:00"},
  {value: '213', name: "21:30"},
  {value: '22', name: "22:00"},
]

export const WEEKDAY_OPTIONS = [
  {value: '0', name: 'lun'},
  {value: '1', name: 'mar'},
  {value: '2', name: 'mer'},
  {value: '3', name: 'gio'},
  {value: '4', name: 'ven'},
  {value: '5', name: 'sab'},
  {value: '6', name: 'dom'},
]

export const LKP_JOB_TYPES = {
  CIA: 'CIA',
  OBS: 'Osservatore',
  DES: 'Designatore',
  IST: 'Istruttore',
  TUT: 'Tutor'
}

export const LKP_ACTIVITY_TYPES: Record<string, string> = {
  RADUNO: 'Raduno',
  LESSON: 'Lezione in sala',
  GYM: 'Allenamento in palestra',
  REMOTE: 'Lezione online'
}

export const EDITOR_TOOLBAR = ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'insertTable', '|', 'undo', 'redo']