import React, { useCallback } from "react";

import AuthenticationService from "../../services/AuthenticationService";
import apiRequest from "../../services/apiRequest";
import { FormattedMessage } from "react-intl";

const RoleSelector = ({roles}) => {

  const userData = AuthenticationService.getUserData();
  const access_token = AuthenticationService.getAuthToken()
  const userActiveRoles = userData?.roles?.split?.(";") || [];

  const onChange = useCallback((e) => {
    localStorage.setItem('refman_role', e.target.value);
    apiRequest.get(
      `/auth/get-token/${e.target.value}`,
      {
        publicRequest: true,
        headers: {"Authorization": `Bearer ${access_token}`},
      }
    ).then((res) => {
      const refresh_token = AuthenticationService.getRefreshToken()
      AuthenticationService.setAuthToken(res, refresh_token, userData);
      window.location.reload();
    }).catch((err) => {
      console.log(err)
    })


  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (userActiveRoles.length <= 1) {
    return null;
  }

  if (!roles) {
    return null
  }

  return (
    <div className="pl-2 pr-2 flex w-full justify-between items-center mb-3">
      <div className="text-xs font-bold">
        <FormattedMessage id="role" defaultMessage="Role" />
      </div>
      <select
        className="bg-transparent border-0 text-sm pt-0 pb-0 hover:bg-gray-50 rounded-2xl h-8 focus:outline-none focus:ring-transparent focus:border-transparent"
        value={localStorage.getItem('refman_role')}
        onChange={onChange}
      >
        {userActiveRoles.map((r) => (
          <option key={r} value={r}>
            {roles[r].description}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RoleSelector;
