import React, {useEffect} from "react";
import useCountDown from "@components/RefMan/Quiz/hooks/useContDownTimer";


interface SessionTimerProps {
  numSecTimer: number | undefined;
  setTimeout: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SessionTimer({numSecTimer, setTimeout}: SessionTimerProps) {

  if (numSecTimer === undefined) {
    return <></>
  }

  const getHourFromSeconds = (sec: number) => {
    return Math.floor(sec / 3600);
  }

  const getMinutesFromSeconds = (sec: number) => {
    return Math.floor((sec % 3600) / 60);
  }

  const getSecondsFromSeconds = (sec: number) => {
    return sec % 60;
  }

  const formatTime = (time: number) => {
    return time < 10 ? `0${time}` : `${time}`;
  }

  const {secondsLeft, start} = useCountDown();

  useEffect(() => {
    start(numSecTimer);
  }, [numSecTimer]);


  if (secondsLeft === 0)
    setTimeout(true);

  return <div className="flex justify-center items-center space-x-1 border border-am-700 rounded-3xl px-3 py-2">
    <span className="text-4xl font-bold">
      {formatTime(getHourFromSeconds(secondsLeft))}
    </span>
    <span className="text-4xl font-bold">:</span>
    <span className="text-4xl font-bold">
      {formatTime(getMinutesFromSeconds(secondsLeft))}
    </span>
    <span className="text-4xl font-bold">:</span>
    <span className="text-4xl font-bold">
      {formatTime(getSecondsFromSeconds(secondsLeft))}
    </span>
  </div>
}