import _ from "lodash";
import XLSX from "xlsx";

export const downloadExcel = (
  data: any,
  fields: {field: string, title: string}[],
  fileTitle: string
) => {

  const content = data.map((key: any) => _.pick(key, fields.map(i => i.field)));
  const workSheet = XLSX.utils.json_to_sheet([]);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(workSheet, [fields.map(i => i.title)]);
  XLSX.utils.sheet_add_json(workSheet, content, {origin: "A2", skipHeader: true});
  XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");
  XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workBook, `${fileTitle}.xlsx`);
};