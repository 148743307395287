import React from "react";
import {PageHeading} from "@ui-components/Container";


export default function NominationChanges() {

  return (
    <>
      <PageHeading title="Gestione rifiuti" historyBackPath="_back" />
    </>
  );
}
