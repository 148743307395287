import React from "react";
import {NOTE_CLS} from "@pages/Threads/Detail/Message.lib";
import {timeStampFormat} from "@pages/Planner/Absences/Absences.lib";
import {ArrowUturnRightIcon, PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import {Message} from "@pages/Threads/Detail/Message";
import Button from "@ui-components/Button";
import {useIntl} from "react-intl";

import {MessageListPropsType} from "@pages/Threads/Threads.type";

export function MessageList(
  {
    push,
    thread,
    userIdFipCode,
    editMessage,
    setEditMessage,
    setIdToDelete,
    setRefresh,
    setNewMention,
    setShowEditor,
  }: MessageListPropsType
) {

  const intl = useIntl();

  return (
    <div className="flex flex-col gap-3 mb-5">
      {
        (thread?.messages ?? []).sort((a, b) => a.tms_creation.localeCompare(b.tms_creation)).map((m) => (
          <div
            key={m.id}
            className={`${NOTE_CLS.replace('px-3 py-2', '')}`}
          >
            <div
              className="px-3 py-2">
              <div className="flex justify-between">
                <div className="font-thin text-sm">
                  <span className="font-extrabold">{m.author.member}</span>
                  <span className="mx-1.5">&#8226;</span>
                  <span>{intl.formatDate(m.tms_creation, timeStampFormat)}</span>
                </div>
                <div className="flex items-start gap-2 cursor-pointer">
                  {
                    userIdFipCode === m.id_author && (
                      <PencilSquareIcon
                        className="w-4 text-gray-400 hover:text-am-500"
                        onClick={() => {
                          setEditMessage(m);
                        }}
                      />
                    )
                  }
                  {
                    userIdFipCode === m.id_author && (
                      <TrashIcon
                        className="w-4 text-gray-400 hover:text-red-500"
                        onClick={() => {
                          setIdToDelete(m.id);
                        }}
                      />
                    )
                  }
                </div>
              </div>
              <div className="text-sm mt-2">
                <Message
                  push={push}
                  idThread={thread.id}
                  recipients={thread.recipients}
                  creator={thread.creator}
                  editMessage={m}
                  onExit={() => {
                    setEditMessage(undefined)
                  }}
                  setRefresh={setRefresh}
                  readOnly={!Boolean(editMessage?.id === m.id)}
                />
              </div>
              <div className="flex items-center justify-between">
                <span className="text-xs text-gray-400">
                  {
                    m.tms_update &&
                    <span>Modificato il {intl.formatDate(m.tms_update, timeStampFormat)}</span>
                  }
                </span>
                <Button
                  styleType="white"
                  onClick={(e: any) => {
                    e.preventDefault();
                    setNewMention({id: m.author.id_fip_code, name: m.author.member})
                    setShowEditor(true)
                  }}
                >
                  <ArrowUturnRightIcon className="w-3 mr-1"/>
                  <span className="text-xxs -my-1">Rispondi</span>
                </Button>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}