import React from "react";
import CheckboxGroup from "../../CheckboxGroup";

const UpdateRoles = ({value, onChange, allRoles}) => {
  const options = Object.values(allRoles).map(i => ({value: String(i.id), name: i.description}));
  const roles = value ? value.split(";") : []
  const setRoles = (e) => onChange(e.length ? e.join(";") : "")

  return (
    <div>
      <CheckboxGroup
        options={options}
        values={roles}
        onChange={(value) => {
          if (roles.indexOf(value) >= 0) {
            setRoles([...roles.filter((v) => v !== value)]);
          } else {
            setRoles([...roles, value]);
          }
        }}
      />
    </div>
  )
};

export default UpdateRoles;
