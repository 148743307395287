import React, {useContext, useState} from "react";

// Services
import {NotificationsContext} from "@ui-components/Notifications";
import {api} from "@services/apiRequest";

// Ui
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import {AddParticipantsDialogProps} from "../Detail.type";
import {AssignListMembers} from "@components/RefMan/AssignListMembers";


export function AddParticipantsDialog({onExit, activity, setActivity}: AddParticipantsDialogProps) {

  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [inserted, setInserted] = useState<number[] | undefined>(
    activity.members_activities.filter(i => i.activity_role === 'STUDENT').map(i => i.id_fip_code))

  const updateData = () => {
    setLoading(true)
    api.post(`/activities/participants`, {
      id_activity: activity.id,
      members: inserted
    })
      .then(() => {
        push({title: "Dati salvati con successo", type: "success"});
        setActivity(undefined);
        onExit();
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  const description = () => <div className="flex flex-col">
    <span className="font-bold sm:text-xl">Gestione convocati</span>
  </div>

  return (
    <Modal
      maxWidth="w-full sm:max-w-4xl"
      onExit={onExit}
    >
      {inserted ?
        <AssignListMembers
          membersList={inserted}
          setMembersList={setInserted}
          description={description()}
        /> : null
      }
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between mt-10">
        <Button styleType="white" onClick={onExit}>
          Annulla
        </Button>
        <Button onClick={updateData} submitting={loading}>
          Salva
        </Button>
      </div>
    </Modal>
  );
}
