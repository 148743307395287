import React, {useContext, useEffect, useState} from "react";

// Services
import {END_SEASON, TIME_OPTIONS, WEEKDAY_OPTIONS} from "@utils/constants";
import {getMissingDataError, getInitAbsence} from "./Absences.lib";
import {NotificationsContext} from "@ui-components/Notifications";
import {DataContext} from "@services/DataContext/DataContext";
import {api} from "@services/apiRequest";
import moment from "moment";

// Ui
import InputMultiLine from "@ui-components/InputMultiLine";
import CheckboxGroup from "@ui-components/CheckboxGroup";
import RadioGroup from "@ui-components/RadioGroup";
import DatePicker from "@ui-components/DatePicker";
import Select from "@ui-components/Select";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import Alert from "@ui-components/Alert";

import {AddUpdateAbsenceDialogPropsType} from "./Absences.type";
import {Absence} from "@services/types/absence";


export default function AddUpdateAbsenceDialog(
  {
    onExit,
    updateAbsence,
    updateReason,
    reasonTypes,
    idFipCode,
    currentUserId,
    isMember,
    setReasons
  }: AddUpdateAbsenceDialogPropsType
) {

  const dataContext = useContext(DataContext);
  const {push} = useContext(NotificationsContext);

  let _updateAbsence = updateAbsence
  if (updateReason) {
    _updateAbsence = {
      absence_reason: updateReason,
      id_absence_reason: updateReason.id,
      id_fip_code: 0,
      id_added_user: 0,
      id_added_role: 0
    }
  }

  // If idFipCode is 0, we are adding/updating just the reason of a group absence
  const [absence, setAbsence] = useState<Absence>(_updateAbsence ?? getInitAbsence(idFipCode ?? 0))
  const [dailyOrPeriod, setDailyOrPeriod] = useState<'daily' | 'period'>(updateAbsence?.absence_reason.dat_to ? 'period' : 'daily')
  const [allOrSomeDays, setAllOrSomeDays] = useState<'all' | 'some'>(updateAbsence?.absence_reason.weekdays ? 'some' : 'all')
  const [missingData, setMissingData] = useState<string | undefined>('Seleziona la data')
  const [loading, setLoading] = useState(false)

  const idTypeOrario = reasonTypes.filter(i => i.des_reason_type === 'Disponibilità oraria')[0].id

  const addAbsence = () => {
    setLoading(true)

    const payload = !idFipCode ? {...absence.absence_reason, flg_group: true} : {
      ...absence, id_added_user: currentUserId, id_added_role: localStorage.getItem("refman_role")
    }
    api.post(idFipCode ? '/absences' : '/absences/reasons', payload)
      .then(() => {
        // @ts-ignore
        dataContext.setAbsences({idFipCode: undefined, data: null})
        if (setReasons) {
          setReasons(undefined)
        }
        push({title: "Indisponibilità registrata con successo", type: "success"});
        onExit()
      })
      .catch((err) => {
        console.log(err)
        push({title: "Errore del server", type: "error"});
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {

    let res = absence
    if (dailyOrPeriod === 'daily') {
      setAllOrSomeDays('all')
      res = {...absence, absence_reason: {...absence.absence_reason, dat_to: undefined, weekdays: undefined}}
    }
    if (allOrSomeDays === 'all') {
      res = {...absence, absence_reason: {...absence.absence_reason, weekdays: undefined}}
    }
    setAbsence(res)

  }, [dailyOrPeriod, allOrSomeDays]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMissingData(getMissingDataError(absence, dailyOrPeriod, allOrSomeDays, idTypeOrario))
  }, [absence]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      maxWidth="w-full sm:max-w-xl"
      onExit={onExit}
    >
      <div className="mb-5 flex flex-col gap-2">
        <h1 className="font-bold sm:text-xl mb-4">
          {updateReason ? 'Modifica' : 'Aggiunta'} indisponibilità
        </h1>
        <div>
          {/*@ts-ignore*/}
          <Select
            label="Tipologia"
            value={absence.absence_reason.id_reason_type}
            options={(isMember ? reasonTypes.filter(i => i.flg_public) : reasonTypes).map(i => ({
              value: i.id,
              name: i.des_reason_type
            }))}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAbsence({
              ...absence,
              absence_reason: {...absence.absence_reason, id_reason_type: Number(e.target.value)}
            })}
          />
        </div>
        {absence.absence_reason.id_reason_type === idTypeOrario ?
          <div className="flex flex-col items-center mt-3">
            <Alert
              slim
              title="N.B. Indicare la fascia oraria in cui si è liberi da impegni"
              text={
                <span>
                  I designatori considereranno possibili gare in base alla distanza dal campo da gioco.<br/><br/>
                  <i>Esempio 1: se termino il turno di lavoro alle 18.00 indicherò "Dalle 18.00 - "</i><br/>
                  <i>Esempio 2: se ho un impegno alle 20:30 indicherò " - Fino alle 20.30"</i>
                </span>
              }
            />
            <div className="flex flex-row gap-3 w-full justify-around mt-3">
              {/*@ts-ignore*/}
              <Select
                label="Dalle"
                value={absence.absence_reason.time_from}
                options={TIME_OPTIONS}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAbsence({
                  ...absence, absence_reason: {...absence.absence_reason, time_from: e.target.value}
                })}
              />
              {/*@ts-ignore*/}
              <Select
                label="Fino alle"
                value={absence.absence_reason.time_to}
                options={TIME_OPTIONS}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAbsence({
                  ...absence, absence_reason: {...absence.absence_reason, time_to: e.target.value}
                })}
              />
            </div>
          </div> : null
        }
        <div>
          <RadioGroup
            id="dailyOrPeriodSelection"
            horizontal
            options={[
              {value: "daily", name: "Un giorno"},
              {value: "period", name: "Un periodo"},
            ]}
            currentValue={dailyOrPeriod}
            onChange={(value: 'daily' | 'period') => {
              setDailyOrPeriod(value)
            }}
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-2 -mt-2 mb-3">
          {/*@ts-ignore*/}
          <DatePicker
            value={absence.absence_reason.dat_from ? new Date(absence.absence_reason.dat_from) : undefined}
            onChange={(i: string) => setAbsence({
                ...absence, absence_reason: {...absence.absence_reason, dat_from: moment(i).format('YYYY-MM-DD')}
              }
            )}
            label={dailyOrPeriod === 'daily' ? 'Data' : 'Data di inizio'}
            placeholder="Seleziona data"
            maxDate={new Date(END_SEASON)}
          />
          {dailyOrPeriod === 'period' ?
            // @ts-ignore
            <DatePicker
              value={absence.absence_reason.dat_to ? new Date(absence.absence_reason.dat_to) : undefined}
              onChange={(i: string) => setAbsence({
                  ...absence, absence_reason: {...absence.absence_reason, dat_to: moment(i).format('YYYY-MM-DD')}
                }
              )}
              label="Data di fine"
              placeholder="Seleziona data"
              maxDate={new Date(END_SEASON)}
            /> : null
          }
        </div>
        {dailyOrPeriod === 'period' ?
          <>
            <div>
              <RadioGroup
                id="allOrSomeDaysSelection"
                horizontal
                options={[
                  {value: "all", name: "Tutti i giorni"},
                  {value: "some", name: "Seleziona giorni"},
                ]}
                currentValue={allOrSomeDays}
                onChange={(value: 'all' | 'some') => {
                  setAllOrSomeDays(value)
                }}
              />
            </div>
            {allOrSomeDays === 'some' ?
              <div>
                {/*@ts-ignore*/}
                <CheckboxGroup
                  horizontal
                  title="Giorni della settimana"
                  options={WEEKDAY_OPTIONS}
                  values={absence.absence_reason.weekdays?.split(';')}
                  onChange={(value: string) => {
                    const currentList = (absence.absence_reason.weekdays ?? '').split(';').filter(i => i)
                    if (currentList.includes(value)) {
                      setAbsence({
                        ...absence, absence_reason: {
                          ...absence.absence_reason,
                          weekdays: [...currentList.filter((v) => v !== value)].join(';')
                        }
                      });
                    } else {
                      setAbsence({
                        ...absence, absence_reason: {
                          ...absence.absence_reason,
                          weekdays: [...currentList, value].join(';')
                        }
                      });
                    }
                  }}
                />
              </div> : null
            }
          </>
          : null
        }
        <div className="mt-2">
          <InputMultiLine
            rows={2}
            value={absence.absence_reason.des_absence}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAbsence({...absence, absence_reason: {...absence.absence_reason, des_absence: e.target.value}});
            }}
            label="Motivazione"
            type="text"
          />
        </div>
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-3 justify-between items-center">
        {missingData ?
          <Alert
            type="warning"
            slim
            title={missingData}
          /> : <div/>
        }
        <div>
          <Button onClick={addAbsence} submitting={loading} disabled={missingData !== undefined}>
            Invia
          </Button>
        </div>
      </div>
    </Modal>
  );
}
