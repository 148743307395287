import React from "react";

const NotificationPing = ({internal = 1.5, external = 2}) => {
  const externalSize = `w-${external} h-${external}`
  const internalSize = `w-${internal} h-${internal}`
  return (<span className={`flex items-center justify-center relative ${externalSize}`}>
    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
    <span className={`inline-flex rounded-full bg-red-500 ${internalSize}`}></span>
  </span>
  )
}

export default NotificationPing;