import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, getXYChart, STATUS_COLORS} from "@components/Charts/commons";
import {LKP_STATUS} from "@utils/constants";

const getChartHigh = (n) => {
  switch (true) {
    case (n < 2):
      return 'h-24'
    case (n < 4):
      return 'h-48'
    case (n < 6):
      return 'h-72'
    default:
      return 'h-96'
  }
}


function NominationPersonaReportLevelChart({id, data, categories}) {

  useLayoutEffect(
    () => {

      if (!data)
        return

      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)])
      root.locale = am5locales_it_IT;
      root.numberFormatter.set("numberFormat", "#,###.");

      // Create chart
      let chart = getXYChart(root, {layout: root.verticalLayout})
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);

      let legend = chart.children.unshift(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );

      legend.markers.template.setAll({
        width: 10,
        height: 10
      });

      legend.labels.template.setAll({
        fontSize: 10,
        fontWeight: "300"
      });

      // Create axes
      // CategoryAxis: is the only axis type that requires its own data as well as data field set up.
      let yAxis = chart.yAxes.push(
        chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "level",
            renderer: am5xy.AxisRendererY.new(root, {minGridDistance: 0}),
          })
        ))

      yAxis.data.setAll(data);

      let yRenderer = yAxis.get("renderer");
      yRenderer.grid.template.set("forceHidden", true);
      yRenderer.labels.template.set("fontSize", 12);

      let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.05,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererX.new(root, {}),
        calculateTotals: true
      }))
      let xRenderer = xAxis.get("renderer");
      xRenderer.grid.template.set("forceHidden", true);
      xRenderer.labels.template.set("forceHidden", true);


      // Add series
      function makeSeries(name) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: LKP_STATUS[name],
          xAxis: xAxis,
          yAxis: yAxis,
          fill: STATUS_COLORS[name],
          stroke: am5.color("rgb(255,255,255)"),
          valueXField: name,
          categoryYField: "level",
          stacked: true,
          tooltip: name === 'total' ? null : am5.Tooltip.new(root, {
            labelText: "{categoryY}\n[bold]{name}: {valueX} ({valueXTotalPercent}%)[/]",
            tooltipY: am5.percent(10),
            fontSize: 8
          })
        }))

        series.columns.template.setAll({
          fillOpacity: 0.65,
          visible: name !== 'total',
          cornerRadiusTL: 4,
          cornerRadiusTR: 4,
          cornerRadiusBL: 4,
          cornerRadiusBR: 4
        });

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            sprite: am5.Label.new(root, {
              text: name === 'total' ? "[bold]{valueXTotal}[/]" : "[bold]{valueX}[/]",
              centerY: am5.p50,
              centerX: name === 'total' ? am5.p0 : am5.p100,
              populateText: true,
              fontSize: name === 'total' ? 13 : 11
            })
          });
        });


        legend.data.push(series);
        series.data.setAll(data);

        if (name === 'total') {
          series.get("legendDataItem").get("itemContainer").hide();
        }

        series.appear(1000);

        return series
      }

      categories.forEach(c => {
        makeSeries(c)
      })
      makeSeries('total')


      return () => {
        root.dispose()
      }
    },
    [id, data, categories] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return <div id={id} className={`w-full ${getChartHigh(data.length)}`}/>
}

export default React.memo(NominationPersonaReportLevelChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
